import { TReportState } from '@/redux/reducers/report';
import { TGetReportTimeOffScheduleSuccess } from '@/redux/actions/report';

export const getReportTimeOffScheduleUpdateState = (
  state: TReportState,
  action: TGetReportTimeOffScheduleSuccess,
): TReportState => ({
  ...state,
  getReportTimeOffScheduleResponse: action.payload.response,
});

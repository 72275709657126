import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 9.67273C9.22589 9.67273 10.625 8.27362 10.625 6.54773C10.625 4.82184 9.22589 3.42273 7.5 3.42273C5.77411 3.42273 4.375 4.82184 4.375 6.54773C4.375 8.27362 5.77411 9.67273 7.5 9.67273Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.875 18.4241H7.5H13.125V17.7465C13.115 16.7936 12.8638 15.859 12.3947 15.0296C11.9255 14.2004 11.2539 13.5035 10.4424 13.0041C9.63094 12.5047 8.70614 12.2192 7.7544 12.1741C7.66955 12.1701 7.58473 12.168 7.5 12.1678C7.41527 12.168 7.33045 12.1701 7.2456 12.1741C6.29386 12.2192 5.36906 12.5047 4.55759 13.0041C3.74613 13.5035 3.07446 14.2004 2.60534 15.0296C2.13622 15.859 1.88495 16.7936 1.875 17.7465V18.4241Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 9.67273C14.2259 9.67273 15.625 8.27362 15.625 6.54773C15.625 4.82184 14.2259 3.42273 12.5 3.42273"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.625 18.4226H18.125V17.745C18.115 16.7921 17.8637 15.8575 17.3946 15.0281C16.9255 14.1989 16.2539 13.502 15.4424 13.0026C14.9179 12.6798 14.3459 12.4463 13.75 12.3094"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { changeStatusAssetAction } from '@/redux/actions';
import { changeStatusAsset, TChangeStatusAssetResponse } from '@/services/api';

// FUNCTION

export function* changeStatusAssetSaga(action: ActionType<typeof changeStatusAssetAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(changeStatusAsset, materials);
    const changeStatusAssetResponse: TChangeStatusAssetResponse = response as TChangeStatusAssetResponse;
    yield put(changeStatusAssetAction.success(changeStatusAssetResponse));
    successCallback?.(changeStatusAssetResponse);
  } catch (err) {
    yield put(changeStatusAssetAction.failure(err));
    failedCallback?.(err);
  }
}

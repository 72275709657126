import { createActionCreator } from 'deox';

import { TGetRequestMaterials, TGetRequestResponse } from '@/services/api/request/get-request';

// CONSTANTS

export enum EGetRequestAction {
  GET_REQUEST = 'GET_REQUEST',
  GET_REQUEST_REQUEST = 'GET_REQUEST_REQUEST',
  GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS',
  GET_REQUEST_FAILED = 'GET_REQUEST_FAILED',
}

// TYPES

export type TGetRequestRequest = {
  type: EGetRequestAction.GET_REQUEST_REQUEST;
  payload: {
    materials: TGetRequestMaterials;
    successCallback?: (response: TGetRequestResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRequestSuccess = {
  type: EGetRequestAction.GET_REQUEST_SUCCESS;
  payload: { response?: TGetRequestResponse };
};

export type TGetRequestFailed = { type: EGetRequestAction.GET_REQUEST_FAILED };

// FUNCTION

export const getRequestAction = {
  request: createActionCreator(
    EGetRequestAction.GET_REQUEST_REQUEST,
    (resolve) =>
      (
        materials: TGetRequestMaterials,
        successCallback?: (response: TGetRequestResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRequestRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRequestAction.GET_REQUEST_SUCCESS,
    (resolve) =>
      (response?: TGetRequestResponse): TGetRequestSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRequestAction.GET_REQUEST_FAILED,
    (resolve) =>
      (error: unknown): TGetRequestFailed =>
        resolve({ error }),
  ),
};

import { TRole } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetRolesParams = unknown;

export type TGetRolesMaterials = {
  params?: TGetRolesParams;
};

export type TGetRolesResponse = {
  data: TRole[];
  total: { [key: string]: string };
};

// FUNCTION

export const getRoles = async ({ params }: TGetRolesMaterials): Promise<TGetRolesResponse> => {
  const response = await ApiService.get(`/role/list`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TGetMyAssetsHistoryMaterials, TGetMyAssetsHistoryResponse } from '@/services/api/asset/get-my-assets-history';

// CONSTANTS

export enum EGetMyAssetsHistoryAction {
  GET_MY_ASSETS_HISTORY = 'GET_MY_ASSETS_HISTORY',
  GET_MY_ASSETS_HISTORY_REQUEST = 'GET_MY_ASSETS_HISTORY_REQUEST',
  GET_MY_ASSETS_HISTORY_SUCCESS = 'GET_MY_ASSETS_HISTORY_SUCCESS',
  GET_MY_ASSETS_HISTORY_FAILED = 'GET_MY_ASSETS_HISTORY_FAILED',
}

// TYPES

export type TGetMyAssetsHistoryRequest = {
  type: EGetMyAssetsHistoryAction.GET_MY_ASSETS_HISTORY_REQUEST;
  payload: {
    materials: TGetMyAssetsHistoryMaterials;
    successCallback?: (response: TGetMyAssetsHistoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMyAssetsHistorySuccess = {
  type: EGetMyAssetsHistoryAction.GET_MY_ASSETS_HISTORY_SUCCESS;
  payload: { response: TGetMyAssetsHistoryResponse };
};

export type TGetMyAssetsHistoryFailed = { type: EGetMyAssetsHistoryAction.GET_MY_ASSETS_HISTORY_FAILED };

// FUNCTION

export const getMyAssetsHistoryAction = {
  request: createActionCreator(
    EGetMyAssetsHistoryAction.GET_MY_ASSETS_HISTORY_REQUEST,
    (resolve) =>
      (
        materials: TGetMyAssetsHistoryMaterials,
        successCallback?: (response: TGetMyAssetsHistoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMyAssetsHistoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMyAssetsHistoryAction.GET_MY_ASSETS_HISTORY_SUCCESS,
    (resolve) =>
      (response: TGetMyAssetsHistoryResponse): TGetMyAssetsHistorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMyAssetsHistoryAction.GET_MY_ASSETS_HISTORY_FAILED,
    (resolve) =>
      (error: unknown): TGetMyAssetsHistoryFailed =>
        resolve({ error }),
  ),
};

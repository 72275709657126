import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { downloadTemplateUsersAction } from '@/redux/actions';
import { downloadTemplateUsers, TDownloadTemplateUsersResponse } from '@/services/api';

// FUNCTION

export function* downloadTemplateUsersSaga(action: ActionType<typeof downloadTemplateUsersAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(downloadTemplateUsers, materials);
    const downloadTemplateUsersResponse: TDownloadTemplateUsersResponse = response as TDownloadTemplateUsersResponse;
    yield put(downloadTemplateUsersAction.success(downloadTemplateUsersResponse));
    successCallback?.(downloadTemplateUsersResponse);
  } catch (err) {
    yield put(downloadTemplateUsersAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { addApproverAction } from '@/redux/actions';
import { addApprover, TAddApproverResponse } from '@/services/api';

// FUNCTION

export function* addApproverSaga(action: ActionType<typeof addApproverAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(addApprover, materials);
    const addApproverResponse: TAddApproverResponse = response as TAddApproverResponse;
    yield put(addApproverAction.success(addApproverResponse));
    successCallback?.(addApproverResponse);
  } catch (err) {
    yield put(addApproverAction.failure(err));
    failedCallback?.(err);
  }
}

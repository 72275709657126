import ApiService from '@/services/api';

// TYPES

export type TAssignOffboardParams = unknown;
export type TAssignOffboardBody = unknown;

export type TAssignOffboardMaterials = {
  params?: TAssignOffboardParams;
  body?: TAssignOffboardBody;
};

export type TAssignOffboardResponse = unknown;

// FUNCTION

export const assignOffboard = async ({ params, body }: TAssignOffboardMaterials): Promise<TAssignOffboardResponse> => {
  const response = await ApiService.post(`/checklist/assign/offboard`, body, { params });
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.125 8.4375C18.125 10.178 17.4336 11.8472 16.2029 13.0779C14.9722 14.3086 13.303 15 11.5625 15C9.82202 15 8.15282 14.3086 6.92211 13.0779C5.6914 11.8472 5 10.178 5 8.4375L18.125 8.4375Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M18.125 8.41758C18.125 6.96482 17.6654 5.54935 16.812 4.37404C15.9586 3.19874 14.7553 2.32393 13.3745 1.875L11.25 8.41758H18.125Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <circle cx="7.5" cy="16.25" r="1.875" stroke={color} strokeWidth="1.25" strokeLinejoin="round" />
      <circle cx="15" cy="16.25" r="1.875" stroke={color} strokeWidth="1.25" strokeLinejoin="round" />
      <circle cx="3.125" cy="4.6875" r="1.25" stroke={color} strokeWidth="1.25" strokeLinejoin="round" />
      <path
        d="M3.75 5.625C4.58334 6.18056 5 7.25895 5 8.37006"
        stroke={color}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

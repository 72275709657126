import { createReducer } from 'deox';

import {
  TCreateTypeWorkResponse,
  TDeleteTypeWorkResponse,
  TGetTypeWorkResponse,
  TGetTypesWorkResponse,
  TUpdateTypeWorkResponse,
} from '@/services/api/type-work';
import {
  createTypeWorkAction,
  deleteTypeWorkAction,
  getTypeWorkAction,
  getTypesWorkAction,
  updateTypeWorkAction,
} from '@/redux/actions';
import { createTypeWorkUpdateState } from './create-type-work';
import { deleteTypeWorkUpdateState } from './delete-type-work';
import { getTypeWorkUpdateState } from './get-type-work';
import { getTypesWorkUpdateState } from './get-types-work';
import { updateTypeWorkUpdateState } from './update-type-work';

export type TTypeWorkState = {
  createTypeWorkResponse?: TCreateTypeWorkResponse;
  deleteTypeWorkResponse?: TDeleteTypeWorkResponse;
  getTypeWorkResponse?: TGetTypeWorkResponse;
  getTypesWorkResponse?: TGetTypesWorkResponse;
  updateTypeWorkResponse?: TUpdateTypeWorkResponse;
};

const initialState: TTypeWorkState = {
  createTypeWorkResponse: undefined,
  deleteTypeWorkResponse: undefined,
  getTypeWorkResponse: undefined,
  getTypesWorkResponse: undefined,
  updateTypeWorkResponse: undefined,
};

const TypeWorkReducer = createReducer(initialState, (handleAction) => [
  handleAction(createTypeWorkAction.success, createTypeWorkUpdateState),
  handleAction(deleteTypeWorkAction.success, deleteTypeWorkUpdateState),
  handleAction(getTypeWorkAction.success, getTypeWorkUpdateState),
  handleAction(getTypesWorkAction.success, getTypesWorkUpdateState),
  handleAction(updateTypeWorkAction.success, updateTypeWorkUpdateState),
]);

export default TypeWorkReducer;

import { TUserState } from '@/redux/reducers/user';
import { TDownloadTemplateUsersSuccess } from '@/redux/actions/user';

export const downloadTemplateUsersUpdateState = (
  state: TUserState,
  action: TDownloadTemplateUsersSuccess,
): TUserState => ({
  ...state,
  downloadTemplateUsersResponse: action.payload.response,
});

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { navigate } from '@reach/router';
import env from '@/env';

import { TWelcomeProps } from '@/layouts/Welcome/Welcome.types';
import { getBanksAction, getMyInfoAction, getNationalsAction, uiActions } from '@/redux/actions';
import { EUserStatus } from '@/common/enums';
import { LayoutPaths, Paths } from '@/pages/routers';
import { TRootState } from '@/redux/reducers';
import Helpers from '@/services/helpers';

const Welcome: React.FC<TWelcomeProps> = ({ children }) => {
  const dispatch = useDispatch();
  const atk = Helpers.getAccessToken();

  const myInfoState = useSelector((state: TRootState) => state.userReducer.getMyInfoResponse);
  const socketState = useSelector((state: TRootState) => state.uiReducer.socket);

  const getMyInfo = useCallback(() => {
    dispatch(getMyInfoAction.request({}));
  }, [dispatch]);

  const getBanks = useCallback(() => {
    dispatch(getBanksAction.request({}));
  }, [dispatch]);

  const getNationals = useCallback(() => {
    dispatch(getNationalsAction.request({}));
  }, [dispatch]);

  useEffect(() => {
    getNationals();
  }, [getNationals]);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    getMyInfo();
  }, [getMyInfo]);

  useEffect(() => {
    if (myInfoState) {
      switch (myInfoState.userStatus) {
        case EUserStatus.ON_BOARDING: {
          if (myInfoState.waittingReview) {
            navigate(`${LayoutPaths.Welcome}${Paths.OnboardingReviewStatus}`);
          } else {
            navigate(LayoutPaths.Welcome);
          }
          break;
        }
        case EUserStatus.RESIGNED:
          navigate(Paths.AccessDenied);
          break;
        default:
          navigate(Paths.Dashboard);
          break;
      }
    }
  }, [myInfoState]);

  useEffect(() => {
    const socket = io(env.api.baseUrl.service, {
      extraHeaders: {
        'Authorization': `Bearer ${atk}`,
      },
    });
    dispatch(uiActions.initialSocket(socket));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socketState) {
      socketState.on('ASSIGN_CHECKLIST', (): void => {
        getMyInfo();
      });
    }

    return (): void => {
      socketState?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketState]);

  return myInfoState ? <div className="Welcome">{children}</div> : <></>;
};

export default Welcome;

import { createActionCreator } from 'deox';

import { TSignInMaterials, TSignInResponse } from '@/services/api/auth/sign-in';

// CONSTANTS

export enum ESignInAction {
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_REQUEST = 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',
}

// TYPES

export type TSignInRequest = {
  type: ESignInAction.SIGN_IN_REQUEST;
  payload: {
    materials: TSignInMaterials;
    successCallback?: (response: TSignInResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSignInSuccess = {
  type: ESignInAction.SIGN_IN_SUCCESS;
  payload: { response: TSignInResponse };
};

export type TSignInFailed = { type: ESignInAction.SIGN_IN_FAILED };

// FUNCTION

export const signInAction = {
  request: createActionCreator(
    ESignInAction.SIGN_IN_REQUEST,
    (resolve) =>
      (
        materials: TSignInMaterials,
        successCallback?: (response: TSignInResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSignInRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ESignInAction.SIGN_IN_SUCCESS,
    (resolve) =>
      (response: TSignInResponse): TSignInSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ESignInAction.SIGN_IN_FAILED,
    (resolve) =>
      (error: unknown): TSignInFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TGetReportOffboardMaterials, TGetReportOffboardResponse } from '@/services/api/report/get-report-offboard';

// CONSTANTS

export enum EGetReportOffboardAction {
  GET_REPORT_OFFBOARD = 'GET_REPORT_OFFBOARD',
  GET_REPORT_OFFBOARD_REQUEST = 'GET_REPORT_OFFBOARD_REQUEST',
  GET_REPORT_OFFBOARD_SUCCESS = 'GET_REPORT_OFFBOARD_SUCCESS',
  GET_REPORT_OFFBOARD_FAILED = 'GET_REPORT_OFFBOARD_FAILED',
}

// TYPES

export type TGetReportOffboardRequest = {
  type: EGetReportOffboardAction.GET_REPORT_OFFBOARD_REQUEST;
  payload: {
    materials: TGetReportOffboardMaterials;
    successCallback?: (response: TGetReportOffboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportOffboardSuccess = {
  type: EGetReportOffboardAction.GET_REPORT_OFFBOARD_SUCCESS;
  payload: { response: TGetReportOffboardResponse };
};

export type TGetReportOffboardFailed = { type: EGetReportOffboardAction.GET_REPORT_OFFBOARD_FAILED };

// FUNCTION

export const getReportOffboardAction = {
  request: createActionCreator(
    EGetReportOffboardAction.GET_REPORT_OFFBOARD_REQUEST,
    (resolve) =>
      (
        materials: TGetReportOffboardMaterials,
        successCallback?: (response: TGetReportOffboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportOffboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportOffboardAction.GET_REPORT_OFFBOARD_SUCCESS,
    (resolve) =>
      (response: TGetReportOffboardResponse): TGetReportOffboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportOffboardAction.GET_REPORT_OFFBOARD_FAILED,
    (resolve) =>
      (error: unknown): TGetReportOffboardFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TDeleteBackgroundSettingParams = unknown;
export type TDeleteBackgroundSettingBody = unknown;

export type TDeleteBackgroundSettingMaterials = {
  params?: TDeleteBackgroundSettingParams;
  body?: TDeleteBackgroundSettingBody;
};

export type TDeleteBackgroundSettingResponse = unknown;

// FUNCTION

export const deleteBackgroundSetting = async ({
  params,
  body,
}: TDeleteBackgroundSettingMaterials): Promise<TDeleteBackgroundSettingResponse> => {
  const response = await ApiService.patch(`/setting/background/del`, body, { params });
  return response.data;
};

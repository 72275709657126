import { createActionCreator } from 'deox';

import {
  TGetReportEmployeeDataMaterials,
  TGetReportEmployeeDataResponse,
} from '@/services/api/report/get-report-employee-data';

// CONSTANTS

export enum EGetReportEmployeeDataAction {
  GET_REPORT_EMPLOYEE_DATA = 'GET_REPORT_EMPLOYEE_DATA',
  GET_REPORT_EMPLOYEE_DATA_REQUEST = 'GET_REPORT_EMPLOYEE_DATA_REQUEST',
  GET_REPORT_EMPLOYEE_DATA_SUCCESS = 'GET_REPORT_EMPLOYEE_DATA_SUCCESS',
  GET_REPORT_EMPLOYEE_DATA_FAILED = 'GET_REPORT_EMPLOYEE_DATA_FAILED',
}

// TYPES

export type TGetReportEmployeeDataRequest = {
  type: EGetReportEmployeeDataAction.GET_REPORT_EMPLOYEE_DATA_REQUEST;
  payload: {
    materials: TGetReportEmployeeDataMaterials;
    successCallback?: (response: TGetReportEmployeeDataResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportEmployeeDataSuccess = {
  type: EGetReportEmployeeDataAction.GET_REPORT_EMPLOYEE_DATA_SUCCESS;
  payload: { response: TGetReportEmployeeDataResponse };
};

export type TGetReportEmployeeDataFailed = { type: EGetReportEmployeeDataAction.GET_REPORT_EMPLOYEE_DATA_FAILED };

// FUNCTION

export const getReportEmployeeDataAction = {
  request: createActionCreator(
    EGetReportEmployeeDataAction.GET_REPORT_EMPLOYEE_DATA_REQUEST,
    (resolve) =>
      (
        materials: TGetReportEmployeeDataMaterials,
        successCallback?: (response: TGetReportEmployeeDataResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportEmployeeDataRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportEmployeeDataAction.GET_REPORT_EMPLOYEE_DATA_SUCCESS,
    (resolve) =>
      (response: TGetReportEmployeeDataResponse): TGetReportEmployeeDataSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportEmployeeDataAction.GET_REPORT_EMPLOYEE_DATA_FAILED,
    (resolve) =>
      (error: unknown): TGetReportEmployeeDataFailed =>
        resolve({ error }),
  ),
};

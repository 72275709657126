import { TBranch } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetBranchPaths = {
  id: string | number;
};
export type TGetBranchParams = unknown;

export type TGetBranchMaterials = {
  paths?: TGetBranchPaths;
  params?: TGetBranchParams;
};

export type TGetBranchResponse = TBranch;

// FUNCTION

export const getBranch = async ({ paths, params }: TGetBranchMaterials): Promise<TGetBranchResponse> => {
  const response = await ApiService.get(`/branch/${paths?.id}`, { params });
  return response.data;
};

import { all, takeLatest } from 'redux-saga/effects';

import {
  createTaskCategoryAction,
  createTaskAction,
  createTemplateAction,
  deleteTaskCategoryAction,
  deleteTaskAction,
  deleteTemplateAction,
  duplicateTaskAction,
  duplicateTemplateAction,
  getTaskCategoryAction,
  getTaskAction,
  getTasksCategoryAction,
  getTemplateAction,
  getTemplatesAction,
  updateTaskAction,
  updateTemplateAction,
} from '@/redux/actions';

import { createTaskCategorySaga } from './create-task-category';
import { createTaskSaga } from './create-task';
import { createTemplateSaga } from './create-template';
import { deleteTaskCategorySaga } from './delete-task-category';
import { deleteTaskSaga } from './delete-task';
import { deleteTemplateSaga } from './delete-template';
import { duplicateTaskSaga } from './duplicate-task';
import { duplicateTemplateSaga } from './duplicate-template';
import { getTaskCategorySaga } from './get-task-category';
import { getTaskSaga } from './get-task';
import { getTasksCategorySaga } from './get-tasks-category';
import { getTemplateSaga } from './get-template';
import { getTemplatesSaga } from './get-templates';
import { updateTaskSaga } from './update-task';
import { updateTemplateSaga } from './update-template';

export default function* root(): Generator {
  yield all([
    takeLatest(createTaskCategoryAction.request.type, createTaskCategorySaga),
    takeLatest(createTaskAction.request.type, createTaskSaga),
    takeLatest(createTemplateAction.request.type, createTemplateSaga),
    takeLatest(deleteTaskCategoryAction.request.type, deleteTaskCategorySaga),
    takeLatest(deleteTaskAction.request.type, deleteTaskSaga),
    takeLatest(deleteTemplateAction.request.type, deleteTemplateSaga),
    takeLatest(duplicateTaskAction.request.type, duplicateTaskSaga),
    takeLatest(duplicateTemplateAction.request.type, duplicateTemplateSaga),
    takeLatest(getTaskCategoryAction.request.type, getTaskCategorySaga),
    takeLatest(getTaskAction.request.type, getTaskSaga),
    takeLatest(getTasksCategoryAction.request.type, getTasksCategorySaga),
    takeLatest(getTemplateAction.request.type, getTemplateSaga),
    takeLatest(getTemplatesAction.request.type, getTemplatesSaga),
    takeLatest(updateTaskAction.request.type, updateTaskSaga),
    takeLatest(updateTemplateAction.request.type, updateTemplateSaga),
  ]);
}

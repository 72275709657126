import { createActionCreator } from 'deox';

import { TCreateRoleMaterials, TCreateRoleResponse } from '@/services/api/role/create-role';

// CONSTANTS

export enum ECreateRoleAction {
  CREATE_ROLE = 'CREATE_ROLE',
  CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILED = 'CREATE_ROLE_FAILED',
}

// TYPES

export type TCreateRoleRequest = {
  type: ECreateRoleAction.CREATE_ROLE_REQUEST;
  payload: {
    materials: TCreateRoleMaterials;
    successCallback?: (response: TCreateRoleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateRoleSuccess = {
  type: ECreateRoleAction.CREATE_ROLE_SUCCESS;
  payload: { response: TCreateRoleResponse };
};

export type TCreateRoleFailed = { type: ECreateRoleAction.CREATE_ROLE_FAILED };

// FUNCTION

export const createRoleAction = {
  request: createActionCreator(
    ECreateRoleAction.CREATE_ROLE_REQUEST,
    (resolve) =>
      (
        materials: TCreateRoleMaterials,
        successCallback?: (response: TCreateRoleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateRoleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateRoleAction.CREATE_ROLE_SUCCESS,
    (resolve) =>
      (response: TCreateRoleResponse): TCreateRoleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateRoleAction.CREATE_ROLE_FAILED,
    (resolve) =>
      (error: unknown): TCreateRoleFailed =>
        resolve({ error }),
  ),
};

import { all, takeLatest } from 'redux-saga/effects';

import {
  createRegulateCategoryAction,
  deleteRegulateCategoryAction,
  getRegulateCategoriesAction,
  updateRegulateCategoryAction,
} from '@/redux/actions';

import { createRegulateCategorySaga } from './create-regulate-category';
import { deleteRegulateCategorySaga } from './delete-regulate-category';
import { getRegulateCategoriesSaga } from './get-regulate-categories';
import { updateRegulateCategorySaga } from './update-regulate-category';

export default function* root(): Generator {
  yield all([
    takeLatest(createRegulateCategoryAction.request.type, createRegulateCategorySaga),
    takeLatest(deleteRegulateCategoryAction.request.type, deleteRegulateCategorySaga),
    takeLatest(getRegulateCategoriesAction.request.type, getRegulateCategoriesSaga),
    takeLatest(updateRegulateCategoryAction.request.type, updateRegulateCategorySaga),
  ]);
}

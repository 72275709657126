import ApiService from '@/services/api';

// TYPES

export type TAddApproverPaths = {
  typegroup: string | number;
};
export type TAddApproverParams = unknown;
export type TAddApproverBody = unknown;

export type TAddApproverMaterials = {
  paths?: TAddApproverPaths;
  params?: TAddApproverParams;
  body?: TAddApproverBody;
};

export type TAddApproverResponse = unknown;

// FUNCTION

export const addApprover = async ({ paths, params, body }: TAddApproverMaterials): Promise<TAddApproverResponse> => {
  const response = await ApiService.post(`/approver/${paths?.typegroup}/add-member`, body, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import {
  TDownloadTemplateUsersMaterials,
  TDownloadTemplateUsersResponse,
} from '@/services/api/user/download-template-users';

// CONSTANTS

export enum EDownloadTemplateUsersAction {
  DOWNLOAD_TEMPLATE_USERS = 'DOWNLOAD_TEMPLATE_USERS',
  DOWNLOAD_TEMPLATE_USERS_REQUEST = 'DOWNLOAD_TEMPLATE_USERS_REQUEST',
  DOWNLOAD_TEMPLATE_USERS_SUCCESS = 'DOWNLOAD_TEMPLATE_USERS_SUCCESS',
  DOWNLOAD_TEMPLATE_USERS_FAILED = 'DOWNLOAD_TEMPLATE_USERS_FAILED',
}

// TYPES

export type TDownloadTemplateUsersRequest = {
  type: EDownloadTemplateUsersAction.DOWNLOAD_TEMPLATE_USERS_REQUEST;
  payload: {
    materials: TDownloadTemplateUsersMaterials;
    successCallback?: (response: TDownloadTemplateUsersResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadTemplateUsersSuccess = {
  type: EDownloadTemplateUsersAction.DOWNLOAD_TEMPLATE_USERS_SUCCESS;
  payload: { response: TDownloadTemplateUsersResponse };
};

export type TDownloadTemplateUsersFailed = { type: EDownloadTemplateUsersAction.DOWNLOAD_TEMPLATE_USERS_FAILED };

// FUNCTION

export const downloadTemplateUsersAction = {
  request: createActionCreator(
    EDownloadTemplateUsersAction.DOWNLOAD_TEMPLATE_USERS_REQUEST,
    (resolve) =>
      (
        materials: TDownloadTemplateUsersMaterials,
        successCallback?: (response: TDownloadTemplateUsersResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadTemplateUsersRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadTemplateUsersAction.DOWNLOAD_TEMPLATE_USERS_SUCCESS,
    (resolve) =>
      (response: TDownloadTemplateUsersResponse): TDownloadTemplateUsersSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadTemplateUsersAction.DOWNLOAD_TEMPLATE_USERS_FAILED,
    (resolve) =>
      (error: unknown): TDownloadTemplateUsersFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TGetHolidaysMaterials, TGetHolidaysResponse } from '@/services/api/holiday/get-holidays';

// CONSTANTS

export enum EGetHolidaysAction {
  GET_HOLIDAYS = 'GET_HOLIDAYS',
  GET_HOLIDAYS_REQUEST = 'GET_HOLIDAYS_REQUEST',
  GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS',
  GET_HOLIDAYS_FAILED = 'GET_HOLIDAYS_FAILED',
}

// TYPES

export type TGetHolidaysRequest = {
  type: EGetHolidaysAction.GET_HOLIDAYS_REQUEST;
  payload: {
    materials: TGetHolidaysMaterials;
    successCallback?: (response: TGetHolidaysResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetHolidaysSuccess = {
  type: EGetHolidaysAction.GET_HOLIDAYS_SUCCESS;
  payload: { response: TGetHolidaysResponse };
};

export type TGetHolidaysFailed = { type: EGetHolidaysAction.GET_HOLIDAYS_FAILED };

// FUNCTION

export const getHolidaysAction = {
  request: createActionCreator(
    EGetHolidaysAction.GET_HOLIDAYS_REQUEST,
    (resolve) =>
      (
        materials: TGetHolidaysMaterials,
        successCallback?: (response: TGetHolidaysResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetHolidaysRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetHolidaysAction.GET_HOLIDAYS_SUCCESS,
    (resolve) =>
      (response: TGetHolidaysResponse): TGetHolidaysSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetHolidaysAction.GET_HOLIDAYS_FAILED,
    (resolve) =>
      (error: unknown): TGetHolidaysFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TGetTaskCategoryPaths = {
  id: string | number;
};
export type TGetTaskCategoryParams = unknown;

export type TGetTaskCategoryMaterials = {
  paths?: TGetTaskCategoryPaths;
  params?: TGetTaskCategoryParams;
};

export type TGetTaskCategoryResponse = unknown;

// FUNCTION

export const getTaskCategory = async ({
  paths,
  params,
}: TGetTaskCategoryMaterials): Promise<TGetTaskCategoryResponse> => {
  const response = await ApiService.get(`/template/task-category/${paths?.id}`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TGetHolidayMaterials, TGetHolidayResponse } from '@/services/api/holiday/get-holiday';

// CONSTANTS

export enum EGetHolidayAction {
  GET_HOLIDAY = 'GET_HOLIDAY',
  GET_HOLIDAY_REQUEST = 'GET_HOLIDAY_REQUEST',
  GET_HOLIDAY_SUCCESS = 'GET_HOLIDAY_SUCCESS',
  GET_HOLIDAY_FAILED = 'GET_HOLIDAY_FAILED',
}

// TYPES

export type TGetHolidayRequest = {
  type: EGetHolidayAction.GET_HOLIDAY_REQUEST;
  payload: {
    materials: TGetHolidayMaterials;
    successCallback?: (response: TGetHolidayResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetHolidaySuccess = {
  type: EGetHolidayAction.GET_HOLIDAY_SUCCESS;
  payload: { response?: TGetHolidayResponse };
};

export type TGetHolidayFailed = { type: EGetHolidayAction.GET_HOLIDAY_FAILED };

// FUNCTION

export const getHolidayAction = {
  request: createActionCreator(
    EGetHolidayAction.GET_HOLIDAY_REQUEST,
    (resolve) =>
      (
        materials: TGetHolidayMaterials,
        successCallback?: (response: TGetHolidayResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetHolidayRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetHolidayAction.GET_HOLIDAY_SUCCESS,
    (resolve) =>
      (response?: TGetHolidayResponse): TGetHolidaySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetHolidayAction.GET_HOLIDAY_FAILED,
    (resolve) =>
      (error: unknown): TGetHolidayFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { approveRequestAction } from '@/redux/actions';
import { approveRequest, TApproveRequestResponse } from '@/services/api';

// FUNCTION

export function* approveRequestSaga(action: ActionType<typeof approveRequestAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(approveRequest, materials);
    const approveRequestResponse: TApproveRequestResponse = response as TApproveRequestResponse;
    yield put(approveRequestAction.success(approveRequestResponse));
    successCallback?.(approveRequestResponse);
  } catch (err) {
    yield put(approveRequestAction.failure(err));
    failedCallback?.(err);
  }
}

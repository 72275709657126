import { createActionCreator } from 'deox';

import {
  TDeleteAssetCategoryMaterials,
  TDeleteAssetCategoryResponse,
} from '@/services/api/asset/delete-asset-category';

// CONSTANTS

export enum EDeleteAssetCategoryAction {
  DELETE_ASSET_CATEGORY = 'DELETE_ASSET_CATEGORY',
  DELETE_ASSET_CATEGORY_REQUEST = 'DELETE_ASSET_CATEGORY_REQUEST',
  DELETE_ASSET_CATEGORY_SUCCESS = 'DELETE_ASSET_CATEGORY_SUCCESS',
  DELETE_ASSET_CATEGORY_FAILED = 'DELETE_ASSET_CATEGORY_FAILED',
}

// TYPES

export type TDeleteAssetCategoryRequest = {
  type: EDeleteAssetCategoryAction.DELETE_ASSET_CATEGORY_REQUEST;
  payload: {
    materials: TDeleteAssetCategoryMaterials;
    successCallback?: (response: TDeleteAssetCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteAssetCategorySuccess = {
  type: EDeleteAssetCategoryAction.DELETE_ASSET_CATEGORY_SUCCESS;
  payload: { response: TDeleteAssetCategoryResponse };
};

export type TDeleteAssetCategoryFailed = { type: EDeleteAssetCategoryAction.DELETE_ASSET_CATEGORY_FAILED };

// FUNCTION

export const deleteAssetCategoryAction = {
  request: createActionCreator(
    EDeleteAssetCategoryAction.DELETE_ASSET_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TDeleteAssetCategoryMaterials,
        successCallback?: (response: TDeleteAssetCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteAssetCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteAssetCategoryAction.DELETE_ASSET_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TDeleteAssetCategoryResponse): TDeleteAssetCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteAssetCategoryAction.DELETE_ASSET_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TDeleteAssetCategoryFailed =>
        resolve({ error }),
  ),
};

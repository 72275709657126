import ApiService from '@/services/api';

// TYPES

export type TUpdateAssignOffboardBody = unknown;

export type TUpdateAssignOffboardMaterials = {
  body?: TUpdateAssignOffboardBody;
};

export type TUpdateAssignOffboardResponse = unknown;

// FUNCTION

export const updateAssignOffboard = async ({
  body,
}: TUpdateAssignOffboardMaterials): Promise<TUpdateAssignOffboardResponse> => {
  const response = await ApiService.patch(`/checklist/assign/offboard`, body);
  return response.data;
};

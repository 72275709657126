export enum EPermisisonManage {
  SETTINGS = 'SETTINGS',
  REQUESTS = 'REQUESTS',
  REPORTS = 'REPORTS',
  REGULATIONS = 'REGULATIONS',
  CHECKLISTS = 'CHECKLISTS',
  ASSETS = 'ASSETS',
  EMPLOYEES = 'EMPLOYEES',
  SELF_SERVICE = 'SELF_SERVICE',
  OTHER = 'OTHER',
}

export enum EFeatureManage {
  // Settings
  COMPANY_INFO = 'COMPANY_INFO',
  APPROVALS = 'APPROVALS',
  TIME_OFFS = 'TIME_OFFS',
  HOLIDAYS = 'HOLIDAYS',
  BENEFITS = 'BENEFITS',
  USERS_ACCESS = 'USERS_ACCESS',
  PERMISSIONS = 'PERMISSIONS',
  CHECKLISTS = 'CHECKLISTS',
  // Requests
  MANAGER_REQUESTS = 'MANAGER_REQUESTS',
  MAKE_REQUEST_BEHALF = 'MAKE_REQUEST_BEHALF',
  // Reports
  MANAGER_REPORTS = 'MANAGER_REPORTS',
  // Regulations
  COMPANY_REGULATIONS = 'COMPANY_REGULATIONS',
  // Checklists
  TASKS_CHECKLIST = 'TASKS_CHECKLIST',
  ONBOARD = 'ONBOARD',
  OFFBOARD = 'OFFBOARD',
  NEW_TASK = 'NEW_TASK',
  NEW_CATEGORY = 'NEW_CATEGORY',
  // Assets
  ASSETS = 'ASSETS',
  // Employees
  EMPLOYEES_VIEW_2ND = 'EMPLOYEES_VIEW_2ND',
  EMPLOYEES_PERSONAL_INFO = 'EMPLOYEES_PERSONAL_INFO',
  EMPLOYEES_CAREER = 'EMPLOYEES_CAREER',
  EMPLOYEES_TIME_OFFS = 'EMPLOYEES_TIME_OFFS',
  EMPLOYEES_ADJUST_TIME_OFF_BALANCE = 'EMPLOYEES_ADJUST_TIME_OFF_BALANCE',
  EMPLOYEES_ASSETS = 'EMPLOYEES_ASSETS',
  EMPLOYEES_BENEFITS = 'EMPLOYEES_BENEFITS',
  EMPLOYEES_EDIT = 'EMPLOYEES_EDIT',
  EMPLOYEES_DOWNLOAD = 'EMPLOYEES_DOWNLOAD',
  EMPLOYEES_NEW = 'EMPLOYEES_NEW',
  // Self service
  SELF_SERVICE_REQUEST_RESIGN = 'SELF_SERVICE_REQUEST_RESIGN',
  SELF_SERVICE_REQUEST_CONTRACT_TERMINATION = 'SELF_SERVICE_REQUEST_CONTRACT_TERMINATION',
  SELF_SERVICE_REQUEST_PROBATION_TERMINATION = 'SELF_SERVICE_REQUEST_PROBATION_TERMINATION',
  SELF_SERVICE_ADJUST_TIME_OFF_BALANCE = 'SELF_SERVICE_ADJUST_TIME_OFF_BALANCE',
  // Other
  QUICK_ACTION = 'QUICK_ACTION',
  ALL_SELF_INFORMATION = 'ALL_SELF_INFORMATION',
}

import ApiService from '@/services/api';

// TYPES

export type TDonwloadReportTimeOffScheduleParams = unknown;
export type TDonwloadReportTimeOffScheduleBody = unknown;

export type TDonwloadReportTimeOffScheduleMaterials = {
  params?: TDonwloadReportTimeOffScheduleParams;
  body?: TDonwloadReportTimeOffScheduleBody;
};

export type TDonwloadReportTimeOffScheduleResponse = string;

// FUNCTION

export const donwloadReportTimeOffSchedule = async ({
  params,
  body,
}: TDonwloadReportTimeOffScheduleMaterials): Promise<TDonwloadReportTimeOffScheduleResponse> => {
  const response = await ApiService.post(`/report/time-off-schedule`, body, { params });
  return response.data;
};

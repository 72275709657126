import { all, fork } from 'redux-saga/effects';

import approverSaga from './approver';
import assetSaga from './asset';
import authSaga from './auth';
import benefitSaga from './benefit';
import branchSaga from './branch';
import checklistSaga from './checklist';
import companySaga from './company';
import dashboardSaga from './dashboard';
import departmentSaga from './department';
import globalSaga from './global';
import holidaySaga from './holiday';
import jobSaga from './job';
import mailSaga from './mail';
import regulateCategorySaga from './regulate-category';
import regulationSaga from './regulation';
import reportSaga from './report';
import reportViolationSaga from './report-violation';
import requestSaga from './request';
import roleSaga from './role';
import settingSaga from './setting';
import templateSaga from './template';
import timeOffTypeSaga from './time-off-type';
import typeWorkSaga from './type-work';
import uploadSaga from './upload';
import userSaga from './user';
import widenSaga from './widen';

const rootSaga = function* root(): Generator {
  yield all([
    fork(approverSaga),
    fork(assetSaga),
    fork(authSaga),
    fork(benefitSaga),
    fork(branchSaga),
    fork(checklistSaga),
    fork(companySaga),
    fork(dashboardSaga),
    fork(departmentSaga),
    fork(globalSaga),
    fork(holidaySaga),
    fork(jobSaga),
    fork(mailSaga),
    fork(regulateCategorySaga),
    fork(regulationSaga),
    fork(reportSaga),
    fork(reportViolationSaga),
    fork(requestSaga),
    fork(roleSaga),
    fork(settingSaga),
    fork(templateSaga),
    fork(timeOffTypeSaga),
    fork(typeWorkSaga),
    fork(uploadSaga),
    fork(userSaga),
    fork(widenSaga),
  ]);
};

export default rootSaga;

import { ETypeRequest } from '@/common/enums';
import { TRequest } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetRecentTimeOffRequestsParams = {
  page: number;
  pageSize: number;
  year?: string | number;
  typeReq?: string;
  status?: string;
};

export type TGetRecentTimeOffRequestsMaterials = {
  params?: TGetRecentTimeOffRequestsParams;
};

export type TGetRecentTimeOffRequestsResponse = TPaginateResponse & {
  data: TRequest[];
  subTotal: { [key: string]: any };
  total: { [key: string]: number };
};

// FUNCTION

export const getRecentTimeOffRequests = async ({
  params,
}: TGetRecentTimeOffRequestsMaterials): Promise<TGetRecentTimeOffRequestsResponse> => {
  const response = await ApiService.get(`/request/management/my-requests`, {
    params: { ...params, typeReq: ETypeRequest.TIME_OFF_REQUESTS },
  });
  return response.data;
};

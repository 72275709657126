import ApiService from '@/services/api';

// TYPES

export type TReviewOnboardPaths = {
  id: string | number;
};
export type TReviewOnboardParams = unknown;
export type TReviewOnboardBody = unknown;

export type TReviewOnboardMaterials = {
  paths?: TReviewOnboardPaths;
  params?: TReviewOnboardParams;
  body?: TReviewOnboardBody;
};

export type TReviewOnboardResponse = unknown;

// FUNCTION

export const reviewOnboard = async ({
  paths,
  params,
  body,
}: TReviewOnboardMaterials): Promise<TReviewOnboardResponse> => {
  const response = await ApiService.post(`/request/review/onboard/${paths?.id}`, body, { params });
  return response.data;
};

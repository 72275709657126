import { createActionCreator } from 'deox';

import { TUpdateHolidayMaterials, TUpdateHolidayResponse } from '@/services/api/holiday/update-holiday';

// CONSTANTS

export enum EUpdateHolidayAction {
  UPDATE_HOLIDAY = 'UPDATE_HOLIDAY',
  UPDATE_HOLIDAY_REQUEST = 'UPDATE_HOLIDAY_REQUEST',
  UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS',
  UPDATE_HOLIDAY_FAILED = 'UPDATE_HOLIDAY_FAILED',
}

// TYPES

export type TUpdateHolidayRequest = {
  type: EUpdateHolidayAction.UPDATE_HOLIDAY_REQUEST;
  payload: {
    materials: TUpdateHolidayMaterials;
    successCallback?: (response: TUpdateHolidayResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateHolidaySuccess = {
  type: EUpdateHolidayAction.UPDATE_HOLIDAY_SUCCESS;
  payload: { response: TUpdateHolidayResponse };
};

export type TUpdateHolidayFailed = { type: EUpdateHolidayAction.UPDATE_HOLIDAY_FAILED };

// FUNCTION

export const updateHolidayAction = {
  request: createActionCreator(
    EUpdateHolidayAction.UPDATE_HOLIDAY_REQUEST,
    (resolve) =>
      (
        materials: TUpdateHolidayMaterials,
        successCallback?: (response: TUpdateHolidayResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateHolidayRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateHolidayAction.UPDATE_HOLIDAY_SUCCESS,
    (resolve) =>
      (response: TUpdateHolidayResponse): TUpdateHolidaySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateHolidayAction.UPDATE_HOLIDAY_FAILED,
    (resolve) =>
      (error: unknown): TUpdateHolidayFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TDownloadPeopleListParams = unknown;
export type TDownloadPeopleListBody = unknown;

export type TDownloadPeopleListMaterials = {
  params?: TDownloadPeopleListParams;
  body?: TDownloadPeopleListBody;
};

export type TDownloadPeopleListResponse = unknown;

// FUNCTION

export const downloadPeopleList = async ({
  params,
  body,
}: TDownloadPeopleListMaterials): Promise<TDownloadPeopleListResponse> => {
  const response = await ApiService.post(`/user/download-people-list`, body, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { resignRequestAction } from '@/redux/actions';
import { resignRequest, TResignRequestResponse } from '@/services/api';

// FUNCTION

export function* resignRequestSaga(action: ActionType<typeof resignRequestAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(resignRequest, materials);
    const resignRequestResponse: TResignRequestResponse = response as TResignRequestResponse;
    yield put(resignRequestAction.success(resignRequestResponse));
    successCallback?.(resignRequestResponse);
  } catch (err) {
    yield put(resignRequestAction.failure(err));
    failedCallback?.(err);
  }
}

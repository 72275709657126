import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { io } from 'socket.io-client';
import classNames from 'classnames';

import env from '@/env';
import Header from '@/containers/Header';
import {
  getAssetCategoriesAction,
  getBanksAction,
  getBenefitCoveragesAction,
  getBranchesAction,
  getCompanyAction,
  getDepartmentsAction,
  getIconTimeOffAction,
  getJobsAction,
  getMyInfoAction,
  getMyTimeOffTypesAction,
  getNationalsAction,
  getRegulateCategoriesAction,
  getRequestNotificationsAction,
  getRolesAction,
  getSettingsAction,
  getTasksCategoryAction,
  getTimeOffsAction,
  getTypesWorkAction,
  uiActions,
} from '@/redux/actions';
import { TRootState } from '@/redux/reducers';
import { defaultCallCommonOptionsParams, localeName } from '@/common/constants';
import { EAccountStatus, ELanguage, EUserStatus } from '@/common/enums';
import { LayoutPaths, Paths } from '@/pages/routers';
import Helpers from '@/services/helpers';
import { checkPermissionModule } from '@/utils/functions';
import {
  EFeatureManage,
  EPermisisonManage,
} from '@/pages/Settings/Permissions/PermissionsDetail/PermissionsDetail.enums';

import { TAdminProps } from './Admin.types';
import './Admin.scss';

const Admin: React.FC<TAdminProps> = ({ children }) => {
  const dispatch = useDispatch();
  const atk = Helpers.getAccessToken();

  const myInfoState = useSelector((state: TRootState) => state.userReducer.getMyInfoResponse);
  const settingsState = useSelector((state: TRootState) => state.settingReducer.getSettingsResponse);
  const backgroundImageState = useSelector((state: TRootState) => state.uiReducer.backgroundImage);
  const socketState = useSelector((state: TRootState) => state.uiReducer.socket);

  const isShowBackground = !!backgroundImageState && backgroundImageState !== 'undefined';

  const [protectScreenVisible, setProtectScreenVisible] = useState<boolean>(false);

  const getMyInfo = useCallback(() => {
    dispatch(getMyInfoAction.request({}));
  }, [dispatch]);

  const getTypesWork = useCallback(() => {
    dispatch(
      getTypesWorkAction.request({ params: { ...defaultCallCommonOptionsParams, status: EAccountStatus.ACTIVE } }),
    );
  }, [dispatch]);

  const getJobs = useCallback(() => {
    dispatch(getJobsAction.request({ params: { ...defaultCallCommonOptionsParams, status: EAccountStatus.ACTIVE } }));
  }, [dispatch]);

  const getBranches = useCallback(() => {
    if (myInfoState) {
      const permisison = checkPermissionModule(myInfoState, EPermisisonManage.SETTINGS, EFeatureManage.COMPANY_INFO);
      if (permisison?.canView) {
        dispatch(getBranchesAction.request({ params: defaultCallCommonOptionsParams }));
      }
    }
  }, [myInfoState, dispatch]);

  const getDepartments = useCallback(() => {
    dispatch(getDepartmentsAction.request({ params: defaultCallCommonOptionsParams }));
  }, [dispatch]);

  const getRegulateCategories = useCallback(() => {
    if (myInfoState) {
      const permisison = checkPermissionModule(
        myInfoState,
        EPermisisonManage.REGULATIONS,
        EFeatureManage.COMPANY_REGULATIONS,
      );
      if (permisison?.canView) {
        dispatch(getRegulateCategoriesAction.request({ params: defaultCallCommonOptionsParams }));
      }
    }
  }, [myInfoState, dispatch]);

  const getRoles = useCallback(() => {
    if (myInfoState) {
      const permisison = checkPermissionModule(myInfoState, EPermisisonManage.SETTINGS, EFeatureManage.PERMISSIONS);
      if (permisison?.canView) {
        dispatch(getRolesAction.request({ params: defaultCallCommonOptionsParams }));
      }
    }
  }, [myInfoState, dispatch]);

  const getSettings = useCallback(() => {
    dispatch(getSettingsAction.request({}));
  }, [dispatch]);

  const getCompany = useCallback(() => {
    if (myInfoState) {
      const permisison = checkPermissionModule(myInfoState, EPermisisonManage.SETTINGS, EFeatureManage.COMPANY_INFO);
      if (permisison?.canView) {
        dispatch(getCompanyAction.request({}));
      }
    }
  }, [myInfoState, dispatch]);

  const getAssetCategories = useCallback(() => {
    dispatch(getAssetCategoriesAction.request({ params: defaultCallCommonOptionsParams }));
  }, [dispatch]);

  const getBenefitCoverages = useCallback(() => {
    if (myInfoState) {
      const permisison = checkPermissionModule(myInfoState, EPermisisonManage.SETTINGS, EFeatureManage.BENEFITS);
      if (permisison?.canUpdate) {
        dispatch(getBenefitCoveragesAction.request({ params: defaultCallCommonOptionsParams }));
      }
    }
  }, [myInfoState, dispatch]);

  const getBanks = useCallback(() => {
    dispatch(getBanksAction.request({}));
  }, [dispatch]);

  const getNationals = useCallback(() => {
    dispatch(getNationalsAction.request({}));
  }, [dispatch]);

  const getTasksCategory = useCallback(() => {
    if (myInfoState) {
      const permisison = checkPermissionModule(myInfoState, EPermisisonManage.CHECKLISTS, EFeatureManage.NEW_CATEGORY);
      if (permisison?.canUpdate) {
        dispatch(getTasksCategoryAction.request({ params: defaultCallCommonOptionsParams }));
      }
    }
  }, [myInfoState, dispatch]);

  const getIconTimeOff = useCallback(() => {
    dispatch(getIconTimeOffAction.request({}));
  }, [dispatch]);

  const getTimeOffTypes = useCallback(() => {
    dispatch(getTimeOffsAction.request({ params: defaultCallCommonOptionsParams }));
  }, [dispatch]);

  const getMyTimeOffsType = useCallback(() => {
    dispatch(getMyTimeOffTypesAction.request({}));
  }, [dispatch]);

  const getRequestNotifications = useCallback(() => {
    dispatch(getRequestNotificationsAction.request({}));
  }, [dispatch]);

  useEffect(() => {
    getRequestNotifications();
  }, [getRequestNotifications]);

  useEffect(() => {
    getMyTimeOffsType();
  }, [getMyTimeOffsType]);

  useEffect(() => {
    getTimeOffTypes();
  }, [getTimeOffTypes]);

  useEffect(() => {
    getIconTimeOff();
  }, [getIconTimeOff]);

  useEffect(() => {
    getTasksCategory();
  }, [getTasksCategory]);

  useEffect(() => {
    getNationals();
  }, [getNationals]);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  useEffect(() => {
    getBenefitCoverages();
  }, [getBenefitCoverages]);

  useEffect(() => {
    getAssetCategories();
  }, [getAssetCategories]);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    getJobs();
  }, [getJobs]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    getBranches();
  }, [getBranches]);

  useEffect(() => {
    getTypesWork();
  }, [getTypesWork]);

  useEffect(() => {
    getRegulateCategories();
  }, [getRegulateCategories]);

  useEffect(() => {
    getMyInfo();
  }, [getMyInfo]);

  useEffect(() => {
    if (myInfoState) {
      switch (myInfoState.userStatus) {
        case EUserStatus.ON_BOARDING:
          navigate(LayoutPaths.Welcome);
          break;
        case EUserStatus.RESIGNED:
          navigate(Paths.AccessDenied);
          break;
        default:
          break;
      }
    }
  }, [myInfoState]);

  useEffect(() => {
    if (settingsState) {
      dispatch(uiActions.setBackgroundImage(settingsState.backgroundImage));
      dispatch(uiActions.setLanguage(localeName[settingsState?.language as unknown as ELanguage]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, settingsState]);

  useEffect(() => {
    const socket = io(env.api.baseUrl.service, {
      extraHeaders: {
        'Authorization': `Bearer ${atk}`,
      },
    });
    dispatch(uiActions.initialSocket(socket));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socketState) {
      socketState.on('ONLINES', (response): void => {
        const usersOnline = response?.data?.onlines || [];
        dispatch(uiActions.setUsersOnline(usersOnline));
      });
    }

    return (): void => {
      socketState?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketState]);

  return myInfoState && settingsState ? (
    <div className={classNames('Admin', { 'protect-screen': protectScreenVisible })}>
      <div
        className="Admin-protect-screen-btn"
        onMouseEnter={(): void => setProtectScreenVisible(!protectScreenVisible)}
      />
      <div className="Admin-header">
        <Header haveBackground={isShowBackground} />
      </div>
      <div className="Admin-background">{isShowBackground && <img src={backgroundImageState} alt="" />}</div>

      <div className="Admin-body" style={{ opacity: isShowBackground ? 0.97 : 1 }}>
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Admin;

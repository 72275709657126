import { TRequest } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportTimeOffScheduleParams = {
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number;
  timeOffType?: string;
  employType?: string;
  department?: string;
  job?: string;
  branch?: string;
  status?: string;
  sort?: string;
};

export type TGetReportTimeOffScheduleMaterials = {
  params?: TGetReportTimeOffScheduleParams;
};

export type TGetReportTimeOffScheduleResponse = TPaginateResponse & {
  data: TRequest[];
};

// FUNCTION

export const getReportTimeOffSchedule = async ({
  params,
}: TGetReportTimeOffScheduleMaterials): Promise<TGetReportTimeOffScheduleResponse> => {
  const response = await ApiService.get(`/report/time-off-schedule`, { params });
  return response.data;
};

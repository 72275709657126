import { TTaskMisison } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetChecklistTasksParams = {
  search?: string;
  iAssign?: boolean;
  taskAssignTo?: string;
  taskChecklist?: string;
  dueDateStart?: string;
  dueDateEnd?: string;
  taskCategories?: string;
  taskStatus?: string;
  taskPriorities?: string;
};

export type TGetChecklistTasksMaterials = {
  params?: TGetChecklistTasksParams;
};

export type TGetChecklistTasksResponse = TTaskMisison[];

// FUNCTION

export const getChecklistTasks = async ({
  params,
}: TGetChecklistTasksMaterials): Promise<TGetChecklistTasksResponse> => {
  const response = await ApiService.get(`/checklist/task/list`, { params });
  return response.data;
};

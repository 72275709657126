import { createActionCreator } from 'deox';

import { TGetBanksMaterials, TGetBanksResponse } from '@/services/api/widen/get-banks';

// CONSTANTS

export enum EGetBanksAction {
  GET_BANKS = 'GET_BANKS',
  GET_BANKS_REQUEST = 'GET_BANKS_REQUEST',
  GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS',
  GET_BANKS_FAILED = 'GET_BANKS_FAILED',
}

// TYPES

export type TGetBanksRequest = {
  type: EGetBanksAction.GET_BANKS_REQUEST;
  payload: {
    materials: TGetBanksMaterials;
    successCallback?: (response: TGetBanksResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBanksSuccess = {
  type: EGetBanksAction.GET_BANKS_SUCCESS;
  payload: { response: TGetBanksResponse };
};

export type TGetBanksFailed = { type: EGetBanksAction.GET_BANKS_FAILED };

// FUNCTION

export const getBanksAction = {
  request: createActionCreator(
    EGetBanksAction.GET_BANKS_REQUEST,
    (resolve) =>
      (
        materials: TGetBanksMaterials,
        successCallback?: (response: TGetBanksResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBanksRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetBanksAction.GET_BANKS_SUCCESS,
    (resolve) =>
      (response: TGetBanksResponse): TGetBanksSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetBanksAction.GET_BANKS_FAILED,
    (resolve) =>
      (error: unknown): TGetBanksFailed =>
        resolve({ error }),
  ),
};

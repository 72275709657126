import { createActionCreator } from 'deox';

import { TGetBranchMaterials, TGetBranchResponse } from '@/services/api/branch/get-branch';

// CONSTANTS

export enum EGetBranchAction {
  GET_BRANCH = 'GET_BRANCH',
  GET_BRANCH_REQUEST = 'GET_BRANCH_REQUEST',
  GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS',
  GET_BRANCH_FAILED = 'GET_BRANCH_FAILED',
}

// TYPES

export type TGetBranchRequest = {
  type: EGetBranchAction.GET_BRANCH_REQUEST;
  payload: {
    materials: TGetBranchMaterials;
    successCallback?: (response: TGetBranchResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBranchSuccess = {
  type: EGetBranchAction.GET_BRANCH_SUCCESS;
  payload: { response?: TGetBranchResponse };
};

export type TGetBranchFailed = { type: EGetBranchAction.GET_BRANCH_FAILED };

// FUNCTION

export const getBranchAction = {
  request: createActionCreator(
    EGetBranchAction.GET_BRANCH_REQUEST,
    (resolve) =>
      (
        materials: TGetBranchMaterials,
        successCallback?: (response: TGetBranchResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBranchRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetBranchAction.GET_BRANCH_SUCCESS,
    (resolve) =>
      (response?: TGetBranchResponse): TGetBranchSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetBranchAction.GET_BRANCH_FAILED,
    (resolve) =>
      (error: unknown): TGetBranchFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TUpdateMyInfoMaterials, TUpdateMyInfoResponse } from '@/services/api/user/update-my-info';

// CONSTANTS

export enum EUpdateMyInfoAction {
  UPDATE_MY_INFO = 'UPDATE_MY_INFO',
  UPDATE_MY_INFO_REQUEST = 'UPDATE_MY_INFO_REQUEST',
  UPDATE_MY_INFO_SUCCESS = 'UPDATE_MY_INFO_SUCCESS',
  UPDATE_MY_INFO_FAILED = 'UPDATE_MY_INFO_FAILED',
}

// TYPES

export type TUpdateMyInfoRequest = {
  type: EUpdateMyInfoAction.UPDATE_MY_INFO_REQUEST;
  payload: {
    materials: TUpdateMyInfoMaterials;
    successCallback?: (response: TUpdateMyInfoResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateMyInfoSuccess = {
  type: EUpdateMyInfoAction.UPDATE_MY_INFO_SUCCESS;
  payload: { response: TUpdateMyInfoResponse };
};

export type TUpdateMyInfoFailed = { type: EUpdateMyInfoAction.UPDATE_MY_INFO_FAILED };

// FUNCTION

export const updateMyInfoAction = {
  request: createActionCreator(
    EUpdateMyInfoAction.UPDATE_MY_INFO_REQUEST,
    (resolve) =>
      (
        materials: TUpdateMyInfoMaterials,
        successCallback?: (response: TUpdateMyInfoResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateMyInfoRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateMyInfoAction.UPDATE_MY_INFO_SUCCESS,
    (resolve) =>
      (response: TUpdateMyInfoResponse): TUpdateMyInfoSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateMyInfoAction.UPDATE_MY_INFO_FAILED,
    (resolve) =>
      (error: unknown): TUpdateMyInfoFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TGetTemplatesMaterials, TGetTemplatesResponse } from '@/services/api/template/get-templates';

// CONSTANTS

export enum EGetTemplatesAction {
  GET_TEMPLATES = 'GET_TEMPLATES',
  GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_FAILED = 'GET_TEMPLATES_FAILED',
}

// TYPES

export type TGetTemplatesRequest = {
  type: EGetTemplatesAction.GET_TEMPLATES_REQUEST;
  payload: {
    materials: TGetTemplatesMaterials;
    successCallback?: (response: TGetTemplatesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTemplatesSuccess = {
  type: EGetTemplatesAction.GET_TEMPLATES_SUCCESS;
  payload: { response: TGetTemplatesResponse };
};

export type TGetTemplatesFailed = { type: EGetTemplatesAction.GET_TEMPLATES_FAILED };

// FUNCTION

export const getTemplatesAction = {
  request: createActionCreator(
    EGetTemplatesAction.GET_TEMPLATES_REQUEST,
    (resolve) =>
      (
        materials: TGetTemplatesMaterials,
        successCallback?: (response: TGetTemplatesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTemplatesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTemplatesAction.GET_TEMPLATES_SUCCESS,
    (resolve) =>
      (response: TGetTemplatesResponse): TGetTemplatesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTemplatesAction.GET_TEMPLATES_FAILED,
    (resolve) =>
      (error: unknown): TGetTemplatesFailed =>
        resolve({ error }),
  ),
};

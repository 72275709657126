import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateRegulateCategoryAction } from '@/redux/actions';
import { updateRegulateCategory, TUpdateRegulateCategoryResponse } from '@/services/api';

// FUNCTION

export function* updateRegulateCategorySaga(
  action: ActionType<typeof updateRegulateCategoryAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateRegulateCategory, materials);
    const updateRegulateCategoryResponse: TUpdateRegulateCategoryResponse = response as TUpdateRegulateCategoryResponse;
    yield put(updateRegulateCategoryAction.success(updateRegulateCategoryResponse));
    successCallback?.(updateRegulateCategoryResponse);
  } catch (err) {
    yield put(updateRegulateCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

import { Socket } from 'socket.io-client';

import { createReducer } from 'deox';
import { uiActions } from '@/redux/actions';
import { localeName } from '@/common/constants';

export enum EDeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export type TInitialState = {
  device: {
    type: EDeviceType;
    width: number;
  };
  language: string;
  backgroundImage: string;
  socket?: Socket;
  usersOnline: string[];
};

const initialState: TInitialState = {
  device: {
    type: window.innerWidth > 991 ? EDeviceType.DESKTOP : EDeviceType.MOBILE,
    width: window.innerWidth,
  },
  language: localStorage.getItem('language') || localeName.EN,
  backgroundImage: '',
  socket: undefined,
  usersOnline: [],
};

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(uiActions.setDevice, (state, { payload }) => ({
    ...state,
    device: {
      type: payload.deviceWidth > 991 ? EDeviceType.DESKTOP : EDeviceType.MOBILE,
      width: payload.deviceWidth,
    },
  })),
  handleAction(uiActions.setLanguage, (state, { payload }) => {
    localStorage.setItem('language', payload.language);

    return {
      ...state,
      language: payload.language || localeName.EN,
    };
  }),
  handleAction(uiActions.setBackgroundImage, (state, { payload }) => {
    return {
      ...state,
      backgroundImage: payload?.backgroundImage,
    };
  }),
  handleAction(uiActions.initialSocket, (state, { payload }) => {
    return {
      ...state,
      socket: payload?.socket,
    };
  }),
  handleAction(uiActions.setUsersOnline, (state, { payload }) => {
    return {
      ...state,
      usersOnline: payload?.usersOnline,
    };
  }),
]);

export default reducer;

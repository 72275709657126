import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { assignOffboardAction } from '@/redux/actions';
import { assignOffboard, TAssignOffboardResponse } from '@/services/api';

// FUNCTION

export function* assignOffboardSaga(action: ActionType<typeof assignOffboardAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(assignOffboard, materials);
    const assignOffboardResponse: TAssignOffboardResponse = response as TAssignOffboardResponse;
    yield put(assignOffboardAction.success(assignOffboardResponse));
    successCallback?.(assignOffboardResponse);
  } catch (err) {
    yield put(assignOffboardAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TSeenNotificationPaths = {
  id: string | number;
};
export type TSeenNotificationParams = unknown;

export type TSeenNotificationMaterials = {
  paths?: TSeenNotificationPaths;
  params?: TSeenNotificationParams;
};

export type TSeenNotificationResponse = unknown;

// FUNCTION

export const seenNotification = async ({
  paths,
  params,
}: TSeenNotificationMaterials): Promise<TSeenNotificationResponse> => {
  const response = await ApiService.get(`/dashboard/notification/${paths?.id}`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TUploadFilesMaterials, TUploadFilesResponse } from '@/services/api/upload/upload-files';

// CONSTANTS

export enum EUploadFilesAction {
  UPLOAD_FILES = 'UPLOAD_FILES',
  UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST',
  UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAILED = 'UPLOAD_FILES_FAILED',
}

// TYPES

export type TUploadFilesRequest = {
  type: EUploadFilesAction.UPLOAD_FILES_REQUEST;
  payload: {
    materials: TUploadFilesMaterials;
    successCallback?: (response: TUploadFilesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUploadFilesSuccess = {
  type: EUploadFilesAction.UPLOAD_FILES_SUCCESS;
  payload: { response: TUploadFilesResponse };
};

export type TUploadFilesFailed = { type: EUploadFilesAction.UPLOAD_FILES_FAILED };

// FUNCTION

export const uploadFilesAction = {
  request: createActionCreator(
    EUploadFilesAction.UPLOAD_FILES_REQUEST,
    (resolve) =>
      (
        materials: TUploadFilesMaterials,
        successCallback?: (response: TUploadFilesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUploadFilesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUploadFilesAction.UPLOAD_FILES_SUCCESS,
    (resolve) =>
      (response: TUploadFilesResponse): TUploadFilesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUploadFilesAction.UPLOAD_FILES_FAILED,
    (resolve) =>
      (error: unknown): TUploadFilesFailed =>
        resolve({ error }),
  ),
};

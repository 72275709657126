import { TBenefitState } from '@/redux/reducers/benefit';
import { TCreateBenefitCoverageSuccess } from '@/redux/actions/benefit';

export const createBenefitCoverageUpdateState = (
  state: TBenefitState,
  action: TCreateBenefitCoverageSuccess,
): TBenefitState => ({
  ...state,
  createBenefitCoverageResponse: action.payload.response,
});

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteJobAction } from '@/redux/actions';
import { deleteJob, TDeleteJobResponse } from '@/services/api';

// FUNCTION

export function* deleteJobSaga(action: ActionType<typeof deleteJobAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteJob, materials);
    const deleteJobResponse: TDeleteJobResponse = response as TDeleteJobResponse;
    yield put(deleteJobAction.success(deleteJobResponse));
    successCallback?.(deleteJobResponse);
  } catch (err) {
    yield put(deleteJobAction.failure(err));
    failedCallback?.(err);
  }
}

import { TSettingState } from '@/redux/reducers/setting';
import { TDeleteBackgroundSettingSuccess } from '@/redux/actions/setting';

export const deleteBackgroundSettingUpdateState = (
  state: TSettingState,
  action: TDeleteBackgroundSettingSuccess,
): TSettingState => ({
  ...state,
  deleteBackgroundSettingResponse: action.payload.response,
});

import ApiService from '@/services/api';

// TYPES

export type TDownloadReportHeadcountParams = unknown;
export type TDownloadReportHeadcountBody = unknown;

export type TDownloadReportHeadcountMaterials = {
  params?: TDownloadReportHeadcountParams;
  body?: TDownloadReportHeadcountBody;
};

export type TDownloadReportHeadcountResponse = string;

// FUNCTION

export const downloadReportHeadcount = async ({
  params,
  body,
}: TDownloadReportHeadcountMaterials): Promise<TDownloadReportHeadcountResponse> => {
  const response = await ApiService.post(`/report/headcount`, body, { params });
  return response.data;
};

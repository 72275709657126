import { all, takeLatest } from 'redux-saga/effects';

import {
  assignOffboardAction,
  assignOnboardAction,
  changeTaskStatusAction,
  getChecklistTaskOnboardAction,
  getChecklistTasksAction,
  updateAssignOffboardAction,
  updateAssignOnboardAction,
} from '@/redux/actions';

import { assignOffboardSaga } from './assign-offboard';
import { assignOnboardSaga } from './assign-onboard';
import { changeTaskStatusSaga } from './change-task-status';
import { getChecklistTaskOnboardSaga } from './get-checklist-task-onboard';
import { getChecklistTasksSaga } from './get-checklist-tasks';
import { updateAssignOffboardSaga } from './update-assign-offboard';
import { updateAssignOnboardSaga } from './update-assign-onboard';

export default function* root(): Generator {
  yield all([
    takeLatest(assignOffboardAction.request.type, assignOffboardSaga),
    takeLatest(assignOnboardAction.request.type, assignOnboardSaga),
    takeLatest(changeTaskStatusAction.request.type, changeTaskStatusSaga),
    takeLatest(getChecklistTaskOnboardAction.request.type, getChecklistTaskOnboardSaga),
    takeLatest(getChecklistTasksAction.request.type, getChecklistTasksSaga),
    takeLatest(updateAssignOffboardAction.request.type, updateAssignOffboardSaga),
    takeLatest(updateAssignOnboardAction.request.type, updateAssignOnboardSaga),
  ]);
}

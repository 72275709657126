import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { requestTimeOffAction } from '@/redux/actions';
import { requestTimeOff, TRequestTimeOffResponse } from '@/services/api';

// FUNCTION

export function* requestTimeOffSaga(action: ActionType<typeof requestTimeOffAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(requestTimeOff, materials);
    const requestTimeOffResponse: TRequestTimeOffResponse = response as TRequestTimeOffResponse;
    yield put(requestTimeOffAction.success(requestTimeOffResponse));
    successCallback?.(requestTimeOffResponse);
  } catch (err) {
    yield put(requestTimeOffAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRequestNotificationsAction } from '@/redux/actions';
import { getRequestNotifications, TGetRequestNotificationsResponse } from '@/services/api';

// FUNCTION

export function* getRequestNotificationsSaga(
  action: ActionType<typeof getRequestNotificationsAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRequestNotifications, materials);
    const getRequestNotificationsResponse: TGetRequestNotificationsResponse =
      response as TGetRequestNotificationsResponse;
    yield put(getRequestNotificationsAction.success(getRequestNotificationsResponse));
    successCallback?.(getRequestNotificationsResponse);
  } catch (err) {
    yield put(getRequestNotificationsAction.failure(err));
    failedCallback?.(err);
  }
}

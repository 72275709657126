import { createReducer } from 'deox';

import {
  TChangeStatusBenefitResponse,
  TCreateBenefitCoverageResponse,
  TCreateBenefitResponse,
  TDeleteBenefitResponse,
  TGetBenefitCoveragesResponse,
  TGetBenefitResponse,
  TGetBenefitsResponse,
  TGetUserBenefitsHistoryResponse,
  TGetUserBenefitsResponse,
  TUpdateBenefitResponse,
} from '@/services/api/benefit';
import {
  changeStatusBenefitAction,
  createBenefitCoverageAction,
  createBenefitAction,
  deleteBenefitAction,
  getBenefitCoveragesAction,
  getBenefitAction,
  getBenefitsAction,
  getUserBenefitsHistoryAction,
  getUserBenefitsAction,
  updateBenefitAction,
} from '@/redux/actions';
import { changeStatusBenefitUpdateState } from './change-status-benefit';
import { createBenefitCoverageUpdateState } from './create-benefit-coverage';
import { createBenefitUpdateState } from './create-benefit';
import { deleteBenefitUpdateState } from './delete-benefit';
import { getBenefitCoveragesUpdateState } from './get-benefit-coverages';
import { getBenefitUpdateState } from './get-benefit';
import { getBenefitsUpdateState } from './get-benefits';
import { getUserBenefitsHistoryUpdateState } from './get-user-benefits-history';
import { getUserBenefitsUpdateState } from './get-user-benefits';
import { updateBenefitUpdateState } from './update-benefit';

export type TBenefitState = {
  changeStatusBenefitResponse?: TChangeStatusBenefitResponse;
  createBenefitCoverageResponse?: TCreateBenefitCoverageResponse;
  createBenefitResponse?: TCreateBenefitResponse;
  deleteBenefitResponse?: TDeleteBenefitResponse;
  getBenefitCoveragesResponse?: TGetBenefitCoveragesResponse;
  getBenefitResponse?: TGetBenefitResponse;
  getBenefitsResponse?: TGetBenefitsResponse;
  getUserBenefitsHistoryResponse?: TGetUserBenefitsHistoryResponse;
  getUserBenefitsResponse?: TGetUserBenefitsResponse;
  updateBenefitResponse?: TUpdateBenefitResponse;
};

const initialState: TBenefitState = {
  changeStatusBenefitResponse: undefined,
  createBenefitCoverageResponse: undefined,
  createBenefitResponse: undefined,
  deleteBenefitResponse: undefined,
  getBenefitCoveragesResponse: undefined,
  getBenefitResponse: undefined,
  getBenefitsResponse: undefined,
  getUserBenefitsHistoryResponse: undefined,
  getUserBenefitsResponse: undefined,
  updateBenefitResponse: undefined,
};

const BenefitReducer = createReducer(initialState, (handleAction) => [
  handleAction(changeStatusBenefitAction.success, changeStatusBenefitUpdateState),
  handleAction(createBenefitCoverageAction.success, createBenefitCoverageUpdateState),
  handleAction(createBenefitAction.success, createBenefitUpdateState),
  handleAction(deleteBenefitAction.success, deleteBenefitUpdateState),
  handleAction(getBenefitCoveragesAction.success, getBenefitCoveragesUpdateState),
  handleAction(getBenefitAction.success, getBenefitUpdateState),
  handleAction(getBenefitsAction.success, getBenefitsUpdateState),
  handleAction(getUserBenefitsHistoryAction.success, getUserBenefitsHistoryUpdateState),
  handleAction(getUserBenefitsAction.success, getUserBenefitsUpdateState),
  handleAction(updateBenefitAction.success, updateBenefitUpdateState),
]);

export default BenefitReducer;

import { TRegulationState } from '@/redux/reducers/regulation';
import { TCreateRegulationSuccess } from '@/redux/actions/regulation';

export const createRegulationUpdateState = (
  state: TRegulationState,
  action: TCreateRegulationSuccess,
): TRegulationState => ({
  ...state,
  createRegulationResponse: action.payload.response,
});

import { all, takeLatest } from 'redux-saga/effects';

import {
  createBranchAction,
  deleteBranchAction,
  getBranchAction,
  getBranchesAction,
  updateBranchAction,
} from '@/redux/actions';

import { createBranchSaga } from './create-branch';
import { deleteBranchSaga } from './delete-branch';
import { getBranchSaga } from './get-branch';
import { getBranchesSaga } from './get-branches';
import { updateBranchSaga } from './update-branch';

export default function* root(): Generator {
  yield all([
    takeLatest(createBranchAction.request.type, createBranchSaga),
    takeLatest(deleteBranchAction.request.type, deleteBranchSaga),
    takeLatest(getBranchAction.request.type, getBranchSaga),
    takeLatest(getBranchesAction.request.type, getBranchesSaga),
    takeLatest(updateBranchAction.request.type, updateBranchSaga),
  ]);
}

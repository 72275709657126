import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_600 }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 4.125C9 3.50368 9.50368 3 10.125 3H13.875C14.4963 3 15 3.50368 15 4.125V7.875C15 8.49632 14.4963 9 13.875 9H10.125C9.50368 9 9 8.49632 9 7.875V4.125Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M12 9V10.875C12 11.4963 11.4963 12 10.875 12H7.125C6.50368 12 6 12.5037 6 13.125V15"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M12 9V10.875C12 11.4963 12.5037 12 13.125 12H16.875C17.4963 12 18 12.5037 18 13.125V15"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M3 16.125C3 15.5037 3.50368 15 4.125 15H7.875C8.49632 15 9 15.5037 9 16.125V19.875C9 20.4963 8.49632 21 7.875 21H4.125C3.50368 21 3 20.4963 3 19.875V16.125Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M15 16.125C15 15.5037 15.5037 15 16.125 15H19.875C20.4963 15 21 15.5037 21 16.125V19.875C21 20.4963 20.4963 21 19.875 21H16.125C15.5037 21 15 20.4963 15 19.875V16.125Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Svg;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRegulationAction } from '@/redux/actions';
import { getRegulation, TGetRegulationResponse } from '@/services/api';

// FUNCTION

export function* getRegulationSaga(action: ActionType<typeof getRegulationAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRegulation, materials);
    const getRegulationResponse: TGetRegulationResponse = response as TGetRegulationResponse;
    yield put(getRegulationAction.success(getRegulationResponse));
    successCallback?.(getRegulationResponse);
  } catch (err) {
    yield put(getRegulationAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getStatesAction } from '@/redux/actions';
import { getStates, TGetStatesResponse } from '@/services/api';

// FUNCTION

export function* getStatesSaga(action: ActionType<typeof getStatesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getStates, materials);
    const getStatesResponse: TGetStatesResponse = response as TGetStatesResponse;
    yield put(getStatesAction.success(getStatesResponse));
    successCallback?.(getStatesResponse);
  } catch (err) {
    yield put(getStatesAction.failure(err));
    failedCallback?.(err);
  }
}

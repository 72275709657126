import { TRegulationState } from '@/redux/reducers/regulation';
import { TGetRegulationsSuccess } from '@/redux/actions/regulation';

export const getRegulationsUpdateState = (
  state: TRegulationState,
  action: TGetRegulationsSuccess,
): TRegulationState => ({
  ...state,
  getRegulationsResponse: action.payload.response,
});

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteMemberRoleAction } from '@/redux/actions';
import { deleteMemberRole, TDeleteMemberRoleResponse } from '@/services/api';

// FUNCTION

export function* deleteMemberRoleSaga(action: ActionType<typeof deleteMemberRoleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteMemberRole, materials);
    const deleteMemberRoleResponse: TDeleteMemberRoleResponse = response as TDeleteMemberRoleResponse;
    yield put(deleteMemberRoleAction.success(deleteMemberRoleResponse));
    successCallback?.(deleteMemberRoleResponse);
  } catch (err) {
    yield put(deleteMemberRoleAction.failure(err));
    failedCallback?.(err);
  }
}

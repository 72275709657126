import { createActionCreator } from 'deox';

import { TGetTemplateMaterials, TGetTemplateResponse } from '@/services/api/template/get-template';

// CONSTANTS

export enum EGetTemplateAction {
  GET_TEMPLATE = 'GET_TEMPLATE',
  GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST',
  GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS',
  GET_TEMPLATE_FAILED = 'GET_TEMPLATE_FAILED',
}

// TYPES

export type TGetTemplateRequest = {
  type: EGetTemplateAction.GET_TEMPLATE_REQUEST;
  payload: {
    materials: TGetTemplateMaterials;
    successCallback?: (response: TGetTemplateResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTemplateSuccess = {
  type: EGetTemplateAction.GET_TEMPLATE_SUCCESS;
  payload: { response?: TGetTemplateResponse };
};

export type TGetTemplateFailed = { type: EGetTemplateAction.GET_TEMPLATE_FAILED };

// FUNCTION

export const getTemplateAction = {
  request: createActionCreator(
    EGetTemplateAction.GET_TEMPLATE_REQUEST,
    (resolve) =>
      (
        materials: TGetTemplateMaterials,
        successCallback?: (response: TGetTemplateResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTemplateRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTemplateAction.GET_TEMPLATE_SUCCESS,
    (resolve) =>
      (response?: TGetTemplateResponse): TGetTemplateSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTemplateAction.GET_TEMPLATE_FAILED,
    (resolve) =>
      (error: unknown): TGetTemplateFailed =>
        resolve({ error }),
  ),
};

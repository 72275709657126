import { TRegulateCategoryState } from '@/redux/reducers/regulate-category';
import { TUpdateRegulateCategorySuccess } from '@/redux/actions/regulate-category';

export const updateRegulateCategoryUpdateState = (
  state: TRegulateCategoryState,
  action: TUpdateRegulateCategorySuccess,
): TRegulateCategoryState => ({
  ...state,
  updateRegulateCategoryResponse: action.payload.response,
});

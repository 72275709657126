import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="stroke" d="M2.5072 5H14.5072" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        className="stroke"
        d="M4.0072 5H13.0072V14C13.0072 14.2652 12.9018 14.5196 12.7143 14.7071C12.5268 14.8946 12.2724 15 12.0072 15H5.0072C4.74198 15 4.48763 14.8946 4.30009 14.7071C4.11256 14.5196 4.0072 14.2652 4.0072 14V5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6.0072 5V4.5C6.0072 3.83696 6.27059 3.20107 6.73943 2.73223C7.20827 2.26339 7.84416 2 8.5072 2C9.17024 2 9.80613 2.26339 10.275 2.73223C10.7438 3.20107 11.0072 3.83696 11.0072 4.5V5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path className="stroke" d="M7.0072 8V12.0015" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke" d="M10.0072 8V12.0015" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Svg;

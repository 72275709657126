import { createActionCreator } from 'deox';

import {
  TCreateBenefitCoverageMaterials,
  TCreateBenefitCoverageResponse,
} from '@/services/api/benefit/create-benefit-coverage';

// CONSTANTS

export enum ECreateBenefitCoverageAction {
  CREATE_BENEFIT_COVERAGE = 'CREATE_BENEFIT_COVERAGE',
  CREATE_BENEFIT_COVERAGE_REQUEST = 'CREATE_BENEFIT_COVERAGE_REQUEST',
  CREATE_BENEFIT_COVERAGE_SUCCESS = 'CREATE_BENEFIT_COVERAGE_SUCCESS',
  CREATE_BENEFIT_COVERAGE_FAILED = 'CREATE_BENEFIT_COVERAGE_FAILED',
}

// TYPES

export type TCreateBenefitCoverageRequest = {
  type: ECreateBenefitCoverageAction.CREATE_BENEFIT_COVERAGE_REQUEST;
  payload: {
    materials: TCreateBenefitCoverageMaterials;
    successCallback?: (response: TCreateBenefitCoverageResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateBenefitCoverageSuccess = {
  type: ECreateBenefitCoverageAction.CREATE_BENEFIT_COVERAGE_SUCCESS;
  payload: { response: TCreateBenefitCoverageResponse };
};

export type TCreateBenefitCoverageFailed = { type: ECreateBenefitCoverageAction.CREATE_BENEFIT_COVERAGE_FAILED };

// FUNCTION

export const createBenefitCoverageAction = {
  request: createActionCreator(
    ECreateBenefitCoverageAction.CREATE_BENEFIT_COVERAGE_REQUEST,
    (resolve) =>
      (
        materials: TCreateBenefitCoverageMaterials,
        successCallback?: (response: TCreateBenefitCoverageResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateBenefitCoverageRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateBenefitCoverageAction.CREATE_BENEFIT_COVERAGE_SUCCESS,
    (resolve) =>
      (response: TCreateBenefitCoverageResponse): TCreateBenefitCoverageSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateBenefitCoverageAction.CREATE_BENEFIT_COVERAGE_FAILED,
    (resolve) =>
      (error: unknown): TCreateBenefitCoverageFailed =>
        resolve({ error }),
  ),
};

import { TBenefitState } from '@/redux/reducers/benefit';
import { TGetBenefitCoveragesSuccess } from '@/redux/actions/benefit';

export const getBenefitCoveragesUpdateState = (
  state: TBenefitState,
  action: TGetBenefitCoveragesSuccess,
): TBenefitState => ({
  ...state,
  getBenefitCoveragesResponse: action.payload.response,
});

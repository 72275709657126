import { createActionCreator } from 'deox';

import { TAddUserRolesMaterials, TAddUserRolesResponse } from '@/services/api/user/add-user-roles';

// CONSTANTS

export enum EAddUserRolesAction {
  ADD_USER_ROLES = 'ADD_USER_ROLES',
  ADD_USER_ROLES_REQUEST = 'ADD_USER_ROLES_REQUEST',
  ADD_USER_ROLES_SUCCESS = 'ADD_USER_ROLES_SUCCESS',
  ADD_USER_ROLES_FAILED = 'ADD_USER_ROLES_FAILED',
}

// TYPES

export type TAddUserRolesRequest = {
  type: EAddUserRolesAction.ADD_USER_ROLES_REQUEST;
  payload: {
    materials: TAddUserRolesMaterials;
    successCallback?: (response: TAddUserRolesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAddUserRolesSuccess = {
  type: EAddUserRolesAction.ADD_USER_ROLES_SUCCESS;
  payload: { response: TAddUserRolesResponse };
};

export type TAddUserRolesFailed = { type: EAddUserRolesAction.ADD_USER_ROLES_FAILED };

// FUNCTION

export const addUserRolesAction = {
  request: createActionCreator(
    EAddUserRolesAction.ADD_USER_ROLES_REQUEST,
    (resolve) =>
      (
        materials: TAddUserRolesMaterials,
        successCallback?: (response: TAddUserRolesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAddUserRolesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAddUserRolesAction.ADD_USER_ROLES_SUCCESS,
    (resolve) =>
      (response: TAddUserRolesResponse): TAddUserRolesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAddUserRolesAction.ADD_USER_ROLES_FAILED,
    (resolve) =>
      (error: unknown): TAddUserRolesFailed =>
        resolve({ error }),
  ),
};

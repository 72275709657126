import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';

const Svg: React.FC<TIconProps> = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9353 15.4115H3.06517C2.53919 15.4115 2.11279 14.9851 2.11279 14.4591V3.03051C2.11279 2.50452 2.53919 2.07812 3.06517 2.07812H9.4721L13.8877 6.49371V14.4591C13.8877 14.9851 13.4613 15.4115 12.9353 15.4115Z"
        fill="#36BC6C"
      />
      <path
        d="M13.8878 6.49371H10.4245C9.89856 6.49371 9.47217 6.06731 9.47217 5.54133V2.07812L13.8878 6.49371Z"
        fill="#1B8746"
      />
      <path
        d="M3.75515 12.4639C3.66926 12.4639 3.59884 12.4415 3.54388 12.3969C3.48891 12.3488 3.45628 12.2904 3.44597 12.2217C3.4391 12.1495 3.46315 12.0774 3.51811 12.0052L4.69813 10.4336V10.717L3.57479 9.21235C3.51983 9.13677 3.49578 9.06463 3.50265 8.99593C3.50952 8.92379 3.54044 8.86539 3.5954 8.82073C3.65037 8.77263 3.71907 8.74859 3.80152 8.74859C3.87023 8.74859 3.93034 8.76576 3.98187 8.80012C4.03684 8.83103 4.0918 8.88428 4.14677 8.95986L5.0743 10.2378H4.86303L5.7854 8.95986C5.84036 8.88428 5.89361 8.83103 5.94514 8.80012C6.00011 8.76576 6.06366 8.74859 6.1358 8.74859C6.22168 8.74859 6.2921 8.77092 6.34707 8.81558C6.40203 8.86023 6.43295 8.91863 6.43982 8.99078C6.44669 9.05948 6.42093 9.13334 6.36253 9.21235L5.23919 10.717V10.4336L6.41406 12.0052C6.46902 12.0774 6.49307 12.1495 6.4862 12.2217C6.47933 12.2904 6.44841 12.3488 6.39345 12.3969C6.33848 12.4415 6.26634 12.4639 6.17702 12.4639C6.11175 12.4639 6.05164 12.4467 5.99667 12.4123C5.94514 12.378 5.89018 12.323 5.83178 12.2474L4.85272 10.9077H5.07945L4.10554 12.2474C4.05058 12.323 3.99562 12.378 3.94065 12.4123C3.88569 12.4467 3.82385 12.4639 3.75515 12.4639Z"
        fill="white"
      />
      <path
        d="M7.42854 12.4226C7.32205 12.4226 7.2396 12.3934 7.1812 12.335C7.1228 12.2732 7.0936 12.189 7.0936 12.0825V9.10929C7.0936 8.99936 7.12108 8.91692 7.17605 8.86195C7.23445 8.80355 7.31689 8.77435 7.42339 8.77435C7.52988 8.77435 7.61061 8.80355 7.66558 8.86195C7.72398 8.91692 7.75318 8.99936 7.75318 9.10929V11.861H9.24753C9.34028 11.861 9.4107 11.885 9.4588 11.9331C9.51033 11.9812 9.53609 12.0499 9.53609 12.1392C9.53609 12.2285 9.51033 12.299 9.4588 12.3505C9.4107 12.3986 9.34028 12.4226 9.24753 12.4226H7.42854Z"
        fill="white"
      />
      <path
        d="M11.1638 12.4742C11.0126 12.4742 10.8615 12.4621 10.7103 12.4381C10.5592 12.4175 10.4166 12.3848 10.2826 12.3402C10.1486 12.2921 10.0284 12.2337 9.92192 12.165C9.86008 12.1238 9.81714 12.0739 9.79309 12.0155C9.76905 11.9571 9.76046 11.9005 9.76733 11.8455C9.77763 11.7871 9.79996 11.7373 9.83432 11.6961C9.8721 11.6514 9.91848 11.6239 9.97344 11.6136C10.0284 11.6033 10.0885 11.6188 10.1538 11.66C10.305 11.7493 10.4647 11.8146 10.633 11.8558C10.8013 11.897 10.9783 11.9176 11.1638 11.9176C11.4352 11.9176 11.6327 11.873 11.7564 11.7837C11.88 11.6909 11.9419 11.5724 11.9419 11.4281C11.9419 11.3079 11.8972 11.2134 11.8079 11.1447C11.722 11.076 11.5726 11.0193 11.3596 10.9747L10.7928 10.8561C10.4664 10.7874 10.2225 10.6723 10.061 10.5109C9.90302 10.346 9.82401 10.1296 9.82401 9.86162C9.82401 9.69329 9.85836 9.54042 9.92707 9.40301C9.99577 9.2656 10.092 9.14708 10.2156 9.04746C10.3427 8.94783 10.4922 8.87226 10.6639 8.82073C10.8391 8.76576 11.0315 8.73828 11.2411 8.73828C11.4472 8.73828 11.643 8.76405 11.8285 8.81558C12.014 8.8671 12.1806 8.94268 12.3283 9.0423C12.3833 9.08009 12.4194 9.12647 12.4365 9.18143C12.4572 9.2364 12.4623 9.29136 12.452 9.34633C12.4417 9.39786 12.4177 9.44252 12.3799 9.4803C12.3421 9.51809 12.294 9.54042 12.2356 9.54729C12.1806 9.55416 12.1171 9.53699 12.0449 9.49576C11.9178 9.42362 11.789 9.37209 11.6585 9.34117C11.5279 9.31026 11.3871 9.2948 11.2359 9.2948C11.0779 9.2948 10.9422 9.31713 10.8288 9.36179C10.7155 9.40644 10.6279 9.47 10.566 9.55244C10.5076 9.63146 10.4784 9.72593 10.4784 9.83586C10.4784 9.95953 10.5197 10.0591 10.6021 10.1347C10.6845 10.2069 10.8254 10.2635 11.0246 10.3048L11.5863 10.4233C11.9264 10.4954 12.1789 10.6088 12.3438 10.7634C12.5121 10.918 12.5963 11.1241 12.5963 11.3817C12.5963 11.5466 12.5619 11.6961 12.4932 11.83C12.428 11.964 12.3318 12.0791 12.2047 12.1753C12.081 12.2715 11.9316 12.3453 11.7564 12.3969C11.5812 12.4484 11.3836 12.4742 11.1638 12.4742Z"
        fill="white"
      />
    </svg>
  );
};

export default Svg;

import { createActionCreator } from 'deox';

import {
  TGetUserBenefitsHistoryMaterials,
  TGetUserBenefitsHistoryResponse,
} from '@/services/api/benefit/get-user-benefits-history';

// CONSTANTS

export enum EGetUserBenefitsHistoryAction {
  GET_USER_BENEFITS_HISTORY = 'GET_USER_BENEFITS_HISTORY',
  GET_USER_BENEFITS_HISTORY_REQUEST = 'GET_USER_BENEFITS_HISTORY_REQUEST',
  GET_USER_BENEFITS_HISTORY_SUCCESS = 'GET_USER_BENEFITS_HISTORY_SUCCESS',
  GET_USER_BENEFITS_HISTORY_FAILED = 'GET_USER_BENEFITS_HISTORY_FAILED',
}

// TYPES

export type TGetUserBenefitsHistoryRequest = {
  type: EGetUserBenefitsHistoryAction.GET_USER_BENEFITS_HISTORY_REQUEST;
  payload: {
    materials: TGetUserBenefitsHistoryMaterials;
    successCallback?: (response: TGetUserBenefitsHistoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetUserBenefitsHistorySuccess = {
  type: EGetUserBenefitsHistoryAction.GET_USER_BENEFITS_HISTORY_SUCCESS;
  payload: { response: TGetUserBenefitsHistoryResponse };
};

export type TGetUserBenefitsHistoryFailed = { type: EGetUserBenefitsHistoryAction.GET_USER_BENEFITS_HISTORY_FAILED };

// FUNCTION

export const getUserBenefitsHistoryAction = {
  request: createActionCreator(
    EGetUserBenefitsHistoryAction.GET_USER_BENEFITS_HISTORY_REQUEST,
    (resolve) =>
      (
        materials: TGetUserBenefitsHistoryMaterials,
        successCallback?: (response: TGetUserBenefitsHistoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetUserBenefitsHistoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetUserBenefitsHistoryAction.GET_USER_BENEFITS_HISTORY_SUCCESS,
    (resolve) =>
      (response: TGetUserBenefitsHistoryResponse): TGetUserBenefitsHistorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetUserBenefitsHistoryAction.GET_USER_BENEFITS_HISTORY_FAILED,
    (resolve) =>
      (error: unknown): TGetUserBenefitsHistoryFailed =>
        resolve({ error }),
  ),
};

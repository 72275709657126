import { createActionCreator } from 'deox';

import { TDeleteTemplateMaterials, TDeleteTemplateResponse } from '@/services/api/template/delete-template';

// CONSTANTS

export enum EDeleteTemplateAction {
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_FAILED = 'DELETE_TEMPLATE_FAILED',
}

// TYPES

export type TDeleteTemplateRequest = {
  type: EDeleteTemplateAction.DELETE_TEMPLATE_REQUEST;
  payload: {
    materials: TDeleteTemplateMaterials;
    successCallback?: (response: TDeleteTemplateResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteTemplateSuccess = {
  type: EDeleteTemplateAction.DELETE_TEMPLATE_SUCCESS;
  payload: { response: TDeleteTemplateResponse };
};

export type TDeleteTemplateFailed = { type: EDeleteTemplateAction.DELETE_TEMPLATE_FAILED };

// FUNCTION

export const deleteTemplateAction = {
  request: createActionCreator(
    EDeleteTemplateAction.DELETE_TEMPLATE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteTemplateMaterials,
        successCallback?: (response: TDeleteTemplateResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteTemplateRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteTemplateAction.DELETE_TEMPLATE_SUCCESS,
    (resolve) =>
      (response: TDeleteTemplateResponse): TDeleteTemplateSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteTemplateAction.DELETE_TEMPLATE_FAILED,
    (resolve) =>
      (error: unknown): TDeleteTemplateFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createTimeOffTypeAction } from '@/redux/actions';
import { createTimeOffType, TCreateTimeOffTypeResponse } from '@/services/api';

// FUNCTION

export function* createTimeOffTypeSaga(action: ActionType<typeof createTimeOffTypeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createTimeOffType, materials);
    const createTimeOffTypeResponse: TCreateTimeOffTypeResponse = response as TCreateTimeOffTypeResponse;
    yield put(createTimeOffTypeAction.success(createTimeOffTypeResponse));
    successCallback?.(createTimeOffTypeResponse);
  } catch (err) {
    yield put(createTimeOffTypeAction.failure(err));
    failedCallback?.(err);
  }
}

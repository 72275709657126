import { combineReducers } from 'redux';

import { loadingReducer, errorReducer, successReducer } from './status';
import approverReducer from './approver';
import assetReducer from './asset';
import authReducer from './auth';
import benefitReducer from './benefit';
import branchReducer from './branch';
import checklistReducer from './checklist';
import companyReducer from './company';
import dashboardReducer from './dashboard';
import departmentReducer from './department';
import globalReducer from './global';
import holidayReducer from './holiday';
import jobReducer from './job';
import mailReducer from './mail';
import regulateCategoryReducer from './regulate-category';
import regulationReducer from './regulation';
import reportReducer from './report';
import reportViolationReducer from './report-violation';
import requestReducer from './request';
import roleReducer from './role';
import settingReducer from './setting';
import templateReducer from './template';
import timeOffTypeReducer from './time-off-type';
import typeWorkReducer from './type-work';
import uiReducer from './ui';
import uploadReducer from './upload';
import userReducer from './user';
import widenReducer from './widen';

const rootReducer = combineReducers({
  loadingReducer,
  errorReducer,
  successReducer,
  approverReducer,
  assetReducer,
  authReducer,
  benefitReducer,
  branchReducer,
  checklistReducer,
  companyReducer,
  dashboardReducer,
  departmentReducer,
  globalReducer,
  holidayReducer,
  jobReducer,
  mailReducer,
  regulateCategoryReducer,
  regulationReducer,
  reportReducer,
  reportViolationReducer,
  requestReducer,
  roleReducer,
  settingReducer,
  templateReducer,
  timeOffTypeReducer,
  typeWorkReducer,
  uiReducer,
  uploadReducer,
  userReducer,
  widenReducer,
});

export default rootReducer;

export type TRootState = ReturnType<typeof rootReducer>;

import ApiService from '@/services/api';

// TYPES

export type TRehireUserParams = unknown;
export type TRehireUserBody = unknown;

export type TRehireUserMaterials = {
  params?: TRehireUserParams;
  body?: TRehireUserBody;
};

export type TRehireUserResponse = unknown;

// FUNCTION

export const rehireUser = async ({ params, body }: TRehireUserMaterials): Promise<TRehireUserResponse> => {
  const response = await ApiService.post(`/user/rehire`, body, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TDeleteRegulationPaths = {
  id: string | number;
};
export type TDeleteRegulationParams = unknown;

export type TDeleteRegulationMaterials = {
  paths?: TDeleteRegulationPaths;
  params?: TDeleteRegulationParams;
};

export type TDeleteRegulationResponse = unknown;

// FUNCTION

export const deleteRegulation = async ({
  paths,
  params,
}: TDeleteRegulationMaterials): Promise<TDeleteRegulationResponse> => {
  const response = await ApiService.delete(`/regulation/${paths?.id}`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TGetRequestNotificationsParams = unknown;

export type TGetRequestNotificationsMaterials = {
  params?: TGetRequestNotificationsParams;
};

export type TGetRequestNotificationsResponse = {
  assignReqs: boolean;
  ccReqs: boolean;
  myReqs: boolean;
  reqs: number;
};

// FUNCTION

export const getRequestNotifications = async ({
  params,
}: TGetRequestNotificationsMaterials): Promise<TGetRequestNotificationsResponse> => {
  const response = await ApiService.get(`/request/management/notification`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TUpdateSettingBody = unknown;

export type TUpdateSettingMaterials = {
  body?: TUpdateSettingBody;
};

export type TUpdateSettingResponse = unknown;

// FUNCTION

export const updateSetting = async ({ body }: TUpdateSettingMaterials): Promise<TUpdateSettingResponse> => {
  const response = await ApiService.put(`/setting`, body);
  return response.data;
};

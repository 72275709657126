import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';

const Svg: React.FC<TIconProps> = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27 6H13V14H29V8C29 6.89543 28.1046 6 27 6Z" fill="#FFA000" />
      <path
        d="M30 13.8182C30 12.9502 29.6596 12.1178 29.0537 11.504C28.4478 10.8903 27.6261 10.5455 26.7692 10.5455H16L12.8985 4H5.23077C4.37392 4 3.55216 4.3448 2.94627 4.95856C2.34038 5.57232 2 6.40475 2 7.27273V24.7273C2 25.5953 2.34038 26.4277 2.94627 27.0414C3.55216 27.6552 4.37392 28 5.23077 28H26.7692C27.6261 28 28.4478 27.6552 29.0537 27.0414C29.6596 26.4277 30 25.5953 30 24.7273V13.8182Z"
        fill="#FFC107"
      />
      <path
        d="M14.1658 18.5836C15.4315 18.5836 16.4576 17.5575 16.4576 16.2918C16.4576 15.0261 15.4315 14 14.1658 14C12.9001 14 11.874 15.0261 11.874 16.2918C11.874 17.5575 12.9001 18.5836 14.1658 18.5836Z"
        fill="white"
      />
      <path
        d="M10.041 23.9984H14.1662H18.2915V23.5014C18.2842 22.8026 18.0999 22.1172 17.7559 21.5089C17.4118 20.9008 16.9192 20.3897 16.3241 20.0235C15.729 19.6572 15.0508 19.4478 14.3528 19.4148C14.2906 19.4118 14.2284 19.4103 14.1662 19.4102C14.1041 19.4103 14.0419 19.4118 13.9797 19.4148C13.2817 19.4478 12.6035 19.6572 12.0084 20.0235C11.4132 20.3897 10.9207 20.9008 10.5766 21.5089C10.2326 22.1172 10.0483 22.8026 10.041 23.5014V23.9984Z"
        fill="white"
      />
      <path
        d="M17.833 18.5836C19.0987 18.5836 20.1248 17.5575 20.1248 16.2918C20.1248 15.0261 19.0987 14 17.833 14"
        fill="white"
      />
      <path
        d="M20.1251 23.9989H21.9585V23.5019C21.9512 22.8031 21.7669 22.1177 21.4229 21.5094C21.0788 20.9013 20.5863 20.3902 19.9911 20.024C19.6065 19.7872 19.187 19.616 18.75 19.5156"
        fill="white"
      />
    </svg>
  );
};

export default Svg;

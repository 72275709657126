import { createActionCreator } from 'deox';

import { TDeleteHolidayMaterials, TDeleteHolidayResponse } from '@/services/api/holiday/delete-holiday';

// CONSTANTS

export enum EDeleteHolidayAction {
  DELETE_HOLIDAY = 'DELETE_HOLIDAY',
  DELETE_HOLIDAY_REQUEST = 'DELETE_HOLIDAY_REQUEST',
  DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS',
  DELETE_HOLIDAY_FAILED = 'DELETE_HOLIDAY_FAILED',
}

// TYPES

export type TDeleteHolidayRequest = {
  type: EDeleteHolidayAction.DELETE_HOLIDAY_REQUEST;
  payload: {
    materials: TDeleteHolidayMaterials;
    successCallback?: (response: TDeleteHolidayResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteHolidaySuccess = {
  type: EDeleteHolidayAction.DELETE_HOLIDAY_SUCCESS;
  payload: { response: TDeleteHolidayResponse };
};

export type TDeleteHolidayFailed = { type: EDeleteHolidayAction.DELETE_HOLIDAY_FAILED };

// FUNCTION

export const deleteHolidayAction = {
  request: createActionCreator(
    EDeleteHolidayAction.DELETE_HOLIDAY_REQUEST,
    (resolve) =>
      (
        materials: TDeleteHolidayMaterials,
        successCallback?: (response: TDeleteHolidayResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteHolidayRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteHolidayAction.DELETE_HOLIDAY_SUCCESS,
    (resolve) =>
      (response: TDeleteHolidayResponse): TDeleteHolidaySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteHolidayAction.DELETE_HOLIDAY_FAILED,
    (resolve) =>
      (error: unknown): TDeleteHolidayFailed =>
        resolve({ error }),
  ),
};

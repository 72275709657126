import { createActionCreator } from 'deox';

import { TGetDepartmentsMaterials, TGetDepartmentsResponse } from '@/services/api/department/get-departments';

// CONSTANTS

export enum EGetDepartmentsAction {
  GET_DEPARTMENTS = 'GET_DEPARTMENTS',
  GET_DEPARTMENTS_REQUEST = 'GET_DEPARTMENTS_REQUEST',
  GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS',
  GET_DEPARTMENTS_FAILED = 'GET_DEPARTMENTS_FAILED',
}

// TYPES

export type TGetDepartmentsRequest = {
  type: EGetDepartmentsAction.GET_DEPARTMENTS_REQUEST;
  payload: {
    materials: TGetDepartmentsMaterials;
    successCallback?: (response: TGetDepartmentsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetDepartmentsSuccess = {
  type: EGetDepartmentsAction.GET_DEPARTMENTS_SUCCESS;
  payload: { response: TGetDepartmentsResponse };
};

export type TGetDepartmentsFailed = { type: EGetDepartmentsAction.GET_DEPARTMENTS_FAILED };

// FUNCTION

export const getDepartmentsAction = {
  request: createActionCreator(
    EGetDepartmentsAction.GET_DEPARTMENTS_REQUEST,
    (resolve) =>
      (
        materials: TGetDepartmentsMaterials,
        successCallback?: (response: TGetDepartmentsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetDepartmentsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetDepartmentsAction.GET_DEPARTMENTS_SUCCESS,
    (resolve) =>
      (response: TGetDepartmentsResponse): TGetDepartmentsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetDepartmentsAction.GET_DEPARTMENTS_FAILED,
    (resolve) =>
      (error: unknown): TGetDepartmentsFailed =>
        resolve({ error }),
  ),
};

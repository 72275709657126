import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportTimeOffBalanceAction } from '@/redux/actions';
import { getReportTimeOffBalance, TGetReportTimeOffBalanceResponse } from '@/services/api';

// FUNCTION

export function* getReportTimeOffBalanceSaga(
  action: ActionType<typeof getReportTimeOffBalanceAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportTimeOffBalance, materials);
    const getReportTimeOffBalanceResponse: TGetReportTimeOffBalanceResponse =
      response as TGetReportTimeOffBalanceResponse;
    yield put(getReportTimeOffBalanceAction.success(getReportTimeOffBalanceResponse));
    successCallback?.(getReportTimeOffBalanceResponse);
  } catch (err) {
    yield put(getReportTimeOffBalanceAction.failure(err));
    failedCallback?.(err);
  }
}

import { TRegulateCategoryState } from '@/redux/reducers/regulate-category';
import { TGetRegulateCategoriesSuccess } from '@/redux/actions/regulate-category';

export const getRegulateCategoriesUpdateState = (
  state: TRegulateCategoryState,
  action: TGetRegulateCategoriesSuccess,
): TRegulateCategoryState => ({
  ...state,
  getRegulateCategoriesResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import { TGetUserBenefitsMaterials, TGetUserBenefitsResponse } from '@/services/api/benefit/get-user-benefits';

// CONSTANTS

export enum EGetUserBenefitsAction {
  GET_USER_BENEFITS = 'GET_USER_BENEFITS',
  GET_USER_BENEFITS_REQUEST = 'GET_USER_BENEFITS_REQUEST',
  GET_USER_BENEFITS_SUCCESS = 'GET_USER_BENEFITS_SUCCESS',
  GET_USER_BENEFITS_FAILED = 'GET_USER_BENEFITS_FAILED',
}

// TYPES

export type TGetUserBenefitsRequest = {
  type: EGetUserBenefitsAction.GET_USER_BENEFITS_REQUEST;
  payload: {
    materials: TGetUserBenefitsMaterials;
    successCallback?: (response: TGetUserBenefitsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetUserBenefitsSuccess = {
  type: EGetUserBenefitsAction.GET_USER_BENEFITS_SUCCESS;
  payload: { response: TGetUserBenefitsResponse };
};

export type TGetUserBenefitsFailed = { type: EGetUserBenefitsAction.GET_USER_BENEFITS_FAILED };

// FUNCTION

export const getUserBenefitsAction = {
  request: createActionCreator(
    EGetUserBenefitsAction.GET_USER_BENEFITS_REQUEST,
    (resolve) =>
      (
        materials: TGetUserBenefitsMaterials,
        successCallback?: (response: TGetUserBenefitsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetUserBenefitsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetUserBenefitsAction.GET_USER_BENEFITS_SUCCESS,
    (resolve) =>
      (response: TGetUserBenefitsResponse): TGetUserBenefitsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetUserBenefitsAction.GET_USER_BENEFITS_FAILED,
    (resolve) =>
      (error: unknown): TGetUserBenefitsFailed =>
        resolve({ error }),
  ),
};

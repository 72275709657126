import ApiService from '@/services/api';

// TYPES

export type TSignInParams = unknown;
export type TSignInBody = unknown;

export type TSignInMaterials = {
  params?: TSignInParams;
  body?: TSignInBody;
};

export type TSignInResponse = {
  accessToken: string;
};

// FUNCTION

export const signIn = async ({ params, body }: TSignInMaterials): Promise<TSignInResponse> => {
  const response = await ApiService.post(`/auth/sign-in`, body, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTimeOffHistoriesAction } from '@/redux/actions';
import { getTimeOffHistories, TGetTimeOffHistoriesResponse } from '@/services/api';

// FUNCTION

export function* getTimeOffHistoriesSaga(action: ActionType<typeof getTimeOffHistoriesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTimeOffHistories, materials);
    const getTimeOffHistoriesResponse: TGetTimeOffHistoriesResponse = response as TGetTimeOffHistoriesResponse;
    yield put(getTimeOffHistoriesAction.success(getTimeOffHistoriesResponse));
    successCallback?.(getTimeOffHistoriesResponse);
  } catch (err) {
    yield put(getTimeOffHistoriesAction.failure(err));
    failedCallback?.(err);
  }
}

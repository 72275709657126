import { createReducer } from 'deox';

import {
  TCreateDepartmentResponse,
  TDeleteDepartmentResponse,
  TGetDepartmentResponse,
  TGetDepartmentsResponse,
  TUpdateDepartmentResponse,
} from '@/services/api/department';
import {
  createDepartmentAction,
  deleteDepartmentAction,
  getDepartmentAction,
  getDepartmentsAction,
  updateDepartmentAction,
} from '@/redux/actions';
import { createDepartmentUpdateState } from './create-department';
import { deleteDepartmentUpdateState } from './delete-department';
import { getDepartmentUpdateState } from './get-department';
import { getDepartmentsUpdateState } from './get-departments';
import { updateDepartmentUpdateState } from './update-department';

export type TDepartmentState = {
  createDepartmentResponse?: TCreateDepartmentResponse;
  deleteDepartmentResponse?: TDeleteDepartmentResponse;
  getDepartmentResponse?: TGetDepartmentResponse;
  getDepartmentsResponse?: TGetDepartmentsResponse;
  updateDepartmentResponse?: TUpdateDepartmentResponse;
};

const initialState: TDepartmentState = {
  createDepartmentResponse: undefined,
  deleteDepartmentResponse: undefined,
  getDepartmentResponse: undefined,
  getDepartmentsResponse: undefined,
  updateDepartmentResponse: undefined,
};

const DepartmentReducer = createReducer(initialState, (handleAction) => [
  handleAction(createDepartmentAction.success, createDepartmentUpdateState),
  handleAction(deleteDepartmentAction.success, deleteDepartmentUpdateState),
  handleAction(getDepartmentAction.success, getDepartmentUpdateState),
  handleAction(getDepartmentsAction.success, getDepartmentsUpdateState),
  handleAction(updateDepartmentAction.success, updateDepartmentUpdateState),
]);

export default DepartmentReducer;

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7548 3.14147L15.1398 7.95647C15.194 8.07868 15.2793 8.18454 15.3872 8.2635C15.4952 8.34246 15.6219 8.39175 15.7548 8.40647L21.0198 9.18647C21.1722 9.20606 21.3159 9.26863 21.4342 9.36685C21.5524 9.46508 21.6402 9.59491 21.6874 9.74119C21.7346 9.88747 21.7392 10.0442 21.7006 10.1929C21.6621 10.3417 21.582 10.4765 21.4698 10.5815L17.6748 14.3465C17.5779 14.437 17.5052 14.5502 17.4633 14.676C17.4214 14.8017 17.4116 14.936 17.4348 15.0665L18.3498 20.3615C18.3763 20.5136 18.3595 20.6701 18.3014 20.8132C18.2434 20.9563 18.1463 21.0803 18.0212 21.1709C17.8962 21.2616 17.7482 21.3153 17.5942 21.326C17.4401 21.3367 17.2861 21.304 17.1498 21.2315L12.4098 18.7265C12.2884 18.6669 12.155 18.6359 12.0198 18.6359C11.8846 18.6359 11.7511 18.6669 11.6298 18.7265L6.88976 21.2315C6.7534 21.304 6.59942 21.3367 6.44535 21.326C6.29129 21.3153 6.14332 21.2616 6.01829 21.1709C5.89325 21.0803 5.79617 20.9563 5.7381 20.8132C5.68002 20.6701 5.66327 20.5136 5.68976 20.3615L6.60476 15.0065C6.62795 14.876 6.61814 14.7417 6.57623 14.616C6.53431 14.4902 6.46162 14.377 6.36476 14.2865L2.52476 10.5815C2.41116 10.4736 2.33126 10.3351 2.29476 10.1828C2.25826 10.0305 2.26671 9.8708 2.31909 9.72317C2.37148 9.57554 2.46555 9.44626 2.58991 9.35101C2.71427 9.25575 2.86358 9.1986 3.01976 9.18647L8.28476 8.40647C8.41766 8.39175 8.54438 8.34246 8.65229 8.2635C8.76019 8.18454 8.84552 8.07868 8.89976 7.95647L11.2848 3.14147C11.3497 3.00123 11.4534 2.8825 11.5837 2.7993C11.7139 2.71609 11.8652 2.67188 12.0198 2.67188C12.1743 2.67188 12.3256 2.71609 12.4559 2.7993C12.5861 2.8825 12.6898 3.00123 12.7548 3.14147V3.14147Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { TRegulateCategory } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetRegulateCategoriesParams = unknown;

export type TGetRegulateCategoriesMaterials = {
  params?: TGetRegulateCategoriesParams;
};

export type TGetRegulateCategoriesResponse = TPaginateResponse & {
  data: TRegulateCategory[];
};

// FUNCTION

export const getRegulateCategories = async ({
  params,
}: TGetRegulateCategoriesMaterials): Promise<TGetRegulateCategoriesResponse> => {
  const response = await ApiService.get(`/regulate-category/list`, { params });
  return response.data;
};

import { navigate } from '@reach/router';

import {
  EAccountStatus,
  EAccrualType,
  EAccrueType,
  EAssetStatus,
  EAssignDate,
  EBenefitCost,
  EBenefitCostIncur,
  EBenefitType,
  EDateFormat,
  EDayOfWeek,
  EDueDate,
  EEligibilityEnd,
  EEligibilityStart,
  EEndOn,
  EEveryOrAfterTimeType,
  EGender,
  EHolidayApplyTo,
  ELanguage,
  EMaritalStatus,
  EPriority,
  ERelationship,
  ERequestStatus,
  EStartOn,
  ETaskAssignedType,
  ETaskStatus,
  ETaskTimeCustomDate,
  ETaskTypeCustomDate,
  ETimeEEligibilityEnd,
  ETimeEEligibilityStart,
  ETypeCurrency,
  ETypeRequest,
  EUserStatus,
} from '@/common/enums';
import { TAsset, TTimeOffType, TUser } from '@/common/models';
import { TTranslation } from '@/common/types';
import { EIconColor, EIconName } from '@/components/Icon';
import { TMultipleSelectCheckOption } from '@/components/MultipleSelectCheck';
import { TSelectOption } from '@/components/Select';
import { EStatusType } from '@/components/Status';
import { TQuickActionsDropdownData } from '@/containers/Header/QuickActionsDropdown';
import { Paths } from '@/pages/routers';
import { ERequestsStatusManagement } from '@/pages/RequestsManagement/RequestsManagement.enums';

/* eslint-disable no-useless-escape */
export const REGEX = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
  domain: /^[a-zA-Z0-9](?:[a-zA-Z0-9-.]*[a-zA-Z0-9])?$/i,
  alphabetic: /^[a-z\s]+$/i,
  alphanumerial: /^[a-z0-9\s]+$/i,
  numeric: /^\d+$/i,
  onlySpecialKey: /[$&+,:;=?@#|'<>.^*()%`~_!\-"\]\[\\}{'/]/g,
  timeOffHours: /[0-9]+(.[5])?/g,
};

export const localeName = {
  [ELanguage.VN]: 'vi-VN',
  [ELanguage.EN]: 'en-US',
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const dataGenderOptions = (t: TTranslation): TSelectOption[] => [
  { value: EGender.MALE, label: t('Gender_male') },
  { value: EGender.FEMALE, label: t('Gender_female') },
];

export const dataPriorityOptions = (t: TTranslation): TSelectOption[] => [
  {
    value: EPriority.HIGH,
    label: t('Priority_high'),
    data: { backgroundColor: EIconColor.RED_500, borderColor: EIconColor.RED_500 },
  },
  {
    value: EPriority.MEDIUM,
    label: t('Priority_medium'),
    data: { backgroundColor: EIconColor.YELLOW_500, borderColor: EIconColor.YELLOW_500 },
  },
  {
    value: EPriority.LOW,
    label: t('Priority_low'),
    data: { backgroundColor: EIconColor.LIME_500, borderColor: EIconColor.LIME_500 },
  },
  {
    value: EPriority.NONE,
    label: t('Priority_none'),
    data: { backgroundColor: EIconColor.WHITE, borderColor: EIconColor.GRAYSCALE_300 },
  },
];

export const dataDueDateOptions = (t: TTranslation): TSelectOption[] => [
  { value: EDueDate.NORMAL, label: t('DueDate_normal') },
  { value: EDueDate.ONBOARD, label: t('DueDate_onboarding') },
  { value: EDueDate.OFFBOARD, label: t('DueDate_offboarding') },
];

export const dataAccountStatusOptions = (t: TTranslation, notShowInviteOption?: boolean): TSelectOption[] => [
  {
    value: EAccountStatus.ACTIVE,
    label: t('AccountStatus_active'),
    data: {
      color: EStatusType.SOFT_GREEN,
    },
  },
  {
    value: EAccountStatus.DEACTIVATE,
    label: t('AccountStatus_deactivate'),
    data: {
      color: EStatusType.SOFT_RED,
    },
  },
  {
    hide: notShowInviteOption,
    value: EAccountStatus.INVITE,
    label: t('AccountStatus_invite'),
    data: {
      color: EStatusType.SOFT_VIOLET,
    },
  },
];

export const dataHolidayApplyToOptions = (t: TTranslation): TSelectOption[] => [
  { value: EHolidayApplyTo.ALL, label: t('HolidayApplyTo_all') },
  { value: EHolidayApplyTo.BASE_TYPE, label: t('HolidayApplyTo_base_type') },
  { value: EHolidayApplyTo.SPECIFIC, label: t('HolidayApplyTo_specific') },
];

export const dataBenefitTypeOptions = (t: TTranslation): TSelectOption[] => [
  { value: EBenefitType.MONETARY, label: t('BenefitType_monetary') },
  { value: EBenefitType.NON_MONETARY, label: t('BenefitType_non_monetary') },
];

export const dataEligibilityStartOptions = (t: TTranslation): TSelectOption[] => [
  { value: EEligibilityStart.ENTERED_DATE, label: t('EligibilityStart_entered_date') },
  { value: EEligibilityStart.AFTER_ONBOARD, label: t('EligibilityStart_after_onboard') },
  { value: EEligibilityStart.AFTER_PROBATION, label: t('EligibilityStart_after_probation') },
  { value: EEligibilityStart.PERIOD_AFTER_ONBOARD, label: t('EligibilityStart_period_after_onboard') },
  { value: EEligibilityStart.PERIOD_AFTER_PROBATION, label: t('EligibilityStart_period_after_probation') },
];

export const dataEligibilityEndOptions = (t: TTranslation): TSelectOption[] => [
  { value: EEligibilityEnd.ENTERED_DATE, label: t('EligibilityEnd_entered_date') },
  { value: EEligibilityEnd.AFTER_LAST_WORK_DAY, label: t('EligibilityEnd_after_last_working_day') },
  { value: EEligibilityEnd.AFTER_RESIGNATION_APPROVAL, label: t('EligibilityEnd_after_resignation_approval') },
  { value: EEligibilityEnd.BEFORE_LAST_WORK_DAY, label: t('EligibilityEnd_before_last_working_day') },
];

export const dataTimeEligibilityStartOptions = (t: TTranslation): TSelectOption[] => [
  { value: ETimeEEligibilityStart.DAYS, label: t('TimeEEligibility_days') },
  { value: ETimeEEligibilityStart.MONTHS, label: t('TimeEEligibility_months') },
  { value: ETimeEEligibilityStart.YEARS, label: t('TimeEEligibility_years') },
];

export const dataTimeEligibilityEndOptions = (t: TTranslation): TSelectOption[] => [
  { value: ETimeEEligibilityEnd.DAYS, label: t('TimeEEligibility_days') },
  { value: ETimeEEligibilityEnd.MONTHS, label: t('TimeEEligibility_months') },
];

export const dataBenefitCostOptions = (t: TTranslation): TSelectOption[] => [
  { value: EBenefitCost.ENTERED_MANUALLY, label: t('BenefitCost_entered_manually') },
  { value: EBenefitCost.FIXED_COST, label: t('BenefitCost_fixed_cost') },
];

export const dataBenefitCostIncurOptions = (t: TTranslation): TSelectOption[] => [
  { value: EBenefitCostIncur.ONCE, label: t('BenefitCostIncur_once') },
  { value: EBenefitCostIncur.MONTHLY, label: t('BenefitCostIncur_monthly') },
  { value: EBenefitCostIncur.YEARLY, label: t('BenefitCostIncur_yearly') },
];

export const dataUserStatusOptions = (t: TTranslation, user?: TUser): TSelectOption[] => [
  {
    value: EUserStatus.ACTIVE,
    label: t('UserStatus_active'),
    hide: user
      ? ![EUserStatus.ON_PROBATION, EUserStatus.ACTIVE, EUserStatus.ON_LEAVE].includes(
          user?.userStatus as unknown as EUserStatus,
        )
      : false,
    data: {
      selectStyleColor: EStatusType.SOFT_GREEN,
    },
  },
  {
    value: EUserStatus.ON_BOARDING,
    label: t('UserStatus_onboarding'),
    hide: Boolean(user),
    data: {
      selectStyleColor: EStatusType.SOFT_BLUE,
      noAction: true,
    },
  },
  {
    value: EUserStatus.OFF_BOARDING,
    label: t('UserStatus_offboarding'),
    hide: Boolean(user),
    data: {
      selectStyleColor: EStatusType.SOFT_RED,
      noAction: true,
    },
  },
  {
    value: EUserStatus.ON_LEAVE,
    label: t('UserStatus_onleave'),
    hide: user
      ? ![EUserStatus.ACTIVE, EUserStatus.ON_LEAVE].includes(user?.userStatus as unknown as EUserStatus)
      : false,
    data: {
      selectStyleColor: EStatusType.SOFT_PURPLE,
    },
  },
  {
    value: EUserStatus.ON_PROBATION,
    label: t('UserStatus_onprobation'),
    hide: user ? ![EUserStatus.ON_PROBATION].includes(user?.userStatus as unknown as EUserStatus) : false,
    data: {
      selectStyleColor: EStatusType.SOFT_VIOLET,
    },
  },
  {
    value: EUserStatus.RESIGNED,
    label: t('UserStatus_resigned'),
    hide: Boolean(user),
    data: {
      selectStyleColor: EStatusType.SOFT_GRAY,
      noAction: true,
    },
  },
];

export const dataAssetStatusOptions = (
  t: TTranslation,
  asset?: TAsset,
  showBorrowStatus?: boolean,
): TSelectOption[] => {
  const common = [
    {
      value: EAssetStatus.AVAILABLE,
      label: t('AssetStatus_active'),
      data: {
        selectStyleColor: EStatusType.SOFT_GREEN,
      },
    },
    {
      value: EAssetStatus.UNAVAILABLE,
      label: t('AssetStatus_unavailable'),
      data: {
        selectStyleColor: EStatusType.SOFT_GRAY,
      },
    },
  ];
  return showBorrowStatus
    ? [
        ...common,
        {
          value: EAssetStatus.BORROWED,
          label: t('AssetStatus_borrowed'),
          data: {
            selectStyleColor: EStatusType.SOFT_VIOLET,
            noAction: true,
          },
        },
      ]
    : common;
};

export const dataMaritalStautusOptions = (t: TTranslation): TSelectOption[] => [
  { value: EMaritalStatus.SINGLE, label: t('Marital_single') },
  { value: EMaritalStatus.MARRIED, label: t('Marital_married') },
  { value: EMaritalStatus.DIVORCED, label: t('Marital_divorced') },
  { value: EMaritalStatus.WINDOWED, label: t('Marital_windowed') },
];

export const dataDayOfWeeksOptions = (t: TTranslation): TSelectOption[] => [
  { value: EDayOfWeek.MONDAY, label: t('DayOfWeeks_mon') },
  { value: EDayOfWeek.TUESDAY, label: t('DayOfWeeks_tue') },
  { value: EDayOfWeek.WEDNESDAY, label: t('DayOfWeeks_wed') },
  { value: EDayOfWeek.THURSDAY, label: t('DayOfWeeks_thu') },
  { value: EDayOfWeek.FRIDAY, label: t('DayOfWeeks_fri') },
  { value: EDayOfWeek.SATURDAY, label: t('DayOfWeeks_sat') },
  { value: EDayOfWeek.SUNDAY, label: t('DayOfWeeks_sun') },
];

export const dataRelationshipOptions = (t: TTranslation): TSelectOption[] => {
  const common = [
    { value: ERelationship.FATHER, label: t('Relationship_father') },
    { value: ERelationship.MOTHER, label: t('Relationship_mother') },
    { value: ERelationship.FATHER_IN_LAW, label: t('Relationship_father_in_law') },
    { value: ERelationship.MOTHER_IN_LAW, label: t('Relationship_mother_in_law') },
    { value: ERelationship.CHILDREN, label: t('Relationship_children') },
    { value: ERelationship.HUSBAND, label: t('Relationship_husband') },
    { value: ERelationship.WIFE, label: t('Relationship_wife') },
    { value: ERelationship.SIBLING, label: t('Relationship_sibling') },
    // { value: ERelationship.SPOUSE, label: t('Relationship_spouse') },
    { value: ERelationship.GRAND_MOTHER, label: t('Relationship_grand_mother') },
    { value: ERelationship.GRAND_FATHER, label: t('Relationship_other_grand_father') },
    // { value: ERelationship.FRIEND, label: t('Relationship_friend') },
    { value: ERelationship.OTHER, label: t('Relationship_other') },
  ];

  return common;
};

export const dataTimeOffRequestOptions = (
  t: TTranslation,
  response: TTimeOffType[],
  user?: TUser,
  requestBehalf?: boolean,
): TQuickActionsDropdownData[] => {
  const data =
    response?.map((item) => ({
      icon: item.icon,
      key: item.id,
      title: item.title,
      onClick: (): void => {
        navigate(Paths.NewTimeOffRequest, { state: { dataClone: { user, timeOffType: item, requestBehalf } } });
      },
    })) || [];

  return [
    {
      key: 'requests',
      title: t('AssetsRequests_time_off_requests'),
      data,
    },
  ];
};

export const dataAssetsRequestOptions = (
  t: TTranslation,
  isBehalf?: boolean,
  user?: TUser,
): TQuickActionsDropdownData[] => [
  {
    key: 'assets',
    title: t('AssetsRequests_assets_requests'),
    data: [
      {
        iconName: EIconName.Asset,
        key: 'borrowing',
        title: t('AssetsRequests_assets_borrowing_request'),
        onClick: (): void => {
          navigate(Paths.NewBorrowAssetRequest, { state: { dataClone: { requestBehalf: isBehalf, user } } });
        },
      },
      {
        iconName: EIconName.Asset,
        key: 'return',
        title: t('AssetsRequests_assets_return_request'),
        onClick: (): void => {
          navigate(Paths.NewReturnAssetRequest, { state: { dataClone: { requestBehalf: isBehalf, user } } });
        },
      },
    ],
  },
];

export const dataResignRequestOptions = (t: TTranslation, isHRRole?: boolean): TQuickActionsDropdownData[] => [
  {
    key: 'resign',
    title: t('ResignRequests_resign_requests'),
    data: [
      {
        iconName: EIconName.Logout,
        key: 'resign',
        title: t('ResignRequests_resign_request'),
        onClick: (): void => {
          navigate(Paths.NewResignRequest);
        },
      },
      {
        iconName: EIconName.Logout,
        key: 'contract',
        title: t('ResignRequests_contract_termination_request'),
        hide: !isHRRole,
        onClick: (): void => {
          navigate(Paths.NewContractTerminationRequest);
        },
      },
      {
        iconName: EIconName.Logout,
        key: 'probation',
        title: t('ResignRequests_probation_termination_request'),
        hide: !isHRRole,
        onClick: (): void => {
          navigate(Paths.NewProbationTerminationRequest);
        },
      },
    ],
  },
];

export const dataAllRequestOptions = (
  t: TTranslation,
  timeOffsResponse: TTimeOffType[],
  user?: TUser,
  requestBehalf?: boolean,
  isHRRole?: boolean,
): TQuickActionsDropdownData[] => [
  ...dataTimeOffRequestOptions(t, timeOffsResponse, user, requestBehalf),
  ...dataAssetsRequestOptions(t),
  ...dataResignRequestOptions(t, isHRRole),
];

export const dataTablePerPageOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '75', value: 75 },
  { label: '100', value: 100 },
];

export const dataCurrencyOptions = (t: TTranslation, withPercent?: boolean, showSuffix?: boolean): TSelectOption[] => {
  const common = [
    { value: ETypeCurrency.VND, label: `VND ${showSuffix ? '(Vietnamese dong)' : ''}` },
    { value: ETypeCurrency.USD, label: `USD ${showSuffix ? '(U.S. dollar)' : ''}` },
    // { value: ETypeCurrency.CNY, label: `CNY ${showSuffix ? '(Chinese Renminbi)' : ''}` },
  ];

  return withPercent ? [...common, { value: ETypeCurrency.PERCENT, label: '%' }] : common;
};

export const defaultCallCommonOptionsParams = { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE * 5 };

export const dataTaskTimeCustomDateOptions = (t: TTranslation): TSelectOption[] => [
  { value: ETaskTimeCustomDate.DAYS, label: t('Common_days') },
  { value: ETaskTimeCustomDate.WEEKS, label: t('Common_weeks') },
  { value: ETaskTimeCustomDate.MONTHS, label: t('Common_months') },
];

export const dataTaskTypeCustomDateOptions = (t: TTranslation): TSelectOption[] => [
  { value: ETaskTypeCustomDate.BEFORE, label: t('Common_before') },
  { value: ETaskTypeCustomDate.AFTER, label: t('Common_after') },
];

export const dataTaskAssignedTypeOptions = (t: TTranslation): TSelectOption[] => [
  { value: ETaskAssignedType.UNASSIGN as any, label: t('TaskAssignedType_unassigned') },
  { value: ETaskAssignedType.ASSIGNOR, label: t('TaskAssignedType_checklist_assignor') },
  { value: ETaskAssignedType.HR_IN_CHARGE, label: t('TaskAssignedType_hr_in_charge') },
  { value: ETaskAssignedType.LINE_MANAGER, label: t('TaskAssignedType_line_manager') },
  { value: ETaskAssignedType.SPECIFIC, label: t('TaskAssignedType_specific_employee') },
];

export const dataRequestBoardingStatusOptions = (t: TTranslation): TMultipleSelectCheckOption[] => [
  {
    value: ERequestStatus.INVITED,
    label: t('RequestBoardingStatus_invited'),
    data: {
      color: EStatusType.SOFT_GRAY,
    },
  },
  {
    value: ERequestStatus.PROCESS,
    label: t('RequestBoardingStatus_in_process'),
    data: {
      color: EStatusType.SOFT_RED,
    },
  },
  {
    value: ERequestStatus.PENDING,
    label: t('RequestBoardingStatus_pending'),
    data: {
      color: EStatusType.SOFT_VIOLET,
    },
  },
  {
    value: ERequestStatus.NEED_REVIEW,
    label: t('RequestBoardingStatus_review_needed'),
    data: {
      color: EStatusType.SOFT_PURPLE,
    },
  },
  {
    value: ERequestStatus.COMPLETED,
    label: t('RequestBoardingStatus_completed'),
    data: {
      color: EStatusType.SOFT_GREEN,
    },
  },
  {
    value: ERequestStatus.CANCELED,
    label: t('RequestBoardingStatus_canceled'),
    data: {
      color: EStatusType.SOFT_GRAY,
    },
  },
];

export const dataTypeRequestOptions = (t: TTranslation): TSelectOption[] => [
  { value: ETypeRequest.ON_BOARD_REQUESTS, label: t('DueDate_onboarding') },
  { value: ETypeRequest.OFF_BOARD_REQUESTS, label: t('DueDate_offboarding') },
];

export const dataAccrualTypeOptions = (t: TTranslation): TSelectOption[] => [
  { value: EAccrualType.AUTO, label: t('AccrualType_auto_accrue') },
  { value: EAccrualType.CUSTOM, label: t('AccrualType_custom') },
];

export const dataEveryOrAfterTimeTypeOptions = (t: TTranslation): TSelectOption[] => [
  { value: EEveryOrAfterTimeType.DAYS, label: t('EveryOrAfterTimeType_days') },
  { value: EEveryOrAfterTimeType.WEEKS, label: t('EveryOrAfterTimeType_weeks') },
  { value: EEveryOrAfterTimeType.MONTHS, label: t('EveryOrAfterTimeType_months') },
  { value: EEveryOrAfterTimeType.YEARS, label: t('EveryOrAfterTimeType_years') },
];

export const dataAccrueTypeOptions = (t: TTranslation): TSelectOption[] => [
  // { value: EAccrueType.ANNUALLY, label: 'Annually' },
  // { value: EAccrueType.DAILY, label: 'Daily' },
  { value: EAccrueType.WEEKLY, label: t('AccrueType_weekly') },
  { value: EAccrueType.MONTHLY, label: t('AccrueType_monthly') },
  { value: EAccrueType.YEARLY, label: t('AccrueType_yearly') },
];

export const dataStartOnOptions = (t: TTranslation, accrueType?: EAccrueType): TSelectOption[] => [
  {
    value: EStartOn['1ST'],
    label: t('StartOn_1st'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['2ND'],
    label: t('StartOn_2nd'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['3RD'],
    label: t('StartOn_3rd'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['4TH'],
    label: t('StartOn_4th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['5TH'],
    label: t('StartOn_5th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['6TH'],
    label: t('StartOn_6th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['7TH'],
    label: t('StartOn_7th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['8TH'],
    label: t('StartOn_8th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['9TH'],
    label: t('StartOn_9th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['10TH'],
    label: t('StartOn_10th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['11TH'],
    label: t('StartOn_11th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['12TH'],
    label: t('StartOn_12th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['13TH'],
    label: t('StartOn_13th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['14TH'],
    label: t('StartOn_14th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['15TH'],
    label: t('StartOn_15th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['16TH'],
    label: t('StartOn_16th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['17TH'],
    label: t('StartOn_17th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['18TH'],
    label: t('StartOn_18th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['19TH'],
    label: t('StartOn_19th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['20TH'],
    label: t('StartOn_20th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['21ST'],
    label: t('StartOn_21th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['22ND'],
    label: t('StartOn_22th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['23RD'],
    label: t('StartOn_23th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['24TH'],
    label: t('StartOn_24th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['25TH'],
    label: t('StartOn_25th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['26TH'],
    label: t('StartOn_26th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['27TH'],
    label: t('StartOn_27th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['28TH'],
    label: t('StartOn_28th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['29TH'],
    label: t('StartOn_29th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['30TH'],
    label: t('StartOn_30th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn['31ST'],
    label: t('StartOn_31th'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.LAST_DAY,
    label: t('StartOn_last_day'),
    hide: [EAccrueType.WEEKLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.MONDAY,
    label: t('StartOn_monday'),
    hide: [EAccrueType.MONTHLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.TUESDAY,
    label: t('StartOn_tuesday'),
    hide: [EAccrueType.MONTHLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.WEDNESDAY,
    label: t('StartOn_wednesday'),
    hide: [EAccrueType.MONTHLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.THURSDAY,
    label: t('StartOn_thursday'),
    hide: [EAccrueType.MONTHLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.FRIDAY,
    label: t('StartOn_friday'),
    hide: [EAccrueType.MONTHLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.SATURDAY,
    label: t('StartOn_saturday'),
    hide: [EAccrueType.MONTHLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.SUNDAY,
    label: t('StartOn_sunday'),
    hide: [EAccrueType.MONTHLY, EAccrueType.YEARLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.JANUARY,
    label: t('StartOn_january'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.FEBRUARY,
    label: t('StartOn_febuary'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.MARCH,
    label: t('StartOn_march'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.APRIL,
    label: t('StartOn_april'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.MAY,
    label: t('StartOn_may'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.JUNE,
    label: t('StartOn_june'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.JULY,
    label: t('StartOn_july'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.AUGUST,
    label: t('StartOn_august'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.SEPTEMBER,
    label: t('StartOn_september'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.OCTOBER,
    label: t('StartOn_october'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.NOVEMBER,
    label: t('StartOn_november'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
  {
    value: EStartOn.DECEMBER,
    label: t('StartOn_december'),
    hide: [EAccrueType.MONTHLY, EAccrueType.WEEKLY].includes(accrueType as EAccrueType),
  },
];

export const dataStartOnTimeOptions = (t: TTranslation): TSelectOption[] => [
  { value: EStartOn['1ST'], label: t('StartOn_1st') },
  { value: EStartOn['2ND'], label: t('StartOn_2nd') },
  { value: EStartOn['3RD'], label: t('StartOn_3rd') },
  { value: EStartOn['4TH'], label: t('StartOn_4th') },
  { value: EStartOn['5TH'], label: t('StartOn_5th') },
  { value: EStartOn['6TH'], label: t('StartOn_6th') },
  { value: EStartOn['7TH'], label: t('StartOn_7th') },
  { value: EStartOn['8TH'], label: t('StartOn_8th') },
  { value: EStartOn['9TH'], label: t('StartOn_9th') },
  { value: EStartOn['10TH'], label: t('StartOn_10th') },
  { value: EStartOn['11TH'], label: t('StartOn_11th') },
  { value: EStartOn['12TH'], label: t('StartOn_12th') },
  { value: EStartOn['13TH'], label: t('StartOn_13th') },
  { value: EStartOn['14TH'], label: t('StartOn_14th') },
  { value: EStartOn['15TH'], label: t('StartOn_15th') },
  { value: EStartOn['16TH'], label: t('StartOn_16th') },
  { value: EStartOn['17TH'], label: t('StartOn_17th') },
  { value: EStartOn['18TH'], label: t('StartOn_18th') },
  { value: EStartOn['19TH'], label: t('StartOn_19th') },
  { value: EStartOn['20TH'], label: t('StartOn_20th') },
  { value: EStartOn['21ST'], label: t('StartOn_21th') },
  { value: EStartOn['22ND'], label: t('StartOn_22th') },
  { value: EStartOn['23RD'], label: t('StartOn_23th') },
  { value: EStartOn['24TH'], label: t('StartOn_24th') },
  { value: EStartOn['25TH'], label: t('StartOn_25th') },
  { value: EStartOn['26TH'], label: t('StartOn_26th') },
  { value: EStartOn['27TH'], label: t('StartOn_27th') },
  { value: EStartOn['28TH'], label: t('StartOn_28th') },
  { value: EStartOn['29TH'], label: t('StartOn_29th') },
  { value: EStartOn['30TH'], label: t('StartOn_30th') },
  { value: EStartOn['31ST'], label: t('StartOn_31th') },
  { value: EStartOn.LAST_DAY, label: t('StartOn_last_day') },
];

export const dataEndOnOptions = (t: TTranslation): TSelectOption[] => [
  { value: EEndOn.NEVER, label: t('EndOn_never') },
  { value: EEndOn.SPECIFIC_DATE, label: t('EndOn_specific_date') },
];

export const dataTaskStatusOptions = (t: TTranslation): TSelectOption[] => [
  { value: ETaskStatus.OPEN, label: t('TaskStatus_open') },
  { value: ETaskStatus.PROCESS, label: t('TaskStatus_in_progress') },
  { value: ETaskStatus.DONE, label: t('TaskStatus_done') },
];

export const dataTimeOffPaidTypeOptions = (t: TTranslation): TSelectOption[] => [
  { value: true as any, label: t('TimeOffPaidType_paid'), data: { color: EStatusType.SOFT_YELLOW } },
  { value: false as any, label: t('TimeOffPaidType_unpaid'), data: { color: EStatusType.SOFT_VIOLET } },
];

export const dataAssignDateOptions = (t: TTranslation): TSelectOption[] => [
  { value: EAssignDate.UPON_JOIN_DATE, label: t('AssignDate_upon_join_date') },
  { value: EAssignDate.AFTER_PROBATION_END_DATE, label: t('AssignDate_after_probation_end_date') },
];

export const dataTabsStatusRequestManagementOptions = (t: TTranslation): TSelectOption[] => [
  { value: ERequestsStatusManagement.PENDING, label: t('RequestsStatusManagement_pending') },
  { value: ERequestsStatusManagement.COMPLETED, label: t('RequestsStatusManagement_completed') },
];

export const dataSettingFormatShorthand = {
  [EDateFormat['DD-MMM-YYYY']]: {
    noYear: 'DD MMM',
  },
  [EDateFormat['DD/MM/YYYY']]: {
    noYear: 'DD/MM',
  },
  [EDateFormat['MM/DD/YYYY']]: {
    noYear: 'MM/DD',
  },
  [EDateFormat['YYYY-MM-DD']]: {
    noYear: 'MM-DD',
  },
};

import { createActionCreator } from 'deox';

import { TGetUsersGlobalMaterials, TGetUsersGlobalResponse } from '@/services/api/global/get-users-global';

// CONSTANTS

export enum EGetUsersGlobalAction {
  GET_USERS_GLOBAL = 'GET_USERS_GLOBAL',
  GET_USERS_GLOBAL_REQUEST = 'GET_USERS_GLOBAL_REQUEST',
  GET_USERS_GLOBAL_SUCCESS = 'GET_USERS_GLOBAL_SUCCESS',
  GET_USERS_GLOBAL_FAILED = 'GET_USERS_GLOBAL_FAILED',
}

// TYPES

export type TGetUsersGlobalRequest = {
  type: EGetUsersGlobalAction.GET_USERS_GLOBAL_REQUEST;
  payload: {
    materials: TGetUsersGlobalMaterials;
    successCallback?: (response: TGetUsersGlobalResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetUsersGlobalSuccess = {
  type: EGetUsersGlobalAction.GET_USERS_GLOBAL_SUCCESS;
  payload: { response: TGetUsersGlobalResponse };
};

export type TGetUsersGlobalFailed = { type: EGetUsersGlobalAction.GET_USERS_GLOBAL_FAILED };

// FUNCTION

export const getUsersGlobalAction = {
  request: createActionCreator(
    EGetUsersGlobalAction.GET_USERS_GLOBAL_REQUEST,
    (resolve) =>
      (
        materials: TGetUsersGlobalMaterials,
        successCallback?: (response: TGetUsersGlobalResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetUsersGlobalRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetUsersGlobalAction.GET_USERS_GLOBAL_SUCCESS,
    (resolve) =>
      (response: TGetUsersGlobalResponse): TGetUsersGlobalSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetUsersGlobalAction.GET_USERS_GLOBAL_FAILED,
    (resolve) =>
      (error: unknown): TGetUsersGlobalFailed =>
        resolve({ error }),
  ),
};

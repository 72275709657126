import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateAssignOffboardAction } from '@/redux/actions';
import { updateAssignOffboard, TUpdateAssignOffboardResponse } from '@/services/api';

// FUNCTION

export function* updateAssignOffboardSaga(action: ActionType<typeof updateAssignOffboardAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateAssignOffboard, materials);
    const updateAssignOffboardResponse: TUpdateAssignOffboardResponse = response as TUpdateAssignOffboardResponse;
    yield put(updateAssignOffboardAction.success(updateAssignOffboardResponse));
    successCallback?.(updateAssignOffboardResponse);
  } catch (err) {
    yield put(updateAssignOffboardAction.failure(err));
    failedCallback?.(err);
  }
}

import { TTypeWorkTime } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTypeWorkPaths = {
  id: string | number;
};
export type TGetTypeWorkParams = unknown;

export type TGetTypeWorkMaterials = {
  paths?: TGetTypeWorkPaths;
  params?: TGetTypeWorkParams;
};

export type TGetTypeWorkResponse = TTypeWorkTime;

// FUNCTION

export const getTypeWork = async ({ paths, params }: TGetTypeWorkMaterials): Promise<TGetTypeWorkResponse> => {
  const response = await ApiService.get(`/type-work/${paths?.id}`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TGetAssetCategoryMaterials, TGetAssetCategoryResponse } from '@/services/api/asset/get-asset-category';

// CONSTANTS

export enum EGetAssetCategoryAction {
  GET_ASSET_CATEGORY = 'GET_ASSET_CATEGORY',
  GET_ASSET_CATEGORY_REQUEST = 'GET_ASSET_CATEGORY_REQUEST',
  GET_ASSET_CATEGORY_SUCCESS = 'GET_ASSET_CATEGORY_SUCCESS',
  GET_ASSET_CATEGORY_FAILED = 'GET_ASSET_CATEGORY_FAILED',
}

// TYPES

export type TGetAssetCategoryRequest = {
  type: EGetAssetCategoryAction.GET_ASSET_CATEGORY_REQUEST;
  payload: {
    materials: TGetAssetCategoryMaterials;
    successCallback?: (response: TGetAssetCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAssetCategorySuccess = {
  type: EGetAssetCategoryAction.GET_ASSET_CATEGORY_SUCCESS;
  payload: { response: TGetAssetCategoryResponse };
};

export type TGetAssetCategoryFailed = { type: EGetAssetCategoryAction.GET_ASSET_CATEGORY_FAILED };

// FUNCTION

export const getAssetCategoryAction = {
  request: createActionCreator(
    EGetAssetCategoryAction.GET_ASSET_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TGetAssetCategoryMaterials,
        successCallback?: (response: TGetAssetCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAssetCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAssetCategoryAction.GET_ASSET_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TGetAssetCategoryResponse): TGetAssetCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAssetCategoryAction.GET_ASSET_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TGetAssetCategoryFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TCreateTemplateParams = unknown;
export type TCreateTemplateBody = unknown;

export type TCreateTemplateMaterials = {
  params?: TCreateTemplateParams;
  body?: TCreateTemplateBody;
};

export type TCreateTemplateResponse = unknown;

// FUNCTION

export const createTemplate = async ({ params, body }: TCreateTemplateMaterials): Promise<TCreateTemplateResponse> => {
  const response = await ApiService.post(`/template/new`, body, { params });
  return response.data;
};

import { TCareerHistory } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetUserCareerHistoriesPaths = {
  id: string | number;
};
export type TGetUserCareerHistoriesParams = unknown;

export type TGetUserCareerHistoriesMaterials = {
  paths?: TGetUserCareerHistoriesPaths;
  params?: TGetUserCareerHistoriesParams;
};

export type TGetUserCareerHistoriesResponse = TCareerHistory[];

// FUNCTION

export const getUserCareerHistories = async ({
  paths,
  params,
}: TGetUserCareerHistoriesMaterials): Promise<TGetUserCareerHistoriesResponse> => {
  const response = await ApiService.get(`/user/career-histories/${paths?.id}`, { params });
  return response.data;
};

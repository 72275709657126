import { createActionCreator } from 'deox';

import { TDeleteRegulationMaterials, TDeleteRegulationResponse } from '@/services/api/regulation/delete-regulation';

// CONSTANTS

export enum EDeleteRegulationAction {
  DELETE_REGULATION = 'DELETE_REGULATION',
  DELETE_REGULATION_REQUEST = 'DELETE_REGULATION_REQUEST',
  DELETE_REGULATION_SUCCESS = 'DELETE_REGULATION_SUCCESS',
  DELETE_REGULATION_FAILED = 'DELETE_REGULATION_FAILED',
}

// TYPES

export type TDeleteRegulationRequest = {
  type: EDeleteRegulationAction.DELETE_REGULATION_REQUEST;
  payload: {
    materials: TDeleteRegulationMaterials;
    successCallback?: (response: TDeleteRegulationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteRegulationSuccess = {
  type: EDeleteRegulationAction.DELETE_REGULATION_SUCCESS;
  payload: { response: TDeleteRegulationResponse };
};

export type TDeleteRegulationFailed = { type: EDeleteRegulationAction.DELETE_REGULATION_FAILED };

// FUNCTION

export const deleteRegulationAction = {
  request: createActionCreator(
    EDeleteRegulationAction.DELETE_REGULATION_REQUEST,
    (resolve) =>
      (
        materials: TDeleteRegulationMaterials,
        successCallback?: (response: TDeleteRegulationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteRegulationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteRegulationAction.DELETE_REGULATION_SUCCESS,
    (resolve) =>
      (response: TDeleteRegulationResponse): TDeleteRegulationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteRegulationAction.DELETE_REGULATION_FAILED,
    (resolve) =>
      (error: unknown): TDeleteRegulationFailed =>
        resolve({ error }),
  ),
};

import { createReducer } from 'deox';

import {
  TCreateRegulationResponse,
  TDeleteRegulationResponse,
  TDownloadRegulationResponse,
  TGetRegulationResponse,
  TGetRegulationsResponse,
  TUpdateRegulationResponse,
} from '@/services/api/regulation';
import {
  createRegulationAction,
  deleteRegulationAction,
  downloadRegulationAction,
  getRegulationAction,
  getRegulationsAction,
  updateRegulationAction,
} from '@/redux/actions';
import { createRegulationUpdateState } from './create-regulation';
import { deleteRegulationUpdateState } from './delete-regulation';
import { downloadRegulationUpdateState } from './download-regulation';
import { getRegulationUpdateState } from './get-regulation';
import { getRegulationsUpdateState } from './get-regulations';
import { updateRegulationUpdateState } from './update-regulation';

export type TRegulationState = {
  createRegulationResponse?: TCreateRegulationResponse;
  deleteRegulationResponse?: TDeleteRegulationResponse;
  downloadRegulationResponse?: TDownloadRegulationResponse;
  getRegulationResponse?: TGetRegulationResponse;
  getRegulationsResponse?: TGetRegulationsResponse;
  updateRegulationResponse?: TUpdateRegulationResponse;
};

const initialState: TRegulationState = {
  createRegulationResponse: undefined,
  deleteRegulationResponse: undefined,
  downloadRegulationResponse: undefined,
  getRegulationResponse: undefined,
  getRegulationsResponse: undefined,
  updateRegulationResponse: undefined,
};

const RegulationReducer = createReducer(initialState, (handleAction) => [
  handleAction(createRegulationAction.success, createRegulationUpdateState),
  handleAction(deleteRegulationAction.success, deleteRegulationUpdateState),
  handleAction(downloadRegulationAction.success, downloadRegulationUpdateState),
  handleAction(getRegulationAction.success, getRegulationUpdateState),
  handleAction(getRegulationsAction.success, getRegulationsUpdateState),
  handleAction(updateRegulationAction.success, updateRegulationUpdateState),
]);

export default RegulationReducer;

import { createActionCreator } from 'deox';

import {
  TGetReportTimeOffBalanceMaterials,
  TGetReportTimeOffBalanceResponse,
} from '@/services/api/report/get-report-time-off-balance';

// CONSTANTS

export enum EGetReportTimeOffBalanceAction {
  GET_REPORT_TIME_OFF_BALANCE = 'GET_REPORT_TIME_OFF_BALANCE',
  GET_REPORT_TIME_OFF_BALANCE_REQUEST = 'GET_REPORT_TIME_OFF_BALANCE_REQUEST',
  GET_REPORT_TIME_OFF_BALANCE_SUCCESS = 'GET_REPORT_TIME_OFF_BALANCE_SUCCESS',
  GET_REPORT_TIME_OFF_BALANCE_FAILED = 'GET_REPORT_TIME_OFF_BALANCE_FAILED',
}

// TYPES

export type TGetReportTimeOffBalanceRequest = {
  type: EGetReportTimeOffBalanceAction.GET_REPORT_TIME_OFF_BALANCE_REQUEST;
  payload: {
    materials: TGetReportTimeOffBalanceMaterials;
    successCallback?: (response: TGetReportTimeOffBalanceResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportTimeOffBalanceSuccess = {
  type: EGetReportTimeOffBalanceAction.GET_REPORT_TIME_OFF_BALANCE_SUCCESS;
  payload: { response: TGetReportTimeOffBalanceResponse };
};

export type TGetReportTimeOffBalanceFailed = {
  type: EGetReportTimeOffBalanceAction.GET_REPORT_TIME_OFF_BALANCE_FAILED;
};

// FUNCTION

export const getReportTimeOffBalanceAction = {
  request: createActionCreator(
    EGetReportTimeOffBalanceAction.GET_REPORT_TIME_OFF_BALANCE_REQUEST,
    (resolve) =>
      (
        materials: TGetReportTimeOffBalanceMaterials,
        successCallback?: (response: TGetReportTimeOffBalanceResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportTimeOffBalanceRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportTimeOffBalanceAction.GET_REPORT_TIME_OFF_BALANCE_SUCCESS,
    (resolve) =>
      (response: TGetReportTimeOffBalanceResponse): TGetReportTimeOffBalanceSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportTimeOffBalanceAction.GET_REPORT_TIME_OFF_BALANCE_FAILED,
    (resolve) =>
      (error: unknown): TGetReportTimeOffBalanceFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateRoleAction } from '@/redux/actions';
import { updateRole, TUpdateRoleResponse } from '@/services/api';

// FUNCTION

export function* updateRoleSaga(action: ActionType<typeof updateRoleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateRole, materials);
    const updateRoleResponse: TUpdateRoleResponse = response as TUpdateRoleResponse;
    yield put(updateRoleAction.success(updateRoleResponse));
    successCallback?.(updateRoleResponse);
  } catch (err) {
    yield put(updateRoleAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TCreateRegulationParams = unknown;
export type TCreateRegulationBody = unknown;

export type TCreateRegulationMaterials = {
  params?: TCreateRegulationParams;
  body?: TCreateRegulationBody;
};

export type TCreateRegulationResponse = unknown;

// FUNCTION

export const createRegulation = async ({
  params,
  body,
}: TCreateRegulationMaterials): Promise<TCreateRegulationResponse> => {
  const response = await ApiService.post(`/regulation/new`, body, { params });
  return response.data;
};

import { all, takeLatest } from 'redux-saga/effects';

import {
  changeStatusBenefitAction,
  createBenefitCoverageAction,
  createBenefitAction,
  deleteBenefitAction,
  getBenefitCoveragesAction,
  getBenefitAction,
  getBenefitsAction,
  getUserBenefitsHistoryAction,
  getUserBenefitsAction,
  updateBenefitAction,
} from '@/redux/actions';

import { changeStatusBenefitSaga } from './change-status-benefit';
import { createBenefitCoverageSaga } from './create-benefit-coverage';
import { createBenefitSaga } from './create-benefit';
import { deleteBenefitSaga } from './delete-benefit';
import { getBenefitCoveragesSaga } from './get-benefit-coverages';
import { getBenefitSaga } from './get-benefit';
import { getBenefitsSaga } from './get-benefits';
import { getUserBenefitsHistorySaga } from './get-user-benefits-history';
import { getUserBenefitsSaga } from './get-user-benefits';
import { updateBenefitSaga } from './update-benefit';

export default function* root(): Generator {
  yield all([
    takeLatest(changeStatusBenefitAction.request.type, changeStatusBenefitSaga),
    takeLatest(createBenefitCoverageAction.request.type, createBenefitCoverageSaga),
    takeLatest(createBenefitAction.request.type, createBenefitSaga),
    takeLatest(deleteBenefitAction.request.type, deleteBenefitSaga),
    takeLatest(getBenefitCoveragesAction.request.type, getBenefitCoveragesSaga),
    takeLatest(getBenefitAction.request.type, getBenefitSaga),
    takeLatest(getBenefitsAction.request.type, getBenefitsSaga),
    takeLatest(getUserBenefitsHistoryAction.request.type, getUserBenefitsHistorySaga),
    takeLatest(getUserBenefitsAction.request.type, getUserBenefitsSaga),
    takeLatest(updateBenefitAction.request.type, updateBenefitSaga),
  ]);
}

import { createActionCreator } from 'deox';

import { TOffboardingUserMaterials, TOffboardingUserResponse } from '@/services/api/user/offboarding-user';

// CONSTANTS

export enum EOffboardingUserAction {
  OFFBOARDING_USER = 'OFFBOARDING_USER',
  OFFBOARDING_USER_REQUEST = 'OFFBOARDING_USER_REQUEST',
  OFFBOARDING_USER_SUCCESS = 'OFFBOARDING_USER_SUCCESS',
  OFFBOARDING_USER_FAILED = 'OFFBOARDING_USER_FAILED',
}

// TYPES

export type TOffboardingUserRequest = {
  type: EOffboardingUserAction.OFFBOARDING_USER_REQUEST;
  payload: {
    materials: TOffboardingUserMaterials;
    successCallback?: (response: TOffboardingUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TOffboardingUserSuccess = {
  type: EOffboardingUserAction.OFFBOARDING_USER_SUCCESS;
  payload: { response: TOffboardingUserResponse };
};

export type TOffboardingUserFailed = { type: EOffboardingUserAction.OFFBOARDING_USER_FAILED };

// FUNCTION

export const offboardingUserAction = {
  request: createActionCreator(
    EOffboardingUserAction.OFFBOARDING_USER_REQUEST,
    (resolve) =>
      (
        materials: TOffboardingUserMaterials,
        successCallback?: (response: TOffboardingUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TOffboardingUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EOffboardingUserAction.OFFBOARDING_USER_SUCCESS,
    (resolve) =>
      (response: TOffboardingUserResponse): TOffboardingUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EOffboardingUserAction.OFFBOARDING_USER_FAILED,
    (resolve) =>
      (error: unknown): TOffboardingUserFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TCreateTimeOffTypeParams = unknown;
export type TCreateTimeOffTypeBody = unknown;

export type TCreateTimeOffTypeMaterials = {
  params?: TCreateTimeOffTypeParams;
  body?: TCreateTimeOffTypeBody;
};

export type TCreateTimeOffTypeResponse = unknown;

// FUNCTION

export const createTimeOffType = async ({
  params,
  body,
}: TCreateTimeOffTypeMaterials): Promise<TCreateTimeOffTypeResponse> => {
  const response = await ApiService.post(`/time-off-type/new`, body, { params });
  return response.data;
};

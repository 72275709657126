import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { reviewOnboardAction } from '@/redux/actions';
import { reviewOnboard, TReviewOnboardResponse } from '@/services/api';

// FUNCTION

export function* reviewOnboardSaga(action: ActionType<typeof reviewOnboardAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(reviewOnboard, materials);
    const reviewOnboardResponse: TReviewOnboardResponse = response as TReviewOnboardResponse;
    yield put(reviewOnboardAction.success(reviewOnboardResponse));
    successCallback?.(reviewOnboardResponse);
  } catch (err) {
    yield put(reviewOnboardAction.failure(err));
    failedCallback?.(err);
  }
}

import { all, takeLatest } from 'redux-saga/effects';

import {
  copyLastYearHolidaysAction,
  createHolidayAction,
  deleteHolidayAction,
  getHolidayAction,
  getHolidaysAction,
  updateHolidayAction,
} from '@/redux/actions';

import { copyLastYearHolidaysSaga } from './copy-last-year-holidays';
import { createHolidaySaga } from './create-holiday';
import { deleteHolidaySaga } from './delete-holiday';
import { getHolidaySaga } from './get-holiday';
import { getHolidaysSaga } from './get-holidays';
import { updateHolidaySaga } from './update-holiday';

export default function* root(): Generator {
  yield all([
    takeLatest(copyLastYearHolidaysAction.request.type, copyLastYearHolidaysSaga),
    takeLatest(createHolidayAction.request.type, createHolidaySaga),
    takeLatest(deleteHolidayAction.request.type, deleteHolidaySaga),
    takeLatest(getHolidayAction.request.type, getHolidaySaga),
    takeLatest(getHolidaysAction.request.type, getHolidaysSaga),
    takeLatest(updateHolidayAction.request.type, updateHolidaySaga),
  ]);
}

import { ETypeRequest } from '@/common/enums';
import { TRequest } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetRequestsParams = {
  page: number;
  pageSize: number;
  typeRequest?: ETypeRequest;
  status?: string;
  sort?: string;
  search?: string;
};

export type TGetRequestsMaterials = {
  params?: TGetRequestsParams;
};

export type TGetRequestsResponse = TPaginateResponse & {
  data: TRequest[];
};

// FUNCTION

export const getRequests = async ({ params }: TGetRequestsMaterials): Promise<TGetRequestsResponse> => {
  const response = await ApiService.get(`/request/list`, { params });
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.125 3.75C2.79348 3.75 2.47554 3.8817 2.24112 4.11611C2.0067 4.35054 1.875 4.66848 1.875 5V16.875C1.875 17.2065 2.0067 17.5245 2.24112 17.7589C2.47554 17.9932 2.79348 18.125 3.125 18.125H16.875C17.2065 18.125 17.5245 17.9932 17.7589 17.7589C17.9932 17.5245 18.125 17.2065 18.125 16.875V5C18.125 4.66848 17.9932 4.35054 17.7589 4.11611C17.5245 3.8817 17.2065 3.75 16.875 3.75H14.375"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.625 1.875V5.625" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.375 1.875V5.625" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.625 3.75H11.875" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.625 9.375C5.97018 9.375 6.25 9.09518 6.25 8.75C6.25 8.40483 5.97018 8.125 5.625 8.125C5.27983 8.125 5 8.40483 5 8.75C5 9.09518 5.27983 9.375 5.625 9.375Z"
        fill={color}
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.375C10.3452 9.375 10.625 9.09518 10.625 8.75C10.625 8.40483 10.3452 8.125 10 8.125C9.65483 8.125 9.375 8.40483 9.375 8.75C9.375 9.09518 9.65483 9.375 10 9.375Z"
        fill={color}
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 9.375C14.7201 9.375 15 9.09518 15 8.75C15 8.40483 14.7201 8.125 14.375 8.125C14.0299 8.125 13.75 8.40483 13.75 8.75C13.75 9.09518 14.0299 9.375 14.375 9.375Z"
        fill={color}
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 14.375C5.97018 14.375 6.25 14.0951 6.25 13.75C6.25 13.4048 5.97018 13.125 5.625 13.125C5.27983 13.125 5 13.4048 5 13.75C5 14.0951 5.27983 14.375 5.625 14.375Z"
        fill={color}
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.375C10.3452 14.375 10.625 14.0951 10.625 13.75C10.625 13.4048 10.3452 13.125 10 13.125C9.65483 13.125 9.375 13.4048 9.375 13.75C9.375 14.0951 9.65483 14.375 10 14.375Z"
        fill={color}
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

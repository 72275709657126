import { TAssetState } from '@/redux/reducers/asset';
import { TUpdateAssetCategorySuccess } from '@/redux/actions/asset';

export const updateAssetCategoryUpdateState = (
  state: TAssetState,
  action: TUpdateAssetCategorySuccess,
): TAssetState => ({
  ...state,
  updateAssetCategoryResponse: action.payload.response,
});

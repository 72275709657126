import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { patchTimeOffsAction } from '@/redux/actions';
import { patchTimeOffs, TPatchTimeOffsResponse } from '@/services/api';

// FUNCTION

export function* patchTimeOffsSaga(action: ActionType<typeof patchTimeOffsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(patchTimeOffs, materials);
    const patchTimeOffsResponse: TPatchTimeOffsResponse = response as TPatchTimeOffsResponse;
    yield put(patchTimeOffsAction.success(patchTimeOffsResponse));
    successCallback?.(patchTimeOffsResponse);
  } catch (err) {
    yield put(patchTimeOffsAction.failure(err));
    failedCallback?.(err);
  }
}

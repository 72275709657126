import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getUsersGlobalAction } from '@/redux/actions';
import { getUsersGlobal, TGetUsersGlobalResponse } from '@/services/api';

// FUNCTION

export function* getUsersGlobalSaga(action: ActionType<typeof getUsersGlobalAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getUsersGlobal, materials);
    const getUsersGlobalResponse: TGetUsersGlobalResponse = response as TGetUsersGlobalResponse;
    yield put(getUsersGlobalAction.success(getUsersGlobalResponse));
    successCallback?.(getUsersGlobalResponse);
  } catch (err) {
    yield put(getUsersGlobalAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TDeleteAssetMaterials, TDeleteAssetResponse } from '@/services/api/asset/delete-asset';

// CONSTANTS

export enum EDeleteAssetAction {
  DELETE_ASSET = 'DELETE_ASSET',
  DELETE_ASSET_REQUEST = 'DELETE_ASSET_REQUEST',
  DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS',
  DELETE_ASSET_FAILED = 'DELETE_ASSET_FAILED',
}

// TYPES

export type TDeleteAssetRequest = {
  type: EDeleteAssetAction.DELETE_ASSET_REQUEST;
  payload: {
    materials: TDeleteAssetMaterials;
    successCallback?: (response: TDeleteAssetResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteAssetSuccess = {
  type: EDeleteAssetAction.DELETE_ASSET_SUCCESS;
  payload: { response: TDeleteAssetResponse };
};

export type TDeleteAssetFailed = { type: EDeleteAssetAction.DELETE_ASSET_FAILED };

// FUNCTION

export const deleteAssetAction = {
  request: createActionCreator(
    EDeleteAssetAction.DELETE_ASSET_REQUEST,
    (resolve) =>
      (
        materials: TDeleteAssetMaterials,
        successCallback?: (response: TDeleteAssetResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteAssetRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteAssetAction.DELETE_ASSET_SUCCESS,
    (resolve) =>
      (response: TDeleteAssetResponse): TDeleteAssetSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteAssetAction.DELETE_ASSET_FAILED,
    (resolve) =>
      (error: unknown): TDeleteAssetFailed =>
        resolve({ error }),
  ),
};

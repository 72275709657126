import { TRegulationState } from '@/redux/reducers/regulation';
import { TDownloadRegulationSuccess } from '@/redux/actions/regulation';

export const downloadRegulationUpdateState = (
  state: TRegulationState,
  action: TDownloadRegulationSuccess,
): TRegulationState => ({
  ...state,
  downloadRegulationResponse: action.payload.response,
});

import { TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetUsersGlobalParams = {
  page: number;
  pageSize: number;
  search?: string;
};

export type TGetUsersGlobalMaterials = {
  params?: TGetUsersGlobalParams;
};

export type TGetUsersGlobalResponse = TPaginateResponse & {
  data: TUser[];
};

// FUNCTION

export const getUsersGlobal = async ({ params }: TGetUsersGlobalMaterials): Promise<TGetUsersGlobalResponse> => {
  const response = await ApiService.get(`/user/list`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTypesWorkAction } from '@/redux/actions';
import { getTypesWork, TGetTypesWorkResponse } from '@/services/api';

// FUNCTION

export function* getTypesWorkSaga(action: ActionType<typeof getTypesWorkAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTypesWork, materials);
    const getTypesWorkResponse: TGetTypesWorkResponse = response as TGetTypesWorkResponse;
    yield put(getTypesWorkAction.success(getTypesWorkResponse));
    successCallback?.(getTypesWorkResponse);
  } catch (err) {
    yield put(getTypesWorkAction.failure(err));
    failedCallback?.(err);
  }
}

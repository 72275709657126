import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_400 }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.535 7.76758H15.0647C14.4691 7.76758 13.9863 8.25039 13.9863 8.84597V19.6298C13.9863 20.2254 14.4691 20.7082 15.0647 20.7082H21.535C22.1306 20.7082 22.6134 20.2254 22.6134 19.6298V8.84597C22.6134 8.25039 22.1306 7.76758 21.535 7.76758Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3789 7.77449V4.53933C19.3789 4.25332 19.2653 3.97903 19.0631 3.77679C18.8609 3.57455 18.5866 3.46094 18.3006 3.46094H4.28151C3.99551 3.46094 3.72121 3.57455 3.51898 3.77679C3.31674 3.97903 3.20313 4.25332 3.20312 4.53933V17.48C3.20313 17.766 3.31674 18.0403 3.51898 18.2425C3.72121 18.4448 3.99551 18.5584 4.28151 18.5584H13.987"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2222 8.85156H19.3789"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { createActionCreator } from 'deox';

import {
  TGetDashboardReportMaterials,
  TGetDashboardReportResponse,
} from '@/services/api/dashboard/get-dashboard-report';

// CONSTANTS

export enum EGetDashboardReportAction {
  GET_DASHBOARD_REPORT = 'GET_DASHBOARD_REPORT',
  GET_DASHBOARD_REPORT_REQUEST = 'GET_DASHBOARD_REPORT_REQUEST',
  GET_DASHBOARD_REPORT_SUCCESS = 'GET_DASHBOARD_REPORT_SUCCESS',
  GET_DASHBOARD_REPORT_FAILED = 'GET_DASHBOARD_REPORT_FAILED',
}

// TYPES

export type TGetDashboardReportRequest = {
  type: EGetDashboardReportAction.GET_DASHBOARD_REPORT_REQUEST;
  payload: {
    materials: TGetDashboardReportMaterials;
    successCallback?: (response: TGetDashboardReportResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetDashboardReportSuccess = {
  type: EGetDashboardReportAction.GET_DASHBOARD_REPORT_SUCCESS;
  payload: { response: TGetDashboardReportResponse };
};

export type TGetDashboardReportFailed = { type: EGetDashboardReportAction.GET_DASHBOARD_REPORT_FAILED };

// FUNCTION

export const getDashboardReportAction = {
  request: createActionCreator(
    EGetDashboardReportAction.GET_DASHBOARD_REPORT_REQUEST,
    (resolve) =>
      (
        materials: TGetDashboardReportMaterials,
        successCallback?: (response: TGetDashboardReportResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetDashboardReportRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetDashboardReportAction.GET_DASHBOARD_REPORT_SUCCESS,
    (resolve) =>
      (response: TGetDashboardReportResponse): TGetDashboardReportSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetDashboardReportAction.GET_DASHBOARD_REPORT_FAILED,
    (resolve) =>
      (error: unknown): TGetDashboardReportFailed =>
        resolve({ error }),
  ),
};

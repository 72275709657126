import { createActionCreator } from 'deox';

import { TGetNationalsMaterials, TGetNationalsResponse } from '@/services/api/widen/get-nationals';

// CONSTANTS

export enum EGetNationalsAction {
  GET_NATIONALS = 'GET_NATIONALS',
  GET_NATIONALS_REQUEST = 'GET_NATIONALS_REQUEST',
  GET_NATIONALS_SUCCESS = 'GET_NATIONALS_SUCCESS',
  GET_NATIONALS_FAILED = 'GET_NATIONALS_FAILED',
}

// TYPES

export type TGetNationalsRequest = {
  type: EGetNationalsAction.GET_NATIONALS_REQUEST;
  payload: {
    materials: TGetNationalsMaterials;
    successCallback?: (response: TGetNationalsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetNationalsSuccess = {
  type: EGetNationalsAction.GET_NATIONALS_SUCCESS;
  payload: { response: TGetNationalsResponse };
};

export type TGetNationalsFailed = { type: EGetNationalsAction.GET_NATIONALS_FAILED };

// FUNCTION

export const getNationalsAction = {
  request: createActionCreator(
    EGetNationalsAction.GET_NATIONALS_REQUEST,
    (resolve) =>
      (
        materials: TGetNationalsMaterials,
        successCallback?: (response: TGetNationalsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetNationalsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetNationalsAction.GET_NATIONALS_SUCCESS,
    (resolve) =>
      (response: TGetNationalsResponse): TGetNationalsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetNationalsAction.GET_NATIONALS_FAILED,
    (resolve) =>
      (error: unknown): TGetNationalsFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TDeleteRolePaths = {
  id: string | number;
};
export type TDeleteRoleParams = unknown;

export type TDeleteRoleMaterials = {
  paths?: TDeleteRolePaths;
  params?: TDeleteRoleParams;
};

export type TDeleteRoleResponse = unknown;

// FUNCTION

export const deleteRole = async ({ paths, params }: TDeleteRoleMaterials): Promise<TDeleteRoleResponse> => {
  const response = await ApiService.delete(`/role/${paths?.id}`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TCreateDepartmentParams = unknown;
export type TCreateDepartmentBody = unknown;

export type TCreateDepartmentMaterials = {
  params?: TCreateDepartmentParams;
  body?: TCreateDepartmentBody;
};

export type TCreateDepartmentResponse = unknown;

// FUNCTION

export const createDepartment = async ({
  params,
  body,
}: TCreateDepartmentMaterials): Promise<TCreateDepartmentResponse> => {
  const response = await ApiService.post(`/department/new`, body, { params });
  return response.data;
};

export enum EButtonStyleType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  OUTLINE_PRIMARY = 'OUTLINE_PRIMARY',
  TEXT_BLACK = 'TEXT_BLACK',
  TEXT_PRIMARY = 'TEXT_PRIMARY',
  TEXT_DANGER = 'TEXT_DANGER',
  DANGER = 'DANGER',
  TEXT_SUCCESS = 'TEXT_SUCCESS',
}

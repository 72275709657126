import { createActionCreator } from 'deox';

import { TRehireUserMaterials, TRehireUserResponse } from '@/services/api/user/rehire-user';

// CONSTANTS

export enum ERehireUserAction {
  REHIRE_USER = 'REHIRE_USER',
  REHIRE_USER_REQUEST = 'REHIRE_USER_REQUEST',
  REHIRE_USER_SUCCESS = 'REHIRE_USER_SUCCESS',
  REHIRE_USER_FAILED = 'REHIRE_USER_FAILED',
}

// TYPES

export type TRehireUserRequest = {
  type: ERehireUserAction.REHIRE_USER_REQUEST;
  payload: {
    materials: TRehireUserMaterials;
    successCallback?: (response: TRehireUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRehireUserSuccess = {
  type: ERehireUserAction.REHIRE_USER_SUCCESS;
  payload: { response: TRehireUserResponse };
};

export type TRehireUserFailed = { type: ERehireUserAction.REHIRE_USER_FAILED };

// FUNCTION

export const rehireUserAction = {
  request: createActionCreator(
    ERehireUserAction.REHIRE_USER_REQUEST,
    (resolve) =>
      (
        materials: TRehireUserMaterials,
        successCallback?: (response: TRehireUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRehireUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERehireUserAction.REHIRE_USER_SUCCESS,
    (resolve) =>
      (response: TRehireUserResponse): TRehireUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERehireUserAction.REHIRE_USER_FAILED,
    (resolve) =>
      (error: unknown): TRehireUserFailed =>
        resolve({ error }),
  ),
};

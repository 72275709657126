import { TNational } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetNationalsParams = unknown;

export type TGetNationalsMaterials = {
  params?: TGetNationalsParams;
};

export type TGetNationalsResponse = TNational[];

// FUNCTION

export const getNationals = async ({ params }: TGetNationalsMaterials): Promise<TGetNationalsResponse> => {
  const response = await ApiService.get(`/widen/national/list`, { params });
  return response.data;
};

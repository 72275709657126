import { createActionCreator } from 'deox';

import { TGetJobMaterials, TGetJobResponse } from '@/services/api/job/get-job';

// CONSTANTS

export enum EGetJobAction {
  GET_JOB = 'GET_JOB',
  GET_JOB_REQUEST = 'GET_JOB_REQUEST',
  GET_JOB_SUCCESS = 'GET_JOB_SUCCESS',
  GET_JOB_FAILED = 'GET_JOB_FAILED',
}

// TYPES

export type TGetJobRequest = {
  type: EGetJobAction.GET_JOB_REQUEST;
  payload: {
    materials: TGetJobMaterials;
    successCallback?: (response: TGetJobResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetJobSuccess = {
  type: EGetJobAction.GET_JOB_SUCCESS;
  payload: { response?: TGetJobResponse };
};

export type TGetJobFailed = { type: EGetJobAction.GET_JOB_FAILED };

// FUNCTION

export const getJobAction = {
  request: createActionCreator(
    EGetJobAction.GET_JOB_REQUEST,
    (resolve) =>
      (
        materials: TGetJobMaterials,
        successCallback?: (response: TGetJobResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetJobRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetJobAction.GET_JOB_SUCCESS,
    (resolve) =>
      (response?: TGetJobResponse): TGetJobSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetJobAction.GET_JOB_FAILED,
    (resolve) =>
      (error: unknown): TGetJobFailed =>
        resolve({ error }),
  ),
};

import { TUser } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetMyInfoParams = unknown;

export type TGetMyInfoMaterials = {
  params?: TGetMyInfoParams;
};

export type TGetMyInfoResponse = TUser;

// FUNCTION

export const getMyInfo = async ({ params }: TGetMyInfoMaterials): Promise<TGetMyInfoResponse> => {
  const response = await ApiService.get(`/user/detail/my-info`, { params });
  return response.data;
};

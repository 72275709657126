import { TReportState } from '@/redux/reducers/report';
import { TDownloadEmployeeTurnoverRateSuccess } from '@/redux/actions/report';

export const downloadEmployeeTurnoverRateUpdateState = (
  state: TReportState,
  action: TDownloadEmployeeTurnoverRateSuccess,
): TReportState => ({
  ...state,
  downloadEmployeeTurnoverRateResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import {
  TContractProbationRequestMaterials,
  TContractProbationRequestResponse,
} from '@/services/api/request/contract-probation-request';

// CONSTANTS

export enum EContractProbationRequestAction {
  CONTRACT_PROBATION_REQUEST = 'CONTRACT_PROBATION_REQUEST',
  CONTRACT_PROBATION_REQUEST_REQUEST = 'CONTRACT_PROBATION_REQUEST_REQUEST',
  CONTRACT_PROBATION_REQUEST_SUCCESS = 'CONTRACT_PROBATION_REQUEST_SUCCESS',
  CONTRACT_PROBATION_REQUEST_FAILED = 'CONTRACT_PROBATION_REQUEST_FAILED',
}

// TYPES

export type TContractProbationRequestRequest = {
  type: EContractProbationRequestAction.CONTRACT_PROBATION_REQUEST_REQUEST;
  payload: {
    materials: TContractProbationRequestMaterials;
    successCallback?: (response: TContractProbationRequestResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TContractProbationRequestSuccess = {
  type: EContractProbationRequestAction.CONTRACT_PROBATION_REQUEST_SUCCESS;
  payload: { response: TContractProbationRequestResponse };
};

export type TContractProbationRequestFailed = {
  type: EContractProbationRequestAction.CONTRACT_PROBATION_REQUEST_FAILED;
};

// FUNCTION

export const contractProbationRequestAction = {
  request: createActionCreator(
    EContractProbationRequestAction.CONTRACT_PROBATION_REQUEST_REQUEST,
    (resolve) =>
      (
        materials: TContractProbationRequestMaterials,
        successCallback?: (response: TContractProbationRequestResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TContractProbationRequestRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EContractProbationRequestAction.CONTRACT_PROBATION_REQUEST_SUCCESS,
    (resolve) =>
      (response: TContractProbationRequestResponse): TContractProbationRequestSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EContractProbationRequestAction.CONTRACT_PROBATION_REQUEST_FAILED,
    (resolve) =>
      (error: unknown): TContractProbationRequestFailed =>
        resolve({ error }),
  ),
};

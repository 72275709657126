import { TChecklistState } from '@/redux/reducers/checklist';
import { TUpdateAssignOffboardSuccess } from '@/redux/actions/checklist';

export const updateAssignOffboardUpdateState = (
  state: TChecklistState,
  action: TUpdateAssignOffboardSuccess,
): TChecklistState => ({
  ...state,
  updateAssignOffboardResponse: action.payload.response,
});

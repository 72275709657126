const dataSources = {
  Common_previous: 'Previous',
  Common_next: 'Next',
  Common_enter_data: 'Enter data',
  Common_select_date: 'Select date',
  Common_select_date_start_end: 'Start date - End date',
  Common_select_with_dash: '-- Select --',
  Common_close: 'Close',
  Common_confirm: 'Confirm',
  Common_cancel: 'Cancel',
  Common_submit: 'Submit',
  Common_submit_request: 'Submit Request',
  Common_delete: 'Delete',
  Common_save_changes: 'Save Changes',
  Common_days: 'days',
  Common_weeks: 'weeks',
  Common_months: 'months',
  Common_before: 'before',
  Common_after: 'after',
  Common_change: 'Change',
  Common_back: 'Back',
  Common_activate: 'activate',
  Common_deactivate: 'deactivate',
  Common_create: 'Create',
  Common_update: 'Update',
  Common_hrs: 'hr(s)',
  Common_hours: 'hour(s)',
  Common_search: 'Search',
  Common_days_bracket: 'day(s)',
  Common_all_categories: 'All Categories',
  Common_all: 'All',

  Tooltip_click_to: 'Click to',

  NewCategory: 'New Category',
  NewCategory_placeholder: 'Enter Category title',
  NewCategory_create: 'Create Category',
  NewCategory_update: 'Update Category',

  Table_items_per_page: 'Items per page',
  Table_page: 'Page',
  Table_sort_asc: 'Click to sort ascending',
  Table_sort_desc: 'Click to sort descending',
  Table_sort_cancel: 'Click to cancel sorting',

  Gender_male: 'Male',
  Gender_female: 'Female',

  Marital_single: 'Single',
  Marital_married: 'Married',
  Marital_divorced: 'Divorced',
  Marital_windowed: 'Widowed',

  Header_self_service: 'Self-service',
  Header_people: 'People',
  Header_assets: 'Assets',
  Header_checklists: 'Checklists',
  Header_report: 'Reports',
  Header_requests: 'Requests',
  Header_company_regulations: 'Regulations',
  Header_search_placeholder: 'Search Employee',

  AccountDropdown_account_settings: 'Account Settings',
  AccountDropdown_change_password: 'Change Password',
  AccountDropdown_logout: 'Log Out',
  AccountDropdown_give_us_feedback: 'Give Us Feedback',

  QuickActionsDropdown_create_new: 'Create New',
  QuickActionsDropdown_new_employee: 'New Employee',
  QuickActionsDropdown_new_assets: 'New Assets',
  QuickActionsDropdown_new_regulations: 'New Regulations',
  QuickActionsDropdown_import: 'Import',
  QuickActionsDropdown_import_employees: 'Import Employees',

  DatePicker_selected: 'Selected',
  DatePicker_today: 'Today',

  MemberCard_joined_on: 'Joined on',

  Empty_there_nothing_here: 'There’s nothing here.',

  Onboarding_title: 'Before officially joining, we would like to know more about your...',
  Onboarding_basic_information: 'Basic Information',
  Onboarding_home_address: 'Home Address',
  Onboarding_banking_info: 'Banking Info',
  Onboarding_emergency_contact: 'Emergency Contact',
  Onboarding_dependants: 'Dependants',
  Onboarding_social_media: 'Social Media',
  Onboarding_checklist: 'Checklist',
  Onboarding_finish_onboarding: 'Finish Onboarding',
  Onboarding_profile_photo: 'Profile Photo',
  Onboarding_first_name: 'First Name',
  Onboarding_last_name: 'Last Name',
  Onboarding_date_of_birth: 'Date Of Birth',
  Onboarding_nationality: 'Nationality',
  Onboarding_gender: 'Gender',
  Onboarding_marital_status: 'Marital Status',
  Onboarding_phone_number: 'Phone Number',
  Onboarding_personal_email: 'Personal Email',
  Onboarding_work_email: 'Work Email',
  Onboarding_personal_id: 'Personal ID No.',
  Onboarding_date_issued: 'Date Issued',
  Onboarding_place_issued: 'Place Issued',
  Onboarding_personal_tax: 'Personal Tax No.',
  Onboarding_social_insurance: 'Social Insurance No.',
  Onboarding_health_insurance: 'Health Insurance No.',
  Onboarding_country: 'Country',
  Onboarding_state_province: 'State / Province',
  Onboarding_permanent_address: 'Permanent Address',
  Onboarding_current_address: 'Current Address',
  Onboarding_bank_name: 'Bank Name',
  Onboarding_branch_name: 'Branch Name',
  Onboarding_account_name: 'Account Name',
  Onboarding_account_number: 'Account Number',
  Onboarding_full_name: 'Full Name',
  Onboarding_relationship: 'Relationship',
  Onboarding_contact: 'Contact',
  Onboarding_default: 'Default',
  Onboarding_add: 'Add',
  Onboarding_dependant_empty: 'If you have any dependants, please add them here to receive full social benefits.',
  Onboarding_contact_empty: 'If you have any emergency, please add them here to receive full social benefits.',
  Onboarding_dependant: 'Dependant',
  Onboarding_not_have_personal_id: 'This person does not have Personal ID',

  OnboardingProcessing_title_success: 'Onboarding Successful!',
  OnboardingProcessing_description_success:
    'Your Onboarding Information has been approved.\nWelcome to Acazia Software!',
  OnboardingProcessing_title_process: 'Reviewing Onboarding Information...',
  OnboardingProcessing_description_process:
    'To prevent future mistakes, we need to review your onboarding information before continuing. Once approved, you will be able to access your workspace.\n\nThis would not take long. Thank you for your patience!',
  OnboardingProcessing_title_failed: 'Onboarding Failed!',
  OnboardingProcessing_description_failed:
    'Your Onboarding Information has been reviewed. Some information has not met our requirements.\nPlease complete all required information before submitting again.',
  OnboardingProcessing_title_access_denied: 'Access Denied',
  OnboardingProcessing_description_access_denied: `You don't have permission to access system.\nPlease contact your System Administrator !`,
  OnboardingProcessing_go_to_dashboard: 'Go to Dashboard',
  OnboardingProcessing_back_to_login: 'Back to Login',
  OnboardingProcessing_back_to_onboarding: 'Back to Onboarding',
  OnboardingProcessing_title_forbidden: 'Access Denied',
  OnboardingProcessing_description_forbidden: `You don't have permission to access feature.\nPlease contact your System Administrator !`,
  OnboardingProcessing_back_to_home: 'Back to Dashboard',

  Onboarding_department: 'Department',
  Onboarding_employment_type: 'Employment Type',
  Onboarding_job_title: 'Job Title',
  Onboarding_line_manager: 'Line Manager',
  Onboarding_branch: 'Branch',
  Onboarding_current_password: 'Current Password',
  Onboarding_new_password: 'New Password',
  Onboarding_re_new_password: 'Re-enter New Password',
  Onboarding_join_date: 'Join Date',
  Onboarding_probation_start: 'Probation Start',
  Onboarding_probation_end: 'Probation End',
  Onboarding_contract_start: 'Contract Start',
  Onboarding_contract_end: 'Contract End',
  Onboarding_job_tenure: 'Job Tenure',

  TimeOffCard_hrs_left: 'hrs left',
  TimeOffCard_request_time_off: 'Request Time-Off',
  TimeOffCard_request_annual: 'Annual',
  TimeOffCard_request_paternity: 'Parernity',
  TimeOffCard_request_wedding: 'Wedding',
  TimeOffCard_request_funeral: 'Funeral',
  TimeOffCard_request_sick: 'Sick',
  TimeOffCard_request_covid: 'Covid-19',
  TimeOffCard_request_unpaid: 'Unpaid',
  TimeOffCard_request_annual_leave: 'Annual Leave',
  TimeOffCard_request_paternity_leave: 'Parernity Leave',
  TimeOffCard_request_wedding_leave: 'Wedding Leave',
  TimeOffCard_request_funeral_leave: 'Funeral Leave',
  TimeOffCard_request_sick_leave: 'Sick Leave',
  TimeOffCard_request_covid_leave: 'Covid-19 Leave',
  TimeOffCard_request_unpaid_leave: 'Unpaid Leave',

  DashboardCard_your_request: 'Your Requests',
  DashboardCard_company_time_off: 'Company Time-Off',
  DashboardCard_company_time_off_today: 'Today',
  DashboardCard_company_time_off_tomorrow: 'Tomorrow',
  DashboardCard_company_holidays: 'Holidays',
  DashboardCard_company_celebration: 'Celebration',
  DashboardCard_company_links: 'Company Links',
  DashboardCard_update: 'Update',
  DashboardCard_notifications: 'Notifications',
  DashboardCard_new_members: 'New Mebmers',
  DashboardCard_reports: 'Reports',
  DashboardCard_all_reports: 'All Reports',

  ModalLogout_title: 'Log Out',
  ModalLogout_description: 'Are you sure you want to logout this session?',

  Priority_none: 'None',
  Priority_low: 'Low Priority',
  Priority_medium: 'Medium Priority',
  Priority_high: 'High Priority',

  DueDate_normal: 'Normal',
  DueDate_onboarding: 'Onboarding',
  DueDate_offboarding: 'Offboarding',

  AccountStatus_active: 'Active',
  AccountStatus_deactivate: 'Inactive',
  AccountStatus_invite: 'Invited',

  HolidayApplyTo_all: 'All Employees',
  HolidayApplyTo_base_type: 'Based On Employment Type',
  HolidayApplyTo_specific: 'Specific Employees',

  BenefitType_monetary: 'Monetary',
  BenefitType_non_monetary: 'Non-monetary',

  EligibilityStart_entered_date: 'On Manually Entered Date',
  EligibilityStart_after_onboard: 'Immediately Upon Onboarding',
  EligibilityStart_after_probation: 'Immediately After Probation',
  EligibilityStart_period_after_onboard: 'After A Period Upon Onboarding',
  EligibilityStart_period_after_probation: 'After A Period Upon End Of Probation',

  EligibilityEnd_entered_date: 'On Manually Entered Date',
  EligibilityEnd_after_last_working_day: 'Immediately After Last Working Day',
  EligibilityEnd_after_resignation_approval: 'Immediately After Resignation Approval',
  EligibilityEnd_before_last_working_day: 'Before Last Working Day',

  TimeEEligibility_days: 'Days',
  TimeEEligibility_months: 'Months',
  TimeEEligibility_years: 'Years',

  BenefitCost_entered_manually: 'Entered Manually',
  BenefitCost_fixed_cost: 'Fixed Cost',

  BenefitCostIncur_once: 'Once',
  BenefitCostIncur_monthly: 'Monthly',
  BenefitCostIncur_yearly: 'Yearly',

  UserStatus_active: 'Active',
  UserStatus_onboarding: 'Onboarding',
  UserStatus_offboarding: 'Offboarding',
  UserStatus_onleave: 'On Leave',
  UserStatus_onprobation: 'On Probation',
  UserStatus_resigned: 'Resigned',

  AssetStatus_active: 'Available',
  AssetStatus_unavailable: 'Unavailable',
  AssetStatus_borrowed: 'Borrowed',

  DayOfWeeks_mon: 'Mon',
  DayOfWeeks_tue: 'Tue',
  DayOfWeeks_wed: 'Wed',
  DayOfWeeks_thu: 'Thu',
  DayOfWeeks_fri: 'Fri',
  DayOfWeeks_sat: 'Sat',
  DayOfWeeks_sun: 'Sun',

  Relationship_father: 'Father',
  Relationship_mother: 'Mother',
  Relationship_spouse: 'Spouse',
  Relationship_sibling: 'Sibling',
  Relationship_friend: 'Friend',
  Relationship_other: 'Other',
  Relationship_father_in_law: 'Father in-law',
  Relationship_mother_in_law: 'Mother in-law',
  Relationship_children: 'Children',
  Relationship_husband: 'Husband',
  Relationship_wife: 'Wife',
  Relationship_other_grand_father: 'Grand Father',
  Relationship_grand_mother: 'Grand Mother',

  AssetsRequests_assets_requests: 'Assets Requests',
  AssetsRequests_time_off_requests: 'Time-off Requests',
  AssetsRequests_assets_borrowing_request: 'Assets Borrowing Request',
  AssetsRequests_assets_return_request: 'Assets Return Request',

  ResignRequests_resign_requests: 'Resign Requests',
  ResignRequests_resign_request: 'Resign Request',
  ResignRequests_contract_termination_request: 'Contract Termination Request',
  ResignRequests_probation_termination_request: 'Probation Termination Request',

  PersonalCard_general: 'General',
  PersonalCard_employee_information: 'Employee Information',
  PersonalCard_line_manager: 'Line Manager',
  PersonalCard_social_media: 'Social Media',

  SelfService_tab_personal_info: 'Personal Info',
  SelfService_tab_career: 'Career',
  SelfService_tab_benefits: 'Benefits',
  SelfService_tab_time_off: 'Time Off',
  SelfService_tab_assets: 'Assets',
  SelfService_tab_documents: 'Documents',

  Peoples_new_employee: 'New Employee',
  Peoples_invite_employee: 'Invite Employee',
  Peoples_import_employee: 'Import Employee',
  Peoples_people: 'People',
  Peoples_total: 'Total',
  Peoples_search_placeholder: 'Search Name, Email...',
  Peoples_filter: 'Filter',
  Peoples_download: 'Download',
  Peoples_empty_select: 'Select “New Employee” on the top right corner to add employees here.',

  PeoplesTable_employees_id: 'Employees ID',
  PeoplesTable_employees: 'Employees',
  PeoplesTable_job_title: 'Job Title',
  PeoplesTable_department: 'Department',
  PeoplesTable_joined_on: 'Joined On',
  PeoplesTable_employment_type: 'Employment Type',
  PeoplesTable_employee_status: 'Employee Status',
  PeoplesTable_view_onboarding_progress: 'View Onboarding Progress',
  PeoplesTable_view_offboarding_progress: 'View Offboarding Progress',
  PeoplesTable_report_violation: 'Report Violation',
  PeoplesTable_regulation_violation_des: 'Show employees flagged due to regulations violation.',
  PeoplesTable_regulation_violation: 'Regulation Violation',
  PeoplesTable_view_report_violation: 'View Report Violation',
  PeoplesTable_delete_report_violation: 'Delete Report Violation',
  PeoplesTable_offboarding: 'Offboarding',
  PeoplesTable_complete_offboarding: 'Complete Offboarding',
  PeoplesTable_cancel_offboarding: 'Cancel Offboarding',
  PeoplesTable_rehire: 'Rehire',
  PeoplesTable_reason_for_leaving: 'Reason for Leaving',
  PeoplesTable_resignation_date: 'Resignation Date',
  PeoplesTable_last_working_date: 'Last Working Date',
  PeoplesTable_length_of_service: 'Length Of Service',

  UploadDragDrop_text: 'Drag and drop or browse your files.',

  ModalImportEmployee_title: 'Import Employee',
  ModalImportEmployee_description: 'Import your employees file here.',
  ModalImportEmployee_or: 'or',
  ModalImportEmployee_download: 'Download Employee Template',

  ModalReportViolation_title: 'Report Violations',
  ModalReportViolation_detail_title: 'Detail Violations Report',
  ModalReportViolation_detail_desc: 'has been flagged due to regulation violations.',
  ModalReportViolation_desc: 'will be marked due to regulation violations. Please specify your reasons for reporting.',
  ModalReportViolation_label: 'Reasons For Report',
  ModalReportViolation_add_file: 'Add File',

  ModalDeleteReportViolation_title: 'Remove Violation Report',

  ModalChangePeopleStatus_title_1: 'Change Employment Type',
  ModalChangePeopleStatus_title_2: 'Change Employee Status',
  ModalChangePeopleStatus_changing: 'Changing',
  ModalChangePeopleStatus_employment_type: 'employment type',
  ModalChangePeopleStatus_employee_status: 'employee status',
  ModalChangePeopleStatus_desc_1: 'would affect other information such as Time Off, Benefits,...',
  ModalChangePeopleStatus_desc_2: 'Are you sure you want to change',
  ModalChangePeopleStatus_from: 'from',
  ModalChangePeopleStatus_to: 'to',

  InvitePeople_back_to_people: 'Back to People',
  InvitePeople_title: 'Invite Employee',
  InvitePeople_desc:
    'New employee will be sent an invitation to create HRM account via email. Once the invitation is accepted, this employee will be added to Checklists for Onboarding.',

  NavigationSidePanel_settings: 'Settings',
  NavigationSidePanel_general: 'General',
  NavigationSidePanel_company_information: 'Company Information',
  NavigationSidePanel_approvals: 'Approvals',
  NavigationSidePanel_requets_approvers: 'Requests Approvers',
  NavigationSidePanel_requets_cc_recipients: `Requests CC'ed Recipients`,
  NavigationSidePanel_time_offs: 'Time-offs',
  NavigationSidePanel_holidays: 'Holidays',
  NavigationSidePanel_benefits: 'Benefits',
  NavigationSidePanel_users: 'User Access Control',
  NavigationSidePanel_permissions: 'Permissions',
  NavigationSidePanel_checklists_template: 'Checklists Template',
  NavigationSidePanel_integration: 'Integration',

  ImageGalleryPicker_add_new_picture: 'Add new picture',

  General_back_to_general: 'Back to General Settings',
  General_date_input_format: 'Date Input Format',
  General_time_zone: 'Time Zone',
  General_default_currency: 'Default Currency',
  General_language: 'Language',
  General_background_picture: 'Background Picture',
  General_background_picture_desc: 'Personalized your workspace with background picture.',
  General_change_password: 'Change password',
  General_change_password_desc: 'Change your current password.',

  ModalChangePasswordSuccess_title: 'Password Changed Successfully',
  ModalChangePasswordSuccess_desc:
    'Your password has been changed successfully. You will be logged out of Acazia HR. Please log in with your new password.',
  ModalChangePasswordSuccess_logout: 'Log Out',

  Language_vn: 'Vietnamese',
  Language_en: 'English',

  Company_basic_info: 'Basic Info',
  Company_branch: 'Branch',
  Company_department: 'Department',
  Company_job_title: 'Job Title',
  Company_employment_type: 'Employment Type',
  Company_employee_id: 'Employee ID',

  CompanyBasicInfo_company_name: 'Company Name',
  CompanyBasicInfo_date_of_establishment: 'Date Of Establishment',
  CompanyBasicInfo_company_logo: 'Company Logo',
  CompanyBasicInfo_company_logo_desc: 'Your company logo will appear on website’s header.',
  CompanyBasicInfo_upload_desc_1: 'Maximum file size: 2MB. File format: png, jpg, jpeg.',
  CompanyBasicInfo_upload_desc_2: 'Logo with transparent background is recommended.',
  CompanyBasicInfo_upload_placeholder: 'Your Logo',
  CompanyBasicInfo_company_links: 'Company Links',
  CompanyBasicInfo_company_links_desc: 'Company links will appear on Dashboard.',
  CompanyBasicInfo_add_new_link: 'Add new link',

  CompanyBranch_new_branch: 'New Branch',
  CompanyBranch_edit_branch: 'Edit Branch',
  CompanyBranch_delete_branch: 'Delete Branch',
  CompanyBranch_title_branch: 'Branch Title',
  CompanyBranch_address_branch: 'Branch Address',
  CompanyBranch_working_days_branch: 'Working Days',
  CompanyBranch_employees: 'Employees',
  CompanyBranch_working_time: 'Working Time',
  CompanyBranch_time_zone: 'Time Zone',
  CompanyBranch_pic: 'PIC',
  CompanyBranch_pic_suffix: 'PIC (Person-In-Charge)',
  CompanyBranch_contact_email: 'Contact Email',
  CompanyBranch_contact_number: 'Contact Number',

  ModalDeleteBranch_title: 'Delete Branch',
  ModalDeleteBranch_desc_1: 'This branch has',
  ModalDeleteBranch_desc_2: 'employees. Their branch information will be empty once deletion is confirmed.',
  ModalDeleteBranch_desc_3: 'Are you sure you want to delete this branch?',

  BranchDetail_new: 'New',
  BranchDetail_edit: 'Edit',
  BranchDetail_back_to_branch: 'Back to Branch Settings',

  Department_title: 'Department Title',
  Department_no_of_employees: 'No. Of Employees',
  Department_leader: 'Leader',
  Department_sub_leader: 'Sub Leaders',
  Department_new_department: 'New Department',
  Department_edit_department: 'Edit Department',
  Department_delete_department: 'Delete Department',

  DepartmentDetail_back_to_department: 'Back to Department Settings',
  DepartmentDetail_department_leader: 'Department Leader',

  ModalDeleteDepartment_title: 'Delete Department',
  ModalDeleteDepartment_desc_1:
    'employee is in this department. Some of their information such as Time-off, Benefits,... might be affected once deletion is confirmed.',
  ModalDeleteDepartment_desc_2: 'Are you sure you want to delete Department',

  JobTitle_new_job_title: 'New Job Title',
  JobTitle_edit_job_title: 'Edit Job Title',
  JobTitle_delete_job_title: 'Delete Job Title',

  JobTitleDetail_back_to_job_title: 'Back to Job Title Settings',
  JobTitleDetail_status: 'Status',

  ModalDeleteJobTitle_desc_1:
    'employee is holding this job title. Some of their information such as Time-off, Benefits,... might be affected once deletion is confirmed.',
  ModalDeleteJobTitle_desc_2: 'Are you sure you want to delete job title',

  ModalDeactivateJobTitle_title: 'Deactivate Job Title',
  ModalDeactivateJobTitle_desc_1:
    'employees is holding this job title. Some of their information such as Time-off, Benefits,... might be affected once deactivation is confirmed.',
  ModalDeactivateJobTitle_desc_2: 'Are you sure you want to deactivate job title',

  EmploymentType_new_employment_type: 'New Employment Type',
  EmploymentType_edit_employment_type: 'Edit Employment Type',
  EmploymentType_delete_employment_type: 'Delete Employment Type',

  EmploymentTypeDetail_back_to_employment_type: 'Back to Employment Type Settings',

  ModalDeleteEmploymentType_desc_1:
    'employee is holding this job title. Some of their information such as Time-off, Benefits,... might be affected once deletion is confirmed.',
  ModalDeleteEmploymentType_desc_2: 'Are you sure you want to delete job title',

  ModalDeactivateEmploymentType_title: 'Deactivate Employment Type',
  ModalDeactivateEmploymentType_desc_1:
    'employees is holding this job title. Some of their information such as Time-off, Benefits,... might be affected once deactivation is confirmed.',
  ModalDeactivateEmploymentType_desc_2: 'Are you sure you want to deactivate job title',

  EmploymentTypeDetail_title: 'Employment Type Title',
  EmploymentTypeDetail_status: 'Status',

  EmployeeID_title: 'Auto Generate Employee ID',
  EmployeeID_desc: 'Turn On and Off Employee ID generation',
  EmployeeID_example: 'Ex: Employee ID is AZ-000001',
  EmployeeID_tooltip:
    'Employee ID consists a 2-digit prefix and a series of numbers.\nYou can set ID prefix as any characters (AB, MD,...).',
  EmployeeID_title_btn: 'Configure Employee ID',

  Carrer_overview: 'Career Overview',
  Carrer_new_resign_request: 'New Resign Request',
  Carrer_new_contract_termination_request: 'New Contract Termination Request',
  Carrer_history: 'Career History',

  TaskAssignedType_unassigned: 'Unassigned',
  TaskAssignedType_checklist_assignor: '@Checklist Assignor',
  TaskAssignedType_hr_in_charge: '@HR In Charge',
  TaskAssignedType_line_manager: '@Line Manager',
  TaskAssignedType_specific_employee: 'Specific Employee',

  RequestBoardingStatus_invited: 'Invited',
  RequestBoardingStatus_in_process: 'In Process',
  RequestBoardingStatus_review_needed: 'Review Needed',
  RequestBoardingStatus_pending: 'Pending',
  RequestBoardingStatus_completed: 'Completed',
  RequestBoardingStatus_canceled: 'Canceled',

  TaskStatus_open: 'Open',
  TaskStatus_in_progress: 'In Progress',
  TaskStatus_done: 'Done',

  EndOn_never: 'Never',
  EndOn_specific_date: 'Specific Date',

  AccrualType_auto_accrue: 'Auto Accrue',
  AccrualType_custom: 'Custom',

  EveryOrAfterTimeType_days: 'Days',
  EveryOrAfterTimeType_weeks: 'Weeks',
  EveryOrAfterTimeType_months: 'Months',
  EveryOrAfterTimeType_years: 'Years',

  AccrueType_weekly: 'Weekly',
  AccrueType_monthly: 'Monthly',
  AccrueType_yearly: 'Yearly',

  StartOn_1st: '1st',
  StartOn_2nd: '2nd',
  StartOn_3rd: '3rd',
  StartOn_4th: '4th',
  StartOn_5th: '5th',
  StartOn_6th: '6th',
  StartOn_7th: '7th',
  StartOn_8th: '8th',
  StartOn_9th: '9th',
  StartOn_10th: '10th',
  StartOn_11th: '11th',
  StartOn_12th: '12th',
  StartOn_13th: '13th',
  StartOn_14th: '14th',
  StartOn_15th: '15th',
  StartOn_16th: '16th',
  StartOn_17th: '17th',
  StartOn_18th: '18th',
  StartOn_19th: '19th',
  StartOn_20th: '20th',
  StartOn_21th: '21st',
  StartOn_22th: '22nd',
  StartOn_23th: '23rd',
  StartOn_24th: '24th',
  StartOn_25th: '25th',
  StartOn_26th: '26th',
  StartOn_27th: '27th',
  StartOn_28th: '28th',
  StartOn_29th: '29th',
  StartOn_30th: '30th',
  StartOn_31th: '31st',
  StartOn_last_day: 'Last Day',
  StartOn_monday: 'Monday',
  StartOn_tuesday: 'Tuesday',
  StartOn_wednesday: 'Wednesday',
  StartOn_thursday: 'Thursday',
  StartOn_friday: 'Friday',
  StartOn_saturday: 'Saturday',
  StartOn_sunday: 'Sunday',
  StartOn_january: 'January',
  StartOn_febuary: 'Febuary',
  StartOn_march: 'March',
  StartOn_april: 'April',
  StartOn_may: 'May',
  StartOn_june: 'June',
  StartOn_july: 'July',
  StartOn_august: 'August',
  StartOn_september: 'September',
  StartOn_october: 'October',
  StartOn_november: 'November',
  StartOn_december: 'December',

  TimeOffPaidType_paid: 'Paid',
  TimeOffPaidType_unpaid: 'Unpaid',

  AssignDate_upon_join_date: 'Upon Join Date',
  AssignDate_after_probation_end_date: 'After Probation End Date',

  AdjustBalance_add: 'Add',
  AdjustBalance_subtract: 'Subtract',
  AdjustBalance_added: 'Added',
  AdjustBalance_subtracted: 'Subtracted',

  SendFeedback_ask_a_question: 'Ask A Question',
  SendFeedback_leave_a_comment: 'Leave A Comment',
  SendFeedback_report_a_bug: 'Report A Bug',
  SendFeedback_suggest_an_improvement: 'Suggest An Improvement',

  Assets_title: 'Assets',
  Assets_new: 'New Assets',
  Assets_new_category: 'New Category',
  Assets_print_qr: 'Print Asset QR',
  Assets_search_location: 'Search asset, location,...',
  Assets_total: 'Total assets',

  Assets_name: 'Name',
  Assets_category: 'Category',
  Assets_borrowed_by: 'Borrowed By',
  Assets_borrowed_on: 'Borrowed On',
  Assets_returned_on: 'Returned On',
  Assets_approved_by: 'Approved By',
  Assets_description: 'Description',
  Assets_status: 'Status',
  Assets_borrow: 'Borrow Asset',
  Assets_return: 'Return Asset',
  Assets_edit_asset: 'Edit Asset',
  Assets_delete_asset: 'Delete Asset',
  Assets_code: 'Code',
  Assets_location: 'Location',
  Assets_filter: 'Filter',
  Assets_filter_categories: 'Categories',
  Assets_filter_reset: 'Reset',
  Assets_apply_filter: 'Apply Filter',
  Assets_reset_filter: 'Reset Filter',
  Assets_form_back: 'Back to Assets',
  Assets_form_new: 'New',
  Assets_form_edit: 'Edit',
  Assets_form_title: 'Asset Title',
  Assets_form_serial_number: 'Serial Number (S/N)',
  Assets_form_purchase_date: 'Purchase Date',
  Assets_form_purchase_value: 'Purchase Value',
  Assets_form_photo: 'Asset Photo',

  Approvals_requests_onboarding: 'Onboarding Requests',
  Approvals_requests_information_update: 'Information Update Requests',
  Approvals_requests_timeoff: 'Time-off Requests',
  Approvals_requests_assets: 'Assets Requests',
  Approvals_requests_resign: 'Resign Requests',
  Approvals_requests_settings_desc_setup: 'Set up who will automatically receive all',
  Approvals_requests_settings_desc_onlypeople: 'Only people in this list can approve ',
  Approvals_requests_table_title: `CC'ed Recipients`,
  Approvals_requests_table_column: 'Added On',
  Approvals_requests_remove: 'Remove',

  Holiday: 'Holiday',
  Holidays_new_holiday: 'New Holiday',
  Holidays_title: 'Holiday Title',
  Holidays_applied_to: 'Applied To',
  Holidays_edit: 'Edit Holiday',
  Holidays_delete: 'Delete Holiday',
  Holidays_back_to_holidays_settings: 'Back to Holidays Settings',
  Holidays_copy_last_year: `Copy Last Year's Holidays`,

  Users_title: 'Users',
  Users_invite_user: 'Invite User',
  Users_desc_manage_all_users_in_the_system_and_invite_new_ones: 'Manage all users in the system and invite new ones.',
  Users_desc_all_permission_roles: 'All Permission Roles',
  Users_desc_all_account_statuses: 'All Account Statuses',
  Users_members: 'Members',
  Users_last_activity: 'Last Activity',
  Users_permission_role: 'Permission Role',
  Users_status: 'Account Status',
  Users_assign_role: 'Assign Role',
  Users_revoke_access: 'Revoke Access',
  Users_reinvite: 'Reinvite',
  Users_desc_changing_permission_role:
    'Changing permission role will affect account’s access to several features on the system. Please consider carefully before making such changes.',
  Users_revoke_access_desc: `“ will not be able to access Acazia HR once access is revoked. Are you sure you want to revoke
  this account’s access to the system?`,

  CompanyRegulations_title: 'Company Regulations',
  CompanyRegulations_new_regulation: 'New Regulation',
  CompanyRegulations_search_placeholder: 'Search Regulation, Author,...',

  CompanyRegulationsTable_regulation_title: 'Regulation Title',
  CompanyRegulationsTable_category: 'Category',
  CompanyRegulationsTable_uploaded_by: 'Uploaded By',
  CompanyRegulationsTable_access_role: 'Access Role',
  CompanyRegulationsTable_last_update: 'Last Update',
  CompanyRegulationsTable_edit_category: 'Edit Category',
  CompanyRegulationsTable_delete_category: 'Delete Category',
  CompanyRegulationsTable_edit_regulation: 'Edit Regulation',
  CompanyRegulationsTable_delete_regulation: 'Delete Regulation',
  CompanyRegulationsTable_download_regulation: 'Download Regulation',
  CompanyRegulationsTable_published_on: 'Published on',

  RegulationForm_back_to: 'Back to Regulations',
  RegulationForm_create: 'New Regulation',
  RegulationForm_edit: 'Edit Regulation',

  TimeOff_time_off_types: 'Time-off Types',
  TimeOff_new_time_off_request: 'New Time-off Request',
  TimeOff_time_off_history: 'Time-off History',
  TimeOff_balance_history: 'Balance History',

  TimeOffTable_submitted_on: 'Submitted On',
  TimeOffTable_time_off_type: 'Time-off Type',
  TimeOffTable_total_time: 'Total Time',
  TimeOffTable_approver: 'Approver',
  TimeOffTable_status: 'Status',
  TimeOffTable_clone_request: 'Clone Request',
  TimeOffTable_cancel_request: 'Cancel Request',

  BlanceTable_effective_on: 'Effective On',
  BlanceTable_description: 'Description',
  BlanceTable_used: 'Used',
  BlanceTable_accrued: 'Accrued',
  BlanceTable_balance: 'Balance',

  Checklists_template_desc:
    'Checklists Template contains pre-defined tasks for quick tasks assignments. These can be used for Onboarding, Offboarding or regular tasks assignments.',
  Checklists_new_template: 'New Template',
  Checklists_details: 'Checklist Details',
  Checklists_edit_template: 'Edit Template',
  Checklists_add_new_task: 'Add New Task',
  Checklists_duplicate_template: 'Duplicate Template',
  Checklists_delete_template: 'Delete Template',
  Checklists_edit_task: 'Edit Task',
  Checklists_duplicate_task: 'Duplicate Task',
  Checklists_delete_task: 'Delete Task',
  Checklists_select: 'Select “Checklist Template“ to see details',
  Checklists_new_task: 'New Task',
  Checklists_template: 'Checklist Template',
  Checklists_enter_template_title: 'Enter template title',
  Checklists_task_name: 'Task Name',
  Checklists_assigned_to: 'Assigned To',
  Checklists_task_category: 'Task Category',
  Checklists_task_checklist: 'Task Checklist',
  Checklists_task_priority: 'Task Priority',
  Checklists_task_progress: 'Task Progress',
  Checklists_assign_to: 'Assign To',
  Checklists_task_title: 'Task Title',
  Checklists_task_description: 'Task Description',
  Checklists_due_date: 'Due Date',
  Checklists_tasks_with_due_date: 'Tasks with due date',
  Checklists_after_assigning: 'after assigning',
  Checklists_back_to: 'Back to',
  Checklists_onboarding_date: 'onboarding date',
  Checklists_termination_date: 'termination date',
  Checklists_task: 'Task',
  Checklists_delete_task_desc_this_tasks_cannot: 'This tasks cannot be recovered once deleted.',
  Checklists_delete_task_desc_are_you_sure: 'Are you sure you want to delete this task?',
  Checklists_assigned_to_lower_case: 'assigned to',
  Checklists_me: 'Me',
  Checklists_due_on: 'Due On',
  Checklists_due_on_none: 'None',
  Checklists_add_task: 'Add Task',
  Checklists_view_full_checklist: 'View Full Checklist',
  Checklists_open: 'Open',
  Checklists_in_progress: 'In Progress',
  Checklists_done: 'Done',
  Checklists_task_delayed: 'This task has been delayed',
  Checklists_to_dos: 'To-dos',
  Checklists_template_empty_placeholder: 'Select “New Task“ on the top right card header to add tasks here.',

  Time_off_title: 'Time-off',
  Time_off_type: 'Time-off Type',
  Time_off_new_type: 'New Time-off Type',
  Time_applicable_to: 'Applicable To',
  Time_paid_unpaid: '	Paid / Unpaid',
  Time_no_of_employees: 'No. Of Employees',
  Time_off_edit: 'Edit Time-off',
  Time_off_delete: 'Delete Time-off',
  Time_back_to_time_offs_settings: 'Back to Time-offs Settings',
  Time_off_information: 'Information',
  Time_off_leave_title: 'Leave Title',
  Time_off_leave_icon: 'Leave Icon',
  Time_off_desc_are_you_sure_you_want_to_delete: 'Are you sure you want to delete time-off',
  Time_off_tooltip_a_descriptive_name_for_this_type_of: 'A descriptive name for this type of time-off ',
  Time_off_tooltip_paid_time_off:
    'Paid time-off (PTO) is a period of time that an employee can take off work, while still getting paid by the company',
  Time_off_tooltip_unpaid: 'Unpaid time-off (UTO) is time away from work an employee can take without pay',
  Time_off_tooltip_a_picture_representing: 'A picture representing the time-off',
  Time_off_policy: 'Policy',
  Time_off_after_specific_occurrence: 'After specific occurrence',
  Time_off_maximum_leave: 'Maximum Leave',
  Time_off_the_maximum_period_of_time_an_employee_can_take_off_work_in_aleave_request:
    'The maximum period of time an employee can take off work in a leave request',
  Time_off_desc_hours_on_per_request: 'hours on per request',
  Time_off_applied_to: 'Applied To',
  Time_off_tooltip_this_type_of_time_off_can_be_applicable_to_all_employees_or_a_specific_group_of_employees:
    'This type of time-off can be applicable to all employees or a specific group of employees',
  Time_off_employment_type: 'Employment Type',
  Time_off_accrual_rules: 'Accrual Rules',
  Time_off_assign_date: 'Assign Date',
  Time_off_accrual_type: 'Accrual Type',
  Time_off_tooltip_the_accrual_type_which_the_time_off_type_is_applicable_to_employees:
    'The accrual type which the time-off type is applicable to employees',
  Time_off_tooltip_this_rule_would_be_applied_once: 'This rule would be applied once',
  Time_off_tooltip_this_rule_would_be_applied_automatically: 'This rule would be applied automatically',
  Time_off_every: 'Every',
  Time_off_accrue: 'Accrue',
  Time_off_hours: 'hours',
  Time_start_on: 'Start on',
  Time_end_on: 'End on',
  Time_day_of_month: 'day of month',
  Time_add_level: 'Add Level',
  Time_carry_over: 'Carry Over',
  Time_entitlement: 'Entitlement',
  Time_requested: 'Requested',
  Time_carry_over_report: 'Carry Over',
  Time_maximum_carry_over: 'Maximum Carry Over',
  Time_carry_over_expiration: 'Carry Over Expiration',
  Time_off_tooltip_maximum_number: 'Maximum number of days employees can carry from one year to the next',
  Time_off_tooltip_the_date:
    'The date at which all number of days that were accrued during the current year will no longer be usable',

  MyAssets: 'My Assets',
  MyAssets_new_assets_request: 'New Asset Request',
  MyAssets_assets_history: 'Assets History',

  RequestForm_back_to: 'Back to Requests',
  RequestForm_new_resign_request: 'New Resign Request',
  RequestForm_new_contract_termination_request: 'New Contract Termination Request',
  RequestForm_new_probation_termination_request: 'New Probation Termination Request',
  RequestForm_make_request_on_behalf: 'Make Request on Behalf',
  RequestForm_requester: 'Requester',
  RequestForm_approver: 'Approver',
  RequestForm_other_recipients: 'Other Recipients',
  RequestForm_resign_request_reason: 'Resign Request Reason',
  RequestForm_contract_termination_request_reason: 'Contract Termination Request Reason',
  RequestForm_probation_termination_request_reason: 'Probation Termination Request Reason',
  RequestForm_last_working_day: 'Last Working Day',
  RequestForm_resign_request: 'Resign Request',
  RequestForm_contract_termination: 'Contract Termination Request',
  RequestForm_probation_termination: 'Probation Termination Request',
  RequestForm_on_behalf: 'On Behalf',
  RequestForm_created_by: 'Created By',
  RequestForm_attached_file: 'Attached File',
  RequestForm_borrowing_request: 'Asset Borrowing Request',
  RequestForm_returning_requeset: 'Asset Returning Request',
  RequestForm_new_borrowing_request: 'New Asset Borrowing Request',
  RequestForm_new_returning_requeset: 'New Asset Returning Request',
  RequestForm_borrow_on: 'Borrow On',
  RequestForm_return_on: 'Return On',
  RequestForm_borrowing_reason: 'Borrowing Reason',
  RequestForm_returning_reason: 'Returning Reason',
  RequestForm_borrowed_assets: 'Borrowed Assets',
  RequestForm_returned_assets: 'Returned Assets',
  RequestForm_total_requested_assets: 'Total Requested Assets',
  RequestForm_clear_section: 'Clear selection',
  RequestForm_time_off_request: 'Time-off Request',
  RequestForm_new_time_off_request: 'New Time-off Request',
  RequestForm_time_off_type: 'Time-off Type',
  RequestForm_time_off_reason: 'Time-off Reason',
  RequestForm_requested_time_off: 'Requested Time-off',
  RequestForm_total_requested_hrs: 'Total requested hrs',
  RequestForm_remaining_hrs: 'Remaining hrs',

  PreviewEmail_preview_request: 'Preview Request',
  PreviewEmail_reviewed_request: 'Request Reviewed',
  PreviewEmail_canceled_request: 'Request Canceled',
  PreviewEmail_processing_request: 'Request Processing',
  PreviewEmail_pending_request: 'Request Pending',
  PreviewEmail_from: 'from',
  PreviewEmail_to: 'to',

  RequestManagement_my_requests: 'My Requests',
  RequestManagement_my_requests_des:
    'Requests in this section are those you have submitted or have been submitted on your behalf.',
  RequestManagement_assigned_to_me: 'Assigned To Me',
  RequestManagement_assigned_to_me_des: 'Requests in this section require your approval.',
  RequestManagement_cc_to_me: 'CC’ed To Me',
  RequestManagement_cc_to_me_des: 'Requests in this section have you as one of the recipients.',
  RequestManagement_new_request: 'New Request',

  RequestsStatusManagement_pending: 'Pending',
  RequestsStatusManagement_completed: 'Completed',
  RequestsStatusManagement_my_tasks: 'My Tasks',
  RequestsStatusManagement_tasks_i_assigned: 'Tasks I Assigned',

  RequestsManagementNavigation_all_requests: 'All Requests',
  RequestsManagementNavigation_onboarding_requests: 'Onboarding Requests',
  RequestsManagementNavigation_information_update_requests: 'Information Update Requests',
  RequestsManagementNavigation_time_off_requests: 'Time-off Requests',
  RequestsManagementNavigation_assets_requests: 'Assets Requests',
  RequestsManagementNavigation_assets_borrowing_requests: 'Assets Borrowing Requests',
  RequestsManagementNavigation_assets_returning_requests: 'Assets Returning Requests',
  RequestsManagementNavigation_resign_requests: 'Resign Requests',
  RequestsManagementNavigation_contract_termination_requests: 'Contract Termination Requests',
  RequestsManagementNavigation_probation_termination_requests: 'Probation Termination Requests',

  RequestsManagementTable_from: 'from',
  RequestsManagementTable_to: 'to',
  RequestsManagementTable_on: 'on',
  RequestsManagementTable_on_upper_case: 'On',
  RequestsManagementTable_has_requested: 'has requested',
  RequestsManagementTable_has_reviewed_this_request: 'has reviewed this request.',
  RequestsManagementTable_has_not_been_reviewed: 'This request has not been reviewed.',
  RequestManagementTable_asset_borrowing: 'Asset Borrowing',
  RequestManagementTable_asset_returning: 'Asset Returning',
  RequestManagementTable_resign: 'Resign',
  RequestManagementTable_contract_termination: 'Contract Termination',
  RequestManagementTable_probation_termination: 'Probation Termination',
  RequestManagementTable_information_update: 'Information Update',
  RequestManagementTable_onboarding: 'Onboarding',
  RequestManagementTable_offboarding: 'Offboarding',

  RequestsManagementDetail_pending: 'This request cannot be used as it has not been reviewed.',
  RequestsManagementDetail_completed: 'This request has been applied.',
  RequestsManagementDetail_of: 'of',
  RequestsManagementDetail_requests: 'requests',
  RequestsManagementDetail_onboarding_review_request: 'Onboarding Review Request',
  RequestsManagementDetail_clone_request: 'Clone Request',
  RequestsManagementDetail_cancel_request: 'Cancel Request',
  RequestsManagementDetail_confirm_request_review: 'Confirm Request Review',
  RequestsManagementDetail_approve_request: 'Approve Request',
  RequestsManagementDetail_old_value: 'Old Value',
  RequestsManagementDetail_new_value: 'New Value',
  RequestsManagementDetail_approve_all: 'Approve All',
  RequestsManagementDetail_reject_all: 'Reject All',
  RequestsManagementDetail_hr_comment: `HR's Comment`,

  BoardingTable_employee_name: 'Employee Name',
  BoardingTable_onboard_on: 'Onboard On',
  BoardingTable_onboarding_status: 'Onboarding Status',
  BoardingTable_offboard_on: 'Offboard On',
  BoardingTable_offboarding_status: 'Offboarding Status',
  BoardingTable_assign_onboarding_checklist: 'Assign Onboarding Checklist',
  BoardingTable_assign_offboarding_checklist: 'Assign Offboarding Checklist',
  BoardingTable_review_onboarding_information: 'Review Onboarding Information',
  BoardingTable_edit_onboarding_checklist: 'Edit Onboarding Checklist',
  BoardingTable_edit_offboarding_checklist: 'Edit Offboarding Checklist',
  BoardingTable_empty_placeholder: 'This Employee has not been assigned any checklist.',
  BoardingTable_completed_tasks: 'Completed Tasks',

  ModalSelectPeople_select_employee: 'Select Employee',
  ModalSelectPeople_select_sub_leaders: 'Select Sub Leaders',
  ModalSelectPeople_select_assignee: 'Select Assignee',
  ModalSelectPeople_your_line_manager: 'Your Line Manager',

  Give_us_feedback_title: 'Share your thoughts',
  Give_us_feedback_what_would_you_like: 'What would you like to know',
  Give_us_feedback_acaziahr_can_contact_me_about_this_feedback: 'AcaziaHR can contact me about this feedback',
  Give_us_feedback_like_to_participate_in_product_research: `I'd like to participate in product research`,
  Give_us_send_feedback: 'Send Feedback',

  AssignOnboardingOffboarding_onboarding_title: 'Assign Onboarding Checklist',
  AssignOnboardingOffboarding_offboarding_title: 'Assign Offboarding Checklist',
  AssignOnboardingOffboarding_onboarding_des: 'Assign an onboarding checklist to your employee',
  AssignOnboardingOffboarding_offboarding_des: 'Assign an offboarding checklist to your employee',
  AssignOnboardingOffboarding_select_template: 'Select Template',
  AssignOnboardingOffboarding_hr_in_charge: 'HR In Charge',
  AssignOnboardingOffboarding_template_description: 'Template Description',

  ModalApproveRequest_confirm_review_request: 'Confirm Review Request',
  ModalApproveRequest_approve_request: 'Approve Request',
  ModalApproveRequest_des:
    'will be notified of this request approval once confirmed. Please check all information before confirming to prevent future mistakes.',

  ModalCancelRequest_title: 'Cancel Request',
  ModalCancelRequest_des_1:
    'Your HR, Line Manager and CCed recipients will be notified of your request cancellation. This request cannot be reactivated once canceled.',
  ModalCancelRequest_des_2: 'Are you sure you want to cancel this request?',

  ModalDeleteAsset_des_1: 'You are about to delete asset',
  ModalDeleteAsset_des_2: 'Once deleted, this asset cannot be recovered.',
  ModalDeleteAsset_des_3: 'Are you sure you want to delete this asset?',

  ModalDeleteRequest_title: 'Delete Request',
  ModalDeleteRequest_des:
    'This request cannot be recovered once deleted. Are you sure you want to delete this request?',

  ModalDeleteTask_title: 'Delete Task',
  ModalDeleteTask_des: 'This task cannot be recovered once deleted. Are you sure you want to delete this task?',

  ModalDeleteCompanyRegulations_title: 'Delete Regulation',
  ModalDeleteCompanyRegulations_des1: 'This regulation cannot be recovered once deleted.',
  ModalDeleteCompanyRegulations_des2: 'Are you sure you want to delete this regulation?',

  ModalDeleteCompanyRegulationsCategory_title: 'Delete Regulation Category',
  ModalDeleteCompanyRegulationsCategory_des1: 'This regulation category cannot be recovered once deleted.',
  ModalDeleteCompanyRegulationsCategory_des2: 'Are you sure you want to delete this regulation category?',

  ModalInvitationSent_title: 'Invitation Sent',
  ModalInvitationSent_back_to_people: 'Back to People',
  ModalInvitationSent_go_to_checklist: 'Go to Checklist',
  ModalInvitationSent_des1: 'An invitation to create HRM account has been sent to',
  ModalInvitationSent_des2: 'Once accepted, this user will be added to Checklist for Onboarding.',

  ModalDeleteDependant_title: 'Delete Dependant',
  ModalDeleteDependant_des: 'Are you sure you want to delete this dependant?',

  ModalDeleteEmergencyContact_title: 'Delete Contact',
  ModalDeleteEmergencyContact_des: 'Are you sure you want to delete this contact?',

  ModalSubmitProfileUpdate_title: 'Profile Update Request',
  ModalSubmitProfileUpdate_des:
    'You are about to send a Profile Update Request. All changes will be reviewed by HR/Admin before taking effect. Please check all your updates thoroughly before submitting.',

  ModalAdjustBlance_amount: 'Amount',
  ModalAdjustBlance_effective_date: 'Effective Date',
  ModalAdjustBlance_note: 'Note',
  ModalAdjustBlance_summary: 'Summary',

  ModalCancelRequestTimeOff_title: 'Cancel Time-off Request',
  ModalCancelRequestTimeOff_des1:
    'Requester and other recipients will be notified of your request cancellation. This request cannot be reactivated once canceled.',

  ModalDeleteApprover_title_approver: 'Remove Approver',
  ModalDeleteApprover_title_recipient: `Remove CC'ed Recipient`,
  ModalDeleteApprover_des_approver: 'Are you sure you want to remove approver',
  ModalDeleteApprover_des_recipient: `Are you sure you want to remove CC'ed recipient`,

  ModalNewCoverageOption_title: 'New Coverage Option',

  ModalDeleteTemplate_title: 'Delete Checklist Template',
  ModalDeleteTemplate_des1: 'You are about to delete checklist template',
  ModalDeleteTemplate_des2: 'Once deleted, this template and its tasks cannot be recovered.',
  ModalDeleteTemplate_des3: 'Are you sure you want to delete this checklist template?',

  ModalDeleteHoliday_title: 'Delete Holiday',
  ModalDeleteHoliday_des: 'Are you sure you want to delete holiday',

  Benefits_view_benefit_group: 'View Benefit Group',

  BenefitDetail_general_info: 'General Info',
  BenefitDetail_coverage_option: 'Coverage Option',
  BenefitDetail_add_coverage_option: 'Add Coverage Option',
  BenefitDetail_benefit_title: 'Benefit Title',
  BenefitDetail_benefit_type: 'Benefit Type',
  BenefitDetail_benefit_type_monetary_des:
    'This Benefit type requires monetary contribution from Employee and/or Company.',
  BenefitDetail_benefit_type_non_monetary_des:
    'This Benefit type does not require monetary contribution from Employee and/or Company.',
  BenefitDetail_benefit_description: 'Description',
  BenefitDetail_benefit_details: 'Benefit Details',
  BenefitDetail_benefit_status: 'Status',
  BenefitDetail_back_to_benefits: 'Back to Benefits Settings',
  BenefitDetail_new: 'New',
  BenefitDetail_edit: 'Edit',
  BenefitDetail_benefit: 'Benefit',
  BenefitDetail_benefit_eligibility_and_cost: 'Benefit Eligibility and Cost',
  BenefitDetail_group: 'Group',
  BenefitDetail_group_title: 'Group Title',
  BenefitDetail_group_description: 'Description',
  BenefitDetail_group_eligible_employees: 'Eligible Employees',
  BenefitDetail_group_eligibility_start: 'Eligibility For This Benefit Starts...',
  BenefitDetail_group_eligibility_end: 'Eligibility For This Benefit Ends...',
  BenefitDetail_group_set_period: 'Set Period',
  BenefitDetail_group_benefit_cost: 'Benefit Cost',
  BenefitDetail_group_benefit_cost_des: `You can edit Benefit Cost individually in Employee's Self-Service`,
  BenefitDetail_group_benefit_cost_incur: 'Benefit Cost Incur...',
  BenefitDetail_group_total_cost: 'Total Cost',
  BenefitDetail_group_employee_pays: 'Employee Pays',
  BenefitDetail_group_company_pays: 'Company Pays',

  ModalViewBenefitGroup_summary: 'Summary',
  ModalViewBenefitGroup_employees_in_this_group_title: 'Employees In This Group',
  ModalViewBenefitGroup_employees_in_this_group: 'employee(s) eligible in this group',

  Notifications_welcome_to: 'Welcome to',
  Notifications_welcome_you_to: 'welcome you to',
  Notifications_congratulations_on: 'congratulations on',
  Notifications_your_celebration: 'your Celebration !',
  Notifications_has_submitted: 'has submitted',
  Notifications_update_information_request: 'has updated infomation for',
  Notifications_change_employee_type: 'has changed employee type for',
  Notifications_change_user_status: 'has changed user status for',
  Notifications_has_approved: 'has approved',
  Notifications_has_canceled: 'has canceled',
  Notifications_assigned_you: 'assigned you',

  WelcomeMemberNote_title: 'Welcome Note',
  WelcomeMemberNote_placeholder: 'Welcome you to Acazia Software!',
  WelcomeMemberNote_des: 'Say Hi to your new coworker',
  WelcomeMemberNote_add_image: 'Add Image',
  WelcomeMemberNote_send: 'Send',

  CelebrationNote_title: 'Celebration',
  CelebrationNote_placeholder: 'Happy Birthday to You ! / Happy Anniversary !',
  CelebrationNote_des: 'Say Congratulations to your colleague',

  Reports_headcounts: 'Headcounts',
  Reports_staff_turnover: 'Staff Turnover',
  Reports_department: 'Department',
  Reports_employee_type: 'Employee Type',

  BenefitsOverviewTable_title: 'Benefits Overview',
  BenefitsOverviewTable_benefit: 'Benefits',
  BenefitsOverviewTable_coverage: 'Coverage',
  BenefitsOverviewTable_employee_pays: 'Employee Pays',
  BenefitsOverviewTable_company_pays: 'Company Pays',
  BenefitsOverviewTable_frequency: 'Frequency',
  BenefitsOverviewTable_effective_on: 'Effective On',

  BenefitsHistoryTable_title: 'Benefits History',
  BenefitsHistoryTable_date: 'Date',
  BenefitsHistoryTable_event: 'Event',
  BenefitsHistoryTable_changed_by: 'Changed By',

  Report_back_to_report: 'Back to Reports',
  Report_total_employees: 'TOTAL EMPLOYEES',
  Report_last_6_months: 'LAST 6 MONTHS',
  Report_headcount_title: 'Headcount (Point-in-time)',
  Report_headcount_des: 'Breakdown of all current employees',
  Report_onboarding_title: 'Onboarding',
  Report_onboarding_des: 'Onboarding employees by month of their Join Date.',
  Report_offboarding_title: 'Offboarding',
  Report_offboarding_des: 'Offboarding and inactive employees by month of their Last Working Date.',
  Report_time_off_balance_title: 'Time Off Balance',
  Report_time_off_balance_des: `An overview of employees' time off balances.`,
  Report_time_off_schedule_title: 'Time Off Schedule',
  Report_time_off_schedule_des: `An overview of employees' time off schedules.`,
  Report_employee_data_title: 'Employee Data Reports',
  Report_employee_data_des: 'An overview of employee information',
  Report_employee_turnover_title: 'Employee Turnover Rate',
  Report_employee_turnover_des: 'An overview of the resigned employees over the active employees in the company',
  Report_assets_in_used_title: 'Assets In Used',
  Report_assets_in_used_des:
    'Assets in used report would only list active assets, hence if you also want to see deactivated assets the report can not be used.',

  ModalEditAssignChecklist_title: 'Edit Assigned Checklist',
  ModalEditAssignChecklist_des1:
    'Some tasks on this checklist have been completed. All tasks progress will be reset and cannot be recovered once this checklist is edited.',
  ModalEditAssignChecklist_des2: 'Are you sure you want to edit this checklist?',

  Notification_edit_asset_successfully: 'Edit Asset Successfully',
  Notification_create_asset_successfully: 'Create Asset Successfully',
  Notification_successfully: 'Successfully',
  Notification_assign_checklists_successfully: 'Assign Checklists Successfully',
  Notification_send_feedback_successfully: 'Send Feedback Successfully',
  Notification_update_regulation_successfully: 'Update Regulation Successfully',
  Notification_create_regulation_successfully: 'Create Regulation Successfully',
  Notification_confirm_review_request_successfully: 'Confirm Review Request Successfully',
  Notification_approve_request_successfully: 'Approve Request Successfully',
  Notification_cancel_request_successfully: 'Cancel Request Successfully',
  Notification_update_task_successfully: 'Update Task Successfully',
  Notification_create_task_successfully: 'Create Task Successfully',
  Notification_create_task_category_successfully: 'Create Task Category Successfully',
  Notification_create_timeoff_request_successfully: 'Create Time-off Request Successfully',
  Notification_change_asset_status_successfully: 'Change Asset Status Successfully',
  Notification_delete_asset_successfully: 'Delete Asset Successfully',
  Notification_create_asset_category_successfully: 'Create Asset Category Successfully',
  Notification_delete_request_successfully: 'Delete Request Successfully',
  Notification_delete_regulation_successfully: 'Delete Regulation Successfully',
  Notification_delete_regulation_category_successfully: 'Delete Regulation Category Successfully',
  Notification_update_regulate_category_successfully: 'Update Regulate Category Successfully',
  Notification_create_regulate_category_successfully: 'Create Regulate Category Successfully',
  Notification_send_message_successfully: 'Send Message Successfully',
  Notification_invite_employee_successfully: 'Invite Employee Successfully',
  Notification_login_successfully: 'Login Successfully',
  Notification_delete_report_violation_successfully: 'Delete Report Violation Successfully',
  Notification_import_employees_successfully: 'Import Employees Successfully',
  Notification_report_violations_successfully: 'Report Violations Successfully',
  Notification_update_information_successfully: 'Update Information Successfully',
  Notification_request_update_information_successfully: 'Request Update Information Successfully',
  Notification_adjust_balance_successfully: 'Adjust Balance Successfully',
  Notification_add_approver_successfully: 'Add Approver Successfully',
  Notification_remove_approver_successfully: 'Remove Approver Successfully',
  Notification_create_benefit_successfully: 'Create Benefit Successfully',
  Notification_update_benefit_successfully: 'Update Benefit Successfully',
  Notification_delete_benefit_successfully: 'Delete Benefit Successfully',
  Notification_update_branch_successfully: ' Update Branch Successfully',
  Notification_create_branch_successfully: ' Create Branch Successfully',
  Notification_duplicate_template_successfully: 'Duplicate Template Successfully',
  Notification_duplicate_task_successfully: 'Duplicate Task Successfully',
  Notification_delete_task_successfully: 'Delete Task Successfully',
  Notification_delete_template_successfully: 'Delete Template Successfully',
  Notification_update_template_successfully: 'Update Template Successfully',
  Notification_create_template_successfully: 'Create Template Successfully',
  Notification_company_infomation_saved_successfully: 'Company Infomation Saved Successfully',
  Notification_delete_branch_successfully: 'Delete Branch Successfully',
  Notification_delete_department_successfully: 'Delete Department Successfully',
  Notification_update_configure_successfully: 'Update Configure Successfully',
  Notification_activated_employment_type_successfully: 'Activated Employment Type Successfully',
  Notification_deactivate_employment_type_successfully: 'Deactivate Employment Type Successfully',
  Notification_delete_employment_type_successfully: 'Delete Employment Type Successfully',
  Notification_activated_job_title_successfully: 'Activated Job Title Successfully',
  Notification_deactivate_job_title_successfully: 'Deactivate Job Title Successfully',
  Notification_delete_job_title_successfully: 'Delete Job Title Successfully',
  Notification_update_department_successfully: 'Update Department Successfully',
  Notification_create_department_successfully: 'Create Department Successfully',
  Notification_update_employment_type_successfully: 'Update Employment Type Successfully',
  Notification_create_employment_type_successfully: 'Create Employment Type Successfully',
  Notification_setting_saved_successfully: 'Setting Saved Successfully',
  Notification_update_holiday_successfully: 'Update Holiday Successfully',
  Notification_create_holiday_successfully: 'Create Holiday Successfully',
  Notification_delete_holiday_successfully: 'Delete Holiday Successfully',
  Notification_update_job_title_successfully: 'Update Job Title Successfully',
  Notification_create_job_title_successfully: 'Create Job Title Successfully',
  Notification_update_timeoff_successfully: 'Update Time-off Successfully',
  Notification_create_timeoff_successfully: 'Create Time-off Successfully',
  Notification_delete_timeoff_successfully: 'Delete Time-off Successfully',
  Notification_reinvite_user_successfully: 'Reinvite User Successfully',
  Notification_add_user_roles_successfully: 'Add User Roles Successfully',
  Notification_revoce_access_user_successfully: 'Revoce Access User Successfully',
  Notification_create_role_successfully: 'Create Role Successfully',
  Notification_update_role_successfully: 'Update Role Successfully',
  Notification_delete_role_successfully: 'Delete Role Successfully',
  Notification_add_member_role_successfully: 'Add Member Role Successfully',
  Notification_update_role_permission_successfully: 'Update Role Permission Successfully',
  Notification_remove_member_successfully: 'Remove Member Successfully',

  ValidationRules_required: 'This field is required',
  ValidationRules_max: `Enter number less or equal than`,
  ValidationRules_min: `Enter number greater or equal than`,
  ValidationRules_minLength: `Enter characters at least`,
  ValidationRules_maxLength: `Enter characters at most`,
  ValidationRules_email: 'This field is invalid email',
  ValidationRules_noSpecialKey: 'This field must not contain special characters',
  ValidationRules_onlyAlphabetic: 'This field must contain alphabetic characters',
  ValidationRules_onlyNumeric: 'This is a field where only numeric characters are entered',
  ValidationRules_onlyAlphanumerial: 'This is a field where only alphanumeric characters are entered',
  ValidationRules_domain: 'Invalid domain',
  ValidationRules_url: 'This field is invalid URL',
  ValidationRules_confirmPassword: 'This field not matched with password field',
  ValidationRules_rangesDigits: `This field must have`,
  ValidationRules_rangesDigits_or: ` or `,
  ValidationRules_rangesDigits_digits: `digits`,
  ValidationRules_maxSizeFiles: `File size must equal or less than`,
  ValidationRules_timeOffHours: `Invalid format hours time-off`,
  ValidationRules_maxTimeLeaveHours: `Enter total request hours less or equal than max time leave:`,
  ValidationRules_maxRemainingHours: `Enter total request hours less or equal than remaining hours`,

  Permissions_title: 'Permissions',
  Permissions_des: `Allow or restrict employees’ access to system’s features`,
  Permissions_roles: 'Permission Roles',
  Permissions_new_role: 'New Role',
  Permissions_edit_role: 'Edit Role',
  Permissions_new_permission_role: 'New Permission Role',
  Permissions_edit_permission_role: 'Edit Permission Role',
  Permissions_delete_role: 'Delete Role',
  Permissions_title_role_placeholder: 'Enter Role Title',
  Permissions_description_role_placeholder: 'Enter Role Description',
  Permissions_detail: 'Permission Details',

  PermissionFeatureMethod_view_only: 'View Only',
  PermissionFeatureMethod_view_and_edit: 'View & Edit',
  PermissionFeatureMethod_no_access: 'No Access',

  FeatureGroupTitle_manager_requests: 'Manager Requests',
  FeatureGroupTitle_manager_reports: 'Manager Reports',
  FeatureGroupTitle_tasks_checklist: 'Tasks Checklist',
  FeatureGroupTitle_manager_employees: 'Manager Employees',
  FeatureGroupTitle_employee_view_2nd: 'View Employee Information',
  FeatureGroupTitle_adjust_time_off_balance: 'Adjust Time-off Balance',
  FeatureGroupTitle_other: 'Other',
  FeatureGroupTitle_quick_action: 'Quick Action',
  FeatureGroupTitle_all_self_information: 'All Self Information',

  ModalDeleteRole_title: 'Delete Permission Role',
  ModalDeleteRole_des1: 'This role and its permission settings cannot be recovered once deleted.',
  ModalDeleteRole_des2: 'This role is currently assigned to',
  ModalDeleteRole_des3:
    'members. Once deleted, these members’ roles will be switch to “Employee” automatically. This role and its permission settings cannot be recovered.',
  ModalDeleteRole_des4: 'Are you sure you want to delete this role?',

  ModalRemoveMember_title: 'Remove Member',
  ModalRemoveMember_des1: 'You are about to remove',
  ModalRemoveMember_des2: 'from permission role',
  ModalRemoveMember_des3: 'This member’s role will be switched to “Employee“ once removal is confirmed.',
  ModalRemoveMember_des4: 'Are you sure you want to remove this member from role',

  ModalChangePermission_title: 'Change Permission Settings',
  ModalChangePermission_des1: 'You have made some changes to role',
  ModalChangePermission_des2: 'permission settings. These changes will be applied to all members assigned as',
  ModalChangePermission_des3: 'Are you sure you want to change permission settings?',

  ModalChangeUserPermission_title: 'Change User Role',
  ModalChangeUserPermission_des1:
    'Changing permission would affect the ability to access the system to perform functions.',
  ModalChangeUserPermission_des2: 'Are you sure you want to change the permission from',
};

export default dataSources;

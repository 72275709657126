import { all, takeLatest } from 'redux-saga/effects';

import {
  getCelebrationsAction,
  getCompanyTimeOffsAction,
  getDashboardReportAction,
  getNotificationsAction,
  seenNotificationAction,
  sendCelebrationAction,
} from '@/redux/actions';

import { getCelebrationsSaga } from './get-celebrations';
import { getCompanyTimeOffsSaga } from './get-company-time-offs';
import { getDashboardReportSaga } from './get-dashboard-report';
import { getNotificationsSaga } from './get-notifications';
import { seenNotificationSaga } from './seen-notification';
import { sendCelebrationSaga } from './send-celebration';

export default function* root(): Generator {
  yield all([
    takeLatest(getCelebrationsAction.request.type, getCelebrationsSaga),
    takeLatest(getCompanyTimeOffsAction.request.type, getCompanyTimeOffsSaga),
    takeLatest(getDashboardReportAction.request.type, getDashboardReportSaga),
    takeLatest(getNotificationsAction.request.type, getNotificationsSaga),
    takeLatest(seenNotificationAction.request.type, seenNotificationSaga),
    takeLatest(sendCelebrationAction.request.type, sendCelebrationSaga),
  ]);
}

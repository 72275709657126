import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createReportViolationAction } from '@/redux/actions';
import { createReportViolation, TCreateReportViolationResponse } from '@/services/api';

// FUNCTION

export function* createReportViolationSaga(action: ActionType<typeof createReportViolationAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createReportViolation, materials);
    const createReportViolationResponse: TCreateReportViolationResponse = response as TCreateReportViolationResponse;
    yield put(createReportViolationAction.success(createReportViolationResponse));
    successCallback?.(createReportViolationResponse);
  } catch (err) {
    yield put(createReportViolationAction.failure(err));
    failedCallback?.(err);
  }
}

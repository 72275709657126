import { TReportState } from '@/redux/reducers/report';
import { TGetReportTimeOffBalanceSuccess } from '@/redux/actions/report';

export const getReportTimeOffBalanceUpdateState = (
  state: TReportState,
  action: TGetReportTimeOffBalanceSuccess,
): TReportState => ({
  ...state,
  getReportTimeOffBalanceResponse: action.payload.response,
});

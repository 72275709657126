import { TCelebration } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetCelebrationsParams = {
  page: number;
  pageSize: number;
  search?: string;
};

export type TGetCelebrationsMaterials = {
  params?: TGetCelebrationsParams;
};

export type TGetCelebrationsResponse = TPaginateResponse & {
  data: TCelebration[];
};

// FUNCTION

export const getCelebrations = async ({ params }: TGetCelebrationsMaterials): Promise<TGetCelebrationsResponse> => {
  const response = await ApiService.get(`/dashboard/celebration/list`, { params });
  return response.data;
};

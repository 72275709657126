import { createActionCreator } from 'deox';

import { TUpdateBenefitMaterials, TUpdateBenefitResponse } from '@/services/api/benefit/update-benefit';

// CONSTANTS

export enum EUpdateBenefitAction {
  UPDATE_BENEFIT = 'UPDATE_BENEFIT',
  UPDATE_BENEFIT_REQUEST = 'UPDATE_BENEFIT_REQUEST',
  UPDATE_BENEFIT_SUCCESS = 'UPDATE_BENEFIT_SUCCESS',
  UPDATE_BENEFIT_FAILED = 'UPDATE_BENEFIT_FAILED',
}

// TYPES

export type TUpdateBenefitRequest = {
  type: EUpdateBenefitAction.UPDATE_BENEFIT_REQUEST;
  payload: {
    materials: TUpdateBenefitMaterials;
    successCallback?: (response: TUpdateBenefitResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateBenefitSuccess = {
  type: EUpdateBenefitAction.UPDATE_BENEFIT_SUCCESS;
  payload: { response: TUpdateBenefitResponse };
};

export type TUpdateBenefitFailed = { type: EUpdateBenefitAction.UPDATE_BENEFIT_FAILED };

// FUNCTION

export const updateBenefitAction = {
  request: createActionCreator(
    EUpdateBenefitAction.UPDATE_BENEFIT_REQUEST,
    (resolve) =>
      (
        materials: TUpdateBenefitMaterials,
        successCallback?: (response: TUpdateBenefitResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateBenefitRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateBenefitAction.UPDATE_BENEFIT_SUCCESS,
    (resolve) =>
      (response: TUpdateBenefitResponse): TUpdateBenefitSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateBenefitAction.UPDATE_BENEFIT_FAILED,
    (resolve) =>
      (error: unknown): TUpdateBenefitFailed =>
        resolve({ error }),
  ),
};

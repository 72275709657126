import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.RED_500 }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9125 5.76256L3.49701 1.29878C3.16707 1.15738 2.80005 1.39939 2.80005 1.75835V14.5001C2.80005 14.7762 3.02391 15.0001 3.30005 15.0001H3.80005C4.07619 15.0001 4.30005 14.7762 4.30005 14.5001V10.3334L13.8931 6.68955C14.3135 6.52987 14.3258 5.9397 13.9125 5.76256Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;

import { TAssetState } from '@/redux/reducers/asset';
import { TDeleteAssetCategorySuccess } from '@/redux/actions/asset';

export const deleteAssetCategoryUpdateState = (
  state: TAssetState,
  action: TDeleteAssetCategorySuccess,
): TAssetState => ({
  ...state,
  deleteAssetCategoryResponse: action.payload.response,
});

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { downloadEmployeeTurnoverRateAction } from '@/redux/actions';
import { downloadEmployeeTurnoverRate, TDownloadEmployeeTurnoverRateResponse } from '@/services/api';

// FUNCTION

export function* downloadEmployeeTurnoverRateSaga(
  action: ActionType<typeof downloadEmployeeTurnoverRateAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(downloadEmployeeTurnoverRate, materials);
    const downloadEmployeeTurnoverRateResponse: TDownloadEmployeeTurnoverRateResponse =
      response as TDownloadEmployeeTurnoverRateResponse;
    yield put(downloadEmployeeTurnoverRateAction.success(downloadEmployeeTurnoverRateResponse));
    successCallback?.(downloadEmployeeTurnoverRateResponse);
  } catch (err) {
    yield put(downloadEmployeeTurnoverRateAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TUpdateDepartmentPaths = {
  id: string | number;
};
export type TUpdateDepartmentBody = unknown;

export type TUpdateDepartmentMaterials = {
  paths?: TUpdateDepartmentPaths;
  body?: TUpdateDepartmentBody;
};

export type TUpdateDepartmentResponse = unknown;

// FUNCTION

export const updateDepartment = async ({
  paths,
  body,
}: TUpdateDepartmentMaterials): Promise<TUpdateDepartmentResponse> => {
  const response = await ApiService.put(`/department/${paths?.id}`, body);
  return response.data;
};

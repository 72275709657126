import { createActionCreator } from 'deox';

import { TGetTaskCategoryMaterials, TGetTaskCategoryResponse } from '@/services/api/template/get-task-category';

// CONSTANTS

export enum EGetTaskCategoryAction {
  GET_TASK_CATEGORY = 'GET_TASK_CATEGORY',
  GET_TASK_CATEGORY_REQUEST = 'GET_TASK_CATEGORY_REQUEST',
  GET_TASK_CATEGORY_SUCCESS = 'GET_TASK_CATEGORY_SUCCESS',
  GET_TASK_CATEGORY_FAILED = 'GET_TASK_CATEGORY_FAILED',
}

// TYPES

export type TGetTaskCategoryRequest = {
  type: EGetTaskCategoryAction.GET_TASK_CATEGORY_REQUEST;
  payload: {
    materials: TGetTaskCategoryMaterials;
    successCallback?: (response: TGetTaskCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTaskCategorySuccess = {
  type: EGetTaskCategoryAction.GET_TASK_CATEGORY_SUCCESS;
  payload: { response: TGetTaskCategoryResponse };
};

export type TGetTaskCategoryFailed = { type: EGetTaskCategoryAction.GET_TASK_CATEGORY_FAILED };

// FUNCTION

export const getTaskCategoryAction = {
  request: createActionCreator(
    EGetTaskCategoryAction.GET_TASK_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TGetTaskCategoryMaterials,
        successCallback?: (response: TGetTaskCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTaskCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTaskCategoryAction.GET_TASK_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TGetTaskCategoryResponse): TGetTaskCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTaskCategoryAction.GET_TASK_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TGetTaskCategoryFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateUserCareerAction } from '@/redux/actions';
import { updateUserCareer, TUpdateUserCareerResponse } from '@/services/api';

// FUNCTION

export function* updateUserCareerSaga(action: ActionType<typeof updateUserCareerAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateUserCareer, materials);
    const updateUserCareerResponse: TUpdateUserCareerResponse = response as TUpdateUserCareerResponse;
    yield put(updateUserCareerAction.success(updateUserCareerResponse));
    successCallback?.(updateUserCareerResponse);
  } catch (err) {
    yield put(updateUserCareerAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TGetRegulationsMaterials, TGetRegulationsResponse } from '@/services/api/regulation/get-regulations';

// CONSTANTS

export enum EGetRegulationsAction {
  GET_REGULATIONS = 'GET_REGULATIONS',
  GET_REGULATIONS_REQUEST = 'GET_REGULATIONS_REQUEST',
  GET_REGULATIONS_SUCCESS = 'GET_REGULATIONS_SUCCESS',
  GET_REGULATIONS_FAILED = 'GET_REGULATIONS_FAILED',
}

// TYPES

export type TGetRegulationsRequest = {
  type: EGetRegulationsAction.GET_REGULATIONS_REQUEST;
  payload: {
    materials: TGetRegulationsMaterials;
    successCallback?: (response: TGetRegulationsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRegulationsSuccess = {
  type: EGetRegulationsAction.GET_REGULATIONS_SUCCESS;
  payload: { response: TGetRegulationsResponse };
};

export type TGetRegulationsFailed = { type: EGetRegulationsAction.GET_REGULATIONS_FAILED };

// FUNCTION

export const getRegulationsAction = {
  request: createActionCreator(
    EGetRegulationsAction.GET_REGULATIONS_REQUEST,
    (resolve) =>
      (
        materials: TGetRegulationsMaterials,
        successCallback?: (response: TGetRegulationsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRegulationsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRegulationsAction.GET_REGULATIONS_SUCCESS,
    (resolve) =>
      (response: TGetRegulationsResponse): TGetRegulationsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRegulationsAction.GET_REGULATIONS_FAILED,
    (resolve) =>
      (error: unknown): TGetRegulationsFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TDeleteTaskMaterials, TDeleteTaskResponse } from '@/services/api/template/delete-task';

// CONSTANTS

export enum EDeleteTaskAction {
  DELETE_TASK = 'DELETE_TASK',
  DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST',
  DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS',
  DELETE_TASK_FAILED = 'DELETE_TASK_FAILED',
}

// TYPES

export type TDeleteTaskRequest = {
  type: EDeleteTaskAction.DELETE_TASK_REQUEST;
  payload: {
    materials: TDeleteTaskMaterials;
    successCallback?: (response: TDeleteTaskResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteTaskSuccess = {
  type: EDeleteTaskAction.DELETE_TASK_SUCCESS;
  payload: { response: TDeleteTaskResponse };
};

export type TDeleteTaskFailed = { type: EDeleteTaskAction.DELETE_TASK_FAILED };

// FUNCTION

export const deleteTaskAction = {
  request: createActionCreator(
    EDeleteTaskAction.DELETE_TASK_REQUEST,
    (resolve) =>
      (
        materials: TDeleteTaskMaterials,
        successCallback?: (response: TDeleteTaskResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteTaskRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteTaskAction.DELETE_TASK_SUCCESS,
    (resolve) =>
      (response: TDeleteTaskResponse): TDeleteTaskSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteTaskAction.DELETE_TASK_FAILED,
    (resolve) =>
      (error: unknown): TDeleteTaskFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TUpdateRolePaths = {
  id: string | number;
};
export type TUpdateRoleBody = unknown;

export type TUpdateRoleMaterials = {
  paths?: TUpdateRolePaths;
  body?: TUpdateRoleBody;
};

export type TUpdateRoleResponse = unknown;

// FUNCTION

export const updateRole = async ({ paths, body }: TUpdateRoleMaterials): Promise<TUpdateRoleResponse> => {
  const response = await ApiService.put(`/role/${paths?.id}`, body);
  return response.data;
};

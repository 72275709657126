import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_600 }) => {
  return (
    <svg width="116" height="25" viewBox="0 0 116 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.4792 10.6953C0.583469 12.0389 0.583469 13.7893 1.4792 15.1329L8 24.9141V0.914062L1.4792 10.6953Z"
        fill={color}
      />
      <rect width="100" height="24" transform="translate(8 0.914062)" fill={color} />
      <path
        d="M114.521 10.6953C115.417 12.0389 115.417 13.7893 114.521 15.1329L108 24.9141L108 0.914062L114.521 10.6953Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;

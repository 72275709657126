import { createActionCreator } from 'deox';
import { Socket } from 'socket.io-client';

import { EUIAction } from './constants';
import {
  TInitialSocket,
  TResetActionStatus,
  TSetBackgroundImage,
  TSetDevice,
  TSetLanguage,
  TSetUsersOnline,
} from './types';

export const uiActions = {
  setDevice: createActionCreator(
    EUIAction.SET_DEVICE,
    (resolve) =>
      (deviceWidth: number): TSetDevice =>
        resolve({ deviceWidth }),
  ),
  setLanguage: createActionCreator(
    EUIAction.SET_LANGUAGE,
    (resolve) =>
      (language: string): TSetLanguage =>
        resolve({ language }),
  ),
  setBackgroundImage: createActionCreator(
    EUIAction.SET_BACKGROUND_IMAGE,
    (resolve) =>
      (backgroundImage: string): TSetBackgroundImage =>
        resolve({ backgroundImage }),
  ),
  initialSocket: createActionCreator(
    EUIAction.INITIAL_SOCKET,
    (resolve) =>
      (socket?: Socket): TInitialSocket =>
        resolve({ socket }),
  ),
  setUsersOnline: createActionCreator(
    EUIAction.SET_USERS_ONLINE,
    (resolve) =>
      (usersOnline: string[]): TSetUsersOnline =>
        resolve({ usersOnline }),
  ),
  resetActionStatus: createActionCreator(
    EUIAction.RESET_ACTION_STATUS,
    (resolve) =>
      (actionName: string): TResetActionStatus =>
        resolve({ actionName: actionName.replace('_REQUEST', '') }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_400 }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.98186 12.0667C8.82897 13.7986 10.2328 15.196 11.9685 16.0352C12.0955 16.0954 12.2359 16.1215 12.3761 16.1109C12.5162 16.1003 12.6512 16.0534 12.7676 15.9748L15.3233 14.2706C15.4364 14.1952 15.5664 14.1492 15.7017 14.1368C15.837 14.1244 15.9732 14.1459 16.0981 14.1994L20.8793 16.2485C21.0417 16.3175 21.1773 16.4374 21.2656 16.5902C21.354 16.7429 21.3903 16.9202 21.3691 17.0954C21.218 18.278 20.641 19.3648 19.7462 20.1526C18.8515 20.9403 17.7002 21.3749 16.5081 21.375C12.8261 21.375 9.29485 19.9123 6.69126 17.3087C4.08768 14.7052 2.625 11.1739 2.625 7.49192C2.62506 6.29978 3.05966 5.14854 3.84741 4.25376C4.63516 3.35898 5.72205 2.78202 6.90456 2.6309C7.07975 2.6097 7.25708 2.646 7.40984 2.73435C7.5626 2.82269 7.68251 2.95829 7.7515 3.12071L9.80237 7.90607C9.85542 8.02985 9.87703 8.16482 9.86529 8.29898C9.85355 8.43313 9.80881 8.5623 9.73507 8.67498L8.0368 11.2699C7.9595 11.3866 7.91381 11.5214 7.90418 11.661C7.89455 11.8007 7.92131 11.9405 7.98186 12.0667Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

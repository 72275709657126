import { createActionCreator } from 'deox';

import {
  TDownloadReportOnboardMaterials,
  TDownloadReportOnboardResponse,
} from '@/services/api/report/download-report-onboard';

// CONSTANTS

export enum EDownloadReportOnboardAction {
  DOWNLOAD_REPORT_ONBOARD = 'DOWNLOAD_REPORT_ONBOARD',
  DOWNLOAD_REPORT_ONBOARD_REQUEST = 'DOWNLOAD_REPORT_ONBOARD_REQUEST',
  DOWNLOAD_REPORT_ONBOARD_SUCCESS = 'DOWNLOAD_REPORT_ONBOARD_SUCCESS',
  DOWNLOAD_REPORT_ONBOARD_FAILED = 'DOWNLOAD_REPORT_ONBOARD_FAILED',
}

// TYPES

export type TDownloadReportOnboardRequest = {
  type: EDownloadReportOnboardAction.DOWNLOAD_REPORT_ONBOARD_REQUEST;
  payload: {
    materials: TDownloadReportOnboardMaterials;
    successCallback?: (response: TDownloadReportOnboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadReportOnboardSuccess = {
  type: EDownloadReportOnboardAction.DOWNLOAD_REPORT_ONBOARD_SUCCESS;
  payload: { response: TDownloadReportOnboardResponse };
};

export type TDownloadReportOnboardFailed = { type: EDownloadReportOnboardAction.DOWNLOAD_REPORT_ONBOARD_FAILED };

// FUNCTION

export const downloadReportOnboardAction = {
  request: createActionCreator(
    EDownloadReportOnboardAction.DOWNLOAD_REPORT_ONBOARD_REQUEST,
    (resolve) =>
      (
        materials: TDownloadReportOnboardMaterials,
        successCallback?: (response: TDownloadReportOnboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadReportOnboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadReportOnboardAction.DOWNLOAD_REPORT_ONBOARD_SUCCESS,
    (resolve) =>
      (response: TDownloadReportOnboardResponse): TDownloadReportOnboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadReportOnboardAction.DOWNLOAD_REPORT_ONBOARD_FAILED,
    (resolve) =>
      (error: unknown): TDownloadReportOnboardFailed =>
        resolve({ error }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M78.1969 58.6257L68.3854 62.3565L55.6533 54.2829L78.1969 58.6257Z" fill={color} />
      <path d="M40.5003 71.7823L68.1601 61.6319V31.1806L40.5003 41.331V51.4814V71.7823Z" fill="#CFD8DC" />
      <path d="M12.8404 61.6319L40.5003 71.7823V51.4814V41.331L12.8404 31.1806V61.6319Z" fill="white" />
      <path
        d="M12.8404 61.6319H12.0904C12.0904 61.9464 12.2867 62.2276 12.582 62.336L12.8404 61.6319ZM40.5003 71.7823L40.2419 72.4864C40.4087 72.5476 40.5918 72.5476 40.7586 72.4864L40.5003 71.7823ZM68.1601 61.6319L68.4185 62.336C68.7138 62.2276 68.9101 61.9464 68.9101 61.6319H68.1601ZM12.8404 31.1806L12.582 30.4765C12.2867 30.5849 12.0904 30.866 12.0904 31.1806H12.8404ZM68.1601 31.1806H68.9101C68.9101 30.866 68.7138 30.5849 68.4185 30.4765L68.1601 31.1806ZM48.2581 23.0782C47.8692 22.9355 47.4383 23.135 47.2956 23.5239C47.1529 23.9128 47.3525 24.3437 47.7413 24.4864L48.2581 23.0782ZM33.2592 24.4864C33.6481 24.3437 33.8476 23.9128 33.7049 23.5239C33.5622 23.135 33.1313 22.9355 32.7424 23.0782L33.2592 24.4864ZM12.582 62.336L40.2419 72.4864L40.7586 71.0782L13.0988 60.9278L12.582 62.336ZM40.7586 72.4864L68.4185 62.336L67.9018 60.9278L40.2419 71.0782L40.7586 72.4864ZM12.582 31.8847L40.2419 42.0351L40.7586 40.6269L13.0988 30.4765L12.582 31.8847ZM40.7586 42.0351L68.4185 31.8847L67.9018 30.4765L40.2419 40.6269L40.7586 42.0351ZM41.2503 71.7823V51.4814H39.7503V71.7823H41.2503ZM41.2503 51.4814V41.331H39.7503V51.4814H41.2503ZM47.7413 24.4864L67.9018 31.8847L68.4185 30.4765L48.2581 23.0782L47.7413 24.4864ZM32.7424 23.0782L12.582 30.4765L13.0988 31.8847L33.2592 24.4864L32.7424 23.0782ZM13.5904 61.6319V31.1806H12.0904V61.6319H13.5904ZM67.4101 31.1806V61.6319H68.9101V31.1806H67.4101Z"
        fill={color}
      />
      <path
        d="M36.1209 59.7287L40.5004 41.3311L12.8405 31.1807L8 49.5783L36.1209 59.7287Z"
        fill="white"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M44.8791 59.7287L40.4996 41.3311L68.1595 31.1807L73 49.5783L44.8791 59.7287Z"
        fill="white"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M40.4999 28.2177C41.0369 28.2177 41.4722 27.7824 41.4722 27.2454C41.4722 26.7084 41.0369 26.2731 40.4999 26.2731C39.963 26.2731 39.5276 26.7084 39.5276 27.2454C39.5276 27.7824 39.963 28.2177 40.4999 28.2177Z"
        fill={color}
      />
      <path
        d="M40.5 24.0162V19.708C41.6363 19.708 42.7471 19.3711 43.6919 18.7398C44.6367 18.1085 45.373 17.2112 45.8079 16.1615C46.2427 15.1117 46.3565 13.9565 46.1348 12.842C45.9131 11.7276 45.366 10.7039 44.5625 9.90043C43.759 9.09696 42.7353 8.54978 41.6209 8.32811C40.5064 8.10643 39.3513 8.2202 38.3015 8.65504C37.2517 9.08988 36.3544 9.82625 35.7231 10.771C35.0918 11.7158 34.7549 12.8266 34.7549 13.9629"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_600 }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3.32227" cy="6.99533" r="0.625" fill={color} stroke={color} strokeWidth="1.25" />
      <circle cx="14.165" cy="17.4384" r="0.625" fill={color} stroke={color} strokeWidth="1.25" />
      <circle cx="12.3647" cy="3.79243" r="0.625" fill={color} stroke={color} strokeWidth="1.25" />
      <path
        d="M10.5118 14.0538L6.78834 10.415C6.54027 10.1726 6.12675 10.2502 5.9835 10.5661L3.22385 16.6519C3.03976 17.0578 3.43875 17.4836 3.85578 17.3262L10.3389 14.8792C10.681 14.7501 10.7733 14.3094 10.5118 14.0538Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M6.19385 3.16605C7.40582 3.39216 9.84658 4.92991 8.78229 8.49527"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9897 12.807C15.2532 12.312 17.3499 12.807 17.9024 15.9749"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.693 3.63068L16.7162 4.63465V6.6426H14.8981V8.75908H12.8902L11.0723 10.36"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRoleMembersAction } from '@/redux/actions';
import { getRoleMembers, TGetRoleMembersResponse } from '@/services/api';

// FUNCTION

export function* getRoleMembersSaga(action: ActionType<typeof getRoleMembersAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRoleMembers, materials);
    const getRoleMembersResponse: TGetRoleMembersResponse = response as TGetRoleMembersResponse;
    yield put(getRoleMembersAction.success(getRoleMembersResponse));
    successCallback?.(getRoleMembersResponse);
  } catch (err) {
    yield put(getRoleMembersAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TCreateReportViolationParams = unknown;
export type TCreateReportViolationBody = unknown;

export type TCreateReportViolationMaterials = {
  params?: TCreateReportViolationParams;
  body?: TCreateReportViolationBody;
};

export type TCreateReportViolationResponse = unknown;

// FUNCTION

export const createReportViolation = async ({
  params,
  body,
}: TCreateReportViolationMaterials): Promise<TCreateReportViolationResponse> => {
  const response = await ApiService.post(`/report-violation/new`, body, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TGetNewMembersMaterials, TGetNewMembersResponse } from '@/services/api/user/get-new-members';

// CONSTANTS

export enum EGetNewMembersAction {
  GET_NEW_MEMBERS = 'GET_NEW_MEMBERS',
  GET_NEW_MEMBERS_REQUEST = 'GET_NEW_MEMBERS_REQUEST',
  GET_NEW_MEMBERS_SUCCESS = 'GET_NEW_MEMBERS_SUCCESS',
  GET_NEW_MEMBERS_FAILED = 'GET_NEW_MEMBERS_FAILED',
}

// TYPES

export type TGetNewMembersRequest = {
  type: EGetNewMembersAction.GET_NEW_MEMBERS_REQUEST;
  payload: {
    materials: TGetNewMembersMaterials;
    successCallback?: (response: TGetNewMembersResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetNewMembersSuccess = {
  type: EGetNewMembersAction.GET_NEW_MEMBERS_SUCCESS;
  payload: { response: TGetNewMembersResponse };
};

export type TGetNewMembersFailed = { type: EGetNewMembersAction.GET_NEW_MEMBERS_FAILED };

// FUNCTION

export const getNewMembersAction = {
  request: createActionCreator(
    EGetNewMembersAction.GET_NEW_MEMBERS_REQUEST,
    (resolve) =>
      (
        materials: TGetNewMembersMaterials,
        successCallback?: (response: TGetNewMembersResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetNewMembersRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetNewMembersAction.GET_NEW_MEMBERS_SUCCESS,
    (resolve) =>
      (response: TGetNewMembersResponse): TGetNewMembersSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetNewMembersAction.GET_NEW_MEMBERS_FAILED,
    (resolve) =>
      (error: unknown): TGetNewMembersFailed =>
        resolve({ error }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 8H1.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 4.5L1.5 8L5 11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Svg;

import { createActionCreator } from 'deox';

import { TCreateDepartmentMaterials, TCreateDepartmentResponse } from '@/services/api/department/create-department';

// CONSTANTS

export enum ECreateDepartmentAction {
  CREATE_DEPARTMENT = 'CREATE_DEPARTMENT',
  CREATE_DEPARTMENT_REQUEST = 'CREATE_DEPARTMENT_REQUEST',
  CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS',
  CREATE_DEPARTMENT_FAILED = 'CREATE_DEPARTMENT_FAILED',
}

// TYPES

export type TCreateDepartmentRequest = {
  type: ECreateDepartmentAction.CREATE_DEPARTMENT_REQUEST;
  payload: {
    materials: TCreateDepartmentMaterials;
    successCallback?: (response: TCreateDepartmentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateDepartmentSuccess = {
  type: ECreateDepartmentAction.CREATE_DEPARTMENT_SUCCESS;
  payload: { response: TCreateDepartmentResponse };
};

export type TCreateDepartmentFailed = { type: ECreateDepartmentAction.CREATE_DEPARTMENT_FAILED };

// FUNCTION

export const createDepartmentAction = {
  request: createActionCreator(
    ECreateDepartmentAction.CREATE_DEPARTMENT_REQUEST,
    (resolve) =>
      (
        materials: TCreateDepartmentMaterials,
        successCallback?: (response: TCreateDepartmentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateDepartmentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateDepartmentAction.CREATE_DEPARTMENT_SUCCESS,
    (resolve) =>
      (response: TCreateDepartmentResponse): TCreateDepartmentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateDepartmentAction.CREATE_DEPARTMENT_FAILED,
    (resolve) =>
      (error: unknown): TCreateDepartmentFailed =>
        resolve({ error }),
  ),
};

import { TRequest, TTaskMisison } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetRequestPaths = {
  id: string | number;
};
export type TGetRequestParams = unknown;

export type TGetRequestMaterials = {
  paths?: TGetRequestPaths;
  params?: TGetRequestParams;
};

export type TGetRequestResponse = {
  request: TRequest;
  taskMissions: TTaskMisison[];
};

// FUNCTION

export const getRequest = async ({ paths, params }: TGetRequestMaterials): Promise<TGetRequestResponse> => {
  const response = await ApiService.get(`/request/${paths?.id}`, { params });
  return response.data;
};

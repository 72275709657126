import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteRegulationAction } from '@/redux/actions';
import { deleteRegulation, TDeleteRegulationResponse } from '@/services/api';

// FUNCTION

export function* deleteRegulationSaga(action: ActionType<typeof deleteRegulationAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteRegulation, materials);
    const deleteRegulationResponse: TDeleteRegulationResponse = response as TDeleteRegulationResponse;
    yield put(deleteRegulationAction.success(deleteRegulationResponse));
    successCallback?.(deleteRegulationResponse);
  } catch (err) {
    yield put(deleteRegulationAction.failure(err));
    failedCallback?.(err);
  }
}

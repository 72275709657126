import { TRole } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetRolePaths = {
  id: string | number;
};
export type TGetRoleParams = unknown;

export type TGetRoleMaterials = {
  paths?: TGetRolePaths;
  params?: TGetRoleParams;
};

export type TGetRoleResponse = TRole;

// FUNCTION

export const getRole = async ({ paths, params }: TGetRoleMaterials): Promise<TGetRoleResponse> => {
  const response = await ApiService.get(`/role/${paths?.id}`, { params });
  return response.data;
};

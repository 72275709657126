import { createActionCreator } from 'deox';

import { TGetRoleMaterials, TGetRoleResponse } from '@/services/api/role/get-role';

// CONSTANTS

export enum EGetRoleAction {
  GET_ROLE = 'GET_ROLE',
  GET_ROLE_REQUEST = 'GET_ROLE_REQUEST',
  GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS',
  GET_ROLE_FAILED = 'GET_ROLE_FAILED',
}

// TYPES

export type TGetRoleRequest = {
  type: EGetRoleAction.GET_ROLE_REQUEST;
  payload: {
    materials: TGetRoleMaterials;
    successCallback?: (response: TGetRoleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRoleSuccess = {
  type: EGetRoleAction.GET_ROLE_SUCCESS;
  payload: { response?: TGetRoleResponse };
};

export type TGetRoleFailed = { type: EGetRoleAction.GET_ROLE_FAILED };

// FUNCTION

export const getRoleAction = {
  request: createActionCreator(
    EGetRoleAction.GET_ROLE_REQUEST,
    (resolve) =>
      (
        materials: TGetRoleMaterials,
        successCallback?: (response: TGetRoleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRoleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRoleAction.GET_ROLE_SUCCESS,
    (resolve) =>
      (response?: TGetRoleResponse): TGetRoleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRoleAction.GET_ROLE_FAILED,
    (resolve) =>
      (error: unknown): TGetRoleFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TCreateTaskParams = unknown;
export type TCreateTaskBody = unknown;

export type TCreateTaskMaterials = {
  params?: TCreateTaskParams;
  body?: TCreateTaskBody;
};

export type TCreateTaskResponse = unknown;

// FUNCTION

export const createTask = async ({ params, body }: TCreateTaskMaterials): Promise<TCreateTaskResponse> => {
  const response = await ApiService.post(`/template/task/new`, body, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TCreateAssetMaterials, TCreateAssetResponse } from '@/services/api/asset/create-asset';

// CONSTANTS

export enum ECreateAssetAction {
  CREATE_ASSET = 'CREATE_ASSET',
  CREATE_ASSET_REQUEST = 'CREATE_ASSET_REQUEST',
  CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS',
  CREATE_ASSET_FAILED = 'CREATE_ASSET_FAILED',
}

// TYPES

export type TCreateAssetRequest = {
  type: ECreateAssetAction.CREATE_ASSET_REQUEST;
  payload: {
    materials: TCreateAssetMaterials;
    successCallback?: (response: TCreateAssetResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateAssetSuccess = {
  type: ECreateAssetAction.CREATE_ASSET_SUCCESS;
  payload: { response: TCreateAssetResponse };
};

export type TCreateAssetFailed = { type: ECreateAssetAction.CREATE_ASSET_FAILED };

// FUNCTION

export const createAssetAction = {
  request: createActionCreator(
    ECreateAssetAction.CREATE_ASSET_REQUEST,
    (resolve) =>
      (
        materials: TCreateAssetMaterials,
        successCallback?: (response: TCreateAssetResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateAssetRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateAssetAction.CREATE_ASSET_SUCCESS,
    (resolve) =>
      (response: TCreateAssetResponse): TCreateAssetSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateAssetAction.CREATE_ASSET_FAILED,
    (resolve) =>
      (error: unknown): TCreateAssetFailed =>
        resolve({ error }),
  ),
};

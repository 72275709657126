import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLUE_300 }) => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_613_50222" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
        <circle cx="16.625" cy="16.9227" r="16" fill={EIconColor.WHITE} />
      </mask>
      <g mask="url(#mask0_613_50222)">
        <circle cx="16.625" cy="16.9227" r="16" fill={color} />
        <path
          d="M-4.25662 53.4377C-0.6359 20.8768 8.9536 6.15891 37.2467 -0.446183L37.2467 30.146L19.6363 56.159C6.14024 64.5167 -7.40505 81.7514 -4.25662 53.4377Z"
          fill={EIconColor.BLUE_500}
        />
        <path
          d="M-9.27107 40.8946C8.18979 16.8324 29.6129 16.5541 53.0261 16.8975L12.437 54.9972C-3.3253 56.879 -24.4544 61.8182 -9.27107 40.8946Z"
          fill={EIconColor.BLUE_700}
        />
        <path
          d="M15.6247 15.2367L13.5935 11.7185C13.3867 11.3596 13.3308 10.9333 13.4382 10.5333C13.5455 10.1333 13.8073 9.79228 14.166 9.58519C14.5247 9.37811 14.9509 9.3219 15.351 9.42893C15.7511 9.53596 16.0924 9.79746 16.2998 10.156L19.4248 15.5686"
          stroke={EIconColor.WHITE}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2305 17.3405L10.2617 12.1985C10.1589 12.0207 10.0922 11.8245 10.0653 11.621C10.0383 11.4175 10.0518 11.2106 10.1048 11.0123C10.1579 10.814 10.2495 10.628 10.3744 10.4651C10.4994 10.3022 10.6552 10.1656 10.833 10.0629C11.0108 9.96026 11.2071 9.89366 11.4106 9.8669C11.6142 9.84015 11.821 9.85377 12.0193 9.90699C12.2176 9.96021 12.4034 10.052 12.5662 10.1771C12.729 10.3021 12.8656 10.4581 12.9681 10.636L15.6243 15.2367"
          stroke={EIconColor.WHITE}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2805 19.8375C17.8661 19.1197 17.7538 18.2667 17.9683 17.4662C18.1828 16.6656 18.7066 15.9831 19.4244 15.5687L18.6431 14.2155C18.5403 14.0378 18.4735 13.8416 18.4466 13.638C18.4197 13.4345 18.4331 13.2277 18.4862 13.0293C18.5392 12.831 18.6309 12.6451 18.7558 12.4822C18.8807 12.3193 19.0366 12.1826 19.2144 12.0799C19.3922 11.9773 19.5884 11.9107 19.792 11.8839C19.9955 11.8572 20.2024 11.8708 20.4007 11.924C20.5989 11.9772 20.7848 12.069 20.9476 12.1941C21.1104 12.3192 21.2469 12.4751 21.3494 12.653L22.9119 15.3593C23.3225 16.0701 23.5891 16.8549 23.6964 17.6687C23.8036 18.4825 23.7496 19.3095 23.5372 20.1025C23.3248 20.8954 22.9583 21.6387 22.4586 22.29C21.959 22.9413 21.3359 23.4877 20.625 23.8982C19.9141 24.3086 19.1293 24.575 18.3154 24.6821C17.5016 24.7892 16.6746 24.7349 15.8817 24.5224C15.0888 24.3098 14.3455 23.9432 13.6944 23.4433C13.0432 22.9435 12.4969 22.3203 12.0866 21.6093L8.80537 15.926C8.59858 15.5672 8.54273 15.1409 8.65009 14.7409C8.75746 14.3409 9.01925 13.9998 9.37793 13.7927C9.73662 13.5857 10.1629 13.5294 10.563 13.6365C10.9631 13.7435 11.3043 14.005 11.5117 14.3635L13.2304 17.3405"
          stroke={EIconColor.WHITE}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5855 25.6729C11.5261 24.9379 10.6431 23.977 10 22.8595"
          stroke={EIconColor.WHITE}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 9.3446C20.7131 9.34451 21.4136 9.53222 22.0312 9.88886C22.6487 10.2455 23.1614 10.7585 23.5176 11.3762"
          stroke={EIconColor.WHITE}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Svg;

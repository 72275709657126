import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 13.75C11.5 10.9722 7.79167 5.20062 7.375 2.5L6.58786 4.46786C6.28643 5.22142 6.20498 6.05436 6.46011 6.82483C8.10745 11.7997 12.6969 15.9805 16.75 18.125C16.9072 17.7989 17.5 16.2963 18 13.75Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M4.25 18.125C4.1348 17.8838 3.5 16.2963 3 13.75C5.10911 12.8487 7.01633 11.9907 8.3427 10.6201C8.67139 11.1377 9.67725 12.4609 10.484 13.3083C8.88605 15.3745 6.33448 17.0221 4.25 18.125Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M13.625 2.5L14.4121 4.46786C14.7136 5.22142 14.8075 6.06187 14.4893 6.80849C13.9732 8.01945 13.1873 8.99714 12.4995 10.2417C12.1064 9.91211 11.0811 8.90869 10.5684 8.28857C12.111 6.03365 13.4123 3.87853 13.625 2.5Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M7.375 2.5C7.89583 2.29167 9.25 1.875 10.5 1.875C11.75 1.875 13.1042 2.29167 13.625 2.5"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33008 4.64741C8.69179 4.43908 9.63225 4.02966 10.5004 4.02966C11.3685 4.02966 12.3089 4.43908 12.6707 4.64741"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

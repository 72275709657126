import { TTypeWorkTime } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetTypesWorkParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
  status?: string;
};

export type TGetTypesWorkMaterials = {
  params?: TGetTypesWorkParams;
};

export type TGetTypesWorkResponse = TPaginateResponse & {
  data: TTypeWorkTime[];
  total: { [key: string]: string | number };
};

// FUNCTION

export const getTypesWork = async ({ params }: TGetTypesWorkMaterials): Promise<TGetTypesWorkResponse> => {
  const response = await ApiService.get(`/type-work/list`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TImportUsersParams = unknown;
export type TImportUsersBody = unknown;

export type TImportUsersMaterials = {
  params?: TImportUsersParams;
  body?: TImportUsersBody;
};

export type TImportUsersResponse = unknown;

// FUNCTION

export const importUsers = async ({ params, body }: TImportUsersMaterials): Promise<TImportUsersResponse> => {
  const response = await ApiService.post(`/user/import-users`, body, { params });
  return response.data;
};

import { TDashboardState } from '@/redux/reducers/dashboard';
import { TGetCompanyTimeOffsSuccess } from '@/redux/actions/dashboard';

export const getCompanyTimeOffsUpdateState = (
  state: TDashboardState,
  action: TGetCompanyTimeOffsSuccess,
): TDashboardState => ({
  ...state,
  getCompanyTimeOffsResponse: action.payload.response,
});

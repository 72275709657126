import { createActionCreator } from 'deox';

import { TRemoveApproverMaterials, TRemoveApproverResponse } from '@/services/api/approver/remove-approver';

// CONSTANTS

export enum ERemoveApproverAction {
  REMOVE_APPROVER = 'REMOVE_APPROVER',
  REMOVE_APPROVER_REQUEST = 'REMOVE_APPROVER_REQUEST',
  REMOVE_APPROVER_SUCCESS = 'REMOVE_APPROVER_SUCCESS',
  REMOVE_APPROVER_FAILED = 'REMOVE_APPROVER_FAILED',
}

// TYPES

export type TRemoveApproverRequest = {
  type: ERemoveApproverAction.REMOVE_APPROVER_REQUEST;
  payload: {
    materials: TRemoveApproverMaterials;
    successCallback?: (response: TRemoveApproverResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRemoveApproverSuccess = {
  type: ERemoveApproverAction.REMOVE_APPROVER_SUCCESS;
  payload: { response: TRemoveApproverResponse };
};

export type TRemoveApproverFailed = { type: ERemoveApproverAction.REMOVE_APPROVER_FAILED };

// FUNCTION

export const removeApproverAction = {
  request: createActionCreator(
    ERemoveApproverAction.REMOVE_APPROVER_REQUEST,
    (resolve) =>
      (
        materials: TRemoveApproverMaterials,
        successCallback?: (response: TRemoveApproverResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRemoveApproverRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERemoveApproverAction.REMOVE_APPROVER_SUCCESS,
    (resolve) =>
      (response: TRemoveApproverResponse): TRemoveApproverSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERemoveApproverAction.REMOVE_APPROVER_FAILED,
    (resolve) =>
      (error: unknown): TRemoveApproverFailed =>
        resolve({ error }),
  ),
};

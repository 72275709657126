import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateBenefitAction } from '@/redux/actions';
import { updateBenefit, TUpdateBenefitResponse } from '@/services/api';

// FUNCTION

export function* updateBenefitSaga(action: ActionType<typeof updateBenefitAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateBenefit, materials);
    const updateBenefitResponse: TUpdateBenefitResponse = response as TUpdateBenefitResponse;
    yield put(updateBenefitAction.success(updateBenefitResponse));
    successCallback?.(updateBenefitResponse);
  } catch (err) {
    yield put(updateBenefitAction.failure(err));
    failedCallback?.(err);
  }
}

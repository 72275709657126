import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DropdownCustom from '@/components/DropdownCustom';
import Input from '@/components/Input/Input';
import WrapperLazyLoad from '@/components/WrapperLazyLoad';
import Avatar from '@/components/Avatar';
import { TGetUsersGlobalParams } from '@/services/api/global';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/common/constants';
import { TRootState } from '@/redux/reducers';
import { TSelectOption } from '@/components/Select';
import Empty from '@/components/Empty';
import { getUsersGlobalAction } from '@/redux/actions';
import { EEmpty } from '@/common/enums';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import { TUser } from '@/common/models';

import { TInputActionUsersProps } from './InputActionUsers.types.d';
import './InputActionUsers.scss';

const InputActionUsers: React.FC<TInputActionUsersProps> = ({
  placeholder,
  size,
  hideUsers,
  disabled,
  getPopupContainer,
  onClickUser,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const usersGlobalState = useSelector((state: TRootState) => state.globalReducer.getUsersGlobalResponse);
  const [usersOption, setUsersOption] = useState<TSelectOption[]>([]);
  const [getUsersParamsRequest, setGetUsersParamsRequest] = useState<TGetUsersGlobalParams>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const isEmpty = usersOption.length === 0;

  const handleSearch = (search?: string): void => {
    setGetUsersParamsRequest({
      ...getUsersParamsRequest,
      page: DEFAULT_PAGE,
      search,
    });
  };

  const handleScrollEnd = (): void => {
    const isLoadMore = getUsersParamsRequest.page < (usersGlobalState?.pagination?.totalPage || 0);
    if (isLoadMore) {
      setGetUsersParamsRequest({
        ...getUsersParamsRequest,
        page: getUsersParamsRequest.page + 1,
      });
    }
  };

  const handleClickUser = (data: TUser): void => {
    setVisible(false);
    setInputValue('');
    onClickUser?.(data);
  };

  const renderOverlay = (): React.ReactElement => {
    return (
      <div className="InputActionUsers-dropdown">
        {isEmpty ? (
          <Empty />
        ) : (
          <WrapperLazyLoad maxHeight={256} onEnd={handleScrollEnd}>
            <div className="InputActionUsers-list">
              {usersOption
                .filter((item) => !hideUsers?.includes(item.value as string))
                .map((item) => (
                  <div
                    className="InputActionUsers-list-item flex items-center"
                    onClick={(): void => handleClickUser(item?.data)}
                  >
                    <div className="InputActionUsers-list-item-avatar">
                      <Avatar showZoom={false} image={item.data?.avatar} size={32} />
                    </div>
                    <div className="InputActionUsers-list-item-info">
                      <div className="InputActionUsers-list-item-info-title">{item.label}</div>
                      <div className="InputActionUsers-list-item-info-description">
                        {item.data?.email || EEmpty.DASH}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </WrapperLazyLoad>
        )}
      </div>
    );
  };

  const getUsersGlobal = useCallback(() => {
    dispatch(
      getUsersGlobalAction.request({ params: getUsersParamsRequest }, (response): void => {
        const isFirstFetching = getUsersParamsRequest.page === DEFAULT_PAGE;
        const dataResponse = response.data.map((item) => ({
          label: item.fullName || EEmpty.DASH,
          value: item.id,
          data: item,
        }));
        setUsersOption(isFirstFetching ? dataResponse : [...usersOption, ...dataResponse]);
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersParamsRequest, dispatch]);

  useEffect(() => {
    if (visible) {
      const isFirstFetching = getUsersParamsRequest.page === DEFAULT_PAGE && !getUsersParamsRequest.search;
      if (isFirstFetching) getUsersGlobal();
      else {
        setGetUsersParamsRequest({ page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE, search: inputValue || undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visible) getUsersGlobal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersGlobal]);

  return (
    <div className="InputActionUsers">
      <DropdownCustom
        overlay={renderOverlay()}
        visible={visible}
        onVisibleChange={setVisible}
        disabled={disabled}
        getPopupContainer={getPopupContainer}
      >
        <div
          className="InputActionUsers-wrapper"
          onClick={(e): void => {
            visible && e.stopPropagation();
          }}
        >
          <Input
            prefix={<Icon name={EIconName.Search} color={EIconColor.GRAYSCALE_900} />}
            noAffixBorder
            placeholder={placeholder}
            size={size}
            value={inputValue}
            onChange={(value): void => setInputValue(String(value))}
            onSearch={handleSearch}
          />
        </div>
      </DropdownCustom>
    </div>
  );
};

export default InputActionUsers;

import { TTaskCategory } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetTasksCategoryParams = unknown;

export type TGetTasksCategoryMaterials = {
  params?: TGetTasksCategoryParams;
};

export type TGetTasksCategoryResponse = TPaginateResponse & {
  data: TTaskCategory[];
};

// FUNCTION

export const getTasksCategory = async ({ params }: TGetTasksCategoryMaterials): Promise<TGetTasksCategoryResponse> => {
  const response = await ApiService.get(`/template/task-category/list`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { cancelRequestAction } from '@/redux/actions';
import { cancelRequest, TCancelRequestResponse } from '@/services/api';

// FUNCTION

export function* cancelRequestSaga(action: ActionType<typeof cancelRequestAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(cancelRequest, materials);
    const cancelRequestResponse: TCancelRequestResponse = response as TCancelRequestResponse;
    yield put(cancelRequestAction.success(cancelRequestResponse));
    successCallback?.(cancelRequestResponse);
  } catch (err) {
    yield put(cancelRequestAction.failure(err));
    failedCallback?.(err);
  }
}

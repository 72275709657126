import { createActionCreator } from 'deox';

import {
  TGetReportViolationMaterials,
  TGetReportViolationResponse,
} from '@/services/api/report-violation/get-report-violation';

// CONSTANTS

export enum EGetReportViolationAction {
  GET_REPORT_VIOLATION = 'GET_REPORT_VIOLATION',
  GET_REPORT_VIOLATION_REQUEST = 'GET_REPORT_VIOLATION_REQUEST',
  GET_REPORT_VIOLATION_SUCCESS = 'GET_REPORT_VIOLATION_SUCCESS',
  GET_REPORT_VIOLATION_FAILED = 'GET_REPORT_VIOLATION_FAILED',
}

// TYPES

export type TGetReportViolationRequest = {
  type: EGetReportViolationAction.GET_REPORT_VIOLATION_REQUEST;
  payload: {
    materials: TGetReportViolationMaterials;
    successCallback?: (response: TGetReportViolationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportViolationSuccess = {
  type: EGetReportViolationAction.GET_REPORT_VIOLATION_SUCCESS;
  payload: { response: TGetReportViolationResponse };
};

export type TGetReportViolationFailed = { type: EGetReportViolationAction.GET_REPORT_VIOLATION_FAILED };

// FUNCTION

export const getReportViolationAction = {
  request: createActionCreator(
    EGetReportViolationAction.GET_REPORT_VIOLATION_REQUEST,
    (resolve) =>
      (
        materials: TGetReportViolationMaterials,
        successCallback?: (response: TGetReportViolationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportViolationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportViolationAction.GET_REPORT_VIOLATION_SUCCESS,
    (resolve) =>
      (response: TGetReportViolationResponse): TGetReportViolationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportViolationAction.GET_REPORT_VIOLATION_FAILED,
    (resolve) =>
      (error: unknown): TGetReportViolationFailed =>
        resolve({ error }),
  ),
};

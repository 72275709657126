import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getHolidayAction } from '@/redux/actions';
import { getHoliday, TGetHolidayResponse } from '@/services/api';

// FUNCTION

export function* getHolidaySaga(action: ActionType<typeof getHolidayAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getHoliday, materials);
    const getHolidayResponse: TGetHolidayResponse = response as TGetHolidayResponse;
    yield put(getHolidayAction.success(getHolidayResponse));
    successCallback?.(getHolidayResponse);
  } catch (err) {
    yield put(getHolidayAction.failure(err));
    failedCallback?.(err);
  }
}

import { TRegulateCategory } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetRegulationsParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
  filterCategories?: string;
};

export type TGetRegulationsMaterials = {
  params?: TGetRegulationsParams;
};

export type TGetRegulationsResponse = TPaginateResponse & {
  data: TRegulateCategory[];
};

// FUNCTION

export const getRegulations = async ({ params }: TGetRegulationsMaterials): Promise<TGetRegulationsResponse> => {
  const response = await ApiService.get(`/regulation/list`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TCreateTaskMaterials, TCreateTaskResponse } from '@/services/api/template/create-task';

// CONSTANTS

export enum ECreateTaskAction {
  CREATE_TASK = 'CREATE_TASK',
  CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST',
  CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS',
  CREATE_TASK_FAILED = 'CREATE_TASK_FAILED',
}

// TYPES

export type TCreateTaskRequest = {
  type: ECreateTaskAction.CREATE_TASK_REQUEST;
  payload: {
    materials: TCreateTaskMaterials;
    successCallback?: (response: TCreateTaskResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateTaskSuccess = {
  type: ECreateTaskAction.CREATE_TASK_SUCCESS;
  payload: { response: TCreateTaskResponse };
};

export type TCreateTaskFailed = { type: ECreateTaskAction.CREATE_TASK_FAILED };

// FUNCTION

export const createTaskAction = {
  request: createActionCreator(
    ECreateTaskAction.CREATE_TASK_REQUEST,
    (resolve) =>
      (
        materials: TCreateTaskMaterials,
        successCallback?: (response: TCreateTaskResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateTaskRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateTaskAction.CREATE_TASK_SUCCESS,
    (resolve) =>
      (response: TCreateTaskResponse): TCreateTaskSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateTaskAction.CREATE_TASK_FAILED,
    (resolve) =>
      (error: unknown): TCreateTaskFailed =>
        resolve({ error }),
  ),
};

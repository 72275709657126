import ApiService from '@/services/api';

// TYPES

export type TUpdateHolidayPaths = {
  id: string | number;
};
export type TUpdateHolidayBody = unknown;

export type TUpdateHolidayMaterials = {
  paths?: TUpdateHolidayPaths;
  body?: TUpdateHolidayBody;
};

export type TUpdateHolidayResponse = unknown;

// FUNCTION

export const updateHoliday = async ({ paths, body }: TUpdateHolidayMaterials): Promise<TUpdateHolidayResponse> => {
  const response = await ApiService.put(`/holiday/${paths?.id}`, body);
  return response.data;
};

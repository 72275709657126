import { TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetNewMembersParams = {
  page: number;
  pageSize: number;
};

export type TGetNewMembersMaterials = {
  params?: TGetNewMembersParams;
};

export type TGetNewMembersResponse = TPaginateResponse & {
  data: TUser[];
};

// FUNCTION

export const getNewMembers = async ({ params }: TGetNewMembersMaterials): Promise<TGetNewMembersResponse> => {
  const response = await ApiService.get(`/user/new-member/list`, { params });
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLUE_300 }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125Z"
        fill={color}
      />
      <path
        d="M8.125 8.125C8.125 7.75416 8.23497 7.39165 8.44099 7.08331C8.64702 6.77496 8.93986 6.53464 9.28247 6.39273C9.62508 6.25081 10.0021 6.21368 10.3658 6.28603C10.7295 6.35838 11.0636 6.53695 11.3258 6.79918C11.588 7.0614 11.7666 7.39549 11.839 7.75921C11.9113 8.12292 11.8742 8.49992 11.7323 8.84253C11.5904 9.18514 11.35 9.47798 11.0417 9.68401C10.7334 9.89003 10.3708 10 10 10V11.25"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.125C9.81458 13.125 9.63332 13.18 9.47915 13.283C9.32498 13.386 9.20482 13.5324 9.13386 13.7037C9.06291 13.875 9.04434 14.0635 9.08051 14.2454C9.11669 14.4273 9.20598 14.5943 9.33709 14.7254C9.4682 14.8565 9.63525 14.9458 9.8171 14.982C9.99896 15.0182 10.1875 14.9996 10.3588 14.9286C10.5301 14.8577 10.6765 14.7375 10.7795 14.5833C10.8825 14.4292 10.9375 14.2479 10.9375 14.0625C10.9343 13.8149 10.8345 13.5783 10.6593 13.4032C10.4842 13.228 10.2476 13.1282 10 13.125Z"
        fill="white"
      />
    </svg>
  );
};

export default Svg;

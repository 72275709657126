import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getBanksAction } from '@/redux/actions';
import { getBanks, TGetBanksResponse } from '@/services/api';

// FUNCTION

export function* getBanksSaga(action: ActionType<typeof getBanksAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getBanks, materials);
    const getBanksResponse: TGetBanksResponse = response as TGetBanksResponse;
    yield put(getBanksAction.success(getBanksResponse));
    successCallback?.(getBanksResponse);
  } catch (err) {
    yield put(getBanksAction.failure(err));
    failedCallback?.(err);
  }
}

import { TBenefitState } from '@/redux/reducers/benefit';
import { TGetUserBenefitsHistorySuccess } from '@/redux/actions/benefit';

export const getUserBenefitsHistoryUpdateState = (
  state: TBenefitState,
  action: TGetUserBenefitsHistorySuccess,
): TBenefitState => ({
  ...state,
  getUserBenefitsHistoryResponse: action.payload.response,
});

import { TBranch } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetBranchesParams = unknown;

export type TGetBranchesMaterials = {
  params?: TGetBranchesParams;
};

export type TGetBranchesResponse = TBranch[];

// FUNCTION

export const getBranches = async ({ params }: TGetBranchesMaterials): Promise<TGetBranchesResponse> => {
  const response = await ApiService.get(`/branch/list`, { params });
  return response.data;
};

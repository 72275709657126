import ApiService from '@/services/api';

// TYPES

export type TAddUserRolesPaths = {
  id: string | number;
};
export type TAddUserRolesParams = unknown;
export type TAddUserRolesBody = unknown;

export type TAddUserRolesMaterials = {
  paths?: TAddUserRolesPaths;
  params?: TAddUserRolesParams;
  body?: TAddUserRolesBody;
};

export type TAddUserRolesResponse = unknown;

// FUNCTION

export const addUserRoles = async ({ paths, params, body }: TAddUserRolesMaterials): Promise<TAddUserRolesResponse> => {
  const response = await ApiService.post(`/user/add-roles/${paths?.id}`, body, { params });
  return response.data;
};

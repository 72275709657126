import { createReducer } from 'deox';

import { TGetCompanyResponse, TUpdateCompanyResponse, TUpdateGenerateEmployIdResponse } from '@/services/api/company';
import { getCompanyAction, updateCompanyAction, updateGenerateEmployIdAction } from '@/redux/actions';
import { getCompanyUpdateState } from './get-company';
import { updateCompanyUpdateState } from './update-company';
import { updateGenerateEmployIdUpdateState } from './update-generate-employ-id';

export type TCompanyState = {
  getCompanyResponse?: TGetCompanyResponse;
  updateCompanyResponse?: TUpdateCompanyResponse;
  updateGenerateEmployIdResponse?: TUpdateGenerateEmployIdResponse;
};

const initialState: TCompanyState = {
  getCompanyResponse: undefined,
  updateCompanyResponse: undefined,
  updateGenerateEmployIdResponse: undefined,
};

const CompanyReducer = createReducer(initialState, (handleAction) => [
  handleAction(getCompanyAction.success, getCompanyUpdateState),
  handleAction(updateCompanyAction.success, updateCompanyUpdateState),
  handleAction(updateGenerateEmployIdAction.success, updateGenerateEmployIdUpdateState),
]);

export default CompanyReducer;

import ApiService from '@/services/api';

// TYPES

export type TDeleteRegulateCategoryPaths = {
  id: string | number;
};
export type TDeleteRegulateCategoryParams = unknown;

export type TDeleteRegulateCategoryMaterials = {
  paths?: TDeleteRegulateCategoryPaths;
  params?: TDeleteRegulateCategoryParams;
};

export type TDeleteRegulateCategoryResponse = unknown;

// FUNCTION

export const deleteRegulateCategory = async ({
  paths,
  params,
}: TDeleteRegulateCategoryMaterials): Promise<TDeleteRegulateCategoryResponse> => {
  const response = await ApiService.delete(`/regulate-category/${paths?.id}`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createHolidayAction } from '@/redux/actions';
import { createHoliday, TCreateHolidayResponse } from '@/services/api';

// FUNCTION

export function* createHolidaySaga(action: ActionType<typeof createHolidayAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createHoliday, materials);
    const createHolidayResponse: TCreateHolidayResponse = response as TCreateHolidayResponse;
    yield put(createHolidayAction.success(createHolidayResponse));
    successCallback?.(createHolidayResponse);
  } catch (err) {
    yield put(createHolidayAction.failure(err));
    failedCallback?.(err);
  }
}

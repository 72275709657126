import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTasksCategoryAction } from '@/redux/actions';
import { getTasksCategory, TGetTasksCategoryResponse } from '@/services/api';

// FUNCTION

export function* getTasksCategorySaga(action: ActionType<typeof getTasksCategoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTasksCategory, materials);
    const getTasksCategoryResponse: TGetTasksCategoryResponse = response as TGetTasksCategoryResponse;
    yield put(getTasksCategoryAction.success(getTasksCategoryResponse));
    successCallback?.(getTasksCategoryResponse);
  } catch (err) {
    yield put(getTasksCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

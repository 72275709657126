import { createActionCreator } from 'deox';

import { TGetMyAssetsMaterials, TGetMyAssetsResponse } from '@/services/api/asset/get-my-assets';

// CONSTANTS

export enum EGetMyAssetsAction {
  GET_MY_ASSETS = 'GET_MY_ASSETS',
  GET_MY_ASSETS_REQUEST = 'GET_MY_ASSETS_REQUEST',
  GET_MY_ASSETS_SUCCESS = 'GET_MY_ASSETS_SUCCESS',
  GET_MY_ASSETS_FAILED = 'GET_MY_ASSETS_FAILED',
}

// TYPES

export type TGetMyAssetsRequest = {
  type: EGetMyAssetsAction.GET_MY_ASSETS_REQUEST;
  payload: {
    materials: TGetMyAssetsMaterials;
    successCallback?: (response: TGetMyAssetsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMyAssetsSuccess = {
  type: EGetMyAssetsAction.GET_MY_ASSETS_SUCCESS;
  payload: { response?: TGetMyAssetsResponse };
};

export type TGetMyAssetsFailed = { type: EGetMyAssetsAction.GET_MY_ASSETS_FAILED };

// FUNCTION

export const getMyAssetsAction = {
  request: createActionCreator(
    EGetMyAssetsAction.GET_MY_ASSETS_REQUEST,
    (resolve) =>
      (
        materials: TGetMyAssetsMaterials,
        successCallback?: (response: TGetMyAssetsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMyAssetsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMyAssetsAction.GET_MY_ASSETS_SUCCESS,
    (resolve) =>
      (response?: TGetMyAssetsResponse): TGetMyAssetsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMyAssetsAction.GET_MY_ASSETS_FAILED,
    (resolve) =>
      (error: unknown): TGetMyAssetsFailed =>
        resolve({ error }),
  ),
};

import { TRequestState } from '@/redux/reducers/request';
import { TGetRequestNotificationsSuccess } from '@/redux/actions/request';

export const getRequestNotificationsUpdateState = (
  state: TRequestState,
  action: TGetRequestNotificationsSuccess,
): TRequestState => ({
  ...state,
  getRequestNotificationsResponse: action.payload.response,
});

import { TDashboardState } from '@/redux/reducers/dashboard';
import { TSeenNotificationSuccess } from '@/redux/actions/dashboard';

export const seenNotificationUpdateState = (
  state: TDashboardState,
  action: TSeenNotificationSuccess,
): TDashboardState => ({
  ...state,
  seenNotificationResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import { TLoginIdPortalMaterials, TLoginIdPortalResponse } from '@/services/api/auth/login-id-portal';

// CONSTANTS

export enum ELoginIdPortalAction {
  LOGIN_ID_PORTAL = 'LOGIN_ID_PORTAL',
  LOGIN_ID_PORTAL_REQUEST = 'LOGIN_ID_PORTAL_REQUEST',
  LOGIN_ID_PORTAL_SUCCESS = 'LOGIN_ID_PORTAL_SUCCESS',
  LOGIN_ID_PORTAL_FAILED = 'LOGIN_ID_PORTAL_FAILED',
}

// TYPES

export type TLoginIdPortalRequest = {
  type: ELoginIdPortalAction.LOGIN_ID_PORTAL_REQUEST;
  payload: {
    materials: TLoginIdPortalMaterials;
    successCallback?: (response: TLoginIdPortalResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TLoginIdPortalSuccess = {
  type: ELoginIdPortalAction.LOGIN_ID_PORTAL_SUCCESS;
  payload: { response: TLoginIdPortalResponse };
};

export type TLoginIdPortalFailed = { type: ELoginIdPortalAction.LOGIN_ID_PORTAL_FAILED };

// FUNCTION

export const loginIdPortalAction = {
  request: createActionCreator(
    ELoginIdPortalAction.LOGIN_ID_PORTAL_REQUEST,
    (resolve) =>
      (
        materials: TLoginIdPortalMaterials,
        successCallback?: (response: TLoginIdPortalResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TLoginIdPortalRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ELoginIdPortalAction.LOGIN_ID_PORTAL_SUCCESS,
    (resolve) =>
      (response: TLoginIdPortalResponse): TLoginIdPortalSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ELoginIdPortalAction.LOGIN_ID_PORTAL_FAILED,
    (resolve) =>
      (error: unknown): TLoginIdPortalFailed =>
        resolve({ error }),
  ),
};

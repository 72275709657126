import { THoliday } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetHolidayPaths = {
  id: string | number;
};
export type TGetHolidayParams = unknown;

export type TGetHolidayMaterials = {
  paths?: TGetHolidayPaths;
  params?: TGetHolidayParams;
};

export type TGetHolidayResponse = THoliday;

// FUNCTION

export const getHoliday = async ({ paths, params }: TGetHolidayMaterials): Promise<TGetHolidayResponse> => {
  const response = await ApiService.get(`/holiday/${paths?.id}`, { params });
  return response.data;
};

export enum EResponseCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  MULTI_STATUS = 207,
  ALREADY_REPORTED = 208,
  I_AM_USED = 226,

  MULTIPLE_CHOICE = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED_REQUEST = 421,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  TOO_EARLY = 425,
  UPGRADE_REQUIRED = 426,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  VARIANT_ALSO_NEGOTIATES = 506,
  INSUFFICIENT_STORAGE = 507,
  LOOP_DETECTED = 508,
  NOT_EXTENDED = 510,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export enum ETypeNotification {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum ETimeoutDebounce {
  SEARCH = 300,
}

export enum EEmpty {
  DASH = '-',
  DOUBLE_DASH = '--',
  INFINITE = '∞',
  ZERO = '0',
}

export enum EFormat {
  DATE_SLASH = 'DD/MM/YYYY',
  DATE_TIME = 'DD/MM/YYYY - HH:mm',
  TEXT_MONTH = 'MMMM',
  TEXT_DAY = 'DD',
  MONTH_YEAR_SLASH = 'MM/YYYY',
  YEAR = 'YYYY',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'HH:mm' = 'HH:mm',
  'HH:mm:ss' = 'HH:mm:ss',
  'HH:mm A' = 'HH:mm A',
  'MMM' = 'MMM',
}

export enum EDueDate {
  NORMAL = 'NORMAL',
  ONBOARD = 'ONBOARD',
  OFFBOARD = 'OFFBOARD',
}

export enum EPriority {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum EGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum EMaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WINDOWED = 'WINDOWED',
}

export enum EShareFileStatus {
  ONLY_ADMIN = 'Only Admin',
  INVITED_PEOPLE = 'Only Invited People',
  EVERYONE = 'Everyone at Acazia Software',
}

export enum EShareDropdown {
  RENAME = 'rename',
  COPY = 'copy',
  MOVE = 'move',
  DOWNLOAD = 'download',
  SHARE = 'share',
  DELETE = 'delete',
}

export enum EAccountStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATE = 'DEACTIVATE',
  INVITE = 'INVITE',
}

export enum EUserStatus {
  ON_BOARDING = 'ON_BOARDING',
  ON_PROBATION = 'ON_PROBATION',
  ACTIVE = 'ACTIVE',
  ON_LEAVE = 'ON_LEAVE',
  OFF_BOARDING = 'OFF_BOARDING',
  RESIGNED = 'RESIGNED',
  REHIRE = 'REHIRE',
}

export enum ELanguage {
  VN = 'VN',
  EN = 'EN',
}

export enum EDateFormat {
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'DD-MMM-YYYY' = 'DD MMM YYYY',
}

export enum EHolidayApplyTo {
  ALL = 'ALL',
  BASE_TYPE = 'BASE_TYPE',
  SPECIFIC = 'SPECIFIC',
}

export enum EBenefitType {
  MONETARY = 'MONETARY',
  NON_MONETARY = 'NON_MONETARY',
}

export enum EEligibilityStart {
  ENTERED_DATE = 'ENTERED_DATE',
  AFTER_ONBOARD = 'AFTER_ONBOARD',
  AFTER_PROBATION = 'AFTER_PROBATION',
  PERIOD_AFTER_ONBOARD = 'PERIOD_AFTER_ONBOARD',
  PERIOD_AFTER_PROBATION = 'PERIOD_AFTER_PROBATION',
}

export enum EEligibilityEnd {
  ENTERED_DATE = 'ENTERED_DATE',
  AFTER_LAST_WORK_DAY = 'AFTER_LAST_WORK_DAY',
  AFTER_RESIGNATION_APPROVAL = 'AFTER_RESIGNATION_APPROVAL',
  BEFORE_LAST_WORK_DAY = 'BEFORE_LAST_WORK_DAY',
}

export enum ETimeEEligibilityStart {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum ETimeEEligibilityEnd {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
}

export enum EBenefitCost {
  ENTERED_MANUALLY = 'ENTERED_MANUALLY',
  FIXED_COST = 'FIXED_COST',
}

export enum EBenefitCostIncur {
  ONCE = 'ONCE',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum ETypeCurrency {
  VND = 'VND',
  USD = 'USD',
  CNY = 'CNY',
  PERCENT = 'PERCENT',
}

export enum EDayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum EAssetStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  BORROWED = 'BORROWED',
}

export enum ERelationship {
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  SPOUSE = 'SPOUSE',
  SIBLING = 'SIBLING',
  FRIEND = 'FRIEND',
  OTHER = 'OTHER',

  FATHER_IN_LAW = 'FATHER_IN_LAW',
  MOTHER_IN_LAW = 'MOTHER_IN_LAW',
  CHILDREN = 'CHILDREN',
  HUSBAND = 'HUSBAND',
  WIFE = 'WIFE',
  GRAND_MOTHER = 'GRAND_MOTHER',
  GRAND_FATHER = 'GRAND_FATHER',
}

export enum ETaskType {
  NONE = 'NONE',
  ON_THIS_DATE = 'ON_THIS_DATE',
  CUSTOM_DATE = 'CUSTOM_DATE',
  SPECIFIC_DATE = 'SPECIFIC_DATE',
}

export enum ETaskTimeCustomDate {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export enum ETaskTypeCustomDate {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  MONTHS = 'MONTHS',
}

export enum ETaskAssignedType {
  UNASSIGN = 'UNASSIGN',
  ASSIGNOR = 'ASSIGNOR',
  HR_IN_CHARGE = 'HR_IN_CHARGE',
  LINE_MANAGER = 'LINE_MANAGER',
  SPECIFIC = 'SPECIFIC',
}

export enum ECareerHistory {
  ON_BOARD = 'ON_BOARD',
  OFFF_BOARD = 'OFFF_BOARD',
  JOB = 'JOB',
  EMPLOY_TYPE = 'EMPLOY_TYPE',
  BRANCH = 'BRANCH',
  JOIN_DATE = 'JOIN_DATE',
  MANAGER = 'MANAGER',
  PROBATION_START = 'PROBATION_START',
  PROBATION_END = 'PROBATION_END',
  CONTRACT_START = 'CONTRACT_START',
  CONTRACT_END = 'CONTRACT_END',
}

export enum ETypeRequest {
  ALL_REQUESTS = 'ALL_REQUESTS',
  INFO_UPDATE_REQUESTS = 'INFO_UPDATE_REQUESTS',
  TIME_OFF_REQUESTS = 'TIME_OFF_REQUESTS',
  ON_BOARD_REQUESTS = 'ON_BOARD_REQUESTS',
  OFF_BOARD_REQUESTS = 'OFF_BOARD_REQUESTS',
  RESIGN_REQUESTS = 'RESIGN_REQUESTS',
  RESIGN_REQUESTS_RESIGN = 'RESIGN',
  RESIGN_REQUESTS_CONTRACT_TERMINATION = 'CONTRACT_TERMINATION',
  RESIGN_REQUESTS_PROBATION_TERMINATION = 'PROBATION_TERMINATION',
  ASSETS_REQUESTS = 'ASSETS_REQUESTS',
  ASSETS_REQUESTS_BORROW = 'BORROW',
  ASSETS_REQUESTS_RETURN = 'RETURN',
}

export enum ERequestStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECT = 'REJECT',
  CANCELED = 'CANCELED',
  INVITED = 'INVITED',
  PROCESS = 'PROCESS',
  NEED_REVIEW = 'NEED_REVIEW',
  COMPLETED = 'COMPLETED',
}

export enum ETaskStatus {
  OPEN = 'OPEN',
  PROCESS = 'PROCESS',
  DONE = 'DONE',
}

export enum EAccrualType {
  AUTO = 'AUTO',
  CUSTOM = 'CUSTOM',
}

export enum EEveryOrAfterTimeType {
  YEARS = 'YEARS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
  DAYS = 'DAYS',
}

export enum EAccrueType {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  ANNUALLY = 'ANNUALLY',
}

export enum EStartOn {
  '1ST' = '1ST',
  '2ND' = '2ND',
  '3RD' = '3RD',
  '4TH' = '4TH',
  '5TH' = '5TH',
  '6TH' = '6TH',
  '7TH' = '7TH',
  '8TH' = '8TH',
  '9TH' = '9TH',
  '10TH' = '10TH',
  '11TH' = '11TH',
  '12TH' = '12TH',
  '13TH' = '13TH',
  '14TH' = '14TH',
  '15TH' = '15TH',
  '16TH' = '16TH',
  '17TH' = '17TH',
  '18TH' = '18TH',
  '19TH' = '19TH',
  '20TH' = '20TH',
  '21ST' = '21ST',
  '22ND' = '22ND',
  '23RD' = '23RD',
  '24TH' = '24TH',
  '25TH' = '25TH',
  '26TH' = '26TH',
  '27TH' = '27TH',
  '28TH' = '28TH',
  '29TH' = '29TH',
  '30TH' = '30TH',
  '31ST' = '31ST',
  LAST_DAY = 'LAST_DAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}

export enum EEndOn {
  NEVER = 'NEVER',
  SPECIFIC_DATE = 'SPECIFIC_DATE',
}

export enum ERole {
  SADMIN = 'SADMIN',
  HR = 'HR',
  EMPLOYEE = 'EMPLOYEE',
}

export enum ETypeRequestManage {
  MY_REQ = 'MY_REQ',
  ASSIGN_REQ = 'ASSIGN_REQ',
  CC_REQ = 'CC_REQ',
}

export enum EAssignDate {
  UPON_JOIN_DATE = 'UPON_JOIN_DATE',
  AFTER_PROBATION_END_DATE = 'AFTER_PROBATION_END_DATE',
}

export enum ETypesNotifications {
  SYSTEM_WELCOME = 'SYSTEM_WELCOME',
  WELCOME_NOTE = 'WELCOME_NOTE',
  SUBMIT_REQUEST = 'SUBMIT_REQUEST',
  APPROVE_REQUEST = 'APPROVE_REQUEST',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  ASSIGN_CHECKLIST = 'ASSIGN_CHECKLIST',
  CHANGE_TYPE_WORK_TIME = 'CHANGE_TYPE_WORK_TIME',
  CHANGE_USER_STATUS = 'CHANGE_USER_STATUS',
  UPDATE_INFO = 'UPDATE_INFO',
}

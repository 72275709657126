import { createActionCreator } from 'deox';

import {
  TDeleteTaskCategoryMaterials,
  TDeleteTaskCategoryResponse,
} from '@/services/api/template/delete-task-category';

// CONSTANTS

export enum EDeleteTaskCategoryAction {
  DELETE_TASK_CATEGORY = 'DELETE_TASK_CATEGORY',
  DELETE_TASK_CATEGORY_REQUEST = 'DELETE_TASK_CATEGORY_REQUEST',
  DELETE_TASK_CATEGORY_SUCCESS = 'DELETE_TASK_CATEGORY_SUCCESS',
  DELETE_TASK_CATEGORY_FAILED = 'DELETE_TASK_CATEGORY_FAILED',
}

// TYPES

export type TDeleteTaskCategoryRequest = {
  type: EDeleteTaskCategoryAction.DELETE_TASK_CATEGORY_REQUEST;
  payload: {
    materials: TDeleteTaskCategoryMaterials;
    successCallback?: (response: TDeleteTaskCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteTaskCategorySuccess = {
  type: EDeleteTaskCategoryAction.DELETE_TASK_CATEGORY_SUCCESS;
  payload: { response: TDeleteTaskCategoryResponse };
};

export type TDeleteTaskCategoryFailed = { type: EDeleteTaskCategoryAction.DELETE_TASK_CATEGORY_FAILED };

// FUNCTION

export const deleteTaskCategoryAction = {
  request: createActionCreator(
    EDeleteTaskCategoryAction.DELETE_TASK_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TDeleteTaskCategoryMaterials,
        successCallback?: (response: TDeleteTaskCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteTaskCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteTaskCategoryAction.DELETE_TASK_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TDeleteTaskCategoryResponse): TDeleteTaskCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteTaskCategoryAction.DELETE_TASK_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TDeleteTaskCategoryFailed =>
        resolve({ error }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_600 }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5625 18.75V5.25C21.5625 3.98887 20.5736 3 19.3125 3C18.0514 3 17.0625 3.98887 17.0625 5.25V18.75L19.3125 21L21.5625 18.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.0625 7.5H21.5625" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19.3125 21H4.6875C4.09076 21 3.51847 20.7629 3.09651 20.341C2.67455 19.919 2.4375 19.3467 2.4375 18.75C2.4375 18.1533 2.67455 17.581 3.09651 17.159C3.51847 16.7371 4.09076 16.5 4.6875 16.5H9.1875C9.78424 16.5 10.3565 16.2629 10.7785 15.841C11.2004 15.419 11.4375 14.8467 11.4375 14.25C11.4375 13.6533 11.2004 13.081 10.7785 12.659C10.3565 12.2371 9.78424 12 9.1875 12H5.8125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

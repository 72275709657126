import { TTimeOffDays } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetCompanyTimeOffsParams = unknown;

export type TGetCompanyTimeOffsMaterials = {
  params?: TGetCompanyTimeOffsParams;
};

export type TGetCompanyTimeOffsResponse = {
  offToday: TTimeOffDays[];
  offTomorrow: TTimeOffDays[];
};

// FUNCTION

export const getCompanyTimeOffs = async ({
  params,
}: TGetCompanyTimeOffsMaterials): Promise<TGetCompanyTimeOffsResponse> => {
  const response = await ApiService.get(`/dashboard/company-time-off`, { params });
  return response.data;
};

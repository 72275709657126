import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportOffboardAction } from '@/redux/actions';
import { getReportOffboard, TGetReportOffboardResponse } from '@/services/api';

// FUNCTION

export function* getReportOffboardSaga(action: ActionType<typeof getReportOffboardAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportOffboard, materials);
    const getReportOffboardResponse: TGetReportOffboardResponse = response as TGetReportOffboardResponse;
    yield put(getReportOffboardAction.success(getReportOffboardResponse));
    successCallback?.(getReportOffboardResponse);
  } catch (err) {
    yield put(getReportOffboardAction.failure(err));
    failedCallback?.(err);
  }
}

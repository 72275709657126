import { TDepartment } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetDepartmentPaths = {
  id: string | number;
};
export type TGetDepartmentParams = unknown;

export type TGetDepartmentMaterials = {
  paths?: TGetDepartmentPaths;
  params?: TGetDepartmentParams;
};

export type TGetDepartmentResponse = TDepartment;

// FUNCTION

export const getDepartment = async ({ paths, params }: TGetDepartmentMaterials): Promise<TGetDepartmentResponse> => {
  const response = await ApiService.get(`/department/${paths?.id}`, { params });
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V5.5C1.5 6.05228 1.94772 6.5 2.5 6.5H5.5C6.05228 6.5 6.5 6.05228 6.5 5.5V2.5C6.5 1.94772 6.05228 1.5 5.5 1.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M13.5 1.5H10.5C9.94772 1.5 9.5 1.94772 9.5 2.5V5.5C9.5 6.05228 9.94772 6.5 10.5 6.5H13.5C14.0523 6.5 14.5 6.05228 14.5 5.5V2.5C14.5 1.94772 14.0523 1.5 13.5 1.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M5.5 9.5H2.5C1.94772 9.5 1.5 9.94772 1.5 10.5V13.5C1.5 14.0523 1.94772 14.5 2.5 14.5H5.5C6.05228 14.5 6.5 14.0523 6.5 13.5V10.5C6.5 9.94772 6.05228 9.5 5.5 9.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M13.5 9.5H10.5C9.94772 9.5 9.5 9.94772 9.5 10.5V13.5C9.5 14.0523 9.94772 14.5 10.5 14.5H13.5C14.0523 14.5 14.5 14.0523 14.5 13.5V10.5C14.5 9.94772 14.0523 9.5 13.5 9.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default Svg;

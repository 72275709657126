import { createReducer } from 'deox';

import {
  TAddUserRolesResponse,
  TAdjustTimeOffBlanceResponse,
  TChangeUserTypeStatusResponse,
  TCreateUserResponse,
  TDeleteUserResponse,
  TDownloadPeopleListResponse,
  TDownloadTemplateUsersResponse,
  TGetBalanceHistoriesResponse,
  TGetMyCareerHistoriesResponse,
  TGetMyInfoResponse,
  TGetMyTimeOffTypesResponse,
  TGetNewMembersResponse,
  TGetTimeOffHistoriesResponse,
  TGetUserCareerHistoriesResponse,
  TGetUserTimeOffTypesResponse,
  TGetUserResponse,
  TGetUsersResponse,
  TImportUsersResponse,
  TOffboardingUserResponse,
  TOnboardingUserFlowResponse,
  TRehireUserResponse,
  TReinviteUserResponse,
  TRevokeAccessUserResponse,
  TUpdateMyCareerResponse,
  TUpdateMyInfoResponse,
  TUpdateUserCareerResponse,
  TUpdateUserResponse,
  TWelcomeUserResponse,
} from '@/services/api/user';
import {
  addUserRolesAction,
  adjustTimeOffBlanceAction,
  changeUserTypeStatusAction,
  createUserAction,
  deleteUserAction,
  downloadPeopleListAction,
  downloadTemplateUsersAction,
  getBalanceHistoriesAction,
  getMyCareerHistoriesAction,
  getMyInfoAction,
  getMyTimeOffTypesAction,
  getNewMembersAction,
  getTimeOffHistoriesAction,
  getUserCareerHistoriesAction,
  getUserTimeOffTypesAction,
  getUserAction,
  getUsersAction,
  importUsersAction,
  offboardingUserAction,
  onboardingUserFlowAction,
  rehireUserAction,
  reinviteUserAction,
  revokeAccessUserAction,
  updateMyCareerAction,
  updateMyInfoAction,
  updateUserCareerAction,
  updateUserAction,
  welcomeUserAction,
} from '@/redux/actions';
import { addUserRolesUpdateState } from './add-user-roles';
import { adjustTimeOffBlanceUpdateState } from './adjust-time-off-blance';
import { changeUserTypeStatusUpdateState } from './change-user-type-status';
import { createUserUpdateState } from './create-user';
import { deleteUserUpdateState } from './delete-user';
import { downloadPeopleListUpdateState } from './download-people-list';
import { downloadTemplateUsersUpdateState } from './download-template-users';
import { getBalanceHistoriesUpdateState } from './get-balance-histories';
import { getMyCareerHistoriesUpdateState } from './get-my-career-histories';
import { getMyInfoUpdateState } from './get-my-info';
import { getMyTimeOffTypesUpdateState } from './get-my-time-off-types';
import { getNewMembersUpdateState } from './get-new-members';
import { getTimeOffHistoriesUpdateState } from './get-time-off-histories';
import { getUserCareerHistoriesUpdateState } from './get-user-career-histories';
import { getUserTimeOffTypesUpdateState } from './get-user-time-off-types';
import { getUserUpdateState } from './get-user';
import { getUsersUpdateState } from './get-users';
import { importUsersUpdateState } from './import-users';
import { offboardingUserUpdateState } from './offboarding-user';
import { onboardingUserFlowUpdateState } from './onboarding-user-flow';
import { rehireUserUpdateState } from './rehire-user';
import { reinviteUserUpdateState } from './reinvite-user';
import { revokeAccessUserUpdateState } from './revoke-access-user';
import { updateMyCareerUpdateState } from './update-my-career';
import { updateMyInfoUpdateState } from './update-my-info';
import { updateUserCareerUpdateState } from './update-user-career';
import { updateUserUpdateState } from './update-user';
import { welcomeUserUpdateState } from './welcome-user';

export type TUserState = {
  addUserRolesResponse?: TAddUserRolesResponse;
  adjustTimeOffBlanceResponse?: TAdjustTimeOffBlanceResponse;
  changeUserTypeStatusResponse?: TChangeUserTypeStatusResponse;
  createUserResponse?: TCreateUserResponse;
  deleteUserResponse?: TDeleteUserResponse;
  downloadPeopleListResponse?: TDownloadPeopleListResponse;
  downloadTemplateUsersResponse?: TDownloadTemplateUsersResponse;
  getBalanceHistoriesResponse?: TGetBalanceHistoriesResponse;
  getMyCareerHistoriesResponse?: TGetMyCareerHistoriesResponse;
  getMyInfoResponse?: TGetMyInfoResponse;
  getMyTimeOffTypesResponse?: TGetMyTimeOffTypesResponse;
  getNewMembersResponse?: TGetNewMembersResponse;
  getTimeOffHistoriesResponse?: TGetTimeOffHistoriesResponse;
  getUserCareerHistoriesResponse?: TGetUserCareerHistoriesResponse;
  getUserTimeOffTypesResponse?: TGetUserTimeOffTypesResponse;
  getUserResponse?: TGetUserResponse;
  getUsersResponse?: TGetUsersResponse;
  importUsersResponse?: TImportUsersResponse;
  offboardingUserResponse?: TOffboardingUserResponse;
  onboardingUserFlowResponse?: TOnboardingUserFlowResponse;
  rehireUserResponse?: TRehireUserResponse;
  reinviteUserResponse?: TReinviteUserResponse;
  revokeAccessUserResponse?: TRevokeAccessUserResponse;
  updateMyCareerResponse?: TUpdateMyCareerResponse;
  updateMyInfoResponse?: TUpdateMyInfoResponse;
  updateUserCareerResponse?: TUpdateUserCareerResponse;
  updateUserResponse?: TUpdateUserResponse;
  welcomeUserResponse?: TWelcomeUserResponse;
};

const initialState: TUserState = {
  addUserRolesResponse: undefined,
  adjustTimeOffBlanceResponse: undefined,
  changeUserTypeStatusResponse: undefined,
  createUserResponse: undefined,
  deleteUserResponse: undefined,
  downloadPeopleListResponse: undefined,
  downloadTemplateUsersResponse: undefined,
  getBalanceHistoriesResponse: undefined,
  getMyCareerHistoriesResponse: undefined,
  getMyInfoResponse: undefined,
  getMyTimeOffTypesResponse: undefined,
  getNewMembersResponse: undefined,
  getTimeOffHistoriesResponse: undefined,
  getUserCareerHistoriesResponse: undefined,
  getUserTimeOffTypesResponse: undefined,
  getUserResponse: undefined,
  getUsersResponse: undefined,
  importUsersResponse: undefined,
  offboardingUserResponse: undefined,
  onboardingUserFlowResponse: undefined,
  rehireUserResponse: undefined,
  reinviteUserResponse: undefined,
  revokeAccessUserResponse: undefined,
  updateMyCareerResponse: undefined,
  updateMyInfoResponse: undefined,
  updateUserCareerResponse: undefined,
  updateUserResponse: undefined,
  welcomeUserResponse: undefined,
};

const UserReducer = createReducer(initialState, (handleAction) => [
  handleAction(addUserRolesAction.success, addUserRolesUpdateState),
  handleAction(adjustTimeOffBlanceAction.success, adjustTimeOffBlanceUpdateState),
  handleAction(changeUserTypeStatusAction.success, changeUserTypeStatusUpdateState),
  handleAction(createUserAction.success, createUserUpdateState),
  handleAction(deleteUserAction.success, deleteUserUpdateState),
  handleAction(downloadPeopleListAction.success, downloadPeopleListUpdateState),
  handleAction(downloadTemplateUsersAction.success, downloadTemplateUsersUpdateState),
  handleAction(getBalanceHistoriesAction.success, getBalanceHistoriesUpdateState),
  handleAction(getMyCareerHistoriesAction.success, getMyCareerHistoriesUpdateState),
  handleAction(getMyInfoAction.success, getMyInfoUpdateState),
  handleAction(getMyTimeOffTypesAction.success, getMyTimeOffTypesUpdateState),
  handleAction(getNewMembersAction.success, getNewMembersUpdateState),
  handleAction(getTimeOffHistoriesAction.success, getTimeOffHistoriesUpdateState),
  handleAction(getUserCareerHistoriesAction.success, getUserCareerHistoriesUpdateState),
  handleAction(getUserTimeOffTypesAction.success, getUserTimeOffTypesUpdateState),
  handleAction(getUserAction.success, getUserUpdateState),
  handleAction(getUsersAction.success, getUsersUpdateState),
  handleAction(importUsersAction.success, importUsersUpdateState),
  handleAction(offboardingUserAction.success, offboardingUserUpdateState),
  handleAction(onboardingUserFlowAction.success, onboardingUserFlowUpdateState),
  handleAction(rehireUserAction.success, rehireUserUpdateState),
  handleAction(reinviteUserAction.success, reinviteUserUpdateState),
  handleAction(revokeAccessUserAction.success, revokeAccessUserUpdateState),
  handleAction(updateMyCareerAction.success, updateMyCareerUpdateState),
  handleAction(updateMyInfoAction.success, updateMyInfoUpdateState),
  handleAction(updateUserCareerAction.success, updateUserCareerUpdateState),
  handleAction(updateUserAction.success, updateUserUpdateState),
  handleAction(welcomeUserAction.success, welcomeUserUpdateState),
]);

export default UserReducer;

import ApiService from '@/services/api';

// TYPES

export type TOffboardingUserParams = unknown;
export type TOffboardingUserBody = unknown;

export type TOffboardingUserMaterials = {
  params?: TOffboardingUserParams;
  body?: TOffboardingUserBody;
};

export type TOffboardingUserResponse = unknown;

// FUNCTION

export const offboardingUser = async ({
  params,
  body,
}: TOffboardingUserMaterials): Promise<TOffboardingUserResponse> => {
  const response = await ApiService.post(`/user/off-board`, body, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TGetBalanceHistoriesMaterials, TGetBalanceHistoriesResponse } from '@/services/api/user/get-balance-histories';

// CONSTANTS

export enum EGetBalanceHistoriesAction {
  GET_BALANCE_HISTORIES = 'GET_BALANCE_HISTORIES',
  GET_BALANCE_HISTORIES_REQUEST = 'GET_BALANCE_HISTORIES_REQUEST',
  GET_BALANCE_HISTORIES_SUCCESS = 'GET_BALANCE_HISTORIES_SUCCESS',
  GET_BALANCE_HISTORIES_FAILED = 'GET_BALANCE_HISTORIES_FAILED',
}

// TYPES

export type TGetBalanceHistoriesRequest = {
  type: EGetBalanceHistoriesAction.GET_BALANCE_HISTORIES_REQUEST;
  payload: {
    materials: TGetBalanceHistoriesMaterials;
    successCallback?: (response: TGetBalanceHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBalanceHistoriesSuccess = {
  type: EGetBalanceHistoriesAction.GET_BALANCE_HISTORIES_SUCCESS;
  payload: { response: TGetBalanceHistoriesResponse };
};

export type TGetBalanceHistoriesFailed = { type: EGetBalanceHistoriesAction.GET_BALANCE_HISTORIES_FAILED };

// FUNCTION

export const getBalanceHistoriesAction = {
  request: createActionCreator(
    EGetBalanceHistoriesAction.GET_BALANCE_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetBalanceHistoriesMaterials,
        successCallback?: (response: TGetBalanceHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBalanceHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetBalanceHistoriesAction.GET_BALANCE_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetBalanceHistoriesResponse): TGetBalanceHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetBalanceHistoriesAction.GET_BALANCE_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetBalanceHistoriesFailed =>
        resolve({ error }),
  ),
};

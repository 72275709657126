import { TAssetCategory } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetAssetCategoryPaths = {
  id: string | number;
};
export type TGetAssetCategoryParams = unknown;

export type TGetAssetCategoryMaterials = {
  paths?: TGetAssetCategoryPaths;
  params?: TGetAssetCategoryParams;
};

export type TGetAssetCategoryResponse = TAssetCategory;

// FUNCTION

export const getAssetCategory = async ({
  paths,
  params,
}: TGetAssetCategoryMaterials): Promise<TGetAssetCategoryResponse> => {
  const response = await ApiService.get(`/asset/category/${paths?.id}`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createJobAction } from '@/redux/actions';
import { createJob, TCreateJobResponse } from '@/services/api';

// FUNCTION

export function* createJobSaga(action: ActionType<typeof createJobAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createJob, materials);
    const createJobResponse: TCreateJobResponse = response as TCreateJobResponse;
    yield put(createJobAction.success(createJobResponse));
    successCallback?.(createJobResponse);
  } catch (err) {
    yield put(createJobAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getBenefitCoveragesAction } from '@/redux/actions';
import { getBenefitCoverages, TGetBenefitCoveragesResponse } from '@/services/api';

// FUNCTION

export function* getBenefitCoveragesSaga(action: ActionType<typeof getBenefitCoveragesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getBenefitCoverages, materials);
    const getBenefitCoveragesResponse: TGetBenefitCoveragesResponse = response as TGetBenefitCoveragesResponse;
    yield put(getBenefitCoveragesAction.success(getBenefitCoveragesResponse));
    successCallback?.(getBenefitCoveragesResponse);
  } catch (err) {
    yield put(getBenefitCoveragesAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteDepartmentAction } from '@/redux/actions';
import { deleteDepartment, TDeleteDepartmentResponse } from '@/services/api';

// FUNCTION

export function* deleteDepartmentSaga(action: ActionType<typeof deleteDepartmentAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteDepartment, materials);
    const deleteDepartmentResponse: TDeleteDepartmentResponse = response as TDeleteDepartmentResponse;
    yield put(deleteDepartmentAction.success(deleteDepartmentResponse));
    successCallback?.(deleteDepartmentResponse);
  } catch (err) {
    yield put(deleteDepartmentAction.failure(err));
    failedCallback?.(err);
  }
}

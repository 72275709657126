import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getNewMembersAction } from '@/redux/actions';
import { getNewMembers, TGetNewMembersResponse } from '@/services/api';

// FUNCTION

export function* getNewMembersSaga(action: ActionType<typeof getNewMembersAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getNewMembers, materials);
    const getNewMembersResponse: TGetNewMembersResponse = response as TGetNewMembersResponse;
    yield put(getNewMembersAction.success(getNewMembersResponse));
    successCallback?.(getNewMembersResponse);
  } catch (err) {
    yield put(getNewMembersAction.failure(err));
    failedCallback?.(err);
  }
}

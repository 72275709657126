import ApiService from '@/services/api';

// TYPES

export type TUpdateRegulationPaths = {
  id: string | number;
};
export type TUpdateRegulationBody = unknown;

export type TUpdateRegulationMaterials = {
  paths?: TUpdateRegulationPaths;
  body?: TUpdateRegulationBody;
};

export type TUpdateRegulationResponse = unknown;

// FUNCTION

export const updateRegulation = async ({
  paths,
  body,
}: TUpdateRegulationMaterials): Promise<TUpdateRegulationResponse> => {
  const response = await ApiService.patch(`/regulation/${paths?.id}`, body);
  return response.data;
};

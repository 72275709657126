import ApiService from '@/services/api';

// TYPES

export type TLoginIdPortalParams = unknown;
export type TLoginIdPortalBody = unknown;

export type TLoginIdPortalMaterials = {
  params?: TLoginIdPortalParams;
  body?: TLoginIdPortalBody;
};

export type TLoginIdPortalResponse = {
  accessToken: string;
};

// FUNCTION

export const loginIdPortal = async ({ params, body }: TLoginIdPortalMaterials): Promise<TLoginIdPortalResponse> => {
  const response = await ApiService.post(`/auth/token`, body, { params });
  return response.data;
};

import { TRequestState } from '@/redux/reducers/request';
import { TContractTerminationRequestSuccess } from '@/redux/actions/request';

export const contractTerminationRequestUpdateState = (
  state: TRequestState,
  action: TContractTerminationRequestSuccess,
): TRequestState => ({
  ...state,
  contractTerminationRequestResponse: action.payload.response,
});

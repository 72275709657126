import React from 'react';
import classNames from 'classnames';

import { TStatusProps } from './Status.types.d';
import './Status.scss';

const Status: React.FC<TStatusProps> = ({ title, type, size, onClick }) => {
  return (
    <div className={classNames('Status', type, size, { 'have-action': Boolean(onClick) })} onClick={onClick}>
      <span>{title}</span>
    </div>
  );
};

export default Status;

import { createActionCreator } from 'deox';

import {
  TCreateReportViolationMaterials,
  TCreateReportViolationResponse,
} from '@/services/api/report-violation/create-report-violation';

// CONSTANTS

export enum ECreateReportViolationAction {
  CREATE_REPORT_VIOLATION = 'CREATE_REPORT_VIOLATION',
  CREATE_REPORT_VIOLATION_REQUEST = 'CREATE_REPORT_VIOLATION_REQUEST',
  CREATE_REPORT_VIOLATION_SUCCESS = 'CREATE_REPORT_VIOLATION_SUCCESS',
  CREATE_REPORT_VIOLATION_FAILED = 'CREATE_REPORT_VIOLATION_FAILED',
}

// TYPES

export type TCreateReportViolationRequest = {
  type: ECreateReportViolationAction.CREATE_REPORT_VIOLATION_REQUEST;
  payload: {
    materials: TCreateReportViolationMaterials;
    successCallback?: (response: TCreateReportViolationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateReportViolationSuccess = {
  type: ECreateReportViolationAction.CREATE_REPORT_VIOLATION_SUCCESS;
  payload: { response: TCreateReportViolationResponse };
};

export type TCreateReportViolationFailed = { type: ECreateReportViolationAction.CREATE_REPORT_VIOLATION_FAILED };

// FUNCTION

export const createReportViolationAction = {
  request: createActionCreator(
    ECreateReportViolationAction.CREATE_REPORT_VIOLATION_REQUEST,
    (resolve) =>
      (
        materials: TCreateReportViolationMaterials,
        successCallback?: (response: TCreateReportViolationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateReportViolationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateReportViolationAction.CREATE_REPORT_VIOLATION_SUCCESS,
    (resolve) =>
      (response: TCreateReportViolationResponse): TCreateReportViolationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateReportViolationAction.CREATE_REPORT_VIOLATION_FAILED,
    (resolve) =>
      (error: unknown): TCreateReportViolationFailed =>
        resolve({ error }),
  ),
};

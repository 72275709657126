import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { signInAction } from '@/redux/actions';
import { signIn, TSignInResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* signInSaga(action: ActionType<typeof signInAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(signIn, materials);
    const signInResponse: TSignInResponse = response as TSignInResponse;

    Helpers.storeAccessToken(signInResponse.accessToken);
    Helpers.storeRefreshToken('');

    yield put(signInAction.success(signInResponse));
    successCallback?.(signInResponse);
  } catch (err) {
    yield put(signInAction.failure(err));
    failedCallback?.(err);
  }
}

import { TTask } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTaskPaths = {
  id: string | number;
};
export type TGetTaskParams = unknown;

export type TGetTaskMaterials = {
  paths?: TGetTaskPaths;
  params?: TGetTaskParams;
};

export type TGetTaskResponse = TTask;

// FUNCTION

export const getTask = async ({ paths, params }: TGetTaskMaterials): Promise<TGetTaskResponse> => {
  const response = await ApiService.get(`/template/task/${paths?.id}`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TCreateBenefitMaterials, TCreateBenefitResponse } from '@/services/api/benefit/create-benefit';

// CONSTANTS

export enum ECreateBenefitAction {
  CREATE_BENEFIT = 'CREATE_BENEFIT',
  CREATE_BENEFIT_REQUEST = 'CREATE_BENEFIT_REQUEST',
  CREATE_BENEFIT_SUCCESS = 'CREATE_BENEFIT_SUCCESS',
  CREATE_BENEFIT_FAILED = 'CREATE_BENEFIT_FAILED',
}

// TYPES

export type TCreateBenefitRequest = {
  type: ECreateBenefitAction.CREATE_BENEFIT_REQUEST;
  payload: {
    materials: TCreateBenefitMaterials;
    successCallback?: (response: TCreateBenefitResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateBenefitSuccess = {
  type: ECreateBenefitAction.CREATE_BENEFIT_SUCCESS;
  payload: { response: TCreateBenefitResponse };
};

export type TCreateBenefitFailed = { type: ECreateBenefitAction.CREATE_BENEFIT_FAILED };

// FUNCTION

export const createBenefitAction = {
  request: createActionCreator(
    ECreateBenefitAction.CREATE_BENEFIT_REQUEST,
    (resolve) =>
      (
        materials: TCreateBenefitMaterials,
        successCallback?: (response: TCreateBenefitResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateBenefitRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateBenefitAction.CREATE_BENEFIT_SUCCESS,
    (resolve) =>
      (response: TCreateBenefitResponse): TCreateBenefitSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateBenefitAction.CREATE_BENEFIT_FAILED,
    (resolve) =>
      (error: unknown): TCreateBenefitFailed =>
        resolve({ error }),
  ),
};

import { THolidayState } from '@/redux/reducers/holiday';
import { TCopyLastYearHolidaysSuccess } from '@/redux/actions/holiday';

export const copyLastYearHolidaysUpdateState = (
  state: THolidayState,
  action: TCopyLastYearHolidaysSuccess,
): THolidayState => ({
  ...state,
  copyLastYearHolidaysResponse: action.payload.response,
});

import ApiService from '@/services/api';

// TYPES

export type TCreateRoleParams = unknown;
export type TCreateRoleBody = unknown;

export type TCreateRoleMaterials = {
  params?: TCreateRoleParams;
  body?: TCreateRoleBody;
};

export type TCreateRoleResponse = unknown;

// FUNCTION

export const createRole = async ({ params, body }: TCreateRoleMaterials): Promise<TCreateRoleResponse> => {
  const response = await ApiService.post(`/role`, body, { params });
  return response.data;
};

import { all, takeLatest } from 'redux-saga/effects';

import {
  createTypeWorkAction,
  deleteTypeWorkAction,
  getTypeWorkAction,
  getTypesWorkAction,
  updateTypeWorkAction,
} from '@/redux/actions';

import { createTypeWorkSaga } from './create-type-work';
import { deleteTypeWorkSaga } from './delete-type-work';
import { getTypeWorkSaga } from './get-type-work';
import { getTypesWorkSaga } from './get-types-work';
import { updateTypeWorkSaga } from './update-type-work';

export default function* root(): Generator {
  yield all([
    takeLatest(createTypeWorkAction.request.type, createTypeWorkSaga),
    takeLatest(deleteTypeWorkAction.request.type, deleteTypeWorkSaga),
    takeLatest(getTypeWorkAction.request.type, getTypeWorkSaga),
    takeLatest(getTypesWorkAction.request.type, getTypesWorkSaga),
    takeLatest(updateTypeWorkAction.request.type, updateTypeWorkSaga),
  ]);
}

import React from 'react';
import classNames from 'classnames';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { TCheckboxProps } from '@/components/Checkbox/Checkbox.types';
import FormLabel from '@/components/FormLabel';
import { ECheckboxStyleType } from '@/components/Checkbox/Checkbox.enums';

import './Checkbox.scss';

const Checkbox: React.FC<TCheckboxProps> = ({
  className,
  title,
  disabled,
  value,
  label,
  required,
  passed,
  failed,
  styleType = ECheckboxStyleType.PRIMARY,
  strikethough,
  readOnly,
  onChange,
}) => {
  const handleCheckboxChange = (e: CheckboxChangeEvent): void => {
    if (!readOnly) {
      const { checked } = e.target;
      onChange?.(checked);
    }
  };

  return (
    <div className={classNames('Checkbox', className, styleType, { strikethough })}>
      <FormLabel disabled={disabled} label={label} required={required} passed={passed} failed={failed} />

      <AntdCheckbox checked={value} onChange={handleCheckboxChange} disabled={disabled}>
        {title}
      </AntdCheckbox>
    </div>
  );
};

export default Checkbox;

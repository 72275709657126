import ApiService from '@/services/api';

// TYPES

export type TDeleteRequestPaths = {
  id: string | number;
};
export type TDeleteRequestParams = unknown;

export type TDeleteRequestMaterials = {
  paths?: TDeleteRequestPaths;
  params?: TDeleteRequestParams;
};

export type TDeleteRequestResponse = unknown;

// FUNCTION

export const deleteRequest = async ({ paths, params }: TDeleteRequestMaterials): Promise<TDeleteRequestResponse> => {
  const response = await ApiService.delete(`/request/${paths?.id}`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TCreateJobMaterials, TCreateJobResponse } from '@/services/api/job/create-job';

// CONSTANTS

export enum ECreateJobAction {
  CREATE_JOB = 'CREATE_JOB',
  CREATE_JOB_REQUEST = 'CREATE_JOB_REQUEST',
  CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS',
  CREATE_JOB_FAILED = 'CREATE_JOB_FAILED',
}

// TYPES

export type TCreateJobRequest = {
  type: ECreateJobAction.CREATE_JOB_REQUEST;
  payload: {
    materials: TCreateJobMaterials;
    successCallback?: (response: TCreateJobResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateJobSuccess = {
  type: ECreateJobAction.CREATE_JOB_SUCCESS;
  payload: { response: TCreateJobResponse };
};

export type TCreateJobFailed = { type: ECreateJobAction.CREATE_JOB_FAILED };

// FUNCTION

export const createJobAction = {
  request: createActionCreator(
    ECreateJobAction.CREATE_JOB_REQUEST,
    (resolve) =>
      (
        materials: TCreateJobMaterials,
        successCallback?: (response: TCreateJobResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateJobRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateJobAction.CREATE_JOB_SUCCESS,
    (resolve) =>
      (response: TCreateJobResponse): TCreateJobSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateJobAction.CREATE_JOB_FAILED,
    (resolve) =>
      (error: unknown): TCreateJobFailed =>
        resolve({ error }),
  ),
};

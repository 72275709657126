import { createReducer } from 'deox';

import { TMailFeedbackResponse } from '@/services/api/mail';
import { mailFeedbackAction } from '@/redux/actions';
import { mailFeedbackUpdateState } from './mail-feedback';

export type TMailState = {
  mailFeedbackResponse?: TMailFeedbackResponse;
};

const initialState: TMailState = {
  mailFeedbackResponse: undefined,
};

const MailReducer = createReducer(initialState, (handleAction) => [
  handleAction(mailFeedbackAction.success, mailFeedbackUpdateState),
]);

export default MailReducer;

import { createActionCreator } from 'deox';

import { TDeleteRoleMaterials, TDeleteRoleResponse } from '@/services/api/role/delete-role';

// CONSTANTS

export enum EDeleteRoleAction {
  DELETE_ROLE = 'DELETE_ROLE',
  DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED',
}

// TYPES

export type TDeleteRoleRequest = {
  type: EDeleteRoleAction.DELETE_ROLE_REQUEST;
  payload: {
    materials: TDeleteRoleMaterials;
    successCallback?: (response: TDeleteRoleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteRoleSuccess = {
  type: EDeleteRoleAction.DELETE_ROLE_SUCCESS;
  payload: { response: TDeleteRoleResponse };
};

export type TDeleteRoleFailed = { type: EDeleteRoleAction.DELETE_ROLE_FAILED };

// FUNCTION

export const deleteRoleAction = {
  request: createActionCreator(
    EDeleteRoleAction.DELETE_ROLE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteRoleMaterials,
        successCallback?: (response: TDeleteRoleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteRoleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteRoleAction.DELETE_ROLE_SUCCESS,
    (resolve) =>
      (response: TDeleteRoleResponse): TDeleteRoleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteRoleAction.DELETE_ROLE_FAILED,
    (resolve) =>
      (error: unknown): TDeleteRoleFailed =>
        resolve({ error }),
  ),
};

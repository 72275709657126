import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateHolidayAction } from '@/redux/actions';
import { updateHoliday, TUpdateHolidayResponse } from '@/services/api';

// FUNCTION

export function* updateHolidaySaga(action: ActionType<typeof updateHolidayAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateHoliday, materials);
    const updateHolidayResponse: TUpdateHolidayResponse = response as TUpdateHolidayResponse;
    yield put(updateHolidayAction.success(updateHolidayResponse));
    successCallback?.(updateHolidayResponse);
  } catch (err) {
    yield put(updateHolidayAction.failure(err));
    failedCallback?.(err);
  }
}

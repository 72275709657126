import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getMyTimeOffTypesAction } from '@/redux/actions';
import { getMyTimeOffTypes, TGetMyTimeOffTypesResponse } from '@/services/api';

// FUNCTION

export function* getMyTimeOffTypesSaga(action: ActionType<typeof getMyTimeOffTypesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getMyTimeOffTypes, materials);
    const getMyTimeOffTypesResponse: TGetMyTimeOffTypesResponse = response as TGetMyTimeOffTypesResponse;
    yield put(getMyTimeOffTypesAction.success(getMyTimeOffTypesResponse));
    successCallback?.(getMyTimeOffTypesResponse);
  } catch (err) {
    yield put(getMyTimeOffTypesAction.failure(err));
    failedCallback?.(err);
  }
}

import { all, takeLatest } from 'redux-saga/effects';

import { authLoginAction, loginIdPortalAction, signInAction, signupUserAction } from '@/redux/actions';

import { authLoginSaga } from './auth-login';
import { loginIdPortalSaga } from './login-id-portal';
import { signInSaga } from './sign-in';
import { signupUserSaga } from './signup-user';

export default function* root(): Generator {
  yield all([
    takeLatest(authLoginAction.request.type, authLoginSaga),
    takeLatest(loginIdPortalAction.request.type, loginIdPortalSaga),
    takeLatest(signInAction.request.type, signInSaga),
    takeLatest(signupUserAction.request.type, signupUserSaga),
  ]);
}

import { TApprover } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetApproversPaths = {
  typegroup: string | number;
};
export type TGetApproversParams = {
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  typegroup?: string;
};

export type TGetApproversMaterials = {
  paths?: TGetApproversPaths;
  params?: TGetApproversParams;
};

export type TGetApproversResponse = TApprover[];

// FUNCTION

export const getApprovers = async ({ paths, params }: TGetApproversMaterials): Promise<TGetApproversResponse> => {
  const response = await ApiService.get(`/approver/${paths?.typegroup}/list-member`, { params });
  return response.data;
};

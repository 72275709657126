import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRegulationsAction } from '@/redux/actions';
import { getRegulations, TGetRegulationsResponse } from '@/services/api';

// FUNCTION

export function* getRegulationsSaga(action: ActionType<typeof getRegulationsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRegulations, materials);
    const getRegulationsResponse: TGetRegulationsResponse = response as TGetRegulationsResponse;
    yield put(getRegulationsAction.success(getRegulationsResponse));
    successCallback?.(getRegulationsResponse);
  } catch (err) {
    yield put(getRegulationsAction.failure(err));
    failedCallback?.(err);
  }
}

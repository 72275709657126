import { createReducer } from 'deox';

import { TUploadFilesResponse } from '@/services/api/upload';
import { uploadFilesAction } from '@/redux/actions';
import { uploadFilesUpdateState } from './upload-files';

export type TUploadState = {
  uploadFilesResponse?: TUploadFilesResponse;
};

const initialState: TUploadState = {
  uploadFilesResponse: undefined,
};

const UploadReducer = createReducer(initialState, (handleAction) => [
  handleAction(uploadFilesAction.success, uploadFilesUpdateState),
]);

export default UploadReducer;

import ApiService from '@/services/api';

// TYPES

export type TCreateJobParams = unknown;
export type TCreateJobBody = unknown;

export type TCreateJobMaterials = {
  params?: TCreateJobParams;
  body?: TCreateJobBody;
};

export type TCreateJobResponse = unknown;

// FUNCTION

export const createJob = async ({ params, body }: TCreateJobMaterials): Promise<TCreateJobResponse> => {
  const response = await ApiService.post(`/job/new`, body, { params });
  return response.data;
};

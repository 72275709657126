import { TDepartment, THeadcountAndStaffTurnOver, TTypeWorkTime } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetDashboardReportParams = unknown;

export type TGetDashboardReportMaterials = {
  params?: TGetDashboardReportParams;
};

export type TGetDashboardReportResponse = {
  departments: {
    data: TDepartment[];
    total: { [key: string]: string };
    totalUsers: number;
  };
  employTypes: {
    data: TTypeWorkTime[];
    total: { [key: string]: string };
    totalUsers: number;
  };
  headcounts: THeadcountAndStaffTurnOver[];
  staffTurnover: THeadcountAndStaffTurnOver[];
  totalEmploys: number;
  growthEmploys: number;
  growth: number;
};

// FUNCTION

export const getDashboardReport = async ({
  params,
}: TGetDashboardReportMaterials): Promise<TGetDashboardReportResponse> => {
  const response = await ApiService.get(`/dashboard/report`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateJobAction } from '@/redux/actions';
import { updateJob, TUpdateJobResponse } from '@/services/api';

// FUNCTION

export function* updateJobSaga(action: ActionType<typeof updateJobAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateJob, materials);
    const updateJobResponse: TUpdateJobResponse = response as TUpdateJobResponse;
    yield put(updateJobAction.success(updateJobResponse));
    successCallback?.(updateJobResponse);
  } catch (err) {
    yield put(updateJobAction.failure(err));
    failedCallback?.(err);
  }
}

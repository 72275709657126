import { createActionCreator } from 'deox';

import { TAddRoleMemberMaterials, TAddRoleMemberResponse } from '@/services/api/role/add-role-member';

// CONSTANTS

export enum EAddRoleMemberAction {
  ADD_ROLE_MEMBER = 'ADD_ROLE_MEMBER',
  ADD_ROLE_MEMBER_REQUEST = 'ADD_ROLE_MEMBER_REQUEST',
  ADD_ROLE_MEMBER_SUCCESS = 'ADD_ROLE_MEMBER_SUCCESS',
  ADD_ROLE_MEMBER_FAILED = 'ADD_ROLE_MEMBER_FAILED',
}

// TYPES

export type TAddRoleMemberRequest = {
  type: EAddRoleMemberAction.ADD_ROLE_MEMBER_REQUEST;
  payload: {
    materials: TAddRoleMemberMaterials;
    successCallback?: (response: TAddRoleMemberResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAddRoleMemberSuccess = {
  type: EAddRoleMemberAction.ADD_ROLE_MEMBER_SUCCESS;
  payload: { response: TAddRoleMemberResponse };
};

export type TAddRoleMemberFailed = { type: EAddRoleMemberAction.ADD_ROLE_MEMBER_FAILED };

// FUNCTION

export const addRoleMemberAction = {
  request: createActionCreator(
    EAddRoleMemberAction.ADD_ROLE_MEMBER_REQUEST,
    (resolve) =>
      (
        materials: TAddRoleMemberMaterials,
        successCallback?: (response: TAddRoleMemberResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAddRoleMemberRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAddRoleMemberAction.ADD_ROLE_MEMBER_SUCCESS,
    (resolve) =>
      (response: TAddRoleMemberResponse): TAddRoleMemberSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAddRoleMemberAction.ADD_ROLE_MEMBER_FAILED,
    (resolve) =>
      (error: unknown): TAddRoleMemberFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TDeleteJobPaths = {
  id: string | number;
};
export type TDeleteJobParams = unknown;

export type TDeleteJobMaterials = {
  paths?: TDeleteJobPaths;
  params?: TDeleteJobParams;
};

export type TDeleteJobResponse = unknown;

// FUNCTION

export const deleteJob = async ({ paths, params }: TDeleteJobMaterials): Promise<TDeleteJobResponse> => {
  const response = await ApiService.delete(`/job/${paths?.id}`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TDownloadEmployeeTurnoverRateParams = unknown;
export type TDownloadEmployeeTurnoverRateBody = unknown;

export type TDownloadEmployeeTurnoverRateMaterials = {
  params?: TDownloadEmployeeTurnoverRateParams;
  body?: TDownloadEmployeeTurnoverRateBody;
};

export type TDownloadEmployeeTurnoverRateResponse = string;

// FUNCTION

export const downloadEmployeeTurnoverRate = async ({
  params,
  body,
}: TDownloadEmployeeTurnoverRateMaterials): Promise<TDownloadEmployeeTurnoverRateResponse> => {
  const response = await ApiService.post(`/report/employee-turnover-rate`, body, { params });
  return response.data;
};

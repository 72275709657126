import ApiService from '@/services/api';

// TYPES

export type TUpdateTaskPaths = {
  id: string | number;
};
export type TUpdateTaskBody = unknown;

export type TUpdateTaskMaterials = {
  paths?: TUpdateTaskPaths;
  body?: TUpdateTaskBody;
};

export type TUpdateTaskResponse = unknown;

// FUNCTION

export const updateTask = async ({ paths, body }: TUpdateTaskMaterials): Promise<TUpdateTaskResponse> => {
  const response = await ApiService.put(`/template/task/${paths?.id}`, body);
  return response.data;
};

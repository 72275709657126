import ApiService from '@/services/api';

// TYPES

export type TChangeStatusAssetPaths = {
  id: string | number;
};
export type TChangeStatusAssetBody = unknown;

export type TChangeStatusAssetMaterials = {
  paths?: TChangeStatusAssetPaths;
  body?: TChangeStatusAssetBody;
};

export type TChangeStatusAssetResponse = unknown;

// FUNCTION

export const changeStatusAsset = async ({
  paths,
  body,
}: TChangeStatusAssetMaterials): Promise<TChangeStatusAssetResponse> => {
  const response = await ApiService.patch(`/asset/${paths?.id}`, body);
  return response.data;
};

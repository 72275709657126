import { createActionCreator } from 'deox';

import { TGetRolesMaterials, TGetRolesResponse } from '@/services/api/role/get-roles';

// CONSTANTS

export enum EGetRolesAction {
  GET_ROLES = 'GET_ROLES',
  GET_ROLES_REQUEST = 'GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILED = 'GET_ROLES_FAILED',
}

// TYPES

export type TGetRolesRequest = {
  type: EGetRolesAction.GET_ROLES_REQUEST;
  payload: {
    materials: TGetRolesMaterials;
    successCallback?: (response: TGetRolesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRolesSuccess = {
  type: EGetRolesAction.GET_ROLES_SUCCESS;
  payload: { response: TGetRolesResponse };
};

export type TGetRolesFailed = { type: EGetRolesAction.GET_ROLES_FAILED };

// FUNCTION

export const getRolesAction = {
  request: createActionCreator(
    EGetRolesAction.GET_ROLES_REQUEST,
    (resolve) =>
      (
        materials: TGetRolesMaterials,
        successCallback?: (response: TGetRolesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRolesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRolesAction.GET_ROLES_SUCCESS,
    (resolve) =>
      (response: TGetRolesResponse): TGetRolesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRolesAction.GET_ROLES_FAILED,
    (resolve) =>
      (error: unknown): TGetRolesFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import {
  TGetMyCareerHistoriesMaterials,
  TGetMyCareerHistoriesResponse,
} from '@/services/api/user/get-my-career-histories';

// CONSTANTS

export enum EGetMyCareerHistoriesAction {
  GET_MY_CAREER_HISTORIES = 'GET_MY_CAREER_HISTORIES',
  GET_MY_CAREER_HISTORIES_REQUEST = 'GET_MY_CAREER_HISTORIES_REQUEST',
  GET_MY_CAREER_HISTORIES_SUCCESS = 'GET_MY_CAREER_HISTORIES_SUCCESS',
  GET_MY_CAREER_HISTORIES_FAILED = 'GET_MY_CAREER_HISTORIES_FAILED',
}

// TYPES

export type TGetMyCareerHistoriesRequest = {
  type: EGetMyCareerHistoriesAction.GET_MY_CAREER_HISTORIES_REQUEST;
  payload: {
    materials: TGetMyCareerHistoriesMaterials;
    successCallback?: (response: TGetMyCareerHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMyCareerHistoriesSuccess = {
  type: EGetMyCareerHistoriesAction.GET_MY_CAREER_HISTORIES_SUCCESS;
  payload: { response: TGetMyCareerHistoriesResponse };
};

export type TGetMyCareerHistoriesFailed = { type: EGetMyCareerHistoriesAction.GET_MY_CAREER_HISTORIES_FAILED };

// FUNCTION

export const getMyCareerHistoriesAction = {
  request: createActionCreator(
    EGetMyCareerHistoriesAction.GET_MY_CAREER_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetMyCareerHistoriesMaterials,
        successCallback?: (response: TGetMyCareerHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMyCareerHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMyCareerHistoriesAction.GET_MY_CAREER_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetMyCareerHistoriesResponse): TGetMyCareerHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMyCareerHistoriesAction.GET_MY_CAREER_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetMyCareerHistoriesFailed =>
        resolve({ error }),
  ),
};

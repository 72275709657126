import { TReportState } from '@/redux/reducers/report';
import { TDonwloadReportTimeOffScheduleSuccess } from '@/redux/actions/report';

export const donwloadReportTimeOffScheduleUpdateState = (
  state: TReportState,
  action: TDonwloadReportTimeOffScheduleSuccess,
): TReportState => ({
  ...state,
  donwloadReportTimeOffScheduleResponse: action.payload.response,
});

import ApiService from '@/services/api';

// TYPES

export type TRequestBorrowAssetParams = unknown;
export type TRequestBorrowAssetBody = unknown;

export type TRequestBorrowAssetMaterials = {
  params?: TRequestBorrowAssetParams;
  body?: TRequestBorrowAssetBody;
};

export type TRequestBorrowAssetResponse = unknown;

// FUNCTION

export const requestBorrowAsset = async ({
  params,
  body,
}: TRequestBorrowAssetMaterials): Promise<TRequestBorrowAssetResponse> => {
  const response = await ApiService.post(`/request/asset/borrow`, body, { params });
  return response.data;
};

import { localeName } from '@/common/constants';

import dataEnLanguageSource from './en';
import dataViLanguageSource from './vi';

export const resources = {
  [localeName.EN]: {
    translation: dataEnLanguageSource,
  },
  [localeName.VN]: {
    translation: dataViLanguageSource,
  },
};

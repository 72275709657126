import { TMyTimeOffType } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetUserTimeOffTypesPaths = {
  id?: string | number;
};
export type TGetUserTimeOffTypesParams = unknown;

export type TGetUserTimeOffTypesMaterials = {
  paths?: TGetUserTimeOffTypesPaths;
  params?: TGetUserTimeOffTypesParams;
};

export type TGetUserTimeOffTypesResponse = TMyTimeOffType[];

// FUNCTION

export const getUserTimeOffTypes = async ({
  paths,
  params,
}: TGetUserTimeOffTypesMaterials): Promise<TGetUserTimeOffTypesResponse> => {
  const response = await ApiService.get(`/user/time-off-types/${paths?.id || 'my-info'}`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import {
  TAdjustTimeOffBlanceMaterials,
  TAdjustTimeOffBlanceResponse,
} from '@/services/api/user/adjust-time-off-blance';

// CONSTANTS

export enum EAdjustTimeOffBlanceAction {
  ADJUST_TIME_OFF_BLANCE = 'ADJUST_TIME_OFF_BLANCE',
  ADJUST_TIME_OFF_BLANCE_REQUEST = 'ADJUST_TIME_OFF_BLANCE_REQUEST',
  ADJUST_TIME_OFF_BLANCE_SUCCESS = 'ADJUST_TIME_OFF_BLANCE_SUCCESS',
  ADJUST_TIME_OFF_BLANCE_FAILED = 'ADJUST_TIME_OFF_BLANCE_FAILED',
}

// TYPES

export type TAdjustTimeOffBlanceRequest = {
  type: EAdjustTimeOffBlanceAction.ADJUST_TIME_OFF_BLANCE_REQUEST;
  payload: {
    materials: TAdjustTimeOffBlanceMaterials;
    successCallback?: (response: TAdjustTimeOffBlanceResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAdjustTimeOffBlanceSuccess = {
  type: EAdjustTimeOffBlanceAction.ADJUST_TIME_OFF_BLANCE_SUCCESS;
  payload: { response: TAdjustTimeOffBlanceResponse };
};

export type TAdjustTimeOffBlanceFailed = { type: EAdjustTimeOffBlanceAction.ADJUST_TIME_OFF_BLANCE_FAILED };

// FUNCTION

export const adjustTimeOffBlanceAction = {
  request: createActionCreator(
    EAdjustTimeOffBlanceAction.ADJUST_TIME_OFF_BLANCE_REQUEST,
    (resolve) =>
      (
        materials: TAdjustTimeOffBlanceMaterials,
        successCallback?: (response: TAdjustTimeOffBlanceResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAdjustTimeOffBlanceRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAdjustTimeOffBlanceAction.ADJUST_TIME_OFF_BLANCE_SUCCESS,
    (resolve) =>
      (response: TAdjustTimeOffBlanceResponse): TAdjustTimeOffBlanceSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAdjustTimeOffBlanceAction.ADJUST_TIME_OFF_BLANCE_FAILED,
    (resolve) =>
      (error: unknown): TAdjustTimeOffBlanceFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import {
  TGetReportMonthlyTimekeepingMaterials,
  TGetReportMonthlyTimekeepingResponse,
} from '@/services/api/report/get-report-monthly-timekeeping';

// CONSTANTS

export enum EGetReportMonthlyTimekeepingAction {
  GET_REPORT_MONTHLY_TIMEKEEPING = 'GET_REPORT_MONTHLY_TIMEKEEPING',
  GET_REPORT_MONTHLY_TIMEKEEPING_REQUEST = 'GET_REPORT_MONTHLY_TIMEKEEPING_REQUEST',
  GET_REPORT_MONTHLY_TIMEKEEPING_SUCCESS = 'GET_REPORT_MONTHLY_TIMEKEEPING_SUCCESS',
  GET_REPORT_MONTHLY_TIMEKEEPING_FAILED = 'GET_REPORT_MONTHLY_TIMEKEEPING_FAILED',
}

// TYPES

export type TGetReportMonthlyTimekeepingRequest = {
  type: EGetReportMonthlyTimekeepingAction.GET_REPORT_MONTHLY_TIMEKEEPING_REQUEST;
  payload: {
    materials: TGetReportMonthlyTimekeepingMaterials;
    successCallback?: (response: TGetReportMonthlyTimekeepingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportMonthlyTimekeepingSuccess = {
  type: EGetReportMonthlyTimekeepingAction.GET_REPORT_MONTHLY_TIMEKEEPING_SUCCESS;
  payload: { response: TGetReportMonthlyTimekeepingResponse };
};

export type TGetReportMonthlyTimekeepingFailed = {
  type: EGetReportMonthlyTimekeepingAction.GET_REPORT_MONTHLY_TIMEKEEPING_FAILED;
};

// FUNCTION

export const getReportMonthlyTimekeepingAction = {
  request: createActionCreator(
    EGetReportMonthlyTimekeepingAction.GET_REPORT_MONTHLY_TIMEKEEPING_REQUEST,
    (resolve) =>
      (
        materials: TGetReportMonthlyTimekeepingMaterials,
        successCallback?: (response: TGetReportMonthlyTimekeepingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportMonthlyTimekeepingRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportMonthlyTimekeepingAction.GET_REPORT_MONTHLY_TIMEKEEPING_SUCCESS,
    (resolve) =>
      (response: TGetReportMonthlyTimekeepingResponse): TGetReportMonthlyTimekeepingSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportMonthlyTimekeepingAction.GET_REPORT_MONTHLY_TIMEKEEPING_FAILED,
    (resolve) =>
      (error: unknown): TGetReportMonthlyTimekeepingFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TDeleteDepartmentPaths = {
  id: string | number;
};
export type TDeleteDepartmentParams = unknown;

export type TDeleteDepartmentMaterials = {
  paths?: TDeleteDepartmentPaths;
  params?: TDeleteDepartmentParams;
};

export type TDeleteDepartmentResponse = unknown;

// FUNCTION

export const deleteDepartment = async ({
  paths,
  params,
}: TDeleteDepartmentMaterials): Promise<TDeleteDepartmentResponse> => {
  const response = await ApiService.delete(`/department/${paths?.id}`, { params });
  return response.data;
};

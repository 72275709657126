import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getUserTimeOffTypesAction } from '@/redux/actions';
import { getUserTimeOffTypes, TGetUserTimeOffTypesResponse } from '@/services/api';

// FUNCTION

export function* getUserTimeOffTypesSaga(action: ActionType<typeof getUserTimeOffTypesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getUserTimeOffTypes, materials);
    const getUserTimeOffTypesResponse: TGetUserTimeOffTypesResponse = response as TGetUserTimeOffTypesResponse;
    yield put(getUserTimeOffTypesAction.success(getUserTimeOffTypesResponse));
    successCallback?.(getUserTimeOffTypesResponse);
  } catch (err) {
    yield put(getUserTimeOffTypesAction.failure(err));
    failedCallback?.(err);
  }
}

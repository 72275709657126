import { createActionCreator } from 'deox';

import { TChangeStatusAssetMaterials, TChangeStatusAssetResponse } from '@/services/api/asset/change-status-asset';

// CONSTANTS

export enum EChangeStatusAssetAction {
  CHANGE_STATUS_ASSET = 'CHANGE_STATUS_ASSET',
  CHANGE_STATUS_ASSET_REQUEST = 'CHANGE_STATUS_ASSET_REQUEST',
  CHANGE_STATUS_ASSET_SUCCESS = 'CHANGE_STATUS_ASSET_SUCCESS',
  CHANGE_STATUS_ASSET_FAILED = 'CHANGE_STATUS_ASSET_FAILED',
}

// TYPES

export type TChangeStatusAssetRequest = {
  type: EChangeStatusAssetAction.CHANGE_STATUS_ASSET_REQUEST;
  payload: {
    materials: TChangeStatusAssetMaterials;
    successCallback?: (response: TChangeStatusAssetResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TChangeStatusAssetSuccess = {
  type: EChangeStatusAssetAction.CHANGE_STATUS_ASSET_SUCCESS;
  payload: { response: TChangeStatusAssetResponse };
};

export type TChangeStatusAssetFailed = { type: EChangeStatusAssetAction.CHANGE_STATUS_ASSET_FAILED };

// FUNCTION

export const changeStatusAssetAction = {
  request: createActionCreator(
    EChangeStatusAssetAction.CHANGE_STATUS_ASSET_REQUEST,
    (resolve) =>
      (
        materials: TChangeStatusAssetMaterials,
        successCallback?: (response: TChangeStatusAssetResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TChangeStatusAssetRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EChangeStatusAssetAction.CHANGE_STATUS_ASSET_SUCCESS,
    (resolve) =>
      (response: TChangeStatusAssetResponse): TChangeStatusAssetSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EChangeStatusAssetAction.CHANGE_STATUS_ASSET_FAILED,
    (resolve) =>
      (error: unknown): TChangeStatusAssetFailed =>
        resolve({ error }),
  ),
};

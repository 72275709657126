import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 13.1538L19.5 18.3462C19.5 18.6522 19.3784 18.9457 19.162 19.162C18.9457 19.3784 18.6522 19.5 18.3462 19.5L5.65384 19.5C5.34783 19.5 5.05434 19.3784 4.83795 19.162C4.62156 18.9457 4.5 18.6522 4.5 18.3462L4.5 5.65384C4.5 5.34782 4.62157 5.05434 4.83795 4.83795C5.05434 4.62156 5.34783 4.5 5.65385 4.5L10.8462 4.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4615 4.49962L19.5 4.49962L19.5 8.53809"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19.5 4.5L12 12" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Svg;

import { TCareerHistory } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetMyCareerHistoriesParams = unknown;

export type TGetMyCareerHistoriesMaterials = {
  params?: TGetMyCareerHistoriesParams;
};

export type TGetMyCareerHistoriesResponse = TCareerHistory[];

// FUNCTION

export const getMyCareerHistories = async ({
  params,
}: TGetMyCareerHistoriesMaterials): Promise<TGetMyCareerHistoriesResponse> => {
  const response = await ApiService.get(`/user/career-histories/my-info`, { params });
  return response.data;
};

import { TBenefit, TUser } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetBenefitPaths = {
  id: string | number;
};
export type TGetBenefitParams = unknown;

export type TGetBenefitMaterials = {
  paths?: TGetBenefitPaths;
  params?: TGetBenefitParams;
};

export type TGetBenefitResponse = {
  benefit: TBenefit;
  usersInGroup: TUser[];
};

// FUNCTION

export const getBenefit = async ({ paths, params }: TGetBenefitMaterials): Promise<TGetBenefitResponse> => {
  const response = await ApiService.get(`/benefit/${paths?.id}`, { params });
  return response.data;
};

import { createReducer } from 'deox';

import {
  TCreateTaskCategoryResponse,
  TCreateTaskResponse,
  TCreateTemplateResponse,
  TDeleteTaskCategoryResponse,
  TDeleteTaskResponse,
  TDeleteTemplateResponse,
  TDuplicateTaskResponse,
  TDuplicateTemplateResponse,
  TGetTaskCategoryResponse,
  TGetTaskResponse,
  TGetTasksCategoryResponse,
  TGetTemplateResponse,
  TGetTemplatesResponse,
  TUpdateTaskResponse,
  TUpdateTemplateResponse,
} from '@/services/api/template';
import {
  createTaskCategoryAction,
  createTaskAction,
  createTemplateAction,
  deleteTaskCategoryAction,
  deleteTaskAction,
  deleteTemplateAction,
  duplicateTaskAction,
  duplicateTemplateAction,
  getTaskCategoryAction,
  getTaskAction,
  getTasksCategoryAction,
  getTemplateAction,
  getTemplatesAction,
  updateTaskAction,
  updateTemplateAction,
} from '@/redux/actions';
import { createTaskCategoryUpdateState } from './create-task-category';
import { createTaskUpdateState } from './create-task';
import { createTemplateUpdateState } from './create-template';
import { deleteTaskCategoryUpdateState } from './delete-task-category';
import { deleteTaskUpdateState } from './delete-task';
import { deleteTemplateUpdateState } from './delete-template';
import { duplicateTaskUpdateState } from './duplicate-task';
import { duplicateTemplateUpdateState } from './duplicate-template';
import { getTaskCategoryUpdateState } from './get-task-category';
import { getTaskUpdateState } from './get-task';
import { getTasksCategoryUpdateState } from './get-tasks-category';
import { getTemplateUpdateState } from './get-template';
import { getTemplatesUpdateState } from './get-templates';
import { updateTaskUpdateState } from './update-task';
import { updateTemplateUpdateState } from './update-template';

export type TTemplateState = {
  createTaskCategoryResponse?: TCreateTaskCategoryResponse;
  createTaskResponse?: TCreateTaskResponse;
  createTemplateResponse?: TCreateTemplateResponse;
  deleteTaskCategoryResponse?: TDeleteTaskCategoryResponse;
  deleteTaskResponse?: TDeleteTaskResponse;
  deleteTemplateResponse?: TDeleteTemplateResponse;
  duplicateTaskResponse?: TDuplicateTaskResponse;
  duplicateTemplateResponse?: TDuplicateTemplateResponse;
  getTaskCategoryResponse?: TGetTaskCategoryResponse;
  getTaskResponse?: TGetTaskResponse;
  getTasksCategoryResponse?: TGetTasksCategoryResponse;
  getTemplateResponse?: TGetTemplateResponse;
  getTemplatesResponse?: TGetTemplatesResponse;
  updateTaskResponse?: TUpdateTaskResponse;
  updateTemplateResponse?: TUpdateTemplateResponse;
};

const initialState: TTemplateState = {
  createTaskCategoryResponse: undefined,
  createTaskResponse: undefined,
  createTemplateResponse: undefined,
  deleteTaskCategoryResponse: undefined,
  deleteTaskResponse: undefined,
  deleteTemplateResponse: undefined,
  duplicateTaskResponse: undefined,
  duplicateTemplateResponse: undefined,
  getTaskCategoryResponse: undefined,
  getTaskResponse: undefined,
  getTasksCategoryResponse: undefined,
  getTemplateResponse: undefined,
  getTemplatesResponse: undefined,
  updateTaskResponse: undefined,
  updateTemplateResponse: undefined,
};

const TemplateReducer = createReducer(initialState, (handleAction) => [
  handleAction(createTaskCategoryAction.success, createTaskCategoryUpdateState),
  handleAction(createTaskAction.success, createTaskUpdateState),
  handleAction(createTemplateAction.success, createTemplateUpdateState),
  handleAction(deleteTaskCategoryAction.success, deleteTaskCategoryUpdateState),
  handleAction(deleteTaskAction.success, deleteTaskUpdateState),
  handleAction(deleteTemplateAction.success, deleteTemplateUpdateState),
  handleAction(duplicateTaskAction.success, duplicateTaskUpdateState),
  handleAction(duplicateTemplateAction.success, duplicateTemplateUpdateState),
  handleAction(getTaskCategoryAction.success, getTaskCategoryUpdateState),
  handleAction(getTaskAction.success, getTaskUpdateState),
  handleAction(getTasksCategoryAction.success, getTasksCategoryUpdateState),
  handleAction(getTemplateAction.success, getTemplateUpdateState),
  handleAction(getTemplatesAction.success, getTemplatesUpdateState),
  handleAction(updateTaskAction.success, updateTaskUpdateState),
  handleAction(updateTemplateAction.success, updateTemplateUpdateState),
]);

export default TemplateReducer;

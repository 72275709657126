import { TMyBenefit } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetUserBenefitsPaths = {
  id?: string | number;
};
export type TGetUserBenefitsParams = {
  page: number;
  pageSize: number;
  sort?: string;
  search?: string;
};

export type TGetUserBenefitsMaterials = {
  paths?: TGetUserBenefitsPaths;
  params?: TGetUserBenefitsParams;
};

export type TGetUserBenefitsResponse = TPaginateResponse & {
  data: TMyBenefit[];
};

// FUNCTION

export const getUserBenefits = async ({
  paths,
  params,
}: TGetUserBenefitsMaterials): Promise<TGetUserBenefitsResponse> => {
  const response = await ApiService.get(`/benefit/list/${paths?.id || 'my-info'}`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { uploadFilesAction } from '@/redux/actions';
import { uploadFiles, TUploadFilesResponse } from '@/services/api';

// FUNCTION

export function* uploadFilesSaga(action: ActionType<typeof uploadFilesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(uploadFiles, materials);
    const uploadFilesResponse: TUploadFilesResponse = response as TUploadFilesResponse;
    yield put(uploadFilesAction.success(uploadFilesResponse));
    successCallback?.(uploadFilesResponse);
  } catch (err) {
    yield put(uploadFilesAction.failure(err));
    failedCallback?.(err);
  }
}

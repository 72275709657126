import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { signupUserAction } from '@/redux/actions';
import { signupUser, TSignupUserResponse } from '@/services/api';

import Helpers from '@/services/helpers';

// FUNCTION

export function* signupUserSaga(action: ActionType<typeof signupUserAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(signupUser, materials);
    const signupUserResponse: TSignupUserResponse = response as TSignupUserResponse;

    Helpers.storeAccessToken(signupUserResponse.accessToken);
    Helpers.storeRefreshToken('');

    yield put(signupUserAction.success(signupUserResponse));
    successCallback?.(signupUserResponse);
  } catch (err) {
    yield put(signupUserAction.failure(err));
    failedCallback?.(err);
  }
}

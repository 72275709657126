import { createActionCreator } from 'deox';

import { TWelcomeUserMaterials, TWelcomeUserResponse } from '@/services/api/user/welcome-user';

// CONSTANTS

export enum EWelcomeUserAction {
  WELCOME_USER = 'WELCOME_USER',
  WELCOME_USER_REQUEST = 'WELCOME_USER_REQUEST',
  WELCOME_USER_SUCCESS = 'WELCOME_USER_SUCCESS',
  WELCOME_USER_FAILED = 'WELCOME_USER_FAILED',
}

// TYPES

export type TWelcomeUserRequest = {
  type: EWelcomeUserAction.WELCOME_USER_REQUEST;
  payload: {
    materials: TWelcomeUserMaterials;
    successCallback?: (response: TWelcomeUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TWelcomeUserSuccess = {
  type: EWelcomeUserAction.WELCOME_USER_SUCCESS;
  payload: { response: TWelcomeUserResponse };
};

export type TWelcomeUserFailed = { type: EWelcomeUserAction.WELCOME_USER_FAILED };

// FUNCTION

export const welcomeUserAction = {
  request: createActionCreator(
    EWelcomeUserAction.WELCOME_USER_REQUEST,
    (resolve) =>
      (
        materials: TWelcomeUserMaterials,
        successCallback?: (response: TWelcomeUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TWelcomeUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EWelcomeUserAction.WELCOME_USER_SUCCESS,
    (resolve) =>
      (response: TWelcomeUserResponse): TWelcomeUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EWelcomeUserAction.WELCOME_USER_FAILED,
    (resolve) =>
      (error: unknown): TWelcomeUserFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TOnboardingUserFlowMaterials, TOnboardingUserFlowResponse } from '@/services/api/user/onboarding-user-flow';

// CONSTANTS

export enum EOnboardingUserFlowAction {
  ONBOARDING_USER_FLOW = 'ONBOARDING_USER_FLOW',
  ONBOARDING_USER_FLOW_REQUEST = 'ONBOARDING_USER_FLOW_REQUEST',
  ONBOARDING_USER_FLOW_SUCCESS = 'ONBOARDING_USER_FLOW_SUCCESS',
  ONBOARDING_USER_FLOW_FAILED = 'ONBOARDING_USER_FLOW_FAILED',
}

// TYPES

export type TOnboardingUserFlowRequest = {
  type: EOnboardingUserFlowAction.ONBOARDING_USER_FLOW_REQUEST;
  payload: {
    materials: TOnboardingUserFlowMaterials;
    successCallback?: (response: TOnboardingUserFlowResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TOnboardingUserFlowSuccess = {
  type: EOnboardingUserFlowAction.ONBOARDING_USER_FLOW_SUCCESS;
  payload: { response: TOnboardingUserFlowResponse };
};

export type TOnboardingUserFlowFailed = { type: EOnboardingUserFlowAction.ONBOARDING_USER_FLOW_FAILED };

// FUNCTION

export const onboardingUserFlowAction = {
  request: createActionCreator(
    EOnboardingUserFlowAction.ONBOARDING_USER_FLOW_REQUEST,
    (resolve) =>
      (
        materials: TOnboardingUserFlowMaterials,
        successCallback?: (response: TOnboardingUserFlowResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TOnboardingUserFlowRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EOnboardingUserFlowAction.ONBOARDING_USER_FLOW_SUCCESS,
    (resolve) =>
      (response: TOnboardingUserFlowResponse): TOnboardingUserFlowSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EOnboardingUserFlowAction.ONBOARDING_USER_FLOW_FAILED,
    (resolve) =>
      (error: unknown): TOnboardingUserFlowFailed =>
        resolve({ error }),
  ),
};

import { TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportOffboardParams = {
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  department?: string;
  employType?: string;
  branch?: string;
  sort?: string;
};

export type TGetReportOffboardMaterials = {
  params?: TGetReportOffboardParams;
};

export type TGetReportOffboardResponse = {
  chart: { year: number; month: number; total: number | string }[];
  data: TPaginateResponse & {
    data: TUser[];
  };
};

// FUNCTION

export const getReportOffboard = async ({
  params,
}: TGetReportOffboardMaterials): Promise<TGetReportOffboardResponse> => {
  const response = await ApiService.get(`/report/offboard`, { params });
  return response.data;
};

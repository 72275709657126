import ApiService from '@/services/api';

// TYPES

export type TCreateHolidayParams = unknown;
export type TCreateHolidayBody = unknown;

export type TCreateHolidayMaterials = {
  params?: TCreateHolidayParams;
  body?: TCreateHolidayBody;
};

export type TCreateHolidayResponse = unknown;

// FUNCTION

export const createHoliday = async ({ params, body }: TCreateHolidayMaterials): Promise<TCreateHolidayResponse> => {
  const response = await ApiService.post(`/holiday/new`, body, { params });
  return response.data;
};

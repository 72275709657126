import { createActionCreator } from 'deox';

import { TGetMyTimeOffTypesMaterials, TGetMyTimeOffTypesResponse } from '@/services/api/user/get-my-time-off-types';

// CONSTANTS

export enum EGetMyTimeOffTypesAction {
  GET_MY_TIME_OFF_TYPES = 'GET_MY_TIME_OFF_TYPES',
  GET_MY_TIME_OFF_TYPES_REQUEST = 'GET_MY_TIME_OFF_TYPES_REQUEST',
  GET_MY_TIME_OFF_TYPES_SUCCESS = 'GET_MY_TIME_OFF_TYPES_SUCCESS',
  GET_MY_TIME_OFF_TYPES_FAILED = 'GET_MY_TIME_OFF_TYPES_FAILED',
}

// TYPES

export type TGetMyTimeOffTypesRequest = {
  type: EGetMyTimeOffTypesAction.GET_MY_TIME_OFF_TYPES_REQUEST;
  payload: {
    materials: TGetMyTimeOffTypesMaterials;
    successCallback?: (response: TGetMyTimeOffTypesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMyTimeOffTypesSuccess = {
  type: EGetMyTimeOffTypesAction.GET_MY_TIME_OFF_TYPES_SUCCESS;
  payload: { response: TGetMyTimeOffTypesResponse };
};

export type TGetMyTimeOffTypesFailed = { type: EGetMyTimeOffTypesAction.GET_MY_TIME_OFF_TYPES_FAILED };

// FUNCTION

export const getMyTimeOffTypesAction = {
  request: createActionCreator(
    EGetMyTimeOffTypesAction.GET_MY_TIME_OFF_TYPES_REQUEST,
    (resolve) =>
      (
        materials: TGetMyTimeOffTypesMaterials,
        successCallback?: (response: TGetMyTimeOffTypesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMyTimeOffTypesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMyTimeOffTypesAction.GET_MY_TIME_OFF_TYPES_SUCCESS,
    (resolve) =>
      (response: TGetMyTimeOffTypesResponse): TGetMyTimeOffTypesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMyTimeOffTypesAction.GET_MY_TIME_OFF_TYPES_FAILED,
    (resolve) =>
      (error: unknown): TGetMyTimeOffTypesFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { sendCelebrationAction } from '@/redux/actions';
import { sendCelebration, TSendCelebrationResponse } from '@/services/api';

// FUNCTION

export function* sendCelebrationSaga(action: ActionType<typeof sendCelebrationAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(sendCelebration, materials);
    const sendCelebrationResponse: TSendCelebrationResponse = response as TSendCelebrationResponse;
    yield put(sendCelebrationAction.success(sendCelebrationResponse));
    successCallback?.(sendCelebrationResponse);
  } catch (err) {
    yield put(sendCelebrationAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TRequestTimeOffParams = unknown;
export type TRequestTimeOffBody = unknown;

export type TRequestTimeOffMaterials = {
  params?: TRequestTimeOffParams;
  body?: TRequestTimeOffBody;
};

export type TRequestTimeOffResponse = unknown;

// FUNCTION

export const requestTimeOff = async ({ params, body }: TRequestTimeOffMaterials): Promise<TRequestTimeOffResponse> => {
  const response = await ApiService.post(`/request/time-off/new`, body, { params });
  return response.data;
};

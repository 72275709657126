import { createActionCreator } from 'deox';

import {
  TCreateNewBackgroundSettingMaterials,
  TCreateNewBackgroundSettingResponse,
} from '@/services/api/setting/create-new-background-setting';

// CONSTANTS

export enum ECreateNewBackgroundSettingAction {
  CREATE_NEW_BACKGROUND_SETTING = 'CREATE_NEW_BACKGROUND_SETTING',
  CREATE_NEW_BACKGROUND_SETTING_REQUEST = 'CREATE_NEW_BACKGROUND_SETTING_REQUEST',
  CREATE_NEW_BACKGROUND_SETTING_SUCCESS = 'CREATE_NEW_BACKGROUND_SETTING_SUCCESS',
  CREATE_NEW_BACKGROUND_SETTING_FAILED = 'CREATE_NEW_BACKGROUND_SETTING_FAILED',
}

// TYPES

export type TCreateNewBackgroundSettingRequest = {
  type: ECreateNewBackgroundSettingAction.CREATE_NEW_BACKGROUND_SETTING_REQUEST;
  payload: {
    materials: TCreateNewBackgroundSettingMaterials;
    successCallback?: (response: TCreateNewBackgroundSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateNewBackgroundSettingSuccess = {
  type: ECreateNewBackgroundSettingAction.CREATE_NEW_BACKGROUND_SETTING_SUCCESS;
  payload: { response: TCreateNewBackgroundSettingResponse };
};

export type TCreateNewBackgroundSettingFailed = {
  type: ECreateNewBackgroundSettingAction.CREATE_NEW_BACKGROUND_SETTING_FAILED;
};

// FUNCTION

export const createNewBackgroundSettingAction = {
  request: createActionCreator(
    ECreateNewBackgroundSettingAction.CREATE_NEW_BACKGROUND_SETTING_REQUEST,
    (resolve) =>
      (
        materials: TCreateNewBackgroundSettingMaterials,
        successCallback?: (response: TCreateNewBackgroundSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateNewBackgroundSettingRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateNewBackgroundSettingAction.CREATE_NEW_BACKGROUND_SETTING_SUCCESS,
    (resolve) =>
      (response: TCreateNewBackgroundSettingResponse): TCreateNewBackgroundSettingSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateNewBackgroundSettingAction.CREATE_NEW_BACKGROUND_SETTING_FAILED,
    (resolve) =>
      (error: unknown): TCreateNewBackgroundSettingFailed =>
        resolve({ error }),
  ),
};

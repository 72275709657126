import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { contractProbationRequestAction } from '@/redux/actions';
import { contractProbationRequest, TContractProbationRequestResponse } from '@/services/api';

// FUNCTION

export function* contractProbationRequestSaga(
  action: ActionType<typeof contractProbationRequestAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(contractProbationRequest, materials);
    const contractProbationRequestResponse: TContractProbationRequestResponse =
      response as TContractProbationRequestResponse;
    yield put(contractProbationRequestAction.success(contractProbationRequestResponse));
    successCallback?.(contractProbationRequestResponse);
  } catch (err) {
    yield put(contractProbationRequestAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createAssetAction } from '@/redux/actions';
import { createAsset, TCreateAssetResponse } from '@/services/api';

// FUNCTION

export function* createAssetSaga(action: ActionType<typeof createAssetAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createAsset, materials);
    const createAssetResponse: TCreateAssetResponse = response as TCreateAssetResponse;
    yield put(createAssetAction.success(createAssetResponse));
    successCallback?.(createAssetResponse);
  } catch (err) {
    yield put(createAssetAction.failure(err));
    failedCallback?.(err);
  }
}

const dataSources = {
  Common_previous: 'Quay Lại',
  Common_next: 'Tiếp Theo',
  Common_enter_data: 'Nhập',
  Common_select_date: 'Chọn Ngày',
  Common_select_date_start_end: 'Ngày Bắt Đầu - Ngày Kết Thúc',
  Common_select_with_dash: '-- Chọn --',
  Common_close: 'Đóng',
  Common_confirm: 'Xác Nhận',
  Common_cancel: 'Huỷ',
  Common_submit: 'Gửi',
  Common_submit_request: 'Gửi Yêu Cầu',
  Common_delete: 'Xóa',
  Common_save_changes: 'Lưu',
  Common_days: 'ngày',
  Common_weeks: 'tuần',
  Common_months: 'tháng',
  Common_before: 'trước',
  Common_after: 'sau',
  Common_change: 'Thay Đổi',
  Common_back: 'Trở Lại',
  Common_activate: 'hoạt động',
  Common_deactivate: 'vô hiệu hóa',
  Common_create: 'Tạo Mới',
  Common_update: 'Cập Nhật',
  Common_hrs: 'giờ',
  Common_hours: 'giờ',
  Common_search: 'Tìm Kiếm',
  Common_days_bracket: 'ngày',
  Common_all_categories: 'Tất Cả Danh Mục',
  Common_all: 'Tất Cả',

  Tooltip_click_to: 'Chọn',

  NewCategory: 'Thêm Danh Mục',
  NewCategory_placeholder: 'Nhập Tên Danh Mục',
  NewCategory_create: 'Thêm Danh Mục',
  NewCategory_update: 'Sửa Danh Mục',

  Table_items_per_page: 'Dòng hiển thị',
  Table_page: 'Trang',
  Table_sort_asc: 'Sắp xếp tăng dần',
  Table_sort_desc: 'Sắp xếp giảm dần',
  Table_sort_cancel: 'Hủy sắp xếp',

  Gender_male: 'Nam',
  Gender_female: 'Nữ',

  Marital_single: 'Độc Thân',
  Marital_married: 'Kết Hôn',
  Marital_divorced: 'Ly Hôn',
  Marital_windowed: 'Góa',

  Header_self_service: 'Hồ Sơ Cá Nhân',
  Header_people: 'Nhân Viên',
  Header_assets: 'Tài Sản',
  Header_checklists: 'Công Việc',
  Header_report: 'Báo Cáo',
  Header_requests: 'Yêu Cầu',
  Header_company_regulations: 'Quy Định',
  Header_search_placeholder: 'Tìm Kiếm Nhân Viên',

  AccountDropdown_account_settings: 'Cài Đặt Tài Khoản',
  AccountDropdown_change_password: 'Đổi Mật Khẩu',
  AccountDropdown_logout: 'Đăng Xuất',
  AccountDropdown_give_us_feedback: 'Gửi Ý Kiến Đóng Góp',

  QuickActionsDropdown_create_new: 'Tạo',
  QuickActionsDropdown_new_employee: 'Nhân Viên Mới',
  QuickActionsDropdown_new_assets: 'Tài Sản Mới',
  QuickActionsDropdown_new_regulations: 'Quy Định Mới',
  QuickActionsDropdown_import: 'Tải Lên',
  QuickActionsDropdown_import_employees: 'Tải Lên Nhân Viên',

  DatePicker_selected: 'Đã chọn',
  DatePicker_today: 'Hôm nay',

  MemberCard_joined_on: 'Ngày Tham Gia',

  Empty_there_nothing_here: 'Không có dữ liệu.',

  Onboarding_title: 'Trước khi chính thức gia nhập công ty, chúng tôi muốn tìm hiểu thêm về bạn...',
  Onboarding_basic_information: 'Thông Tin Cá Nhân',
  Onboarding_home_address: 'Địa Chỉ',
  Onboarding_banking_info: 'Tài Khoản Ngân Hàng',
  Onboarding_emergency_contact: 'Liên Hệ Khẩn Cấp',
  Onboarding_dependants: 'Người Phụ Thuộc',
  Onboarding_social_media: 'Mạng Xã Hội',
  Onboarding_checklist: 'Công Việc',
  Onboarding_finish_onboarding: 'Hoàn Thành Onboarding',
  Onboarding_profile_photo: 'Ảnh Đại Diện',
  Onboarding_first_name: 'Tên',
  Onboarding_last_name: 'Họ',
  Onboarding_date_of_birth: 'Ngày Sinh',
  Onboarding_nationality: 'Quốc Tịch',
  Onboarding_gender: 'Giới Tính',
  Onboarding_marital_status: 'Tình Trạng Hôn Nhân',
  Onboarding_phone_number: 'Số Điện Thoại',
  Onboarding_personal_email: 'Email Cá Nhân',
  Onboarding_work_email: 'Email Công Việc',
  Onboarding_personal_id: 'Số CMND/CCCD',
  Onboarding_date_issued: 'Ngày Cấp',
  Onboarding_place_issued: 'Nơi Cấp',
  Onboarding_personal_tax: 'Mã Số Thuế',
  Onboarding_social_insurance: 'Mã Số Bảo Hiểm Xã Hội',
  Onboarding_health_insurance: 'Mã Số Bảo Hiểm Y Tế',
  Onboarding_country: 'Quốc Gia',
  Onboarding_state_province: 'Tỉnh/Thành Phố',
  Onboarding_permanent_address: 'Địa Chỉ Thường Trú',
  Onboarding_current_address: 'Địa Chỉ Hiện Tại',
  Onboarding_bank_name: 'Tên Ngân Hàng',
  Onboarding_branch_name: 'Chi Nhánh',
  Onboarding_account_name: 'Chủ Tài Khoản',
  Onboarding_account_number: 'Số Tài Khoản',
  Onboarding_full_name: 'Họ Tên',
  Onboarding_relationship: 'Mối Quan Hệ',
  Onboarding_contact: 'Liên hệ',
  Onboarding_default: 'Mặc Định',
  Onboarding_add: 'Thêm',
  Onboarding_dependant_empty:
    'Nếu bạn có người phụ thuộc, hãy thêm họ vào đây để được hưởng đầy đủ các phúc lợi xã hội',
  Onboarding_contact_empty: 'Nếu bạn có liên hệ khẩn cấp, hãy thêm họ vào đây để được hưởng đầy đủ các phúc lợi xã hội',
  Onboarding_dependant: 'Người Phụ Thuộc',
  Onboarding_not_have_personal_id: 'Người này không có CMND/CCCD',

  OnboardingProcessing_title_success: 'Onboarding Thành Công!',
  OnboardingProcessing_description_success:
    'Thông tin Onboarding của bạn đã được chấp thuận.\nChào mừng đến với Acazia Software!',
  OnboardingProcessing_title_process: 'Đang kiểm tra thông tin Onboarding...',
  OnboardingProcessing_description_process:
    'Để tránh các sơ suất trong tương lai, chúng tôi cần kiểm tra thông tin onboarding của bạn trước khi tiếp tục. Bạn sẽ có thể truy cập vào hệ thống sau khi thông tin được chấp thuận.\nViệc này sẽ không mất nhiều thời gian. Cảm ơn bạn đã chờ đợi!',
  OnboardingProcessing_title_failed: 'Onboarding Thất Bại!',
  OnboardingProcessing_description_failed:
    'Thông tin Onboarding của bạn đã được kiểm tra. Một vài thông tin chưa đáp ứng yêu cầu của chúng tôi.\nVui lòng hoàn thành tất cả các thông tin bắt buộc trước khi gửi lại lần nữa.',
  OnboardingProcessing_title_access_denied: 'Truy Cập Bị Từ Chối',
  OnboardingProcessing_description_access_denied: `Bạn không có quyền truy cập hệ thống.\nXin vui lòng liên hệ với Quản Trị Hệ Thống của bạn !`,
  OnboardingProcessing_go_to_dashboard: 'Đi đến Trang Chủ',
  OnboardingProcessing_back_to_login: 'Quay lại Đăng Nhập',
  OnboardingProcessing_back_to_onboarding: 'Quay lại mục Onboarding',
  OnboardingProcessing_title_forbidden: 'Truy Cập Bị Từ Chối',
  OnboardingProcessing_description_forbidden: `Bạn không có quyền truy cập tính năng này.\nXin vui lòng liên hệ với Quản Trị Hệ Thống của bạn !`,
  OnboardingProcessing_back_to_home: 'Quay lại Màn Hình Chính',

  Onboarding_department: 'Bộ Phận',
  Onboarding_employment_type: 'Hình Thức Công Việc',
  Onboarding_job_title: 'Chức Danh',
  Onboarding_line_manager: 'Người Quản Lý',
  Onboarding_branch: 'Chi Nhánh',
  Onboarding_current_password: 'Mật Khẩu Hiện Tại',
  Onboarding_new_password: 'Mật Khẩu Mới',
  Onboarding_re_new_password: 'Nhập lại Mật Khẩu Mới',
  Onboarding_join_date: 'Ngày Tham Gia',
  Onboarding_probation_start: 'Ngày Bắt Đầu Thử Việc',
  Onboarding_probation_end: 'Ngày Kết Thúc Thử Việc',
  Onboarding_contract_start: 'Ngày Bắt Đầu Hợp Đồng',
  Onboarding_contract_end: 'Ngày Kết Thúc Hợp Đồng',
  Onboarding_job_tenure: 'Nhiệm Kỳ Công Việc',

  TimeOffCard_hrs_left: 'giờ còn lại',
  TimeOffCard_request_time_off: 'Yêu Cầu Nghỉ',
  TimeOffCard_request_annual: 'Hưởng Lương',
  TimeOffCard_request_paternity: 'Thai Sản',
  TimeOffCard_request_wedding: 'Đám Cưới',
  TimeOffCard_request_funeral: 'Ma Chay',
  TimeOffCard_request_sick: 'Ốm',
  TimeOffCard_request_covid: 'Covid-19',
  TimeOffCard_request_unpaid: 'Không Lương',
  TimeOffCard_request_annual_leave: 'Nghỉ Hưởng Lương',
  TimeOffCard_request_paternity_leave: 'Nghỉ Thai Sản',
  TimeOffCard_request_wedding_leave: 'Nghỉ Cưới',
  TimeOffCard_request_funeral_leave: 'Nghỉ Ma Chay',
  TimeOffCard_request_sick_leave: 'Nghỉ Ốm',
  TimeOffCard_request_covid_leave: 'Nghỉ Covid-19',
  TimeOffCard_request_unpaid_leave: 'Nghỉ Không Lương',

  DashboardCard_your_request: 'Yêu Cầu Của Tôi',
  DashboardCard_company_time_off: 'Danh Sách Nhân Viên Nghỉ',
  DashboardCard_company_time_off_today: 'Hôm Nay',
  DashboardCard_company_time_off_tomorrow: 'Ngày Mai',
  DashboardCard_company_holidays: 'Ngày Lễ',
  DashboardCard_company_celebration: 'Lễ Kỷ Niệm',
  DashboardCard_company_links: 'Trang Liên Kết',
  DashboardCard_update: 'Cập Nhật',
  DashboardCard_notifications: 'Thông Báo',
  DashboardCard_new_members: 'Nhân Viên Mới',
  DashboardCard_reports: 'Báo Cáo',
  DashboardCard_all_reports: 'Tất Cả Báo Cáo',

  ModalLogout_title: 'Đăng Xuất',
  ModalLogout_description: 'Bạn có chắc chắn muốn đăng xuất ?',

  Priority_none: 'Không',
  Priority_low: 'Ưu Tiên Thấp',
  Priority_medium: 'Ưu Tiên Trung Bình',
  Priority_high: 'Ưu Tiên Cao',

  DueDate_normal: 'Bình Thường',
  DueDate_onboarding: 'Onboarding',
  DueDate_offboarding: 'Offboarding',

  AccountStatus_active: 'Hoạt Động',
  AccountStatus_deactivate: 'Vô Hiệu Hóa',
  AccountStatus_invite: 'Đã Mời',

  HolidayApplyTo_all: 'Tất Cả Nhân Viên',
  HolidayApplyTo_base_type: 'Theo Hình Thức Công Việc ',
  HolidayApplyTo_specific: 'Nhân Viên Cụ Thể',

  BenefitType_monetary: 'Mất Phí',
  BenefitType_non_monetary: 'Không mất phí',

  EligibilityStart_entered_date: 'Vào Ngày Được Nhập',
  EligibilityStart_after_onboard: 'Sau Khi Onboarding',
  EligibilityStart_after_probation: 'Sau Khi Hoàn Thành Thử Việc',
  EligibilityStart_period_after_onboard: 'Sau Khi Onboarding Một Khoảng Thời Gian',
  EligibilityStart_period_after_probation: 'Sau Khi Hoàn Thành Thử Việc Một Khoảng Thời Gian',

  EligibilityEnd_entered_date: 'Vào Ngày Được Nhập',
  EligibilityEnd_after_last_working_day: 'Sau Ngày Làm Việc Cuối Cùng',
  EligibilityEnd_after_resignation_approval: 'Sau Khi Đơn Xin Thôi Việc Được Chấp Thuận',
  EligibilityEnd_before_last_working_day: 'Trước Ngày Làm Việc Cuối Cùng',

  TimeEEligibility_days: 'Ngày',
  TimeEEligibility_months: 'Tháng',
  TimeEEligibility_years: 'Năm',

  BenefitCost_entered_manually: 'Nhập Thủ Công',
  BenefitCost_fixed_cost: 'Chi Phí Cố Định',

  BenefitCostIncur_once: 'Một Lần',
  BenefitCostIncur_monthly: 'Hàng Tháng',
  BenefitCostIncur_yearly: 'Hàng Năm',

  UserStatus_active: 'Hoạt Động',
  UserStatus_onboarding: 'Onboarding',
  UserStatus_offboarding: 'Offboarding',
  UserStatus_onleave: 'Nghỉ Phép',
  UserStatus_onprobation: 'Thử Việc',
  UserStatus_resigned: 'Đã Nghỉ',

  AssetStatus_active: 'Có Sẵn',
  AssetStatus_unavailable: 'Không Có Sẵn',
  AssetStatus_borrowed: 'Đã Mượn',

  DayOfWeeks_mon: 'Thứ Hai',
  DayOfWeeks_tue: 'Thứ Ba',
  DayOfWeeks_wed: 'Thứ Tư',
  DayOfWeeks_thu: 'Thứ Năm',
  DayOfWeeks_fri: 'Thứ Sáu',
  DayOfWeeks_sat: 'Thứ Bảy',
  DayOfWeeks_sun: 'Chủ Nhật',

  Relationship_father: 'Bố',
  Relationship_mother: 'Mẹ',
  Relationship_spouse: 'Vợ/Chồng',
  Relationship_sibling: 'Anh/Chị/Em',
  Relationship_friend: 'Bạn',
  Relationship_other: 'Khác',
  Relationship_father_in_law: 'Bố Vợ/Chồng',
  Relationship_mother_in_law: 'Mẹ Vợ/Chồng',
  Relationship_children: 'Con',
  Relationship_husband: 'Chồng',
  Relationship_wife: 'Vợ',
  Relationship_other_grand_father: 'Ông',
  Relationship_grand_mother: 'Bà',

  AssetsRequests_assets_requests: 'Yêu Cầu Tài Sản',
  AssetsRequests_time_off_requests: 'Yêu Cầu Nghỉ Phép',
  AssetsRequests_assets_borrowing_request: 'Yêu Cầu Mượn Tài Sản',
  AssetsRequests_assets_return_request: 'Yêu Cầu Trả Tài Sản',

  ResignRequests_resign_requests: 'Yêu Cầu Thôi Việc',
  ResignRequests_resign_request: 'Yêu Cầu Thôi Việc',
  ResignRequests_contract_termination_request: 'Yêu Cầu Chấm Dứt Hợp Đồng',
  ResignRequests_probation_termination_request: 'Yêu Cầu Chấm Dứt Thử Việc',

  PersonalCard_general: 'Thông Tin Chung',
  PersonalCard_employee_information: 'Thông Tin Nhân Viên',
  PersonalCard_line_manager: 'Người Quản Lý',
  PersonalCard_social_media: 'Mạng Xã Hội',

  SelfService_tab_personal_info: 'Thông Tin Cá Nhân',
  SelfService_tab_career: 'Nghề Nghiệp',
  SelfService_tab_benefits: 'Quyền Lợi',
  SelfService_tab_time_off: 'Nghỉ Phép',
  SelfService_tab_assets: 'Tài Sản',
  SelfService_tab_documents: 'Tài Liệu',

  Peoples_new_employee: 'Thêm Nhân Viên',
  Peoples_invite_employee: 'Mời Nhân Viên',
  Peoples_import_employee: 'Tải Lên',
  Peoples_people: 'Nhân Viên',
  Peoples_total: 'Tổng',
  Peoples_search_placeholder: 'Tìm kiếm Tên, Email...',
  Peoples_filter: 'Lọc',
  Peoples_download: 'Tải Xuống',
  Peoples_empty_select: 'Chọn “Thêm Nhân Viên” ở góc trên cùng bên phải để tạo nhân viên mới.',

  PeoplesTable_employees_id: 'Mã Nhân viên',
  PeoplesTable_employees: 'Nhân viên',
  PeoplesTable_job_title: 'Chức danh',
  PeoplesTable_department: 'Bộ phận',
  PeoplesTable_joined_on: 'Ngày tham gia',
  PeoplesTable_employment_type: 'Hình thức',
  PeoplesTable_employee_status: 'Trạng thái',
  PeoplesTable_view_onboarding_progress: 'Xem quá trình Onboarding',
  PeoplesTable_view_offboarding_progress: 'Xem quá trình Offboarding',
  PeoplesTable_report_violation: 'Báo Cáo Vi Phạm',
  PeoplesTable_regulation_violation: 'Vi Phạm Quy Định',
  PeoplesTable_regulation_violation_des: 'Hiển thị nhân viên bị gắn cờ do vi phạm quy định.',
  PeoplesTable_view_report_violation: 'Xem Báo Cáo Vi Phạm',
  PeoplesTable_delete_report_violation: 'Gỡ Báo Cáo Vi Phạm',
  PeoplesTable_offboarding: 'Offboarding',
  PeoplesTable_complete_offboarding: 'Hoàn Thành Offboarding',
  PeoplesTable_cancel_offboarding: 'Hủy Offboarding',
  PeoplesTable_rehire: 'Tuyển Dụng Lại',
  PeoplesTable_reason_for_leaving: 'Lý Do Nghỉ Việc',
  PeoplesTable_resignation_date: 'Ngày Từ Chức',
  PeoplesTable_last_working_date: 'Ngày Làm Việc Cuối',
  PeoplesTable_length_of_service: 'Thời Hạn Làm Việc',

  UploadDragDrop_text: 'Kéo và thả file vào đây.',

  ModalImportEmployee_title: 'Tải Lên Nhân Viên',
  ModalImportEmployee_description: 'Tải lên file thông tin nhân viên',
  ModalImportEmployee_or: 'hoặc',
  ModalImportEmployee_download: 'Tải xuống mẫu tệp thông tin nhân viên',

  ModalReportViolation_title: 'Báo Cáo Vi Phạm',
  ModalReportViolation_detail_title: 'Chi Tiết Báo Cáo Vi Phạm',
  ModalReportViolation_detail_desc: 'bị đánh dấu do vi phạm quy định.',
  ModalReportViolation_desc: 'sẽ bị đánh dấu do vi phạm quy định. Vui lòng nêu rõ lý do để báo cáo vi phạm.',
  ModalReportViolation_label: 'Nguyên Nhân',
  ModalReportViolation_add_file: 'Thêm File',

  ModalDeleteReportViolation_title: 'Gỡ Báo Cáo Vi Phạm',

  ModalChangePeopleStatus_title_1: 'Thay Đổi Hình Thức Công Việc',
  ModalChangePeopleStatus_title_2: 'Thay Đổi Trạng Thái Nhân Viên',
  ModalChangePeopleStatus_changing: 'Thay đổi',
  ModalChangePeopleStatus_employment_type: 'hình thức công việc',
  ModalChangePeopleStatus_employee_status: 'trạng thái nhân viên',
  ModalChangePeopleStatus_desc_1: 'sẽ ảnh hưởng đến các thông tin khác như Nghỉ Phép, Quyền Lợi,...',
  ModalChangePeopleStatus_desc_2: 'Bạn có chắc muốn thay đổi',
  ModalChangePeopleStatus_from: 'từ',
  ModalChangePeopleStatus_to: 'thành',

  InvitePeople_back_to_people: 'Quay lại mục Nhân Viên',
  InvitePeople_title: 'Mời Nhân Viên',
  InvitePeople_desc:
    'Nhân viên mới sẽ được gửi link mời tạo tài khoản HRM qua email. Sau khi chấp nhận, nhân viên này sẽ được thêm vào mục Công Việc để HR thực hiện Onboarding.',

  NavigationSidePanel_settings: 'Cài Đặt',
  NavigationSidePanel_general: 'Cài Đặt Chung',
  NavigationSidePanel_company_information: 'Thông Tin Công Ty',
  NavigationSidePanel_approvals: 'Cài Đặt Phê Duyệt',
  NavigationSidePanel_requets_approvers: 'Người Duyệt Yêu Cầu',
  NavigationSidePanel_requets_cc_recipients: `Người Liên Quan`,
  NavigationSidePanel_time_offs: 'Nghỉ Phép',
  NavigationSidePanel_holidays: 'Nghỉ Lễ',
  NavigationSidePanel_benefits: 'Quyền Lợi',
  NavigationSidePanel_users: 'Truy Cập Người Dùng',
  NavigationSidePanel_permissions: 'Phân Quyền',
  NavigationSidePanel_checklists_template: 'Mẫu Công Việc',
  NavigationSidePanel_integration: 'Tích Hợp Hệ Thống',

  ImageGalleryPicker_add_new_picture: 'Thêm Ảnh',

  General_back_to_general: 'Trở về Cài Đặt Chung',
  General_date_input_format: 'Định Dạng Ngày Tháng',
  General_time_zone: 'Múi Giờ',
  General_default_currency: 'Tiền Tệ Mặc Định',
  General_language: 'Ngôn Ngữ',
  General_background_picture: 'Hình Nền',
  General_background_picture_desc: 'Cá nhân hóa không gian làm việc với hình nền.',
  General_change_password: 'Đổi Mật Khẩu',
  General_change_password_desc: 'Thay đổi mật khẩu hiện tại của bạn.',

  ModalChangePasswordSuccess_title: 'Thành Công !',
  ModalChangePasswordSuccess_desc:
    'Mật khẩu đã được thay đổi thành công. Bạn sẽ bị đăng xuất khỏi Acazia HR. Vui lòng đăng nhập lại bằng mật khẩu mới của bạn.',
  ModalChangePasswordSuccess_logout: 'Đăng Xuất',

  Language_vn: 'Tiếng Việt',
  Language_en: 'Tiếng Anh',

  Company_basic_info: 'Thông Tin Chung',
  Company_branch: 'Chi Nhánh',
  Company_department: 'Bộ Phận',
  Company_job_title: 'Chức Danh',
  Company_employment_type: 'Hình Thức Nhân Viên',
  Company_employee_id: 'Mã Nhân Viên',

  CompanyBasicInfo_company_name: 'Tên Công Ty',
  CompanyBasicInfo_date_of_establishment: 'Ngày Thành Lập',
  CompanyBasicInfo_company_logo: 'Logo',
  CompanyBasicInfo_company_logo_desc: 'Logo công ty sẽ xuất hiện trên tiêu đề của trang web.',
  CompanyBasicInfo_upload_desc_1: 'Kích thước tối đa: 2MB. Định dạng: png, jpg, jpeg.',
  CompanyBasicInfo_upload_desc_2: 'Đề xuất sử dụng Logo có nền trong suốt.',
  CompanyBasicInfo_upload_placeholder: 'Logo',
  CompanyBasicInfo_company_links: 'Trang liên Kết',
  CompanyBasicInfo_company_links_desc: 'Các trang liên kết của công ty sẽ hiển thị tại phần Trang Chủ.',
  CompanyBasicInfo_add_new_link: 'Thêm Liên Kết',

  CompanyBranch_new_branch: 'Thêm Chi Nhánh',
  CompanyBranch_edit_branch: 'Sửa Chi Nhánh',
  CompanyBranch_delete_branch: 'Xoá Chi Nhánh',
  CompanyBranch_title_branch: 'Tên Chi Nhánh',
  CompanyBranch_address_branch: 'Địa Chỉ Chi Nhánh',
  CompanyBranch_working_days_branch: 'Thời Gian Làm Việc',
  CompanyBranch_employees: 'Tổng Số Nhân Viên',
  CompanyBranch_working_time: 'Giờ Làm Việc',
  CompanyBranch_time_zone: 'Múi Giờ',
  CompanyBranch_pic: 'Người Phụ Trách',
  CompanyBranch_pic_suffix: 'Người Phụ Trách',
  CompanyBranch_contact_email: 'Email',
  CompanyBranch_contact_number: 'Số Điện Thoại',

  ModalDeleteBranch_title: 'Xoá Chi Nhánh',
  ModalDeleteBranch_desc_1: 'Chi nhánh này có',
  ModalDeleteBranch_desc_2: 'nhân viên. Thông tin về Chi Nhánh của họ sẽ bị mất sau khi xác nhận xoá.',
  ModalDeleteBranch_desc_3: 'Bạn có chắc muốn xoá chi nhánh này?',

  BranchDetail_new: 'Thêm',
  BranchDetail_edit: 'Sửa',
  BranchDetail_back_to_branch: 'Quay lại mục Cài Đặt Chi Nhánh',

  Department_title: 'Tên Bộ Phận',
  Department_no_of_employees: 'Tổng Số Nhân Viên',
  Department_leader: 'Trưởng Bộ Phận',
  Department_sub_leader: 'Phó Bộ Phận',
  Department_new_department: 'Thêm Bộ Phận',
  Department_edit_department: 'Sửa Bộ Phận',
  Department_delete_department: 'Xoá Bộ Phận',

  DepartmentDetail_back_to_department: 'Quay lại mục Cài Đặt Bộ Phận',
  DepartmentDetail_department_leader: 'Trưởng Bộ Phận',

  ModalDeleteDepartment_title: 'Xoá Bộ Phận',
  ModalDeleteDepartment_desc_1:
    'nhân viên làm việc trong bộ phận này. Một số thông tin như Nghỉ Phép, Quyền Lợi,...của họ sẽ bị ảnh hưởng sau khi xác nhận xoá.',
  ModalDeleteDepartment_desc_2: 'Bạn có chắc muốn xoá bộ phận',

  JobTitle_new_job_title: 'Thêm Chức Danh',
  JobTitle_edit_job_title: 'Sửa',
  JobTitle_delete_job_title: 'Xoá',

  JobTitleDetail_back_to_job_title: 'Quay lại phần Cài Đặt Chức Danh',
  JobTitleDetail_status: 'Trạng Thái',

  ModalDeleteJobTitle_desc_1:
    'nhân viên đang giữ chức danh này. Một số thông tin như Nghỉ Phép, Quyền Lợi,...của họ sẽ bị ảnh hưởng sau khi xác nhận xoá.',
  ModalDeleteJobTitle_desc_2: 'Bạn có chắc muốn xoá chức danh',

  ModalDeactivateJobTitle_title: 'Tắt',
  ModalDeactivateJobTitle_desc_1:
    'nhân viên đang giữ chức danh này. Một số thông tin như Nghỉ Phép, Quyền Lợi,...của họ sẽ bị ảnh hưởng sau khi xác nhận tắt.',
  ModalDeactivateJobTitle_desc_2: 'Bạn có chắc muốn tắt hoạt động của chức danh này',

  EmploymentType_new_employment_type: 'Thêm Hình Thức Nhân Viên',
  EmploymentType_edit_employment_type: 'Sửa',
  EmploymentType_delete_employment_type: 'Xoá',

  EmploymentTypeDetail_back_to_employment_type: 'Quay lại mục Cài Đặt Hình Thức Nhân Viên',

  ModalDeleteEmploymentType_desc_1:
    'nhân viên làm việc với hình thức này. Một số thông tin như Nghỉ Phép, Quyền Lợi,...của họ sẽ bị ảnh hưởng sau khi xác nhận xoá.',
  ModalDeleteEmploymentType_desc_2: 'Bạn có chắc muốn xoá hình thức nhân viên',

  ModalDeactivateEmploymentType_title: 'Tắt',
  ModalDeactivateEmploymentType_desc_1:
    'nhân viên đang giữ chức danh này. Một số thông tin như Nghỉ Phép, Quyền Lợi,...của họ sẽ bị ảnh hưởng sau khi xác nhận tắt.',
  ModalDeactivateEmploymentType_desc_2: 'Bạn có chắc muốn tắt hoạt động của hình thức này',

  EmploymentTypeDetail_title: 'Tên Hình Thức Nhân Viên',
  EmploymentTypeDetail_status: 'Trạng Thái',

  EmployeeID_title: 'Tự động tạo Mã Nhân Viên',
  EmployeeID_desc: 'Bật hoặc Tắt chức năng tạo mã nhân viên',
  EmployeeID_example: 'Ví dụ: Mã nhân viên là AZ-000001',
  EmployeeID_tooltip:
    'ID nhân viên bao gồm tiền tố gồm 2 chữ số và một dãy số. Có thể đặt tiền tố ID là bất kỳ ký tự nào (AB, MD,...).',
  EmployeeID_title_btn: 'Cấu hình Mã Nhân Viên',

  Carrer_overview: 'Tổng Quan Nghề Nghiệp',
  Carrer_new_resign_request: 'Yêu cầu Thôi Việc',
  Carrer_new_contract_termination_request: 'Yêu cầu Huỷ Hợp Đồng',
  Carrer_history: 'Lịch Sử Chỉnh Sửa',

  TaskAssignedType_unassigned: 'Chưa xác định',
  TaskAssignedType_checklist_assignor: '@Người thực hiện',
  TaskAssignedType_hr_in_charge: '@HR phụ trách',
  TaskAssignedType_line_manager: '@Người quản lý',
  TaskAssignedType_specific_employee: 'Nhân viên cụ thể',

  RequestBoardingStatus_invited: 'Đã Mời',
  RequestBoardingStatus_in_process: 'Đang Thực Hiện',
  RequestBoardingStatus_review_needed: 'Cần Đánh Giá',
  RequestBoardingStatus_pending: 'Đang Chờ',
  RequestBoardingStatus_completed: 'Hoàn Thành',
  RequestBoardingStatus_canceled: 'Đã Huỷ',

  TaskStatus_open: 'Chưa Giải Quyết',
  TaskStatus_in_progress: 'Đang Thực Hiện',
  TaskStatus_done: 'Đã Hoàn Thành',

  EndOn_never: 'Không Bao Giờ',
  EndOn_specific_date: 'Ngày Cụ Thể',

  AccrualType_auto_accrue: 'Tự Động Tích Luỹ',
  AccrualType_custom: 'Tuỳ Chỉnh',

  EveryOrAfterTimeType_days: 'Ngày',
  EveryOrAfterTimeType_weeks: 'Tuần',
  EveryOrAfterTimeType_months: 'Tháng',
  EveryOrAfterTimeType_years: 'Năm',

  AccrueType_weekly: 'Hàng Tuần',
  AccrueType_monthly: 'Hàng Tháng',
  AccrueType_yearly: 'Hàng Năm',

  StartOn_1st: '1',
  StartOn_2nd: '2',
  StartOn_3rd: '3',
  StartOn_4th: '4',
  StartOn_5th: '5',
  StartOn_6th: '6',
  StartOn_7th: '7',
  StartOn_8th: '8',
  StartOn_9th: '9',
  StartOn_10th: '10',
  StartOn_11th: '11',
  StartOn_12th: '12',
  StartOn_13th: '13',
  StartOn_14th: '14',
  StartOn_15th: '15',
  StartOn_16th: '16',
  StartOn_17th: '17',
  StartOn_18th: '18',
  StartOn_19th: '19',
  StartOn_20th: '20',
  StartOn_21th: '21',
  StartOn_22th: '22',
  StartOn_23th: '23',
  StartOn_24th: '24',
  StartOn_25th: '25',
  StartOn_26th: '26',
  StartOn_27th: '27',
  StartOn_28th: '28',
  StartOn_29th: '29',
  StartOn_30th: '30',
  StartOn_31th: '31',
  StartOn_last_day: 'Ngày cuối cùng',
  StartOn_monday: 'Thứ 2',
  StartOn_tuesday: 'Thứ 3',
  StartOn_wednesday: 'Thứ 4',
  StartOn_thursday: 'Thứ 5',
  StartOn_friday: 'Thứ 6',
  StartOn_saturday: 'Thứ 7',
  StartOn_sunday: 'Chủ Nhật',
  StartOn_january: 'Tháng 1',
  StartOn_febuary: 'Tháng 2',
  StartOn_march: 'Tháng 3',
  StartOn_april: 'Tháng 4',
  StartOn_may: 'Tháng 5',
  StartOn_june: 'Tháng 6',
  StartOn_july: 'Tháng 7',
  StartOn_august: 'Tháng 8',
  StartOn_september: 'Tháng 9',
  StartOn_october: 'Tháng 10',
  StartOn_november: 'Tháng 11',
  StartOn_december: 'Tháng 12',

  TimeOffPaidType_paid: 'Trả Phí',
  TimeOffPaidType_unpaid: 'Không Trả Phí',

  AssignDate_upon_join_date: 'Vào Ngày Tham Gia',
  AssignDate_after_probation_end_date: 'Sau Ngày Kết Thúc Thử Việc',

  AdjustBalance_add: 'Cộng',
  AdjustBalance_subtract: 'Trừ',
  AdjustBalance_added: 'Đã Cộng',
  AdjustBalance_subtracted: 'Đã Trừ',

  SendFeedback_ask_a_question: 'Đặt Câu Hỏi',
  SendFeedback_leave_a_comment: 'Để Lại Ý Kiến',
  SendFeedback_report_a_bug: 'Báo Lỗi',
  SendFeedback_suggest_an_improvement: 'Đề Xuất Cải Thiện',

  Assets_title: 'Tài Sản',
  Assets_new: 'Thêm Tài Sản',
  Assets_new_category: 'Thêm Danh Mục',
  Assets_print_qr: 'In Mã QR Tài Sản',
  Assets_search_location: 'Tìm kiếm tài sản, chi nhánh,...',
  Assets_total: 'Tổng',
  Assets_name: 'Tên',
  Assets_category: 'Danh Mục',
  Assets_borrowed_by: 'Người Mượn',
  Assets_borrowed_on: 'Ngày Mượn',
  Assets_returned_on: 'Ngày Trả',
  Assets_approved_by: 'Người Duyệt',
  Assets_description: 'Mô Tả',
  Assets_status: 'Trạng Thái',
  Assets_bofrrow: 'Mượn Tài Sản',
  Assets_return: 'Trả Tài Sản',
  Assets_edit_asset: 'Sửa Tài Sản',
  Assets_delete_asset: 'Xóa Tài Sản',
  Assets_code: 'Mã',
  Assets_location: 'Chi Nhánh',
  Assets_filter: 'Lọc',
  Assets_filter_categories: 'Danh Mục',
  Assets_filter_reset: 'Đặt Lại',
  Assets_apply_filter: 'Áp Dụng Bộ Lọc',
  Assets_reset_filter: 'Đặt Lại Bộ Lọc',

  Assets_form_back: 'Quay lại Tài sản',
  Assets_form_new: 'Thêm',
  Assets_form_edit: 'Sửa',
  Assets_form_title: 'Tiêu Đề Nội Dung',
  Assets_form_serial_number: 'Số Seri (S/N)',
  Assets_form_purchase_date: 'Ngày Mua',
  Assets_form_purchase_value: 'Giá Trị',
  Assets_form_photo: 'Ảnh',

  Approvals_requests_onboarding: 'Yêu Cầu Onboarding',
  Approvals_requests_information_update: 'Yêu Cầu Thay Đổi Thông Tin',
  Approvals_requests_timeoff: 'Yêu Cầu Nghỉ Phép',
  Approvals_requests_assets: 'Yêu Cầu Tài Sản',
  Approvals_requests_resign: 'Yêu Cầu Từ Chức',
  Approvals_requests_settings_desc_setup: 'Thiết lập người sẽ tự động nhận tất cả',
  Approvals_requests_settings_desc_onlypeople: 'Chỉ những người trong danh sách này mới có thể phê duyệt ',
  Approvals_requests_table_title: `Người Liên Quan`,
  Approvals_requests_table_column: 'Đã Thêm Vào',
  Approvals_requests_remove: 'Gỡ',

  Holiday: 'Nghỉ Lễ',
  Holidays_new_holiday: 'Thêm Nghỉ Lễ',
  Holidays_title: 'Tiêu Đề Nghỉ Lễ',
  Holidays_applied_to: 'Áp Dụng Cho',
  Holidays_edit: 'Sửa Nghỉ Lễ',
  Holidays_delete: 'Xóa Nghỉ Lễ',
  Holidays_back_to_holidays_settings: 'Quay lại Cài Đặt Nghỉ Lễ',
  Holidays_copy_last_year: `Sao chép dữ liệu nghỉ lễ năm trước`,

  Users_title: 'Người Dùng',
  Users_invite_user: 'Mời Người Dùng',
  Users_desc_manage_all_users_in_the_system_and_invite_new_ones:
    'Quản lý tất cả người dùng trong hệ thống và mời những người mới.',
  Users_desc_all_permission_roles: 'Tất Cả Quyền Truy Cập',
  Users_desc_all_account_statuses: 'Tất Cả Trạng Thái Tài Khoản',
  Users_members: 'Thành Viên',
  Users_last_activity: 'Lần Hoạt Động Cuối',
  Users_permission_role: 'Quyền',
  Users_status: 'Trạng Thái Hoạt Động',
  Users_assign_role: 'Chỉ Định Quyền',
  Users_revoke_access: 'Thu Hồi Truy Cập',
  Users_reinvite: 'Mời Lại',
  Users_desc_changing_permission_role:
    'Việc thay đổi quyền sẽ ảnh hưởng đến truy cập của tài khoản vào một số tính năng trên hệ thống. Vui lòng cân nhắc kỹ trước khi thực hiện những thay đổi đó.',
  Users_revoke_access_desc: `sẽ không thể truy cập Acazia HR sau khi quyền bị thu hồi. Bạn có chắc chắn muốn thu hồi quyền truy cập hệ thống của tài khoản này không?`,

  CompanyRegulations_title: 'Quy Định Công Ty',
  CompanyRegulations_new_regulation: 'Tạo Mới Quy Định',
  CompanyRegulations_search_placeholder: 'Tìm kiếm Quy Định, Tác Giả,...',

  CompanyRegulationsTable_regulation_title: 'Tên Quy Định',
  CompanyRegulationsTable_category: 'Danh Mục',
  CompanyRegulationsTable_uploaded_by: 'Người Đăng',
  CompanyRegulationsTable_access_role: 'Quyền Truy Cập',
  CompanyRegulationsTable_last_update: 'Lần Cuối Cập Nhật',
  CompanyRegulationsTable_edit_category: 'Sửa Danh Mục',
  CompanyRegulationsTable_delete_category: 'Xoá Danh Mục',
  CompanyRegulationsTable_edit_regulation: 'Sửa Quy Định',
  CompanyRegulationsTable_delete_regulation: 'Xoá Quy Định',
  CompanyRegulationsTable_download_regulation: 'Tải Xuống Quy Định',
  CompanyRegulationsTable_published_on: 'Ban Hành vào',

  RegulationForm_back_to: 'Quay lại Quy Định',
  RegulationForm_create: 'Tạo Mới Quy Định',
  RegulationForm_edit: 'Sửa Quy Định',

  TimeOff_time_off_types: 'Loại Nghỉ Phép',
  TimeOff_new_time_off_request: 'Tạo Yêu Cầu Nghỉ Phép',
  TimeOff_time_off_history: 'Lịch Sử Nghỉ Phép',
  TimeOff_balance_history: 'Biến Động Phép',

  TimeOffTable_submitted_on: 'Ngày Yêu Cầu',
  TimeOffTable_time_off_type: 'Loại Nghỉ Phép',
  TimeOffTable_total_time: 'Tổng Thời Gian',
  TimeOffTable_approver: 'Người Duyệt',
  TimeOffTable_status: 'Trạng Thái',
  TimeOffTable_clone_request: 'Nhân Bản Yêu Cầu',
  TimeOffTable_cancel_request: 'Huỷ Yêu Cầu',

  BlanceTable_effective_on: 'Hiệu Lực Vào',
  BlanceTable_description: 'Nội Dung',
  BlanceTable_used: 'Sử Dụng',
  BlanceTable_accrued: 'Tích Luỹ',
  BlanceTable_balance: 'Số Dư',

  Checklists_template_desc:
    'Mẫu danh sách công việc chứa các công việc được xác định trước để phân công nhanh chóng. Chúng có thể được sử dụng cho các công việc Onboarding, Offboarding hoặc công việc thông thường.',
  Checklists_new_template: 'Bản Mẫu Mới',
  Checklists_details: 'Chi Tiết Danh Sách Kiểm Tra',
  Checklists_edit_template: 'Chỉnh Sửa Mẫu',
  Checklists_add_new_task: 'Thêm Công Việc Mới',
  Checklists_duplicate_template: 'Sao Chép Mẫu',
  Checklists_delete_template: 'Xóa Mẫu',
  Checklists_edit_task: 'Chỉnh Sửa Công Việc',
  Checklists_duplicate_task: 'Nhân Bản Công Việc',
  Checklists_delete_task: 'Xóa Công Việc',
  Checklists_select: 'Chọn “Mẫu Công Việc“ để xem chi tiết',
  Checklists_new_task: 'Công Việc Mới',
  Checklists_template: 'Mẫu Công Việc',
  Checklists_enter_template_title: 'Nhập Tiêu Đề Mẫu',
  Checklists_task_name: 'Tên Công Việc',
  Checklists_task_description: 'Mô Tả Công Việc',
  Checklists_assigned_to: 'Được Giao Cho',
  Checklists_task_category: 'Danh Mục Công Việc',
  Checklists_task_checklist: 'Danh Sách Công Việc',
  Checklists_task_priority: 'Độ Ưu Tiên Công Việc',
  Checklists_task_progress: 'Trạng Thái Công Việc',
  Checklists_assign_to: 'Được Giao Cho',
  Checklists_task_title: 'Tiêu Đề Công Việc',
  Checklists_due_date: 'Ngày Hết Hạn',
  Checklists_tasks_with_due_date: 'Các Công Việc có hạn',
  Checklists_after_assigning: 'sau khi chỉ định',
  Checklists_back_to: 'Quay lại',
  Checklists_onboarding_date: 'ngày onboarding',
  Checklists_termination_date: 'ngày kết thúc',
  Checklists_task: 'Công Việc',
  Checklists_delete_task_desc_this_tasks_cannot: 'Nhiệm vụ này không thể phục hồi sau khi bị xóa.',
  Checklists_delete_task_desc_are_you_sure: 'Bạn có chắc chắn muốn xóa tác vụ này không?',
  Checklists_assigned_to_lower_case: 'được giao cho',
  Checklists_me: 'Tôi',
  Checklists_due_on: 'Đến Hạn',
  Checklists_due_on_none: 'Không Có Hạn',
  Checklists_add_task: 'Thêm Công Việc',
  Checklists_view_full_checklist: 'Xem Tất Cả Công Việc',
  Checklists_task_delayed: 'Công Việc này đang bị chậm',
  Checklists_to_dos: 'Việc Cần Làm',
  Checklists_template_empty_placeholder: 'Chọn “Tạo Công Việc Mới“ trên cùng bên phải để thêm công việc tại đây.',

  Time_off_title: 'Nghỉ Phép',
  Time_off_type: 'Loại Nghỉ Phép',
  Time_off_new_type: 'Tạo Mới Loại Nghỉ Phép',
  Time_applicable_to: 'Áp Dụng Cho',
  Time_paid_unpaid: '	Đã Thanh Toán / Chưa Thanh Toán',
  Time_no_of_employees: 'Tổng Số Nhân Viên',
  Time_off_edit: 'Sửa',
  Time_off_delete: 'Xóa',
  Time_back_to_time_offs_settings: 'Quay Lại Cài Đặt Nghỉ Phép',
  Time_off_information: 'Thông Tin',
  Time_off_leave_title: 'Tiêu Đề',
  Time_off_leave_icon: 'Biểu Tượng',
  Time_off_desc_are_you_sure_you_want_to_delete: 'Bạn có chắc chắn muốn xóa ',
  Time_off_tooltip_a_descriptive_name_for_this_type_of: 'Tên mô tả cho loại nghỉ phép này',
  Time_off_tooltip_paid_time_off:
    'Thời gian nghỉ có lương (PTO) là khoảng thời gian mà nhân viên nghỉ làm nhưng vẫn được công ty trả lương',
  Time_off_tooltip_unpaid: 'Thời gian nghỉ không lương (UTO) là thời gian nhân viên nghỉ làm và không được trả lương',
  Time_off_tooltip_a_picture_representing: 'Một hình ảnh đại diện cho nghỉ phép',
  Time_off_policy: 'Chính Sách',
  Time_off_after_specific_occurrence: 'Sau khi xảy ra cụ thể',
  Time_off_maximum_leave: 'Nghỉ Phép Tối Đa',
  Time_off_the_maximum_period_of_time_an_employee_can_take_off_work_in_aleave_request:
    'Thời gian tối đa người lao động được nghỉ làm trong đơn xin nghỉ',
  Time_off_desc_hours_on_per_request: 'giờ trong một lần yêu cầu',
  Time_off_applied_to: 'Áp Dụng Cho',
  Time_off_tooltip_this_type_of_time_off_can_be_applicable_to_all_employees_or_a_specific_group_of_employees:
    'Loại nghỉ phép này có thể áp dụng cho tất cả nhân viên hoặc một nhóm nhân viên cụ thể',
  Time_off_employment_type: 'Loại Việc Làm',
  Time_off_accrual_rules: 'Quy Tắc Cộng Dồn',
  Time_off_assign_date: 'Chỉ Định Ngày',
  Time_off_accrual_type: 'Loại Tích Lũy',
  Time_off_tooltip_the_accrual_type_which_the_time_off_type_is_applicable_to_employees:
    'Kiểu tích lũy mà loại nghỉ phép áp dụng cho nhân viên',
  Time_off_tooltip_this_rule_would_be_applied_once: 'Quy tắc này sẽ được áp dụng một lần',
  Time_off_tooltip_this_rule_would_be_applied_automatically: 'Quy tắc này sẽ được áp dụng tự động',
  Time_off_every: 'Mọi',
  Time_off_accrue: 'Tích Lũy',
  Time_off_hours: 'giờ',
  Time_start_on: 'Bắt Đầu',
  Time_end_on: 'Kết Thúc Vào',
  Time_day_of_month: 'ngày trong tháng',
  Time_add_level: 'Thêm Cấp Độ',
  Time_carry_over: 'Chuyển Giao Phép Thừa',
  Time_entitlement: 'Tổng Phép',
  Time_requested: 'Đã Sử Dụng',
  Time_carry_over_report: 'Số Dư Nghỉ Phép',
  Time_maximum_carry_over: 'Số Phép Chuyển Tối Đa',
  Time_carry_over_expiration: 'Ngày Hết Hạn',
  Time_off_tooltip_maximum_number: 'Số ngày tối đa nhân viên có thể chuyển từ năm này sang năm khác',
  Time_off_tooltip_the_date: 'Ngày mà tất cả số ngày được tích lũy trong năm hiện tại sẽ không còn sử dụng được',

  MyAssets: 'Tài Sản Của Tôi',
  MyAssets_new_assets_request: 'Yêu Cầu Tài Sản',
  MyAssets_assets_history: 'Lịch Sử Tài Sản',

  RequestForm_back_to: 'Quay lại Yêu Cầu',
  RequestForm_new_resign_request: 'Yêu Cầu Thôi Việc Mới',
  RequestForm_new_contract_termination_request: 'Yêu Cầu Chấm Dứt Hợp Đồng Mới',
  RequestForm_new_probation_termination_request: 'Yêu Cầu Chấm Dứt Thử Việc Mới',
  RequestForm_make_request_on_behalf: 'Thay mặt Yêu Cầu',
  RequestForm_requester: 'Người Yêu Cầu',
  RequestForm_approver: 'Người Duyệt',
  RequestForm_other_recipients: 'Người Nhận Khác',
  RequestForm_resign_request_reason: 'Lý Do Thôi Việc',
  RequestForm_contract_termination_request_reason: 'Lý Do Chấm Dứt Hợp Đồng',
  RequestForm_probation_termination_request_reason: 'Lý Do Chấm Dứt Thử Việc',
  RequestForm_last_working_day: 'Ngày Làm Việc Cuối Cùng',
  RequestForm_resign_request: 'Yêu Cầu Thôi Việc',
  RequestForm_contract_termination: 'Yêu Cầu Chấm Dứt Hợp Đồng',
  RequestForm_probation_termination: 'Yêu Cầu Chấm Dứt Thử Việc',
  RequestForm_on_behalf: 'Thay Mặt',
  RequestForm_created_by: 'Người Tạo',
  RequestForm_attached_file: 'Tệp Đính Kèm',
  RequestForm_borrowing_request: 'Yêu Cầu Mượn Tài Sản',
  RequestForm_returning_requeset: 'Yêu Cầu Trả Tài Sản',
  RequestForm_new_borrowing_request: 'Yêu Cầu Mượn Tài Sản Mới',
  RequestForm_new_returning_requeset: 'Yêu Cầu Trả Tài Sản Mới',
  RequestForm_borrow_on: 'Mượn Vào',
  RequestForm_return_on: 'Trả Vào',
  RequestForm_borrowing_reason: 'Lý Do Mượn',
  RequestForm_returning_reason: 'Lý Do Trả',
  RequestForm_borrowed_assets: 'Tài Sản Được Mượn',
  RequestForm_returned_assets: 'Tài Sản Được Trả',
  RequestForm_total_requested_assets: 'Tổng Số Tài Sản Yêu Cầu',
  RequestForm_clear_section: 'Xoá Lựa Chọn',
  RequestForm_time_off_request: 'Yêu Cầu Nghỉ Phép',
  RequestForm_new_time_off_request: 'Yêu Cầu Nghỉ Phép Mới',
  RequestForm_time_off_type: 'Loại Nghỉ Phép',
  RequestForm_time_off_reason: 'Lý Do Nghỉ',
  RequestForm_requested_time_off: 'Thời Gian Yêu Cầu Nghỉ',
  RequestForm_total_requested_hrs: 'Tổng số giờ yêu cầu',
  RequestForm_remaining_hrs: 'Tổng số giờ còn lại',

  PreviewEmail_preview_request: 'Xem Trước Yêu Cầu',
  PreviewEmail_reviewed_request: 'Yêu Cầu Được Duyệt',
  PreviewEmail_canceled_request: 'Yêu Cầu Đã Huỷ',
  PreviewEmail_processing_request: 'Yêu Cầu Đang Thực Hiện',
  PreviewEmail_pending_request: 'Yêu Cầu Đang Chờ',
  PreviewEmail_from: 'từ',
  PreviewEmail_to: 'đến',

  RequestManagement_my_requests: 'Yêu Cầu Của Tôi',
  RequestManagement_my_requests_des: 'Đây là những yêu cầu bạn đã gửi hoặc người khác đã gửi thay bạn.',
  RequestManagement_assigned_to_me: 'Được Giao Cho Tôi',
  RequestManagement_assigned_to_me_des: 'Yêu cầu trong phần này cần sự chấp thuận của bạn.',
  RequestManagement_cc_to_me: 'Liên Quan Đến Tôi',
  RequestManagement_cc_to_me_des: 'Các yêu cầu trong phần này có bạn là một trong những người nhận.',
  RequestManagement_new_request: 'Yêu Cầu Mới',

  RequestsStatusManagement_pending: 'Đang Chờ',
  RequestsStatusManagement_completed: 'Hoàn Thành',
  RequestsStatusManagement_my_tasks: 'Công Việc Của Tôi',
  RequestsStatusManagement_tasks_i_assigned: 'Công Việc Tôi Giao',

  RequestsManagementNavigation_all_requests: 'Tất Cả Yêu Cầu',
  RequestsManagementNavigation_onboarding_requests: 'Yêu Cầu Onboarding',
  RequestsManagementNavigation_information_update_requests: 'Yêu Cầu Thay Đổi Thông Tin',
  RequestsManagementNavigation_time_off_requests: 'Yêu Cầu Nghỉ Phép',
  RequestsManagementNavigation_assets_requests: 'Yêu Cầu Tài Sản',
  RequestsManagementNavigation_assets_borrowing_requests: 'Yêu Cầu Mượn Tài Sản',
  RequestsManagementNavigation_assets_returning_requests: 'Yêu Cầu Trả Tài Sản',
  RequestsManagementNavigation_resign_requests: 'Yêu Cầu Thôi Việc',
  RequestsManagementNavigation_contract_termination_requests: 'Yêu Cầu Chấm Dứt Hợp Đồng',
  RequestsManagementNavigation_probation_termination_requests: 'Yêu Cầu Chấm Dứt Thử Việc',

  RequestsManagementTable_from: 'từ',
  RequestsManagementTable_to: 'đến',
  RequestsManagementTable_on: 'vào',
  RequestsManagementTable_on_upper_case: 'Vào',
  RequestsManagementTable_has_requested: 'đã yêu cầu',
  RequestsManagementTable_has_reviewed_this_request: 'đã duyệt yêu cầu.',
  RequestsManagementTable_has_not_been_reviewed: 'Yêu cầu chưa được duyệt.',
  RequestManagementTable_asset_borrowing: 'Mượn Tài Sản',
  RequestManagementTable_asset_returning: 'Trả Tài Sản',
  RequestManagementTable_resign: 'Thôi Việc',
  RequestManagementTable_contract_termination: 'Chấm Dứt Hợp Đồng',
  RequestManagementTable_probation_termination: 'Chấm Dứt Thử Việc',
  RequestManagementTable_information_update: 'Thay Đổi Thông Tin',
  RequestManagementTable_onboarding: 'Onboarding',
  RequestManagementTable_offboarding: 'Offboarding',

  RequestsManagementDetail_pending: 'Yêu cầu này không thể được sử dụng vì nó chưa được duyệt.',
  RequestsManagementDetail_completed: 'Yêu cầu này đã được áp dụng.',
  RequestsManagementDetail_of: 'trong',
  RequestsManagementDetail_requests: 'yêu cầu',
  RequestsManagementDetail_onboarding_review_request: 'Yêu Cầu Duyệt Onboarding',
  RequestsManagementDetail_clone_request: 'Nhân Bản Yêu Cầu',
  RequestsManagementDetail_cancel_request: 'Huỷ Yêu Cầu',
  RequestsManagementDetail_confirm_request_review: 'Xác Nhận Đánh Giá Yêu Cầu',
  RequestsManagementDetail_approve_request: 'Duyệt Yêu Cầu',
  RequestsManagementDetail_old_value: 'Thông Tin Cũ',
  RequestsManagementDetail_new_value: 'Thông Tin Mới',
  RequestsManagementDetail_approve_all: 'Phê Duyệt Tất Cả',
  RequestsManagementDetail_reject_all: 'Từ Chối Tất Cả',
  RequestsManagementDetail_hr_comment: `Lời Nhắn của HR`,

  BoardingTable_employee_name: 'Tên Nhân Viên',
  BoardingTable_onboard_on: 'Ngày Onboard',
  BoardingTable_onboarding_status: 'Trạng Thái Onboarding',
  BoardingTable_offboard_on: 'Ngày Offboard',
  BoardingTable_offboarding_status: 'Trạng Thái Offboarding',
  BoardingTable_assign_onboarding_checklist: 'Giao Công Việc Onboarding',
  BoardingTable_assign_offboarding_checklist: 'Giao Công Việc Offboarding',
  BoardingTable_review_onboarding_information: 'Đánh Giá Thông Tin Onboarding',
  BoardingTable_edit_onboarding_checklist: 'Sửa Công Việc Onboarding',
  BoardingTable_edit_offboarding_checklist: 'Sửa Công Việc Offboarding',
  BoardingTable_empty_placeholder: 'Nhân Viên này chưa được chỉ định công việc.',
  BoardingTable_completed_tasks: 'Công Việc Hoàn Thành',

  ModalSelectPeople_select_employee: 'Chọn Nhân Viên',
  ModalSelectPeople_select_sub_leaders: 'Chọn Phó Nhóm',
  ModalSelectPeople_select_assignee: 'Chọn Người Giao',
  ModalSelectPeople_your_line_manager: 'Quản Lý Của Bạn',

  Give_us_feedback_title: 'Chia sẻ những suy nghĩ của bạn',
  Give_us_feedback_what_would_you_like: 'Bạn muốn viết điều gì',
  Give_us_feedback_acaziahr_can_contact_me_about_this_feedback: 'AcaziaHR có thể liên hệ với tôi về phản hồi này',
  Give_us_feedback_like_to_participate_in_product_research: `Tôi muốn tham gia nghiên cứu sản phẩm`,
  Give_us_send_feedback: 'Gửi Phản Hồi',

  AssignOnboardingOffboarding_onboarding_title: 'Chỉ Định Công Việc Onboarding',
  AssignOnboardingOffboarding_offboarding_title: 'Chỉ Định Công Việc Offboarding',
  AssignOnboardingOffboarding_onboarding_des: 'Chỉ định danh sách công việc Onboarding cho nhân viên',
  AssignOnboardingOffboarding_offboarding_des: 'Chỉ định danh sách công việc Offboarding cho nhân viên',
  AssignOnboardingOffboarding_select_template: 'Chọn Mẫu',
  AssignOnboardingOffboarding_hr_in_charge: 'HR Phụ Trách',
  AssignOnboardingOffboarding_template_description: 'Mô Tả',

  ModalApproveRequest_confirm_review_request: 'Chấp Nhận Đánh Giá Yêu Cầu',
  ModalApproveRequest_approve_request: 'Duyệt Yêu Cầuu',
  ModalApproveRequest_des:
    'sẽ được thông báo về việc phê duyệt yêu cầu này sau khi được xác nhận. Vui lòng kiểm tra tất cả các thông tin trước khi xác nhận để tránh những sai lầm trong tương lai.',

  ModalCancelRequest_title: 'Huỷ Bỏ Yêu Cầu',
  ModalCancelRequest_des_1:
    'Nhân sự, Người quản lý trực tiếp và người liên quan của bạn sẽ được thông báo về việc hủy yêu cầu của bạn. Yêu cầu này không thể được kích hoạt lại sau khi hủy bỏ.',
  ModalCancelRequest_des_2: 'Bạn có chắc chắn muốn hủy yêu cầu này không?',

  ModalDeleteAsset_des_1: 'Bạn sắp xóa tài sản',
  ModalDeleteAsset_des_2: 'Sau khi bị xóa, tài sản này không thể phục hồi được.',
  ModalDeleteAsset_des_3: 'Bạn có chắc chắn muốn xóa nội dung này không?',

  ModalDeleteRequest_title: 'Xoá Yêu Cầu',
  ModalDeleteRequest_des: 'Yêu Cầu này không thể phục hồi sau khi đã xóa. Bạn có chắc chắn muốn xóa Yêu Cầu này không?',

  ModalDeleteTask_title: 'Xoá Công Việc',
  ModalDeleteTask_des:
    'Công Việc này không thể phục hồi sau khi đã xóa. Bạn có chắc chắn muốn xóa Công Việc này không?',

  ModalDeleteCompanyRegulations_title: 'Xoá Quy Định',
  ModalDeleteCompanyRegulations_des1: 'Quy Định này không thể phục hồi sau khi đã xóa.',
  ModalDeleteCompanyRegulations_des2: 'Bạn có chắc chắn muốn xóa Quy Định này?',

  ModalDeleteCompanyRegulationsCategory_title: 'Xoá Danh Mục Quy Định',
  ModalDeleteCompanyRegulationsCategory_des1: 'Danh Mục Quy Định này không thể phục hồi sau khi bị xóa.',
  ModalDeleteCompanyRegulationsCategory_des2: 'Bạn có chắc chắn muốn xóa Danh Mục Quy Định này?',

  ModalInvitationSent_title: 'Đã Gửi Lời Mời',
  ModalInvitationSent_back_to_people: 'Quay lại Nhân Viên',
  ModalInvitationSent_go_to_checklist: 'Đi đến Công Việc',
  ModalInvitationSent_des1: 'Lời mời tạo tài khoản HRM đã được gửi đến',
  ModalInvitationSent_des2: 'Sau khi được chấp nhận, người dùng này sẽ được thêm vào Danh sách Công Việc Onboarding.',

  ModalDeleteDependant_title: 'Xoá Phòng Ban',
  ModalDeleteDependant_des: 'Bạn có chắc chắn muốn xoá Phòng Ban này?',

  ModalDeleteEmergencyContact_title: 'Xoá Liên Hệ',
  ModalDeleteEmergencyContact_des: 'Bạn có chắc chắn muốn xoá Liên Hệ này?',

  ModalSubmitProfileUpdate_title: 'Yêu Cầu Cập Nhật Hồ Sơ',
  ModalSubmitProfileUpdate_des:
    'Bạn chuẩn bị gửi Yêu Cầu Cập Nhật Hồ Sơ. Mọi thay đổi sẽ được HR/Admin xem xét trước khi có hiệu lực. Vui lòng kiểm tra kỹ lưỡng tất cả các cập nhật của bạn trước khi gửi.',

  ModalAdjustBlance_amount: 'Số Lượng',
  ModalAdjustBlance_effective_date: 'Ngày Hiệu Lực',
  ModalAdjustBlance_note: 'Ghi Chú',
  ModalAdjustBlance_summary: 'Tổng',

  ModalCancelRequestTimeOff_title: 'Huỷ Bỏ Yêu Cầu Nghỉ Phép',
  ModalCancelRequestTimeOff_des1:
    'Người yêu cầu và những người nhận khác sẽ được thông báo về việc hủy bỏ yêu cầu của bạn. Yêu cầu này không thể được kích hoạt lại sau khi bị hủy.',

  ModalDeleteApprover_title_approver: 'Loại Bỏ Người Duyệt',
  ModalDeleteApprover_title_recipient: `Loại Bỏ Người Liên Quan`,
  ModalDeleteApprover_des_approver: 'Bạn có chắc chắn muốn loại bỏ Người Duyệt',
  ModalDeleteApprover_des_recipient: `Bạn có chắc chắn muốn loại bỏ Người Liên Quan`,

  ModalNewCoverageOption_title: 'Đối Tượng Hưởng Mới',

  ModalDeleteTemplate_title: 'Xoá Mẫu Công Việc',
  ModalDeleteTemplate_des1: 'Bạn sắp xoá Mẫu Công Việc',
  ModalDeleteTemplate_des2: 'Sau khi bị xóa, mẫu này và các công việc của mẫu không thể phục hồi được.',
  ModalDeleteTemplate_des3: 'Bạn có chắc chắn muốn xóa Mẫu Công Việc này không?',

  ModalDeleteHoliday_title: 'Xoá Kỳ Nghỉ',
  ModalDeleteHoliday_des: 'Bạn có chắc chắn muốn xoá Kỳ Nghỉ',

  Benefits_view_benefit_group: 'Xem Nhóm Quyền Lợi',

  BenefitDetail_general_info: 'Thông Tin Chung',
  BenefitDetail_coverage_option: 'Đối Tượng Hưởng',
  BenefitDetail_add_coverage_option: 'Thêm Đối Tượng Hưởng',
  BenefitDetail_benefit_title: 'Tiêu Đề Quyền Lợi',
  BenefitDetail_benefit_type: 'Kiểu Quyền Lợi',
  BenefitDetail_benefit_type_monetary_des: 'Loại Phúc lợi này yêu cầu đóng góp bằng tiền từ Nhân viên và/hoặc Công ty.',
  BenefitDetail_benefit_type_non_monetary_des:
    'Loại Phúc lợi này không yêu cầu đóng góp bằng tiền từ Nhân viên và/hoặc Công ty.',
  BenefitDetail_benefit_description: 'Mô Tả',
  BenefitDetail_benefit_details: 'Chi Tiết Quyền Lợi',
  BenefitDetail_benefit_status: 'Trạng Thái',
  BenefitDetail_back_to_benefits: 'Quay lại Cài Đặt Quyền Lợi',
  BenefitDetail_new: 'Tạo Mới',
  BenefitDetail_edit: 'Sửa',
  BenefitDetail_benefit: 'Quyền Lợi',
  BenefitDetail_benefit_eligibility_and_cost: 'Quyền Lợi Và Chi Phí',
  BenefitDetail_group: 'Nhóm',
  BenefitDetail_group_title: 'Tiêu Đề Nhóm',
  BenefitDetail_group_description: 'Mô Tả',
  BenefitDetail_group_eligible_employees: 'Nhân Viên Đủ Điều Kiện',
  BenefitDetail_group_eligibility_start: 'Quyền Lợi Khi Đủ Điều Kiện Bắt Đầu...',
  BenefitDetail_group_eligibility_end: 'Quyền Lợi Khi Đủ Điều Kiện Kết Thúc...',
  BenefitDetail_group_set_period: 'Đặt Thời Gian',
  BenefitDetail_group_benefit_cost: 'Chi Phí Quyền Lợi',
  BenefitDetail_group_benefit_cost_des: `Bạn có thể chỉnh sửa Chi phí Quyền Lợi riêng lẻ trong Hồ Sơ Cá Nhân của Nhân Viên`,
  BenefitDetail_group_benefit_cost_incur: 'Thời Gian Áp Dụng Quyền Lợi...',
  BenefitDetail_group_total_cost: 'Tổng Chi Phí',
  BenefitDetail_group_employee_pays: 'Nhân Viên Trả',
  BenefitDetail_group_company_pays: 'Công Ty Trả',

  ModalViewBenefitGroup_summary: 'Tóm Tắt',
  ModalViewBenefitGroup_employees_in_this_group_title: 'Nhân Viên Trong Nhóm',
  ModalViewBenefitGroup_employees_in_this_group: 'nhân viên đủ điều kiện trong nhóm',

  Notifications_welcome_to: 'Chào mừng đến với',
  Notifications_welcome_you_to: 'gửi lời chào bạn đến với',
  Notifications_congratulations_on: 'chúc mừng',
  Notifications_your_celebration: 'Lễ Kỷ Niệm của bạn !',
  Notifications_has_submitted: 'đã gửi',
  Notifications_update_information_request: 'đã cập nhật thông tin cho',
  Notifications_change_employee_type: 'đã thay đổi hình thức công việc cho',
  Notifications_change_user_status: 'đã thay đổi trạng thái nhân viên cho',
  Notifications_has_approved: 'đã duyệt',
  Notifications_has_canceled: 'đã huỷ',
  Notifications_assigned_you: 'chỉ định cho bạn',

  WelcomeMemberNote_title: 'Gửi Lời Chào',
  WelcomeMemberNote_des: 'Nói lời chào với đồng nghiệp mới của bạn',
  WelcomeMemberNote_placeholder: 'Chào mừng bạn đến với Acazia Software!',
  WelcomeMemberNote_add_image: 'Thêm Ảnh',
  WelcomeMemberNote_send: 'Gửi',

  CelebrationNote_title: 'Lễ Chúc Mừng',
  CelebrationNote_placeholder: 'Chúc mừng sinh nhật Bạn ! / Chúc mừng lễ kỷ niệm !',
  CelebrationNote_des: 'Nói lời chúc mừng với đồng nghiệp của bạn',

  Reports_headcounts: 'Số Lượng Nhân Viên',
  Reports_staff_turnover: 'Lượng Vào/Ra Nhân Viên',
  Reports_department: 'Bộ Phận',
  Reports_employee_type: 'Hình Thức Công Việc',

  BenefitsOverviewTable_title: 'Tổng Quan Quyền Lợi',
  BenefitsOverviewTable_benefit: 'Quyền Lợi',
  BenefitsOverviewTable_coverage: 'Đối Tượng Hưởng',
  BenefitsOverviewTable_employee_pays: 'Nhân Viên Trả',
  BenefitsOverviewTable_company_pays: 'Công Ty Trả',
  BenefitsOverviewTable_frequency: 'Kỳ Hạn',
  BenefitsOverviewTable_effective_on: 'Có Hiệu Lực Vào',

  BenefitsHistoryTable_title: 'Lịch Sử Quyền Lợi',
  BenefitsHistoryTable_date: 'Ngày',
  BenefitsHistoryTable_event: 'Sự Kiện',
  BenefitsHistoryTable_changed_by: 'Thay Đổi Bởi',

  Report_back_to_report: 'Quay lại Báo Cáo',
  Report_total_employees: 'TỔNG NHÂN VIÊN',
  Report_last_6_months: '6 THÁNG GẦN ĐÂY',
  Report_headcount_title: 'Thống Kê Nhân Viên (Hiện Tại)',
  Report_headcount_des: 'Phân tích tất cả thông tin của nhân viên viên hiện tại',
  Report_onboarding_title: 'Onboarding',
  Report_onboarding_des: 'Nhân viên Onboarding theo tháng kể từ Ngày Tham Gia của họ.',
  Report_offboarding_title: 'Offboarding',
  Report_offboarding_des: 'Nhân viên Offboarding hoặc không hoạt động theo tháng kể từ Ngày Làm Việc Cuối của họ.',
  Report_time_off_balance_title: 'Số Dư Nghỉ Phép',
  Report_time_off_balance_des: 'Tổng quan về số dư nghỉ phép của nhân viên.',
  Report_time_off_schedule_title: 'Lịch Nghỉ Phép',
  Report_time_off_schedule_des: 'Tổng quan về lịch nghỉ phép của nhân viên',
  Report_employee_data_title: 'Báo Cáo Dữ Liệu Nhân Viên',
  Report_employee_data_des: 'Tổng quan về thông tin của nhân viên',
  Report_employee_turnover_title: 'Tỉ Lệ Luân Chuyển',
  Report_employee_turnover_des:
    'Tổng quan về thông tin tỉ lệ nhân viên Nghỉ Việc so với nhân viên Đang Làm Việc tại công ty',
  Report_assets_in_used_title: 'Tài Sản Đang Sử Dụng',
  Report_assets_in_used_des:
    'Tài sản trong báo cáo đã sử dụng sẽ chỉ liệt kê nội dung đang hoạt động, do đó nếu bạn cũng muốn xem nội dung đã hủy kích hoạt thì không thể sử dụng báo cáo.',

  ModalEditAssignChecklist_title: 'Sửa Chỉ Định Công Việc',
  ModalEditAssignChecklist_des1:
    'Một số công việc trong danh sách công việc này có thể đã được hoàn thành. Tất cả tiến độ công việc sẽ được đặt lại và không thể phục hồi sau khi danh sách công việc này được chỉnh sửa.',
  ModalEditAssignChecklist_des2: 'Bạn có chắc chắn muốn chỉnh sửa danh sách công việc này không?',

  Notification_edit_asset_successfully: 'Chỉnh Sửa Nội Dung Thành Công',
  Notification_create_asset_successfully: 'Tạo Thành Công',
  Notification_successfully: 'Thành Công',
  Notification_assign_checklists_successfully: 'Danh Sách Thành Công',
  Notification_send_feedback_successfully: 'Gửi Phản Hồi Thành Công',
  Notification_update_regulation_successfully: 'Cập Nhật Quy Định Thành Công',
  Notification_create_regulation_successfully: 'Tạo Quy Định Thành Công',
  Notification_confirm_review_request_successfully: 'Xác Nhận Yêu Cầu Đánh Giá Thành Công',
  Notification_approve_request_successfully: 'Phê Duyệt Yêu Cầu Thành Công',
  Notification_cancel_request_successfully: 'Hủy Yêu Cầu Thành Công',
  Notification_update_task_successfully: 'Cập Nhật Nhiệm Vụ Thành Công',
  Notification_create_task_successfully: 'Tạo Nhiệm Vụ Thành Công',
  Notification_create_task_category_successfully: 'Tạo Danh Mục Nhiệm Vụ Thành Công',
  Notification_create_timeoff_request_successfully: 'Tạo Yêu Cầu Thời Gian Nghỉ Thành Công',
  Notification_change_asset_status_successfully: 'Thay Đổi Trạng Thái Nội Dung Thành Công ',
  Notification_delete_asset_successfully: 'Xóa Nội Dung Thành Công',
  Notification_create_asset_category_successfully: 'Tạo Danh Mục Nội Dung Thành Công',
  Notification_delete_request_successfully: 'Xóa Yêu Cầu Thành Công',
  Notification_delete_regulation_successfully: 'Xóa Quy Định Thành Công',
  Notification_delete_regulation_category_successfully: 'Xóa Danh Mục Quy Định Thành Công',
  Notification_update_regulate_category_successfully: 'Cập Nhật Điều Chỉnh Danh Mục Thành Công ',
  Notification_create_regulate_category_successfully: 'Tạo Điều Chỉnh Danh Mục Thành Công',
  Notification_send_message_successfully: 'Gửi Tin Nhắn Thành Công',
  Notification_invite_employee_successfully: 'Mời Nhân Viên Thành Công',
  Notification_login_successfully: 'Đăng Nhập Thành Công',
  Notification_delete_report_violation_successfully: 'Xóa Báo Cáo Vi Phạm Thành Công',
  Notification_import_employees_successfully: 'Nhập Nhân Viên Thành Công',
  Notification_report_violations_successfully: 'Báo Cáo Vi Phạm Thành Công',
  Notification_update_information_successfully: 'Cập Nhật Thông Tin Thành Công',
  Notification_request_update_information_successfully: 'Yêu Cầu Cập Nhật Thông Tin Thành Công',
  Notification_adjust_balance_successfully: 'Điều Chỉnh Số Dư Thành Công',
  Notification_add_approver_successfully: 'Thêm Người Phê Duyệt Thành Công',
  Notification_remove_approver_successfully: ' Xóa Người Phê Duyệt Thành Công',
  Notification_create_benefit_successfully: 'Tạo Lợi Ích Thành Công',
  Notification_update_benefit_successfully: 'Cập Nhật Lợi Ích Thành Công',
  Notification_delete_benefit_successfully: 'Xóa Lợi Ích Thành Công',
  Notification_update_branch_successfully: 'Cập Nhật Chi Nhánh Thành Công ',
  Notification_create_branch_successfully: 'Tạo Chi Nhánh Thành Công',
  Notification_duplicate_template_successfully: 'Sao Chép Mẫu Thành Công',
  Notification_duplicate_task_successfully: 'Nhân Đôi Nhiệm Vụ Thành Công',
  Notification_delete_task_successfully: 'Xóa Tác Vụ Thành Công',
  Notification_delete_template_successfully: 'Xóa Mẫu Thành Công',
  Notification_update_template_successfully: 'Cập Nhật Mẫu Thành Công',
  Notification_create_template_successfully: 'Tạo Mẫu Thành Công',
  Notification_company_infomation_saved_successfully: 'Thông Tin Công Ty Được Lưu Thành Công',
  Notification_delete_branch_successfully: 'Xóa Chi Nhánh Thành Công',
  Notification_delete_department_successfully: 'Xóa Phòng Thành Công',
  Notification_update_configure_successfully: 'Cập Nhật Cấu Hình Thành Công',
  Notification_activated_employment_type_successfully: 'Đã Kích Hoạt Loại Công Việc Thành Công',
  Notification_deactivate_employment_type_successfully: 'Hủy Kích Hoạt Loại Việc Làm Thành Công',
  Notification_delete_employment_type_successfully: 'Xóa Loại Việc Làm Thành Công',
  Notification_activated_job_title_successfully: 'Chức Danh Công Việc Được Kích Hoạt Thành Công',
  Notification_deactivate_job_title_successfully: 'Hủy Kích Hoạt Chức Danh Thành Công',
  Notification_delete_job_title_successfully: 'Xóa Chức Danh Công Việc Thành Công',
  Notification_update_department_successfully: 'Cập Nhật Bộ Phận Thành Công',
  Notification_create_department_successfully: 'Thêm Bộ Phận Thành Công',
  Notification_update_employment_type_successfully: 'Cập Nhật Loại Việc Làm Thành Công',
  Notification_create_employment_type_successfully: 'Tạo Loại Việc Làm Thành Công',
  Notification_setting_saved_successfully: 'Cài Đặt Đã Lưu Thành Công',
  Notification_update_holiday_successfully: 'Cập Nhật Kỳ Nghỉ Thành Công',
  Notification_create_holiday_successfully: 'Thêm Kỳ Nghỉ Thành Công',
  Notification_delete_holiday_successfully: 'Xóa Kỳ Nghỉ Thành Công',
  Notification_update_job_title_successfully: 'Cập Nhật Công Việc Thành Công',
  Notification_create_job_title_successfully: 'tạo Công Việc Thành Công',
  Notification_update_timeoff_successfully: 'Cập Nhật Thời Gian Nghỉ Thành Công',
  Notification_create_timeoff_successfully: 'Tạo Thời Gian Nghỉ Thành Công',
  Notification_delete_timeoff_successfully: 'Xóa Thời Gian Nghỉ Thành Công',
  Notification_reinvite_user_successfully: 'Mời Lại Người Dùng Thành Công',
  Notification_add_user_roles_successfully: 'Thêm Vai Trò Người Dùng Thành Công',
  Notification_revoce_access_user_successfully: 'Thu Hồi Quyền Truy Cập Người Dùng Thành Công',
  Notification_create_role_successfully: 'Tạo Quyền Hạn Mới Thành Công',
  Notification_update_role_successfully: 'Cập Nhật Quyền Hạn Thành Công',
  Notification_delete_role_successfully: 'Xoá Quyền Hạn Thành Công',
  Notification_add_member_role_successfully: 'Thêm Vai Trò Nhân Viên Thành Công',
  Notification_update_role_permission_successfully: 'Cập Nhật Vai Trò Quyền Hạn Thành Công',
  Notification_remove_member_successfully: 'Xoá Nhân Viên Thành Công',

  ValidationRules_required: 'Trường này là bắt buộc',
  ValidationRules_max: `Nhập số nhỏ hơn hoặc bằng`,
  ValidationRules_min: `Nhập số lớn hơn hoặc bằng`,
  ValidationRules_minLength: `Nhập ký tự ít nhất`,
  ValidationRules_maxLength: `Nhập ký tự nhiều nhất`,
  ValidationRules_email: 'Email không hợp lệ',
  ValidationRules_noSpecialKey: 'Trường này không được chứa ký tự đặc biệt',
  ValidationRules_onlyAlphabetic: 'Trường này chỉ chứa các ký tự chữ cái',
  ValidationRules_onlyNumeric: 'Trường này chỉ chứa các ký tự số',
  ValidationRules_onlyAlphanumerial: 'Trường này chỉ chứa các ký tự chữ số và chữ cái',
  ValidationRules_domain: 'Tên miền không hợp lệ',
  ValidationRules_url: 'Địa chỉ URL không hợp lệ',
  ValidationRules_confirmPassword: 'Trường này không khớp với trường mật khẩu',
  ValidationRules_rangesDigits: `Trường này phải có`,
  ValidationRules_rangesDigits_or: ` hoặc `,
  ValidationRules_rangesDigits_digits: `chữ số`,
  ValidationRules_maxSizeFiles: `Kích thước tệp phải bằng hoặc nhỏ hơn`,
  ValidationRules_timeOffHours: `Định dạng giờ không hợp lệ`,
  ValidationRules_maxTimeLeaveHours: `Nhập tổng số giờ yêu cầu ít hơn hoặc bằng thời gian nghỉ phép tối đa:`,
  ValidationRules_maxRemainingHours: `Nhập tổng số giờ yêu cầu ít hơn hoặc bằng số giờ còn lại`,

  Permissions_title: 'Phân Quyền',
  Permissions_des: `Cho phép hoặc hạn chế quyền truy cập của nhân viên vào các tính năng của hệ thống`,
  Permissions_roles: 'Vai Trò Cấp Phép',
  Permissions_new_role: 'Tạo Quyền Hạn',
  Permissions_edit_role: 'Sửa Quyền Hạn',
  Permissions_new_permission_role: 'Tạo Quyền Hạn',
  Permissions_edit_permission_role: 'Sửa Quyền Hạn',
  Permissions_delete_role: 'Xoá Quyền Hạn',
  Permissions_title_role_placeholder: 'Nhập Tiêu Đề Quyền Hạn',
  Permissions_description_role_placeholder: 'Nhập Mô Tả Quyền Hạn',
  Permissions_detail: 'Chi Tiết Quyền Hạn',

  PermissionFeatureMethod_view_only: 'Xem',
  PermissionFeatureMethod_view_and_edit: 'Xem và Sửa',
  PermissionFeatureMethod_no_access: 'Không Truy Cập',

  FeatureGroupTitle_manager_requests: 'Quản Lý Yêu Cầu',
  FeatureGroupTitle_manager_reports: 'Quản Lý Báo Cáo',
  FeatureGroupTitle_tasks_checklist: 'Quản Lý Công Việc',
  FeatureGroupTitle_manager_employees: 'Quản Lý Nhân Viên',
  FeatureGroupTitle_employee_view_2nd: 'Danh Sách Nhân Viên',
  FeatureGroupTitle_adjust_time_off_balance: 'Chỉnh Sửa Số Dư Nghỉ Phép',
  FeatureGroupTitle_other: 'Khác',
  FeatureGroupTitle_quick_action: 'Thao Tác Nhanh',
  FeatureGroupTitle_all_self_information: 'Tất Cả Thông Tin Bản Thân',

  ModalDeleteRole_title: 'Xoá Quyền Hạn',
  ModalDeleteRole_des1: 'Quyền Hạn không thể khôi phục vai trò và cài đặt quyền của nó sau khi đã xóa.',
  ModalDeleteRole_des2: 'Vai trò này hiện được giao cho',
  ModalDeleteRole_des3:
    'thành viên. Sau khi bị xóa, vai trò của các thành viên này sẽ tự động được chuyển thành “Nhân viên”. Không thể khôi phục vai trò này và cài đặt quyền của nó.',
  ModalDeleteRole_des4: 'Bạn có chắc chắn muốn xóa vai trò này?',

  ModalRemoveMember_title: 'Xoá Nhân Viên',
  ModalRemoveMember_des1: 'Bạn sắp xóa',
  ModalRemoveMember_des2: 'khỏi vai trò cấp phép',
  ModalRemoveMember_des3: 'Vai trò của thành viên này sẽ được chuyển sang “Nhân viên“ sau khi việc xóa được xác nhận.',
  ModalRemoveMember_des4: 'Bạn có chắc chắn muốn xóa thành viên này khỏi quyền hạn',

  ModalChangePermission_title: 'Thay Đổi Cài Đặt Quyền Hạn',
  ModalChangePermission_des1: 'Bạn đang thực hiện một số thay đổi đối với vai trò',
  ModalChangePermission_des2:
    'cài đặt quyền. Những thay đổi này sẽ được áp dụng cho tất cả các thành viên được chỉ định là',
  ModalChangePermission_des3: 'Bạn có chắc chắn muốn thay đổi cài đặt Quyền Hạn không?',

  ModalChangeUserPermission_title: 'Thay Đổi Vai Trò Người Dùng',
  ModalChangeUserPermission_des1:
    'Việc thay đổi quyền hạn sẽ ảnh hưởng đến khả năng truy cập vào hệ thống để thực hiện các chức năng.',
  ModalChangeUserPermission_des2: 'Bạn có chắc chắn muốn thay đổi quyền hạn từ',
};

export default dataSources;

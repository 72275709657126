import { createActionCreator } from 'deox';

import {
  TUpdateRolePermissionMaterials,
  TUpdateRolePermissionResponse,
} from '@/services/api/role/update-role-permission';

// CONSTANTS

export enum EUpdateRolePermissionAction {
  UPDATE_ROLE_PERMISSION = 'UPDATE_ROLE_PERMISSION',
  UPDATE_ROLE_PERMISSION_REQUEST = 'UPDATE_ROLE_PERMISSION_REQUEST',
  UPDATE_ROLE_PERMISSION_SUCCESS = 'UPDATE_ROLE_PERMISSION_SUCCESS',
  UPDATE_ROLE_PERMISSION_FAILED = 'UPDATE_ROLE_PERMISSION_FAILED',
}

// TYPES

export type TUpdateRolePermissionRequest = {
  type: EUpdateRolePermissionAction.UPDATE_ROLE_PERMISSION_REQUEST;
  payload: {
    materials: TUpdateRolePermissionMaterials;
    successCallback?: (response: TUpdateRolePermissionResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateRolePermissionSuccess = {
  type: EUpdateRolePermissionAction.UPDATE_ROLE_PERMISSION_SUCCESS;
  payload: { response: TUpdateRolePermissionResponse };
};

export type TUpdateRolePermissionFailed = { type: EUpdateRolePermissionAction.UPDATE_ROLE_PERMISSION_FAILED };

// FUNCTION

export const updateRolePermissionAction = {
  request: createActionCreator(
    EUpdateRolePermissionAction.UPDATE_ROLE_PERMISSION_REQUEST,
    (resolve) =>
      (
        materials: TUpdateRolePermissionMaterials,
        successCallback?: (response: TUpdateRolePermissionResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateRolePermissionRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateRolePermissionAction.UPDATE_ROLE_PERMISSION_SUCCESS,
    (resolve) =>
      (response: TUpdateRolePermissionResponse): TUpdateRolePermissionSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateRolePermissionAction.UPDATE_ROLE_PERMISSION_FAILED,
    (resolve) =>
      (error: unknown): TUpdateRolePermissionFailed =>
        resolve({ error }),
  ),
};

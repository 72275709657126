import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';

const Svg: React.FC<TIconProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.75 4.5H9.75V10.5H21.75V6.5C21.75 5.39543 20.8546 4.5 19.75 4.5Z" fill="#FFA000" />
      <path
        d="M22.5 10.3636C22.5 9.71265 22.2447 9.08833 21.7903 8.62801C21.3359 8.16769 20.7196 7.90909 20.0769 7.90909H12L9.67385 3H3.92308C3.28044 3 2.66412 3.2586 2.2097 3.71892C1.75529 4.17924 1.5 4.80356 1.5 5.45455V18.5455C1.5 19.1964 1.75529 19.8208 2.2097 20.2811C2.66412 20.7414 3.28044 21 3.92308 21H20.0769C20.7196 21 21.3359 20.7414 21.7903 20.2811C22.2447 19.8208 22.5 19.1964 22.5 18.5455V10.3636Z"
        fill="#FFC107"
      />
    </svg>
  );
};

export default Svg;

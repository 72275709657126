import { TChecklistState } from '@/redux/reducers/checklist';
import { TChangeTaskStatusSuccess } from '@/redux/actions/checklist';

export const changeTaskStatusUpdateState = (
  state: TChecklistState,
  action: TChangeTaskStatusSuccess,
): TChecklistState => ({
  ...state,
  changeTaskStatusResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import {
  TDownloadEmployeeTurnoverRateMaterials,
  TDownloadEmployeeTurnoverRateResponse,
} from '@/services/api/report/download-employee-turnover-rate';

// CONSTANTS

export enum EDownloadEmployeeTurnoverRateAction {
  DOWNLOAD_EMPLOYEE_TURNOVER_RATE = 'DOWNLOAD_EMPLOYEE_TURNOVER_RATE',
  DOWNLOAD_EMPLOYEE_TURNOVER_RATE_REQUEST = 'DOWNLOAD_EMPLOYEE_TURNOVER_RATE_REQUEST',
  DOWNLOAD_EMPLOYEE_TURNOVER_RATE_SUCCESS = 'DOWNLOAD_EMPLOYEE_TURNOVER_RATE_SUCCESS',
  DOWNLOAD_EMPLOYEE_TURNOVER_RATE_FAILED = 'DOWNLOAD_EMPLOYEE_TURNOVER_RATE_FAILED',
}

// TYPES

export type TDownloadEmployeeTurnoverRateRequest = {
  type: EDownloadEmployeeTurnoverRateAction.DOWNLOAD_EMPLOYEE_TURNOVER_RATE_REQUEST;
  payload: {
    materials: TDownloadEmployeeTurnoverRateMaterials;
    successCallback?: (response: TDownloadEmployeeTurnoverRateResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadEmployeeTurnoverRateSuccess = {
  type: EDownloadEmployeeTurnoverRateAction.DOWNLOAD_EMPLOYEE_TURNOVER_RATE_SUCCESS;
  payload: { response: TDownloadEmployeeTurnoverRateResponse };
};

export type TDownloadEmployeeTurnoverRateFailed = {
  type: EDownloadEmployeeTurnoverRateAction.DOWNLOAD_EMPLOYEE_TURNOVER_RATE_FAILED;
};

// FUNCTION

export const downloadEmployeeTurnoverRateAction = {
  request: createActionCreator(
    EDownloadEmployeeTurnoverRateAction.DOWNLOAD_EMPLOYEE_TURNOVER_RATE_REQUEST,
    (resolve) =>
      (
        materials: TDownloadEmployeeTurnoverRateMaterials,
        successCallback?: (response: TDownloadEmployeeTurnoverRateResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadEmployeeTurnoverRateRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadEmployeeTurnoverRateAction.DOWNLOAD_EMPLOYEE_TURNOVER_RATE_SUCCESS,
    (resolve) =>
      (response: TDownloadEmployeeTurnoverRateResponse): TDownloadEmployeeTurnoverRateSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadEmployeeTurnoverRateAction.DOWNLOAD_EMPLOYEE_TURNOVER_RATE_FAILED,
    (resolve) =>
      (error: unknown): TDownloadEmployeeTurnoverRateFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteHolidayAction } from '@/redux/actions';
import { deleteHoliday, TDeleteHolidayResponse } from '@/services/api';

// FUNCTION

export function* deleteHolidaySaga(action: ActionType<typeof deleteHolidayAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteHoliday, materials);
    const deleteHolidayResponse: TDeleteHolidayResponse = response as TDeleteHolidayResponse;
    yield put(deleteHolidayAction.success(deleteHolidayResponse));
    successCallback?.(deleteHolidayResponse);
  } catch (err) {
    yield put(deleteHolidayAction.failure(err));
    failedCallback?.(err);
  }
}

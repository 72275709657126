import { createActionCreator } from 'deox';

import { TGetChecklistTasksMaterials, TGetChecklistTasksResponse } from '@/services/api/checklist/get-checklist-tasks';

// CONSTANTS

export enum EGetChecklistTasksAction {
  GET_CHECKLIST_TASKS = 'GET_CHECKLIST_TASKS',
  GET_CHECKLIST_TASKS_REQUEST = 'GET_CHECKLIST_TASKS_REQUEST',
  GET_CHECKLIST_TASKS_SUCCESS = 'GET_CHECKLIST_TASKS_SUCCESS',
  GET_CHECKLIST_TASKS_FAILED = 'GET_CHECKLIST_TASKS_FAILED',
}

// TYPES

export type TGetChecklistTasksRequest = {
  type: EGetChecklistTasksAction.GET_CHECKLIST_TASKS_REQUEST;
  payload: {
    materials: TGetChecklistTasksMaterials;
    successCallback?: (response: TGetChecklistTasksResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetChecklistTasksSuccess = {
  type: EGetChecklistTasksAction.GET_CHECKLIST_TASKS_SUCCESS;
  payload: { response: TGetChecklistTasksResponse };
};

export type TGetChecklistTasksFailed = { type: EGetChecklistTasksAction.GET_CHECKLIST_TASKS_FAILED };

// FUNCTION

export const getChecklistTasksAction = {
  request: createActionCreator(
    EGetChecklistTasksAction.GET_CHECKLIST_TASKS_REQUEST,
    (resolve) =>
      (
        materials: TGetChecklistTasksMaterials,
        successCallback?: (response: TGetChecklistTasksResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetChecklistTasksRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetChecklistTasksAction.GET_CHECKLIST_TASKS_SUCCESS,
    (resolve) =>
      (response: TGetChecklistTasksResponse): TGetChecklistTasksSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetChecklistTasksAction.GET_CHECKLIST_TASKS_FAILED,
    (resolve) =>
      (error: unknown): TGetChecklistTasksFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTypeWorkAction } from '@/redux/actions';
import { getTypeWork, TGetTypeWorkResponse } from '@/services/api';

// FUNCTION

export function* getTypeWorkSaga(action: ActionType<typeof getTypeWorkAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTypeWork, materials);
    const getTypeWorkResponse: TGetTypeWorkResponse = response as TGetTypeWorkResponse;
    yield put(getTypeWorkAction.success(getTypeWorkResponse));
    successCallback?.(getTypeWorkResponse);
  } catch (err) {
    yield put(getTypeWorkAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TGetApproversMaterials, TGetApproversResponse } from '@/services/api/approver/get-approvers';

// CONSTANTS

export enum EGetApproversAction {
  GET_APPROVERS = 'GET_APPROVERS',
  GET_APPROVERS_REQUEST = 'GET_APPROVERS_REQUEST',
  GET_APPROVERS_SUCCESS = 'GET_APPROVERS_SUCCESS',
  GET_APPROVERS_FAILED = 'GET_APPROVERS_FAILED',
}

// TYPES

export type TGetApproversRequest = {
  type: EGetApproversAction.GET_APPROVERS_REQUEST;
  payload: {
    materials: TGetApproversMaterials;
    successCallback?: (response: TGetApproversResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetApproversSuccess = {
  type: EGetApproversAction.GET_APPROVERS_SUCCESS;
  payload: { response: TGetApproversResponse };
};

export type TGetApproversFailed = { type: EGetApproversAction.GET_APPROVERS_FAILED };

// FUNCTION

export const getApproversAction = {
  request: createActionCreator(
    EGetApproversAction.GET_APPROVERS_REQUEST,
    (resolve) =>
      (
        materials: TGetApproversMaterials,
        successCallback?: (response: TGetApproversResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetApproversRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetApproversAction.GET_APPROVERS_SUCCESS,
    (resolve) =>
      (response: TGetApproversResponse): TGetApproversSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetApproversAction.GET_APPROVERS_FAILED,
    (resolve) =>
      (error: unknown): TGetApproversFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import {
  TGetCompanyTimeOffsMaterials,
  TGetCompanyTimeOffsResponse,
} from '@/services/api/dashboard/get-company-time-offs';

// CONSTANTS

export enum EGetCompanyTimeOffsAction {
  GET_COMPANY_TIME_OFFS = 'GET_COMPANY_TIME_OFFS',
  GET_COMPANY_TIME_OFFS_REQUEST = 'GET_COMPANY_TIME_OFFS_REQUEST',
  GET_COMPANY_TIME_OFFS_SUCCESS = 'GET_COMPANY_TIME_OFFS_SUCCESS',
  GET_COMPANY_TIME_OFFS_FAILED = 'GET_COMPANY_TIME_OFFS_FAILED',
}

// TYPES

export type TGetCompanyTimeOffsRequest = {
  type: EGetCompanyTimeOffsAction.GET_COMPANY_TIME_OFFS_REQUEST;
  payload: {
    materials: TGetCompanyTimeOffsMaterials;
    successCallback?: (response: TGetCompanyTimeOffsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCompanyTimeOffsSuccess = {
  type: EGetCompanyTimeOffsAction.GET_COMPANY_TIME_OFFS_SUCCESS;
  payload: { response: TGetCompanyTimeOffsResponse };
};

export type TGetCompanyTimeOffsFailed = { type: EGetCompanyTimeOffsAction.GET_COMPANY_TIME_OFFS_FAILED };

// FUNCTION

export const getCompanyTimeOffsAction = {
  request: createActionCreator(
    EGetCompanyTimeOffsAction.GET_COMPANY_TIME_OFFS_REQUEST,
    (resolve) =>
      (
        materials: TGetCompanyTimeOffsMaterials,
        successCallback?: (response: TGetCompanyTimeOffsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCompanyTimeOffsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCompanyTimeOffsAction.GET_COMPANY_TIME_OFFS_SUCCESS,
    (resolve) =>
      (response: TGetCompanyTimeOffsResponse): TGetCompanyTimeOffsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCompanyTimeOffsAction.GET_COMPANY_TIME_OFFS_FAILED,
    (resolve) =>
      (error: unknown): TGetCompanyTimeOffsFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TUpdateBenefitPaths = {
  id: string | number;
};
export type TUpdateBenefitBody = unknown;

export type TUpdateBenefitMaterials = {
  paths?: TUpdateBenefitPaths;
  body?: TUpdateBenefitBody;
};

export type TUpdateBenefitResponse = unknown;

// FUNCTION

export const updateBenefit = async ({ paths, body }: TUpdateBenefitMaterials): Promise<TUpdateBenefitResponse> => {
  const response = await ApiService.put(`/benefit/${paths?.id}`, body);
  return response.data;
};

import { TDashboardState } from '@/redux/reducers/dashboard';
import { TGetDashboardReportSuccess } from '@/redux/actions/dashboard';

export const getDashboardReportUpdateState = (
  state: TDashboardState,
  action: TGetDashboardReportSuccess,
): TDashboardState => ({
  ...state,
  getDashboardReportResponse: action.payload.response,
});

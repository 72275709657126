import React from 'react';
import { Empty as AntdEmpty } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon, { EIconColor, EIconName } from '@/components/Icon';
import Button, { EButtonStyleType } from '@/components/Button';

import { TEmptyProps } from './Empty.types.d';
import './Empty.scss';

const Empty: React.FC<TEmptyProps> = ({ style, buttonProps, description }) => {
  const { t } = useTranslation();
  const emptyDescription = React.useMemo(
    () => (
      <div className="Empty-description">
        <p>{t(`Empty_there_nothing_here`)}</p>
        <p>{description}</p>
      </div>
    ),
    [t, description],
  );

  return (
    <div className="Empty" style={style}>
      <AntdEmpty
        description={emptyDescription}
        image={<Icon name={EIconName.EmptyState} color={EIconColor.GRAYSCALE_200} />}
      />

      {buttonProps && (
        <div className="Empty-btn flex justify-center">
          <Button styleType={EButtonStyleType.PRIMARY} {...buttonProps} />
        </div>
      )}
    </div>
  );
};

export default Empty;

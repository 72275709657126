import { createActionCreator } from 'deox';

import {
  TDownloadReportTimeOffBalanceMaterials,
  TDownloadReportTimeOffBalanceResponse,
} from '@/services/api/report/download-report-time-off-balance';

// CONSTANTS

export enum EDownloadReportTimeOffBalanceAction {
  DOWNLOAD_REPORT_TIME_OFF_BALANCE = 'DOWNLOAD_REPORT_TIME_OFF_BALANCE',
  DOWNLOAD_REPORT_TIME_OFF_BALANCE_REQUEST = 'DOWNLOAD_REPORT_TIME_OFF_BALANCE_REQUEST',
  DOWNLOAD_REPORT_TIME_OFF_BALANCE_SUCCESS = 'DOWNLOAD_REPORT_TIME_OFF_BALANCE_SUCCESS',
  DOWNLOAD_REPORT_TIME_OFF_BALANCE_FAILED = 'DOWNLOAD_REPORT_TIME_OFF_BALANCE_FAILED',
}

// TYPES

export type TDownloadReportTimeOffBalanceRequest = {
  type: EDownloadReportTimeOffBalanceAction.DOWNLOAD_REPORT_TIME_OFF_BALANCE_REQUEST;
  payload: {
    materials: TDownloadReportTimeOffBalanceMaterials;
    successCallback?: (response: TDownloadReportTimeOffBalanceResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadReportTimeOffBalanceSuccess = {
  type: EDownloadReportTimeOffBalanceAction.DOWNLOAD_REPORT_TIME_OFF_BALANCE_SUCCESS;
  payload: { response: TDownloadReportTimeOffBalanceResponse };
};

export type TDownloadReportTimeOffBalanceFailed = {
  type: EDownloadReportTimeOffBalanceAction.DOWNLOAD_REPORT_TIME_OFF_BALANCE_FAILED;
};

// FUNCTION

export const downloadReportTimeOffBalanceAction = {
  request: createActionCreator(
    EDownloadReportTimeOffBalanceAction.DOWNLOAD_REPORT_TIME_OFF_BALANCE_REQUEST,
    (resolve) =>
      (
        materials: TDownloadReportTimeOffBalanceMaterials,
        successCallback?: (response: TDownloadReportTimeOffBalanceResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadReportTimeOffBalanceRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadReportTimeOffBalanceAction.DOWNLOAD_REPORT_TIME_OFF_BALANCE_SUCCESS,
    (resolve) =>
      (response: TDownloadReportTimeOffBalanceResponse): TDownloadReportTimeOffBalanceSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadReportTimeOffBalanceAction.DOWNLOAD_REPORT_TIME_OFF_BALANCE_FAILED,
    (resolve) =>
      (error: unknown): TDownloadReportTimeOffBalanceFailed =>
        resolve({ error }),
  ),
};

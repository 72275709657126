import { createReducer } from 'deox';

import { TAuthLoginResponse, TLoginIdPortalResponse, TSignInResponse, TSignupUserResponse } from '@/services/api/auth';
import { authLoginAction, loginIdPortalAction, signInAction, signupUserAction } from '@/redux/actions';
import { authLoginUpdateState } from './auth-login';
import { loginIdPortalUpdateState } from './login-id-portal';
import { signInUpdateState } from './sign-in';
import { signupUserUpdateState } from './signup-user';

export type TAuthState = {
  authLoginResponse?: TAuthLoginResponse;
  loginIdPortalResponse?: TLoginIdPortalResponse;
  signInResponse?: TSignInResponse;
  signupUserResponse?: TSignupUserResponse;
};

const initialState: TAuthState = {
  authLoginResponse: undefined,
  loginIdPortalResponse: undefined,
  signInResponse: undefined,
  signupUserResponse: undefined,
};

const AuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(authLoginAction.success, authLoginUpdateState),
  handleAction(loginIdPortalAction.success, loginIdPortalUpdateState),
  handleAction(signInAction.success, signInUpdateState),
  handleAction(signupUserAction.success, signupUserUpdateState),
]);

export default AuthReducer;

import React, { useState } from 'react';
import { Link, navigate, useLocation } from '@reach/router';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Paths } from '@/pages/routers';
import Logo from '@/assets/images/logo.svg';
import { EIconColor, EIconName } from '@/components/Icon';
import Button from '@/components/Button';
import Avatar from '@/components/Avatar';
import DropdownCustom from '@/components/DropdownCustom';
import AccountDropdown from '@/containers/Header/AccountDropdown';
import QuickActionsDropdown from '@/containers/Header/QuickActionsDropdown';
import ModalLogout from '@/containers/Header/ModalLogout';
import ModalImportEmployee from '@/pages/Peoples/ModalImportEmployee';
import { EKeySettingsNaviagion } from '@/pages/Settings/SettingsNavigation/SettingsNavigation.enums';
import { TRootState } from '@/redux/reducers';
import env from '@/env';
import InputActionUsers from '@/components/InputActionUsers';
import { ETypeRequestManage } from '@/common/enums';
import ModalSendFeedback from '@/containers/ModalSendFeedback';
import {
  EPermisisonManage,
  EFeatureManage,
} from '@/pages/Settings/Permissions/PermissionsDetail/PermissionsDetail.enums';
import { checkPermissionModule } from '@/utils/functions';

import { dataHeaderMenu } from './Header.data';
import { THeaderProps } from './Header.types.d';
import './Header.scss';

const Header: React.FC<THeaderProps> = ({ haveBackground }) => {
  const [visibleAccountDropdown, setVisibleAccountDropdown] = useState<boolean>(false);
  const [visibleQuickActionsDropdown, setVisibleQuickActionsDropdown] = useState<boolean>(false);
  const [logoutModalState, setLogoutModalState] = useState<{ visible: boolean }>({ visible: false });
  const [importEmployeeModalState, setImportEmployeeModalState] = useState<{ visible: boolean }>({ visible: false });
  const [sendFeedbackModalState, setSendFeedbackModalState] = useState<{ visible: boolean }>({ visible: false });

  const myInfoState = useSelector((state: TRootState) => state.userReducer.getMyInfoResponse);
  const companyState = useSelector((state: TRootState) => state.companyReducer.getCompanyResponse);

  const requestNotifications = useSelector((state: TRootState) => state.requestReducer.getRequestNotificationsResponse);

  const permisisonManagerRequests = checkPermissionModule(
    myInfoState,
    EPermisisonManage.REQUESTS,
    EFeatureManage.MANAGER_REQUESTS,
  );

  const permisisonEmployeesView2Nd = checkPermissionModule(
    myInfoState,
    EPermisisonManage.EMPLOYEES,
    EFeatureManage.EMPLOYEES_VIEW_2ND,
  );
  const permisisonEmployeesPersonalInfo = checkPermissionModule(
    myInfoState,
    EPermisisonManage.EMPLOYEES,
    EFeatureManage.EMPLOYEES_PERSONAL_INFO,
  );
  const permisisonEmployeesNew = checkPermissionModule(
    myInfoState,
    EPermisisonManage.EMPLOYEES,
    EFeatureManage.EMPLOYEES_NEW,
  );
  const permisisonAssets = checkPermissionModule(myInfoState, EPermisisonManage.ASSETS, EFeatureManage.ASSETS);
  const permisisonCompanyRegulations = checkPermissionModule(
    myInfoState,
    EPermisisonManage.REGULATIONS,
    EFeatureManage.COMPANY_REGULATIONS,
  );
  const permisisonQuickAction = checkPermissionModule(
    myInfoState,
    EPermisisonManage.OTHER,
    EFeatureManage.QUICK_ACTION,
  );

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [, , requestId, , taskId] = pathname.split('/');
  const id = [...pathname.split('/')].pop();

  const pathFields = { id, requestId, taskId, myInfoState };

  const handleOpenImportEmployeeModal = (): void => {
    setImportEmployeeModalState({ visible: true });
  };

  const handleCloseImportEmployeeModal = (): void => {
    setImportEmployeeModalState({ visible: false });
  };

  const handleOpenSendFeedbackModal = (): void => {
    setSendFeedbackModalState({ visible: true });
  };
  const handleCloseSendFeedbackModal = (): void => {
    setSendFeedbackModalState({ visible: false });
  };

  const dataHeaderQuickActions = [
    {
      key: 'create',
      title: t('QuickActionsDropdown_create_new'),
      data: [
        {
          key: 'newEmployee',
          iconName: EIconName.User,
          title: t('QuickActionsDropdown_new_employee'),
          hide: !permisisonEmployeesView2Nd?.canUpdate,
          onClick: (): void => {
            navigate(Paths.InvitePeople);
          },
        },
        {
          key: 'assets',
          iconName: EIconName.Responsive,
          title: t('QuickActionsDropdown_new_assets'),
          hide: !permisisonAssets?.canUpdate,
          onClick: (): void => {
            navigate(Paths.NewAsset);
          },
        },
        {
          key: 'regulations',
          iconName: EIconName.Document,
          title: t('QuickActionsDropdown_new_regulations'),
          hide: !permisisonCompanyRegulations?.canUpdate,
          onClick: (): void => {
            navigate(Paths.NewRegulation);
          },
        },
      ],
    },
    {
      key: 'import',
      title: t('QuickActionsDropdown_import'),
      data: [
        {
          key: 'importEmployees',
          iconName: EIconName.User,
          title: t('QuickActionsDropdown_import_employees'),
          hide: !permisisonEmployeesNew?.canUpdate,
          onClick: (): void => {
            handleOpenImportEmployeeModal();
          },
        },
      ],
    },
  ];

  const handleOpenModalLogout = (): void => {
    setLogoutModalState({ visible: true });
  };
  const handleCloseModalLogout = (): void => {
    setLogoutModalState({ visible: false });
  };

  return (
    <div className={classNames('Header', { 'have-background': haveBackground })}>
      <div className="container">
        <div className="Header-wrapper flex items-center justify-between">
          <div className="Header-logo">
            <Link to={Paths.Home}>
              <img src={companyState?.logo ? `${env.api.baseUrl.service}${companyState.logo}` : Logo} alt="" />
            </Link>
          </div>

          <div className="Header-nav flex items-center">
            {dataHeaderMenu(t, { ...pathFields })
              ?.filter((item) => !item.hide)
              .map((item) => (
                <Link
                  key={item.key}
                  to={item.link}
                  className={classNames('Header-nav-item', {
                    active: item.activePaths.includes(pathname),
                    disabled: item.disabled,
                  })}
                >
                  {item.title}
                </Link>
              ))}
          </div>

          {permisisonEmployeesView2Nd?.canView && permisisonEmployeesPersonalInfo?.canView && (
            <div className="Header-search">
              <InputActionUsers
                placeholder={t('Header_search_placeholder')}
                getPopupContainer={(trigger): HTMLElement => trigger}
                onClickUser={(user): void => {
                  navigate(myInfoState?.id === user.id ? Paths.SelfService : Paths.PeopleDetail(user.id));
                }}
              />
            </div>
          )}

          <div className="Header-actions flex items-center">
            {permisisonQuickAction?.canView && (
              <DropdownCustom
                overlay={
                  <QuickActionsDropdown
                    data={dataHeaderQuickActions}
                    onClickItem={(): void => setVisibleQuickActionsDropdown(false)}
                  />
                }
                visible={visibleQuickActionsDropdown}
                onVisibleChange={setVisibleQuickActionsDropdown}
                placement="bottomRight"
                getPopupContainer={(trigger): HTMLElement => trigger}
              >
                <div className="Header-actions-item">
                  <Button iconName={EIconName.Plus} iconColor={EIconColor.WHITE} size="large" />
                </div>
              </DropdownCustom>
            )}

            {permisisonManagerRequests?.canView && (
              <div className="Header-actions-item">
                {(requestNotifications?.reqs || 0) > 0 && (
                  <div className="Header-actions-item-badge">
                    {(requestNotifications?.reqs || 0) > 9 ? '9' : requestNotifications?.reqs}
                    {(requestNotifications?.reqs || 0) > 9 ? '+' : ''}
                  </div>
                )}

                <Button
                  className={classNames({ active: [Paths.RequestsManagement].includes(pathname) })}
                  iconName={EIconName.Inbox}
                  iconColor={EIconColor.WHITE}
                  size="large"
                  link={`${Paths.RequestsManagement}?tab=${ETypeRequestManage.MY_REQ}`}
                />
              </div>
            )}

            <div className="Header-actions-item">
              <Button
                className={classNames({ active: [Paths.Settings].includes(pathname) })}
                iconName={EIconName.Setting}
                iconColor={EIconColor.WHITE}
                size="large"
                link={`${Paths.Settings}?navigation=${EKeySettingsNaviagion.GENERAL}`}
              />
            </div>
          </div>

          <DropdownCustom
            overlay={
              <AccountDropdown
                onClose={(): void => setVisibleAccountDropdown(false)}
                onLogout={handleOpenModalLogout}
                onFeedback={handleOpenSendFeedbackModal}
              />
            }
            visible={visibleAccountDropdown}
            placement="bottomRight"
            onVisibleChange={setVisibleAccountDropdown}
            getPopupContainer={(trigger): HTMLElement => trigger}
          >
            <div className="Header-account flex items-center">
              <Avatar showZoom={false} size={40} image={myInfoState?.avatar} />
            </div>
          </DropdownCustom>
        </div>
      </div>

      <ModalLogout {...logoutModalState} onClose={handleCloseModalLogout} />

      <ModalImportEmployee {...importEmployeeModalState} onClose={handleCloseImportEmployeeModal} />

      <ModalSendFeedback {...sendFeedbackModalState} onClose={handleCloseSendFeedbackModal} />
    </div>
  );
};

export default Header;

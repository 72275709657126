import { TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetRoleMembersPaths = {
  id: string | number;
};
export type TGetRoleMembersParams = {
  page: number;
  pageSize: number;
  sort?: string;
};

export type TGetRoleMembersMaterials = {
  paths?: TGetRoleMembersPaths;
  params?: TGetRoleMembersParams;
};

export type TGetRoleMembersResponse = TPaginateResponse & {
  data: { user: TUser }[];
};

// FUNCTION

export const getRoleMembers = async ({ paths, params }: TGetRoleMembersMaterials): Promise<TGetRoleMembersResponse> => {
  const response = await ApiService.get(`/role/members/${paths?.id}`, { params });
  return response.data;
};

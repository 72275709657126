import { createActionCreator } from 'deox';

import { TGetCelebrationsMaterials, TGetCelebrationsResponse } from '@/services/api/dashboard/get-celebrations';

// CONSTANTS

export enum EGetCelebrationsAction {
  GET_CELEBRATIONS = 'GET_CELEBRATIONS',
  GET_CELEBRATIONS_REQUEST = 'GET_CELEBRATIONS_REQUEST',
  GET_CELEBRATIONS_SUCCESS = 'GET_CELEBRATIONS_SUCCESS',
  GET_CELEBRATIONS_FAILED = 'GET_CELEBRATIONS_FAILED',
}

// TYPES

export type TGetCelebrationsRequest = {
  type: EGetCelebrationsAction.GET_CELEBRATIONS_REQUEST;
  payload: {
    materials: TGetCelebrationsMaterials;
    successCallback?: (response: TGetCelebrationsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCelebrationsSuccess = {
  type: EGetCelebrationsAction.GET_CELEBRATIONS_SUCCESS;
  payload: { response: TGetCelebrationsResponse };
};

export type TGetCelebrationsFailed = { type: EGetCelebrationsAction.GET_CELEBRATIONS_FAILED };

// FUNCTION

export const getCelebrationsAction = {
  request: createActionCreator(
    EGetCelebrationsAction.GET_CELEBRATIONS_REQUEST,
    (resolve) =>
      (
        materials: TGetCelebrationsMaterials,
        successCallback?: (response: TGetCelebrationsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCelebrationsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCelebrationsAction.GET_CELEBRATIONS_SUCCESS,
    (resolve) =>
      (response: TGetCelebrationsResponse): TGetCelebrationsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCelebrationsAction.GET_CELEBRATIONS_FAILED,
    (resolve) =>
      (error: unknown): TGetCelebrationsFailed =>
        resolve({ error }),
  ),
};

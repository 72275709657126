import { createActionCreator } from 'deox';

import { TGetAssetCategoriesMaterials, TGetAssetCategoriesResponse } from '@/services/api/asset/get-asset-categories';

// CONSTANTS

export enum EGetAssetCategoriesAction {
  GET_ASSET_CATEGORIES = 'GET_ASSET_CATEGORIES',
  GET_ASSET_CATEGORIES_REQUEST = 'GET_ASSET_CATEGORIES_REQUEST',
  GET_ASSET_CATEGORIES_SUCCESS = 'GET_ASSET_CATEGORIES_SUCCESS',
  GET_ASSET_CATEGORIES_FAILED = 'GET_ASSET_CATEGORIES_FAILED',
}

// TYPES

export type TGetAssetCategoriesRequest = {
  type: EGetAssetCategoriesAction.GET_ASSET_CATEGORIES_REQUEST;
  payload: {
    materials: TGetAssetCategoriesMaterials;
    successCallback?: (response: TGetAssetCategoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAssetCategoriesSuccess = {
  type: EGetAssetCategoriesAction.GET_ASSET_CATEGORIES_SUCCESS;
  payload: { response: TGetAssetCategoriesResponse };
};

export type TGetAssetCategoriesFailed = { type: EGetAssetCategoriesAction.GET_ASSET_CATEGORIES_FAILED };

// FUNCTION

export const getAssetCategoriesAction = {
  request: createActionCreator(
    EGetAssetCategoriesAction.GET_ASSET_CATEGORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetAssetCategoriesMaterials,
        successCallback?: (response: TGetAssetCategoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAssetCategoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAssetCategoriesAction.GET_ASSET_CATEGORIES_SUCCESS,
    (resolve) =>
      (response: TGetAssetCategoriesResponse): TGetAssetCategoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAssetCategoriesAction.GET_ASSET_CATEGORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetAssetCategoriesFailed =>
        resolve({ error }),
  ),
};

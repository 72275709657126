import { createActionCreator } from 'deox';

import {
  TCreateTimeOffTypeMaterials,
  TCreateTimeOffTypeResponse,
} from '@/services/api/time-off-type/create-time-off-type';

// CONSTANTS

export enum ECreateTimeOffTypeAction {
  CREATE_TIME_OFF_TYPE = 'CREATE_TIME_OFF_TYPE',
  CREATE_TIME_OFF_TYPE_REQUEST = 'CREATE_TIME_OFF_TYPE_REQUEST',
  CREATE_TIME_OFF_TYPE_SUCCESS = 'CREATE_TIME_OFF_TYPE_SUCCESS',
  CREATE_TIME_OFF_TYPE_FAILED = 'CREATE_TIME_OFF_TYPE_FAILED',
}

// TYPES

export type TCreateTimeOffTypeRequest = {
  type: ECreateTimeOffTypeAction.CREATE_TIME_OFF_TYPE_REQUEST;
  payload: {
    materials: TCreateTimeOffTypeMaterials;
    successCallback?: (response: TCreateTimeOffTypeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateTimeOffTypeSuccess = {
  type: ECreateTimeOffTypeAction.CREATE_TIME_OFF_TYPE_SUCCESS;
  payload: { response: TCreateTimeOffTypeResponse };
};

export type TCreateTimeOffTypeFailed = { type: ECreateTimeOffTypeAction.CREATE_TIME_OFF_TYPE_FAILED };

// FUNCTION

export const createTimeOffTypeAction = {
  request: createActionCreator(
    ECreateTimeOffTypeAction.CREATE_TIME_OFF_TYPE_REQUEST,
    (resolve) =>
      (
        materials: TCreateTimeOffTypeMaterials,
        successCallback?: (response: TCreateTimeOffTypeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateTimeOffTypeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateTimeOffTypeAction.CREATE_TIME_OFF_TYPE_SUCCESS,
    (resolve) =>
      (response: TCreateTimeOffTypeResponse): TCreateTimeOffTypeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateTimeOffTypeAction.CREATE_TIME_OFF_TYPE_FAILED,
    (resolve) =>
      (error: unknown): TCreateTimeOffTypeFailed =>
        resolve({ error }),
  ),
};

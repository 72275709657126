import ApiService from '@/services/api';

// TYPES

export type TUpdateJobPaths = {
  id: string | number;
};
export type TUpdateJobBody = unknown;

export type TUpdateJobMaterials = {
  paths?: TUpdateJobPaths;
  body?: TUpdateJobBody;
};

export type TUpdateJobResponse = unknown;

// FUNCTION

export const updateJob = async ({ paths, body }: TUpdateJobMaterials): Promise<TUpdateJobResponse> => {
  const response = await ApiService.put(`/job/${paths?.id}`, body);
  return response.data;
};

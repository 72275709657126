import { createActionCreator } from 'deox';

import { TGetStatesMaterials, TGetStatesResponse } from '@/services/api/widen/get-states';

// CONSTANTS

export enum EGetStatesAction {
  GET_STATES = 'GET_STATES',
  GET_STATES_REQUEST = 'GET_STATES_REQUEST',
  GET_STATES_SUCCESS = 'GET_STATES_SUCCESS',
  GET_STATES_FAILED = 'GET_STATES_FAILED',
}

// TYPES

export type TGetStatesRequest = {
  type: EGetStatesAction.GET_STATES_REQUEST;
  payload: {
    materials: TGetStatesMaterials;
    successCallback?: (response: TGetStatesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetStatesSuccess = {
  type: EGetStatesAction.GET_STATES_SUCCESS;
  payload: { response: TGetStatesResponse };
};

export type TGetStatesFailed = { type: EGetStatesAction.GET_STATES_FAILED };

// FUNCTION

export const getStatesAction = {
  request: createActionCreator(
    EGetStatesAction.GET_STATES_REQUEST,
    (resolve) =>
      (
        materials: TGetStatesMaterials,
        successCallback?: (response: TGetStatesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetStatesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetStatesAction.GET_STATES_SUCCESS,
    (resolve) =>
      (response: TGetStatesResponse): TGetStatesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetStatesAction.GET_STATES_FAILED,
    (resolve) =>
      (error: unknown): TGetStatesFailed =>
        resolve({ error }),
  ),
};

import { TUploadFile } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TUploadFilesParams = unknown;
export type TUploadFilesBody = unknown;

export type TUploadFilesMaterials = {
  params?: TUploadFilesParams;
  body?: TUploadFilesBody;
};

export type TUploadFilesResponse = TUploadFile[];

// FUNCTION

export const uploadFiles = async ({ params, body }: TUploadFilesMaterials): Promise<TUploadFilesResponse> => {
  const response = await ApiService.post(`/upload/files`, body, { params });
  return response.data;
};

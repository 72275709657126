import { TMyAsset } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetMyAssetsParams = {
  page: number;
  pageSize: number;
  sort?: string;
  categories?: string;
};

export type TGetMyAssetsPaths = {
  id?: string | number;
};

export type TGetMyAssetsMaterials = {
  params?: TGetMyAssetsParams;
  paths?: TGetMyAssetsPaths;
};

export type TGetMyAssetsResponse = TPaginateResponse & {
  data: TMyAsset[];
};

// FUNCTION

export const getMyAssets = async ({ params, paths }: TGetMyAssetsMaterials): Promise<TGetMyAssetsResponse> => {
  const response = await ApiService.get(`/asset/${paths?.id ? `assets/${paths?.id}` : 'my-assets'}`, { params });
  return response.data;
};

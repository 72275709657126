import { all, takeLatest } from 'redux-saga/effects';

import {
  donwloadReportTimeOffScheduleAction,
  downloadEmployeeTurnoverRateAction,
  downloadReportEmployeeDataAction,
  downloadReportHeadcountAction,
  downloadReportOffboardAction,
  downloadReportOnboardAction,
  downloadReportTimeOffBalanceAction,
  getReportEmployeeDataAction,
  getReportEmployeeTurnoverRateAction,
  getReportHeadcountAction,
  getReportMonthlyTimekeepingAction,
  getReportOffboardAction,
  getReportOnboardAction,
  getReportTimeOffBalanceAction,
  getReportTimeOffScheduleAction,
} from '@/redux/actions';

import { donwloadReportTimeOffScheduleSaga } from './donwload-report-time-off-schedule';
import { downloadEmployeeTurnoverRateSaga } from './download-employee-turnover-rate';
import { downloadReportEmployeeDataSaga } from './download-report-employee-data';
import { downloadReportHeadcountSaga } from './download-report-headcount';
import { downloadReportOffboardSaga } from './download-report-offboard';
import { downloadReportOnboardSaga } from './download-report-onboard';
import { downloadReportTimeOffBalanceSaga } from './download-report-time-off-balance';
import { getReportEmployeeDataSaga } from './get-report-employee-data';
import { getReportEmployeeTurnoverRateSaga } from './get-report-employee-turnover-rate';
import { getReportHeadcountSaga } from './get-report-headcount';
import { getReportMonthlyTimekeepingSaga } from './get-report-monthly-timekeeping';
import { getReportOffboardSaga } from './get-report-offboard';
import { getReportOnboardSaga } from './get-report-onboard';
import { getReportTimeOffBalanceSaga } from './get-report-time-off-balance';
import { getReportTimeOffScheduleSaga } from './get-report-time-off-schedule';

export default function* root(): Generator {
  yield all([
    takeLatest(donwloadReportTimeOffScheduleAction.request.type, donwloadReportTimeOffScheduleSaga),
    takeLatest(downloadEmployeeTurnoverRateAction.request.type, downloadEmployeeTurnoverRateSaga),
    takeLatest(downloadReportEmployeeDataAction.request.type, downloadReportEmployeeDataSaga),
    takeLatest(downloadReportHeadcountAction.request.type, downloadReportHeadcountSaga),
    takeLatest(downloadReportOffboardAction.request.type, downloadReportOffboardSaga),
    takeLatest(downloadReportOnboardAction.request.type, downloadReportOnboardSaga),
    takeLatest(downloadReportTimeOffBalanceAction.request.type, downloadReportTimeOffBalanceSaga),
    takeLatest(getReportEmployeeDataAction.request.type, getReportEmployeeDataSaga),
    takeLatest(getReportEmployeeTurnoverRateAction.request.type, getReportEmployeeTurnoverRateSaga),
    takeLatest(getReportHeadcountAction.request.type, getReportHeadcountSaga),
    takeLatest(getReportMonthlyTimekeepingAction.request.type, getReportMonthlyTimekeepingSaga),
    takeLatest(getReportOffboardAction.request.type, getReportOffboardSaga),
    takeLatest(getReportOnboardAction.request.type, getReportOnboardSaga),
    takeLatest(getReportTimeOffBalanceAction.request.type, getReportTimeOffBalanceSaga),
    takeLatest(getReportTimeOffScheduleAction.request.type, getReportTimeOffScheduleSaga),
  ]);
}

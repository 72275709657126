import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteAssetAction } from '@/redux/actions';
import { deleteAsset, TDeleteAssetResponse } from '@/services/api';

// FUNCTION

export function* deleteAssetSaga(action: ActionType<typeof deleteAssetAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteAsset, materials);
    const deleteAssetResponse: TDeleteAssetResponse = response as TDeleteAssetResponse;
    yield put(deleteAssetAction.success(deleteAssetResponse));
    successCallback?.(deleteAssetResponse);
  } catch (err) {
    yield put(deleteAssetAction.failure(err));
    failedCallback?.(err);
  }
}

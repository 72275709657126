import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Avatar from '@/components/Avatar';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import { TRootState } from '@/redux/reducers';
import { EEmpty } from '@/common/enums';

import { TAccountDropdownProps } from './AccountDropdown.types';
import './AccountDropdown.scss';

const AccountDropdown: React.FC<TAccountDropdownProps> = ({ onLogout, onClose, onFeedback }) => {
  const { t } = useTranslation();
  const myInfoState = useSelector((state: TRootState) => state.userReducer.getMyInfoResponse);

  const dataAccountDropdownList = [
    {
      icon: EIconName.User,
      title: t('AccountDropdown_account_settings'),
      suffixIcon: EIconName.Expand,
      onClick: (): void => {
        window.open('https://id.acaziasoft.com/app/manage-profile/my-profile', '_blank');
      },
    },
    {
      icon: EIconName.Lock,
      title: t('AccountDropdown_change_password'),
      onClick: (): void => {
        window.open('https://id.acaziasoft.com/app/manage-profile/security', '_blank');
      },
    },
    {
      icon: EIconName.Logout,
      title: t('AccountDropdown_logout'),
      danger: true,
      onClick: (): void => {
        onLogout?.();
      },
    },
    {
      icon: EIconName.Star,
      title: t('AccountDropdown_give_us_feedback'),
      border: true,
      onClick: onFeedback,
    },
  ];

  return (
    <div className="AccountDropdown">
      <div className="AccountDropdown-account flex items-center">
        <Avatar size={48} image={myInfoState?.avatar} />
        <div className="AccountDropdown-account-info">
          <div className="AccountDropdown-account-info-title">{myInfoState?.fullName || EEmpty.DASH}</div>
          <div className="AccountDropdown-account-info-description">{myInfoState?.email || EEmpty.DASH}</div>
        </div>
      </div>

      <div className="AccountDropdown-list">
        {dataAccountDropdownList.map((item) => (
          <div
            className={classNames('AccountDropdown-list-item flex items-center', {
              danger: item.danger,
              border: item.border,
            })}
            onClick={(): void => {
              onClose?.();
              item.onClick?.();
            }}
          >
            <div className="AccountDropdown-list-item-icon">
              <Icon name={item.icon} color={item.danger ? EIconColor.RED_500 : EIconColor.GRAYSCALE_900} />
            </div>

            <div className="AccountDropdown-list-item-title">{item.title}</div>

            {item.suffixIcon && (
              <div className="AccountDropdown-list-item-icon">
                <Icon name={item.suffixIcon} color={item.danger ? EIconColor.RED_500 : EIconColor.GRAYSCALE_900} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountDropdown;

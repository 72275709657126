import { TReportState } from '@/redux/reducers/report';
import { TGetReportEmployeeTurnoverRateSuccess } from '@/redux/actions/report';

export const getReportEmployeeTurnoverRateUpdateState = (
  state: TReportState,
  action: TGetReportEmployeeTurnoverRateSuccess,
): TReportState => ({
  ...state,
  getReportEmployeeTurnoverRateResponse: action.payload.response,
});

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateTaskAction } from '@/redux/actions';
import { updateTask, TUpdateTaskResponse } from '@/services/api';

// FUNCTION

export function* updateTaskSaga(action: ActionType<typeof updateTaskAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateTask, materials);
    const updateTaskResponse: TUpdateTaskResponse = response as TUpdateTaskResponse;
    yield put(updateTaskAction.success(updateTaskResponse));
    successCallback?.(updateTaskResponse);
  } catch (err) {
    yield put(updateTaskAction.failure(err));
    failedCallback?.(err);
  }
}

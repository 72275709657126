import { TRegulateCategoryState } from '@/redux/reducers/regulate-category';
import { TDeleteRegulateCategorySuccess } from '@/redux/actions/regulate-category';

export const deleteRegulateCategoryUpdateState = (
  state: TRegulateCategoryState,
  action: TDeleteRegulateCategorySuccess,
): TRegulateCategoryState => ({
  ...state,
  deleteRegulateCategoryResponse: action.payload.response,
});

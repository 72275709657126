import { createActionCreator } from 'deox';

import { TUpdateSettingMaterials, TUpdateSettingResponse } from '@/services/api/setting/update-setting';

// CONSTANTS

export enum EUpdateSettingAction {
  UPDATE_SETTING = 'UPDATE_SETTING',
  UPDATE_SETTING_REQUEST = 'UPDATE_SETTING_REQUEST',
  UPDATE_SETTING_SUCCESS = 'UPDATE_SETTING_SUCCESS',
  UPDATE_SETTING_FAILED = 'UPDATE_SETTING_FAILED',
}

// TYPES

export type TUpdateSettingRequest = {
  type: EUpdateSettingAction.UPDATE_SETTING_REQUEST;
  payload: {
    materials: TUpdateSettingMaterials;
    successCallback?: (response: TUpdateSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateSettingSuccess = {
  type: EUpdateSettingAction.UPDATE_SETTING_SUCCESS;
  payload: { response: TUpdateSettingResponse };
};

export type TUpdateSettingFailed = { type: EUpdateSettingAction.UPDATE_SETTING_FAILED };

// FUNCTION

export const updateSettingAction = {
  request: createActionCreator(
    EUpdateSettingAction.UPDATE_SETTING_REQUEST,
    (resolve) =>
      (
        materials: TUpdateSettingMaterials,
        successCallback?: (response: TUpdateSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateSettingRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateSettingAction.UPDATE_SETTING_SUCCESS,
    (resolve) =>
      (response: TUpdateSettingResponse): TUpdateSettingSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateSettingAction.UPDATE_SETTING_FAILED,
    (resolve) =>
      (error: unknown): TUpdateSettingFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TGetRegulationMaterials, TGetRegulationResponse } from '@/services/api/regulation/get-regulation';

// CONSTANTS

export enum EGetRegulationAction {
  GET_REGULATION = 'GET_REGULATION',
  GET_REGULATION_REQUEST = 'GET_REGULATION_REQUEST',
  GET_REGULATION_SUCCESS = 'GET_REGULATION_SUCCESS',
  GET_REGULATION_FAILED = 'GET_REGULATION_FAILED',
}

// TYPES

export type TGetRegulationRequest = {
  type: EGetRegulationAction.GET_REGULATION_REQUEST;
  payload: {
    materials: TGetRegulationMaterials;
    successCallback?: (response: TGetRegulationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRegulationSuccess = {
  type: EGetRegulationAction.GET_REGULATION_SUCCESS;
  payload: { response?: TGetRegulationResponse };
};

export type TGetRegulationFailed = { type: EGetRegulationAction.GET_REGULATION_FAILED };

// FUNCTION

export const getRegulationAction = {
  request: createActionCreator(
    EGetRegulationAction.GET_REGULATION_REQUEST,
    (resolve) =>
      (
        materials: TGetRegulationMaterials,
        successCallback?: (response: TGetRegulationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRegulationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRegulationAction.GET_REGULATION_SUCCESS,
    (resolve) =>
      (response?: TGetRegulationResponse): TGetRegulationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRegulationAction.GET_REGULATION_FAILED,
    (resolve) =>
      (error: unknown): TGetRegulationFailed =>
        resolve({ error }),
  ),
};

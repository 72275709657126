import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { downloadPeopleListAction } from '@/redux/actions';
import { downloadPeopleList, TDownloadPeopleListResponse } from '@/services/api';

// FUNCTION

export function* downloadPeopleListSaga(action: ActionType<typeof downloadPeopleListAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(downloadPeopleList, materials);
    const downloadPeopleListResponse: TDownloadPeopleListResponse = response as TDownloadPeopleListResponse;
    yield put(downloadPeopleListAction.success(downloadPeopleListResponse));
    successCallback?.(downloadPeopleListResponse);
  } catch (err) {
    yield put(downloadPeopleListAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TOnboardingUserFlowParams = unknown;
export type TOnboardingUserFlowBody = unknown;

export type TOnboardingUserFlowMaterials = {
  params?: TOnboardingUserFlowParams;
  body?: TOnboardingUserFlowBody;
};

export type TOnboardingUserFlowResponse = unknown;

// FUNCTION

export const onboardingUserFlow = async ({
  params,
  body,
}: TOnboardingUserFlowMaterials): Promise<TOnboardingUserFlowResponse> => {
  const response = await ApiService.post(`/user/onboarding`, body, { params });
  return response.data;
};

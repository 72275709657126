import { TDepartmentState } from '@/redux/reducers/department';
import { TCreateDepartmentSuccess } from '@/redux/actions/department';

export const createDepartmentUpdateState = (
  state: TDepartmentState,
  action: TCreateDepartmentSuccess,
): TDepartmentState => ({
  ...state,
  createDepartmentResponse: action.payload.response,
});

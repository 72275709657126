import { all, takeLatest } from 'redux-saga/effects';

import {
  changePasswordAction,
  createNewBackgroundSettingAction,
  deleteBackgroundSettingAction,
  getSettingsAction,
  updateSettingAction,
} from '@/redux/actions';

import { changePasswordSaga } from './change-password';
import { createNewBackgroundSettingSaga } from './create-new-background-setting';
import { deleteBackgroundSettingSaga } from './delete-background-setting';
import { getSettingsSaga } from './get-settings';
import { updateSettingSaga } from './update-setting';

export default function* root(): Generator {
  yield all([
    takeLatest(changePasswordAction.request.type, changePasswordSaga),
    takeLatest(createNewBackgroundSettingAction.request.type, createNewBackgroundSettingSaga),
    takeLatest(deleteBackgroundSettingAction.request.type, deleteBackgroundSettingSaga),
    takeLatest(getSettingsAction.request.type, getSettingsSaga),
    takeLatest(updateSettingAction.request.type, updateSettingSaga),
  ]);
}

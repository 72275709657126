import { createActionCreator } from 'deox';

import { TRequestTimeOffMaterials, TRequestTimeOffResponse } from '@/services/api/request/request-time-off';

// CONSTANTS

export enum ERequestTimeOffAction {
  REQUEST_TIME_OFF = 'REQUEST_TIME_OFF',
  REQUEST_TIME_OFF_REQUEST = 'REQUEST_TIME_OFF_REQUEST',
  REQUEST_TIME_OFF_SUCCESS = 'REQUEST_TIME_OFF_SUCCESS',
  REQUEST_TIME_OFF_FAILED = 'REQUEST_TIME_OFF_FAILED',
}

// TYPES

export type TRequestTimeOffRequest = {
  type: ERequestTimeOffAction.REQUEST_TIME_OFF_REQUEST;
  payload: {
    materials: TRequestTimeOffMaterials;
    successCallback?: (response: TRequestTimeOffResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRequestTimeOffSuccess = {
  type: ERequestTimeOffAction.REQUEST_TIME_OFF_SUCCESS;
  payload: { response: TRequestTimeOffResponse };
};

export type TRequestTimeOffFailed = { type: ERequestTimeOffAction.REQUEST_TIME_OFF_FAILED };

// FUNCTION

export const requestTimeOffAction = {
  request: createActionCreator(
    ERequestTimeOffAction.REQUEST_TIME_OFF_REQUEST,
    (resolve) =>
      (
        materials: TRequestTimeOffMaterials,
        successCallback?: (response: TRequestTimeOffResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRequestTimeOffRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERequestTimeOffAction.REQUEST_TIME_OFF_SUCCESS,
    (resolve) =>
      (response: TRequestTimeOffResponse): TRequestTimeOffSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERequestTimeOffAction.REQUEST_TIME_OFF_FAILED,
    (resolve) =>
      (error: unknown): TRequestTimeOffFailed =>
        resolve({ error }),
  ),
};

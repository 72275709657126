import { createReducer } from 'deox';

import {
  TGetBanksResponse,
  TGetIconTimeOffResponse,
  TGetNationalsResponse,
  TGetStatesResponse,
} from '@/services/api/widen';
import { getBanksAction, getIconTimeOffAction, getNationalsAction, getStatesAction } from '@/redux/actions';
import { getBanksUpdateState } from './get-banks';
import { getIconTimeOffUpdateState } from './get-icon-time-off';
import { getNationalsUpdateState } from './get-nationals';
import { getStatesUpdateState } from './get-states';

export type TWidenState = {
  getBanksResponse?: TGetBanksResponse;
  getIconTimeOffResponse?: TGetIconTimeOffResponse;
  getNationalsResponse?: TGetNationalsResponse;
  getStatesResponse?: TGetStatesResponse;
};

const initialState: TWidenState = {
  getBanksResponse: undefined,
  getIconTimeOffResponse: undefined,
  getNationalsResponse: undefined,
  getStatesResponse: undefined,
};

const WidenReducer = createReducer(initialState, (handleAction) => [
  handleAction(getBanksAction.success, getBanksUpdateState),
  handleAction(getIconTimeOffAction.success, getIconTimeOffUpdateState),
  handleAction(getNationalsAction.success, getNationalsUpdateState),
  handleAction(getStatesAction.success, getStatesUpdateState),
]);

export default WidenReducer;

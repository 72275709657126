import { TRegulation } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetRegulationPaths = {
  id: string | number;
};
export type TGetRegulationParams = unknown;

export type TGetRegulationMaterials = {
  paths?: TGetRegulationPaths;
  params?: TGetRegulationParams;
};

export type TGetRegulationResponse = TRegulation;

// FUNCTION

export const getRegulation = async ({ paths, params }: TGetRegulationMaterials): Promise<TGetRegulationResponse> => {
  const response = await ApiService.get(`/regulation/${paths?.id}`, { params });
  return response.data;
};

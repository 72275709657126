import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getMyAssetsAction } from '@/redux/actions';
import { getMyAssets, TGetMyAssetsResponse } from '@/services/api';

// FUNCTION

export function* getMyAssetsSaga(action: ActionType<typeof getMyAssetsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getMyAssets, materials);
    const getMyAssetsResponse: TGetMyAssetsResponse = response as TGetMyAssetsResponse;
    yield put(getMyAssetsAction.success(getMyAssetsResponse));
    successCallback?.(getMyAssetsResponse);
  } catch (err) {
    yield put(getMyAssetsAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TImportUsersMaterials, TImportUsersResponse } from '@/services/api/user/import-users';

// CONSTANTS

export enum EImportUsersAction {
  IMPORT_USERS = 'IMPORT_USERS',
  IMPORT_USERS_REQUEST = 'IMPORT_USERS_REQUEST',
  IMPORT_USERS_SUCCESS = 'IMPORT_USERS_SUCCESS',
  IMPORT_USERS_FAILED = 'IMPORT_USERS_FAILED',
}

// TYPES

export type TImportUsersRequest = {
  type: EImportUsersAction.IMPORT_USERS_REQUEST;
  payload: {
    materials: TImportUsersMaterials;
    successCallback?: (response: TImportUsersResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TImportUsersSuccess = {
  type: EImportUsersAction.IMPORT_USERS_SUCCESS;
  payload: { response: TImportUsersResponse };
};

export type TImportUsersFailed = { type: EImportUsersAction.IMPORT_USERS_FAILED };

// FUNCTION

export const importUsersAction = {
  request: createActionCreator(
    EImportUsersAction.IMPORT_USERS_REQUEST,
    (resolve) =>
      (
        materials: TImportUsersMaterials,
        successCallback?: (response: TImportUsersResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TImportUsersRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EImportUsersAction.IMPORT_USERS_SUCCESS,
    (resolve) =>
      (response: TImportUsersResponse): TImportUsersSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EImportUsersAction.IMPORT_USERS_FAILED,
    (resolve) =>
      (error: unknown): TImportUsersFailed =>
        resolve({ error }),
  ),
};

import { createReducer } from 'deox';

import { TAddApproverResponse, TGetApproversResponse, TRemoveApproverResponse } from '@/services/api/approver';
import { addApproverAction, getApproversAction, removeApproverAction } from '@/redux/actions';
import { addApproverUpdateState } from './add-approver';
import { getApproversUpdateState } from './get-approvers';
import { removeApproverUpdateState } from './remove-approver';

export type TApproverState = {
  addApproverResponse?: TAddApproverResponse;
  getApproversResponse?: TGetApproversResponse;
  removeApproverResponse?: TRemoveApproverResponse;
};

const initialState: TApproverState = {
  addApproverResponse: undefined,
  getApproversResponse: undefined,
  removeApproverResponse: undefined,
};

const ApproverReducer = createReducer(initialState, (handleAction) => [
  handleAction(addApproverAction.success, addApproverUpdateState),
  handleAction(getApproversAction.success, getApproversUpdateState),
  handleAction(removeApproverAction.success, removeApproverUpdateState),
]);

export default ApproverReducer;

import { TUploadFile } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetIconTimeOffParams = unknown;

export type TGetIconTimeOffMaterials = {
  params?: TGetIconTimeOffParams;
};

export type TGetIconTimeOffResponse = TUploadFile[];

// FUNCTION

export const getIconTimeOff = async ({ params }: TGetIconTimeOffMaterials): Promise<TGetIconTimeOffResponse> => {
  const response = await ApiService.get(`/widen/ico-time-off/list`, { params });
  return response.data;
};

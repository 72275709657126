import { TBenefitCoverage } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetBenefitCoveragesParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
};

export type TGetBenefitCoveragesMaterials = {
  params?: TGetBenefitCoveragesParams;
};

export type TGetBenefitCoveragesResponse = TPaginateResponse & {
  data: TBenefitCoverage[];
};

// FUNCTION

export const getBenefitCoverages = async ({
  params,
}: TGetBenefitCoveragesMaterials): Promise<TGetBenefitCoveragesResponse> => {
  const response = await ApiService.get(`/benefit/coverage-option/list`, { params });
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_400 }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6.75C3 5.92157 3.67157 5.25 4.5 5.25H19.5C20.3284 5.25 21 5.92157 21 6.75V17.25C21 18.0784 20.3284 18.75 19.5 18.75H4.5C3.67157 18.75 3 18.0784 3 17.25V6.75Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <path
        d="M3.75 6L11.0501 12.75C11.6027 13.2021 12.3973 13.2021 12.9499 12.75L20.25 6"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M3.75 17.5898L9.33984 12M14.11 12C16.9609 14.636 20.25 17.5898 20.25 17.5898"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Svg;

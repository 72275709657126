import { TBank } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetBanksParams = unknown;

export type TGetBanksMaterials = {
  params?: TGetBanksParams;
};

export type TGetBanksResponse = TBank[];

// FUNCTION

export const getBanks = async ({ params }: TGetBanksMaterials): Promise<TGetBanksResponse> => {
  const response = await ApiService.get(`/widen/bank/list`, { params });
  return response.data;
};

import { all, takeLatest } from 'redux-saga/effects';

import { createJobAction, deleteJobAction, getJobAction, getJobsAction, updateJobAction } from '@/redux/actions';

import { createJobSaga } from './create-job';
import { deleteJobSaga } from './delete-job';
import { getJobSaga } from './get-job';
import { getJobsSaga } from './get-jobs';
import { updateJobSaga } from './update-job';

export default function* root(): Generator {
  yield all([
    takeLatest(createJobAction.request.type, createJobSaga),
    takeLatest(deleteJobAction.request.type, deleteJobSaga),
    takeLatest(getJobAction.request.type, getJobSaga),
    takeLatest(getJobsAction.request.type, getJobsSaga),
    takeLatest(updateJobAction.request.type, updateJobSaga),
  ]);
}

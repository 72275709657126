import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateAssignOnboardAction } from '@/redux/actions';
import { updateAssignOnboard, TUpdateAssignOnboardResponse } from '@/services/api';

// FUNCTION

export function* updateAssignOnboardSaga(action: ActionType<typeof updateAssignOnboardAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateAssignOnboard, materials);
    const updateAssignOnboardResponse: TUpdateAssignOnboardResponse = response as TUpdateAssignOnboardResponse;
    yield put(updateAssignOnboardAction.success(updateAssignOnboardResponse));
    successCallback?.(updateAssignOnboardResponse);
  } catch (err) {
    yield put(updateAssignOnboardAction.failure(err));
    failedCallback?.(err);
  }
}

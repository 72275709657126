import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_600 }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4463 6.43917L14.7607 8.96092C14.6149 9.0978 14.5647 9.30837 14.633 9.4963L17.1939 16.5387C17.2603 16.7212 17.2149 16.9257 17.0775 17.0631L15.6423 18.4984C15.4208 18.7198 15.0525 18.6855 14.8759 18.4269L11.3832 13.3144C11.2119 13.0638 10.8587 13.0223 10.6341 13.2264L9.28327 14.4536C9.07466 14.6431 8.95572 14.9119 8.95572 15.1937V16.4237C8.95572 16.6889 8.85036 16.9433 8.66283 17.1308L7.21023 18.5834C7.05302 18.7406 6.78539 18.6782 6.71399 18.4676L5.63497 15.2856L2.45739 14.2104C2.2467 14.1391 2.18414 13.8714 2.34141 13.7141L3.79375 12.2618C3.98129 12.0742 4.23564 11.9689 4.50086 11.9689H5.75918C6.0244 11.9689 6.27875 11.8635 6.46629 11.676L7.82887 10.3134C8.05198 10.0903 8.0152 9.71883 7.75267 9.54381L2.50737 6.04694C2.24484 5.87192 2.20806 5.50047 2.43117 5.27737L3.86148 3.84705C3.99885 3.70969 4.20334 3.66432 4.38591 3.73071L11.4295 6.292C11.6168 6.36012 11.8267 6.31046 11.9636 6.16561L14.4952 3.48806L14.436 3.5375C14.6297 3.34338 14.8597 3.18934 15.113 3.08419C15.3663 2.97903 15.6378 2.92483 15.912 2.92468C16.1862 2.92453 16.4578 2.97843 16.7112 3.08331C16.9646 3.18818 17.1948 3.34197 17.3887 3.53588C17.5826 3.72979 17.7364 3.96001 17.8413 4.2134C17.9462 4.46678 18.0001 4.73835 17.9999 5.01258C17.9998 5.2868 17.9456 5.55831 17.8404 5.81158C17.7352 6.06485 17.5812 6.29491 17.3871 6.4886L17.4463 6.43917Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import ApiService from '@/services/api';

// TYPES

export type TDeleteMemberRolePaths = {
  id: string | number;
};
export type TDeleteMemberRoleParams = unknown;

export type TDeleteMemberRoleMaterials = {
  paths?: TDeleteMemberRolePaths;
  params?: TDeleteMemberRoleParams;
};

export type TDeleteMemberRoleResponse = unknown;

// FUNCTION

export const deleteMemberRole = async ({
  paths,
  params,
}: TDeleteMemberRoleMaterials): Promise<TDeleteMemberRoleResponse> => {
  const response = await ApiService.delete(`/role/members/${paths?.id}`, { params });
  return response.data;
};

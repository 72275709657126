import React from 'react';

import { TAuthProps } from '@/layouts/Auth/Auth.types';
// import ChangeLanguageBtn from '@/components/ChangeLanguageBtn';

const Auth: React.FC<TAuthProps> = ({ children }) => {
  return (
    <div className="Auth">
      {/* <ChangeLanguageBtn /> */}
      {children}
    </div>
  );
};

export default Auth;

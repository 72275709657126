import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { welcomeUserAction } from '@/redux/actions';
import { welcomeUser, TWelcomeUserResponse } from '@/services/api';

// FUNCTION

export function* welcomeUserSaga(action: ActionType<typeof welcomeUserAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(welcomeUser, materials);
    const welcomeUserResponse: TWelcomeUserResponse = response as TWelcomeUserResponse;
    yield put(welcomeUserAction.success(welcomeUserResponse));
    successCallback?.(welcomeUserResponse);
  } catch (err) {
    yield put(welcomeUserAction.failure(err));
    failedCallback?.(err);
  }
}

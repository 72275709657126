import ApiService from '@/services/api';

// TYPES

export type TUpdateRolePermissionPaths = {
  id: string | number;
};
export type TUpdateRolePermissionBody = unknown;

export type TUpdateRolePermissionMaterials = {
  paths?: TUpdateRolePermissionPaths;
  body?: TUpdateRolePermissionBody;
};

export type TUpdateRolePermissionResponse = unknown;

// FUNCTION

export const updateRolePermission = async ({
  paths,
  body,
}: TUpdateRolePermissionMaterials): Promise<TUpdateRolePermissionResponse> => {
  const response = await ApiService.put(`/role/permission/${paths?.id}`, body);
  return response.data;
};

import { TRequestState } from '@/redux/reducers/request';
import { TGetRecentTimeOffRequestsSuccess } from '@/redux/actions/request';

export const getRecentTimeOffRequestsUpdateState = (
  state: TRequestState,
  action: TGetRecentTimeOffRequestsSuccess,
): TRequestState => ({
  ...state,
  getRecentTimeOffRequestsResponse: action.payload.response,
});

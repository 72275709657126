import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTaskCategoryAction } from '@/redux/actions';
import { getTaskCategory, TGetTaskCategoryResponse } from '@/services/api';

// FUNCTION

export function* getTaskCategorySaga(action: ActionType<typeof getTaskCategoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTaskCategory, materials);
    const getTaskCategoryResponse: TGetTaskCategoryResponse = response as TGetTaskCategoryResponse;
    yield put(getTaskCategoryAction.success(getTaskCategoryResponse));
    successCallback?.(getTaskCategoryResponse);
  } catch (err) {
    yield put(getTaskCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

import { TRequest } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetCcMeRequestsParams = unknown;

export type TGetCcMeRequestsMaterials = {
  params?: TGetCcMeRequestsParams;
};

export type TGetCcMeRequestsResponse = TPaginateResponse & {
  data: TRequest[];
  subTotal: { [key: string]: number };
  total: { [key: string]: number };
};

// FUNCTION

export const getCcMeRequests = async ({ params }: TGetCcMeRequestsMaterials): Promise<TGetCcMeRequestsResponse> => {
  const response = await ApiService.get(`/request/management/cc-me-requests`, { params });
  return response.data;
};

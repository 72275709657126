import { createActionCreator } from 'deox';

import { TDeleteRequestMaterials, TDeleteRequestResponse } from '@/services/api/request/delete-request';

// CONSTANTS

export enum EDeleteRequestAction {
  DELETE_REQUEST = 'DELETE_REQUEST',
  DELETE_REQUEST_REQUEST = 'DELETE_REQUEST_REQUEST',
  DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS',
  DELETE_REQUEST_FAILED = 'DELETE_REQUEST_FAILED',
}

// TYPES

export type TDeleteRequestRequest = {
  type: EDeleteRequestAction.DELETE_REQUEST_REQUEST;
  payload: {
    materials: TDeleteRequestMaterials;
    successCallback?: (response: TDeleteRequestResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteRequestSuccess = {
  type: EDeleteRequestAction.DELETE_REQUEST_SUCCESS;
  payload: { response: TDeleteRequestResponse };
};

export type TDeleteRequestFailed = { type: EDeleteRequestAction.DELETE_REQUEST_FAILED };

// FUNCTION

export const deleteRequestAction = {
  request: createActionCreator(
    EDeleteRequestAction.DELETE_REQUEST_REQUEST,
    (resolve) =>
      (
        materials: TDeleteRequestMaterials,
        successCallback?: (response: TDeleteRequestResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteRequestRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteRequestAction.DELETE_REQUEST_SUCCESS,
    (resolve) =>
      (response: TDeleteRequestResponse): TDeleteRequestSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteRequestAction.DELETE_REQUEST_FAILED,
    (resolve) =>
      (error: unknown): TDeleteRequestFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TPatchTimeOffsMaterials, TPatchTimeOffsResponse } from '@/services/api/time-off-type/patch-time-offs';

// CONSTANTS

export enum EPatchTimeOffsAction {
  PATCH_TIME_OFFS = 'PATCH_TIME_OFFS',
  PATCH_TIME_OFFS_REQUEST = 'PATCH_TIME_OFFS_REQUEST',
  PATCH_TIME_OFFS_SUCCESS = 'PATCH_TIME_OFFS_SUCCESS',
  PATCH_TIME_OFFS_FAILED = 'PATCH_TIME_OFFS_FAILED',
}

// TYPES

export type TPatchTimeOffsRequest = {
  type: EPatchTimeOffsAction.PATCH_TIME_OFFS_REQUEST;
  payload: {
    materials: TPatchTimeOffsMaterials;
    successCallback?: (response: TPatchTimeOffsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPatchTimeOffsSuccess = {
  type: EPatchTimeOffsAction.PATCH_TIME_OFFS_SUCCESS;
  payload: { response: TPatchTimeOffsResponse };
};

export type TPatchTimeOffsFailed = { type: EPatchTimeOffsAction.PATCH_TIME_OFFS_FAILED };

// FUNCTION

export const patchTimeOffsAction = {
  request: createActionCreator(
    EPatchTimeOffsAction.PATCH_TIME_OFFS_REQUEST,
    (resolve) =>
      (
        materials: TPatchTimeOffsMaterials,
        successCallback?: (response: TPatchTimeOffsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPatchTimeOffsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EPatchTimeOffsAction.PATCH_TIME_OFFS_SUCCESS,
    (resolve) =>
      (response: TPatchTimeOffsResponse): TPatchTimeOffsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EPatchTimeOffsAction.PATCH_TIME_OFFS_FAILED,
    (resolve) =>
      (error: unknown): TPatchTimeOffsFailed =>
        resolve({ error }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 10.25C4.99264 10.25 6 9.24264 6 8C6 6.75736 4.99264 5.75 3.75 5.75C2.50736 5.75 1.5 6.75736 1.5 8C1.5 9.24264 2.50736 10.25 3.75 10.25Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 14.5C13.4926 14.5 14.5 13.4926 14.5 12.25C14.5 11.0074 13.4926 10 12.25 10C11.0074 10 10 11.0074 10 12.25C10 13.4926 11.0074 14.5 12.25 14.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 6C13.4926 6 14.5 4.99264 14.5 3.75C14.5 2.50736 13.4926 1.5 12.25 1.5C11.0074 1.5 10 2.50736 10 3.75C10 4.99264 11.0074 6 12.25 6Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.75977 7L10.2398 4.75" stroke="#000001" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.75977 9L10.2398 11.25" stroke="#000001" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Svg;

import { TDepartmentState } from '@/redux/reducers/department';
import { TUpdateDepartmentSuccess } from '@/redux/actions/department';

export const updateDepartmentUpdateState = (
  state: TDepartmentState,
  action: TUpdateDepartmentSuccess,
): TDepartmentState => ({
  ...state,
  updateDepartmentResponse: action.payload.response,
});

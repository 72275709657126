import { TRequestState } from '@/redux/reducers/request';
import { TRequestBorrowAssetSuccess } from '@/redux/actions/request';

export const requestBorrowAssetUpdateState = (
  state: TRequestState,
  action: TRequestBorrowAssetSuccess,
): TRequestState => ({
  ...state,
  requestBorrowAssetResponse: action.payload.response,
});

import { createReducer } from 'deox';

import { TGetUsersGlobalResponse } from '@/services/api/global';
import { getUsersGlobalAction } from '@/redux/actions';
import { getUsersGlobalUpdateState } from './get-users-global';

export type TGlobalState = {
  getUsersGlobalResponse?: TGetUsersGlobalResponse;
};

const initialState: TGlobalState = {
  getUsersGlobalResponse: undefined,
};

const GlobalReducer = createReducer(initialState, (handleAction) => [
  handleAction(getUsersGlobalAction.success, getUsersGlobalUpdateState),
]);

export default GlobalReducer;

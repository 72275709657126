import { createActionCreator } from 'deox';

import { TAssignOffboardMaterials, TAssignOffboardResponse } from '@/services/api/checklist/assign-offboard';

// CONSTANTS

export enum EAssignOffboardAction {
  ASSIGN_OFFBOARD = 'ASSIGN_OFFBOARD',
  ASSIGN_OFFBOARD_REQUEST = 'ASSIGN_OFFBOARD_REQUEST',
  ASSIGN_OFFBOARD_SUCCESS = 'ASSIGN_OFFBOARD_SUCCESS',
  ASSIGN_OFFBOARD_FAILED = 'ASSIGN_OFFBOARD_FAILED',
}

// TYPES

export type TAssignOffboardRequest = {
  type: EAssignOffboardAction.ASSIGN_OFFBOARD_REQUEST;
  payload: {
    materials: TAssignOffboardMaterials;
    successCallback?: (response: TAssignOffboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAssignOffboardSuccess = {
  type: EAssignOffboardAction.ASSIGN_OFFBOARD_SUCCESS;
  payload: { response: TAssignOffboardResponse };
};

export type TAssignOffboardFailed = { type: EAssignOffboardAction.ASSIGN_OFFBOARD_FAILED };

// FUNCTION

export const assignOffboardAction = {
  request: createActionCreator(
    EAssignOffboardAction.ASSIGN_OFFBOARD_REQUEST,
    (resolve) =>
      (
        materials: TAssignOffboardMaterials,
        successCallback?: (response: TAssignOffboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAssignOffboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAssignOffboardAction.ASSIGN_OFFBOARD_SUCCESS,
    (resolve) =>
      (response: TAssignOffboardResponse): TAssignOffboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAssignOffboardAction.ASSIGN_OFFBOARD_FAILED,
    (resolve) =>
      (error: unknown): TAssignOffboardFailed =>
        resolve({ error }),
  ),
};

import { TCompany } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetCompanyParams = unknown;

export type TGetCompanyMaterials = {
  params?: TGetCompanyParams;
};

export type TGetCompanyResponse = TCompany;

// FUNCTION

export const getCompany = async ({ params }: TGetCompanyMaterials): Promise<TGetCompanyResponse> => {
  const response = await ApiService.get(`/company`, { params });
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8334 2.5H12.3334C12.5986 2.5 12.8529 2.60536 13.0405 2.79289C13.228 2.98043 13.3334 3.23478 13.3334 3.5V13.5C13.3334 13.7652 13.228 14.0196 13.0405 14.2071C12.8529 14.3946 12.5986 14.5 12.3334 14.5H4.33337C4.06816 14.5 3.8138 14.3946 3.62627 14.2071C3.43873 14.0196 3.33337 13.7652 3.33337 13.5V3.5C3.33337 3.23478 3.43873 2.98043 3.62627 2.79289C3.8138 2.60536 4.06816 2.5 4.33337 2.5H5.83337"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83337 1.5H6.83337C6.28109 1.5 5.83337 1.94772 5.83337 2.5V3C5.83337 3.55228 6.28109 4 6.83337 4H9.83337C10.3857 4 10.8334 3.55228 10.8334 3V2.5C10.8334 1.94772 10.3857 1.5 9.83337 1.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.83337 6.5H10.8334" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.83337 9H10.8334" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.83337 11.5H10.8334" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Svg;

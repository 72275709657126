import { TAssetState } from '@/redux/reducers/asset';
import { TCreateAssetCategorySuccess } from '@/redux/actions/asset';

export const createAssetCategoryUpdateState = (
  state: TAssetState,
  action: TCreateAssetCategorySuccess,
): TAssetState => ({
  ...state,
  createAssetCategoryResponse: action.payload.response,
});

import ApiService from '@/services/api';

// TYPES

export type TDuplicateTaskParams = unknown;
export type TDuplicateTaskBody = unknown;

export type TDuplicateTaskMaterials = {
  params?: TDuplicateTaskParams;
  body?: TDuplicateTaskBody;
};

export type TDuplicateTaskResponse = unknown;

// FUNCTION

export const duplicateTask = async ({ params, body }: TDuplicateTaskMaterials): Promise<TDuplicateTaskResponse> => {
  const response = await ApiService.post(`/template/task/duplicate`, body, { params });
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';

const Svg: React.FC<TIconProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75049 5.25C3.75049 4.83579 4.05828 4.5 4.43796 4.5H19.5624C19.9421 4.5 20.2499 4.83579 20.2499 5.25V18.75C20.2499 19.1642 19.9421 19.5 19.5624 19.5H4.43797C4.05828 19.5 3.75049 19.1642 3.75049 18.75V5.25Z"
        stroke="#0984E3"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51784 9.74989C8.97712 9.1641 8.10043 9.1641 7.5597 9.74989L3.40585 14.2499C3.15257 14.5243 3.00755 14.894 3.00057 15.2812C3.00019 15.291 3 15.3008 3 15.3105V18.7499C3 18.7598 3.00019 18.7696 3.00057 18.7794C3.01511 19.5942 3.62934 20.2499 4.38492 20.2499H19.6157C20.3804 20.2499 21.0003 19.5783 21.0003 18.7499V16.8105C21.0003 16.4127 20.8544 16.0312 20.5948 15.7499L17.1332 11.9999C16.5925 11.4141 15.7158 11.4141 15.1751 11.9999L13.3849 13.9392L9.51784 9.74989Z"
        fill="#0984E3"
      />
      <ellipse cx="15.3855" cy="8.61133" rx="0.9375" ry="0.9375" fill="#0984E3" />
    </svg>
  );
};

export default Svg;

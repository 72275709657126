import ApiService from '@/services/api';

// TYPES

export type TUpdateMyCareerBody = unknown;

export type TUpdateMyCareerMaterials = {
  body?: TUpdateMyCareerBody;
};

export type TUpdateMyCareerResponse = unknown;

// FUNCTION

export const updateMyCareer = async ({ body }: TUpdateMyCareerMaterials): Promise<TUpdateMyCareerResponse> => {
  const response = await ApiService.patch(`/user/career/my-info`, body);
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getMyAssetsHistoryAction } from '@/redux/actions';
import { getMyAssetsHistory, TGetMyAssetsHistoryResponse } from '@/services/api';

// FUNCTION

export function* getMyAssetsHistorySaga(action: ActionType<typeof getMyAssetsHistoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getMyAssetsHistory, materials);
    const getMyAssetsHistoryResponse: TGetMyAssetsHistoryResponse = response as TGetMyAssetsHistoryResponse;
    yield put(getMyAssetsHistoryAction.success(getMyAssetsHistoryResponse));
    successCallback?.(getMyAssetsHistoryResponse);
  } catch (err) {
    yield put(getMyAssetsHistoryAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { removeApproverAction } from '@/redux/actions';
import { removeApprover, TRemoveApproverResponse } from '@/services/api';

// FUNCTION

export function* removeApproverSaga(action: ActionType<typeof removeApproverAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(removeApprover, materials);
    const removeApproverResponse: TRemoveApproverResponse = response as TRemoveApproverResponse;
    yield put(removeApproverAction.success(removeApproverResponse));
    successCallback?.(removeApproverResponse);
  } catch (err) {
    yield put(removeApproverAction.failure(err));
    failedCallback?.(err);
  }
}

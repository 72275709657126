import { createActionCreator } from 'deox';

import {
  TChangeUserTypeStatusMaterials,
  TChangeUserTypeStatusResponse,
} from '@/services/api/user/change-user-type-status';

// CONSTANTS

export enum EChangeUserTypeStatusAction {
  CHANGE_USER_TYPE_STATUS = 'CHANGE_USER_TYPE_STATUS',
  CHANGE_USER_TYPE_STATUS_REQUEST = 'CHANGE_USER_TYPE_STATUS_REQUEST',
  CHANGE_USER_TYPE_STATUS_SUCCESS = 'CHANGE_USER_TYPE_STATUS_SUCCESS',
  CHANGE_USER_TYPE_STATUS_FAILED = 'CHANGE_USER_TYPE_STATUS_FAILED',
}

// TYPES

export type TChangeUserTypeStatusRequest = {
  type: EChangeUserTypeStatusAction.CHANGE_USER_TYPE_STATUS_REQUEST;
  payload: {
    materials: TChangeUserTypeStatusMaterials;
    successCallback?: (response: TChangeUserTypeStatusResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TChangeUserTypeStatusSuccess = {
  type: EChangeUserTypeStatusAction.CHANGE_USER_TYPE_STATUS_SUCCESS;
  payload: { response: TChangeUserTypeStatusResponse };
};

export type TChangeUserTypeStatusFailed = { type: EChangeUserTypeStatusAction.CHANGE_USER_TYPE_STATUS_FAILED };

// FUNCTION

export const changeUserTypeStatusAction = {
  request: createActionCreator(
    EChangeUserTypeStatusAction.CHANGE_USER_TYPE_STATUS_REQUEST,
    (resolve) =>
      (
        materials: TChangeUserTypeStatusMaterials,
        successCallback?: (response: TChangeUserTypeStatusResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TChangeUserTypeStatusRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EChangeUserTypeStatusAction.CHANGE_USER_TYPE_STATUS_SUCCESS,
    (resolve) =>
      (response: TChangeUserTypeStatusResponse): TChangeUserTypeStatusSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EChangeUserTypeStatusAction.CHANGE_USER_TYPE_STATUS_FAILED,
    (resolve) =>
      (error: unknown): TChangeUserTypeStatusFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TUpdateTaskMaterials, TUpdateTaskResponse } from '@/services/api/template/update-task';

// CONSTANTS

export enum EUpdateTaskAction {
  UPDATE_TASK = 'UPDATE_TASK',
  UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST',
  UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_FAILED = 'UPDATE_TASK_FAILED',
}

// TYPES

export type TUpdateTaskRequest = {
  type: EUpdateTaskAction.UPDATE_TASK_REQUEST;
  payload: {
    materials: TUpdateTaskMaterials;
    successCallback?: (response: TUpdateTaskResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateTaskSuccess = {
  type: EUpdateTaskAction.UPDATE_TASK_SUCCESS;
  payload: { response: TUpdateTaskResponse };
};

export type TUpdateTaskFailed = { type: EUpdateTaskAction.UPDATE_TASK_FAILED };

// FUNCTION

export const updateTaskAction = {
  request: createActionCreator(
    EUpdateTaskAction.UPDATE_TASK_REQUEST,
    (resolve) =>
      (
        materials: TUpdateTaskMaterials,
        successCallback?: (response: TUpdateTaskResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateTaskRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateTaskAction.UPDATE_TASK_SUCCESS,
    (resolve) =>
      (response: TUpdateTaskResponse): TUpdateTaskSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateTaskAction.UPDATE_TASK_FAILED,
    (resolve) =>
      (error: unknown): TUpdateTaskFailed =>
        resolve({ error }),
  ),
};

import React from 'react';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';

import Icon, { EIconColor, EIconName } from '@/components/Icon';

import DropdownCustom from '@/components/DropdownCustom';
import { TTextAreaEmojiProps } from './TextAreaEmoji.types.d';
import './TextAreaEmoji.scss';

const TextAreaEmoji: React.FC<TTextAreaEmojiProps> = ({ onPicker }) => {
  const { t } = useTranslation();

  const handleSelectEmoji = (data: EmojiClickData): void => {
    onPicker?.(data?.emoji);
  };

  return (
    <div className="TextAreaEmoji">
      <DropdownCustom
        overlay={
          <EmojiPicker
            onEmojiClick={handleSelectEmoji}
            theme={Theme.LIGHT}
            searchPlaceHolder={t('Common_search')}
            previewConfig={{
              showPreview: false,
            }}
          />
        }
        placement="bottomRight"
        noUseVisible
        getPopupContainer={(trigger): HTMLElement => trigger}
      >
        <div className="TextAreaEmoji-wrapper">
          <Icon name={EIconName.Emoji} color={EIconColor.GRAYSCALE_500} />
        </div>
      </DropdownCustom>
    </div>
  );
};

export default TextAreaEmoji;

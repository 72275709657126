import { TRequest } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetTimeOffHistoriesParams = unknown;
export type TGetTimeOffHistoriesPaths = {
  id?: string | number;
};

export type TGetTimeOffHistoriesMaterials = {
  params?: TGetTimeOffHistoriesParams;
  paths?: TGetTimeOffHistoriesPaths;
};

export type TGetTimeOffHistoriesResponse = TPaginateResponse & {
  data: TRequest[];
  total: { [key: string]: string };
};

// FUNCTION

export const getTimeOffHistories = async ({
  params,
  paths,
}: TGetTimeOffHistoriesMaterials): Promise<TGetTimeOffHistoriesResponse> => {
  const response = await ApiService.get(`/user/time-off-histories/${paths?.id || 'my-info'}`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TRemoveApproverPaths = {
  typegroup: string | number;
};
export type TRemoveApproverParams = unknown;
export type TRemoveApproverBody = unknown;

export type TRemoveApproverMaterials = {
  paths?: TRemoveApproverPaths;
  params?: TRemoveApproverParams;
  body?: TRemoveApproverBody;
};

export type TRemoveApproverResponse = unknown;

// FUNCTION

export const removeApprover = async ({
  paths,
  params,
  body,
}: TRemoveApproverMaterials): Promise<TRemoveApproverResponse> => {
  const response = await ApiService.patch(`/approver/${paths?.typegroup}/remove-member`, body, { params });
  return response.data;
};

import { createReducer } from 'deox';

import {
  TApproveRequestResponse,
  TCancelRequestResponse,
  TContractProbationRequestResponse,
  TContractTerminationRequestResponse,
  TDeleteRequestResponse,
  TGetAssignMeRequestsResponse,
  TGetCcMeRequestsResponse,
  TGetMyRequestsResponse,
  TGetRecentTimeOffRequestsResponse,
  TGetRequestManagementResponse,
  TGetRequestNotificationsResponse,
  TGetRequestResponse,
  TGetRequestsResponse,
  TRequestBorrowAssetResponse,
  TRequestReturnAssetResponse,
  TRequestTimeOffResponse,
  TResignRequestResponse,
  TReviewOnboardResponse,
} from '@/services/api/request';
import {
  approveRequestAction,
  cancelRequestAction,
  contractProbationRequestAction,
  contractTerminationRequestAction,
  deleteRequestAction,
  getAssignMeRequestsAction,
  getCcMeRequestsAction,
  getMyRequestsAction,
  getRecentTimeOffRequestsAction,
  getRequestManagementAction,
  getRequestNotificationsAction,
  getRequestAction,
  getRequestsAction,
  requestBorrowAssetAction,
  requestReturnAssetAction,
  requestTimeOffAction,
  resignRequestAction,
  reviewOnboardAction,
} from '@/redux/actions';
import { approveRequestUpdateState } from './approve-request';
import { cancelRequestUpdateState } from './cancel-request';
import { contractProbationRequestUpdateState } from './contract-probation-request';
import { contractTerminationRequestUpdateState } from './contract-termination-request';
import { deleteRequestUpdateState } from './delete-request';
import { getAssignMeRequestsUpdateState } from './get-assign-me-requests';
import { getCcMeRequestsUpdateState } from './get-cc-me-requests';
import { getMyRequestsUpdateState } from './get-my-requests';
import { getRecentTimeOffRequestsUpdateState } from './get-recent-time-off-requests';
import { getRequestManagementUpdateState } from './get-request-management';
import { getRequestNotificationsUpdateState } from './get-request-notifications';
import { getRequestUpdateState } from './get-request';
import { getRequestsUpdateState } from './get-requests';
import { requestBorrowAssetUpdateState } from './request-borrow-asset';
import { requestReturnAssetUpdateState } from './request-return-asset';
import { requestTimeOffUpdateState } from './request-time-off';
import { resignRequestUpdateState } from './resign-request';
import { reviewOnboardUpdateState } from './review-onboard';

export type TRequestState = {
  approveRequestResponse?: TApproveRequestResponse;
  cancelRequestResponse?: TCancelRequestResponse;
  contractProbationRequestResponse?: TContractProbationRequestResponse;
  contractTerminationRequestResponse?: TContractTerminationRequestResponse;
  deleteRequestResponse?: TDeleteRequestResponse;
  getAssignMeRequestsResponse?: TGetAssignMeRequestsResponse;
  getCcMeRequestsResponse?: TGetCcMeRequestsResponse;
  getMyRequestsResponse?: TGetMyRequestsResponse;
  getRecentTimeOffRequestsResponse?: TGetRecentTimeOffRequestsResponse;
  getRequestManagementResponse?: TGetRequestManagementResponse;
  getRequestNotificationsResponse?: TGetRequestNotificationsResponse;
  getRequestResponse?: TGetRequestResponse;
  getRequestsResponse?: TGetRequestsResponse;
  requestBorrowAssetResponse?: TRequestBorrowAssetResponse;
  requestReturnAssetResponse?: TRequestReturnAssetResponse;
  requestTimeOffResponse?: TRequestTimeOffResponse;
  resignRequestResponse?: TResignRequestResponse;
  reviewOnboardResponse?: TReviewOnboardResponse;
};

const initialState: TRequestState = {
  approveRequestResponse: undefined,
  cancelRequestResponse: undefined,
  contractProbationRequestResponse: undefined,
  contractTerminationRequestResponse: undefined,
  deleteRequestResponse: undefined,
  getAssignMeRequestsResponse: undefined,
  getCcMeRequestsResponse: undefined,
  getMyRequestsResponse: undefined,
  getRecentTimeOffRequestsResponse: undefined,
  getRequestManagementResponse: undefined,
  getRequestNotificationsResponse: undefined,
  getRequestResponse: undefined,
  getRequestsResponse: undefined,
  requestBorrowAssetResponse: undefined,
  requestReturnAssetResponse: undefined,
  requestTimeOffResponse: undefined,
  resignRequestResponse: undefined,
  reviewOnboardResponse: undefined,
};

const RequestReducer = createReducer(initialState, (handleAction) => [
  handleAction(approveRequestAction.success, approveRequestUpdateState),
  handleAction(cancelRequestAction.success, cancelRequestUpdateState),
  handleAction(contractProbationRequestAction.success, contractProbationRequestUpdateState),
  handleAction(contractTerminationRequestAction.success, contractTerminationRequestUpdateState),
  handleAction(deleteRequestAction.success, deleteRequestUpdateState),
  handleAction(getAssignMeRequestsAction.success, getAssignMeRequestsUpdateState),
  handleAction(getCcMeRequestsAction.success, getCcMeRequestsUpdateState),
  handleAction(getMyRequestsAction.success, getMyRequestsUpdateState),
  handleAction(getRecentTimeOffRequestsAction.success, getRecentTimeOffRequestsUpdateState),
  handleAction(getRequestManagementAction.success, getRequestManagementUpdateState),
  handleAction(getRequestNotificationsAction.success, getRequestNotificationsUpdateState),
  handleAction(getRequestAction.success, getRequestUpdateState),
  handleAction(getRequestsAction.success, getRequestsUpdateState),
  handleAction(requestBorrowAssetAction.success, requestBorrowAssetUpdateState),
  handleAction(requestReturnAssetAction.success, requestReturnAssetUpdateState),
  handleAction(requestTimeOffAction.success, requestTimeOffUpdateState),
  handleAction(resignRequestAction.success, resignRequestUpdateState),
  handleAction(reviewOnboardAction.success, reviewOnboardUpdateState),
]);

export default RequestReducer;

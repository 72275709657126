import { createActionCreator } from 'deox';

import { TGetCompanyMaterials, TGetCompanyResponse } from '@/services/api/company/get-company';

// CONSTANTS

export enum EGetCompanyAction {
  GET_COMPANY = 'GET_COMPANY',
  GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILED = 'GET_COMPANY_FAILED',
}

// TYPES

export type TGetCompanyRequest = {
  type: EGetCompanyAction.GET_COMPANY_REQUEST;
  payload: {
    materials: TGetCompanyMaterials;
    successCallback?: (response: TGetCompanyResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCompanySuccess = {
  type: EGetCompanyAction.GET_COMPANY_SUCCESS;
  payload: { response: TGetCompanyResponse };
};

export type TGetCompanyFailed = { type: EGetCompanyAction.GET_COMPANY_FAILED };

// FUNCTION

export const getCompanyAction = {
  request: createActionCreator(
    EGetCompanyAction.GET_COMPANY_REQUEST,
    (resolve) =>
      (
        materials: TGetCompanyMaterials,
        successCallback?: (response: TGetCompanyResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCompanyRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCompanyAction.GET_COMPANY_SUCCESS,
    (resolve) =>
      (response: TGetCompanyResponse): TGetCompanySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCompanyAction.GET_COMPANY_FAILED,
    (resolve) =>
      (error: unknown): TGetCompanyFailed =>
        resolve({ error }),
  ),
};

import { all, takeLatest } from 'redux-saga/effects';

import {
  approveRequestAction,
  cancelRequestAction,
  contractProbationRequestAction,
  contractTerminationRequestAction,
  deleteRequestAction,
  getAssignMeRequestsAction,
  getCcMeRequestsAction,
  getMyRequestsAction,
  getRecentTimeOffRequestsAction,
  getRequestManagementAction,
  getRequestNotificationsAction,
  getRequestAction,
  getRequestsAction,
  requestBorrowAssetAction,
  requestReturnAssetAction,
  requestTimeOffAction,
  resignRequestAction,
  reviewOnboardAction,
} from '@/redux/actions';

import { approveRequestSaga } from './approve-request';
import { cancelRequestSaga } from './cancel-request';
import { contractProbationRequestSaga } from './contract-probation-request';
import { contractTerminationRequestSaga } from './contract-termination-request';
import { deleteRequestSaga } from './delete-request';
import { getAssignMeRequestsSaga } from './get-assign-me-requests';
import { getCcMeRequestsSaga } from './get-cc-me-requests';
import { getMyRequestsSaga } from './get-my-requests';
import { getRecentTimeOffRequestsSaga } from './get-recent-time-off-requests';
import { getRequestManagementSaga } from './get-request-management';
import { getRequestNotificationsSaga } from './get-request-notifications';
import { getRequestSaga } from './get-request';
import { getRequestsSaga } from './get-requests';
import { requestBorrowAssetSaga } from './request-borrow-asset';
import { requestReturnAssetSaga } from './request-return-asset';
import { requestTimeOffSaga } from './request-time-off';
import { resignRequestSaga } from './resign-request';
import { reviewOnboardSaga } from './review-onboard';

export default function* root(): Generator {
  yield all([
    takeLatest(approveRequestAction.request.type, approveRequestSaga),
    takeLatest(cancelRequestAction.request.type, cancelRequestSaga),
    takeLatest(contractProbationRequestAction.request.type, contractProbationRequestSaga),
    takeLatest(contractTerminationRequestAction.request.type, contractTerminationRequestSaga),
    takeLatest(deleteRequestAction.request.type, deleteRequestSaga),
    takeLatest(getAssignMeRequestsAction.request.type, getAssignMeRequestsSaga),
    takeLatest(getCcMeRequestsAction.request.type, getCcMeRequestsSaga),
    takeLatest(getMyRequestsAction.request.type, getMyRequestsSaga),
    takeLatest(getRecentTimeOffRequestsAction.request.type, getRecentTimeOffRequestsSaga),
    takeLatest(getRequestManagementAction.request.type, getRequestManagementSaga),
    takeLatest(getRequestNotificationsAction.request.type, getRequestNotificationsSaga),
    takeLatest(getRequestAction.request.type, getRequestSaga),
    takeLatest(getRequestsAction.request.type, getRequestsSaga),
    takeLatest(requestBorrowAssetAction.request.type, requestBorrowAssetSaga),
    takeLatest(requestReturnAssetAction.request.type, requestReturnAssetSaga),
    takeLatest(requestTimeOffAction.request.type, requestTimeOffSaga),
    takeLatest(resignRequestAction.request.type, resignRequestSaga),
    takeLatest(reviewOnboardAction.request.type, reviewOnboardSaga),
  ]);
}

import { createActionCreator } from 'deox';

import { TSignupUserMaterials, TSignupUserResponse } from '@/services/api/auth/signup-user';

// CONSTANTS

export enum ESignupUserAction {
  SIGNUP_USER = 'SIGNUP_USER',
  SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST',
  SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS',
  SIGNUP_USER_FAILED = 'SIGNUP_USER_FAILED',
}

// TYPES

export type TSignupUserRequest = {
  type: ESignupUserAction.SIGNUP_USER_REQUEST;
  payload: {
    materials: TSignupUserMaterials;
    successCallback?: (response: TSignupUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSignupUserSuccess = {
  type: ESignupUserAction.SIGNUP_USER_SUCCESS;
  payload: { response: TSignupUserResponse };
};

export type TSignupUserFailed = { type: ESignupUserAction.SIGNUP_USER_FAILED };

// FUNCTION

export const signupUserAction = {
  request: createActionCreator(
    ESignupUserAction.SIGNUP_USER_REQUEST,
    (resolve) =>
      (
        materials: TSignupUserMaterials,
        successCallback?: (response: TSignupUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSignupUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ESignupUserAction.SIGNUP_USER_SUCCESS,
    (resolve) =>
      (response: TSignupUserResponse): TSignupUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ESignupUserAction.SIGNUP_USER_FAILED,
    (resolve) =>
      (error: unknown): TSignupUserFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getCompanyAction } from '@/redux/actions';
import { getCompany, TGetCompanyResponse } from '@/services/api';

// FUNCTION

export function* getCompanySaga(action: ActionType<typeof getCompanyAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getCompany, materials);
    const getCompanyResponse: TGetCompanyResponse = response as TGetCompanyResponse;
    yield put(getCompanyAction.success(getCompanyResponse));
    successCallback?.(getCompanyResponse);
  } catch (err) {
    yield put(getCompanyAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TAssignOnboardParams = unknown;
export type TAssignOnboardBody = unknown;

export type TAssignOnboardMaterials = {
  params?: TAssignOnboardParams;
  body?: TAssignOnboardBody;
};

export type TAssignOnboardResponse = unknown;

// FUNCTION

export const assignOnboard = async ({ params, body }: TAssignOnboardMaterials): Promise<TAssignOnboardResponse> => {
  const response = await ApiService.post(`/checklist/assign/onboard`, body, { params });
  return response.data;
};

import { TTaskMisison } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetChecklistTaskOnboardParams = unknown;

export type TGetChecklistTaskOnboardMaterials = {
  params?: TGetChecklistTaskOnboardParams;
};

export type TGetChecklistTaskOnboardResponse = TTaskMisison[];

// FUNCTION

export const getChecklistTaskOnboard = async ({
  params,
}: TGetChecklistTaskOnboardMaterials): Promise<TGetChecklistTaskOnboardResponse> => {
  const response = await ApiService.get(`/checklist/my-task/onboard`, { params });
  return response.data;
};

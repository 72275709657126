import { TMyTimeOffType } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportTimeOffBalanceParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
  timeOffType?: string;
  user?: string;
};

export type TGetReportTimeOffBalanceMaterials = {
  params?: TGetReportTimeOffBalanceParams;
};

export type TGetReportTimeOffBalanceResponse = TPaginateResponse & {
  data: TMyTimeOffType[];
};

// FUNCTION

export const getReportTimeOffBalance = async ({
  params,
}: TGetReportTimeOffBalanceMaterials): Promise<TGetReportTimeOffBalanceResponse> => {
  const response = await ApiService.get(`/report/time-off-balance`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TDeleteReportViolationPaths = {
  userId: string | number;
};
export type TDeleteReportViolationParams = unknown;

export type TDeleteReportViolationMaterials = {
  paths?: TDeleteReportViolationPaths;
  params?: TDeleteReportViolationParams;
};

export type TDeleteReportViolationResponse = unknown;

// FUNCTION

export const deleteReportViolation = async ({
  paths,
  params,
}: TDeleteReportViolationMaterials): Promise<TDeleteReportViolationResponse> => {
  const response = await ApiService.delete(`/report-violation/${paths?.userId}`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { rehireUserAction } from '@/redux/actions';
import { rehireUser, TRehireUserResponse } from '@/services/api';

// FUNCTION

export function* rehireUserSaga(action: ActionType<typeof rehireUserAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(rehireUser, materials);
    const rehireUserResponse: TRehireUserResponse = response as TRehireUserResponse;
    yield put(rehireUserAction.success(rehireUserResponse));
    successCallback?.(rehireUserResponse);
  } catch (err) {
    yield put(rehireUserAction.failure(err));
    failedCallback?.(err);
  }
}

import { TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportOnboardParams = {
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  employType?: string;
  department?: string;
  branch?: string;
  sort?: string;
};

export type TGetReportOnboardMaterials = {
  params?: TGetReportOnboardParams;
};

export type TGetReportOnboardResponse = {
  chart: { year: number; month: number; total: number | string }[];
  data: TPaginateResponse & {
    data: TUser[];
  };
};

// FUNCTION

export const getReportOnboard = async ({ params }: TGetReportOnboardMaterials): Promise<TGetReportOnboardResponse> => {
  const response = await ApiService.get(`/report/onboard`, { params });
  return response.data;
};

import { TJob } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetJobsParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
  status?: string;
};

export type TGetJobsMaterials = {
  params?: TGetJobsParams;
};

export type TGetJobsResponse = TPaginateResponse & {
  data: TJob[];
  total: { [key: string]: string };
};

// FUNCTION

export const getJobs = async ({ params }: TGetJobsMaterials): Promise<TGetJobsResponse> => {
  const response = await ApiService.get(`/job/list`, { params });
  return response.data;
};

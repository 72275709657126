import { createReducer } from 'deox';

import {
  TGetCelebrationsResponse,
  TGetCompanyTimeOffsResponse,
  TGetDashboardReportResponse,
  TGetNotificationsResponse,
  TSeenNotificationResponse,
  TSendCelebrationResponse,
} from '@/services/api/dashboard';
import {
  getCelebrationsAction,
  getCompanyTimeOffsAction,
  getDashboardReportAction,
  getNotificationsAction,
  seenNotificationAction,
  sendCelebrationAction,
} from '@/redux/actions';
import { getCelebrationsUpdateState } from './get-celebrations';
import { getCompanyTimeOffsUpdateState } from './get-company-time-offs';
import { getDashboardReportUpdateState } from './get-dashboard-report';
import { getNotificationsUpdateState } from './get-notifications';
import { seenNotificationUpdateState } from './seen-notification';
import { sendCelebrationUpdateState } from './send-celebration';

export type TDashboardState = {
  getCelebrationsResponse?: TGetCelebrationsResponse;
  getCompanyTimeOffsResponse?: TGetCompanyTimeOffsResponse;
  getDashboardReportResponse?: TGetDashboardReportResponse;
  getNotificationsResponse?: TGetNotificationsResponse;
  seenNotificationResponse?: TSeenNotificationResponse;
  sendCelebrationResponse?: TSendCelebrationResponse;
};

const initialState: TDashboardState = {
  getCelebrationsResponse: undefined,
  getCompanyTimeOffsResponse: undefined,
  getDashboardReportResponse: undefined,
  getNotificationsResponse: undefined,
  seenNotificationResponse: undefined,
  sendCelebrationResponse: undefined,
};

const DashboardReducer = createReducer(initialState, (handleAction) => [
  handleAction(getCelebrationsAction.success, getCelebrationsUpdateState),
  handleAction(getCompanyTimeOffsAction.success, getCompanyTimeOffsUpdateState),
  handleAction(getDashboardReportAction.success, getDashboardReportUpdateState),
  handleAction(getNotificationsAction.success, getNotificationsUpdateState),
  handleAction(seenNotificationAction.success, seenNotificationUpdateState),
  handleAction(sendCelebrationAction.success, sendCelebrationUpdateState),
]);

export default DashboardReducer;

import { TRequestState } from '@/redux/reducers/request';
import { TGetAssignMeRequestsSuccess } from '@/redux/actions/request';

export const getAssignMeRequestsUpdateState = (
  state: TRequestState,
  action: TGetAssignMeRequestsSuccess,
): TRequestState => ({
  ...state,
  getAssignMeRequestsResponse: action.payload.response,
});

import { TReportViolationState } from '@/redux/reducers/report-violation';
import { TCreateReportViolationSuccess } from '@/redux/actions/report-violation';

export const createReportViolationUpdateState = (
  state: TReportViolationState,
  action: TCreateReportViolationSuccess,
): TReportViolationState => ({
  ...state,
  createReportViolationResponse: action.payload.response,
});

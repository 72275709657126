import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getUserBenefitsHistoryAction } from '@/redux/actions';
import { getUserBenefitsHistory, TGetUserBenefitsHistoryResponse } from '@/services/api';

// FUNCTION

export function* getUserBenefitsHistorySaga(
  action: ActionType<typeof getUserBenefitsHistoryAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getUserBenefitsHistory, materials);
    const getUserBenefitsHistoryResponse: TGetUserBenefitsHistoryResponse = response as TGetUserBenefitsHistoryResponse;
    yield put(getUserBenefitsHistoryAction.success(getUserBenefitsHistoryResponse));
    successCallback?.(getUserBenefitsHistoryResponse);
  } catch (err) {
    yield put(getUserBenefitsHistoryAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TGetMyRequestsMaterials, TGetMyRequestsResponse } from '@/services/api/request/get-my-requests';

// CONSTANTS

export enum EGetMyRequestsAction {
  GET_MY_REQUESTS = 'GET_MY_REQUESTS',
  GET_MY_REQUESTS_REQUEST = 'GET_MY_REQUESTS_REQUEST',
  GET_MY_REQUESTS_SUCCESS = 'GET_MY_REQUESTS_SUCCESS',
  GET_MY_REQUESTS_FAILED = 'GET_MY_REQUESTS_FAILED',
}

// TYPES

export type TGetMyRequestsRequest = {
  type: EGetMyRequestsAction.GET_MY_REQUESTS_REQUEST;
  payload: {
    materials: TGetMyRequestsMaterials;
    successCallback?: (response: TGetMyRequestsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMyRequestsSuccess = {
  type: EGetMyRequestsAction.GET_MY_REQUESTS_SUCCESS;
  payload: { response: TGetMyRequestsResponse };
};

export type TGetMyRequestsFailed = { type: EGetMyRequestsAction.GET_MY_REQUESTS_FAILED };

// FUNCTION

export const getMyRequestsAction = {
  request: createActionCreator(
    EGetMyRequestsAction.GET_MY_REQUESTS_REQUEST,
    (resolve) =>
      (
        materials: TGetMyRequestsMaterials,
        successCallback?: (response: TGetMyRequestsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMyRequestsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMyRequestsAction.GET_MY_REQUESTS_SUCCESS,
    (resolve) =>
      (response: TGetMyRequestsResponse): TGetMyRequestsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMyRequestsAction.GET_MY_REQUESTS_FAILED,
    (resolve) =>
      (error: unknown): TGetMyRequestsFailed =>
        resolve({ error }),
  ),
};

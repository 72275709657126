import { createActionCreator } from 'deox';

import { TChangeTaskStatusMaterials, TChangeTaskStatusResponse } from '@/services/api/checklist/change-task-status';

// CONSTANTS

export enum EChangeTaskStatusAction {
  CHANGE_TASK_STATUS = 'CHANGE_TASK_STATUS',
  CHANGE_TASK_STATUS_REQUEST = 'CHANGE_TASK_STATUS_REQUEST',
  CHANGE_TASK_STATUS_SUCCESS = 'CHANGE_TASK_STATUS_SUCCESS',
  CHANGE_TASK_STATUS_FAILED = 'CHANGE_TASK_STATUS_FAILED',
}

// TYPES

export type TChangeTaskStatusRequest = {
  type: EChangeTaskStatusAction.CHANGE_TASK_STATUS_REQUEST;
  payload: {
    materials: TChangeTaskStatusMaterials;
    successCallback?: (response: TChangeTaskStatusResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TChangeTaskStatusSuccess = {
  type: EChangeTaskStatusAction.CHANGE_TASK_STATUS_SUCCESS;
  payload: { response: TChangeTaskStatusResponse };
};

export type TChangeTaskStatusFailed = { type: EChangeTaskStatusAction.CHANGE_TASK_STATUS_FAILED };

// FUNCTION

export const changeTaskStatusAction = {
  request: createActionCreator(
    EChangeTaskStatusAction.CHANGE_TASK_STATUS_REQUEST,
    (resolve) =>
      (
        materials: TChangeTaskStatusMaterials,
        successCallback?: (response: TChangeTaskStatusResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TChangeTaskStatusRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EChangeTaskStatusAction.CHANGE_TASK_STATUS_SUCCESS,
    (resolve) =>
      (response: TChangeTaskStatusResponse): TChangeTaskStatusSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EChangeTaskStatusAction.CHANGE_TASK_STATUS_FAILED,
    (resolve) =>
      (error: unknown): TChangeTaskStatusFailed =>
        resolve({ error }),
  ),
};

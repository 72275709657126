import { createActionCreator } from 'deox';

import {
  TGetRequestNotificationsMaterials,
  TGetRequestNotificationsResponse,
} from '@/services/api/request/get-request-notifications';

// CONSTANTS

export enum EGetRequestNotificationsAction {
  GET_REQUEST_NOTIFICATIONS = 'GET_REQUEST_NOTIFICATIONS',
  GET_REQUEST_NOTIFICATIONS_REQUEST = 'GET_REQUEST_NOTIFICATIONS_REQUEST',
  GET_REQUEST_NOTIFICATIONS_SUCCESS = 'GET_REQUEST_NOTIFICATIONS_SUCCESS',
  GET_REQUEST_NOTIFICATIONS_FAILED = 'GET_REQUEST_NOTIFICATIONS_FAILED',
}

// TYPES

export type TGetRequestNotificationsRequest = {
  type: EGetRequestNotificationsAction.GET_REQUEST_NOTIFICATIONS_REQUEST;
  payload: {
    materials: TGetRequestNotificationsMaterials;
    successCallback?: (response: TGetRequestNotificationsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRequestNotificationsSuccess = {
  type: EGetRequestNotificationsAction.GET_REQUEST_NOTIFICATIONS_SUCCESS;
  payload: { response: TGetRequestNotificationsResponse };
};

export type TGetRequestNotificationsFailed = { type: EGetRequestNotificationsAction.GET_REQUEST_NOTIFICATIONS_FAILED };

// FUNCTION

export const getRequestNotificationsAction = {
  request: createActionCreator(
    EGetRequestNotificationsAction.GET_REQUEST_NOTIFICATIONS_REQUEST,
    (resolve) =>
      (
        materials: TGetRequestNotificationsMaterials,
        successCallback?: (response: TGetRequestNotificationsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRequestNotificationsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRequestNotificationsAction.GET_REQUEST_NOTIFICATIONS_SUCCESS,
    (resolve) =>
      (response: TGetRequestNotificationsResponse): TGetRequestNotificationsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRequestNotificationsAction.GET_REQUEST_NOTIFICATIONS_FAILED,
    (resolve) =>
      (error: unknown): TGetRequestNotificationsFailed =>
        resolve({ error }),
  ),
};

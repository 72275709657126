import { createReducer } from 'deox';

import {
  TAssignOffboardResponse,
  TAssignOnboardResponse,
  TChangeTaskStatusResponse,
  TGetChecklistTaskOnboardResponse,
  TGetChecklistTasksResponse,
  TUpdateAssignOffboardResponse,
  TUpdateAssignOnboardResponse,
} from '@/services/api/checklist';
import {
  assignOffboardAction,
  assignOnboardAction,
  changeTaskStatusAction,
  getChecklistTaskOnboardAction,
  getChecklistTasksAction,
  updateAssignOffboardAction,
  updateAssignOnboardAction,
} from '@/redux/actions';
import { assignOffboardUpdateState } from './assign-offboard';
import { assignOnboardUpdateState } from './assign-onboard';
import { changeTaskStatusUpdateState } from './change-task-status';
import { getChecklistTaskOnboardUpdateState } from './get-checklist-task-onboard';
import { getChecklistTasksUpdateState } from './get-checklist-tasks';
import { updateAssignOffboardUpdateState } from './update-assign-offboard';
import { updateAssignOnboardUpdateState } from './update-assign-onboard';

export type TChecklistState = {
  assignOffboardResponse?: TAssignOffboardResponse;
  assignOnboardResponse?: TAssignOnboardResponse;
  changeTaskStatusResponse?: TChangeTaskStatusResponse;
  getChecklistTaskOnboardResponse?: TGetChecklistTaskOnboardResponse;
  getChecklistTasksResponse?: TGetChecklistTasksResponse;
  updateAssignOffboardResponse?: TUpdateAssignOffboardResponse;
  updateAssignOnboardResponse?: TUpdateAssignOnboardResponse;
};

const initialState: TChecklistState = {
  assignOffboardResponse: undefined,
  assignOnboardResponse: undefined,
  changeTaskStatusResponse: undefined,
  getChecklistTaskOnboardResponse: undefined,
  getChecklistTasksResponse: undefined,
  updateAssignOffboardResponse: undefined,
  updateAssignOnboardResponse: undefined,
};

const ChecklistReducer = createReducer(initialState, (handleAction) => [
  handleAction(assignOffboardAction.success, assignOffboardUpdateState),
  handleAction(assignOnboardAction.success, assignOnboardUpdateState),
  handleAction(changeTaskStatusAction.success, changeTaskStatusUpdateState),
  handleAction(getChecklistTaskOnboardAction.success, getChecklistTaskOnboardUpdateState),
  handleAction(getChecklistTasksAction.success, getChecklistTasksUpdateState),
  handleAction(updateAssignOffboardAction.success, updateAssignOffboardUpdateState),
  handleAction(updateAssignOnboardAction.success, updateAssignOnboardUpdateState),
]);

export default ChecklistReducer;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createRegulateCategoryAction } from '@/redux/actions';
import { createRegulateCategory, TCreateRegulateCategoryResponse } from '@/services/api';

// FUNCTION

export function* createRegulateCategorySaga(
  action: ActionType<typeof createRegulateCategoryAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createRegulateCategory, materials);
    const createRegulateCategoryResponse: TCreateRegulateCategoryResponse = response as TCreateRegulateCategoryResponse;
    yield put(createRegulateCategoryAction.success(createRegulateCategoryResponse));
    successCallback?.(createRegulateCategoryResponse);
  } catch (err) {
    yield put(createRegulateCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

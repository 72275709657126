import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { addRoleMemberAction } from '@/redux/actions';
import { addRoleMember, TAddRoleMemberResponse } from '@/services/api';

// FUNCTION

export function* addRoleMemberSaga(action: ActionType<typeof addRoleMemberAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(addRoleMember, materials);
    const addRoleMemberResponse: TAddRoleMemberResponse = response as TAddRoleMemberResponse;
    yield put(addRoleMemberAction.success(addRoleMemberResponse));
    successCallback?.(addRoleMemberResponse);
  } catch (err) {
    yield put(addRoleMemberAction.failure(err));
    failedCallback?.(err);
  }
}

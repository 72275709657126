import { all, takeLatest } from 'redux-saga/effects';

import { getBanksAction, getIconTimeOffAction, getNationalsAction, getStatesAction } from '@/redux/actions';

import { getBanksSaga } from './get-banks';
import { getIconTimeOffSaga } from './get-icon-time-off';
import { getNationalsSaga } from './get-nationals';
import { getStatesSaga } from './get-states';

export default function* root(): Generator {
  yield all([
    takeLatest(getBanksAction.request.type, getBanksSaga),
    takeLatest(getIconTimeOffAction.request.type, getIconTimeOffSaga),
    takeLatest(getNationalsAction.request.type, getNationalsSaga),
    takeLatest(getStatesAction.request.type, getStatesSaga),
  ]);
}

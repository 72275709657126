import { createActionCreator } from 'deox';

import { TCreateHolidayMaterials, TCreateHolidayResponse } from '@/services/api/holiday/create-holiday';

// CONSTANTS

export enum ECreateHolidayAction {
  CREATE_HOLIDAY = 'CREATE_HOLIDAY',
  CREATE_HOLIDAY_REQUEST = 'CREATE_HOLIDAY_REQUEST',
  CREATE_HOLIDAY_SUCCESS = 'CREATE_HOLIDAY_SUCCESS',
  CREATE_HOLIDAY_FAILED = 'CREATE_HOLIDAY_FAILED',
}

// TYPES

export type TCreateHolidayRequest = {
  type: ECreateHolidayAction.CREATE_HOLIDAY_REQUEST;
  payload: {
    materials: TCreateHolidayMaterials;
    successCallback?: (response: TCreateHolidayResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateHolidaySuccess = {
  type: ECreateHolidayAction.CREATE_HOLIDAY_SUCCESS;
  payload: { response: TCreateHolidayResponse };
};

export type TCreateHolidayFailed = { type: ECreateHolidayAction.CREATE_HOLIDAY_FAILED };

// FUNCTION

export const createHolidayAction = {
  request: createActionCreator(
    ECreateHolidayAction.CREATE_HOLIDAY_REQUEST,
    (resolve) =>
      (
        materials: TCreateHolidayMaterials,
        successCallback?: (response: TCreateHolidayResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateHolidayRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateHolidayAction.CREATE_HOLIDAY_SUCCESS,
    (resolve) =>
      (response: TCreateHolidayResponse): TCreateHolidaySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateHolidayAction.CREATE_HOLIDAY_FAILED,
    (resolve) =>
      (error: unknown): TCreateHolidayFailed =>
        resolve({ error }),
  ),
};

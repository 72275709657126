import ApiService from '@/services/api';

// TYPES

export type TDuplicateTemplateParams = unknown;
export type TDuplicateTemplateBody = unknown;

export type TDuplicateTemplateMaterials = {
  params?: TDuplicateTemplateParams;
  body?: TDuplicateTemplateBody;
};

export type TDuplicateTemplateResponse = unknown;

// FUNCTION

export const duplicateTemplate = async ({
  params,
  body,
}: TDuplicateTemplateMaterials): Promise<TDuplicateTemplateResponse> => {
  const response = await ApiService.post(`/template/duplicate`, body, { params });
  return response.data;
};

import { TTemplateState } from '@/redux/reducers/template';
import { TCreateTaskCategorySuccess } from '@/redux/actions/template';

export const createTaskCategoryUpdateState = (
  state: TTemplateState,
  action: TCreateTaskCategorySuccess,
): TTemplateState => ({
  ...state,
  createTaskCategoryResponse: action.payload.response,
});

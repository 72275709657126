import { createActionCreator } from 'deox';

import {
  TDeleteRegulateCategoryMaterials,
  TDeleteRegulateCategoryResponse,
} from '@/services/api/regulate-category/delete-regulate-category';

// CONSTANTS

export enum EDeleteRegulateCategoryAction {
  DELETE_REGULATE_CATEGORY = 'DELETE_REGULATE_CATEGORY',
  DELETE_REGULATE_CATEGORY_REQUEST = 'DELETE_REGULATE_CATEGORY_REQUEST',
  DELETE_REGULATE_CATEGORY_SUCCESS = 'DELETE_REGULATE_CATEGORY_SUCCESS',
  DELETE_REGULATE_CATEGORY_FAILED = 'DELETE_REGULATE_CATEGORY_FAILED',
}

// TYPES

export type TDeleteRegulateCategoryRequest = {
  type: EDeleteRegulateCategoryAction.DELETE_REGULATE_CATEGORY_REQUEST;
  payload: {
    materials: TDeleteRegulateCategoryMaterials;
    successCallback?: (response: TDeleteRegulateCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteRegulateCategorySuccess = {
  type: EDeleteRegulateCategoryAction.DELETE_REGULATE_CATEGORY_SUCCESS;
  payload: { response: TDeleteRegulateCategoryResponse };
};

export type TDeleteRegulateCategoryFailed = { type: EDeleteRegulateCategoryAction.DELETE_REGULATE_CATEGORY_FAILED };

// FUNCTION

export const deleteRegulateCategoryAction = {
  request: createActionCreator(
    EDeleteRegulateCategoryAction.DELETE_REGULATE_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TDeleteRegulateCategoryMaterials,
        successCallback?: (response: TDeleteRegulateCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteRegulateCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteRegulateCategoryAction.DELETE_REGULATE_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TDeleteRegulateCategoryResponse): TDeleteRegulateCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteRegulateCategoryAction.DELETE_REGULATE_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TDeleteRegulateCategoryFailed =>
        resolve({ error }),
  ),
};

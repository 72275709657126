import ApiService from '@/services/api';

// TYPES

export type TDownloadReportTimeOffBalanceParams = unknown;
export type TDownloadReportTimeOffBalanceBody = unknown;

export type TDownloadReportTimeOffBalanceMaterials = {
  params?: TDownloadReportTimeOffBalanceParams;
  body?: TDownloadReportTimeOffBalanceBody;
};

export type TDownloadReportTimeOffBalanceResponse = string;

// FUNCTION

export const downloadReportTimeOffBalance = async ({
  params,
  body,
}: TDownloadReportTimeOffBalanceMaterials): Promise<TDownloadReportTimeOffBalanceResponse> => {
  const response = await ApiService.post(`/report/time-off-balance`, body, { params });
  return response.data;
};

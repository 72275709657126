import { all, takeLatest } from 'redux-saga/effects';

import {
  createDepartmentAction,
  deleteDepartmentAction,
  getDepartmentAction,
  getDepartmentsAction,
  updateDepartmentAction,
} from '@/redux/actions';

import { createDepartmentSaga } from './create-department';
import { deleteDepartmentSaga } from './delete-department';
import { getDepartmentSaga } from './get-department';
import { getDepartmentsSaga } from './get-departments';
import { updateDepartmentSaga } from './update-department';

export default function* root(): Generator {
  yield all([
    takeLatest(createDepartmentAction.request.type, createDepartmentSaga),
    takeLatest(deleteDepartmentAction.request.type, deleteDepartmentSaga),
    takeLatest(getDepartmentAction.request.type, getDepartmentSaga),
    takeLatest(getDepartmentsAction.request.type, getDepartmentsSaga),
    takeLatest(updateDepartmentAction.request.type, updateDepartmentSaga),
  ]);
}

import { TDepartmentState } from '@/redux/reducers/department';
import { TDeleteDepartmentSuccess } from '@/redux/actions/department';

export const deleteDepartmentUpdateState = (
  state: TDepartmentState,
  action: TDeleteDepartmentSuccess,
): TDepartmentState => ({
  ...state,
  deleteDepartmentResponse: action.payload.response,
});

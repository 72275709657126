import { createReducer } from 'deox';

import {
  TCreateJobResponse,
  TDeleteJobResponse,
  TGetJobResponse,
  TGetJobsResponse,
  TUpdateJobResponse,
} from '@/services/api/job';
import { createJobAction, deleteJobAction, getJobAction, getJobsAction, updateJobAction } from '@/redux/actions';
import { createJobUpdateState } from './create-job';
import { deleteJobUpdateState } from './delete-job';
import { getJobUpdateState } from './get-job';
import { getJobsUpdateState } from './get-jobs';
import { updateJobUpdateState } from './update-job';

export type TJobState = {
  createJobResponse?: TCreateJobResponse;
  deleteJobResponse?: TDeleteJobResponse;
  getJobResponse?: TGetJobResponse;
  getJobsResponse?: TGetJobsResponse;
  updateJobResponse?: TUpdateJobResponse;
};

const initialState: TJobState = {
  createJobResponse: undefined,
  deleteJobResponse: undefined,
  getJobResponse: undefined,
  getJobsResponse: undefined,
  updateJobResponse: undefined,
};

const JobReducer = createReducer(initialState, (handleAction) => [
  handleAction(createJobAction.success, createJobUpdateState),
  handleAction(deleteJobAction.success, deleteJobUpdateState),
  handleAction(getJobAction.success, getJobUpdateState),
  handleAction(getJobsAction.success, getJobsUpdateState),
  handleAction(updateJobAction.success, updateJobUpdateState),
]);

export default JobReducer;

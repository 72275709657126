import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.125 7.16309L3.1725 9.79559C3.24159 9.88535 3.33012 9.95831 3.43144 10.009C3.53275 10.0596 3.64423 10.0867 3.7575 10.0881C3.86895 10.0894 3.97928 10.0658 4.08047 10.0191C4.18166 9.9724 4.27118 9.90373 4.3425 9.81809L10.875 1.91309"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { loginIdPortalAction } from '@/redux/actions';
import { loginIdPortal, TLoginIdPortalResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* loginIdPortalSaga(action: ActionType<typeof loginIdPortalAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(loginIdPortal, materials);
    const loginIdPortalResponse: TLoginIdPortalResponse = response as TLoginIdPortalResponse;

    Helpers.storeAccessToken(loginIdPortalResponse.accessToken);
    Helpers.storeRefreshToken('');

    yield put(loginIdPortalAction.success(loginIdPortalResponse));
    successCallback?.(loginIdPortalResponse);
  } catch (err) {
    yield put(loginIdPortalAction.failure(err));
    failedCallback?.(err);
  }
}

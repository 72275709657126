import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_921_74331)">
        <path
          d="M10.6621 17.5C14.8042 17.5 18.1621 14.1421 18.1621 10C18.1621 5.85786 14.8042 2.5 10.6621 2.5C6.51997 2.5 3.16211 5.85786 3.16211 10C3.16211 14.1421 6.51997 17.5 10.6621 17.5Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.16211 8.33325H8.17044"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1621 8.33325H13.1704"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.57861 12.5C8.85018 12.7772 9.17432 12.9974 9.53205 13.1477C9.88978 13.298 10.2739 13.3754 10.6619 13.3754C11.05 13.3754 11.4341 13.298 11.7918 13.1477C12.1496 12.9974 12.4737 12.7772 12.7453 12.5"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_921_74331">
          <rect width="20" height="20" fill="white" transform="translate(0.662109)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg;

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.58862 16.9583V12.5472C7.58862 11.3774 8.05335 10.2554 8.88058 9.42819C9.70781 8.60096 10.8298 8.13623 11.9996 8.13623C13.1695 8.13623 14.2915 8.60096 15.1187 9.42819C15.9459 10.2554 16.4107 11.3774 16.4107 12.5472V16.9583"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.0752 12.5498H3.17795M12 2.625V3.72775M20.822 12.5498H21.9248M4.94235 5.49216L5.71428 6.26409M19.0576 5.49216L18.2857 6.26409"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5133 16.9614H6.48581C5.87678 16.9614 5.38306 17.4551 5.38306 18.0642V20.2697C5.38306 20.8787 5.87678 21.3724 6.48581 21.3724H17.5133C18.1224 21.3724 18.6161 20.8787 18.6161 20.2697V18.0642C18.6161 17.4551 18.1224 16.9614 17.5133 16.9614Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

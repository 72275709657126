import { createActionCreator } from 'deox';

import {
  TDonwloadReportTimeOffScheduleMaterials,
  TDonwloadReportTimeOffScheduleResponse,
} from '@/services/api/report/donwload-report-time-off-schedule';

// CONSTANTS

export enum EDonwloadReportTimeOffScheduleAction {
  DONWLOAD_REPORT_TIME_OFF_SCHEDULE = 'DONWLOAD_REPORT_TIME_OFF_SCHEDULE',
  DONWLOAD_REPORT_TIME_OFF_SCHEDULE_REQUEST = 'DONWLOAD_REPORT_TIME_OFF_SCHEDULE_REQUEST',
  DONWLOAD_REPORT_TIME_OFF_SCHEDULE_SUCCESS = 'DONWLOAD_REPORT_TIME_OFF_SCHEDULE_SUCCESS',
  DONWLOAD_REPORT_TIME_OFF_SCHEDULE_FAILED = 'DONWLOAD_REPORT_TIME_OFF_SCHEDULE_FAILED',
}

// TYPES

export type TDonwloadReportTimeOffScheduleRequest = {
  type: EDonwloadReportTimeOffScheduleAction.DONWLOAD_REPORT_TIME_OFF_SCHEDULE_REQUEST;
  payload: {
    materials: TDonwloadReportTimeOffScheduleMaterials;
    successCallback?: (response: TDonwloadReportTimeOffScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDonwloadReportTimeOffScheduleSuccess = {
  type: EDonwloadReportTimeOffScheduleAction.DONWLOAD_REPORT_TIME_OFF_SCHEDULE_SUCCESS;
  payload: { response: TDonwloadReportTimeOffScheduleResponse };
};

export type TDonwloadReportTimeOffScheduleFailed = {
  type: EDonwloadReportTimeOffScheduleAction.DONWLOAD_REPORT_TIME_OFF_SCHEDULE_FAILED;
};

// FUNCTION

export const donwloadReportTimeOffScheduleAction = {
  request: createActionCreator(
    EDonwloadReportTimeOffScheduleAction.DONWLOAD_REPORT_TIME_OFF_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TDonwloadReportTimeOffScheduleMaterials,
        successCallback?: (response: TDonwloadReportTimeOffScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDonwloadReportTimeOffScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDonwloadReportTimeOffScheduleAction.DONWLOAD_REPORT_TIME_OFF_SCHEDULE_SUCCESS,
    (resolve) =>
      (response: TDonwloadReportTimeOffScheduleResponse): TDonwloadReportTimeOffScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDonwloadReportTimeOffScheduleAction.DONWLOAD_REPORT_TIME_OFF_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TDonwloadReportTimeOffScheduleFailed =>
        resolve({ error }),
  ),
};

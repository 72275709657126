import { createActionCreator } from 'deox';

import {
  TGetReportEmployeeTurnoverRateMaterials,
  TGetReportEmployeeTurnoverRateResponse,
} from '@/services/api/report/get-report-employee-turnover-rate';

// CONSTANTS

export enum EGetReportEmployeeTurnoverRateAction {
  GET_REPORT_EMPLOYEE_TURNOVER_RATE = 'GET_REPORT_EMPLOYEE_TURNOVER_RATE',
  GET_REPORT_EMPLOYEE_TURNOVER_RATE_REQUEST = 'GET_REPORT_EMPLOYEE_TURNOVER_RATE_REQUEST',
  GET_REPORT_EMPLOYEE_TURNOVER_RATE_SUCCESS = 'GET_REPORT_EMPLOYEE_TURNOVER_RATE_SUCCESS',
  GET_REPORT_EMPLOYEE_TURNOVER_RATE_FAILED = 'GET_REPORT_EMPLOYEE_TURNOVER_RATE_FAILED',
}

// TYPES

export type TGetReportEmployeeTurnoverRateRequest = {
  type: EGetReportEmployeeTurnoverRateAction.GET_REPORT_EMPLOYEE_TURNOVER_RATE_REQUEST;
  payload: {
    materials: TGetReportEmployeeTurnoverRateMaterials;
    successCallback?: (response: TGetReportEmployeeTurnoverRateResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportEmployeeTurnoverRateSuccess = {
  type: EGetReportEmployeeTurnoverRateAction.GET_REPORT_EMPLOYEE_TURNOVER_RATE_SUCCESS;
  payload: { response: TGetReportEmployeeTurnoverRateResponse };
};

export type TGetReportEmployeeTurnoverRateFailed = {
  type: EGetReportEmployeeTurnoverRateAction.GET_REPORT_EMPLOYEE_TURNOVER_RATE_FAILED;
};

// FUNCTION

export const getReportEmployeeTurnoverRateAction = {
  request: createActionCreator(
    EGetReportEmployeeTurnoverRateAction.GET_REPORT_EMPLOYEE_TURNOVER_RATE_REQUEST,
    (resolve) =>
      (
        materials: TGetReportEmployeeTurnoverRateMaterials,
        successCallback?: (response: TGetReportEmployeeTurnoverRateResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportEmployeeTurnoverRateRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportEmployeeTurnoverRateAction.GET_REPORT_EMPLOYEE_TURNOVER_RATE_SUCCESS,
    (resolve) =>
      (response: TGetReportEmployeeTurnoverRateResponse): TGetReportEmployeeTurnoverRateSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportEmployeeTurnoverRateAction.GET_REPORT_EMPLOYEE_TURNOVER_RATE_FAILED,
    (resolve) =>
      (error: unknown): TGetReportEmployeeTurnoverRateFailed =>
        resolve({ error }),
  ),
};

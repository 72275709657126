import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTimeOffAction } from '@/redux/actions';
import { getTimeOff, TGetTimeOffResponse } from '@/services/api';

// FUNCTION

export function* getTimeOffSaga(action: ActionType<typeof getTimeOffAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTimeOff, materials);
    const getTimeOffResponse: TGetTimeOffResponse = response as TGetTimeOffResponse;
    yield put(getTimeOffAction.success(getTimeOffResponse));
    successCallback?.(getTimeOffResponse);
  } catch (err) {
    yield put(getTimeOffAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TUpdateMyCareerMaterials, TUpdateMyCareerResponse } from '@/services/api/user/update-my-career';

// CONSTANTS

export enum EUpdateMyCareerAction {
  UPDATE_MY_CAREER = 'UPDATE_MY_CAREER',
  UPDATE_MY_CAREER_REQUEST = 'UPDATE_MY_CAREER_REQUEST',
  UPDATE_MY_CAREER_SUCCESS = 'UPDATE_MY_CAREER_SUCCESS',
  UPDATE_MY_CAREER_FAILED = 'UPDATE_MY_CAREER_FAILED',
}

// TYPES

export type TUpdateMyCareerRequest = {
  type: EUpdateMyCareerAction.UPDATE_MY_CAREER_REQUEST;
  payload: {
    materials: TUpdateMyCareerMaterials;
    successCallback?: (response: TUpdateMyCareerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateMyCareerSuccess = {
  type: EUpdateMyCareerAction.UPDATE_MY_CAREER_SUCCESS;
  payload: { response: TUpdateMyCareerResponse };
};

export type TUpdateMyCareerFailed = { type: EUpdateMyCareerAction.UPDATE_MY_CAREER_FAILED };

// FUNCTION

export const updateMyCareerAction = {
  request: createActionCreator(
    EUpdateMyCareerAction.UPDATE_MY_CAREER_REQUEST,
    (resolve) =>
      (
        materials: TUpdateMyCareerMaterials,
        successCallback?: (response: TUpdateMyCareerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateMyCareerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateMyCareerAction.UPDATE_MY_CAREER_SUCCESS,
    (resolve) =>
      (response: TUpdateMyCareerResponse): TUpdateMyCareerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateMyCareerAction.UPDATE_MY_CAREER_FAILED,
    (resolve) =>
      (error: unknown): TUpdateMyCareerFailed =>
        resolve({ error }),
  ),
};

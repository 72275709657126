import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createTaskCategoryAction } from '@/redux/actions';
import { createTaskCategory, TCreateTaskCategoryResponse } from '@/services/api';

// FUNCTION

export function* createTaskCategorySaga(action: ActionType<typeof createTaskCategoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createTaskCategory, materials);
    const createTaskCategoryResponse: TCreateTaskCategoryResponse = response as TCreateTaskCategoryResponse;
    yield put(createTaskCategoryAction.success(createTaskCategoryResponse));
    successCallback?.(createTaskCategoryResponse);
  } catch (err) {
    yield put(createTaskCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

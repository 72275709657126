import { createActionCreator } from 'deox';

import { TGetAssetMaterials, TGetAssetResponse } from '@/services/api/asset/get-asset';

// CONSTANTS

export enum EGetAssetAction {
  GET_ASSET = 'GET_ASSET',
  GET_ASSET_REQUEST = 'GET_ASSET_REQUEST',
  GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS',
  GET_ASSET_FAILED = 'GET_ASSET_FAILED',
}

// TYPES

export type TGetAssetRequest = {
  type: EGetAssetAction.GET_ASSET_REQUEST;
  payload: {
    materials: TGetAssetMaterials;
    successCallback?: (response: TGetAssetResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAssetSuccess = {
  type: EGetAssetAction.GET_ASSET_SUCCESS;
  payload: { response: TGetAssetResponse };
};

export type TGetAssetFailed = { type: EGetAssetAction.GET_ASSET_FAILED };

// FUNCTION

export const getAssetAction = {
  request: createActionCreator(
    EGetAssetAction.GET_ASSET_REQUEST,
    (resolve) =>
      (
        materials: TGetAssetMaterials,
        successCallback?: (response: TGetAssetResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAssetRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAssetAction.GET_ASSET_SUCCESS,
    (resolve) =>
      (response: TGetAssetResponse): TGetAssetSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAssetAction.GET_ASSET_FAILED,
    (resolve) =>
      (error: unknown): TGetAssetFailed =>
        resolve({ error }),
  ),
};

import React from 'react';
import classNames from 'classnames';
import { Input } from 'antd';

import { TTextAreaProps } from '@/components/TextArea/TextArea.types';
import FormLabel from '@/components/FormLabel';
import TextAreaEmoji from '@/components/TextAreaEmoji';

import './TextArea.scss';

const { TextArea: AntdTextArea } = Input;

const TextArea: React.FC<TTextAreaProps> = ({
  className,
  size,
  label,
  disabled,
  required,
  placeholder,
  value,
  passed,
  failed,
  preview,
  maxLength,
  keepLabelEnable,
  showCount,
  showEmoji,
  onChange,
}) => {
  return (
    <div className={classNames('TextArea', className, { preview })}>
      <FormLabel
        disabled={disabled}
        keepLabelEnable={keepLabelEnable}
        label={label}
        required={required}
        passed={passed}
        failed={failed}
      />

      <AntdTextArea
        size={size}
        placeholder={placeholder}
        value={value}
        onChange={(e): void => onChange?.(e.target.value)}
        disabled={disabled}
        showCount={showCount}
        readOnly={preview}
        maxLength={maxLength}
      />

      {showEmoji && <TextAreaEmoji onPicker={(data): void => onChange?.(`${value || ''} ${data}`)} />}
    </div>
  );
};

export default TextArea;

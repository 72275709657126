import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getMyRequestsAction } from '@/redux/actions';
import { getMyRequests, TGetMyRequestsResponse } from '@/services/api';

// FUNCTION

export function* getMyRequestsSaga(action: ActionType<typeof getMyRequestsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getMyRequests, materials);
    const getMyRequestsResponse: TGetMyRequestsResponse = response as TGetMyRequestsResponse;
    yield put(getMyRequestsAction.success(getMyRequestsResponse));
    successCallback?.(getMyRequestsResponse);
  } catch (err) {
    yield put(getMyRequestsAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TUpdateUserCareerMaterials, TUpdateUserCareerResponse } from '@/services/api/user/update-user-career';

// CONSTANTS

export enum EUpdateUserCareerAction {
  UPDATE_USER_CAREER = 'UPDATE_USER_CAREER',
  UPDATE_USER_CAREER_REQUEST = 'UPDATE_USER_CAREER_REQUEST',
  UPDATE_USER_CAREER_SUCCESS = 'UPDATE_USER_CAREER_SUCCESS',
  UPDATE_USER_CAREER_FAILED = 'UPDATE_USER_CAREER_FAILED',
}

// TYPES

export type TUpdateUserCareerRequest = {
  type: EUpdateUserCareerAction.UPDATE_USER_CAREER_REQUEST;
  payload: {
    materials: TUpdateUserCareerMaterials;
    successCallback?: (response: TUpdateUserCareerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateUserCareerSuccess = {
  type: EUpdateUserCareerAction.UPDATE_USER_CAREER_SUCCESS;
  payload: { response: TUpdateUserCareerResponse };
};

export type TUpdateUserCareerFailed = { type: EUpdateUserCareerAction.UPDATE_USER_CAREER_FAILED };

// FUNCTION

export const updateUserCareerAction = {
  request: createActionCreator(
    EUpdateUserCareerAction.UPDATE_USER_CAREER_REQUEST,
    (resolve) =>
      (
        materials: TUpdateUserCareerMaterials,
        successCallback?: (response: TUpdateUserCareerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateUserCareerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateUserCareerAction.UPDATE_USER_CAREER_SUCCESS,
    (resolve) =>
      (response: TUpdateUserCareerResponse): TUpdateUserCareerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateUserCareerAction.UPDATE_USER_CAREER_FAILED,
    (resolve) =>
      (error: unknown): TUpdateUserCareerFailed =>
        resolve({ error }),
  ),
};

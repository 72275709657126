import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getDepartmentAction } from '@/redux/actions';
import { getDepartment, TGetDepartmentResponse } from '@/services/api';

// FUNCTION

export function* getDepartmentSaga(action: ActionType<typeof getDepartmentAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getDepartment, materials);
    const getDepartmentResponse: TGetDepartmentResponse = response as TGetDepartmentResponse;
    yield put(getDepartmentAction.success(getDepartmentResponse));
    successCallback?.(getDepartmentResponse);
  } catch (err) {
    yield put(getDepartmentAction.failure(err));
    failedCallback?.(err);
  }
}

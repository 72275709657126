import { createReducer } from 'deox';

import {
  TCreateRegulateCategoryResponse,
  TDeleteRegulateCategoryResponse,
  TGetRegulateCategoriesResponse,
  TUpdateRegulateCategoryResponse,
} from '@/services/api/regulate-category';
import {
  createRegulateCategoryAction,
  deleteRegulateCategoryAction,
  getRegulateCategoriesAction,
  updateRegulateCategoryAction,
} from '@/redux/actions';
import { createRegulateCategoryUpdateState } from './create-regulate-category';
import { deleteRegulateCategoryUpdateState } from './delete-regulate-category';
import { getRegulateCategoriesUpdateState } from './get-regulate-categories';
import { updateRegulateCategoryUpdateState } from './update-regulate-category';

export type TRegulateCategoryState = {
  createRegulateCategoryResponse?: TCreateRegulateCategoryResponse;
  deleteRegulateCategoryResponse?: TDeleteRegulateCategoryResponse;
  getRegulateCategoriesResponse?: TGetRegulateCategoriesResponse;
  updateRegulateCategoryResponse?: TUpdateRegulateCategoryResponse;
};

const initialState: TRegulateCategoryState = {
  createRegulateCategoryResponse: undefined,
  deleteRegulateCategoryResponse: undefined,
  getRegulateCategoriesResponse: undefined,
  updateRegulateCategoryResponse: undefined,
};

const RegulateCategoryReducer = createReducer(initialState, (handleAction) => [
  handleAction(createRegulateCategoryAction.success, createRegulateCategoryUpdateState),
  handleAction(deleteRegulateCategoryAction.success, deleteRegulateCategoryUpdateState),
  handleAction(getRegulateCategoriesAction.success, getRegulateCategoriesUpdateState),
  handleAction(updateRegulateCategoryAction.success, updateRegulateCategoryUpdateState),
]);

export default RegulateCategoryReducer;

import { createReducer } from 'deox';

import {
  TChangePasswordResponse,
  TCreateNewBackgroundSettingResponse,
  TDeleteBackgroundSettingResponse,
  TGetSettingsResponse,
  TUpdateSettingResponse,
} from '@/services/api/setting';
import {
  changePasswordAction,
  createNewBackgroundSettingAction,
  deleteBackgroundSettingAction,
  getSettingsAction,
  updateSettingAction,
} from '@/redux/actions';
import { changePasswordUpdateState } from './change-password';
import { createNewBackgroundSettingUpdateState } from './create-new-background-setting';
import { deleteBackgroundSettingUpdateState } from './delete-background-setting';
import { getSettingsUpdateState } from './get-settings';
import { updateSettingUpdateState } from './update-setting';

export type TSettingState = {
  changePasswordResponse?: TChangePasswordResponse;
  createNewBackgroundSettingResponse?: TCreateNewBackgroundSettingResponse;
  deleteBackgroundSettingResponse?: TDeleteBackgroundSettingResponse;
  getSettingsResponse?: TGetSettingsResponse;
  updateSettingResponse?: TUpdateSettingResponse;
};

const initialState: TSettingState = {
  changePasswordResponse: undefined,
  createNewBackgroundSettingResponse: undefined,
  deleteBackgroundSettingResponse: undefined,
  getSettingsResponse: undefined,
  updateSettingResponse: undefined,
};

const SettingReducer = createReducer(initialState, (handleAction) => [
  handleAction(changePasswordAction.success, changePasswordUpdateState),
  handleAction(createNewBackgroundSettingAction.success, createNewBackgroundSettingUpdateState),
  handleAction(deleteBackgroundSettingAction.success, deleteBackgroundSettingUpdateState),
  handleAction(getSettingsAction.success, getSettingsUpdateState),
  handleAction(updateSettingAction.success, updateSettingUpdateState),
]);

export default SettingReducer;

import { createActionCreator } from 'deox';

import {
  TGetBenefitCoveragesMaterials,
  TGetBenefitCoveragesResponse,
} from '@/services/api/benefit/get-benefit-coverages';

// CONSTANTS

export enum EGetBenefitCoveragesAction {
  GET_BENEFIT_COVERAGES = 'GET_BENEFIT_COVERAGES',
  GET_BENEFIT_COVERAGES_REQUEST = 'GET_BENEFIT_COVERAGES_REQUEST',
  GET_BENEFIT_COVERAGES_SUCCESS = 'GET_BENEFIT_COVERAGES_SUCCESS',
  GET_BENEFIT_COVERAGES_FAILED = 'GET_BENEFIT_COVERAGES_FAILED',
}

// TYPES

export type TGetBenefitCoveragesRequest = {
  type: EGetBenefitCoveragesAction.GET_BENEFIT_COVERAGES_REQUEST;
  payload: {
    materials: TGetBenefitCoveragesMaterials;
    successCallback?: (response: TGetBenefitCoveragesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBenefitCoveragesSuccess = {
  type: EGetBenefitCoveragesAction.GET_BENEFIT_COVERAGES_SUCCESS;
  payload: { response: TGetBenefitCoveragesResponse };
};

export type TGetBenefitCoveragesFailed = { type: EGetBenefitCoveragesAction.GET_BENEFIT_COVERAGES_FAILED };

// FUNCTION

export const getBenefitCoveragesAction = {
  request: createActionCreator(
    EGetBenefitCoveragesAction.GET_BENEFIT_COVERAGES_REQUEST,
    (resolve) =>
      (
        materials: TGetBenefitCoveragesMaterials,
        successCallback?: (response: TGetBenefitCoveragesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBenefitCoveragesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetBenefitCoveragesAction.GET_BENEFIT_COVERAGES_SUCCESS,
    (resolve) =>
      (response: TGetBenefitCoveragesResponse): TGetBenefitCoveragesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetBenefitCoveragesAction.GET_BENEFIT_COVERAGES_FAILED,
    (resolve) =>
      (error: unknown): TGetBenefitCoveragesFailed =>
        resolve({ error }),
  ),
};

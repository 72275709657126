import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { requestReturnAssetAction } from '@/redux/actions';
import { requestReturnAsset, TRequestReturnAssetResponse } from '@/services/api';

// FUNCTION

export function* requestReturnAssetSaga(action: ActionType<typeof requestReturnAssetAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(requestReturnAsset, materials);
    const requestReturnAssetResponse: TRequestReturnAssetResponse = response as TRequestReturnAssetResponse;
    yield put(requestReturnAssetAction.success(requestReturnAssetResponse));
    successCallback?.(requestReturnAssetResponse);
  } catch (err) {
    yield put(requestReturnAssetAction.failure(err));
    failedCallback?.(err);
  }
}

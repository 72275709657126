import ApiService from '@/services/api';

// TYPES

export type TCopyLastYearHolidaysParams = unknown;
export type TCopyLastYearHolidaysBody = unknown;

export type TCopyLastYearHolidaysMaterials = {
  params?: TCopyLastYearHolidaysParams;
  body?: TCopyLastYearHolidaysBody;
};

export type TCopyLastYearHolidaysResponse = unknown;

// FUNCTION

export const copyLastYearHolidays = async ({
  params,
  body,
}: TCopyLastYearHolidaysMaterials): Promise<TCopyLastYearHolidaysResponse> => {
  const response = await ApiService.post(`/holiday/cp-last-year`, body, { params });
  return response.data;
};

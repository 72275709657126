import { createActionCreator } from 'deox';

import {
  TDownloadRegulationMaterials,
  TDownloadRegulationResponse,
} from '@/services/api/regulation/download-regulation';

// CONSTANTS

export enum EDownloadRegulationAction {
  DOWNLOAD_REGULATION = 'DOWNLOAD_REGULATION',
  DOWNLOAD_REGULATION_REQUEST = 'DOWNLOAD_REGULATION_REQUEST',
  DOWNLOAD_REGULATION_SUCCESS = 'DOWNLOAD_REGULATION_SUCCESS',
  DOWNLOAD_REGULATION_FAILED = 'DOWNLOAD_REGULATION_FAILED',
}

// TYPES

export type TDownloadRegulationRequest = {
  type: EDownloadRegulationAction.DOWNLOAD_REGULATION_REQUEST;
  payload: {
    materials: TDownloadRegulationMaterials;
    successCallback?: (response: TDownloadRegulationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadRegulationSuccess = {
  type: EDownloadRegulationAction.DOWNLOAD_REGULATION_SUCCESS;
  payload: { response: TDownloadRegulationResponse };
};

export type TDownloadRegulationFailed = { type: EDownloadRegulationAction.DOWNLOAD_REGULATION_FAILED };

// FUNCTION

export const downloadRegulationAction = {
  request: createActionCreator(
    EDownloadRegulationAction.DOWNLOAD_REGULATION_REQUEST,
    (resolve) =>
      (
        materials: TDownloadRegulationMaterials,
        successCallback?: (response: TDownloadRegulationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadRegulationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadRegulationAction.DOWNLOAD_REGULATION_SUCCESS,
    (resolve) =>
      (response: TDownloadRegulationResponse): TDownloadRegulationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadRegulationAction.DOWNLOAD_REGULATION_FAILED,
    (resolve) =>
      (error: unknown): TDownloadRegulationFailed =>
        resolve({ error }),
  ),
};

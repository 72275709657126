import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { revokeAccessUserAction } from '@/redux/actions';
import { revokeAccessUser, TRevokeAccessUserResponse } from '@/services/api';

// FUNCTION

export function* revokeAccessUserSaga(action: ActionType<typeof revokeAccessUserAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(revokeAccessUser, materials);
    const revokeAccessUserResponse: TRevokeAccessUserResponse = response as TRevokeAccessUserResponse;
    yield put(revokeAccessUserAction.success(revokeAccessUserResponse));
    successCallback?.(revokeAccessUserResponse);
  } catch (err) {
    yield put(revokeAccessUserAction.failure(err));
    failedCallback?.(err);
  }
}

import { TTemplateState } from '@/redux/reducers/template';
import { TDeleteTaskCategorySuccess } from '@/redux/actions/template';

export const deleteTaskCategoryUpdateState = (
  state: TTemplateState,
  action: TDeleteTaskCategorySuccess,
): TTemplateState => ({
  ...state,
  deleteTaskCategoryResponse: action.payload.response,
});

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getAssetAction } from '@/redux/actions';
import { getAsset, TGetAssetResponse } from '@/services/api';

// FUNCTION

export function* getAssetSaga(action: ActionType<typeof getAssetAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getAsset, materials);
    const getAssetResponse: TGetAssetResponse = response as TGetAssetResponse;
    yield put(getAssetAction.success(getAssetResponse));
    successCallback?.(getAssetResponse);
  } catch (err) {
    yield put(getAssetAction.failure(err));
    failedCallback?.(err);
  }
}

import { TUserState } from '@/redux/reducers/user';
import { TChangeUserTypeStatusSuccess } from '@/redux/actions/user';

export const changeUserTypeStatusUpdateState = (
  state: TUserState,
  action: TChangeUserTypeStatusSuccess,
): TUserState => ({
  ...state,
  changeUserTypeStatusResponse: action.payload.response,
});

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.375 10.625C17.375 14.6488 11.5594 16.0292 10.6258 16.2249C10.5428 16.2423 10.4572 16.2423 10.3742 16.2249C9.4406 16.0292 3.625 14.6488 3.625 10.625V6.06277C3.625 5.93447 3.66448 5.80929 3.73808 5.70421C3.81168 5.59912 3.91584 5.51924 4.03641 5.4754L10.2864 3.20267C10.4244 3.1525 10.5756 3.1525 10.7136 3.20267L16.9636 5.4754C17.0842 5.51924 17.1883 5.59912 17.2619 5.70421C17.3355 5.80929 17.375 5.93447 17.375 6.06277V10.625Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.375 8.75H13.625" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.375 11.25H13.625" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.1729 11.875H18.0002C18.4975 11.875 18.9744 11.6775 19.326 11.3258C19.6777 10.9742 19.8752 10.4973 19.8752 10V8.125C19.8752 7.62772 19.6777 7.15081 19.326 6.79917C18.9744 6.44754 18.4975 6.25 18.0002 6.25H17.3752"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.82744 11.875H3C2.50272 11.875 2.02581 11.6775 1.67417 11.3258C1.32254 10.9742 1.125 10.4973 1.125 10V8.125C1.125 7.62772 1.32254 7.15081 1.67417 6.79917C2.02581 6.44754 2.50272 6.25 3 6.25H3.625"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

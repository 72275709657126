import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { downloadReportEmployeeDataAction } from '@/redux/actions';
import { downloadReportEmployeeData, TDownloadReportEmployeeDataResponse } from '@/services/api';

// FUNCTION

export function* downloadReportEmployeeDataSaga(
  action: ActionType<typeof downloadReportEmployeeDataAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(downloadReportEmployeeData, materials);
    const downloadReportEmployeeDataResponse: TDownloadReportEmployeeDataResponse =
      response as TDownloadReportEmployeeDataResponse;
    yield put(downloadReportEmployeeDataAction.success(downloadReportEmployeeDataResponse));
    successCallback?.(downloadReportEmployeeDataResponse);
  } catch (err) {
    yield put(downloadReportEmployeeDataAction.failure(err));
    failedCallback?.(err);
  }
}

import { TCompanyState } from '@/redux/reducers/company';
import { TUpdateGenerateEmployIdSuccess } from '@/redux/actions/company';

export const updateGenerateEmployIdUpdateState = (
  state: TCompanyState,
  action: TUpdateGenerateEmployIdSuccess,
): TCompanyState => ({
  ...state,
  updateGenerateEmployIdResponse: action.payload.response,
});

import ApiService from '@/services/api';

// TYPES

export type TDeleteTemplatePaths = {
  id: string | number;
};
export type TDeleteTemplateParams = unknown;

export type TDeleteTemplateMaterials = {
  paths?: TDeleteTemplatePaths;
  params?: TDeleteTemplateParams;
};

export type TDeleteTemplateResponse = unknown;

// FUNCTION

export const deleteTemplate = async ({ paths, params }: TDeleteTemplateMaterials): Promise<TDeleteTemplateResponse> => {
  const response = await ApiService.delete(`/template/${paths?.id}`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TCreateAssetParams = unknown;
export type TCreateAssetBody = unknown;

export type TCreateAssetMaterials = {
  params?: TCreateAssetParams;
  body?: TCreateAssetBody;
};

export type TCreateAssetResponse = unknown;

// FUNCTION

export const createAsset = async ({ params, body }: TCreateAssetMaterials): Promise<TCreateAssetResponse> => {
  const response = await ApiService.post(`/asset/new`, body, { params });
  return response.data;
};

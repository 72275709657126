import React from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import Modal from '@/components/Modal';
import { EButtonStyleType } from '@/components/Button';
import Helpers from '@/services/helpers';
import { getMyInfoAction } from '@/redux/actions';
import { generateIdPortalLink } from '@/utils/functions';

import { TModalLogoutProps } from './ModalLogout.types';
import './ModalLogout.scss';

const ModalLogout: React.FC<TModalLogoutProps> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (): void => {
    Helpers.clearTokens();
    dispatch(getMyInfoAction.success(undefined));
    navigate(generateIdPortalLink());
  };

  return (
    <Modal
      width={416}
      visible={visible}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('ModalLogout_title')}
      showFooter
      closeable={false}
      cancelButton={{ title: t('Common_close') }}
      confirmButton={{ title: t('Common_confirm'), styleType: EButtonStyleType.PRIMARY }}
    >
      <div className="ModalLogout-wrapper">
        <div className="ModalLogout-text">{t('ModalLogout_description')}</div>
      </div>
    </Modal>
  );
};

export default ModalLogout;

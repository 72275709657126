import { createActionCreator } from 'deox';

import { TDownloadPeopleListMaterials, TDownloadPeopleListResponse } from '@/services/api/user/download-people-list';

// CONSTANTS

export enum EDownloadPeopleListAction {
  DOWNLOAD_PEOPLE_LIST = 'DOWNLOAD_PEOPLE_LIST',
  DOWNLOAD_PEOPLE_LIST_REQUEST = 'DOWNLOAD_PEOPLE_LIST_REQUEST',
  DOWNLOAD_PEOPLE_LIST_SUCCESS = 'DOWNLOAD_PEOPLE_LIST_SUCCESS',
  DOWNLOAD_PEOPLE_LIST_FAILED = 'DOWNLOAD_PEOPLE_LIST_FAILED',
}

// TYPES

export type TDownloadPeopleListRequest = {
  type: EDownloadPeopleListAction.DOWNLOAD_PEOPLE_LIST_REQUEST;
  payload: {
    materials: TDownloadPeopleListMaterials;
    successCallback?: (response: TDownloadPeopleListResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadPeopleListSuccess = {
  type: EDownloadPeopleListAction.DOWNLOAD_PEOPLE_LIST_SUCCESS;
  payload: { response: TDownloadPeopleListResponse };
};

export type TDownloadPeopleListFailed = { type: EDownloadPeopleListAction.DOWNLOAD_PEOPLE_LIST_FAILED };

// FUNCTION

export const downloadPeopleListAction = {
  request: createActionCreator(
    EDownloadPeopleListAction.DOWNLOAD_PEOPLE_LIST_REQUEST,
    (resolve) =>
      (
        materials: TDownloadPeopleListMaterials,
        successCallback?: (response: TDownloadPeopleListResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadPeopleListRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadPeopleListAction.DOWNLOAD_PEOPLE_LIST_SUCCESS,
    (resolve) =>
      (response: TDownloadPeopleListResponse): TDownloadPeopleListSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadPeopleListAction.DOWNLOAD_PEOPLE_LIST_FAILED,
    (resolve) =>
      (error: unknown): TDownloadPeopleListFailed =>
        resolve({ error }),
  ),
};

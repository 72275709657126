import ApiService from '@/services/api';

// TYPES

export type TUpdateUserCareerPaths = {
  id: string | number;
};
export type TUpdateUserCareerBody = unknown;

export type TUpdateUserCareerMaterials = {
  paths?: TUpdateUserCareerPaths;
  body?: TUpdateUserCareerBody;
};

export type TUpdateUserCareerResponse = unknown;

// FUNCTION

export const updateUserCareer = async ({
  paths,
  body,
}: TUpdateUserCareerMaterials): Promise<TUpdateUserCareerResponse> => {
  const response = await ApiService.patch(`/user/career/${paths?.id}`, body);
  return response.data;
};

import { TRoleState } from '@/redux/reducers/role';
import { TUpdateRolePermissionSuccess } from '@/redux/actions/role';

export const updateRolePermissionUpdateState = (
  state: TRoleState,
  action: TUpdateRolePermissionSuccess,
): TRoleState => ({
  ...state,
  updateRolePermissionResponse: action.payload.response,
});

import { all, takeLatest } from 'redux-saga/effects';

import {
  createRegulationAction,
  deleteRegulationAction,
  downloadRegulationAction,
  getRegulationAction,
  getRegulationsAction,
  updateRegulationAction,
} from '@/redux/actions';

import { createRegulationSaga } from './create-regulation';
import { deleteRegulationSaga } from './delete-regulation';
import { downloadRegulationSaga } from './download-regulation';
import { getRegulationSaga } from './get-regulation';
import { getRegulationsSaga } from './get-regulations';
import { updateRegulationSaga } from './update-regulation';

export default function* root(): Generator {
  yield all([
    takeLatest(createRegulationAction.request.type, createRegulationSaga),
    takeLatest(deleteRegulationAction.request.type, deleteRegulationSaga),
    takeLatest(downloadRegulationAction.request.type, downloadRegulationSaga),
    takeLatest(getRegulationAction.request.type, getRegulationSaga),
    takeLatest(getRegulationsAction.request.type, getRegulationsSaga),
    takeLatest(updateRegulationAction.request.type, updateRegulationSaga),
  ]);
}

import { Paths } from '@/pages/routers';
import { checkPermissionModule } from '@/utils/functions';
import { TTranslation } from '@/common/types';

import { THeaderNavData } from './Header.types';
import {
  EPermisisonManage,
  EFeatureManage,
} from '@/pages/Settings/Permissions/PermissionsDetail/PermissionsDetail.enums';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const dataHeaderMenu = (t: TTranslation, data: any): THeaderNavData[] => {
  const permisisonManagerReports = checkPermissionModule(
    data?.myInfoState,
    EPermisisonManage.REPORTS,
    EFeatureManage.MANAGER_REPORTS,
  );
  const permisisonCompanyRegulations = checkPermissionModule(
    data?.myInfoState,
    EPermisisonManage.REGULATIONS,
    EFeatureManage.COMPANY_REGULATIONS,
  );
  const permisisonTasksChecklist = checkPermissionModule(
    data?.myInfoState,
    EPermisisonManage.CHECKLISTS,
    EFeatureManage.TASKS_CHECKLIST,
  );
  const permisisonAssets = checkPermissionModule(data?.myInfoState, EPermisisonManage.ASSETS, EFeatureManage.ASSETS);
  const permisisonEmployeesView2Nd = checkPermissionModule(
    data?.myInfoState,
    EPermisisonManage.EMPLOYEES,
    EFeatureManage.EMPLOYEES_VIEW_2ND,
  );

  return [
    {
      key: '1',
      link: Paths.SelfService,
      activePaths: [
        Paths.SelfService,
        Paths.NewTimeOffRequest,
        Paths.DetailTimeOffRequest(data.id),
        Paths.NewBorrowAssetRequest,
        Paths.NewBorrowAssetRequest,
        Paths.NewReturnAssetRequest,
        Paths.NewResignRequest,
        Paths.NewContractTerminationRequest,
        Paths.NewProbationTerminationRequest,
      ],
      title: t('Header_self_service'),
    },
    {
      key: '2',
      link: Paths.Peoples,
      activePaths: [Paths.Peoples, Paths.InvitePeople, Paths.PeopleDetail(data?.id)],
      title: t('Header_people'),
      hide: !permisisonEmployeesView2Nd?.canView,
    },
    {
      key: '3',
      link: Paths.Assets,
      activePaths: [Paths.Assets],
      title: t('Header_assets'),
      hide: !permisisonAssets?.canView,
    },
    {
      key: '4',
      link: Paths.Checklists,
      activePaths: [
        Paths.Checklists,
        Paths.NewTask,
        Paths.OnboardingPeople(data?.id),
        Paths.OffboardingPeople(data?.id),
        Paths.EditOnboardingPeople(data?.id),
        Paths.EditOnboardingPeople(data?.id),
        Paths.NewRequestTask(data?.requestId),
        Paths.EditRequestTask(data?.requestId, data?.taskId),
      ],
      title: t('Header_checklists'),
      hide: !permisisonTasksChecklist?.canView,
    },
    {
      key: '5',
      link: Paths.CompanyRegulations,
      activePaths: [Paths.CompanyRegulations, Paths.NewRegulation, Paths.EditRegulation(data.id)],
      title: t('Header_company_regulations'),
      hide: !permisisonCompanyRegulations?.canView,
    },
    {
      key: '6',
      link: Paths.Report,
      activePaths: [
        Paths.Report,
        Paths.ReportHeadcount,
        Paths.ReportOnboarding,
        Paths.ReportOffboarding,
        Paths.ReportTimeOffBalance,
        Paths.ReportTimeOffSchedule,
        Paths.ReportMonthlyTimekeeping,
        Paths.ReportEmployeeData,
        Paths.ReportEmployeeTurnover,
      ],
      title: t('Header_report'),
      hide: !permisisonManagerReports?.canView,
    },
  ];
};

import { createActionCreator } from 'deox';

import {
  TDeleteTimeOffTypeMaterials,
  TDeleteTimeOffTypeResponse,
} from '@/services/api/time-off-type/delete-time-off-type';

// CONSTANTS

export enum EDeleteTimeOffTypeAction {
  DELETE_TIME_OFF_TYPE = 'DELETE_TIME_OFF_TYPE',
  DELETE_TIME_OFF_TYPE_REQUEST = 'DELETE_TIME_OFF_TYPE_REQUEST',
  DELETE_TIME_OFF_TYPE_SUCCESS = 'DELETE_TIME_OFF_TYPE_SUCCESS',
  DELETE_TIME_OFF_TYPE_FAILED = 'DELETE_TIME_OFF_TYPE_FAILED',
}

// TYPES

export type TDeleteTimeOffTypeRequest = {
  type: EDeleteTimeOffTypeAction.DELETE_TIME_OFF_TYPE_REQUEST;
  payload: {
    materials: TDeleteTimeOffTypeMaterials;
    successCallback?: (response: TDeleteTimeOffTypeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteTimeOffTypeSuccess = {
  type: EDeleteTimeOffTypeAction.DELETE_TIME_OFF_TYPE_SUCCESS;
  payload: { response: TDeleteTimeOffTypeResponse };
};

export type TDeleteTimeOffTypeFailed = { type: EDeleteTimeOffTypeAction.DELETE_TIME_OFF_TYPE_FAILED };

// FUNCTION

export const deleteTimeOffTypeAction = {
  request: createActionCreator(
    EDeleteTimeOffTypeAction.DELETE_TIME_OFF_TYPE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteTimeOffTypeMaterials,
        successCallback?: (response: TDeleteTimeOffTypeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteTimeOffTypeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteTimeOffTypeAction.DELETE_TIME_OFF_TYPE_SUCCESS,
    (resolve) =>
      (response: TDeleteTimeOffTypeResponse): TDeleteTimeOffTypeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteTimeOffTypeAction.DELETE_TIME_OFF_TYPE_FAILED,
    (resolve) =>
      (error: unknown): TDeleteTimeOffTypeFailed =>
        resolve({ error }),
  ),
};

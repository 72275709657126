import React, { useEffect } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@/components/Modal';
import UploadDragDrop from '@/components/UploadDragDrop';
import { TRootState } from '@/redux/reducers';
import {
  downloadTemplateUsersAction,
  EDownloadTemplateUsersAction,
  EImportUsersAction,
  importUsersAction,
} from '@/redux/actions';
import { ETypeNotification } from '@/common/enums';
import { parseObjectToFormData, showNotification, validationRules } from '@/utils/functions';

import { TModalImportEmployeeProps } from './ModalImportEmployee.types';
import './ModalImportEmployee.scss';
import env from '@/env';

const ModalImportEmployee: React.FC<TModalImportEmployeeProps> = ({ visible, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const importUserLoading = useSelector((state: TRootState) => state.loadingReducer[EImportUsersAction.IMPORT_USERS]);
  const downloadTemplateUserLoading = useSelector(
    (state: TRootState) => state.loadingReducer[EDownloadTemplateUsersAction.DOWNLOAD_TEMPLATE_USERS],
  );

  const handleDownloadTemplateUser = (): void => {
    if (!downloadTemplateUserLoading) {
      dispatch(
        downloadTemplateUsersAction.request({}, (response): void => {
          window.open(`${env.api.baseUrl.service}${response.path}`);
        }),
      );
    }
  };

  const handleSubmit = (): void => {
    form.validateFields().then((values) => {
      const formData = parseObjectToFormData({});
      if (values.file) {
        values.file.forEach((item: File) => formData.append('file', item));
      }

      dispatch(importUsersAction.request({ body: formData }, handleImportUserSuccess));
    });
  };

  const handleImportUserSuccess = (): void => {
    showNotification(ETypeNotification.SUCCESS, t('Notification_import_employees_successfully'));
    onClose?.();
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  return (
    <Modal
      width={416}
      visible={visible}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('ModalImportEmployee_title')}
      showFooter
      cancelButton={{ title: t('Common_close'), disabled: importUserLoading }}
      confirmButton={{ title: t('Common_confirm'), disabled: importUserLoading }}
    >
      <div className="ModalImportEmployee-wrapper">
        <div className="ModalImportEmployee-text">{t('ModalImportEmployee_description')}</div>

        <Form form={form} layout="vertical">
          <Form.Item name="file" rules={[validationRules.required(t)]}>
            <UploadDragDrop accept=".csv, .xlsx, .xls" />
          </Form.Item>
        </Form>

        <div className="ModalImportEmployee-text">
          {t('ModalImportEmployee_or')}{' '}
          <span onClick={handleDownloadTemplateUser}>{t('ModalImportEmployee_download')}</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalImportEmployee;

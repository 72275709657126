import ApiService from '@/services/api';

// TYPES

export type TCreateTaskCategoryParams = unknown;
export type TCreateTaskCategoryBody = unknown;

export type TCreateTaskCategoryMaterials = {
  params?: TCreateTaskCategoryParams;
  body?: TCreateTaskCategoryBody;
};

export type TCreateTaskCategoryResponse = unknown;

// FUNCTION

export const createTaskCategory = async ({
  params,
  body,
}: TCreateTaskCategoryMaterials): Promise<TCreateTaskCategoryResponse> => {
  const response = await ApiService.post(`/template/task-category/new`, body, { params });
  return response.data;
};

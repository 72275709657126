import ApiService from '@/services/api';

// TYPES

export type TUpdateTypeWorkPaths = {
  id: string | number;
};
export type TUpdateTypeWorkBody = unknown;

export type TUpdateTypeWorkMaterials = {
  paths?: TUpdateTypeWorkPaths;
  body?: TUpdateTypeWorkBody;
};

export type TUpdateTypeWorkResponse = unknown;

// FUNCTION

export const updateTypeWork = async ({ paths, body }: TUpdateTypeWorkMaterials): Promise<TUpdateTypeWorkResponse> => {
  const response = await ApiService.put(`/type-work/${paths?.id}`, body);
  return response.data;
};

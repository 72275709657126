import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.12518 4.37891H4.37518C4.03001 4.37891 3.75018 4.65873 3.75018 5.00391V8.75391C3.75018 9.09908 4.03001 9.37891 4.37518 9.37891H8.12518C8.47036 9.37891 8.75018 9.09908 8.75018 8.75391V5.00391C8.75018 4.65873 8.47036 4.37891 8.12518 4.37891Z"
        stroke={color}
        className="stroke"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12518 11.8789H4.37518C4.03001 11.8789 3.75018 12.1587 3.75018 12.5039V16.2539C3.75018 16.5991 4.03001 16.8789 4.37518 16.8789H8.12518C8.47036 16.8789 8.75018 16.5991 8.75018 16.2539V12.5039C8.75018 12.1587 8.47036 11.8789 8.12518 11.8789Z"
        stroke={color}
        className="stroke"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6252 4.37891H11.8752C11.53 4.37891 11.2502 4.65873 11.2502 5.00391V8.75391C11.2502 9.09908 11.53 9.37891 11.8752 9.37891H15.6252C15.9704 9.37891 16.2502 9.09908 16.2502 8.75391V5.00391C16.2502 4.65873 15.9704 4.37891 15.6252 4.37891Z"
        stroke={color}
        className="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2502 11.8789V14.3789"
        stroke={color}
        className="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2502 16.8789H13.7502V11.8789"
        stroke={color}
        className="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7502 13.1289H16.2502"
        stroke={color}
        className="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2502 15.6289V16.8789"
        stroke={color}
        className="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

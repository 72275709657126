import { createActionCreator } from 'deox';

import {
  TDownloadReportHeadcountMaterials,
  TDownloadReportHeadcountResponse,
} from '@/services/api/report/download-report-headcount';

// CONSTANTS

export enum EDownloadReportHeadcountAction {
  DOWNLOAD_REPORT_HEADCOUNT = 'DOWNLOAD_REPORT_HEADCOUNT',
  DOWNLOAD_REPORT_HEADCOUNT_REQUEST = 'DOWNLOAD_REPORT_HEADCOUNT_REQUEST',
  DOWNLOAD_REPORT_HEADCOUNT_SUCCESS = 'DOWNLOAD_REPORT_HEADCOUNT_SUCCESS',
  DOWNLOAD_REPORT_HEADCOUNT_FAILED = 'DOWNLOAD_REPORT_HEADCOUNT_FAILED',
}

// TYPES

export type TDownloadReportHeadcountRequest = {
  type: EDownloadReportHeadcountAction.DOWNLOAD_REPORT_HEADCOUNT_REQUEST;
  payload: {
    materials: TDownloadReportHeadcountMaterials;
    successCallback?: (response: TDownloadReportHeadcountResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadReportHeadcountSuccess = {
  type: EDownloadReportHeadcountAction.DOWNLOAD_REPORT_HEADCOUNT_SUCCESS;
  payload: { response: TDownloadReportHeadcountResponse };
};

export type TDownloadReportHeadcountFailed = { type: EDownloadReportHeadcountAction.DOWNLOAD_REPORT_HEADCOUNT_FAILED };

// FUNCTION

export const downloadReportHeadcountAction = {
  request: createActionCreator(
    EDownloadReportHeadcountAction.DOWNLOAD_REPORT_HEADCOUNT_REQUEST,
    (resolve) =>
      (
        materials: TDownloadReportHeadcountMaterials,
        successCallback?: (response: TDownloadReportHeadcountResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadReportHeadcountRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadReportHeadcountAction.DOWNLOAD_REPORT_HEADCOUNT_SUCCESS,
    (resolve) =>
      (response: TDownloadReportHeadcountResponse): TDownloadReportHeadcountSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadReportHeadcountAction.DOWNLOAD_REPORT_HEADCOUNT_FAILED,
    (resolve) =>
      (error: unknown): TDownloadReportHeadcountFailed =>
        resolve({ error }),
  ),
};

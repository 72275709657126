import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.87402 6L7.77784 9.90382C7.80634 9.93418 7.84075 9.95838 7.87897 9.97492C7.91718 9.99147 7.95837 10 8.00001 10C8.04165 10 8.08285 9.99147 8.12106 9.97492C8.15927 9.95838 8.19369 9.93418 8.22218 9.90382L12.126 6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { createActionCreator } from 'deox';

import {
  TGetRegulateCategoriesMaterials,
  TGetRegulateCategoriesResponse,
} from '@/services/api/regulate-category/get-regulate-categories';

// CONSTANTS

export enum EGetRegulateCategoriesAction {
  GET_REGULATE_CATEGORIES = 'GET_REGULATE_CATEGORIES',
  GET_REGULATE_CATEGORIES_REQUEST = 'GET_REGULATE_CATEGORIES_REQUEST',
  GET_REGULATE_CATEGORIES_SUCCESS = 'GET_REGULATE_CATEGORIES_SUCCESS',
  GET_REGULATE_CATEGORIES_FAILED = 'GET_REGULATE_CATEGORIES_FAILED',
}

// TYPES

export type TGetRegulateCategoriesRequest = {
  type: EGetRegulateCategoriesAction.GET_REGULATE_CATEGORIES_REQUEST;
  payload: {
    materials: TGetRegulateCategoriesMaterials;
    successCallback?: (response: TGetRegulateCategoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRegulateCategoriesSuccess = {
  type: EGetRegulateCategoriesAction.GET_REGULATE_CATEGORIES_SUCCESS;
  payload: { response: TGetRegulateCategoriesResponse };
};

export type TGetRegulateCategoriesFailed = { type: EGetRegulateCategoriesAction.GET_REGULATE_CATEGORIES_FAILED };

// FUNCTION

export const getRegulateCategoriesAction = {
  request: createActionCreator(
    EGetRegulateCategoriesAction.GET_REGULATE_CATEGORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetRegulateCategoriesMaterials,
        successCallback?: (response: TGetRegulateCategoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRegulateCategoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRegulateCategoriesAction.GET_REGULATE_CATEGORIES_SUCCESS,
    (resolve) =>
      (response: TGetRegulateCategoriesResponse): TGetRegulateCategoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRegulateCategoriesAction.GET_REGULATE_CATEGORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetRegulateCategoriesFailed =>
        resolve({ error }),
  ),
};

import React from 'react';
import { Button as AntdButton } from 'antd';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import { TButtonProps } from '@/components/Button/Button.types';
import Icon, { EIconName } from '@/components/Icon';

import './Button.scss';

const Button: React.FC<TButtonProps> = ({
  className,
  size,
  iconName,
  shadow,
  iconColor,
  type,
  htmlType,
  title,
  danger,
  reverse,
  link,
  disabled,
  loading,
  styleType,
  showArrow,
  noHoverStyle,
  badge,
  noPadding,
  onClick,
}) => {
  const handleClickButton = (): void => {
    if (link) navigate(link);
    else onClick?.();
  };

  return (
    <div
      className={classNames('Button', className, styleType, {
        'no-hover-style': noHoverStyle,
        shadow,
        'only-icon': !title && iconName,
        'no-padding': noPadding,
        reverse,
      })}
    >
      <AntdButton
        size={size}
        type={type}
        htmlType={htmlType}
        onClick={handleClickButton}
        danger={danger}
        disabled={disabled}
        loading={loading}
      >
        {!!badge && <div className="Button-badge">{badge}</div>}
        {iconName && (
          <div className="Button-icon">
            <Icon name={iconName} color={iconColor} />
          </div>
        )}
        {title && <span className="Button-title">{title}</span>}

        {showArrow && (
          <div className="Button-icon">
            <Icon name={EIconName.AngleDown} color={iconColor} />
          </div>
        )}
      </AntdButton>
    </div>
  );
};

export default Button;

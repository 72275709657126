import { TTemplate } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetTemplatesParams = {
  page: number;
  pageSize: number;
  search?: string;
};

export type TGetTemplatesMaterials = {
  params?: TGetTemplatesParams;
};

export type TGetTemplatesResponse = TPaginateResponse & {
  data: TTemplate[];
  total: { [key: string]: string };
};

// FUNCTION

export const getTemplates = async ({ params }: TGetTemplatesMaterials): Promise<TGetTemplatesResponse> => {
  const response = await ApiService.get(`/template/list`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import {
  TGetUserTimeOffTypesMaterials,
  TGetUserTimeOffTypesResponse,
} from '@/services/api/user/get-user-time-off-types';

// CONSTANTS

export enum EGetUserTimeOffTypesAction {
  GET_USER_TIME_OFF_TYPES = 'GET_USER_TIME_OFF_TYPES',
  GET_USER_TIME_OFF_TYPES_REQUEST = 'GET_USER_TIME_OFF_TYPES_REQUEST',
  GET_USER_TIME_OFF_TYPES_SUCCESS = 'GET_USER_TIME_OFF_TYPES_SUCCESS',
  GET_USER_TIME_OFF_TYPES_FAILED = 'GET_USER_TIME_OFF_TYPES_FAILED',
}

// TYPES

export type TGetUserTimeOffTypesRequest = {
  type: EGetUserTimeOffTypesAction.GET_USER_TIME_OFF_TYPES_REQUEST;
  payload: {
    materials: TGetUserTimeOffTypesMaterials;
    successCallback?: (response: TGetUserTimeOffTypesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetUserTimeOffTypesSuccess = {
  type: EGetUserTimeOffTypesAction.GET_USER_TIME_OFF_TYPES_SUCCESS;
  payload: { response?: TGetUserTimeOffTypesResponse };
};

export type TGetUserTimeOffTypesFailed = { type: EGetUserTimeOffTypesAction.GET_USER_TIME_OFF_TYPES_FAILED };

// FUNCTION

export const getUserTimeOffTypesAction = {
  request: createActionCreator(
    EGetUserTimeOffTypesAction.GET_USER_TIME_OFF_TYPES_REQUEST,
    (resolve) =>
      (
        materials: TGetUserTimeOffTypesMaterials,
        successCallback?: (response: TGetUserTimeOffTypesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetUserTimeOffTypesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetUserTimeOffTypesAction.GET_USER_TIME_OFF_TYPES_SUCCESS,
    (resolve) =>
      (response?: TGetUserTimeOffTypesResponse): TGetUserTimeOffTypesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetUserTimeOffTypesAction.GET_USER_TIME_OFF_TYPES_FAILED,
    (resolve) =>
      (error: unknown): TGetUserTimeOffTypesFailed =>
        resolve({ error }),
  ),
};

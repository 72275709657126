import { createActionCreator } from 'deox';

import {
  TGetChecklistTaskOnboardMaterials,
  TGetChecklistTaskOnboardResponse,
} from '@/services/api/checklist/get-checklist-task-onboard';

// CONSTANTS

export enum EGetChecklistTaskOnboardAction {
  GET_CHECKLIST_TASK_ONBOARD = 'GET_CHECKLIST_TASK_ONBOARD',
  GET_CHECKLIST_TASK_ONBOARD_REQUEST = 'GET_CHECKLIST_TASK_ONBOARD_REQUEST',
  GET_CHECKLIST_TASK_ONBOARD_SUCCESS = 'GET_CHECKLIST_TASK_ONBOARD_SUCCESS',
  GET_CHECKLIST_TASK_ONBOARD_FAILED = 'GET_CHECKLIST_TASK_ONBOARD_FAILED',
}

// TYPES

export type TGetChecklistTaskOnboardRequest = {
  type: EGetChecklistTaskOnboardAction.GET_CHECKLIST_TASK_ONBOARD_REQUEST;
  payload: {
    materials: TGetChecklistTaskOnboardMaterials;
    successCallback?: (response: TGetChecklistTaskOnboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetChecklistTaskOnboardSuccess = {
  type: EGetChecklistTaskOnboardAction.GET_CHECKLIST_TASK_ONBOARD_SUCCESS;
  payload: { response: TGetChecklistTaskOnboardResponse };
};

export type TGetChecklistTaskOnboardFailed = { type: EGetChecklistTaskOnboardAction.GET_CHECKLIST_TASK_ONBOARD_FAILED };

// FUNCTION

export const getChecklistTaskOnboardAction = {
  request: createActionCreator(
    EGetChecklistTaskOnboardAction.GET_CHECKLIST_TASK_ONBOARD_REQUEST,
    (resolve) =>
      (
        materials: TGetChecklistTaskOnboardMaterials,
        successCallback?: (response: TGetChecklistTaskOnboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetChecklistTaskOnboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetChecklistTaskOnboardAction.GET_CHECKLIST_TASK_ONBOARD_SUCCESS,
    (resolve) =>
      (response: TGetChecklistTaskOnboardResponse): TGetChecklistTaskOnboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetChecklistTaskOnboardAction.GET_CHECKLIST_TASK_ONBOARD_FAILED,
    (resolve) =>
      (error: unknown): TGetChecklistTaskOnboardFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { copyLastYearHolidaysAction } from '@/redux/actions';
import { copyLastYearHolidays, TCopyLastYearHolidaysResponse } from '@/services/api';

// FUNCTION

export function* copyLastYearHolidaysSaga(action: ActionType<typeof copyLastYearHolidaysAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(copyLastYearHolidays, materials);
    const copyLastYearHolidaysResponse: TCopyLastYearHolidaysResponse = response as TCopyLastYearHolidaysResponse;
    yield put(copyLastYearHolidaysAction.success(copyLastYearHolidaysResponse));
    successCallback?.(copyLastYearHolidaysResponse);
  } catch (err) {
    yield put(copyLastYearHolidaysAction.failure(err));
    failedCallback?.(err);
  }
}

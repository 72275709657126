import { createActionCreator } from 'deox';

import { TGetJobsMaterials, TGetJobsResponse } from '@/services/api/job/get-jobs';

// CONSTANTS

export enum EGetJobsAction {
  GET_JOBS = 'GET_JOBS',
  GET_JOBS_REQUEST = 'GET_JOBS_REQUEST',
  GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS',
  GET_JOBS_FAILED = 'GET_JOBS_FAILED',
}

// TYPES

export type TGetJobsRequest = {
  type: EGetJobsAction.GET_JOBS_REQUEST;
  payload: {
    materials: TGetJobsMaterials;
    successCallback?: (response: TGetJobsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetJobsSuccess = {
  type: EGetJobsAction.GET_JOBS_SUCCESS;
  payload: { response: TGetJobsResponse };
};

export type TGetJobsFailed = { type: EGetJobsAction.GET_JOBS_FAILED };

// FUNCTION

export const getJobsAction = {
  request: createActionCreator(
    EGetJobsAction.GET_JOBS_REQUEST,
    (resolve) =>
      (
        materials: TGetJobsMaterials,
        successCallback?: (response: TGetJobsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetJobsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetJobsAction.GET_JOBS_SUCCESS,
    (resolve) =>
      (response: TGetJobsResponse): TGetJobsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetJobsAction.GET_JOBS_FAILED,
    (resolve) =>
      (error: unknown): TGetJobsFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TResignRequestMaterials, TResignRequestResponse } from '@/services/api/request/resign-request';

// CONSTANTS

export enum EResignRequestAction {
  RESIGN_REQUEST = 'RESIGN_REQUEST',
  RESIGN_REQUEST_REQUEST = 'RESIGN_REQUEST_REQUEST',
  RESIGN_REQUEST_SUCCESS = 'RESIGN_REQUEST_SUCCESS',
  RESIGN_REQUEST_FAILED = 'RESIGN_REQUEST_FAILED',
}

// TYPES

export type TResignRequestRequest = {
  type: EResignRequestAction.RESIGN_REQUEST_REQUEST;
  payload: {
    materials: TResignRequestMaterials;
    successCallback?: (response: TResignRequestResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TResignRequestSuccess = {
  type: EResignRequestAction.RESIGN_REQUEST_SUCCESS;
  payload: { response: TResignRequestResponse };
};

export type TResignRequestFailed = { type: EResignRequestAction.RESIGN_REQUEST_FAILED };

// FUNCTION

export const resignRequestAction = {
  request: createActionCreator(
    EResignRequestAction.RESIGN_REQUEST_REQUEST,
    (resolve) =>
      (
        materials: TResignRequestMaterials,
        successCallback?: (response: TResignRequestResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TResignRequestRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EResignRequestAction.RESIGN_REQUEST_SUCCESS,
    (resolve) =>
      (response: TResignRequestResponse): TResignRequestSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EResignRequestAction.RESIGN_REQUEST_FAILED,
    (resolve) =>
      (error: unknown): TResignRequestFailed =>
        resolve({ error }),
  ),
};

import { TJob } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetJobPaths = {
  id: string | number;
};
export type TGetJobParams = unknown;

export type TGetJobMaterials = {
  paths?: TGetJobPaths;
  params?: TGetJobParams;
};

export type TGetJobResponse = TJob;

// FUNCTION

export const getJob = async ({ paths, params }: TGetJobMaterials): Promise<TGetJobResponse> => {
  const response = await ApiService.get(`/job/${paths?.id}`, { params });
  return response.data;
};

import { TUserState } from '@/redux/reducers/user';
import { TGetUserCareerHistoriesSuccess } from '@/redux/actions/user';

export const getUserCareerHistoriesUpdateState = (
  state: TUserState,
  action: TGetUserCareerHistoriesSuccess,
): TUserState => ({
  ...state,
  getUserCareerHistoriesResponse: action.payload.response,
});

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getDepartmentsAction } from '@/redux/actions';
import { getDepartments, TGetDepartmentsResponse } from '@/services/api';

// FUNCTION

export function* getDepartmentsSaga(action: ActionType<typeof getDepartmentsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getDepartments, materials);
    const getDepartmentsResponse: TGetDepartmentsResponse = response as TGetDepartmentsResponse;
    yield put(getDepartmentsAction.success(getDepartmentsResponse));
    successCallback?.(getDepartmentsResponse);
  } catch (err) {
    yield put(getDepartmentsAction.failure(err));
    failedCallback?.(err);
  }
}

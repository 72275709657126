export enum EKeySettingsNaviagion {
  GENERAL = 'GENERAL',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',

  COMPANY_INFORMATION = 'COMPANY_INFORMATION',
  APPROVALS = 'APPROVALS',
  REQUESTS_APPROVERS = 'REQUESTS_APPROVERS',
  REQUESTS_CC_RECIPIENTS = 'REQUESTS_CC_RECIPIENTS',

  TIMEOFFS = 'TIMEOFFS',
  TIMEOFF_DETAIL = 'TIMEOFF_DETAIL',

  HOLIDAYS = 'HOLIDAYS',
  HOLIDAY_DETAIL = 'HOLIDAY_DETAIL',

  BENEFITS = 'BENEFITS',
  BENEFIT_DETAIL = 'BENEFIT_DETAIL',

  USERS = 'USERS',
  PERMISSIONS = 'PERMISSIONS',
  CHECKLISTS_TEMPLATES = 'CHECKLISTS_TEMPLATES',
  INTEGRATION = 'INTEGRATION',

  DEPARTMENT_DETAIL = 'DEPARTMENT_DETAIL',
  JOB_DETAIL = 'JOB_DETAIL',
  EMPLOYMENT_DETAIL = 'EMPLOYMENT_DETAIL',
  BRANCH_DETAIL = 'BRANCH_DETAIL',
}

import { createActionCreator } from 'deox';

import {
  TGetRequestManagementMaterials,
  TGetRequestManagementResponse,
} from '@/services/api/request/get-request-management';

// CONSTANTS

export enum EGetRequestManagementAction {
  GET_REQUEST_MANAGEMENT = 'GET_REQUEST_MANAGEMENT',
  GET_REQUEST_MANAGEMENT_REQUEST = 'GET_REQUEST_MANAGEMENT_REQUEST',
  GET_REQUEST_MANAGEMENT_SUCCESS = 'GET_REQUEST_MANAGEMENT_SUCCESS',
  GET_REQUEST_MANAGEMENT_FAILED = 'GET_REQUEST_MANAGEMENT_FAILED',
}

// TYPES

export type TGetRequestManagementRequest = {
  type: EGetRequestManagementAction.GET_REQUEST_MANAGEMENT_REQUEST;
  payload: {
    materials: TGetRequestManagementMaterials;
    successCallback?: (response: TGetRequestManagementResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRequestManagementSuccess = {
  type: EGetRequestManagementAction.GET_REQUEST_MANAGEMENT_SUCCESS;
  payload: { response: TGetRequestManagementResponse };
};

export type TGetRequestManagementFailed = { type: EGetRequestManagementAction.GET_REQUEST_MANAGEMENT_FAILED };

// FUNCTION

export const getRequestManagementAction = {
  request: createActionCreator(
    EGetRequestManagementAction.GET_REQUEST_MANAGEMENT_REQUEST,
    (resolve) =>
      (
        materials: TGetRequestManagementMaterials,
        successCallback?: (response: TGetRequestManagementResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRequestManagementRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRequestManagementAction.GET_REQUEST_MANAGEMENT_SUCCESS,
    (resolve) =>
      (response: TGetRequestManagementResponse): TGetRequestManagementSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRequestManagementAction.GET_REQUEST_MANAGEMENT_FAILED,
    (resolve) =>
      (error: unknown): TGetRequestManagementFailed =>
        resolve({ error }),
  ),
};

import { THeadcountAndStaffTurnOver, TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportHeadcountParams = {
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  sort?: string;
};

export type TGetReportHeadcountMaterials = {
  params?: TGetReportHeadcountParams;
};

export type TGetReportHeadcountResponse = {
  chart: THeadcountAndStaffTurnOver[];
  totalEmploys: number;
  growth: number;
  growthEmploys: number;
  recentYears: THeadcountAndStaffTurnOver[];
  data: TPaginateResponse & {
    data: TUser[];
  };
};

// FUNCTION

export const getReportHeadcount = async ({
  params,
}: TGetReportHeadcountMaterials): Promise<TGetReportHeadcountResponse> => {
  const response = await ApiService.get(`/report/headcount`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TRequestBorrowAssetMaterials, TRequestBorrowAssetResponse } from '@/services/api/request/request-borrow-asset';

// CONSTANTS

export enum ERequestBorrowAssetAction {
  REQUEST_BORROW_ASSET = 'REQUEST_BORROW_ASSET',
  REQUEST_BORROW_ASSET_REQUEST = 'REQUEST_BORROW_ASSET_REQUEST',
  REQUEST_BORROW_ASSET_SUCCESS = 'REQUEST_BORROW_ASSET_SUCCESS',
  REQUEST_BORROW_ASSET_FAILED = 'REQUEST_BORROW_ASSET_FAILED',
}

// TYPES

export type TRequestBorrowAssetRequest = {
  type: ERequestBorrowAssetAction.REQUEST_BORROW_ASSET_REQUEST;
  payload: {
    materials: TRequestBorrowAssetMaterials;
    successCallback?: (response: TRequestBorrowAssetResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRequestBorrowAssetSuccess = {
  type: ERequestBorrowAssetAction.REQUEST_BORROW_ASSET_SUCCESS;
  payload: { response: TRequestBorrowAssetResponse };
};

export type TRequestBorrowAssetFailed = { type: ERequestBorrowAssetAction.REQUEST_BORROW_ASSET_FAILED };

// FUNCTION

export const requestBorrowAssetAction = {
  request: createActionCreator(
    ERequestBorrowAssetAction.REQUEST_BORROW_ASSET_REQUEST,
    (resolve) =>
      (
        materials: TRequestBorrowAssetMaterials,
        successCallback?: (response: TRequestBorrowAssetResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRequestBorrowAssetRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERequestBorrowAssetAction.REQUEST_BORROW_ASSET_SUCCESS,
    (resolve) =>
      (response: TRequestBorrowAssetResponse): TRequestBorrowAssetSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERequestBorrowAssetAction.REQUEST_BORROW_ASSET_FAILED,
    (resolve) =>
      (error: unknown): TRequestBorrowAssetFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TGetDepartmentMaterials, TGetDepartmentResponse } from '@/services/api/department/get-department';

// CONSTANTS

export enum EGetDepartmentAction {
  GET_DEPARTMENT = 'GET_DEPARTMENT',
  GET_DEPARTMENT_REQUEST = 'GET_DEPARTMENT_REQUEST',
  GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS',
  GET_DEPARTMENT_FAILED = 'GET_DEPARTMENT_FAILED',
}

// TYPES

export type TGetDepartmentRequest = {
  type: EGetDepartmentAction.GET_DEPARTMENT_REQUEST;
  payload: {
    materials: TGetDepartmentMaterials;
    successCallback?: (response: TGetDepartmentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetDepartmentSuccess = {
  type: EGetDepartmentAction.GET_DEPARTMENT_SUCCESS;
  payload: { response?: TGetDepartmentResponse };
};

export type TGetDepartmentFailed = { type: EGetDepartmentAction.GET_DEPARTMENT_FAILED };

// FUNCTION

export const getDepartmentAction = {
  request: createActionCreator(
    EGetDepartmentAction.GET_DEPARTMENT_REQUEST,
    (resolve) =>
      (
        materials: TGetDepartmentMaterials,
        successCallback?: (response: TGetDepartmentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetDepartmentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetDepartmentAction.GET_DEPARTMENT_SUCCESS,
    (resolve) =>
      (response?: TGetDepartmentResponse): TGetDepartmentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetDepartmentAction.GET_DEPARTMENT_FAILED,
    (resolve) =>
      (error: unknown): TGetDepartmentFailed =>
        resolve({ error }),
  ),
};

/* eslint-disable react/require-default-props */
import React, { lazy, Suspense } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';

import Helpers from '@/services/helpers';

const retryLoadComponent = (fn: () => Promise<unknown>, retriesLeft = 5, interval = 1000): any =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retryLoadComponent(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const Home = lazy(() => retryLoadComponent(() => import('@/pages/Home')));
const Login = lazy(() => retryLoadComponent(() => import('@/pages/Login')));
const RedirectLogin = lazy(() => retryLoadComponent(() => import('@/pages/RedirectLogin')));

const Welcome = lazy(() => retryLoadComponent(() => import('@/pages/Welcome')));
const Onboarding = lazy(() => retryLoadComponent(() => import('@/pages/Onboarding')));
const OnboardingReviewStatus = lazy(() => retryLoadComponent(() => import('@/pages/OnboardingReviewStatus')));

const Dashboard = lazy(() => retryLoadComponent(() => import('@/pages/Dashboard')));
const CreateAccount = lazy(() => retryLoadComponent(() => import('@/pages/CreateAccount')));
const Peoples = lazy(() => retryLoadComponent(() => import('@/pages/Peoples')));
const PeopleDetail = lazy(() => retryLoadComponent(() => import('@/pages/PeopleDetail')));
const InvitePeople = lazy(() => retryLoadComponent(() => import('@/pages/InvitePeople')));
const OnboardingPeople = lazy(() => retryLoadComponent(() => import('@/pages/OnboardingPeople')));
const OffboardingPeople = lazy(() => retryLoadComponent(() => import('@/pages/OffboardingPeople')));
const SelfService = lazy(() => retryLoadComponent(() => import('@/pages/SelfService')));
const NewTimeOffRequest = lazy(() => retryLoadComponent(() => import('@/pages/NewTimeOffRequest')));
const DetailTimeOffRequest = lazy(() => retryLoadComponent(() => import('@/pages/DetailTimeOffRequest')));
const NewAssetRequest = lazy(() => retryLoadComponent(() => import('@/pages/NewAssetRequest')));
const RequestsManagement = lazy(() => retryLoadComponent(() => import('@/pages/RequestsManagement')));
const Report = lazy(() => retryLoadComponent(() => import('@/pages/Report')));
const Assets = lazy(() => retryLoadComponent(() => import('@/pages/Assets')));
const NewAsset = lazy(() => retryLoadComponent(() => import('@/pages/NewAsset')));
const EditAsset = lazy(() => retryLoadComponent(() => import('@/pages/EditAsset')));
const CompanyRegulations = lazy(() => retryLoadComponent(() => import('@/pages/CompanyRegulations')));
const NewRegulation = lazy(() => retryLoadComponent(() => import('@/pages/NewRegulation')));
const EditRegulation = lazy(() => retryLoadComponent(() => import('@/pages/EditRegulation')));
const Checklists = lazy(() => retryLoadComponent(() => import('@/pages/Checklists')));
const NewTask = lazy(() => retryLoadComponent(() => import('@/pages/NewTask')));
const EditTask = lazy(() => retryLoadComponent(() => import('@/pages/EditTask')));
const Settings = lazy(() => retryLoadComponent(() => import('@/pages/Settings')));
const CareerRequest = lazy(() => retryLoadComponent(() => import('@/pages/CareerRequest')));
const AccessDenied = lazy(() => retryLoadComponent(() => import('@/pages/AccessDenied')));
const Forbidden = lazy(() => retryLoadComponent(() => import('@/pages/Forbidden')));
const ReportOnboarding = lazy(() => retryLoadComponent(() => import('@/pages/ReportOnboarding')));
const ReportOffboarding = lazy(() => retryLoadComponent(() => import('@/pages/ReportOffboarding')));
const ReportTimeOffBalance = lazy(() => retryLoadComponent(() => import('@/pages/ReportTimeOffBalance')));
const ReportTimeOffSchedule = lazy(() => retryLoadComponent(() => import('@/pages/ReportTimeOffSchedule')));
const ReportMonthlyTimekeeping = lazy(() => retryLoadComponent(() => import('@/pages/ReportMonthlyTimekeeping')));
const ReportEmployeeData = lazy(() => retryLoadComponent(() => import('@/pages/ReportEmployeeData')));
const ReportEmployeeTurnover = lazy(() => retryLoadComponent(() => import('@/pages/ReportEmployeeTurnover')));
const ReportHeadcount = lazy(() => retryLoadComponent(() => import('@/pages/ReportHeadcount')));

export const LayoutPaths = {
  Guest: '/guest',
  Auth: '/auth',
  Welcome: '/welcome',
  Admin: '/',
};

export const ModulePaths = {
  Peoples: '/people',
  SelfService: '/self-service',
  RequestsManagement: '/requests',
  Assets: '/assets',
  CompanyRegulations: '/regulations',
  Checklists: '/checklists',
  Templates: '/templates',
  Settings: '/settings',
  Report: '/report',
};

export const Paths = {
  Home: '/',
  Login: '/',
  RedirectLogin: '/login-redirect',
  CreateAccount: '/create-account',

  Welcome: '/',
  Onboarding: '/onboarding',
  OnboardingReviewStatus: '/onboarding-status',

  Dashboard: '/',
  Peoples: ModulePaths.Peoples,
  PeopleDetail: (id?: string): string => `${ModulePaths.Peoples}/detail/${id || ':id'}`,
  InvitePeople: `${ModulePaths.Peoples}/invite`,
  OnboardingPeople: (id?: string): string => `${ModulePaths.Checklists}/assign-onboarding/${id || ':id'}`,
  EditOnboardingPeople: (id?: string): string => `${ModulePaths.Checklists}/assign-onboarding/edit/${id || ':id'}`,
  OffboardingPeople: (id?: string): string => `${ModulePaths.Checklists}/assign-offboarding/${id || ':id'}`,
  EditOffboardingPeople: (id?: string): string => `${ModulePaths.Checklists}/assign-offboarding/edit/${id || ':id'}`,
  SelfService: ModulePaths.SelfService,
  NewTimeOffRequest: `${ModulePaths.SelfService}/timeoff/new`,
  DetailTimeOffRequest: (id?: string): string => `${ModulePaths.SelfService}/timeoff/detail/${id || ':id'}`,
  NewBorrowAssetRequest: `${ModulePaths.SelfService}/assets/borrow-request`,
  NewReturnAssetRequest: `${ModulePaths.SelfService}/assets/return-request`,
  RequestsManagement: ModulePaths.RequestsManagement,
  Report: ModulePaths.Report,
  Assets: ModulePaths.Assets,
  NewAsset: `${ModulePaths.Assets}/new`,
  EditAsset: (id?: string): string => `${ModulePaths.Assets}/edit/${id || ':id'}`,
  CompanyRegulations: ModulePaths.CompanyRegulations,
  NewRegulation: `${ModulePaths.CompanyRegulations}/new`,
  EditRegulation: (id?: string): string => `${ModulePaths.CompanyRegulations}/edit/${id || ':id'}`,
  Checklists: ModulePaths.Checklists,
  NewTask: `${ModulePaths.Checklists}/task/new`,
  EditTask: (taskId?: string): string => `${ModulePaths.Checklists}/task/${taskId || ':taskId'}/edit`,
  NewRequestTask: (requestId?: string): string => `${ModulePaths.Checklists}/${requestId || ':requestId'}/task/new`,
  EditRequestTask: (requestId?: string, taskId?: string): string =>
    `${ModulePaths.Checklists}/${requestId || ':requestId'}/task/${taskId || ':taskId'}/edit`,
  NewTemplateTask: (templateId?: string): string => `${ModulePaths.Templates}/${templateId || ':templateId'}/task/new`,
  EditTemplateTask: (templateId?: string, taskId?: string): string =>
    `${ModulePaths.Templates}/${templateId || ':templateId'}/task/${taskId || ':taskId'}/edit`,
  Settings: ModulePaths.Settings,
  NewContractTerminationRequest: `${ModulePaths.SelfService}/career/contract-termination-request`,
  NewProbationTerminationRequest: `${ModulePaths.SelfService}/career/probation-termination-request`,
  NewResignRequest: `${ModulePaths.SelfService}/career/resign-request`,
  AccessDenied: '/access-denied',
  Forbidden: '/forbidden',
  ReportOnboarding: `${ModulePaths.Report}/onboarding`,
  ReportOffboarding: `${ModulePaths.Report}/offboarding`,
  ReportTimeOffBalance: `${ModulePaths.Report}/time-off-balance`,
  ReportTimeOffSchedule: `${ModulePaths.Report}/time-off-schedule`,
  ReportMonthlyTimekeeping: `${ModulePaths.Report}/monthly-timekeeping`,
  ReportEmployeeData: `${ModulePaths.Report}/employee-data`,
  ReportEmployeeTurnover: `${ModulePaths.Report}/employee-turnover-rate`,
  ReportHeadcount: `${ModulePaths.Report}/headcount`,

  Rest: '*',
};

export const Pages = {
  Home,
  Login,
  CreateAccount,
  RedirectLogin,

  Welcome,
  Onboarding,
  OnboardingReviewStatus,

  Dashboard,
  Peoples,
  PeopleDetail,
  InvitePeople,
  OnboardingPeople,
  OffboardingPeople,
  SelfService,
  NewTimeOffRequest,
  DetailTimeOffRequest,
  NewAssetRequest,
  RequestsManagement,
  Report,
  Assets,
  NewAsset,
  EditAsset,
  CompanyRegulations,
  NewRegulation,
  EditRegulation,
  Checklists,
  NewTask,
  EditTask,
  Settings,
  CareerRequest,
  AccessDenied,
  ReportOnboarding,
  ReportOffboarding,
  ReportTimeOffBalance,
  ReportTimeOffSchedule,
  ReportMonthlyTimekeeping,
  ReportEmployeeData,
  ReportEmployeeTurnover,
  ReportHeadcount,
  Forbidden,
};

interface IRouteProps extends RouteComponentProps {
  component: React.FC;
  edit?: boolean;
  request?: boolean;
  template?: boolean;
  contractTermination?: boolean;
  probationTermination?: boolean;
  resign?: boolean;
  requestAsset?: boolean;
  returnAsset?: boolean;
}

export const AuthRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Admin} />
  ) : (
    <Suspense fallback={<></>}>
      <Component {...rest} />
    </Suspense>
  );
};

export const ProtectedRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Suspense fallback={<></>}>
      <Component {...rest} />
    </Suspense>
  ) : (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Auth} />
  );
};

export const PublicRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => (
  <Suspense fallback={<></>}>
    <Component {...rest} />
  </Suspense>
);

import { TTimeOffTypeState } from '@/redux/reducers/time-off-type';
import { TPatchTimeOffsSuccess } from '@/redux/actions/time-off-type';

export const patchTimeOffsUpdateState = (
  state: TTimeOffTypeState,
  action: TPatchTimeOffsSuccess,
): TTimeOffTypeState => ({
  ...state,
  patchTimeOffsResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import { TUpdateRoleMaterials, TUpdateRoleResponse } from '@/services/api/role/update-role';

// CONSTANTS

export enum EUpdateRoleAction {
  UPDATE_ROLE = 'UPDATE_ROLE',
  UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST',
  UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED',
}

// TYPES

export type TUpdateRoleRequest = {
  type: EUpdateRoleAction.UPDATE_ROLE_REQUEST;
  payload: {
    materials: TUpdateRoleMaterials;
    successCallback?: (response: TUpdateRoleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateRoleSuccess = {
  type: EUpdateRoleAction.UPDATE_ROLE_SUCCESS;
  payload: { response: TUpdateRoleResponse };
};

export type TUpdateRoleFailed = { type: EUpdateRoleAction.UPDATE_ROLE_FAILED };

// FUNCTION

export const updateRoleAction = {
  request: createActionCreator(
    EUpdateRoleAction.UPDATE_ROLE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateRoleMaterials,
        successCallback?: (response: TUpdateRoleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateRoleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateRoleAction.UPDATE_ROLE_SUCCESS,
    (resolve) =>
      (response: TUpdateRoleResponse): TUpdateRoleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateRoleAction.UPDATE_ROLE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateRoleFailed =>
        resolve({ error }),
  ),
};

import { TReportState } from '@/redux/reducers/report';
import { TDownloadReportOnboardSuccess } from '@/redux/actions/report';

export const downloadReportOnboardUpdateState = (
  state: TReportState,
  action: TDownloadReportOnboardSuccess,
): TReportState => ({
  ...state,
  downloadReportOnboardResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import {
  TCreateAssetCategoryMaterials,
  TCreateAssetCategoryResponse,
} from '@/services/api/asset/create-asset-category';

// CONSTANTS

export enum ECreateAssetCategoryAction {
  CREATE_ASSET_CATEGORY = 'CREATE_ASSET_CATEGORY',
  CREATE_ASSET_CATEGORY_REQUEST = 'CREATE_ASSET_CATEGORY_REQUEST',
  CREATE_ASSET_CATEGORY_SUCCESS = 'CREATE_ASSET_CATEGORY_SUCCESS',
  CREATE_ASSET_CATEGORY_FAILED = 'CREATE_ASSET_CATEGORY_FAILED',
}

// TYPES

export type TCreateAssetCategoryRequest = {
  type: ECreateAssetCategoryAction.CREATE_ASSET_CATEGORY_REQUEST;
  payload: {
    materials: TCreateAssetCategoryMaterials;
    successCallback?: (response: TCreateAssetCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateAssetCategorySuccess = {
  type: ECreateAssetCategoryAction.CREATE_ASSET_CATEGORY_SUCCESS;
  payload: { response: TCreateAssetCategoryResponse };
};

export type TCreateAssetCategoryFailed = { type: ECreateAssetCategoryAction.CREATE_ASSET_CATEGORY_FAILED };

// FUNCTION

export const createAssetCategoryAction = {
  request: createActionCreator(
    ECreateAssetCategoryAction.CREATE_ASSET_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TCreateAssetCategoryMaterials,
        successCallback?: (response: TCreateAssetCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateAssetCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateAssetCategoryAction.CREATE_ASSET_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TCreateAssetCategoryResponse): TCreateAssetCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateAssetCategoryAction.CREATE_ASSET_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TCreateAssetCategoryFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTemplateAction } from '@/redux/actions';
import { getTemplate, TGetTemplateResponse } from '@/services/api';

// FUNCTION

export function* getTemplateSaga(action: ActionType<typeof getTemplateAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTemplate, materials);
    const getTemplateResponse: TGetTemplateResponse = response as TGetTemplateResponse;
    yield put(getTemplateAction.success(getTemplateResponse));
    successCallback?.(getTemplateResponse);
  } catch (err) {
    yield put(getTemplateAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TDownloadReportEmployeeDataParams = unknown;
export type TDownloadReportEmployeeDataBody = unknown;

export type TDownloadReportEmployeeDataMaterials = {
  params?: TDownloadReportEmployeeDataParams;
  body?: TDownloadReportEmployeeDataBody;
};

export type TDownloadReportEmployeeDataResponse = string;

// FUNCTION

export const downloadReportEmployeeData = async ({
  params,
  body,
}: TDownloadReportEmployeeDataMaterials): Promise<TDownloadReportEmployeeDataResponse> => {
  const response = await ApiService.post(`/report/employee-data-reports`, body, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getJobsAction } from '@/redux/actions';
import { getJobs, TGetJobsResponse } from '@/services/api';

// FUNCTION

export function* getJobsSaga(action: ActionType<typeof getJobsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getJobs, materials);
    const getJobsResponse: TGetJobsResponse = response as TGetJobsResponse;
    yield put(getJobsAction.success(getJobsResponse));
    successCallback?.(getJobsResponse);
  } catch (err) {
    yield put(getJobsAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TDeleteTimeOffTypePaths = {
  id: string | number;
};
export type TDeleteTimeOffTypeParams = unknown;

export type TDeleteTimeOffTypeMaterials = {
  paths?: TDeleteTimeOffTypePaths;
  params?: TDeleteTimeOffTypeParams;
};

export type TDeleteTimeOffTypeResponse = unknown;

// FUNCTION

export const deleteTimeOffType = async ({
  paths,
  params,
}: TDeleteTimeOffTypeMaterials): Promise<TDeleteTimeOffTypeResponse> => {
  const response = await ApiService.delete(`/time-off-type/${paths?.id}`, { params });
  return response.data;
};

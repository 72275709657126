import ApiService from '@/services/api';

// TYPES

export type TUpdateTimeOffTypePaths = {
  id: string | number;
};
export type TUpdateTimeOffTypeBody = unknown;

export type TUpdateTimeOffTypeMaterials = {
  paths?: TUpdateTimeOffTypePaths;
  body?: TUpdateTimeOffTypeBody;
};

export type TUpdateTimeOffTypeResponse = unknown;

// FUNCTION

export const updateTimeOffType = async ({
  paths,
  body,
}: TUpdateTimeOffTypeMaterials): Promise<TUpdateTimeOffTypeResponse> => {
  const response = await ApiService.put(`/time-off-type/${paths?.id}`, body);
  return response.data;
};

import { all, takeLatest } from 'redux-saga/effects';

import {
  changeStatusAssetAction,
  createAssetCategoryAction,
  createAssetAction,
  deleteAssetCategoryAction,
  deleteAssetAction,
  getAssetCategoriesAction,
  getAssetCategoryAction,
  getAssetAction,
  getAssetsAction,
  getMyAssetsHistoryAction,
  getMyAssetsAction,
  updateAssetCategoryAction,
  updateAssetAction,
} from '@/redux/actions';

import { changeStatusAssetSaga } from './change-status-asset';
import { createAssetCategorySaga } from './create-asset-category';
import { createAssetSaga } from './create-asset';
import { deleteAssetCategorySaga } from './delete-asset-category';
import { deleteAssetSaga } from './delete-asset';
import { getAssetCategoriesSaga } from './get-asset-categories';
import { getAssetCategorySaga } from './get-asset-category';
import { getAssetSaga } from './get-asset';
import { getAssetsSaga } from './get-assets';
import { getMyAssetsHistorySaga } from './get-my-assets-history';
import { getMyAssetsSaga } from './get-my-assets';
import { updateAssetCategorySaga } from './update-asset-category';
import { updateAssetSaga } from './update-asset';

export default function* root(): Generator {
  yield all([
    takeLatest(changeStatusAssetAction.request.type, changeStatusAssetSaga),
    takeLatest(createAssetCategoryAction.request.type, createAssetCategorySaga),
    takeLatest(createAssetAction.request.type, createAssetSaga),
    takeLatest(deleteAssetCategoryAction.request.type, deleteAssetCategorySaga),
    takeLatest(deleteAssetAction.request.type, deleteAssetSaga),
    takeLatest(getAssetCategoriesAction.request.type, getAssetCategoriesSaga),
    takeLatest(getAssetCategoryAction.request.type, getAssetCategorySaga),
    takeLatest(getAssetAction.request.type, getAssetSaga),
    takeLatest(getAssetsAction.request.type, getAssetsSaga),
    takeLatest(getMyAssetsHistoryAction.request.type, getMyAssetsHistorySaga),
    takeLatest(getMyAssetsAction.request.type, getMyAssetsSaga),
    takeLatest(updateAssetCategoryAction.request.type, updateAssetCategorySaga),
    takeLatest(updateAssetAction.request.type, updateAssetSaga),
  ]);
}

import ApiService from '@/services/api';

// TYPES

export type TDeleteTaskPaths = {
  id: string | number;
};
export type TDeleteTaskParams = unknown;

export type TDeleteTaskMaterials = {
  paths?: TDeleteTaskPaths;
  params?: TDeleteTaskParams;
};

export type TDeleteTaskResponse = unknown;

// FUNCTION

export const deleteTask = async ({ paths, params }: TDeleteTaskMaterials): Promise<TDeleteTaskResponse> => {
  const response = await ApiService.delete(`/template/task/${paths?.id}`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getBenefitAction } from '@/redux/actions';
import { getBenefit, TGetBenefitResponse } from '@/services/api';

// FUNCTION

export function* getBenefitSaga(action: ActionType<typeof getBenefitAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getBenefit, materials);
    const getBenefitResponse: TGetBenefitResponse = response as TGetBenefitResponse;
    yield put(getBenefitAction.success(getBenefitResponse));
    successCallback?.(getBenefitResponse);
  } catch (err) {
    yield put(getBenefitAction.failure(err));
    failedCallback?.(err);
  }
}

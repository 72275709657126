import { createActionCreator } from 'deox';

import { TGetCcMeRequestsMaterials, TGetCcMeRequestsResponse } from '@/services/api/request/get-cc-me-requests';

// CONSTANTS

export enum EGetCcMeRequestsAction {
  GET_CC_ME_REQUESTS = 'GET_CC_ME_REQUESTS',
  GET_CC_ME_REQUESTS_REQUEST = 'GET_CC_ME_REQUESTS_REQUEST',
  GET_CC_ME_REQUESTS_SUCCESS = 'GET_CC_ME_REQUESTS_SUCCESS',
  GET_CC_ME_REQUESTS_FAILED = 'GET_CC_ME_REQUESTS_FAILED',
}

// TYPES

export type TGetCcMeRequestsRequest = {
  type: EGetCcMeRequestsAction.GET_CC_ME_REQUESTS_REQUEST;
  payload: {
    materials: TGetCcMeRequestsMaterials;
    successCallback?: (response: TGetCcMeRequestsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCcMeRequestsSuccess = {
  type: EGetCcMeRequestsAction.GET_CC_ME_REQUESTS_SUCCESS;
  payload: { response: TGetCcMeRequestsResponse };
};

export type TGetCcMeRequestsFailed = { type: EGetCcMeRequestsAction.GET_CC_ME_REQUESTS_FAILED };

// FUNCTION

export const getCcMeRequestsAction = {
  request: createActionCreator(
    EGetCcMeRequestsAction.GET_CC_ME_REQUESTS_REQUEST,
    (resolve) =>
      (
        materials: TGetCcMeRequestsMaterials,
        successCallback?: (response: TGetCcMeRequestsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCcMeRequestsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCcMeRequestsAction.GET_CC_ME_REQUESTS_SUCCESS,
    (resolve) =>
      (response: TGetCcMeRequestsResponse): TGetCcMeRequestsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCcMeRequestsAction.GET_CC_ME_REQUESTS_FAILED,
    (resolve) =>
      (error: unknown): TGetCcMeRequestsFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { importUsersAction } from '@/redux/actions';
import { importUsers, TImportUsersResponse } from '@/services/api';

// FUNCTION

export function* importUsersSaga(action: ActionType<typeof importUsersAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(importUsers, materials);
    const importUsersResponse: TImportUsersResponse = response as TImportUsersResponse;
    yield put(importUsersAction.success(importUsersResponse));
    successCallback?.(importUsersResponse);
  } catch (err) {
    yield put(importUsersAction.failure(err));
    failedCallback?.(err);
  }
}

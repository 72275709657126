import ApiService from '@/services/api';

// TYPES

export type TCreateNewBackgroundSettingParams = unknown;
export type TCreateNewBackgroundSettingBody = unknown;

export type TCreateNewBackgroundSettingMaterials = {
  params?: TCreateNewBackgroundSettingParams;
  body?: TCreateNewBackgroundSettingBody;
};

export type TCreateNewBackgroundSettingResponse = unknown;

// FUNCTION

export const createNewBackgroundSetting = async ({
  params,
  body,
}: TCreateNewBackgroundSettingMaterials): Promise<TCreateNewBackgroundSettingResponse> => {
  const response = await ApiService.post(`/setting/new-background`, body, { params });
  return response.data;
};

import { createReducer } from 'deox';

import {
  TCreateTimeOffTypeResponse,
  TDeleteTimeOffTypeResponse,
  TGetTimeOffResponse,
  TGetTimeOffsResponse,
  TPatchTimeOffsResponse,
  TUpdateTimeOffTypeResponse,
} from '@/services/api/time-off-type';
import {
  createTimeOffTypeAction,
  deleteTimeOffTypeAction,
  getTimeOffAction,
  getTimeOffsAction,
  patchTimeOffsAction,
  updateTimeOffTypeAction,
} from '@/redux/actions';
import { createTimeOffTypeUpdateState } from './create-time-off-type';
import { deleteTimeOffTypeUpdateState } from './delete-time-off-type';
import { getTimeOffUpdateState } from './get-time-off';
import { getTimeOffsUpdateState } from './get-time-offs';
import { patchTimeOffsUpdateState } from './patch-time-offs';
import { updateTimeOffTypeUpdateState } from './update-time-off-type';

export type TTimeOffTypeState = {
  createTimeOffTypeResponse?: TCreateTimeOffTypeResponse;
  deleteTimeOffTypeResponse?: TDeleteTimeOffTypeResponse;
  getTimeOffResponse?: TGetTimeOffResponse;
  getTimeOffsResponse?: TGetTimeOffsResponse;
  patchTimeOffsResponse?: TPatchTimeOffsResponse;
  updateTimeOffTypeResponse?: TUpdateTimeOffTypeResponse;
};

const initialState: TTimeOffTypeState = {
  createTimeOffTypeResponse: undefined,
  deleteTimeOffTypeResponse: undefined,
  getTimeOffResponse: undefined,
  getTimeOffsResponse: undefined,
  patchTimeOffsResponse: undefined,
  updateTimeOffTypeResponse: undefined,
};

const TimeOffTypeReducer = createReducer(initialState, (handleAction) => [
  handleAction(createTimeOffTypeAction.success, createTimeOffTypeUpdateState),
  handleAction(deleteTimeOffTypeAction.success, deleteTimeOffTypeUpdateState),
  handleAction(getTimeOffAction.success, getTimeOffUpdateState),
  handleAction(getTimeOffsAction.success, getTimeOffsUpdateState),
  handleAction(patchTimeOffsAction.success, patchTimeOffsUpdateState),
  handleAction(updateTimeOffTypeAction.success, updateTimeOffTypeUpdateState),
]);

export default TimeOffTypeReducer;

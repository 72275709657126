import { TTimeOffType } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTimeOffPaths = {
  id: string | number;
};
export type TGetTimeOffParams = unknown;

export type TGetTimeOffMaterials = {
  paths?: TGetTimeOffPaths;
  params?: TGetTimeOffParams;
};

export type TGetTimeOffResponse = TTimeOffType;

// FUNCTION

export const getTimeOff = async ({ paths, params }: TGetTimeOffMaterials): Promise<TGetTimeOffResponse> => {
  const response = await ApiService.get(`/time-off-type/${paths?.id}`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TGetMyInfoMaterials, TGetMyInfoResponse } from '@/services/api/user/get-my-info';

// CONSTANTS

export enum EGetMyInfoAction {
  GET_MY_INFO = 'GET_MY_INFO',
  GET_MY_INFO_REQUEST = 'GET_MY_INFO_REQUEST',
  GET_MY_INFO_SUCCESS = 'GET_MY_INFO_SUCCESS',
  GET_MY_INFO_FAILED = 'GET_MY_INFO_FAILED',
}

// TYPES

export type TGetMyInfoRequest = {
  type: EGetMyInfoAction.GET_MY_INFO_REQUEST;
  payload: {
    materials: TGetMyInfoMaterials;
    successCallback?: (response: TGetMyInfoResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMyInfoSuccess = {
  type: EGetMyInfoAction.GET_MY_INFO_SUCCESS;
  payload: { response?: TGetMyInfoResponse };
};

export type TGetMyInfoFailed = { type: EGetMyInfoAction.GET_MY_INFO_FAILED };

// FUNCTION

export const getMyInfoAction = {
  request: createActionCreator(
    EGetMyInfoAction.GET_MY_INFO_REQUEST,
    (resolve) =>
      (
        materials: TGetMyInfoMaterials,
        successCallback?: (response: TGetMyInfoResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMyInfoRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMyInfoAction.GET_MY_INFO_SUCCESS,
    (resolve) =>
      (response?: TGetMyInfoResponse): TGetMyInfoSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMyInfoAction.GET_MY_INFO_FAILED,
    (resolve) =>
      (error: unknown): TGetMyInfoFailed =>
        resolve({ error }),
  ),
};

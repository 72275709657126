import { TRequest } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAssignMeRequestsParams = unknown;

export type TGetAssignMeRequestsMaterials = {
  params?: TGetAssignMeRequestsParams;
};

export type TGetAssignMeRequestsResponse = TPaginateResponse & {
  data: TRequest[];
  subTotal: { [key: string]: number };
  total: { [key: string]: number };
};

// FUNCTION

export const getAssignMeRequests = async ({
  params,
}: TGetAssignMeRequestsMaterials): Promise<TGetAssignMeRequestsResponse> => {
  const response = await ApiService.get(`/request/management/assign-me-requests`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TSendCelebrationPaths = {
  id: string | number;
};
export type TSendCelebrationParams = unknown;
export type TSendCelebrationBody = unknown;

export type TSendCelebrationMaterials = {
  paths?: TSendCelebrationPaths;
  params?: TSendCelebrationParams;
  body?: TSendCelebrationBody;
};

export type TSendCelebrationResponse = unknown;

// FUNCTION

export const sendCelebration = async ({
  paths,
  params,
  body,
}: TSendCelebrationMaterials): Promise<TSendCelebrationResponse> => {
  const response = await ApiService.post(`/dashboard/celebration/${paths?.id}`, body, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getDashboardReportAction } from '@/redux/actions';
import { getDashboardReport, TGetDashboardReportResponse } from '@/services/api';

// FUNCTION

export function* getDashboardReportSaga(action: ActionType<typeof getDashboardReportAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getDashboardReport, materials);
    const getDashboardReportResponse: TGetDashboardReportResponse = response as TGetDashboardReportResponse;
    yield put(getDashboardReportAction.success(getDashboardReportResponse));
    successCallback?.(getDashboardReportResponse);
  } catch (err) {
    yield put(getDashboardReportAction.failure(err));
    failedCallback?.(err);
  }
}

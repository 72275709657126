import ApiService from '@/services/api';

// TYPES

export type TWelcomeUserPaths = {
  id: string | number;
};
export type TWelcomeUserParams = unknown;
export type TWelcomeUserBody = unknown;

export type TWelcomeUserMaterials = {
  paths?: TWelcomeUserPaths;
  params?: TWelcomeUserParams;
  body?: TWelcomeUserBody;
};

export type TWelcomeUserResponse = unknown;

// FUNCTION

export const welcomeUser = async ({ paths, params, body }: TWelcomeUserMaterials): Promise<TWelcomeUserResponse> => {
  const response = await ApiService.post(`/user/welcome/${paths?.id}`, body, { params });
  return response.data;
};

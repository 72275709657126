import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.87402 10L7.77784 6.09618C7.80634 6.06582 7.84075 6.04162 7.87897 6.02508C7.91718 6.00853 7.95837 6 8.00001 6C8.04165 6 8.08285 6.00853 8.12106 6.02508C8.15927 6.04162 8.19369 6.06582 8.22218 6.09618L12.126 10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateMyInfoAction } from '@/redux/actions';
import { updateMyInfo, TUpdateMyInfoResponse } from '@/services/api';

// FUNCTION

export function* updateMyInfoSaga(action: ActionType<typeof updateMyInfoAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateMyInfo, materials);
    const updateMyInfoResponse: TUpdateMyInfoResponse = response as TUpdateMyInfoResponse;
    yield put(updateMyInfoAction.success(updateMyInfoResponse));
    successCallback?.(updateMyInfoResponse);
  } catch (err) {
    yield put(updateMyInfoAction.failure(err));
    failedCallback?.(err);
  }
}

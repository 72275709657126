import { createActionCreator } from 'deox';

import { TAddApproverMaterials, TAddApproverResponse } from '@/services/api/approver/add-approver';

// CONSTANTS

export enum EAddApproverAction {
  ADD_APPROVER = 'ADD_APPROVER',
  ADD_APPROVER_REQUEST = 'ADD_APPROVER_REQUEST',
  ADD_APPROVER_SUCCESS = 'ADD_APPROVER_SUCCESS',
  ADD_APPROVER_FAILED = 'ADD_APPROVER_FAILED',
}

// TYPES

export type TAddApproverRequest = {
  type: EAddApproverAction.ADD_APPROVER_REQUEST;
  payload: {
    materials: TAddApproverMaterials;
    successCallback?: (response: TAddApproverResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAddApproverSuccess = {
  type: EAddApproverAction.ADD_APPROVER_SUCCESS;
  payload: { response: TAddApproverResponse };
};

export type TAddApproverFailed = { type: EAddApproverAction.ADD_APPROVER_FAILED };

// FUNCTION

export const addApproverAction = {
  request: createActionCreator(
    EAddApproverAction.ADD_APPROVER_REQUEST,
    (resolve) =>
      (
        materials: TAddApproverMaterials,
        successCallback?: (response: TAddApproverResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAddApproverRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAddApproverAction.ADD_APPROVER_SUCCESS,
    (resolve) =>
      (response: TAddApproverResponse): TAddApproverSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAddApproverAction.ADD_APPROVER_FAILED,
    (resolve) =>
      (error: unknown): TAddApproverFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import {
  TContractTerminationRequestMaterials,
  TContractTerminationRequestResponse,
} from '@/services/api/request/contract-termination-request';

// CONSTANTS

export enum EContractTerminationRequestAction {
  CONTRACT_TERMINATION_REQUEST = 'CONTRACT_TERMINATION_REQUEST',
  CONTRACT_TERMINATION_REQUEST_REQUEST = 'CONTRACT_TERMINATION_REQUEST_REQUEST',
  CONTRACT_TERMINATION_REQUEST_SUCCESS = 'CONTRACT_TERMINATION_REQUEST_SUCCESS',
  CONTRACT_TERMINATION_REQUEST_FAILED = 'CONTRACT_TERMINATION_REQUEST_FAILED',
}

// TYPES

export type TContractTerminationRequestRequest = {
  type: EContractTerminationRequestAction.CONTRACT_TERMINATION_REQUEST_REQUEST;
  payload: {
    materials: TContractTerminationRequestMaterials;
    successCallback?: (response: TContractTerminationRequestResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TContractTerminationRequestSuccess = {
  type: EContractTerminationRequestAction.CONTRACT_TERMINATION_REQUEST_SUCCESS;
  payload: { response: TContractTerminationRequestResponse };
};

export type TContractTerminationRequestFailed = {
  type: EContractTerminationRequestAction.CONTRACT_TERMINATION_REQUEST_FAILED;
};

// FUNCTION

export const contractTerminationRequestAction = {
  request: createActionCreator(
    EContractTerminationRequestAction.CONTRACT_TERMINATION_REQUEST_REQUEST,
    (resolve) =>
      (
        materials: TContractTerminationRequestMaterials,
        successCallback?: (response: TContractTerminationRequestResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TContractTerminationRequestRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EContractTerminationRequestAction.CONTRACT_TERMINATION_REQUEST_SUCCESS,
    (resolve) =>
      (response: TContractTerminationRequestResponse): TContractTerminationRequestSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EContractTerminationRequestAction.CONTRACT_TERMINATION_REQUEST_FAILED,
    (resolve) =>
      (error: unknown): TContractTerminationRequestFailed =>
        resolve({ error }),
  ),
};

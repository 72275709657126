import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getChecklistTasksAction } from '@/redux/actions';
import { getChecklistTasks, TGetChecklistTasksResponse } from '@/services/api';

// FUNCTION

export function* getChecklistTasksSaga(action: ActionType<typeof getChecklistTasksAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getChecklistTasks, materials);
    const getChecklistTasksResponse: TGetChecklistTasksResponse = response as TGetChecklistTasksResponse;
    yield put(getChecklistTasksAction.success(getChecklistTasksResponse));
    successCallback?.(getChecklistTasksResponse);
  } catch (err) {
    yield put(getChecklistTasksAction.failure(err));
    failedCallback?.(err);
  }
}

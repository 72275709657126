import { TTimeOffTypeState } from '@/redux/reducers/time-off-type';
import { TCreateTimeOffTypeSuccess } from '@/redux/actions/time-off-type';

export const createTimeOffTypeUpdateState = (
  state: TTimeOffTypeState,
  action: TCreateTimeOffTypeSuccess,
): TTimeOffTypeState => ({
  ...state,
  createTimeOffTypeResponse: action.payload.response,
});

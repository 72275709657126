import { TChecklistState } from '@/redux/reducers/checklist';
import { TGetChecklistTaskOnboardSuccess } from '@/redux/actions/checklist';

export const getChecklistTaskOnboardUpdateState = (
  state: TChecklistState,
  action: TGetChecklistTaskOnboardSuccess,
): TChecklistState => ({
  ...state,
  getChecklistTaskOnboardResponse: action.payload.response,
});

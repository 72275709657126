import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.125 3.4375C3.125 2.57456 3.82456 1.875 4.6875 1.875H15.3125C16.1754 1.875 16.875 2.57456 16.875 3.4375V16.5625C16.875 17.4254 16.1754 18.125 15.3125 18.125H4.6875C3.82456 18.125 3.125 17.4254 3.125 16.5625V3.4375Z"
        stroke={color}
      />
      <path d="M6.875 6.26953H13.125" stroke={color} strokeLinecap="round" />
      <path d="M6.875 10.0195H13.125" stroke={color} strokeLinecap="round" />
      <path d="M6.875 13.7695H13.125" stroke={color} strokeLinecap="round" />
    </svg>
  );
};

export default Svg;

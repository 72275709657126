import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5576_23233)">
        <path d="M10.6621 14L21.6621 3" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M21.6622 3L15.1622 21C15.1183 21.0957 15.0479 21.1769 14.9592 21.2338C14.8706 21.2906 14.7675 21.3209 14.6622 21.3209C14.5569 21.3209 14.4538 21.2906 14.3651 21.2338C14.2765 21.1769 14.2061 21.0957 14.1622 21L10.6622 14L3.66218 10.5C3.56644 10.4561 3.48531 10.3857 3.42843 10.2971C3.37155 10.2084 3.34131 10.1053 3.34131 10C3.34131 9.89468 3.37155 9.79158 3.42843 9.70295C3.48531 9.61431 3.56644 9.54387 3.66218 9.5L21.6622 3Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5576_23233">
          <rect width="24" height="24" fill={color} transform="translate(0.662109)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg;

import { TMyAsset } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetMyAssetsHistoryParams = {
  page: number;
  pageSize: number;
  sort?: string;
  categories?: string;
};

export type TGetMyAssetsHistoryPaths = {
  id?: string | number;
};

export type TGetMyAssetsHistoryMaterials = {
  params?: TGetMyAssetsHistoryParams;
  paths?: TGetMyAssetsHistoryPaths;
};

export type TGetMyAssetsHistoryResponse = TPaginateResponse & {
  data: TMyAsset[];
};

// FUNCTION

export const getMyAssetsHistory = async ({
  params,
  paths,
}: TGetMyAssetsHistoryMaterials): Promise<TGetMyAssetsHistoryResponse> => {
  const response = await ApiService.get(`/asset/${paths?.id ? `asset-histories/${paths?.id}` : 'my-asset-histories'}`, {
    params,
  });
  return response.data;
};

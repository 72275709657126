import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createRoleAction } from '@/redux/actions';
import { createRole, TCreateRoleResponse } from '@/services/api';

// FUNCTION

export function* createRoleSaga(action: ActionType<typeof createRoleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createRole, materials);
    const createRoleResponse: TCreateRoleResponse = response as TCreateRoleResponse;
    yield put(createRoleAction.success(createRoleResponse));
    successCallback?.(createRoleResponse);
  } catch (err) {
    yield put(createRoleAction.failure(err));
    failedCallback?.(err);
  }
}

import { TRequest } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TChangeUserTypeStatusBody = unknown;

export type TChangeUserTypeStatusMaterials = {
  body?: TChangeUserTypeStatusBody;
};

export type TChangeUserTypeStatusResponse = TRequest;

// FUNCTION

export const changeUserTypeStatus = async ({
  body,
}: TChangeUserTypeStatusMaterials): Promise<TChangeUserTypeStatusResponse> => {
  const response = await ApiService.patch(`/user/change/type-status`, body);
  return response.data;
};

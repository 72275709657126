import { createActionCreator } from 'deox';

import {
  TDownloadReportEmployeeDataMaterials,
  TDownloadReportEmployeeDataResponse,
} from '@/services/api/report/download-report-employee-data';

// CONSTANTS

export enum EDownloadReportEmployeeDataAction {
  DOWNLOAD_REPORT_EMPLOYEE_DATA = 'DOWNLOAD_REPORT_EMPLOYEE_DATA',
  DOWNLOAD_REPORT_EMPLOYEE_DATA_REQUEST = 'DOWNLOAD_REPORT_EMPLOYEE_DATA_REQUEST',
  DOWNLOAD_REPORT_EMPLOYEE_DATA_SUCCESS = 'DOWNLOAD_REPORT_EMPLOYEE_DATA_SUCCESS',
  DOWNLOAD_REPORT_EMPLOYEE_DATA_FAILED = 'DOWNLOAD_REPORT_EMPLOYEE_DATA_FAILED',
}

// TYPES

export type TDownloadReportEmployeeDataRequest = {
  type: EDownloadReportEmployeeDataAction.DOWNLOAD_REPORT_EMPLOYEE_DATA_REQUEST;
  payload: {
    materials: TDownloadReportEmployeeDataMaterials;
    successCallback?: (response: TDownloadReportEmployeeDataResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadReportEmployeeDataSuccess = {
  type: EDownloadReportEmployeeDataAction.DOWNLOAD_REPORT_EMPLOYEE_DATA_SUCCESS;
  payload: { response: TDownloadReportEmployeeDataResponse };
};

export type TDownloadReportEmployeeDataFailed = {
  type: EDownloadReportEmployeeDataAction.DOWNLOAD_REPORT_EMPLOYEE_DATA_FAILED;
};

// FUNCTION

export const downloadReportEmployeeDataAction = {
  request: createActionCreator(
    EDownloadReportEmployeeDataAction.DOWNLOAD_REPORT_EMPLOYEE_DATA_REQUEST,
    (resolve) =>
      (
        materials: TDownloadReportEmployeeDataMaterials,
        successCallback?: (response: TDownloadReportEmployeeDataResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadReportEmployeeDataRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadReportEmployeeDataAction.DOWNLOAD_REPORT_EMPLOYEE_DATA_SUCCESS,
    (resolve) =>
      (response: TDownloadReportEmployeeDataResponse): TDownloadReportEmployeeDataSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadReportEmployeeDataAction.DOWNLOAD_REPORT_EMPLOYEE_DATA_FAILED,
    (resolve) =>
      (error: unknown): TDownloadReportEmployeeDataFailed =>
        resolve({ error }),
  ),
};

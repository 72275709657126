import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getBenefitsAction } from '@/redux/actions';
import { getBenefits, TGetBenefitsResponse } from '@/services/api';

// FUNCTION

export function* getBenefitsSaga(action: ActionType<typeof getBenefitsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getBenefits, materials);
    const getBenefitsResponse: TGetBenefitsResponse = response as TGetBenefitsResponse;
    yield put(getBenefitsAction.success(getBenefitsResponse));
    successCallback?.(getBenefitsResponse);
  } catch (err) {
    yield put(getBenefitsAction.failure(err));
    failedCallback?.(err);
  }
}

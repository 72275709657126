import { createActionCreator } from 'deox';

import { TGetRoleMembersMaterials, TGetRoleMembersResponse } from '@/services/api/role/get-role-members';

// CONSTANTS

export enum EGetRoleMembersAction {
  GET_ROLE_MEMBERS = 'GET_ROLE_MEMBERS',
  GET_ROLE_MEMBERS_REQUEST = 'GET_ROLE_MEMBERS_REQUEST',
  GET_ROLE_MEMBERS_SUCCESS = 'GET_ROLE_MEMBERS_SUCCESS',
  GET_ROLE_MEMBERS_FAILED = 'GET_ROLE_MEMBERS_FAILED',
}

// TYPES

export type TGetRoleMembersRequest = {
  type: EGetRoleMembersAction.GET_ROLE_MEMBERS_REQUEST;
  payload: {
    materials: TGetRoleMembersMaterials;
    successCallback?: (response: TGetRoleMembersResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRoleMembersSuccess = {
  type: EGetRoleMembersAction.GET_ROLE_MEMBERS_SUCCESS;
  payload: { response: TGetRoleMembersResponse };
};

export type TGetRoleMembersFailed = { type: EGetRoleMembersAction.GET_ROLE_MEMBERS_FAILED };

// FUNCTION

export const getRoleMembersAction = {
  request: createActionCreator(
    EGetRoleMembersAction.GET_ROLE_MEMBERS_REQUEST,
    (resolve) =>
      (
        materials: TGetRoleMembersMaterials,
        successCallback?: (response: TGetRoleMembersResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRoleMembersRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRoleMembersAction.GET_ROLE_MEMBERS_SUCCESS,
    (resolve) =>
      (response: TGetRoleMembersResponse): TGetRoleMembersSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRoleMembersAction.GET_ROLE_MEMBERS_FAILED,
    (resolve) =>
      (error: unknown): TGetRoleMembersFailed =>
        resolve({ error }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 4C2.27614 4 2.5 3.77614 2.5 3.5C2.5 3.22386 2.27614 3 2 3C1.72386 3 1.5 3.22386 1.5 3.5C1.5 3.77614 1.72386 4 2 4Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path d="M5.5 3.5H14.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
      <path
        d="M2 8.5C2.27614 8.5 2.5 8.27614 2.5 8C2.5 7.72386 2.27614 7.5 2 7.5C1.72386 7.5 1.5 7.72386 1.5 8C1.5 8.27614 1.72386 8.5 2 8.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path d="M5.5 8H14.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
      <path
        d="M2 13C2.27614 13 2.5 12.7761 2.5 12.5C2.5 12.2239 2.27614 12 2 12C1.72386 12 1.5 12.2239 1.5 12.5C1.5 12.7761 1.72386 13 2 13Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path d="M5.5 12.5H14.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
    </svg>
  );
};

export default Svg;

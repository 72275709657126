import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getMyInfoAction } from '@/redux/actions';
import { getMyInfo, TGetMyInfoResponse } from '@/services/api';

// FUNCTION

export function* getMyInfoSaga(action: ActionType<typeof getMyInfoAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getMyInfo, materials);
    const getMyInfoResponse: TGetMyInfoResponse = response as TGetMyInfoResponse;
    yield put(getMyInfoAction.success(getMyInfoResponse));
    successCallback?.(getMyInfoResponse);
  } catch (err) {
    yield put(getMyInfoAction.failure(err));
    failedCallback?.(err);
  }
}

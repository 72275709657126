import { TBenefitState } from '@/redux/reducers/benefit';
import { TChangeStatusBenefitSuccess } from '@/redux/actions/benefit';

export const changeStatusBenefitUpdateState = (
  state: TBenefitState,
  action: TChangeStatusBenefitSuccess,
): TBenefitState => ({
  ...state,
  changeStatusBenefitResponse: action.payload.response,
});

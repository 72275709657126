import ApiService from '@/services/api';

// TYPES

export type TUpdateCompanyBody = unknown;

export type TUpdateCompanyMaterials = {
  body?: TUpdateCompanyBody;
};

export type TUpdateCompanyResponse = unknown;

// FUNCTION

export const updateCompany = async ({ body }: TUpdateCompanyMaterials): Promise<TUpdateCompanyResponse> => {
  const response = await ApiService.patch(`/company`, body);
  return response.data;
};

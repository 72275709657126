import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { changeUserTypeStatusAction } from '@/redux/actions';
import { changeUserTypeStatus, TChangeUserTypeStatusResponse } from '@/services/api';

// FUNCTION

export function* changeUserTypeStatusSaga(action: ActionType<typeof changeUserTypeStatusAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(changeUserTypeStatus, materials);
    const changeUserTypeStatusResponse: TChangeUserTypeStatusResponse = response as TChangeUserTypeStatusResponse;
    yield put(changeUserTypeStatusAction.success(changeUserTypeStatusResponse));
    successCallback?.(changeUserTypeStatusResponse);
  } catch (err) {
    yield put(changeUserTypeStatusAction.failure(err));
    failedCallback?.(err);
  }
}

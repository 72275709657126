import { createReducer } from 'deox';

import {
  TChangeStatusAssetResponse,
  TCreateAssetCategoryResponse,
  TCreateAssetResponse,
  TDeleteAssetCategoryResponse,
  TDeleteAssetResponse,
  TGetAssetCategoriesResponse,
  TGetAssetCategoryResponse,
  TGetAssetResponse,
  TGetAssetsResponse,
  TGetMyAssetsHistoryResponse,
  TGetMyAssetsResponse,
  TUpdateAssetCategoryResponse,
  TUpdateAssetResponse,
} from '@/services/api/asset';
import {
  changeStatusAssetAction,
  createAssetCategoryAction,
  createAssetAction,
  deleteAssetCategoryAction,
  deleteAssetAction,
  getAssetCategoriesAction,
  getAssetCategoryAction,
  getAssetAction,
  getAssetsAction,
  getMyAssetsHistoryAction,
  getMyAssetsAction,
  updateAssetCategoryAction,
  updateAssetAction,
} from '@/redux/actions';
import { changeStatusAssetUpdateState } from './change-status-asset';
import { createAssetCategoryUpdateState } from './create-asset-category';
import { createAssetUpdateState } from './create-asset';
import { deleteAssetCategoryUpdateState } from './delete-asset-category';
import { deleteAssetUpdateState } from './delete-asset';
import { getAssetCategoriesUpdateState } from './get-asset-categories';
import { getAssetCategoryUpdateState } from './get-asset-category';
import { getAssetUpdateState } from './get-asset';
import { getAssetsUpdateState } from './get-assets';
import { getMyAssetsHistoryUpdateState } from './get-my-assets-history';
import { getMyAssetsUpdateState } from './get-my-assets';
import { updateAssetCategoryUpdateState } from './update-asset-category';
import { updateAssetUpdateState } from './update-asset';

export type TAssetState = {
  changeStatusAssetResponse?: TChangeStatusAssetResponse;
  createAssetCategoryResponse?: TCreateAssetCategoryResponse;
  createAssetResponse?: TCreateAssetResponse;
  deleteAssetCategoryResponse?: TDeleteAssetCategoryResponse;
  deleteAssetResponse?: TDeleteAssetResponse;
  getAssetCategoriesResponse?: TGetAssetCategoriesResponse;
  getAssetCategoryResponse?: TGetAssetCategoryResponse;
  getAssetResponse?: TGetAssetResponse;
  getAssetsResponse?: TGetAssetsResponse;
  getMyAssetsHistoryResponse?: TGetMyAssetsHistoryResponse;
  getMyAssetsResponse?: TGetMyAssetsResponse;
  updateAssetCategoryResponse?: TUpdateAssetCategoryResponse;
  updateAssetResponse?: TUpdateAssetResponse;
};

const initialState: TAssetState = {
  changeStatusAssetResponse: undefined,
  createAssetCategoryResponse: undefined,
  createAssetResponse: undefined,
  deleteAssetCategoryResponse: undefined,
  deleteAssetResponse: undefined,
  getAssetCategoriesResponse: undefined,
  getAssetCategoryResponse: undefined,
  getAssetResponse: undefined,
  getAssetsResponse: undefined,
  getMyAssetsHistoryResponse: undefined,
  getMyAssetsResponse: undefined,
  updateAssetCategoryResponse: undefined,
  updateAssetResponse: undefined,
};

const AssetReducer = createReducer(initialState, (handleAction) => [
  handleAction(changeStatusAssetAction.success, changeStatusAssetUpdateState),
  handleAction(createAssetCategoryAction.success, createAssetCategoryUpdateState),
  handleAction(createAssetAction.success, createAssetUpdateState),
  handleAction(deleteAssetCategoryAction.success, deleteAssetCategoryUpdateState),
  handleAction(deleteAssetAction.success, deleteAssetUpdateState),
  handleAction(getAssetCategoriesAction.success, getAssetCategoriesUpdateState),
  handleAction(getAssetCategoryAction.success, getAssetCategoryUpdateState),
  handleAction(getAssetAction.success, getAssetUpdateState),
  handleAction(getAssetsAction.success, getAssetsUpdateState),
  handleAction(getMyAssetsHistoryAction.success, getMyAssetsHistoryUpdateState),
  handleAction(getMyAssetsAction.success, getMyAssetsUpdateState),
  handleAction(updateAssetCategoryAction.success, updateAssetCategoryUpdateState),
  handleAction(updateAssetAction.success, updateAssetUpdateState),
]);

export default AssetReducer;

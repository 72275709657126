import { createActionCreator } from 'deox';

import {
  TGetAssignMeRequestsMaterials,
  TGetAssignMeRequestsResponse,
} from '@/services/api/request/get-assign-me-requests';

// CONSTANTS

export enum EGetAssignMeRequestsAction {
  GET_ASSIGN_ME_REQUESTS = 'GET_ASSIGN_ME_REQUESTS',
  GET_ASSIGN_ME_REQUESTS_REQUEST = 'GET_ASSIGN_ME_REQUESTS_REQUEST',
  GET_ASSIGN_ME_REQUESTS_SUCCESS = 'GET_ASSIGN_ME_REQUESTS_SUCCESS',
  GET_ASSIGN_ME_REQUESTS_FAILED = 'GET_ASSIGN_ME_REQUESTS_FAILED',
}

// TYPES

export type TGetAssignMeRequestsRequest = {
  type: EGetAssignMeRequestsAction.GET_ASSIGN_ME_REQUESTS_REQUEST;
  payload: {
    materials: TGetAssignMeRequestsMaterials;
    successCallback?: (response: TGetAssignMeRequestsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAssignMeRequestsSuccess = {
  type: EGetAssignMeRequestsAction.GET_ASSIGN_ME_REQUESTS_SUCCESS;
  payload: { response: TGetAssignMeRequestsResponse };
};

export type TGetAssignMeRequestsFailed = { type: EGetAssignMeRequestsAction.GET_ASSIGN_ME_REQUESTS_FAILED };

// FUNCTION

export const getAssignMeRequestsAction = {
  request: createActionCreator(
    EGetAssignMeRequestsAction.GET_ASSIGN_ME_REQUESTS_REQUEST,
    (resolve) =>
      (
        materials: TGetAssignMeRequestsMaterials,
        successCallback?: (response: TGetAssignMeRequestsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAssignMeRequestsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAssignMeRequestsAction.GET_ASSIGN_ME_REQUESTS_SUCCESS,
    (resolve) =>
      (response: TGetAssignMeRequestsResponse): TGetAssignMeRequestsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAssignMeRequestsAction.GET_ASSIGN_ME_REQUESTS_FAILED,
    (resolve) =>
      (error: unknown): TGetAssignMeRequestsFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TDeleteTaskCategoryPaths = {
  id: string | number;
};
export type TDeleteTaskCategoryParams = unknown;

export type TDeleteTaskCategoryMaterials = {
  paths?: TDeleteTaskCategoryPaths;
  params?: TDeleteTaskCategoryParams;
};

export type TDeleteTaskCategoryResponse = unknown;

// FUNCTION

export const deleteTaskCategory = async ({
  paths,
  params,
}: TDeleteTaskCategoryMaterials): Promise<TDeleteTaskCategoryResponse> => {
  const response = await ApiService.delete(`/template/task-category/${paths?.id}`, { params });
  return response.data;
};

import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';

import Loading from '@/components/Loading';

import { TWrapperLazyLoadProps } from './WrapperLazyLoad.types';
import './WrapperLazyLoad.scss';

const WrapperLazyLoad: React.FC<TWrapperLazyLoadProps> = ({
  children,
  loading,
  maxHeight,
  resetScrollToTop,
  resetScrollToBottom,
  // scrollValue = 0,
  onInit,
  onScroll,
  onEnd,
  onTop,
  onResetScrollSuccess,
}) => {
  const wrapperLazyLoadRef = useRef<HTMLDivElement>(null);
  const wrapperLazyLoadWrapperRef = useRef<HTMLDivElement>(null);

  const [isShowScroll, setIsShowScroll] = useState<boolean>(false);

  const onScrollEnd = (e: React.UIEvent<HTMLDivElement>): void => {
    onScroll?.(e);
    const element = e.currentTarget;
    const isScrollEndOfElment = Math.round(element.scrollTop + element.clientHeight) >= element.scrollHeight;

    if (element.scrollTop === 0) onTop?.();
    if (isScrollEndOfElment && !resetScrollToTop) onEnd?.();
  };

  useEffect(() => {
    if (resetScrollToTop && wrapperLazyLoadRef?.current) {
      wrapperLazyLoadRef?.current?.scrollTo(0, 0);
      onResetScrollSuccess?.();
    }
  }, [resetScrollToTop, onResetScrollSuccess]);

  useEffect(() => {
    if (resetScrollToBottom && wrapperLazyLoadRef?.current) {
      const domWrapperLazyLoad = wrapperLazyLoadRef?.current;
      if (domWrapperLazyLoad) {
        wrapperLazyLoadRef.current?.scrollTo(0, wrapperLazyLoadRef.current.scrollHeight);
      }
      onResetScrollSuccess?.();
    }
  }, [resetScrollToBottom, onResetScrollSuccess]);

  useEffect(() => {
    if (wrapperLazyLoadWrapperRef && maxHeight) {
      setIsShowScroll((wrapperLazyLoadWrapperRef?.current?.clientHeight || 0) > (maxHeight || 0));
    }
  }, [wrapperLazyLoadWrapperRef, maxHeight]);

  useEffect(() => {
    if (wrapperLazyLoadRef) onInit?.(wrapperLazyLoadRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperLazyLoadRef]);

  return (
    <div
      ref={wrapperLazyLoadRef}
      onScroll={onScrollEnd}
      className={classNames('WrapperLazyLoad', { 'show-scroll': isShowScroll })}
      style={{ maxHeight }}
    >
      <div className="WrapperLazyLoad-wrapper" ref={wrapperLazyLoadWrapperRef}>
        {children}
      </div>
      <div className={classNames('WrapperLazyLoad-loading', { show: loading })}>
        <Loading />
      </div>
    </div>
  );
};

export default WrapperLazyLoad;

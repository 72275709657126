import { createActionCreator } from 'deox';

import {
  TUpdateAssignOffboardMaterials,
  TUpdateAssignOffboardResponse,
} from '@/services/api/checklist/update-assign-offboard';

// CONSTANTS

export enum EUpdateAssignOffboardAction {
  UPDATE_ASSIGN_OFFBOARD = 'UPDATE_ASSIGN_OFFBOARD',
  UPDATE_ASSIGN_OFFBOARD_REQUEST = 'UPDATE_ASSIGN_OFFBOARD_REQUEST',
  UPDATE_ASSIGN_OFFBOARD_SUCCESS = 'UPDATE_ASSIGN_OFFBOARD_SUCCESS',
  UPDATE_ASSIGN_OFFBOARD_FAILED = 'UPDATE_ASSIGN_OFFBOARD_FAILED',
}

// TYPES

export type TUpdateAssignOffboardRequest = {
  type: EUpdateAssignOffboardAction.UPDATE_ASSIGN_OFFBOARD_REQUEST;
  payload: {
    materials: TUpdateAssignOffboardMaterials;
    successCallback?: (response: TUpdateAssignOffboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateAssignOffboardSuccess = {
  type: EUpdateAssignOffboardAction.UPDATE_ASSIGN_OFFBOARD_SUCCESS;
  payload: { response: TUpdateAssignOffboardResponse };
};

export type TUpdateAssignOffboardFailed = { type: EUpdateAssignOffboardAction.UPDATE_ASSIGN_OFFBOARD_FAILED };

// FUNCTION

export const updateAssignOffboardAction = {
  request: createActionCreator(
    EUpdateAssignOffboardAction.UPDATE_ASSIGN_OFFBOARD_REQUEST,
    (resolve) =>
      (
        materials: TUpdateAssignOffboardMaterials,
        successCallback?: (response: TUpdateAssignOffboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateAssignOffboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateAssignOffboardAction.UPDATE_ASSIGN_OFFBOARD_SUCCESS,
    (resolve) =>
      (response: TUpdateAssignOffboardResponse): TUpdateAssignOffboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateAssignOffboardAction.UPDATE_ASSIGN_OFFBOARD_FAILED,
    (resolve) =>
      (error: unknown): TUpdateAssignOffboardFailed =>
        resolve({ error }),
  ),
};

import { TRegulateCategoryState } from '@/redux/reducers/regulate-category';
import { TCreateRegulateCategorySuccess } from '@/redux/actions/regulate-category';

export const createRegulateCategoryUpdateState = (
  state: TRegulateCategoryState,
  action: TCreateRegulateCategorySuccess,
): TRegulateCategoryState => ({
  ...state,
  createRegulateCategoryResponse: action.payload.response,
});

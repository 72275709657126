import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.84525 4.875L10.4902 3.21C10.599 2.9281 10.7904 2.68562 11.0393 2.51432C11.2882 2.34302 11.5831 2.25089 11.8852 2.25H13.1152C13.4174 2.25089 13.7123 2.34302 13.9612 2.51432C14.2101 2.68562 14.4015 2.9281 14.5102 3.21L15.1552 4.875L17.3452 6.135L19.1152 5.865C19.41 5.825 19.7099 5.87351 19.977 6.00438C20.2441 6.13526 20.4663 6.34258 20.6152 6.6L21.2152 7.65C21.369 7.91152 21.4398 8.21352 21.4184 8.51613C21.397 8.81874 21.2843 9.10775 21.0952 9.345L20.0002 10.74V13.26L21.1252 14.655C21.3143 14.8923 21.427 15.1813 21.4484 15.4839C21.4698 15.7865 21.399 16.0885 21.2452 16.35L20.6452 17.4C20.4963 17.6574 20.2741 17.8647 20.007 17.9956C19.7399 18.1265 19.44 18.175 19.1452 18.135L17.3752 17.865L15.1852 19.125L14.5402 20.79C14.4315 21.0719 14.2401 21.3144 13.9912 21.4857C13.7423 21.657 13.4474 21.7491 13.1452 21.75H11.8852C11.5831 21.7491 11.2882 21.657 11.0393 21.4857C10.7904 21.3144 10.599 21.0719 10.4902 20.79L9.84525 19.125L7.65525 17.865L5.88525 18.135C5.59052 18.175 5.29056 18.1265 5.02347 17.9956C4.75638 17.8647 4.53423 17.6574 4.38525 17.4L3.78525 16.35C3.6315 16.0885 3.56066 15.7865 3.58209 15.4839C3.60351 15.1813 3.71619 14.8923 3.90525 14.655L5.00025 13.26V10.74L3.87525 9.345C3.68619 9.10775 3.57351 8.81874 3.55209 8.51613C3.53066 8.21352 3.6015 7.91152 3.75525 7.65L4.35525 6.6C4.50423 6.34258 4.72638 6.13526 4.99347 6.00438C5.26056 5.87351 5.56052 5.825 5.85525 5.865L7.62525 6.135L9.84525 4.875ZM9.50025 12C9.50025 12.5933 9.67619 13.1734 10.0058 13.6667C10.3355 14.1601 10.804 14.5446 11.3522 14.7716C11.9004 14.9987 12.5036 15.0581 13.0855 14.9424C13.6675 14.8266 14.202 14.5409 14.6216 14.1213C15.0411 13.7018 15.3268 13.1672 15.4426 12.5853C15.5584 12.0033 15.4989 11.4001 15.2719 10.8519C15.0448 10.3038 14.6603 9.83524 14.167 9.50559C13.6736 9.17595 13.0936 9 12.5002 9C11.7046 9 10.9415 9.31607 10.3789 9.87868C9.81632 10.4413 9.50025 11.2044 9.50025 12V12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import ApiService from '@/services/api';

// TYPES

export type TAdjustTimeOffBlancePaths = {
  id: string | number;
};
export type TAdjustTimeOffBlanceParams = unknown;
export type TAdjustTimeOffBlanceBody = unknown;

export type TAdjustTimeOffBlanceMaterials = {
  paths?: TAdjustTimeOffBlancePaths;
  params?: TAdjustTimeOffBlanceParams;
  body?: TAdjustTimeOffBlanceBody;
};

export type TAdjustTimeOffBlanceResponse = unknown;

// FUNCTION

export const adjustTimeOffBlance = async ({
  paths,
  params,
  body,
}: TAdjustTimeOffBlanceMaterials): Promise<TAdjustTimeOffBlanceResponse> => {
  const response = await ApiService.post(`/user/adjust-time-off-balance/${paths?.id}`, body, { params });
  return response.data;
};

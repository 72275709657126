import { all, takeLatest } from 'redux-saga/effects';

import {
  createTimeOffTypeAction,
  deleteTimeOffTypeAction,
  getTimeOffAction,
  getTimeOffsAction,
  patchTimeOffsAction,
  updateTimeOffTypeAction,
} from '@/redux/actions';

import { createTimeOffTypeSaga } from './create-time-off-type';
import { deleteTimeOffTypeSaga } from './delete-time-off-type';
import { getTimeOffSaga } from './get-time-off';
import { getTimeOffsSaga } from './get-time-offs';
import { patchTimeOffsSaga } from './patch-time-offs';
import { updateTimeOffTypeSaga } from './update-time-off-type';

export default function* root(): Generator {
  yield all([
    takeLatest(createTimeOffTypeAction.request.type, createTimeOffTypeSaga),
    takeLatest(deleteTimeOffTypeAction.request.type, deleteTimeOffTypeSaga),
    takeLatest(getTimeOffAction.request.type, getTimeOffSaga),
    takeLatest(getTimeOffsAction.request.type, getTimeOffsSaga),
    takeLatest(patchTimeOffsAction.request.type, patchTimeOffsSaga),
    takeLatest(updateTimeOffTypeAction.request.type, updateTimeOffTypeSaga),
  ]);
}

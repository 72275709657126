import ApiService from '@/services/api';

// TYPES

export type TCreateBenefitParams = unknown;
export type TCreateBenefitBody = unknown;

export type TCreateBenefitMaterials = {
  params?: TCreateBenefitParams;
  body?: TCreateBenefitBody;
};

export type TCreateBenefitResponse = unknown;

// FUNCTION

export const createBenefit = async ({ params, body }: TCreateBenefitMaterials): Promise<TCreateBenefitResponse> => {
  const response = await ApiService.post(`/benefit/new`, body, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TReinviteUserPaths = {
  id: string | number;
};
export type TReinviteUserParams = unknown;
export type TReinviteUserBody = unknown;

export type TReinviteUserMaterials = {
  paths?: TReinviteUserPaths;
  params?: TReinviteUserParams;
  body?: TReinviteUserBody;
};

export type TReinviteUserResponse = unknown;

// FUNCTION

export const reinviteUser = async ({ paths, params, body }: TReinviteUserMaterials): Promise<TReinviteUserResponse> => {
  const response = await ApiService.post(`/user/reinvite/${paths?.id}`, body, { params });
  return response.data;
};

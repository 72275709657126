import { TTemplate } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTemplatePaths = {
  id: string | number;
};
export type TGetTemplateParams = unknown;

export type TGetTemplateMaterials = {
  paths?: TGetTemplatePaths;
  params?: TGetTemplateParams;
};

export type TGetTemplateResponse = TTemplate;

// FUNCTION

export const getTemplate = async ({ paths, params }: TGetTemplateMaterials): Promise<TGetTemplateResponse> => {
  const response = await ApiService.get(`/template/${paths?.id}`, { params });
  return response.data;
};

import React, { useState } from 'react';
import { Avatar as AntdAvatar } from 'antd';
import classNames from 'classnames';
import Zoom from 'react-medium-image-zoom';

import ImageAvatarDefault from '@/assets/images/image-avatar-default.svg';

import { TAvatarProps } from './Avatar.types';
import './Avatar.scss';

const Avatar: React.FC<TAvatarProps> = ({
  showZoom = true,
  image,
  size,
  className,
  online,
  offline,
  shape = 'circle',
}) => {
  const [isError, setIsError] = useState(false);

  const avatarRenderer = (
    <AntdAvatar
      size={size}
      shape={shape}
      src={isError ? ImageAvatarDefault : image || ImageAvatarDefault}
      onError={(): boolean => {
        setIsError(true);
        return true;
      }}
    />
  );

  return (
    <div
      className={classNames('Avatar', className, shape, { 'show-zoom': showZoom })}
      style={{ width: size, height: size }}
    >
      {showZoom ? <Zoom classDialog="Zoom">{avatarRenderer}</Zoom> : avatarRenderer}

      {(online || offline) && <div className={classNames('Avatar-status', { online, offline })} />}
    </div>
  );
};

export default Avatar;

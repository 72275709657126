import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getNationalsAction } from '@/redux/actions';
import { getNationals, TGetNationalsResponse } from '@/services/api';

// FUNCTION

export function* getNationalsSaga(action: ActionType<typeof getNationalsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getNationals, materials);
    const getNationalsResponse: TGetNationalsResponse = response as TGetNationalsResponse;
    yield put(getNationalsAction.success(getNationalsResponse));
    successCallback?.(getNationalsResponse);
  } catch (err) {
    yield put(getNationalsAction.failure(err));
    failedCallback?.(err);
  }
}

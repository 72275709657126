import ApiService from '@/services/api';

// TYPES

export type TPatchTimeOffsPaths = {
  id: string | number;
};
export type TPatchTimeOffsBody = unknown;

export type TPatchTimeOffsMaterials = {
  paths?: TPatchTimeOffsPaths;
  body?: TPatchTimeOffsBody;
};

export type TPatchTimeOffsResponse = unknown;

// FUNCTION

export const patchTimeOffs = async ({ paths, body }: TPatchTimeOffsMaterials): Promise<TPatchTimeOffsResponse> => {
  const response = await ApiService.patch(`/time-off-type/${paths?.id}`, body);
  return response.data;
};

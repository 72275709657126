import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createBenefitCoverageAction } from '@/redux/actions';
import { createBenefitCoverage, TCreateBenefitCoverageResponse } from '@/services/api';

// FUNCTION

export function* createBenefitCoverageSaga(action: ActionType<typeof createBenefitCoverageAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createBenefitCoverage, materials);
    const createBenefitCoverageResponse: TCreateBenefitCoverageResponse = response as TCreateBenefitCoverageResponse;
    yield put(createBenefitCoverageAction.success(createBenefitCoverageResponse));
    successCallback?.(createBenefitCoverageResponse);
  } catch (err) {
    yield put(createBenefitCoverageAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TDownloadReportOffboardParams = unknown;
export type TDownloadReportOffboardBody = unknown;

export type TDownloadReportOffboardMaterials = {
  params?: TDownloadReportOffboardParams;
  body?: TDownloadReportOffboardBody;
};

export type TDownloadReportOffboardResponse = string;

// FUNCTION

export const downloadReportOffboard = async ({
  params,
  body,
}: TDownloadReportOffboardMaterials): Promise<TDownloadReportOffboardResponse> => {
  const response = await ApiService.post(`/report/offboard`, body, { params });
  return response.data;
};

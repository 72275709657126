import ApiService from '@/services/api';

// TYPES

export type TDeleteAssetCategoryPaths = {
  id: string | number;
};
export type TDeleteAssetCategoryParams = unknown;

export type TDeleteAssetCategoryMaterials = {
  paths?: TDeleteAssetCategoryPaths;
  params?: TDeleteAssetCategoryParams;
};

export type TDeleteAssetCategoryResponse = unknown;

// FUNCTION

export const deleteAssetCategory = async ({
  paths,
  params,
}: TDeleteAssetCategoryMaterials): Promise<TDeleteAssetCategoryResponse> => {
  const response = await ApiService.delete(`/asset/category/${paths?.id}`, { params });
  return response.data;
};

import { TTimeOffType } from '@/common/models';
import ApiService from '@/services/api';
import { TPaginateResponse } from '@/common/types';

// TYPES
export type TGetTimeOffTypeParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
};
export type TGetTimeOffsParams = unknown;

export type TGetTimeOffsMaterials = {
  params?: TGetTimeOffsParams;
  body?: TGetTimeOffsParams;
};

export type TGetTimeOffsResponse = TPaginateResponse & {
  data: TTimeOffType[];
  total: { [key: string]: string };
};

// FUNCTION

export const getTimeOffs = async ({ params }: TGetTimeOffsMaterials): Promise<TGetTimeOffsResponse> => {
  const response = await ApiService.get(`/time-off-type/list`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TGetTypeWorkMaterials, TGetTypeWorkResponse } from '@/services/api/type-work/get-type-work';

// CONSTANTS

export enum EGetTypeWorkAction {
  GET_TYPE_WORK = 'GET_TYPE_WORK',
  GET_TYPE_WORK_REQUEST = 'GET_TYPE_WORK_REQUEST',
  GET_TYPE_WORK_SUCCESS = 'GET_TYPE_WORK_SUCCESS',
  GET_TYPE_WORK_FAILED = 'GET_TYPE_WORK_FAILED',
}

// TYPES

export type TGetTypeWorkRequest = {
  type: EGetTypeWorkAction.GET_TYPE_WORK_REQUEST;
  payload: {
    materials: TGetTypeWorkMaterials;
    successCallback?: (response: TGetTypeWorkResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTypeWorkSuccess = {
  type: EGetTypeWorkAction.GET_TYPE_WORK_SUCCESS;
  payload: { response?: TGetTypeWorkResponse };
};

export type TGetTypeWorkFailed = { type: EGetTypeWorkAction.GET_TYPE_WORK_FAILED };

// FUNCTION

export const getTypeWorkAction = {
  request: createActionCreator(
    EGetTypeWorkAction.GET_TYPE_WORK_REQUEST,
    (resolve) =>
      (
        materials: TGetTypeWorkMaterials,
        successCallback?: (response: TGetTypeWorkResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTypeWorkRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTypeWorkAction.GET_TYPE_WORK_SUCCESS,
    (resolve) =>
      (response?: TGetTypeWorkResponse): TGetTypeWorkSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTypeWorkAction.GET_TYPE_WORK_FAILED,
    (resolve) =>
      (error: unknown): TGetTypeWorkFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getHolidaysAction } from '@/redux/actions';
import { getHolidays, TGetHolidaysResponse } from '@/services/api';

// FUNCTION

export function* getHolidaysSaga(action: ActionType<typeof getHolidaysAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getHolidays, materials);
    const getHolidaysResponse: TGetHolidaysResponse = response as TGetHolidaysResponse;
    yield put(getHolidaysAction.success(getHolidaysResponse));
    successCallback?.(getHolidaysResponse);
  } catch (err) {
    yield put(getHolidaysAction.failure(err));
    failedCallback?.(err);
  }
}

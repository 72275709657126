import React from 'react';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';

import Dragger from 'antd/lib/upload/Dragger';
import FormLabel from '@/components/FormLabel';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import { renderIconFileType } from '@/utils/functions';

import { TUploadDragDropProps } from './UploadDragDrop.types';
import './UploadDragDrop.scss';

const UploadDragDrop: React.FC<TUploadDragDropProps> = ({
  value = [],
  label,
  disabled,
  required,
  passed,
  accept,
  failed,
  multiple,
  onChange,
}) => {
  const isEmpty = value.length === 0;
  const { t } = useTranslation();

  const handleChange = (data: UploadChangeParam): void => {
    const files = data.fileList?.map((item) => item.originFileObj as File);
    onChange?.(files);
  };

  const dummyRequest = ({ onSuccess }: any): void => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <div className="UploadDragDrop">
      <FormLabel disabled={disabled} label={label} required={required} passed={passed} failed={failed} />

      {isEmpty ? (
        <Dragger
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          onChange={handleChange}
          fileList={[]}
          customRequest={dummyRequest}
        >
          <div className="UploadDragDrop-icon">
            <Icon name={EIconName.Upload} color={EIconColor.BLUE_500} />
          </div>
          <div className="UploadDragDrop-text">
            {t('UploadDragDrop_text')}
            <br />
            {accept && <>({accept})</>}
          </div>
        </Dragger>
      ) : (
        <div className="UploadDragDrop-preview">
          {value.map((item) => (
            <div className="UploadDragDrop-preview-item flex items-center">
              <Icon name={renderIconFileType(item.type)} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadDragDrop;

import React from 'react';
import { Col, Modal as AntdModal, Row } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TModalProps } from '@/components/Modal/Modal.types';
import Button, { EButtonStyleType } from '@/components/Button';
import Icon, { EIconColor, EIconName } from '@/components/Icon';

import './Modal.scss';

const Modal: React.FC<TModalProps> = ({
  visible,
  cancelButton,
  confirmButton,
  showFooter,
  centered,
  width,
  wrapClassName,
  className,
  closeable = true,
  children,
  title,
  hideSubmit,
  hideCancel,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <AntdModal
      footer={null}
      title={null}
      closable={false}
      visible={visible}
      width={width}
      centered={centered}
      onCancel={onClose}
      wrapClassName={classNames('Modal-wrapper', wrapClassName)}
      className={classNames('Modal', className)}
    >
      {closeable && (
        <div className="Modal-close" onClick={onClose}>
          <Icon name={EIconName.X} color={EIconColor.GRAYSCALE_900} />
        </div>
      )}

      {title && (
        <div className="Modal-header">
          <div className="Modal-title">{title}</div>
        </div>
      )}

      <div className="Modal-body">{children}</div>

      {showFooter && (
        <div className="Modal-footer">
          <Row gutter={[16, 16]} justify="end">
            {!hideCancel && (
              <Col>
                <Button
                  title={t('Common_cancel')}
                  styleType={EButtonStyleType.TEXT_BLACK}
                  onClick={onClose}
                  {...cancelButton}
                />
              </Col>
            )}

            {!hideSubmit && (
              <Col>
                <Button
                  title={t('Common_submit')}
                  styleType={EButtonStyleType.PRIMARY}
                  onClick={onSubmit}
                  {...confirmButton}
                />
              </Col>
            )}
          </Row>
        </div>
      )}
    </AntdModal>
  );
};

export default Modal;

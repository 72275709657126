import { createReducer } from 'deox';

import {
  TAddRoleMemberResponse,
  TCreateRoleResponse,
  TDeleteMemberRoleResponse,
  TDeleteRoleResponse,
  TGetRoleMembersResponse,
  TGetRoleResponse,
  TGetRolesResponse,
  TUpdateRolePermissionResponse,
  TUpdateRoleResponse,
} from '@/services/api/role';
import {
  addRoleMemberAction,
  createRoleAction,
  deleteMemberRoleAction,
  deleteRoleAction,
  getRoleMembersAction,
  getRoleAction,
  getRolesAction,
  updateRolePermissionAction,
  updateRoleAction,
} from '@/redux/actions';
import { addRoleMemberUpdateState } from './add-role-member';
import { createRoleUpdateState } from './create-role';
import { deleteMemberRoleUpdateState } from './delete-member-role';
import { deleteRoleUpdateState } from './delete-role';
import { getRoleMembersUpdateState } from './get-role-members';
import { getRoleUpdateState } from './get-role';
import { getRolesUpdateState } from './get-roles';
import { updateRolePermissionUpdateState } from './update-role-permission';
import { updateRoleUpdateState } from './update-role';

export type TRoleState = {
  addRoleMemberResponse?: TAddRoleMemberResponse;
  createRoleResponse?: TCreateRoleResponse;
  deleteMemberRoleResponse?: TDeleteMemberRoleResponse;
  deleteRoleResponse?: TDeleteRoleResponse;
  getRoleMembersResponse?: TGetRoleMembersResponse;
  getRoleResponse?: TGetRoleResponse;
  getRolesResponse?: TGetRolesResponse;
  updateRolePermissionResponse?: TUpdateRolePermissionResponse;
  updateRoleResponse?: TUpdateRoleResponse;
};

const initialState: TRoleState = {
  addRoleMemberResponse: undefined,
  createRoleResponse: undefined,
  deleteMemberRoleResponse: undefined,
  deleteRoleResponse: undefined,
  getRoleMembersResponse: undefined,
  getRoleResponse: undefined,
  getRolesResponse: undefined,
  updateRolePermissionResponse: undefined,
  updateRoleResponse: undefined,
};

const RoleReducer = createReducer(initialState, (handleAction) => [
  handleAction(addRoleMemberAction.success, addRoleMemberUpdateState),
  handleAction(createRoleAction.success, createRoleUpdateState),
  handleAction(deleteMemberRoleAction.success, deleteMemberRoleUpdateState),
  handleAction(deleteRoleAction.success, deleteRoleUpdateState),
  handleAction(getRoleMembersAction.success, getRoleMembersUpdateState),
  handleAction(getRoleAction.success, getRoleUpdateState),
  handleAction(getRolesAction.success, getRolesUpdateState),
  handleAction(updateRolePermissionAction.success, updateRolePermissionUpdateState),
  handleAction(updateRoleAction.success, updateRoleUpdateState),
]);

export default RoleReducer;

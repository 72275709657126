import { TUploadFile } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TDownloadRegulationPaths = {
  id: string | number;
};
export type TDownloadRegulationParams = unknown;
export type TDownloadRegulationBody = unknown;

export type TDownloadRegulationMaterials = {
  paths?: TDownloadRegulationPaths;
  params?: TDownloadRegulationParams;
  body?: TDownloadRegulationBody;
};

export type TDownloadRegulationResponse = TUploadFile[];

// FUNCTION

export const downloadRegulation = async ({
  paths,
  params,
  body,
}: TDownloadRegulationMaterials): Promise<TDownloadRegulationResponse> => {
  const response = await ApiService.post(`/regulation/${paths?.id}/download`, body, { params });
  return response.data;
};

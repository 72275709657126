import { TChecklistState } from '@/redux/reducers/checklist';
import { TGetChecklistTasksSuccess } from '@/redux/actions/checklist';

export const getChecklistTasksUpdateState = (
  state: TChecklistState,
  action: TGetChecklistTasksSuccess,
): TChecklistState => ({
  ...state,
  getChecklistTasksResponse: action.payload.response,
});

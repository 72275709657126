export enum EIconName {
  Plus = 'Plus',
  Trash = 'Trash',
  X = 'X',
  Search = 'Search',
  Inbox = 'Inbox',
  Setting = 'Setting',
  User = 'User',
  Lock = 'Lock',
  Logout = 'Logout',
  Star = 'Star',
  Expand = 'Expand',
  Responsive = 'Responsive',
  Document = 'Document',
  CheckCircle = 'CheckCircle',
  AngleLeft = 'AngleLeft',
  AngleRight = 'AngleRight',
  AngleUp = 'AngleUp',
  AngleDown = 'AngleDown',
  HandWaving = 'HandWaving',
  Gift = 'Gift',
  Briefcase = 'Briefcase',
  Todo = 'Todo',
  DotsThree = 'DotsThree',
  Violation = 'Violation',
  Mail = 'Mail',
  Phone = 'Phone',
  LinkedIn = 'LinkedIn',
  Skype = 'Skype',
  Facebook = 'Facebook',
  Filter = 'Filter',
  EmptyState = 'EmptyState',
  Airplane = 'Airplane',
  HyperLink = 'HyperLink',
  Confetti = 'Confetti',
  Annual = 'Annual',
  Paternity = 'Paternity',
  Wedding = 'Wedding',
  Funeral = 'Funeral',
  Sick = 'Sick',
  Covid = 'Covid',
  TimeClock = 'TimeClock',
  Check = 'Check',
  Calendar = 'Calendar',
  StarFill = 'StarFill',
  CheckCircleOutline = 'CheckCircleOutline',
  StopOutline = 'StopOutline',
  TimeHourGlass = 'TimeHourGlass',
  XCircleOutline = 'XCircleOutline',
  Bell = 'Bell',
  Users = 'Users',
  ChartPieSlice = 'ChartPieSlice',
  Download = 'Download',
  List = 'List',
  Grid = 'Grid',
  Flag = 'Flag',
  ArrowLeft = 'ArrowLeft',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Upload = 'Upload',
  Csv = 'Csv',
  Pdf = 'Pdf',
  Doc = 'Doc',
  Xls = 'Xls',
  Photo = 'Photo',
  SOS = 'SOS',
  Hashtag = 'Hashtag',
  Writing = 'Writing',
  Department = 'Department',
  MapPin = 'MapPin',
  Calculator = 'Calculator',
  Eye = 'Eye',
  EyeClosed = 'EyeClosed',
  Asset = 'Asset',
  QrCode = 'QrCode',
  Kanban = 'Kanban',
  Copy = 'Copy',
  PadLock = 'PadLock',
  QuestionCircle = 'QuestionCircle',
  Folder = 'Folder',
  FolderShare = 'FolderShare',
  FolderOutline = 'FolderOutline',
  FileOutline = 'FileOutline',
  Share = 'Share',
  ShapeTenure = 'ShapeTenure',
  Send = 'Send',
  Emoji = 'Emoji',
}

export enum EIconColor {
  BLACK = '#000',
  WHITE = '#fff',

  GRAYSCALE_50 = '#ECEFF1',
  GRAYSCALE_200 = '#B0BEC5',
  GRAYSCALE_300 = '#90A4AE',
  GRAYSCALE_400 = '#78909C',
  GRAYSCALE_500 = '#607D8B',
  GRAYSCALE_600 = '#546E7A',
  GRAYSCALE_700 = '#546E7A',
  GRAYSCALE_900 = '#263238',

  RED_100 = '#FCE3D5',
  RED_500 = '#D63031',
  RED_700 = '#9A182F',

  LIME_100 = '#E8FBD7',
  LIME_200 = '#CCF8B1',
  LIME_500 = '#4ABC36',
  LIME_800 = '#116D18',

  YELLOW_500 = '#FEB938',

  BLUE_100 = '#E4F8FD',
  BLUE_300 = '#68C8F6',
  BLUE_500 = '#0984E3',
  BLUE_600 = '#0666C3',
  BLUE_700 = '#044CA3',

  VIOLET_100 = '#E4DFFD',
  VIOLET_500 = '#6C5CE7',
  VIOLET_700 = '#392EA6',

  REMY = '#FDDFF8',
  MEDIUM_RED_VIOLET = '#A62E93',
}

import { TTimeOffTypeState } from '@/redux/reducers/time-off-type';
import { TUpdateTimeOffTypeSuccess } from '@/redux/actions/time-off-type';

export const updateTimeOffTypeUpdateState = (
  state: TTimeOffTypeState,
  action: TUpdateTimeOffTypeSuccess,
): TTimeOffTypeState => ({
  ...state,
  updateTimeOffTypeResponse: action.payload.response,
});

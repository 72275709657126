import ApiService from '@/services/api';

// TYPES

export type TUpdateAssetCategoryPaths = {
  id: string | number;
};
export type TUpdateAssetCategoryBody = unknown;

export type TUpdateAssetCategoryMaterials = {
  paths?: TUpdateAssetCategoryPaths;
  body?: TUpdateAssetCategoryBody;
};

export type TUpdateAssetCategoryResponse = unknown;

// FUNCTION

export const updateAssetCategory = async ({
  paths,
  body,
}: TUpdateAssetCategoryMaterials): Promise<TUpdateAssetCategoryResponse> => {
  const response = await ApiService.put(`/asset/category/${paths?.id}`, body);
  return response.data;
};

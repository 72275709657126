import { TRequest } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetMyRequestsParams = {
  page: number;
  pageSize: number;
  year?: string | number;
  typeReq?: string;
  status?: string | number;
};

export type TGetMyRequestsMaterials = {
  params?: TGetMyRequestsParams;
};

export type TGetMyRequestsResponse = TPaginateResponse & {
  data: TRequest[];
  subTotal: { [key: string]: any };
  total: { [key: string]: number };
};

// FUNCTION

export const getMyRequests = async ({ params }: TGetMyRequestsMaterials): Promise<TGetMyRequestsResponse> => {
  const response = await ApiService.get(`/request/management/my-requests`, { params });
  return response.data;
};

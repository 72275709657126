import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill={color} />
      <path
        d="M9.5 17.5508L12.23 21.0608C12.3221 21.1805 12.4402 21.2777 12.5752 21.3453C12.7103 21.4128 12.859 21.4489 13.01 21.4508C13.1586 21.4525 13.3057 21.4211 13.4406 21.3588C13.5756 21.2965 13.6949 21.205 13.79 21.0908L22.5 10.5508"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import React from 'react';
import classNames from 'classnames';

import { TIconProps } from './Icon.types';
import { EIconName } from './Icon.enums';
import './Icon.scss';

import Plus from './Plus';
import Trash from './Trash';
import X from './X';
import Search from './Search';
import Inbox from './Inbox';
import Setting from './Setting';
import User from './User';
import Lock from './Lock';
import Logout from './Logout';
import Star from './Star';
import Expand from './Expand';
import Responsive from './Responsive';
import Document from './Document';
import CheckCircle from './CheckCircle';
import AngleLeft from './AngleLeft';
import AngleRight from './AngleRight';
import AngleDown from './AngleDown';
import HandWaving from './HandWaving';
import Gift from './Gift';
import Briefcase from './Briefcase';
import Todo from './Todo';
import DotsThree from './DotsThree';
import Violation from './Violation';
import Mail from './Mail';
import Phone from './Phone';
import LinkedIn from './LinkedIn';
import Skype from './Skype';
import Facebook from './Facebook';
import Filter from './Filter';
import EmptyState from './EmptyState';
import Airplane from './Airplane';
import HyperLink from './HyperLink';
import Confetti from './Confetti';
import Annual from './Annual';
import Paternity from './Paternity';
import Wedding from './Wedding';
import Funeral from './Funeral';
import Sick from './Sick';
import Covid from './Covid';
import TimeClock from './TimeClock';
import Check from './Check';
import Calendar from './Calendar';
import StarFill from './StarFill';
import CheckCircleOutline from './CheckCircleOutline';
import StopOutline from './StopOutline';
import TimeHourGlass from './TimeHourGlass';
import XCircleOutline from './XCircleOutline';
import Bell from './Bell';
import Users from './Users';
import ChartPieSlice from './ChartPieSlice';
import Download from './Download';
import Grid from './Grid';
import List from './List';
import Flag from './Flag';
import ArrowLeft from './ArrowLeft';
import Upload from './Upload';
import Csv from './Csv';
import Pdf from './Pdf';
import Doc from './Doc';
import Xls from './Xls';
import Photo from './Photo';
import SOS from './SOS';
import Hashtag from './Hashtag';
import Writing from './Writing';
import Department from './Department';
import MapPin from './MapPin';
import AngleUp from './AngleUp';
import Calculator from './Calculator';
import Eye from './Eye';
import Asset from './Asset';
import QrCode from './QrCode';
import Kanban from './Kanban';
import EyeClosed from './EyeClosed';
import Copy from './Copy';
import PadLock from './PadLock';
import QuestionCircle from './QuestionCircle';
import Folder from './Folder';
import FolderShare from './FolderShare';
import FolderOutline from './FolderOutline';
import FileOutline from './FileOutline';
import Share from './Share';
import Send from './Send';
import ShapeTenure from './ShapeTenure';
import ArrowUp from './ArrowUp';
import ArrowDown from './ArrowDown';
import Emoji from './Emoji';

const Icon: React.FC<TIconProps> = ({ name, className, color, onClick }) => {
  const renderIcon = (): React.ReactElement => {
    switch (name) {
      case EIconName.Plus:
        return <Plus color={color} />;
      case EIconName.Trash:
        return <Trash color={color} />;
      case EIconName.X:
        return <X color={color} />;
      case EIconName.Search:
        return <Search color={color} />;
      case EIconName.Inbox:
        return <Inbox color={color} />;
      case EIconName.Setting:
        return <Setting color={color} />;
      case EIconName.User:
        return <User color={color} />;
      case EIconName.Lock:
        return <Lock color={color} />;
      case EIconName.Logout:
        return <Logout color={color} />;
      case EIconName.Star:
        return <Star color={color} />;
      case EIconName.Expand:
        return <Expand color={color} />;
      case EIconName.Responsive:
        return <Responsive color={color} />;
      case EIconName.Document:
        return <Document color={color} />;
      case EIconName.CheckCircle:
        return <CheckCircle color={color} />;
      case EIconName.AngleLeft:
        return <AngleLeft color={color} />;
      case EIconName.AngleRight:
        return <AngleRight color={color} />;
      case EIconName.AngleDown:
        return <AngleDown color={color} />;
      case EIconName.HandWaving:
        return <HandWaving color={color} />;
      case EIconName.Gift:
        return <Gift color={color} />;
      case EIconName.Briefcase:
        return <Briefcase color={color} />;
      case EIconName.Todo:
        return <Todo color={color} />;
      case EIconName.DotsThree:
        return <DotsThree color={color} />;
      case EIconName.Violation:
        return <Violation color={color} />;
      case EIconName.Mail:
        return <Mail color={color} />;
      case EIconName.Phone:
        return <Phone color={color} />;
      case EIconName.LinkedIn:
        return <LinkedIn color={color} />;
      case EIconName.Skype:
        return <Skype color={color} />;
      case EIconName.Facebook:
        return <Facebook color={color} />;
      case EIconName.Filter:
        return <Filter color={color} />;
      case EIconName.EmptyState:
        return <EmptyState color={color} />;
      case EIconName.Airplane:
        return <Airplane color={color} />;
      case EIconName.HyperLink:
        return <HyperLink color={color} />;
      case EIconName.Confetti:
        return <Confetti color={color} />;
      case EIconName.Annual:
        return <Annual color={color} />;
      case EIconName.Paternity:
        return <Paternity color={color} />;
      case EIconName.Wedding:
        return <Wedding color={color} />;
      case EIconName.Funeral:
        return <Funeral color={color} />;
      case EIconName.Sick:
        return <Sick color={color} />;
      case EIconName.Covid:
        return <Covid color={color} />;
      case EIconName.TimeClock:
        return <TimeClock color={color} />;
      case EIconName.Check:
        return <Check color={color} />;
      case EIconName.Calendar:
        return <Calendar color={color} />;
      case EIconName.StarFill:
        return <StarFill color={color} />;
      case EIconName.CheckCircleOutline:
        return <CheckCircleOutline color={color} />;
      case EIconName.StopOutline:
        return <StopOutline color={color} />;
      case EIconName.TimeHourGlass:
        return <TimeHourGlass color={color} />;
      case EIconName.XCircleOutline:
        return <XCircleOutline color={color} />;
      case EIconName.Bell:
        return <Bell color={color} />;
      case EIconName.Users:
        return <Users color={color} />;
      case EIconName.ChartPieSlice:
        return <ChartPieSlice color={color} />;
      case EIconName.Download:
        return <Download color={color} />;
      case EIconName.Grid:
        return <Grid color={color} />;
      case EIconName.List:
        return <List color={color} />;
      case EIconName.Flag:
        return <Flag color={color} />;
      case EIconName.ArrowLeft:
        return <ArrowLeft color={color} />;
      case EIconName.ArrowUp:
        return <ArrowUp color={color} />;
      case EIconName.ArrowDown:
        return <ArrowDown color={color} />;
      case EIconName.Upload:
        return <Upload color={color} />;
      case EIconName.Csv:
        return <Csv color={color} />;
      case EIconName.Pdf:
        return <Pdf color={color} />;
      case EIconName.Doc:
        return <Doc color={color} />;
      case EIconName.Xls:
        return <Xls color={color} />;
      case EIconName.Photo:
        return <Photo color={color} />;
      case EIconName.SOS:
        return <SOS color={color} />;
      case EIconName.Hashtag:
        return <Hashtag color={color} />;
      case EIconName.Writing:
        return <Writing color={color} />;
      case EIconName.Department:
        return <Department color={color} />;
      case EIconName.MapPin:
        return <MapPin color={color} />;
      case EIconName.AngleUp:
        return <AngleUp color={color} />;
      case EIconName.Calculator:
        return <Calculator color={color} />;
      case EIconName.Eye:
        return <Eye color={color} />;
      case EIconName.Asset:
        return <Asset color={color} />;
      case EIconName.QrCode:
        return <QrCode color={color} />;
      case EIconName.Kanban:
        return <Kanban color={color} />;
      case EIconName.EyeClosed:
        return <EyeClosed color={color} />;
      case EIconName.Copy:
        return <Copy color={color} />;
      case EIconName.PadLock:
        return <PadLock color={color} />;
      case EIconName.QuestionCircle:
        return <QuestionCircle color={color} />;
      case EIconName.Folder:
        return <Folder color={color} />;
      case EIconName.FolderShare:
        return <FolderShare color={color} />;
      case EIconName.FolderOutline:
        return <FolderOutline color={color} />;
      case EIconName.FileOutline:
        return <FileOutline color={color} />;
      case EIconName.Share:
        return <Share color={color} />;
      case EIconName.ShapeTenure:
        return <ShapeTenure color={color} />;
      case EIconName.Send:
        return <Send color={color} />;
      case EIconName.Emoji:
        return <Emoji color={color} />;

      default:
        return <></>;
    }
  };

  return (
    <div className={classNames('Icon', 'flex', 'justify-center', 'items-center', className)} onClick={onClick}>
      {renderIcon()}
    </div>
  );
};

export default Icon;

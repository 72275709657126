import ApiService from '@/services/api';

// TYPES

export type TUpdateGenerateEmployIdBody = unknown;

export type TUpdateGenerateEmployIdMaterials = {
  body?: TUpdateGenerateEmployIdBody;
};

export type TUpdateGenerateEmployIdResponse = unknown;

// FUNCTION

export const updateGenerateEmployId = async ({
  body,
}: TUpdateGenerateEmployIdMaterials): Promise<TUpdateGenerateEmployIdResponse> => {
  const response = await ApiService.patch(`/company/generate-employ-id`, body);
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TCreateAssetCategoryParams = unknown;
export type TCreateAssetCategoryBody = unknown;

export type TCreateAssetCategoryMaterials = {
  params?: TCreateAssetCategoryParams;
  body?: TCreateAssetCategoryBody;
};

export type TCreateAssetCategoryResponse = unknown;

// FUNCTION

export const createAssetCategory = async ({
  params,
  body,
}: TCreateAssetCategoryMaterials): Promise<TCreateAssetCategoryResponse> => {
  const response = await ApiService.post(`/asset/category/new`, body, { params });
  return response.data;
};

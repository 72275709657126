import { all, takeLatest } from 'redux-saga/effects';

import { getCompanyAction, updateCompanyAction, updateGenerateEmployIdAction } from '@/redux/actions';

import { getCompanySaga } from './get-company';
import { updateCompanySaga } from './update-company';
import { updateGenerateEmployIdSaga } from './update-generate-employ-id';

export default function* root(): Generator {
  yield all([
    takeLatest(getCompanyAction.request.type, getCompanySaga),
    takeLatest(updateCompanyAction.request.type, updateCompanySaga),
    takeLatest(updateGenerateEmployIdAction.request.type, updateGenerateEmployIdSaga),
  ]);
}

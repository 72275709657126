import { TReportState } from '@/redux/reducers/report';
import { TDownloadReportOffboardSuccess } from '@/redux/actions/report';

export const downloadReportOffboardUpdateState = (
  state: TReportState,
  action: TDownloadReportOffboardSuccess,
): TReportState => ({
  ...state,
  downloadReportOffboardResponse: action.payload.response,
});

import { TDashboardState } from '@/redux/reducers/dashboard';
import { TGetCelebrationsSuccess } from '@/redux/actions/dashboard';

export const getCelebrationsUpdateState = (
  state: TDashboardState,
  action: TGetCelebrationsSuccess,
): TDashboardState => ({
  ...state,
  getCelebrationsResponse: action.payload.response,
});

import { createReducer } from 'deox';

import {
  TCreateBranchResponse,
  TDeleteBranchResponse,
  TGetBranchResponse,
  TGetBranchesResponse,
  TUpdateBranchResponse,
} from '@/services/api/branch';
import {
  createBranchAction,
  deleteBranchAction,
  getBranchAction,
  getBranchesAction,
  updateBranchAction,
} from '@/redux/actions';
import { createBranchUpdateState } from './create-branch';
import { deleteBranchUpdateState } from './delete-branch';
import { getBranchUpdateState } from './get-branch';
import { getBranchesUpdateState } from './get-branches';
import { updateBranchUpdateState } from './update-branch';

export type TBranchState = {
  createBranchResponse?: TCreateBranchResponse;
  deleteBranchResponse?: TDeleteBranchResponse;
  getBranchResponse?: TGetBranchResponse;
  getBranchesResponse?: TGetBranchesResponse;
  updateBranchResponse?: TUpdateBranchResponse;
};

const initialState: TBranchState = {
  createBranchResponse: undefined,
  deleteBranchResponse: undefined,
  getBranchResponse: undefined,
  getBranchesResponse: undefined,
  updateBranchResponse: undefined,
};

const BranchReducer = createReducer(initialState, (handleAction) => [
  handleAction(createBranchAction.success, createBranchUpdateState),
  handleAction(deleteBranchAction.success, deleteBranchUpdateState),
  handleAction(getBranchAction.success, getBranchUpdateState),
  handleAction(getBranchesAction.success, getBranchesUpdateState),
  handleAction(updateBranchAction.success, updateBranchUpdateState),
]);

export default BranchReducer;

import { createActionCreator } from 'deox';

import { TUpdateJobMaterials, TUpdateJobResponse } from '@/services/api/job/update-job';

// CONSTANTS

export enum EUpdateJobAction {
  UPDATE_JOB = 'UPDATE_JOB',
  UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST',
  UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS',
  UPDATE_JOB_FAILED = 'UPDATE_JOB_FAILED',
}

// TYPES

export type TUpdateJobRequest = {
  type: EUpdateJobAction.UPDATE_JOB_REQUEST;
  payload: {
    materials: TUpdateJobMaterials;
    successCallback?: (response: TUpdateJobResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateJobSuccess = {
  type: EUpdateJobAction.UPDATE_JOB_SUCCESS;
  payload: { response: TUpdateJobResponse };
};

export type TUpdateJobFailed = { type: EUpdateJobAction.UPDATE_JOB_FAILED };

// FUNCTION

export const updateJobAction = {
  request: createActionCreator(
    EUpdateJobAction.UPDATE_JOB_REQUEST,
    (resolve) =>
      (
        materials: TUpdateJobMaterials,
        successCallback?: (response: TUpdateJobResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateJobRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateJobAction.UPDATE_JOB_SUCCESS,
    (resolve) =>
      (response: TUpdateJobResponse): TUpdateJobSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateJobAction.UPDATE_JOB_FAILED,
    (resolve) =>
      (error: unknown): TUpdateJobFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createRegulationAction } from '@/redux/actions';
import { createRegulation, TCreateRegulationResponse } from '@/services/api';

// FUNCTION

export function* createRegulationSaga(action: ActionType<typeof createRegulationAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createRegulation, materials);
    const createRegulationResponse: TCreateRegulationResponse = response as TCreateRegulationResponse;
    yield put(createRegulationAction.success(createRegulationResponse));
    successCallback?.(createRegulationResponse);
  } catch (err) {
    yield put(createRegulationAction.failure(err));
    failedCallback?.(err);
  }
}

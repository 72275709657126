import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateMyCareerAction } from '@/redux/actions';
import { updateMyCareer, TUpdateMyCareerResponse } from '@/services/api';

// FUNCTION

export function* updateMyCareerSaga(action: ActionType<typeof updateMyCareerAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateMyCareer, materials);
    const updateMyCareerResponse: TUpdateMyCareerResponse = response as TUpdateMyCareerResponse;
    yield put(updateMyCareerAction.success(updateMyCareerResponse));
    successCallback?.(updateMyCareerResponse);
  } catch (err) {
    yield put(updateMyCareerAction.failure(err));
    failedCallback?.(err);
  }
}

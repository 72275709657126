import { TAsset } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAssetsParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
  categories?: string;
  locations?: string;
  status?: string;
};

export type TGetAssetsMaterials = {
  params?: TGetAssetsParams;
};

export type TGetAssetsResponse = TPaginateResponse & {
  data: TAsset[];
  assetsStatus: { [key: string]: string };
};

// FUNCTION

export const getAssets = async ({ params }: TGetAssetsMaterials): Promise<TGetAssetsResponse> => {
  const response = await ApiService.get(`/asset/list`, { params });
  return response.data;
};

import React, { useEffect } from 'react';
import { Redirect, Router } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { AuthRoute, LayoutPaths, Pages, Paths, ProtectedRoute, PublicRoute } from '@/pages/routers';
import Guest from '@/layouts/Guest';
import Auth from '@/layouts/Auth';
import Admin from '@/layouts/Admin';
import i18n from '@/library/i18n';
import { uiActions } from '@/redux/actions';
import { TRootState } from '@/redux/reducers';
import Welcome from '@/layouts/Welcome';

import 'moment/locale/vi';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const language = useSelector((state: TRootState) => state.uiReducer.language);

  useEffect(() => {
    const updateSize = (): void => {
      dispatch(uiActions.setDevice(window.innerWidth));
    };
    window.addEventListener('resize', updateSize);
    return (): void => window.removeEventListener('resize', updateSize);
  }, [dispatch]);

  useEffect(() => {
    if (language) i18n.changeLanguage(language);
  }, [language]);

  return (
    <div className="App">
      <Router primary={false}>
        <PublicRoute path={Paths.AccessDenied} component={Pages.AccessDenied} />
        <PublicRoute path={Paths.Forbidden} component={Pages.Forbidden} />

        <Guest path={LayoutPaths.Guest}>
          <PublicRoute path={Paths.Home} component={Pages.Home} />
          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Guest}${Paths.Home}`} />
        </Guest>

        <Welcome path={LayoutPaths.Welcome}>
          <ProtectedRoute path={Paths.Welcome} component={Pages.Welcome} />
          <ProtectedRoute path={Paths.Onboarding} component={Pages.Onboarding} />
          <ProtectedRoute path={Paths.OnboardingReviewStatus} component={Pages.OnboardingReviewStatus} />
          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Welcome}${Paths.Welcome}`} />
        </Welcome>

        <Auth path={LayoutPaths.Auth}>
          <AuthRoute path={Paths.RedirectLogin} component={Pages.RedirectLogin} />
          <AuthRoute path={Paths.Login} component={Pages.Login} />
          <AuthRoute path={Paths.CreateAccount} component={Pages.CreateAccount} />
          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Auth}${Paths.Login}`} />
        </Auth>

        <Admin path={LayoutPaths.Admin}>
          <ProtectedRoute path={Paths.Dashboard} component={Pages.Dashboard} />
          <ProtectedRoute path={Paths.Peoples} component={Pages.Peoples} />
          <ProtectedRoute path={Paths.PeopleDetail()} component={Pages.PeopleDetail} />
          <ProtectedRoute path={Paths.InvitePeople} component={Pages.InvitePeople} />
          <ProtectedRoute path={Paths.OnboardingPeople()} component={Pages.OnboardingPeople} />
          <ProtectedRoute path={Paths.EditOnboardingPeople()} component={Pages.OnboardingPeople} edit />
          <ProtectedRoute path={Paths.OffboardingPeople()} component={Pages.OffboardingPeople} />
          <ProtectedRoute path={Paths.EditOffboardingPeople()} component={Pages.OffboardingPeople} edit />
          <ProtectedRoute path={Paths.SelfService} component={Pages.SelfService} />
          <ProtectedRoute path={Paths.Report} component={Pages.Report} />
          <ProtectedRoute path={Paths.NewTimeOffRequest} component={Pages.NewTimeOffRequest} />
          <ProtectedRoute path={Paths.DetailTimeOffRequest()} component={Pages.DetailTimeOffRequest} />
          <ProtectedRoute path={Paths.NewBorrowAssetRequest} component={Pages.NewAssetRequest} requestAsset />
          <ProtectedRoute path={Paths.NewReturnAssetRequest} component={Pages.NewAssetRequest} returnAsset />
          <ProtectedRoute path={Paths.RequestsManagement} component={Pages.RequestsManagement} />
          <ProtectedRoute path={Paths.Assets} component={Pages.Assets} />
          <ProtectedRoute path={Paths.NewAsset} component={Pages.NewAsset} />
          <ProtectedRoute path={Paths.EditAsset()} component={Pages.EditAsset} />
          <ProtectedRoute path={Paths.CompanyRegulations} component={Pages.CompanyRegulations} />
          <ProtectedRoute path={Paths.NewRegulation} component={Pages.NewRegulation} />
          <ProtectedRoute path={Paths.EditRegulation()} component={Pages.EditRegulation} />
          <ProtectedRoute path={Paths.Checklists} component={Pages.Checklists} />
          <ProtectedRoute path={Paths.NewTask} component={Pages.NewTask} />
          <ProtectedRoute path={Paths.EditTask()} component={Pages.EditTask} />
          <ProtectedRoute path={Paths.NewRequestTask()} component={Pages.NewTask} request />
          <ProtectedRoute path={Paths.EditRequestTask()} component={Pages.EditTask} request />
          <ProtectedRoute path={Paths.NewTemplateTask()} component={Pages.NewTask} template />
          <ProtectedRoute path={Paths.EditTemplateTask()} component={Pages.EditTask} template />
          <ProtectedRoute path={Paths.Settings} component={Pages.Settings} />
          <ProtectedRoute
            path={Paths.NewContractTerminationRequest}
            component={Pages.CareerRequest}
            contractTermination
          />
          <ProtectedRoute
            path={Paths.NewProbationTerminationRequest}
            component={Pages.CareerRequest}
            probationTermination
          />
          <ProtectedRoute path={Paths.NewResignRequest} component={Pages.CareerRequest} resign />
          <ProtectedRoute path={Paths.ReportOnboarding} component={Pages.ReportOnboarding} />
          <ProtectedRoute path={Paths.ReportOffboarding} component={Pages.ReportOffboarding} />
          <ProtectedRoute path={Paths.ReportTimeOffBalance} component={Pages.ReportTimeOffBalance} />
          <ProtectedRoute path={Paths.ReportTimeOffSchedule} component={Pages.ReportTimeOffSchedule} />
          <ProtectedRoute path={Paths.ReportMonthlyTimekeeping} component={Pages.ReportMonthlyTimekeeping} />
          <ProtectedRoute path={Paths.ReportEmployeeData} component={Pages.ReportEmployeeData} />
          <ProtectedRoute path={Paths.ReportEmployeeTurnover} component={Pages.ReportEmployeeTurnover} />
          <ProtectedRoute path={Paths.ReportHeadcount} component={Pages.ReportHeadcount} />

          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Admin}${Paths.Dashboard}`} />
        </Admin>
      </Router>
    </div>
  );
};

export default App;

import { TReportViolationState } from '@/redux/reducers/report-violation';
import { TGetReportViolationSuccess } from '@/redux/actions/report-violation';

export const getReportViolationUpdateState = (
  state: TReportViolationState,
  action: TGetReportViolationSuccess,
): TReportViolationState => ({
  ...state,
  getReportViolationResponse: action.payload.response,
});

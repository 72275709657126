import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getAssetCategoryAction } from '@/redux/actions';
import { getAssetCategory, TGetAssetCategoryResponse } from '@/services/api';

// FUNCTION

export function* getAssetCategorySaga(action: ActionType<typeof getAssetCategoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getAssetCategory, materials);
    const getAssetCategoryResponse: TGetAssetCategoryResponse = response as TGetAssetCategoryResponse;
    yield put(getAssetCategoryAction.success(getAssetCategoryResponse));
    successCallback?.(getAssetCategoryResponse);
  } catch (err) {
    yield put(getAssetCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

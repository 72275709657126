import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteRoleAction } from '@/redux/actions';
import { deleteRole, TDeleteRoleResponse } from '@/services/api';

// FUNCTION

export function* deleteRoleSaga(action: ActionType<typeof deleteRoleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteRole, materials);
    const deleteRoleResponse: TDeleteRoleResponse = response as TDeleteRoleResponse;
    yield put(deleteRoleAction.success(deleteRoleResponse));
    successCallback?.(deleteRoleResponse);
  } catch (err) {
    yield put(deleteRoleAction.failure(err));
    failedCallback?.(err);
  }
}

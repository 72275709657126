import { createActionCreator } from 'deox';

import { TGetBenefitsMaterials, TGetBenefitsResponse } from '@/services/api/benefit/get-benefits';

// CONSTANTS

export enum EGetBenefitsAction {
  GET_BENEFITS = 'GET_BENEFITS',
  GET_BENEFITS_REQUEST = 'GET_BENEFITS_REQUEST',
  GET_BENEFITS_SUCCESS = 'GET_BENEFITS_SUCCESS',
  GET_BENEFITS_FAILED = 'GET_BENEFITS_FAILED',
}

// TYPES

export type TGetBenefitsRequest = {
  type: EGetBenefitsAction.GET_BENEFITS_REQUEST;
  payload: {
    materials: TGetBenefitsMaterials;
    successCallback?: (response: TGetBenefitsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBenefitsSuccess = {
  type: EGetBenefitsAction.GET_BENEFITS_SUCCESS;
  payload: { response: TGetBenefitsResponse };
};

export type TGetBenefitsFailed = { type: EGetBenefitsAction.GET_BENEFITS_FAILED };

// FUNCTION

export const getBenefitsAction = {
  request: createActionCreator(
    EGetBenefitsAction.GET_BENEFITS_REQUEST,
    (resolve) =>
      (
        materials: TGetBenefitsMaterials,
        successCallback?: (response: TGetBenefitsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBenefitsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetBenefitsAction.GET_BENEFITS_SUCCESS,
    (resolve) =>
      (response: TGetBenefitsResponse): TGetBenefitsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetBenefitsAction.GET_BENEFITS_FAILED,
    (resolve) =>
      (error: unknown): TGetBenefitsFailed =>
        resolve({ error }),
  ),
};

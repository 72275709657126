import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';

const Svg: React.FC<TIconProps> = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9353 15.4115H3.06517C2.53919 15.4115 2.11279 14.9851 2.11279 14.4591V3.03051C2.11279 2.50452 2.53919 2.07812 3.06517 2.07812H9.4721L13.8877 6.49371V14.4591C13.8877 14.9851 13.4613 15.4115 12.9353 15.4115Z"
        fill="#36BC6C"
      />
      <path
        d="M13.8878 6.49371H10.4245C9.89856 6.49371 9.47217 6.06731 9.47217 5.54133V2.07812L13.8878 6.49371Z"
        fill="#1B8746"
      />
      <path
        d="M4.94442 12.4742C4.56654 12.4742 4.2419 12.3986 3.97052 12.2474C3.70256 12.0928 3.49645 11.8764 3.35217 11.5982C3.20788 11.3165 3.13574 10.985 3.13574 10.6036C3.13574 10.3185 3.17697 10.0626 3.25941 9.83586C3.34186 9.60569 3.46038 9.40988 3.61496 9.24842C3.76955 9.08353 3.95849 8.95814 4.18179 8.87226C4.40852 8.78294 4.66273 8.73828 4.94442 8.73828C5.13336 8.73828 5.31887 8.76233 5.50094 8.81042C5.68301 8.85852 5.84275 8.92722 5.98016 9.01654C6.0523 9.0612 6.1004 9.11616 6.12444 9.18143C6.14849 9.24327 6.15364 9.3051 6.1399 9.36694C6.12616 9.42534 6.09696 9.47515 6.0523 9.51637C6.01108 9.5576 5.95783 9.58164 5.89256 9.58851C5.83073 9.59195 5.76202 9.56962 5.68644 9.52153C5.57995 9.45282 5.46487 9.40301 5.3412 9.37209C5.21753 9.34117 5.09214 9.32572 4.96503 9.32572C4.72113 9.32572 4.51501 9.37553 4.34668 9.47515C4.17835 9.57477 4.05125 9.71906 3.96536 9.908C3.87948 10.0969 3.83654 10.3288 3.83654 10.6036C3.83654 10.875 3.87948 11.1069 3.96536 11.2993C4.05125 11.4917 4.17835 11.6377 4.34668 11.7373C4.51501 11.8369 4.72113 11.8867 4.96503 11.8867C5.09557 11.8867 5.2244 11.8713 5.3515 11.8403C5.47861 11.806 5.59884 11.7545 5.71221 11.6858C5.78778 11.6411 5.85477 11.6222 5.91317 11.6291C5.97501 11.6325 6.02654 11.6531 6.06776 11.6909C6.10898 11.7287 6.13647 11.7768 6.15021 11.8352C6.16395 11.8902 6.16051 11.9486 6.1399 12.0104C6.11929 12.0688 6.07807 12.1203 6.01623 12.165C5.87882 12.2646 5.71393 12.3419 5.52155 12.3969C5.33261 12.4484 5.14023 12.4742 4.94442 12.4742Z"
        fill="white"
      />
      <path
        d="M7.93332 12.4742C7.78217 12.4742 7.63102 12.4621 7.47986 12.4381C7.32871 12.4175 7.18615 12.3848 7.05217 12.3402C6.9182 12.2921 6.79796 12.2337 6.69147 12.165C6.62963 12.1238 6.58669 12.0739 6.56264 12.0155C6.5386 11.9571 6.53001 11.9005 6.53688 11.8455C6.54718 11.7871 6.56951 11.7373 6.60387 11.6961C6.64165 11.6514 6.68803 11.6239 6.743 11.6136C6.79796 11.6033 6.85808 11.6188 6.92335 11.66C7.0745 11.7493 7.23424 11.8146 7.40257 11.8558C7.5709 11.897 7.74782 11.9176 7.93332 11.9176C8.20471 11.9176 8.40224 11.873 8.52591 11.7837C8.64958 11.6909 8.71142 11.5724 8.71142 11.4281C8.71142 11.3079 8.66676 11.2134 8.57744 11.1447C8.49156 11.076 8.34212 11.0193 8.12913 10.9747L7.56231 10.8561C7.23596 10.7874 6.99205 10.6723 6.8306 10.5109C6.67257 10.346 6.59356 10.1296 6.59356 9.86162C6.59356 9.69329 6.62791 9.54042 6.69662 9.40301C6.76532 9.2656 6.86151 9.14708 6.98518 9.04746C7.11229 8.94783 7.26172 8.87226 7.43349 8.82073C7.60869 8.76576 7.80106 8.73828 8.01062 8.73828C8.21673 8.73828 8.41255 8.76405 8.59805 8.81558C8.78356 8.8671 8.95017 8.94268 9.09789 9.0423C9.15285 9.08009 9.18892 9.12647 9.2061 9.18143C9.22671 9.2364 9.23186 9.29136 9.22156 9.34633C9.21125 9.39786 9.1872 9.44252 9.14941 9.4803C9.11163 9.51809 9.06353 9.54042 9.00513 9.54729C8.95017 9.55416 8.88662 9.53699 8.81447 9.49576C8.68737 9.42362 8.55854 9.37209 8.428 9.34117C8.29746 9.31026 8.15662 9.2948 8.00546 9.2948C7.84744 9.2948 7.71175 9.31713 7.59838 9.36179C7.48502 9.40644 7.39742 9.47 7.33558 9.55244C7.27718 9.63146 7.24798 9.72593 7.24798 9.83586C7.24798 9.95953 7.28921 10.0591 7.37165 10.1347C7.4541 10.2069 7.59495 10.2635 7.79419 10.3048L8.35586 10.4233C8.69596 10.4954 8.94845 10.6088 9.11334 10.7634C9.28167 10.918 9.36584 11.1241 9.36584 11.3817C9.36584 11.5466 9.33148 11.6961 9.26278 11.83C9.19751 11.964 9.10132 12.0791 8.97421 12.1753C8.85054 12.2715 8.70111 12.3453 8.52591 12.3969C8.35071 12.4484 8.15318 12.4742 7.93332 12.4742Z"
        fill="white"
      />
      <path
        d="M11.3093 12.4639C11.2166 12.4639 11.1393 12.4432 11.0775 12.402C11.0191 12.3574 10.971 12.2921 10.9332 12.2062L9.58826 9.18659C9.55047 9.1007 9.54016 9.02513 9.55734 8.95986C9.57452 8.89459 9.61059 8.84306 9.66555 8.80527C9.72052 8.76748 9.78579 8.74859 9.86136 8.74859C9.96099 8.74859 10.0348 8.77092 10.0829 8.81558C10.1345 8.8568 10.1774 8.92035 10.2118 9.00623L11.433 11.8352H11.2114L12.4275 9.00108C12.4653 8.91863 12.51 8.8568 12.5615 8.81558C12.613 8.77092 12.6852 8.74859 12.7779 8.74859C12.8535 8.74859 12.9153 8.76748 12.9634 8.80527C13.015 8.84306 13.0476 8.89459 13.0613 8.95986C13.0751 9.02513 13.0631 9.1007 13.0253 9.18659L11.6803 12.2062C11.6426 12.2921 11.5945 12.3574 11.5361 12.402C11.4811 12.4432 11.4055 12.4639 11.3093 12.4639Z"
        fill="white"
      />
    </svg>
  );
};

export default Svg;

import { createActionCreator } from 'deox';

import { TCancelRequestMaterials, TCancelRequestResponse } from '@/services/api/request/cancel-request';

// CONSTANTS

export enum ECancelRequestAction {
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  CANCEL_REQUEST_REQUEST = 'CANCEL_REQUEST_REQUEST',
  CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS',
  CANCEL_REQUEST_FAILED = 'CANCEL_REQUEST_FAILED',
}

// TYPES

export type TCancelRequestRequest = {
  type: ECancelRequestAction.CANCEL_REQUEST_REQUEST;
  payload: {
    materials: TCancelRequestMaterials;
    successCallback?: (response: TCancelRequestResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCancelRequestSuccess = {
  type: ECancelRequestAction.CANCEL_REQUEST_SUCCESS;
  payload: { response: TCancelRequestResponse };
};

export type TCancelRequestFailed = { type: ECancelRequestAction.CANCEL_REQUEST_FAILED };

// FUNCTION

export const cancelRequestAction = {
  request: createActionCreator(
    ECancelRequestAction.CANCEL_REQUEST_REQUEST,
    (resolve) =>
      (
        materials: TCancelRequestMaterials,
        successCallback?: (response: TCancelRequestResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCancelRequestRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECancelRequestAction.CANCEL_REQUEST_SUCCESS,
    (resolve) =>
      (response: TCancelRequestResponse): TCancelRequestSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECancelRequestAction.CANCEL_REQUEST_FAILED,
    (resolve) =>
      (error: unknown): TCancelRequestFailed =>
        resolve({ error }),
  ),
};

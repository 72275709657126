import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_600 }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.75 6H7.75V10.5H16.75V6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.75 20.25V3.75C19.75 3.33579 19.4142 3 19 3L5.5 3C5.08579 3 4.75 3.33579 4.75 3.75L4.75 20.25C4.75 20.6642 5.08579 21 5.5 21H19C19.4142 21 19.75 20.6642 19.75 20.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 14.8125C9.01777 14.8125 9.4375 14.3928 9.4375 13.875C9.4375 13.3572 9.01777 12.9375 8.5 12.9375C7.98223 12.9375 7.5625 13.3572 7.5625 13.875C7.5625 14.3928 7.98223 14.8125 8.5 14.8125Z"
        fill={color}
      />
      <path
        d="M12.25 14.8125C12.7678 14.8125 13.1875 14.3928 13.1875 13.875C13.1875 13.3572 12.7678 12.9375 12.25 12.9375C11.7322 12.9375 11.3125 13.3572 11.3125 13.875C11.3125 14.3928 11.7322 14.8125 12.25 14.8125Z"
        fill={color}
      />
      <path
        d="M16 14.8125C16.5178 14.8125 16.9375 14.3928 16.9375 13.875C16.9375 13.3572 16.5178 12.9375 16 12.9375C15.4822 12.9375 15.0625 13.3572 15.0625 13.875C15.0625 14.3928 15.4822 14.8125 16 14.8125Z"
        fill={color}
      />
      <path
        d="M8.5 18.5625C9.01777 18.5625 9.4375 18.1428 9.4375 17.625C9.4375 17.1072 9.01777 16.6875 8.5 16.6875C7.98223 16.6875 7.5625 17.1072 7.5625 17.625C7.5625 18.1428 7.98223 18.5625 8.5 18.5625Z"
        fill={color}
      />
      <path
        d="M12.25 18.5625C12.7678 18.5625 13.1875 18.1428 13.1875 17.625C13.1875 17.1072 12.7678 16.6875 12.25 16.6875C11.7322 16.6875 11.3125 17.1072 11.3125 17.625C11.3125 18.1428 11.7322 18.5625 12.25 18.5625Z"
        fill={color}
      />
      <path
        d="M16 18.5625C16.5178 18.5625 16.9375 18.1428 16.9375 17.625C16.9375 17.1072 16.5178 16.6875 16 16.6875C15.4822 16.6875 15.0625 17.1072 15.0625 17.625C15.0625 18.1428 15.4822 18.5625 16 18.5625Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;

import { createActionCreator } from 'deox';

import { TDuplicateTemplateMaterials, TDuplicateTemplateResponse } from '@/services/api/template/duplicate-template';

// CONSTANTS

export enum EDuplicateTemplateAction {
  DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE',
  DUPLICATE_TEMPLATE_REQUEST = 'DUPLICATE_TEMPLATE_REQUEST',
  DUPLICATE_TEMPLATE_SUCCESS = 'DUPLICATE_TEMPLATE_SUCCESS',
  DUPLICATE_TEMPLATE_FAILED = 'DUPLICATE_TEMPLATE_FAILED',
}

// TYPES

export type TDuplicateTemplateRequest = {
  type: EDuplicateTemplateAction.DUPLICATE_TEMPLATE_REQUEST;
  payload: {
    materials: TDuplicateTemplateMaterials;
    successCallback?: (response: TDuplicateTemplateResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDuplicateTemplateSuccess = {
  type: EDuplicateTemplateAction.DUPLICATE_TEMPLATE_SUCCESS;
  payload: { response: TDuplicateTemplateResponse };
};

export type TDuplicateTemplateFailed = { type: EDuplicateTemplateAction.DUPLICATE_TEMPLATE_FAILED };

// FUNCTION

export const duplicateTemplateAction = {
  request: createActionCreator(
    EDuplicateTemplateAction.DUPLICATE_TEMPLATE_REQUEST,
    (resolve) =>
      (
        materials: TDuplicateTemplateMaterials,
        successCallback?: (response: TDuplicateTemplateResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDuplicateTemplateRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDuplicateTemplateAction.DUPLICATE_TEMPLATE_SUCCESS,
    (resolve) =>
      (response: TDuplicateTemplateResponse): TDuplicateTemplateSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDuplicateTemplateAction.DUPLICATE_TEMPLATE_FAILED,
    (resolve) =>
      (error: unknown): TDuplicateTemplateFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import {
  TGetReportTimeOffScheduleMaterials,
  TGetReportTimeOffScheduleResponse,
} from '@/services/api/report/get-report-time-off-schedule';

// CONSTANTS

export enum EGetReportTimeOffScheduleAction {
  GET_REPORT_TIME_OFF_SCHEDULE = 'GET_REPORT_TIME_OFF_SCHEDULE',
  GET_REPORT_TIME_OFF_SCHEDULE_REQUEST = 'GET_REPORT_TIME_OFF_SCHEDULE_REQUEST',
  GET_REPORT_TIME_OFF_SCHEDULE_SUCCESS = 'GET_REPORT_TIME_OFF_SCHEDULE_SUCCESS',
  GET_REPORT_TIME_OFF_SCHEDULE_FAILED = 'GET_REPORT_TIME_OFF_SCHEDULE_FAILED',
}

// TYPES

export type TGetReportTimeOffScheduleRequest = {
  type: EGetReportTimeOffScheduleAction.GET_REPORT_TIME_OFF_SCHEDULE_REQUEST;
  payload: {
    materials: TGetReportTimeOffScheduleMaterials;
    successCallback?: (response: TGetReportTimeOffScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportTimeOffScheduleSuccess = {
  type: EGetReportTimeOffScheduleAction.GET_REPORT_TIME_OFF_SCHEDULE_SUCCESS;
  payload: { response: TGetReportTimeOffScheduleResponse };
};

export type TGetReportTimeOffScheduleFailed = {
  type: EGetReportTimeOffScheduleAction.GET_REPORT_TIME_OFF_SCHEDULE_FAILED;
};

// FUNCTION

export const getReportTimeOffScheduleAction = {
  request: createActionCreator(
    EGetReportTimeOffScheduleAction.GET_REPORT_TIME_OFF_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TGetReportTimeOffScheduleMaterials,
        successCallback?: (response: TGetReportTimeOffScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportTimeOffScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportTimeOffScheduleAction.GET_REPORT_TIME_OFF_SCHEDULE_SUCCESS,
    (resolve) =>
      (response: TGetReportTimeOffScheduleResponse): TGetReportTimeOffScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportTimeOffScheduleAction.GET_REPORT_TIME_OFF_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TGetReportTimeOffScheduleFailed =>
        resolve({ error }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_613_50143)">
        <path
          d="M20 8.92273H4C3.44772 8.92273 3 9.37044 3 9.92273V11.9227C3 12.475 3.44772 12.9227 4 12.9227H20C20.5523 12.9227 21 12.475 21 11.9227V9.92273C21 9.37044 20.5523 8.92273 20 8.92273Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12 8.92273V21.9227" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M19 12.9227V19.9227C19 20.4532 18.7893 20.9619 18.4142 21.3369C18.0391 21.712 17.5304 21.9227 17 21.9227H7C6.46957 21.9227 5.96086 21.712 5.58579 21.3369C5.21071 20.9619 5 20.4532 5 19.9227V12.9227"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 8.92272C6.83696 8.92272 6.20107 8.65933 5.73223 8.19049C5.26339 7.72165 5 7.08577 5 6.42272C5 5.75968 5.26339 5.1238 5.73223 4.65496C6.20107 4.18612 6.83696 3.92272 7.5 3.92272C8.46469 3.90592 9.41003 4.37398 10.2127 5.26588C11.0154 6.15779 11.6383 7.43213 12 8.92272C12.3617 7.43213 12.9846 6.15779 13.7873 5.26588C14.59 4.37398 15.5353 3.90592 16.5 3.92272C17.163 3.92272 17.7989 4.18612 18.2678 4.65496C18.7366 5.1238 19 5.75968 19 6.42272C19 7.08577 18.7366 7.72165 18.2678 8.19049C17.7989 8.65933 17.163 8.92272 16.5 8.92272"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_613_50143">
          <rect width="24" height="24" fill="white" transform="translate(0 0.922729)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg;

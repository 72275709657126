import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRoleAction } from '@/redux/actions';
import { getRole, TGetRoleResponse } from '@/services/api';

// FUNCTION

export function* getRoleSaga(action: ActionType<typeof getRoleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRole, materials);
    const getRoleResponse: TGetRoleResponse = response as TGetRoleResponse;
    yield put(getRoleAction.success(getRoleResponse));
    successCallback?.(getRoleResponse);
  } catch (err) {
    yield put(getRoleAction.failure(err));
    failedCallback?.(err);
  }
}

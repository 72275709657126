import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 5.80859L9.14427 11.6643C9.09873 11.7071 9.06243 11.7587 9.03761 11.816C9.0128 11.8733 9 11.9351 9 11.9976C9 12.06 9.0128 12.1218 9.03761 12.1791C9.06243 12.2365 9.09873 12.2881 9.14427 12.3308L15 18.1866"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

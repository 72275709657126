import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getCompanyTimeOffsAction } from '@/redux/actions';
import { getCompanyTimeOffs, TGetCompanyTimeOffsResponse } from '@/services/api';

// FUNCTION

export function* getCompanyTimeOffsSaga(action: ActionType<typeof getCompanyTimeOffsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getCompanyTimeOffs, materials);
    const getCompanyTimeOffsResponse: TGetCompanyTimeOffsResponse = response as TGetCompanyTimeOffsResponse;
    yield put(getCompanyTimeOffsAction.success(getCompanyTimeOffsResponse));
    successCallback?.(getCompanyTimeOffsResponse);
  } catch (err) {
    yield put(getCompanyTimeOffsAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { changeTaskStatusAction } from '@/redux/actions';
import { changeTaskStatus, TChangeTaskStatusResponse } from '@/services/api';

// FUNCTION

export function* changeTaskStatusSaga(action: ActionType<typeof changeTaskStatusAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(changeTaskStatus, materials);
    const changeTaskStatusResponse: TChangeTaskStatusResponse = response as TChangeTaskStatusResponse;
    yield put(changeTaskStatusAction.success(changeTaskStatusResponse));
    successCallback?.(changeTaskStatusResponse);
  } catch (err) {
    yield put(changeTaskStatusAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TGetReportHeadcountMaterials, TGetReportHeadcountResponse } from '@/services/api/report/get-report-headcount';

// CONSTANTS

export enum EGetReportHeadcountAction {
  GET_REPORT_HEADCOUNT = 'GET_REPORT_HEADCOUNT',
  GET_REPORT_HEADCOUNT_REQUEST = 'GET_REPORT_HEADCOUNT_REQUEST',
  GET_REPORT_HEADCOUNT_SUCCESS = 'GET_REPORT_HEADCOUNT_SUCCESS',
  GET_REPORT_HEADCOUNT_FAILED = 'GET_REPORT_HEADCOUNT_FAILED',
}

// TYPES

export type TGetReportHeadcountRequest = {
  type: EGetReportHeadcountAction.GET_REPORT_HEADCOUNT_REQUEST;
  payload: {
    materials: TGetReportHeadcountMaterials;
    successCallback?: (response: TGetReportHeadcountResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportHeadcountSuccess = {
  type: EGetReportHeadcountAction.GET_REPORT_HEADCOUNT_SUCCESS;
  payload: { response: TGetReportHeadcountResponse };
};

export type TGetReportHeadcountFailed = { type: EGetReportHeadcountAction.GET_REPORT_HEADCOUNT_FAILED };

// FUNCTION

export const getReportHeadcountAction = {
  request: createActionCreator(
    EGetReportHeadcountAction.GET_REPORT_HEADCOUNT_REQUEST,
    (resolve) =>
      (
        materials: TGetReportHeadcountMaterials,
        successCallback?: (response: TGetReportHeadcountResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportHeadcountRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportHeadcountAction.GET_REPORT_HEADCOUNT_SUCCESS,
    (resolve) =>
      (response: TGetReportHeadcountResponse): TGetReportHeadcountSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportHeadcountAction.GET_REPORT_HEADCOUNT_FAILED,
    (resolve) =>
      (error: unknown): TGetReportHeadcountFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TUpdateRegulateCategoryPaths = {
  id: string | number;
};
export type TUpdateRegulateCategoryBody = unknown;

export type TUpdateRegulateCategoryMaterials = {
  paths?: TUpdateRegulateCategoryPaths;
  body?: TUpdateRegulateCategoryBody;
};

export type TUpdateRegulateCategoryResponse = unknown;

// FUNCTION

export const updateRegulateCategory = async ({
  paths,
  body,
}: TUpdateRegulateCategoryMaterials): Promise<TUpdateRegulateCategoryResponse> => {
  const response = await ApiService.put(`/regulate-category/${paths?.id}`, body);
  return response.data;
};

import { createActionCreator } from 'deox';

import { TUpdateAssetMaterials, TUpdateAssetResponse } from '@/services/api/asset/update-asset';

// CONSTANTS

export enum EUpdateAssetAction {
  UPDATE_ASSET = 'UPDATE_ASSET',
  UPDATE_ASSET_REQUEST = 'UPDATE_ASSET_REQUEST',
  UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS',
  UPDATE_ASSET_FAILED = 'UPDATE_ASSET_FAILED',
}

// TYPES

export type TUpdateAssetRequest = {
  type: EUpdateAssetAction.UPDATE_ASSET_REQUEST;
  payload: {
    materials: TUpdateAssetMaterials;
    successCallback?: (response: TUpdateAssetResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateAssetSuccess = {
  type: EUpdateAssetAction.UPDATE_ASSET_SUCCESS;
  payload: { response: TUpdateAssetResponse };
};

export type TUpdateAssetFailed = { type: EUpdateAssetAction.UPDATE_ASSET_FAILED };

// FUNCTION

export const updateAssetAction = {
  request: createActionCreator(
    EUpdateAssetAction.UPDATE_ASSET_REQUEST,
    (resolve) =>
      (
        materials: TUpdateAssetMaterials,
        successCallback?: (response: TUpdateAssetResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateAssetRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateAssetAction.UPDATE_ASSET_SUCCESS,
    (resolve) =>
      (response: TUpdateAssetResponse): TUpdateAssetSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateAssetAction.UPDATE_ASSET_FAILED,
    (resolve) =>
      (error: unknown): TUpdateAssetFailed =>
        resolve({ error }),
  ),
};

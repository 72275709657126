import ApiService from '@/services/api';

// TYPES

export type TContractProbationRequestParams = unknown;
export type TContractProbationRequestBody = unknown;

export type TContractProbationRequestMaterials = {
  params?: TContractProbationRequestParams;
  body?: TContractProbationRequestBody;
};

export type TContractProbationRequestResponse = unknown;

// FUNCTION

export const contractProbationRequest = async ({
  params,
  body,
}: TContractProbationRequestMaterials): Promise<TContractProbationRequestResponse> => {
  const response = await ApiService.post(`/request/contract-probation/new`, body, { params });
  return response.data;
};

import { TDashboardState } from '@/redux/reducers/dashboard';
import { TSendCelebrationSuccess } from '@/redux/actions/dashboard';

export const sendCelebrationUpdateState = (
  state: TDashboardState,
  action: TSendCelebrationSuccess,
): TDashboardState => ({
  ...state,
  sendCelebrationResponse: action.payload.response,
});

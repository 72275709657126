import React from 'react';
import { Col, Row } from 'antd';

import Icon, { EIconColor } from '@/components/Icon';

import { TQuickActionsDropdownProps } from './QuickActionsDropdown.types';
import './QuickActionsDropdown.scss';

const QuickActionsDropdown: React.FC<TQuickActionsDropdownProps> = ({ minWidth, data = [], onClickItem }) => {
  return (
    <div className="QuickActionsDropdown" style={{ minWidth }}>
      <div className="QuickActionsDropdown-wrapper">
        <Row gutter={[24, 24]}>
          {data
            ?.filter((group) => (group.data?.filter((item) => !item.hide)?.length || 0) > 0)
            ?.map((group) => (
              <Col key={group.key}>
                <div className="QuickActionsDropdown-wrapper-item">
                  <div className="QuickActionsDropdown-title">{group.title}</div>

                  <div className="QuickActionsDropdown-actions">
                    {group.data
                      ?.filter((item) => !item.hide)
                      ?.map((item) => (
                        <div
                          className="QuickActionsDropdown-actions-item flex items-center"
                          onClick={(): void => {
                            onClickItem?.(item);
                            item.onClick?.();
                          }}
                        >
                          <div className="QuickActionsDropdown-actions-item-icon">
                            {item.icon && <img src={item.icon} alt="" />}
                            {item.iconName && <Icon name={item.iconName} color={EIconColor.GRAYSCALE_900} />}
                          </div>
                          <div className="QuickActionsDropdown-actions-item-title">{item.title}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
};

export default QuickActionsDropdown;

import { createActionCreator } from 'deox';

import {
  TCreateTaskCategoryMaterials,
  TCreateTaskCategoryResponse,
} from '@/services/api/template/create-task-category';

// CONSTANTS

export enum ECreateTaskCategoryAction {
  CREATE_TASK_CATEGORY = 'CREATE_TASK_CATEGORY',
  CREATE_TASK_CATEGORY_REQUEST = 'CREATE_TASK_CATEGORY_REQUEST',
  CREATE_TASK_CATEGORY_SUCCESS = 'CREATE_TASK_CATEGORY_SUCCESS',
  CREATE_TASK_CATEGORY_FAILED = 'CREATE_TASK_CATEGORY_FAILED',
}

// TYPES

export type TCreateTaskCategoryRequest = {
  type: ECreateTaskCategoryAction.CREATE_TASK_CATEGORY_REQUEST;
  payload: {
    materials: TCreateTaskCategoryMaterials;
    successCallback?: (response: TCreateTaskCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateTaskCategorySuccess = {
  type: ECreateTaskCategoryAction.CREATE_TASK_CATEGORY_SUCCESS;
  payload: { response: TCreateTaskCategoryResponse };
};

export type TCreateTaskCategoryFailed = { type: ECreateTaskCategoryAction.CREATE_TASK_CATEGORY_FAILED };

// FUNCTION

export const createTaskCategoryAction = {
  request: createActionCreator(
    ECreateTaskCategoryAction.CREATE_TASK_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TCreateTaskCategoryMaterials,
        successCallback?: (response: TCreateTaskCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateTaskCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateTaskCategoryAction.CREATE_TASK_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TCreateTaskCategoryResponse): TCreateTaskCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateTaskCategoryAction.CREATE_TASK_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TCreateTaskCategoryFailed =>
        resolve({ error }),
  ),
};

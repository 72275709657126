import { createActionCreator } from 'deox';

import { TGetTypesWorkMaterials, TGetTypesWorkResponse } from '@/services/api/type-work/get-types-work';

// CONSTANTS

export enum EGetTypesWorkAction {
  GET_TYPES_WORK = 'GET_TYPES_WORK',
  GET_TYPES_WORK_REQUEST = 'GET_TYPES_WORK_REQUEST',
  GET_TYPES_WORK_SUCCESS = 'GET_TYPES_WORK_SUCCESS',
  GET_TYPES_WORK_FAILED = 'GET_TYPES_WORK_FAILED',
}

// TYPES

export type TGetTypesWorkRequest = {
  type: EGetTypesWorkAction.GET_TYPES_WORK_REQUEST;
  payload: {
    materials: TGetTypesWorkMaterials;
    successCallback?: (response: TGetTypesWorkResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTypesWorkSuccess = {
  type: EGetTypesWorkAction.GET_TYPES_WORK_SUCCESS;
  payload: { response: TGetTypesWorkResponse };
};

export type TGetTypesWorkFailed = { type: EGetTypesWorkAction.GET_TYPES_WORK_FAILED };

// FUNCTION

export const getTypesWorkAction = {
  request: createActionCreator(
    EGetTypesWorkAction.GET_TYPES_WORK_REQUEST,
    (resolve) =>
      (
        materials: TGetTypesWorkMaterials,
        successCallback?: (response: TGetTypesWorkResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTypesWorkRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTypesWorkAction.GET_TYPES_WORK_SUCCESS,
    (resolve) =>
      (response: TGetTypesWorkResponse): TGetTypesWorkSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTypesWorkAction.GET_TYPES_WORK_FAILED,
    (resolve) =>
      (error: unknown): TGetTypesWorkFailed =>
        resolve({ error }),
  ),
};

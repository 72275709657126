import { createActionCreator } from 'deox';

import { TMailFeedbackMaterials, TMailFeedbackResponse } from '@/services/api/mail/mail-feedback';

// CONSTANTS

export enum EMailFeedbackAction {
  MAIL_FEEDBACK = 'MAIL_FEEDBACK',
  MAIL_FEEDBACK_REQUEST = 'MAIL_FEEDBACK_REQUEST',
  MAIL_FEEDBACK_SUCCESS = 'MAIL_FEEDBACK_SUCCESS',
  MAIL_FEEDBACK_FAILED = 'MAIL_FEEDBACK_FAILED',
}

// TYPES

export type TMailFeedbackRequest = {
  type: EMailFeedbackAction.MAIL_FEEDBACK_REQUEST;
  payload: {
    materials: TMailFeedbackMaterials;
    successCallback?: (response: TMailFeedbackResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TMailFeedbackSuccess = {
  type: EMailFeedbackAction.MAIL_FEEDBACK_SUCCESS;
  payload: { response: TMailFeedbackResponse };
};

export type TMailFeedbackFailed = { type: EMailFeedbackAction.MAIL_FEEDBACK_FAILED };

// FUNCTION

export const mailFeedbackAction = {
  request: createActionCreator(
    EMailFeedbackAction.MAIL_FEEDBACK_REQUEST,
    (resolve) =>
      (
        materials: TMailFeedbackMaterials,
        successCallback?: (response: TMailFeedbackResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TMailFeedbackRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EMailFeedbackAction.MAIL_FEEDBACK_SUCCESS,
    (resolve) =>
      (response: TMailFeedbackResponse): TMailFeedbackSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EMailFeedbackAction.MAIL_FEEDBACK_FAILED,
    (resolve) =>
      (error: unknown): TMailFeedbackFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TUpdateAssetPaths = {
  id: string | number;
};
export type TUpdateAssetBody = unknown;

export type TUpdateAssetMaterials = {
  paths?: TUpdateAssetPaths;
  body?: TUpdateAssetBody;
};

export type TUpdateAssetResponse = unknown;

// FUNCTION

export const updateAsset = async ({ paths, body }: TUpdateAssetMaterials): Promise<TUpdateAssetResponse> => {
  const response = await ApiService.put(`/asset/${paths?.id}`, body);
  return response.data;
};

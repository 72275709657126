import { createActionCreator } from 'deox';

import {
  TUpdateTimeOffTypeMaterials,
  TUpdateTimeOffTypeResponse,
} from '@/services/api/time-off-type/update-time-off-type';

// CONSTANTS

export enum EUpdateTimeOffTypeAction {
  UPDATE_TIME_OFF_TYPE = 'UPDATE_TIME_OFF_TYPE',
  UPDATE_TIME_OFF_TYPE_REQUEST = 'UPDATE_TIME_OFF_TYPE_REQUEST',
  UPDATE_TIME_OFF_TYPE_SUCCESS = 'UPDATE_TIME_OFF_TYPE_SUCCESS',
  UPDATE_TIME_OFF_TYPE_FAILED = 'UPDATE_TIME_OFF_TYPE_FAILED',
}

// TYPES

export type TUpdateTimeOffTypeRequest = {
  type: EUpdateTimeOffTypeAction.UPDATE_TIME_OFF_TYPE_REQUEST;
  payload: {
    materials: TUpdateTimeOffTypeMaterials;
    successCallback?: (response: TUpdateTimeOffTypeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateTimeOffTypeSuccess = {
  type: EUpdateTimeOffTypeAction.UPDATE_TIME_OFF_TYPE_SUCCESS;
  payload: { response: TUpdateTimeOffTypeResponse };
};

export type TUpdateTimeOffTypeFailed = { type: EUpdateTimeOffTypeAction.UPDATE_TIME_OFF_TYPE_FAILED };

// FUNCTION

export const updateTimeOffTypeAction = {
  request: createActionCreator(
    EUpdateTimeOffTypeAction.UPDATE_TIME_OFF_TYPE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateTimeOffTypeMaterials,
        successCallback?: (response: TUpdateTimeOffTypeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateTimeOffTypeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateTimeOffTypeAction.UPDATE_TIME_OFF_TYPE_SUCCESS,
    (resolve) =>
      (response: TUpdateTimeOffTypeResponse): TUpdateTimeOffTypeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateTimeOffTypeAction.UPDATE_TIME_OFF_TYPE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateTimeOffTypeFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TSeenNotificationMaterials, TSeenNotificationResponse } from '@/services/api/dashboard/seen-notification';

// CONSTANTS

export enum ESeenNotificationAction {
  SEEN_NOTIFICATION = 'SEEN_NOTIFICATION',
  SEEN_NOTIFICATION_REQUEST = 'SEEN_NOTIFICATION_REQUEST',
  SEEN_NOTIFICATION_SUCCESS = 'SEEN_NOTIFICATION_SUCCESS',
  SEEN_NOTIFICATION_FAILED = 'SEEN_NOTIFICATION_FAILED',
}

// TYPES

export type TSeenNotificationRequest = {
  type: ESeenNotificationAction.SEEN_NOTIFICATION_REQUEST;
  payload: {
    materials: TSeenNotificationMaterials;
    successCallback?: (response: TSeenNotificationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSeenNotificationSuccess = {
  type: ESeenNotificationAction.SEEN_NOTIFICATION_SUCCESS;
  payload: { response: TSeenNotificationResponse };
};

export type TSeenNotificationFailed = { type: ESeenNotificationAction.SEEN_NOTIFICATION_FAILED };

// FUNCTION

export const seenNotificationAction = {
  request: createActionCreator(
    ESeenNotificationAction.SEEN_NOTIFICATION_REQUEST,
    (resolve) =>
      (
        materials: TSeenNotificationMaterials,
        successCallback?: (response: TSeenNotificationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSeenNotificationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ESeenNotificationAction.SEEN_NOTIFICATION_SUCCESS,
    (resolve) =>
      (response: TSeenNotificationResponse): TSeenNotificationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ESeenNotificationAction.SEEN_NOTIFICATION_FAILED,
    (resolve) =>
      (error: unknown): TSeenNotificationFailed =>
        resolve({ error }),
  ),
};

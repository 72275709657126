import { TTemplateState } from '@/redux/reducers/template';
import { TGetTasksCategorySuccess } from '@/redux/actions/template';

export const getTasksCategoryUpdateState = (
  state: TTemplateState,
  action: TGetTasksCategorySuccess,
): TTemplateState => ({
  ...state,
  getTasksCategoryResponse: action.payload.response,
});

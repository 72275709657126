import { createActionCreator } from 'deox';

import { TRevokeAccessUserMaterials, TRevokeAccessUserResponse } from '@/services/api/user/revoke-access-user';

// CONSTANTS

export enum ERevokeAccessUserAction {
  REVOKE_ACCESS_USER = 'REVOKE_ACCESS_USER',
  REVOKE_ACCESS_USER_REQUEST = 'REVOKE_ACCESS_USER_REQUEST',
  REVOKE_ACCESS_USER_SUCCESS = 'REVOKE_ACCESS_USER_SUCCESS',
  REVOKE_ACCESS_USER_FAILED = 'REVOKE_ACCESS_USER_FAILED',
}

// TYPES

export type TRevokeAccessUserRequest = {
  type: ERevokeAccessUserAction.REVOKE_ACCESS_USER_REQUEST;
  payload: {
    materials: TRevokeAccessUserMaterials;
    successCallback?: (response: TRevokeAccessUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRevokeAccessUserSuccess = {
  type: ERevokeAccessUserAction.REVOKE_ACCESS_USER_SUCCESS;
  payload: { response: TRevokeAccessUserResponse };
};

export type TRevokeAccessUserFailed = { type: ERevokeAccessUserAction.REVOKE_ACCESS_USER_FAILED };

// FUNCTION

export const revokeAccessUserAction = {
  request: createActionCreator(
    ERevokeAccessUserAction.REVOKE_ACCESS_USER_REQUEST,
    (resolve) =>
      (
        materials: TRevokeAccessUserMaterials,
        successCallback?: (response: TRevokeAccessUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRevokeAccessUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERevokeAccessUserAction.REVOKE_ACCESS_USER_SUCCESS,
    (resolve) =>
      (response: TRevokeAccessUserResponse): TRevokeAccessUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERevokeAccessUserAction.REVOKE_ACCESS_USER_FAILED,
    (resolve) =>
      (error: unknown): TRevokeAccessUserFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { donwloadReportTimeOffScheduleAction } from '@/redux/actions';
import { donwloadReportTimeOffSchedule, TDonwloadReportTimeOffScheduleResponse } from '@/services/api';

// FUNCTION

export function* donwloadReportTimeOffScheduleSaga(
  action: ActionType<typeof donwloadReportTimeOffScheduleAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(donwloadReportTimeOffSchedule, materials);
    const donwloadReportTimeOffScheduleResponse: TDonwloadReportTimeOffScheduleResponse =
      response as TDonwloadReportTimeOffScheduleResponse;
    yield put(donwloadReportTimeOffScheduleAction.success(donwloadReportTimeOffScheduleResponse));
    successCallback?.(donwloadReportTimeOffScheduleResponse);
  } catch (err) {
    yield put(donwloadReportTimeOffScheduleAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TSendCelebrationMaterials, TSendCelebrationResponse } from '@/services/api/dashboard/send-celebration';

// CONSTANTS

export enum ESendCelebrationAction {
  SEND_CELEBRATION = 'SEND_CELEBRATION',
  SEND_CELEBRATION_REQUEST = 'SEND_CELEBRATION_REQUEST',
  SEND_CELEBRATION_SUCCESS = 'SEND_CELEBRATION_SUCCESS',
  SEND_CELEBRATION_FAILED = 'SEND_CELEBRATION_FAILED',
}

// TYPES

export type TSendCelebrationRequest = {
  type: ESendCelebrationAction.SEND_CELEBRATION_REQUEST;
  payload: {
    materials: TSendCelebrationMaterials;
    successCallback?: (response: TSendCelebrationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSendCelebrationSuccess = {
  type: ESendCelebrationAction.SEND_CELEBRATION_SUCCESS;
  payload: { response: TSendCelebrationResponse };
};

export type TSendCelebrationFailed = { type: ESendCelebrationAction.SEND_CELEBRATION_FAILED };

// FUNCTION

export const sendCelebrationAction = {
  request: createActionCreator(
    ESendCelebrationAction.SEND_CELEBRATION_REQUEST,
    (resolve) =>
      (
        materials: TSendCelebrationMaterials,
        successCallback?: (response: TSendCelebrationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSendCelebrationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ESendCelebrationAction.SEND_CELEBRATION_SUCCESS,
    (resolve) =>
      (response: TSendCelebrationResponse): TSendCelebrationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ESendCelebrationAction.SEND_CELEBRATION_FAILED,
    (resolve) =>
      (error: unknown): TSendCelebrationFailed =>
        resolve({ error }),
  ),
};

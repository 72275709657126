import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportEmployeeTurnoverRateAction } from '@/redux/actions';
import { getReportEmployeeTurnoverRate, TGetReportEmployeeTurnoverRateResponse } from '@/services/api';

// FUNCTION

export function* getReportEmployeeTurnoverRateSaga(
  action: ActionType<typeof getReportEmployeeTurnoverRateAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportEmployeeTurnoverRate, materials);
    const getReportEmployeeTurnoverRateResponse: TGetReportEmployeeTurnoverRateResponse =
      response as TGetReportEmployeeTurnoverRateResponse;
    yield put(getReportEmployeeTurnoverRateAction.success(getReportEmployeeTurnoverRateResponse));
    successCallback?.(getReportEmployeeTurnoverRateResponse);
  } catch (err) {
    yield put(getReportEmployeeTurnoverRateAction.failure(err));
    failedCallback?.(err);
  }
}

import { TDashboardState } from '@/redux/reducers/dashboard';
import { TGetNotificationsSuccess } from '@/redux/actions/dashboard';

export const getNotificationsUpdateState = (
  state: TDashboardState,
  action: TGetNotificationsSuccess,
): TDashboardState => ({
  ...state,
  getNotificationsResponse: action.payload.response,
});

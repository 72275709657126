import React, { useEffect } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@/components/Modal';
import { TModalSendFeedbackProps } from './ModalSendFeedback.types';
import Select from '@/components/Select';
import Checkbox from '@/components/Checkbox';
import { EButtonStyleType } from '@/components/Button';
import TextArea from '@/components/TextArea';
import { showNotification, validationRules } from '@/utils/functions';
import { EMailFeedbackAction, mailFeedbackAction } from '@/redux/actions';
import { ETypeNotification } from '@/common/enums';
import { TRootState } from '@/redux/reducers';

import { dataFeedbackTitleOptions } from './ModalSendFeedback.data';

const ModalSendFeedback: React.FC<TModalSendFeedbackProps> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const mailFeedbackLoading = useSelector(
    (state: TRootState) => state.loadingReducer[EMailFeedbackAction.MAIL_FEEDBACK],
  );

  const handleSubmit = (): void => {
    form.validateFields().then((values) => {
      const body = { ...values, title: values?.title?.value };

      dispatch(mailFeedbackAction.request({ body }, handleMailFeedbackSuccess));
    });
  };

  const handleMailFeedbackSuccess = (): void => {
    showNotification(ETypeNotification.SUCCESS, t('Notification_send_feedback_successfully'));
    onClose?.();
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('Give_us_feedback_title')}
      className="ModalSendFeedback"
      showFooter
      cancelButton={{ title: t('Common_cancel'), disabled: mailFeedbackLoading }}
      confirmButton={{
        title: t('Give_us_send_feedback'),
        styleType: EButtonStyleType.PRIMARY,
        disabled: mailFeedbackLoading,
      }}
    >
      <div className="ModalSendFeedback-wrapper">
        <Form form={form} layout="vertical">
          <Form.Item name="title" rules={[validationRules.required(t)]}>
            <Select
              getPopupContainer={(trigger): HTMLElement => trigger}
              placeholder={t('Common_select_with_dash')}
              options={dataFeedbackTitleOptions(t)}
            />
          </Form.Item>
          <Form.Item name="content" rules={[validationRules.required(t)]}>
            <TextArea label={t('Give_us_feedback_what_would_you_like')} required placeholder={t('Common_enter_data')} />
          </Form.Item>
          <Form.Item name="isCanContactMe" style={{ marginBottom: 0 }}>
            <Checkbox title={t('Give_us_feedback_acaziahr_can_contact_me_about_this_feedback')} />
          </Form.Item>
          <Form.Item name="isParticipateInProductResearch">
            <Checkbox title={t('Give_us_feedback_like_to_participate_in_product_research')} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default ModalSendFeedback;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportTimeOffScheduleAction } from '@/redux/actions';
import { getReportTimeOffSchedule, TGetReportTimeOffScheduleResponse } from '@/services/api';

// FUNCTION

export function* getReportTimeOffScheduleSaga(
  action: ActionType<typeof getReportTimeOffScheduleAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportTimeOffSchedule, materials);
    const getReportTimeOffScheduleResponse: TGetReportTimeOffScheduleResponse =
      response as TGetReportTimeOffScheduleResponse;
    yield put(getReportTimeOffScheduleAction.success(getReportTimeOffScheduleResponse));
    successCallback?.(getReportTimeOffScheduleResponse);
  } catch (err) {
    yield put(getReportTimeOffScheduleAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRolesAction } from '@/redux/actions';
import { getRoles, TGetRolesResponse } from '@/services/api';

// FUNCTION

export function* getRolesSaga(action: ActionType<typeof getRolesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRoles, materials);
    const getRolesResponse: TGetRolesResponse = response as TGetRolesResponse;
    yield put(getRolesAction.success(getRolesResponse));
    successCallback?.(getRolesResponse);
  } catch (err) {
    yield put(getRolesAction.failure(err));
    failedCallback?.(err);
  }
}

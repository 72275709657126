import { createActionCreator } from 'deox';

import { TGetBenefitMaterials, TGetBenefitResponse } from '@/services/api/benefit/get-benefit';

// CONSTANTS

export enum EGetBenefitAction {
  GET_BENEFIT = 'GET_BENEFIT',
  GET_BENEFIT_REQUEST = 'GET_BENEFIT_REQUEST',
  GET_BENEFIT_SUCCESS = 'GET_BENEFIT_SUCCESS',
  GET_BENEFIT_FAILED = 'GET_BENEFIT_FAILED',
}

// TYPES

export type TGetBenefitRequest = {
  type: EGetBenefitAction.GET_BENEFIT_REQUEST;
  payload: {
    materials: TGetBenefitMaterials;
    successCallback?: (response: TGetBenefitResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBenefitSuccess = {
  type: EGetBenefitAction.GET_BENEFIT_SUCCESS;
  payload: { response?: TGetBenefitResponse };
};

export type TGetBenefitFailed = { type: EGetBenefitAction.GET_BENEFIT_FAILED };

// FUNCTION

export const getBenefitAction = {
  request: createActionCreator(
    EGetBenefitAction.GET_BENEFIT_REQUEST,
    (resolve) =>
      (
        materials: TGetBenefitMaterials,
        successCallback?: (response: TGetBenefitResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBenefitRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetBenefitAction.GET_BENEFIT_SUCCESS,
    (resolve) =>
      (response?: TGetBenefitResponse): TGetBenefitSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetBenefitAction.GET_BENEFIT_FAILED,
    (resolve) =>
      (error: unknown): TGetBenefitFailed =>
        resolve({ error }),
  ),
};

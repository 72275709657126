import ApiService from '@/services/api';

// TYPES

export type TCreateBenefitCoverageParams = unknown;
export type TCreateBenefitCoverageBody = unknown;

export type TCreateBenefitCoverageMaterials = {
  params?: TCreateBenefitCoverageParams;
  body?: TCreateBenefitCoverageBody;
};

export type TCreateBenefitCoverageResponse = unknown;

// FUNCTION

export const createBenefitCoverage = async ({
  params,
  body,
}: TCreateBenefitCoverageMaterials): Promise<TCreateBenefitCoverageResponse> => {
  const response = await ApiService.post(`/benefit/coverage-option/new`, body, { params });
  return response.data;
};

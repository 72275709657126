import { TReportState } from '@/redux/reducers/report';
import { TGetReportMonthlyTimekeepingSuccess } from '@/redux/actions/report';

export const getReportMonthlyTimekeepingUpdateState = (
  state: TReportState,
  action: TGetReportMonthlyTimekeepingSuccess,
): TReportState => ({
  ...state,
  getReportMonthlyTimekeepingResponse: action.payload.response,
});

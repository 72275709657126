import { TBenefit } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetBenefitsParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
};

export type TGetBenefitsMaterials = {
  params?: TGetBenefitsParams;
};

export type TGetBenefitsResponse = TPaginateResponse & {
  data: TBenefit[];
};

// FUNCTION

export const getBenefits = async ({ params }: TGetBenefitsMaterials): Promise<TGetBenefitsResponse> => {
  const response = await ApiService.get(`/benefit/list`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateAssetCategoryAction } from '@/redux/actions';
import { updateAssetCategory, TUpdateAssetCategoryResponse } from '@/services/api';

// FUNCTION

export function* updateAssetCategorySaga(action: ActionType<typeof updateAssetCategoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateAssetCategory, materials);
    const updateAssetCategoryResponse: TUpdateAssetCategoryResponse = response as TUpdateAssetCategoryResponse;
    yield put(updateAssetCategoryAction.success(updateAssetCategoryResponse));
    successCallback?.(updateAssetCategoryResponse);
  } catch (err) {
    yield put(updateAssetCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import {
  TGetTimeOffHistoriesMaterials,
  TGetTimeOffHistoriesResponse,
} from '@/services/api/user/get-time-off-histories';

// CONSTANTS

export enum EGetTimeOffHistoriesAction {
  GET_TIME_OFF_HISTORIES = 'GET_TIME_OFF_HISTORIES',
  GET_TIME_OFF_HISTORIES_REQUEST = 'GET_TIME_OFF_HISTORIES_REQUEST',
  GET_TIME_OFF_HISTORIES_SUCCESS = 'GET_TIME_OFF_HISTORIES_SUCCESS',
  GET_TIME_OFF_HISTORIES_FAILED = 'GET_TIME_OFF_HISTORIES_FAILED',
}

// TYPES

export type TGetTimeOffHistoriesRequest = {
  type: EGetTimeOffHistoriesAction.GET_TIME_OFF_HISTORIES_REQUEST;
  payload: {
    materials: TGetTimeOffHistoriesMaterials;
    successCallback?: (response: TGetTimeOffHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTimeOffHistoriesSuccess = {
  type: EGetTimeOffHistoriesAction.GET_TIME_OFF_HISTORIES_SUCCESS;
  payload: { response: TGetTimeOffHistoriesResponse };
};

export type TGetTimeOffHistoriesFailed = { type: EGetTimeOffHistoriesAction.GET_TIME_OFF_HISTORIES_FAILED };

// FUNCTION

export const getTimeOffHistoriesAction = {
  request: createActionCreator(
    EGetTimeOffHistoriesAction.GET_TIME_OFF_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetTimeOffHistoriesMaterials,
        successCallback?: (response: TGetTimeOffHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTimeOffHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTimeOffHistoriesAction.GET_TIME_OFF_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetTimeOffHistoriesResponse): TGetTimeOffHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTimeOffHistoriesAction.GET_TIME_OFF_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetTimeOffHistoriesFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TDuplicateTaskMaterials, TDuplicateTaskResponse } from '@/services/api/template/duplicate-task';

// CONSTANTS

export enum EDuplicateTaskAction {
  DUPLICATE_TASK = 'DUPLICATE_TASK',
  DUPLICATE_TASK_REQUEST = 'DUPLICATE_TASK_REQUEST',
  DUPLICATE_TASK_SUCCESS = 'DUPLICATE_TASK_SUCCESS',
  DUPLICATE_TASK_FAILED = 'DUPLICATE_TASK_FAILED',
}

// TYPES

export type TDuplicateTaskRequest = {
  type: EDuplicateTaskAction.DUPLICATE_TASK_REQUEST;
  payload: {
    materials: TDuplicateTaskMaterials;
    successCallback?: (response: TDuplicateTaskResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDuplicateTaskSuccess = {
  type: EDuplicateTaskAction.DUPLICATE_TASK_SUCCESS;
  payload: { response: TDuplicateTaskResponse };
};

export type TDuplicateTaskFailed = { type: EDuplicateTaskAction.DUPLICATE_TASK_FAILED };

// FUNCTION

export const duplicateTaskAction = {
  request: createActionCreator(
    EDuplicateTaskAction.DUPLICATE_TASK_REQUEST,
    (resolve) =>
      (
        materials: TDuplicateTaskMaterials,
        successCallback?: (response: TDuplicateTaskResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDuplicateTaskRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDuplicateTaskAction.DUPLICATE_TASK_SUCCESS,
    (resolve) =>
      (response: TDuplicateTaskResponse): TDuplicateTaskSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDuplicateTaskAction.DUPLICATE_TASK_FAILED,
    (resolve) =>
      (error: unknown): TDuplicateTaskFailed =>
        resolve({ error }),
  ),
};

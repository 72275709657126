import React, { useEffect, useState } from 'react';
import { Select as AntdSelect } from 'antd';
import classNames from 'classnames';

import { getTotalPage, searchString } from '@/utils/functions';
import { useDebounce } from '@/utils/hooks';
import { ETimeoutDebounce } from '@/common/enums';
import WrapperLazyLoad from '@/components/WrapperLazyLoad';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import FormLabel from '@/components/FormLabel';
import Button, { EButtonStyleType } from '@/components/Button';

import { TSelectOption, TSelectProps } from './Select.types';
import './Select.scss';

const Select: React.FC<TSelectProps> = ({
  placeholder,
  disabled,
  options = [],
  showSearch,
  value,
  className,
  defaultValue,
  allowClear,
  dropdownClassName,
  paginate,
  size,
  label,
  required,
  passed,
  failed,
  readOnly,
  styleColor,
  addOption,
  keepLabelEnable,
  showOnlyText,
  tooltip,
  showArrow = true,
  onAddOption,
  getPopupContainer,
  customSelectionRender,
  onSearch,
  onLoadMore,
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const searchValueDebounce = useDebounce(keyword, ETimeoutDebounce.SEARCH);

  const filterOptions = options.filter((item) => !item.hide);

  const filterOption = (input: string, option: any): boolean => {
    return searchString(option.label, keyword);
  };

  const handleSearch = (keywordValue: string): void => {
    setKeyword(keywordValue);
  };

  const handleChange = (option: TSelectOption | null): void => {
    onChange?.(option);
    setOpen(false);
  };

  const handleDropdownVisibleChange = (visible: boolean): void => {
    setOpen(visible);
  };

  const dropdownRender = (menu: React.ReactElement): React.ReactElement => {
    return (
      <div className={classNames('Select-dropdown-wrapper', dropdownClassName)}>
        <div className="Select-dropdown-main">
          <WrapperLazyLoad maxHeight={256} onEnd={handleScrollEnd}>
            {addOption && onAddOption && (
              <div className="Select-dropdown-main-add-option flex">
                <Button
                  title="Add Category"
                  noPadding
                  iconName={EIconName.Plus}
                  iconColor={EIconColor.BLUE_500}
                  styleType={EButtonStyleType.TEXT_PRIMARY}
                  onClick={onAddOption}
                />
              </div>
            )}
            {customSelectionRender && filterOptions.length > 0
              ? customSelectionRender?.(filterOptions, value, handleChange)
              : menu}
          </WrapperLazyLoad>
        </div>
      </div>
    );
  };

  const handleScrollEnd = (): void => {
    if (onLoadMore && paginate) {
      const isLoadMore = paginate.page < getTotalPage(paginate.total, paginate.pageSize);
      if (isLoadMore) {
        onLoadMore?.();
      }
    }
  };

  const handleClear = (): void => {
    handleChange?.(null);
  };

  useEffect(() => {
    if (isMounted && onSearch) {
      onSearch?.(searchValueDebounce);
    }

    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueDebounce]);

  return (
    <div className={classNames('Select', className, styleColor, { 'show-only-text': showOnlyText })}>
      <FormLabel
        keepLabelEnable={keepLabelEnable}
        disabled={disabled}
        label={label}
        required={required}
        passed={passed}
        failed={failed}
        tooltip={tooltip}
      />

      <AntdSelect
        value={value}
        showSearch={!readOnly && showSearch}
        placeholder={placeholder}
        defaultValue={defaultValue}
        labelInValue
        size={size}
        open={readOnly ? false : open}
        allowClear={allowClear}
        clearIcon={<Icon className="Select-clear-icon" name={EIconName.X} color={EIconColor.GRAYSCALE_900} />}
        filterOption={onSearch ? false : filterOption}
        onSearch={showSearch ? handleSearch : undefined}
        options={filterOptions}
        searchValue={keyword}
        suffixIcon={
          readOnly || disabled || showOnlyText || !showArrow ? (
            <></>
          ) : (
            <Icon name={EIconName.AngleDown} color={EIconColor.GRAYSCALE_900} />
          )
        }
        dropdownClassName={classNames('Select-dropdown', dropdownClassName)}
        getPopupContainer={getPopupContainer}
        onChange={handleChange}
        onClear={handleClear}
        dropdownRender={dropdownRender}
        disabled={disabled}
        onDropdownVisibleChange={(visible): void => handleDropdownVisibleChange(visible)}
        virtual={false}
      />
    </div>
  );
};

export default Select;

import { TMyBenefitHistory } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetUserBenefitsHistoryPaths = {
  id?: string | number;
};
export type TGetUserBenefitsHistoryParams = {
  page: number;
  pageSize: number;
  sort?: string;
  startDate?: string;
  endDate?: string;
};

export type TGetUserBenefitsHistoryMaterials = {
  paths?: TGetUserBenefitsHistoryPaths;
  params?: TGetUserBenefitsHistoryParams;
};

export type TGetUserBenefitsHistoryResponse = TPaginateResponse & {
  data: TMyBenefitHistory[];
};

// FUNCTION

export const getUserBenefitsHistory = async ({
  paths,
  params,
}: TGetUserBenefitsHistoryMaterials): Promise<TGetUserBenefitsHistoryResponse> => {
  const response = await ApiService.get(`/benefit/histories/${paths?.id || 'my-info'}`, { params });
  return response.data;
};

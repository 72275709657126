import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getBalanceHistoriesAction } from '@/redux/actions';
import { getBalanceHistories, TGetBalanceHistoriesResponse } from '@/services/api';

// FUNCTION

export function* getBalanceHistoriesSaga(action: ActionType<typeof getBalanceHistoriesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getBalanceHistories, materials);
    const getBalanceHistoriesResponse: TGetBalanceHistoriesResponse = response as TGetBalanceHistoriesResponse;
    yield put(getBalanceHistoriesAction.success(getBalanceHistoriesResponse));
    successCallback?.(getBalanceHistoriesResponse);
  } catch (err) {
    yield put(getBalanceHistoriesAction.failure(err));
    failedCallback?.(err);
  }
}

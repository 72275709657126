import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportHeadcountAction } from '@/redux/actions';
import { getReportHeadcount, TGetReportHeadcountResponse } from '@/services/api';

// FUNCTION

export function* getReportHeadcountSaga(action: ActionType<typeof getReportHeadcountAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportHeadcount, materials);
    const getReportHeadcountResponse: TGetReportHeadcountResponse = response as TGetReportHeadcountResponse;
    yield put(getReportHeadcountAction.success(getReportHeadcountResponse));
    successCallback?.(getReportHeadcountResponse);
  } catch (err) {
    yield put(getReportHeadcountAction.failure(err));
    failedCallback?.(err);
  }
}

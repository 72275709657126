import { createActionCreator } from 'deox';

import { TGetIconTimeOffMaterials, TGetIconTimeOffResponse } from '@/services/api/widen/get-icon-time-off';

// CONSTANTS

export enum EGetIconTimeOffAction {
  GET_ICON_TIME_OFF = 'GET_ICON_TIME_OFF',
  GET_ICON_TIME_OFF_REQUEST = 'GET_ICON_TIME_OFF_REQUEST',
  GET_ICON_TIME_OFF_SUCCESS = 'GET_ICON_TIME_OFF_SUCCESS',
  GET_ICON_TIME_OFF_FAILED = 'GET_ICON_TIME_OFF_FAILED',
}

// TYPES

export type TGetIconTimeOffRequest = {
  type: EGetIconTimeOffAction.GET_ICON_TIME_OFF_REQUEST;
  payload: {
    materials: TGetIconTimeOffMaterials;
    successCallback?: (response: TGetIconTimeOffResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetIconTimeOffSuccess = {
  type: EGetIconTimeOffAction.GET_ICON_TIME_OFF_SUCCESS;
  payload: { response: TGetIconTimeOffResponse };
};

export type TGetIconTimeOffFailed = { type: EGetIconTimeOffAction.GET_ICON_TIME_OFF_FAILED };

// FUNCTION

export const getIconTimeOffAction = {
  request: createActionCreator(
    EGetIconTimeOffAction.GET_ICON_TIME_OFF_REQUEST,
    (resolve) =>
      (
        materials: TGetIconTimeOffMaterials,
        successCallback?: (response: TGetIconTimeOffResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetIconTimeOffRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetIconTimeOffAction.GET_ICON_TIME_OFF_SUCCESS,
    (resolve) =>
      (response: TGetIconTimeOffResponse): TGetIconTimeOffSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetIconTimeOffAction.GET_ICON_TIME_OFF_FAILED,
    (resolve) =>
      (error: unknown): TGetIconTimeOffFailed =>
        resolve({ error }),
  ),
};

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { localeName } from '@/common/constants';

import { resources } from './resources';

i18n.use(initReactI18next).init({
  fallbackLng: localeName.EN,
  resources,
});

export default i18n;

import ApiService from '@/services/api';

// TYPES

export type TAddRoleMemberPaths = {
  id: string | number;
};
export type TAddRoleMemberParams = unknown;
export type TAddRoleMemberBody = unknown;

export type TAddRoleMemberMaterials = {
  paths?: TAddRoleMemberPaths;
  params?: TAddRoleMemberParams;
  body?: TAddRoleMemberBody;
};

export type TAddRoleMemberResponse = unknown;

// FUNCTION

export const addRoleMember = async ({
  paths,
  params,
  body,
}: TAddRoleMemberMaterials): Promise<TAddRoleMemberResponse> => {
  const response = await ApiService.post(`/role/members/${paths?.id}`, body, { params });
  return response.data;
};

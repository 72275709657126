import { createActionCreator } from 'deox';

import { TUpdateCompanyMaterials, TUpdateCompanyResponse } from '@/services/api/company/update-company';

// CONSTANTS

export enum EUpdateCompanyAction {
  UPDATE_COMPANY = 'UPDATE_COMPANY',
  UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED',
}

// TYPES

export type TUpdateCompanyRequest = {
  type: EUpdateCompanyAction.UPDATE_COMPANY_REQUEST;
  payload: {
    materials: TUpdateCompanyMaterials;
    successCallback?: (response: TUpdateCompanyResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateCompanySuccess = {
  type: EUpdateCompanyAction.UPDATE_COMPANY_SUCCESS;
  payload: { response: TUpdateCompanyResponse };
};

export type TUpdateCompanyFailed = { type: EUpdateCompanyAction.UPDATE_COMPANY_FAILED };

// FUNCTION

export const updateCompanyAction = {
  request: createActionCreator(
    EUpdateCompanyAction.UPDATE_COMPANY_REQUEST,
    (resolve) =>
      (
        materials: TUpdateCompanyMaterials,
        successCallback?: (response: TUpdateCompanyResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateCompanyRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateCompanyAction.UPDATE_COMPANY_SUCCESS,
    (resolve) =>
      (response: TUpdateCompanyResponse): TUpdateCompanySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateCompanyAction.UPDATE_COMPANY_FAILED,
    (resolve) =>
      (error: unknown): TUpdateCompanyFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TGetTimeOffMaterials, TGetTimeOffResponse } from '@/services/api/time-off-type/get-time-off';

// CONSTANTS

export enum EGetTimeOffAction {
  GET_TIME_OFF = 'GET_TIME_OFF',
  GET_TIME_OFF_REQUEST = 'GET_TIME_OFF_REQUEST',
  GET_TIME_OFF_SUCCESS = 'GET_TIME_OFF_SUCCESS',
  GET_TIME_OFF_FAILED = 'GET_TIME_OFF_FAILED',
}

// TYPES

export type TGetTimeOffRequest = {
  type: EGetTimeOffAction.GET_TIME_OFF_REQUEST;
  payload: {
    materials: TGetTimeOffMaterials;
    successCallback?: (response: TGetTimeOffResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTimeOffSuccess = {
  type: EGetTimeOffAction.GET_TIME_OFF_SUCCESS;
  payload: { response: TGetTimeOffResponse };
};

export type TGetTimeOffFailed = { type: EGetTimeOffAction.GET_TIME_OFF_FAILED };

// FUNCTION

export const getTimeOffAction = {
  request: createActionCreator(
    EGetTimeOffAction.GET_TIME_OFF_REQUEST,
    (resolve) =>
      (
        materials: TGetTimeOffMaterials,
        successCallback?: (response: TGetTimeOffResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTimeOffRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTimeOffAction.GET_TIME_OFF_SUCCESS,
    (resolve) =>
      (response: TGetTimeOffResponse): TGetTimeOffSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTimeOffAction.GET_TIME_OFF_FAILED,
    (resolve) =>
      (error: unknown): TGetTimeOffFailed =>
        resolve({ error }),
  ),
};

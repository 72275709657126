import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Input as AntdInput } from 'antd';

import { TInputProps } from '@/components/Input/Input.types';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import FormLabel from '@/components/FormLabel';
import { ETimeoutDebounce } from '@/common/enums';
import { useDebounce } from '@/utils/hooks';
import { formatNumberWithCommas } from '@/utils/functions';

import './Input.scss';

const Input: React.FC<TInputProps> = ({
  className,
  type,
  size,
  placeholder,
  prefix,
  suffix,
  value,
  allowClear = true,
  readOnly,
  noAffixBorder,
  label,
  disabled,
  maxLength,
  required,
  passed,
  failed,
  numberic,
  keepLabelEnable,
  useNumberOutput,
  showOnlyText,
  tooltip,
  useComma,
  onSearch,
  onChange,
  onEnter,
}) => {
  const [keyword, setKeyword] = useState<string>('');
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const searchValueDebounce = useDebounce(keyword, ETimeoutDebounce.SEARCH);

  const handleKeydown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      onEnter?.();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value: inputValue } = e.target;
    setKeyword(inputValue);

    if (numberic) {
      const reg = /^-?\d*(\d*)?$/;
      const isNumbericPass = reg.test(inputValue) || inputValue === '';
      if (useNumberOutput) {
        if (useComma) {
          onChange?.(Number(inputValue?.replaceAll(/[.,\s]/g, '')));
        } else {
          onChange?.(isNumbericPass ? Number(inputValue) : Number(value) || 0);
        }
      } else {
        onChange?.(isNumbericPass ? inputValue : value || '');
      }
    } else {
      onChange?.(inputValue);
    }
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) onSearch?.(searchValueDebounce || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueDebounce]);

  const commonProps = {
    type,
    size,
    placeholder,
    value: useComma ? formatNumberWithCommas(Number(value || 0)) : value,
    prefix: !showOnlyText && prefix && <div className="Input-prefix">{prefix}</div>,
    suffix: !showOnlyText && suffix && <div className="Input-suffix">{suffix}</div>,
    disabled,
    readOnly,
    maxLength,
    allowClear: allowClear && { clearIcon: <Icon name={EIconName.X} color={EIconColor.GRAYSCALE_900} /> },
    onChange: handleChange,
    onKeyDown: handleKeydown,
  };

  return (
    <div
      className={classNames(
        'Input',
        className,
        { affix: suffix || prefix, 'no-affix-border': noAffixBorder },
        { 'show-only-text': showOnlyText },
      )}
    >
      <FormLabel
        keepLabelEnable={keepLabelEnable}
        disabled={disabled}
        label={label}
        required={required}
        passed={passed}
        failed={failed}
        tooltip={tooltip}
      />

      {type === 'password' ? (
        <AntdInput.Password
          {...commonProps}
          iconRender={(visible): React.ReactNode => (
            <div>
              <Icon
                className="cursor-pointer"
                name={visible ? EIconName.EyeClosed : EIconName.Eye}
                color={EIconColor.BLACK}
              />
            </div>
          )}
        />
      ) : (
        <AntdInput {...commonProps} />
      )}
    </div>
  );
};

export default Input;

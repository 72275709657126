import ApiService from '@/services/api';

// TYPES

export type TDeleteTypeWorkPaths = {
  id: string | number;
};
export type TDeleteTypeWorkParams = unknown;

export type TDeleteTypeWorkMaterials = {
  paths?: TDeleteTypeWorkPaths;
  params?: TDeleteTypeWorkParams;
};

export type TDeleteTypeWorkResponse = unknown;

// FUNCTION

export const deleteTypeWork = async ({ paths, params }: TDeleteTypeWorkMaterials): Promise<TDeleteTypeWorkResponse> => {
  const response = await ApiService.delete(`/type-work/${paths?.id}`, { params });
  return response.data;
};

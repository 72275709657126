import { createActionCreator } from 'deox';

import { TReinviteUserMaterials, TReinviteUserResponse } from '@/services/api/user/reinvite-user';

// CONSTANTS

export enum EReinviteUserAction {
  REINVITE_USER = 'REINVITE_USER',
  REINVITE_USER_REQUEST = 'REINVITE_USER_REQUEST',
  REINVITE_USER_SUCCESS = 'REINVITE_USER_SUCCESS',
  REINVITE_USER_FAILED = 'REINVITE_USER_FAILED',
}

// TYPES

export type TReinviteUserRequest = {
  type: EReinviteUserAction.REINVITE_USER_REQUEST;
  payload: {
    materials: TReinviteUserMaterials;
    successCallback?: (response: TReinviteUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TReinviteUserSuccess = {
  type: EReinviteUserAction.REINVITE_USER_SUCCESS;
  payload: { response: TReinviteUserResponse };
};

export type TReinviteUserFailed = { type: EReinviteUserAction.REINVITE_USER_FAILED };

// FUNCTION

export const reinviteUserAction = {
  request: createActionCreator(
    EReinviteUserAction.REINVITE_USER_REQUEST,
    (resolve) =>
      (
        materials: TReinviteUserMaterials,
        successCallback?: (response: TReinviteUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TReinviteUserRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EReinviteUserAction.REINVITE_USER_SUCCESS,
    (resolve) =>
      (response: TReinviteUserResponse): TReinviteUserSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EReinviteUserAction.REINVITE_USER_FAILED,
    (resolve) =>
      (error: unknown): TReinviteUserFailed =>
        resolve({ error }),
  ),
};

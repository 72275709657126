import { createActionCreator } from 'deox';

import { TCreateTemplateMaterials, TCreateTemplateResponse } from '@/services/api/template/create-template';

// CONSTANTS

export enum ECreateTemplateAction {
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_FAILED = 'CREATE_TEMPLATE_FAILED',
}

// TYPES

export type TCreateTemplateRequest = {
  type: ECreateTemplateAction.CREATE_TEMPLATE_REQUEST;
  payload: {
    materials: TCreateTemplateMaterials;
    successCallback?: (response: TCreateTemplateResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateTemplateSuccess = {
  type: ECreateTemplateAction.CREATE_TEMPLATE_SUCCESS;
  payload: { response: TCreateTemplateResponse };
};

export type TCreateTemplateFailed = { type: ECreateTemplateAction.CREATE_TEMPLATE_FAILED };

// FUNCTION

export const createTemplateAction = {
  request: createActionCreator(
    ECreateTemplateAction.CREATE_TEMPLATE_REQUEST,
    (resolve) =>
      (
        materials: TCreateTemplateMaterials,
        successCallback?: (response: TCreateTemplateResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateTemplateRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateTemplateAction.CREATE_TEMPLATE_SUCCESS,
    (resolve) =>
      (response: TCreateTemplateResponse): TCreateTemplateSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateTemplateAction.CREATE_TEMPLATE_FAILED,
    (resolve) =>
      (error: unknown): TCreateTemplateFailed =>
        resolve({ error }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 13.8965C10.5899 13.8965 13.5 10.9863 13.5 7.39648C13.5 3.80663 10.5899 0.896484 7 0.896484C3.41015 0.896484 0.5 3.80663 0.5 7.39648C0.5 10.9863 3.41015 13.8965 7 13.8965Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4 8.39648L6.05 10.0365C6.10506 10.0818 6.16952 10.1143 6.23872 10.1316C6.30791 10.1489 6.38009 10.1506 6.45 10.1365C6.52058 10.1232 6.58749 10.095 6.64616 10.0535C6.70483 10.0121 6.75389 9.95856 6.79 9.89648L10 4.39648"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Svg;

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 12C14.6924 12 16.875 9.81739 16.875 7.125C16.875 4.43261 14.6924 2.25 12 2.25C9.30761 2.25 7.125 4.43261 7.125 7.125C7.125 9.81739 9.30761 12 12 12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2705 21.75C20.6407 19.7863 19.4037 18.0733 17.7376 16.858C16.0716 15.6427 14.0627 14.9878 12.0005 14.9878C9.93826 14.9878 7.92933 15.6427 6.26329 16.858C4.59726 18.0733 3.3602 19.7863 2.73047 21.75H21.2705Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

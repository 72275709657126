import ApiService from '@/services/api';

// TYPES

export type TCancelRequestPaths = {
  id: string | number;
};
export type TCancelRequestBody = unknown;

export type TCancelRequestMaterials = {
  paths?: TCancelRequestPaths;
  body?: TCancelRequestBody;
};

export type TCancelRequestResponse = unknown;

// FUNCTION

export const cancelRequest = async ({ paths, body }: TCancelRequestMaterials): Promise<TCancelRequestResponse> => {
  const response = await ApiService.patch(`/request/management/cancel-request/${paths?.id}`, body);
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TUpdateMyInfoBody = unknown;

export type TUpdateMyInfoMaterials = {
  body?: TUpdateMyInfoBody;
};

export type TUpdateMyInfoResponse = unknown;

// FUNCTION

export const updateMyInfo = async ({ body }: TUpdateMyInfoMaterials): Promise<TUpdateMyInfoResponse> => {
  const response = await ApiService.patch(`/user/detail/my-info`, body);
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7411 4.68936C19.5182 3.98146 18.8618 3.5 18.1196 3.5H6.99903C6.26382 3.5 5.61191 3.97263 5.3833 4.6714L2.4539 13.6254C2.31915 14.0373 2.34679 14.4851 2.53116 14.8773L4.71535 19.5233C4.99562 20.1194 5.59507 20.5 6.25382 20.5H18.7474C19.4062 20.5 20.0056 20.1194 20.2859 19.5233L22.4747 14.8674C22.6563 14.4811 22.686 14.0406 22.5577 13.6335L19.7411 4.68936Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M22.7572 13.6635H17.4237C17.0605 13.6635 16.7374 13.8942 16.6196 14.2378L16.0411 15.9248C15.9233 16.2683 15.6002 16.499 15.237 16.499H9.70358C9.3155 16.499 8.9767 16.2362 8.88025 15.8603L8.48047 14.3022C8.38402 13.9263 8.04522 13.6635 7.65714 13.6635H2.24414"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

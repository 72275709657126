import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createAssetCategoryAction } from '@/redux/actions';
import { createAssetCategory, TCreateAssetCategoryResponse } from '@/services/api';

// FUNCTION

export function* createAssetCategorySaga(action: ActionType<typeof createAssetCategoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createAssetCategory, materials);
    const createAssetCategoryResponse: TCreateAssetCategoryResponse = response as TCreateAssetCategoryResponse;
    yield put(createAssetCategoryAction.success(createAssetCategoryResponse));
    successCallback?.(createAssetCategoryResponse);
  } catch (err) {
    yield put(createAssetCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRequestManagementAction } from '@/redux/actions';
import { getRequestManagement, TGetRequestManagementResponse } from '@/services/api';

// FUNCTION

export function* getRequestManagementSaga(action: ActionType<typeof getRequestManagementAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRequestManagement, materials);
    const getRequestManagementResponse: TGetRequestManagementResponse = response as TGetRequestManagementResponse;
    yield put(getRequestManagementAction.success(getRequestManagementResponse));
    successCallback?.(getRequestManagementResponse);
  } catch (err) {
    yield put(getRequestManagementAction.failure(err));
    failedCallback?.(err);
  }
}

import { THoliday } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetHolidaysParams = {
  page: number;
  pageSize: number;
  sort?: string;
  year?: string;
};

export type TGetHolidaysMaterials = {
  params?: TGetHolidaysParams;
};

export type TGetHolidaysResponse = TPaginateResponse & {
  data: THoliday[];
};

// FUNCTION

export const getHolidays = async ({ params }: TGetHolidaysMaterials): Promise<TGetHolidaysResponse> => {
  const response = await ApiService.get(`/holiday/list`, { params });
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_400 }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0497 2.29572C10.1149 1.78952 9.03087 1.5 7.875 1.5C4.35418 1.5 1.5 4.18629 1.5 7.5C1.5 8.5992 1.81405 9.62936 2.36232 10.5153C2.28836 10.9994 2.25 11.4952 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C12.481 21.75 12.9538 21.7152 13.4162 21.6479C14.3174 22.1889 15.3724 22.5 16.5 22.5C19.8137 22.5 22.5 19.8137 22.5 16.5C22.5 15.3724 22.1889 14.3174 21.6479 13.4162C21.7152 12.9538 21.75 12.481 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C11.6794 2.25 11.3624 2.26548 11.0497 2.29572Z"
        fill={color}
      />
      <path
        d="M12.0516 6.75C13.6366 6.75 15.75 7.5 15.2217 8.5C14.4995 9.8668 13.1083 8.5 12.0516 8.5C10.9949 8.5 10.4665 9 10.4665 9.5C10.4665 10 10.7307 10.5 12.0516 11C13.3724 11.5 15.75 12.25 15.75 14.25C15.75 16.25 13.9008 17.25 11.5232 17.25C9.39339 17.25 7.8248 16.25 8.35315 15.25C8.96075 14.1 10.4665 15.5 11.5232 15.5C12.5799 15.5 13.6366 15.25 13.6366 14.25C13.6366 13.25 12.0516 13 10.9949 12.5C9.67398 11.875 8.35313 11.25 8.35313 9.5C8.35313 7.75 10.4665 6.75 12.0516 6.75Z"
        fill={EIconColor.WHITE}
      />
    </svg>
  );
};

export default Svg;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportEmployeeDataAction } from '@/redux/actions';
import { getReportEmployeeData, TGetReportEmployeeDataResponse } from '@/services/api';

// FUNCTION

export function* getReportEmployeeDataSaga(action: ActionType<typeof getReportEmployeeDataAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportEmployeeData, materials);
    const getReportEmployeeDataResponse: TGetReportEmployeeDataResponse = response as TGetReportEmployeeDataResponse;
    yield put(getReportEmployeeDataAction.success(getReportEmployeeDataResponse));
    successCallback?.(getReportEmployeeDataResponse);
  } catch (err) {
    yield put(getReportEmployeeDataAction.failure(err));
    failedCallback?.(err);
  }
}

import { TTimeOffTypeState } from '@/redux/reducers/time-off-type';
import { TDeleteTimeOffTypeSuccess } from '@/redux/actions/time-off-type';

export const deleteTimeOffTypeUpdateState = (
  state: TTimeOffTypeState,
  action: TDeleteTimeOffTypeSuccess,
): TTimeOffTypeState => ({
  ...state,
  deleteTimeOffTypeResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import {
  TUpdateRegulateCategoryMaterials,
  TUpdateRegulateCategoryResponse,
} from '@/services/api/regulate-category/update-regulate-category';

// CONSTANTS

export enum EUpdateRegulateCategoryAction {
  UPDATE_REGULATE_CATEGORY = 'UPDATE_REGULATE_CATEGORY',
  UPDATE_REGULATE_CATEGORY_REQUEST = 'UPDATE_REGULATE_CATEGORY_REQUEST',
  UPDATE_REGULATE_CATEGORY_SUCCESS = 'UPDATE_REGULATE_CATEGORY_SUCCESS',
  UPDATE_REGULATE_CATEGORY_FAILED = 'UPDATE_REGULATE_CATEGORY_FAILED',
}

// TYPES

export type TUpdateRegulateCategoryRequest = {
  type: EUpdateRegulateCategoryAction.UPDATE_REGULATE_CATEGORY_REQUEST;
  payload: {
    materials: TUpdateRegulateCategoryMaterials;
    successCallback?: (response: TUpdateRegulateCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateRegulateCategorySuccess = {
  type: EUpdateRegulateCategoryAction.UPDATE_REGULATE_CATEGORY_SUCCESS;
  payload: { response: TUpdateRegulateCategoryResponse };
};

export type TUpdateRegulateCategoryFailed = { type: EUpdateRegulateCategoryAction.UPDATE_REGULATE_CATEGORY_FAILED };

// FUNCTION

export const updateRegulateCategoryAction = {
  request: createActionCreator(
    EUpdateRegulateCategoryAction.UPDATE_REGULATE_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TUpdateRegulateCategoryMaterials,
        successCallback?: (response: TUpdateRegulateCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateRegulateCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateRegulateCategoryAction.UPDATE_REGULATE_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TUpdateRegulateCategoryResponse): TUpdateRegulateCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateRegulateCategoryAction.UPDATE_REGULATE_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TUpdateRegulateCategoryFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TRequestReturnAssetMaterials, TRequestReturnAssetResponse } from '@/services/api/request/request-return-asset';

// CONSTANTS

export enum ERequestReturnAssetAction {
  REQUEST_RETURN_ASSET = 'REQUEST_RETURN_ASSET',
  REQUEST_RETURN_ASSET_REQUEST = 'REQUEST_RETURN_ASSET_REQUEST',
  REQUEST_RETURN_ASSET_SUCCESS = 'REQUEST_RETURN_ASSET_SUCCESS',
  REQUEST_RETURN_ASSET_FAILED = 'REQUEST_RETURN_ASSET_FAILED',
}

// TYPES

export type TRequestReturnAssetRequest = {
  type: ERequestReturnAssetAction.REQUEST_RETURN_ASSET_REQUEST;
  payload: {
    materials: TRequestReturnAssetMaterials;
    successCallback?: (response: TRequestReturnAssetResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRequestReturnAssetSuccess = {
  type: ERequestReturnAssetAction.REQUEST_RETURN_ASSET_SUCCESS;
  payload: { response: TRequestReturnAssetResponse };
};

export type TRequestReturnAssetFailed = { type: ERequestReturnAssetAction.REQUEST_RETURN_ASSET_FAILED };

// FUNCTION

export const requestReturnAssetAction = {
  request: createActionCreator(
    ERequestReturnAssetAction.REQUEST_RETURN_ASSET_REQUEST,
    (resolve) =>
      (
        materials: TRequestReturnAssetMaterials,
        successCallback?: (response: TRequestReturnAssetResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRequestReturnAssetRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERequestReturnAssetAction.REQUEST_RETURN_ASSET_SUCCESS,
    (resolve) =>
      (response: TRequestReturnAssetResponse): TRequestReturnAssetSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERequestReturnAssetAction.REQUEST_RETURN_ASSET_FAILED,
    (resolve) =>
      (error: unknown): TRequestReturnAssetFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TChangeStatusBenefitPaths = {
  id: string | number;
};
export type TChangeStatusBenefitBody = unknown;

export type TChangeStatusBenefitMaterials = {
  paths?: TChangeStatusBenefitPaths;
  body?: TChangeStatusBenefitBody;
};

export type TChangeStatusBenefitResponse = unknown;

// FUNCTION

export const changeStatusBenefit = async ({
  paths,
  body,
}: TChangeStatusBenefitMaterials): Promise<TChangeStatusBenefitResponse> => {
  const response = await ApiService.patch(`/benefit/${paths?.id}`, body);
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateDepartmentAction } from '@/redux/actions';
import { updateDepartment, TUpdateDepartmentResponse } from '@/services/api';

// FUNCTION

export function* updateDepartmentSaga(action: ActionType<typeof updateDepartmentAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateDepartment, materials);
    const updateDepartmentResponse: TUpdateDepartmentResponse = response as TUpdateDepartmentResponse;
    yield put(updateDepartmentAction.success(updateDepartmentResponse));
    successCallback?.(updateDepartmentResponse);
  } catch (err) {
    yield put(updateDepartmentAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TGetAssetsMaterials, TGetAssetsResponse } from '@/services/api/asset/get-assets';

// CONSTANTS

export enum EGetAssetsAction {
  GET_ASSETS = 'GET_ASSETS',
  GET_ASSETS_REQUEST = 'GET_ASSETS_REQUEST',
  GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS',
  GET_ASSETS_FAILED = 'GET_ASSETS_FAILED',
}

// TYPES

export type TGetAssetsRequest = {
  type: EGetAssetsAction.GET_ASSETS_REQUEST;
  payload: {
    materials: TGetAssetsMaterials;
    successCallback?: (response: TGetAssetsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAssetsSuccess = {
  type: EGetAssetsAction.GET_ASSETS_SUCCESS;
  payload: { response: TGetAssetsResponse };
};

export type TGetAssetsFailed = { type: EGetAssetsAction.GET_ASSETS_FAILED };

// FUNCTION

export const getAssetsAction = {
  request: createActionCreator(
    EGetAssetsAction.GET_ASSETS_REQUEST,
    (resolve) =>
      (
        materials: TGetAssetsMaterials,
        successCallback?: (response: TGetAssetsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAssetsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAssetsAction.GET_ASSETS_SUCCESS,
    (resolve) =>
      (response: TGetAssetsResponse): TGetAssetsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAssetsAction.GET_ASSETS_FAILED,
    (resolve) =>
      (error: unknown): TGetAssetsFailed =>
        resolve({ error }),
  ),
};

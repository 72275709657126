import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.125 3.82715C2.79348 3.82715 2.47554 3.95885 2.24112 4.19326C2.0067 4.42769 1.875 4.74562 1.875 5.07715V16.9521C1.875 17.2836 2.0067 17.6016 2.24112 17.836C2.47554 18.0704 2.79348 18.2021 3.125 18.2021H16.875C17.2065 18.2021 17.5245 18.0704 17.7589 17.836C17.9932 17.6016 18.125 17.2836 18.125 16.9521V5.07715C18.125 4.74562 17.9932 4.42769 17.7589 4.19326C17.5245 3.95885 17.2065 3.82715 16.875 3.82715H14.375"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.875 8.20215H18.125" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.625 1.95215V5.70215" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.375 1.95215V5.70215"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.625 3.82715H11.875" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Svg;

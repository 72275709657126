import { TReportViolationState } from '@/redux/reducers/report-violation';
import { TDeleteReportViolationSuccess } from '@/redux/actions/report-violation';

export const deleteReportViolationUpdateState = (
  state: TReportViolationState,
  action: TDeleteReportViolationSuccess,
): TReportViolationState => ({
  ...state,
  deleteReportViolationResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import { TCreateTypeWorkMaterials, TCreateTypeWorkResponse } from '@/services/api/type-work/create-type-work';

// CONSTANTS

export enum ECreateTypeWorkAction {
  CREATE_TYPE_WORK = 'CREATE_TYPE_WORK',
  CREATE_TYPE_WORK_REQUEST = 'CREATE_TYPE_WORK_REQUEST',
  CREATE_TYPE_WORK_SUCCESS = 'CREATE_TYPE_WORK_SUCCESS',
  CREATE_TYPE_WORK_FAILED = 'CREATE_TYPE_WORK_FAILED',
}

// TYPES

export type TCreateTypeWorkRequest = {
  type: ECreateTypeWorkAction.CREATE_TYPE_WORK_REQUEST;
  payload: {
    materials: TCreateTypeWorkMaterials;
    successCallback?: (response: TCreateTypeWorkResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateTypeWorkSuccess = {
  type: ECreateTypeWorkAction.CREATE_TYPE_WORK_SUCCESS;
  payload: { response: TCreateTypeWorkResponse };
};

export type TCreateTypeWorkFailed = { type: ECreateTypeWorkAction.CREATE_TYPE_WORK_FAILED };

// FUNCTION

export const createTypeWorkAction = {
  request: createActionCreator(
    ECreateTypeWorkAction.CREATE_TYPE_WORK_REQUEST,
    (resolve) =>
      (
        materials: TCreateTypeWorkMaterials,
        successCallback?: (response: TCreateTypeWorkResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateTypeWorkRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateTypeWorkAction.CREATE_TYPE_WORK_SUCCESS,
    (resolve) =>
      (response: TCreateTypeWorkResponse): TCreateTypeWorkSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateTypeWorkAction.CREATE_TYPE_WORK_FAILED,
    (resolve) =>
      (error: unknown): TCreateTypeWorkFailed =>
        resolve({ error }),
  ),
};

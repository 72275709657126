import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_900 }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 10H6.125L7.375 8.125L9.875 11.875L11.125 10H13"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.69643 7.4995C2.69052 7.39588 2.68754 7.29188 2.6875 7.18751C2.68766 6.24855 3.01301 5.33863 3.60824 4.61244C4.20347 3.88626 5.03183 3.38863 5.95249 3.20418C6.87315 3.01972 7.82928 3.15982 8.65832 3.60066C9.48736 4.04149 10.1381 4.75585 10.5 5.62227L10.5 5.62228C10.8619 4.75585 11.5126 4.04149 12.3417 3.60066C13.1707 3.15983 14.1268 3.01973 15.0475 3.20418C15.9682 3.38863 16.7965 3.88625 17.3918 4.61244C17.987 5.33863 18.3123 6.24855 18.3125 7.18751C18.3125 12.5 10.5 16.875 10.5 16.875C10.5 16.875 7.37932 15.1274 5.08262 12.5001"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

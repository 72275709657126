import ApiService from '@/services/api';

// TYPES

export type TDeleteAssetPaths = {
  id: string | number;
};
export type TDeleteAssetParams = unknown;

export type TDeleteAssetMaterials = {
  paths?: TDeleteAssetPaths;
  params?: TDeleteAssetParams;
};

export type TDeleteAssetResponse = unknown;

// FUNCTION

export const deleteAsset = async ({ paths, params }: TDeleteAssetMaterials): Promise<TDeleteAssetResponse> => {
  const response = await ApiService.delete(`/asset/${paths?.id}`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TCreateTypeWorkParams = unknown;
export type TCreateTypeWorkBody = unknown;

export type TCreateTypeWorkMaterials = {
  params?: TCreateTypeWorkParams;
  body?: TCreateTypeWorkBody;
};

export type TCreateTypeWorkResponse = unknown;

// FUNCTION

export const createTypeWork = async ({ params, body }: TCreateTypeWorkMaterials): Promise<TCreateTypeWorkResponse> => {
  const response = await ApiService.post(`/type-work/new`, body, { params });
  return response.data;
};

import { TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportEmployeeTurnoverRateParams = {
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  employType?: string;
  department?: string;
  job?: string;
  sort?: string;
};

export type TGetReportEmployeeTurnoverRateMaterials = {
  params?: TGetReportEmployeeTurnoverRateParams;
};

export type TGetReportEmployeeTurnoverRateResponse = {
  chart: { year: number; month: number; totalIn: string; totalOut: string }[];
  data: TPaginateResponse & {
    data: TUser[];
  };
};

// FUNCTION

export const getReportEmployeeTurnoverRate = async ({
  params,
}: TGetReportEmployeeTurnoverRateMaterials): Promise<TGetReportEmployeeTurnoverRateResponse> => {
  const response = await ApiService.get(`/report/employee-turnover-rate`, { params });
  return response.data;
};

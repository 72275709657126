import { TReportState } from '@/redux/reducers/report';
import { TGetReportEmployeeDataSuccess } from '@/redux/actions/report';

export const getReportEmployeeDataUpdateState = (
  state: TReportState,
  action: TGetReportEmployeeDataSuccess,
): TReportState => ({
  ...state,
  getReportEmployeeDataResponse: action.payload.response,
});

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteTypeWorkAction } from '@/redux/actions';
import { deleteTypeWork, TDeleteTypeWorkResponse } from '@/services/api';

// FUNCTION

export function* deleteTypeWorkSaga(action: ActionType<typeof deleteTypeWorkAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteTypeWork, materials);
    const deleteTypeWorkResponse: TDeleteTypeWorkResponse = response as TDeleteTypeWorkResponse;
    yield put(deleteTypeWorkAction.success(deleteTypeWorkResponse));
    successCallback?.(deleteTypeWorkResponse);
  } catch (err) {
    yield put(deleteTypeWorkAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import {
  TUpdateAssetCategoryMaterials,
  TUpdateAssetCategoryResponse,
} from '@/services/api/asset/update-asset-category';

// CONSTANTS

export enum EUpdateAssetCategoryAction {
  UPDATE_ASSET_CATEGORY = 'UPDATE_ASSET_CATEGORY',
  UPDATE_ASSET_CATEGORY_REQUEST = 'UPDATE_ASSET_CATEGORY_REQUEST',
  UPDATE_ASSET_CATEGORY_SUCCESS = 'UPDATE_ASSET_CATEGORY_SUCCESS',
  UPDATE_ASSET_CATEGORY_FAILED = 'UPDATE_ASSET_CATEGORY_FAILED',
}

// TYPES

export type TUpdateAssetCategoryRequest = {
  type: EUpdateAssetCategoryAction.UPDATE_ASSET_CATEGORY_REQUEST;
  payload: {
    materials: TUpdateAssetCategoryMaterials;
    successCallback?: (response: TUpdateAssetCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateAssetCategorySuccess = {
  type: EUpdateAssetCategoryAction.UPDATE_ASSET_CATEGORY_SUCCESS;
  payload: { response: TUpdateAssetCategoryResponse };
};

export type TUpdateAssetCategoryFailed = { type: EUpdateAssetCategoryAction.UPDATE_ASSET_CATEGORY_FAILED };

// FUNCTION

export const updateAssetCategoryAction = {
  request: createActionCreator(
    EUpdateAssetCategoryAction.UPDATE_ASSET_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TUpdateAssetCategoryMaterials,
        successCallback?: (response: TUpdateAssetCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateAssetCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateAssetCategoryAction.UPDATE_ASSET_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TUpdateAssetCategoryResponse): TUpdateAssetCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateAssetCategoryAction.UPDATE_ASSET_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TUpdateAssetCategoryFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteAssetCategoryAction } from '@/redux/actions';
import { deleteAssetCategory, TDeleteAssetCategoryResponse } from '@/services/api';

// FUNCTION

export function* deleteAssetCategorySaga(action: ActionType<typeof deleteAssetCategoryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteAssetCategory, materials);
    const deleteAssetCategoryResponse: TDeleteAssetCategoryResponse = response as TDeleteAssetCategoryResponse;
    yield put(deleteAssetCategoryAction.success(deleteAssetCategoryResponse));
    successCallback?.(deleteAssetCategoryResponse);
  } catch (err) {
    yield put(deleteAssetCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

import { TReportViolation } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetReportViolationPaths = {
  id: string | number;
};
export type TGetReportViolationParams = unknown;

export type TGetReportViolationMaterials = {
  paths?: TGetReportViolationPaths;
  params?: TGetReportViolationParams;
};

export type TGetReportViolationResponse = TReportViolation;

// FUNCTION

export const getReportViolation = async ({
  paths,
  params,
}: TGetReportViolationMaterials): Promise<TGetReportViolationResponse> => {
  const response = await ApiService.get(`/report-violation/${paths?.id}`, { params });
  return response.data;
};

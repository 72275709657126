import { TUserState } from '@/redux/reducers/user';
import { TGetMyCareerHistoriesSuccess } from '@/redux/actions/user';

export const getMyCareerHistoriesUpdateState = (
  state: TUserState,
  action: TGetMyCareerHistoriesSuccess,
): TUserState => ({
  ...state,
  getMyCareerHistoriesResponse: action.payload.response,
});

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getUserBenefitsAction } from '@/redux/actions';
import { getUserBenefits, TGetUserBenefitsResponse } from '@/services/api';

// FUNCTION

export function* getUserBenefitsSaga(action: ActionType<typeof getUserBenefitsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getUserBenefits, materials);
    const getUserBenefitsResponse: TGetUserBenefitsResponse = response as TGetUserBenefitsResponse;
    yield put(getUserBenefitsAction.success(getUserBenefitsResponse));
    successCallback?.(getUserBenefitsResponse);
  } catch (err) {
    yield put(getUserBenefitsAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TGetTaskMaterials, TGetTaskResponse } from '@/services/api/template/get-task';

// CONSTANTS

export enum EGetTaskAction {
  GET_TASK = 'GET_TASK',
  GET_TASK_REQUEST = 'GET_TASK_REQUEST',
  GET_TASK_SUCCESS = 'GET_TASK_SUCCESS',
  GET_TASK_FAILED = 'GET_TASK_FAILED',
}

// TYPES

export type TGetTaskRequest = {
  type: EGetTaskAction.GET_TASK_REQUEST;
  payload: {
    materials: TGetTaskMaterials;
    successCallback?: (response: TGetTaskResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTaskSuccess = {
  type: EGetTaskAction.GET_TASK_SUCCESS;
  payload: { response: TGetTaskResponse };
};

export type TGetTaskFailed = { type: EGetTaskAction.GET_TASK_FAILED };

// FUNCTION

export const getTaskAction = {
  request: createActionCreator(
    EGetTaskAction.GET_TASK_REQUEST,
    (resolve) =>
      (
        materials: TGetTaskMaterials,
        successCallback?: (response: TGetTaskResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTaskRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTaskAction.GET_TASK_SUCCESS,
    (resolve) =>
      (response: TGetTaskResponse): TGetTaskSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTaskAction.GET_TASK_FAILED,
    (resolve) =>
      (error: unknown): TGetTaskFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TMailFeedbackParams = unknown;
export type TMailFeedbackBody = unknown;

export type TMailFeedbackMaterials = {
  params?: TMailFeedbackParams;
  body?: TMailFeedbackBody;
};

export type TMailFeedbackResponse = unknown;

// FUNCTION

export const mailFeedback = async ({ params, body }: TMailFeedbackMaterials): Promise<TMailFeedbackResponse> => {
  const response = await ApiService.post(`/mail/feedback`, body, { params });
  return response.data;
};

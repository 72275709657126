import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="stroke"
        d="M5.5 5V2.25C5.5 1.83579 5.83579 1.5 6.25 1.5H14.25C14.6642 1.5 15 1.83579 15 2.25V10.25C15 10.6642 14.6642 11 14.25 11H11.5"
        stroke={color}
      />
      <path
        className="stroke"
        d="M2 5.75C2 5.33579 2.33579 5 2.75 5H10.75C11.1642 5 11.5 5.33579 11.5 5.75V13.75C11.5 14.1642 11.1642 14.5 10.75 14.5H2.75C2.33579 14.5 2 14.1642 2 13.75V5.75Z"
        stroke={color}
      />
    </svg>
  );
};

export default Svg;

import { TChecklistState } from '@/redux/reducers/checklist';
import { TUpdateAssignOnboardSuccess } from '@/redux/actions/checklist';

export const updateAssignOnboardUpdateState = (
  state: TChecklistState,
  action: TUpdateAssignOnboardSuccess,
): TChecklistState => ({
  ...state,
  updateAssignOnboardResponse: action.payload.response,
});

import { createActionCreator } from 'deox';

import { TGetReportOnboardMaterials, TGetReportOnboardResponse } from '@/services/api/report/get-report-onboard';

// CONSTANTS

export enum EGetReportOnboardAction {
  GET_REPORT_ONBOARD = 'GET_REPORT_ONBOARD',
  GET_REPORT_ONBOARD_REQUEST = 'GET_REPORT_ONBOARD_REQUEST',
  GET_REPORT_ONBOARD_SUCCESS = 'GET_REPORT_ONBOARD_SUCCESS',
  GET_REPORT_ONBOARD_FAILED = 'GET_REPORT_ONBOARD_FAILED',
}

// TYPES

export type TGetReportOnboardRequest = {
  type: EGetReportOnboardAction.GET_REPORT_ONBOARD_REQUEST;
  payload: {
    materials: TGetReportOnboardMaterials;
    successCallback?: (response: TGetReportOnboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetReportOnboardSuccess = {
  type: EGetReportOnboardAction.GET_REPORT_ONBOARD_SUCCESS;
  payload: { response: TGetReportOnboardResponse };
};

export type TGetReportOnboardFailed = { type: EGetReportOnboardAction.GET_REPORT_ONBOARD_FAILED };

// FUNCTION

export const getReportOnboardAction = {
  request: createActionCreator(
    EGetReportOnboardAction.GET_REPORT_ONBOARD_REQUEST,
    (resolve) =>
      (
        materials: TGetReportOnboardMaterials,
        successCallback?: (response: TGetReportOnboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetReportOnboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetReportOnboardAction.GET_REPORT_ONBOARD_SUCCESS,
    (resolve) =>
      (response: TGetReportOnboardResponse): TGetReportOnboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetReportOnboardAction.GET_REPORT_ONBOARD_FAILED,
    (resolve) =>
      (error: unknown): TGetReportOnboardFailed =>
        resolve({ error }),
  ),
};

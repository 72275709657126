import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6268 7.68188H14.1565C13.5609 7.68188 13.0781 8.1647 13.0781 8.76027V19.5442C13.0781 20.1397 13.5609 20.6225 14.1565 20.6225H20.6268C21.2224 20.6225 21.7052 20.1397 21.7052 19.5442V8.76027C21.7052 8.1647 21.2224 7.68188 20.6268 7.68188Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4707 7.68855V4.45339C18.4707 4.16738 18.3571 3.89309 18.1549 3.69085C17.9527 3.48862 17.6784 3.375 17.3924 3.375H3.37331C3.0873 3.375 2.81301 3.48862 2.61077 3.69085C2.40854 3.89309 2.29492 4.16738 2.29492 4.45339V17.394C2.29492 17.6801 2.40854 17.9543 2.61077 18.1566C2.81301 18.3588 3.0873 18.4724 3.37331 18.4724H13.0788"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3145 8.76562H18.4712"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

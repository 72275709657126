import ApiService from '@/services/api';

// TYPES

export type TContractTerminationRequestParams = unknown;
export type TContractTerminationRequestBody = unknown;

export type TContractTerminationRequestMaterials = {
  params?: TContractTerminationRequestParams;
  body?: TContractTerminationRequestBody;
};

export type TContractTerminationRequestResponse = unknown;

// FUNCTION

export const contractTerminationRequest = async ({
  params,
  body,
}: TContractTerminationRequestMaterials): Promise<TContractTerminationRequestResponse> => {
  const response = await ApiService.post(`/request/contract-termination/new`, body, { params });
  return response.data;
};

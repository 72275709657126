import { TAssetCategory } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetAssetCategoriesParams = unknown;

export type TGetAssetCategoriesMaterials = {
  params?: TGetAssetCategoriesParams;
};

export type TGetAssetCategoriesResponse = TPaginateResponse & {
  data: TAssetCategory[];
};

// FUNCTION

export const getAssetCategories = async ({
  params,
}: TGetAssetCategoriesMaterials): Promise<TGetAssetCategoriesResponse> => {
  const response = await ApiService.get(`/asset/category/list`, { params });
  return response.data;
};

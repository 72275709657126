import { createActionCreator } from 'deox';

import {
  TDownloadReportOffboardMaterials,
  TDownloadReportOffboardResponse,
} from '@/services/api/report/download-report-offboard';

// CONSTANTS

export enum EDownloadReportOffboardAction {
  DOWNLOAD_REPORT_OFFBOARD = 'DOWNLOAD_REPORT_OFFBOARD',
  DOWNLOAD_REPORT_OFFBOARD_REQUEST = 'DOWNLOAD_REPORT_OFFBOARD_REQUEST',
  DOWNLOAD_REPORT_OFFBOARD_SUCCESS = 'DOWNLOAD_REPORT_OFFBOARD_SUCCESS',
  DOWNLOAD_REPORT_OFFBOARD_FAILED = 'DOWNLOAD_REPORT_OFFBOARD_FAILED',
}

// TYPES

export type TDownloadReportOffboardRequest = {
  type: EDownloadReportOffboardAction.DOWNLOAD_REPORT_OFFBOARD_REQUEST;
  payload: {
    materials: TDownloadReportOffboardMaterials;
    successCallback?: (response: TDownloadReportOffboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDownloadReportOffboardSuccess = {
  type: EDownloadReportOffboardAction.DOWNLOAD_REPORT_OFFBOARD_SUCCESS;
  payload: { response: TDownloadReportOffboardResponse };
};

export type TDownloadReportOffboardFailed = { type: EDownloadReportOffboardAction.DOWNLOAD_REPORT_OFFBOARD_FAILED };

// FUNCTION

export const downloadReportOffboardAction = {
  request: createActionCreator(
    EDownloadReportOffboardAction.DOWNLOAD_REPORT_OFFBOARD_REQUEST,
    (resolve) =>
      (
        materials: TDownloadReportOffboardMaterials,
        successCallback?: (response: TDownloadReportOffboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDownloadReportOffboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDownloadReportOffboardAction.DOWNLOAD_REPORT_OFFBOARD_SUCCESS,
    (resolve) =>
      (response: TDownloadReportOffboardResponse): TDownloadReportOffboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDownloadReportOffboardAction.DOWNLOAD_REPORT_OFFBOARD_FAILED,
    (resolve) =>
      (error: unknown): TDownloadReportOffboardFailed =>
        resolve({ error }),
  ),
};

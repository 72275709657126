import ApiService from '@/services/api';

// TYPES

export type TUpdateTemplatePaths = {
  id: string | number;
};
export type TUpdateTemplateBody = unknown;

export type TUpdateTemplateMaterials = {
  paths?: TUpdateTemplatePaths;
  body?: TUpdateTemplateBody;
};

export type TUpdateTemplateResponse = unknown;

// FUNCTION

export const updateTemplate = async ({ paths, body }: TUpdateTemplateMaterials): Promise<TUpdateTemplateResponse> => {
  const response = await ApiService.put(`/template/${paths?.id}`, body);
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_600 }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46 18C46 21.713 44.525 25.274 41.8995 27.8995C39.274 30.525 35.713 32 32 32C28.287 32 24.726 30.525 22.1005 27.8995C19.475 25.274 18 21.713 18 18V6H46V18Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46 46C46 42.287 44.525 38.726 41.8995 36.1005C39.274 33.475 35.713 32 32 32C28.287 32 24.726 33.475 22.1005 36.1005C19.475 38.726 18 42.287 18 46V58H46V46Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 6H54" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 58H54" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Svg;

import { createActionCreator } from 'deox';

import {
  TUpdateGenerateEmployIdMaterials,
  TUpdateGenerateEmployIdResponse,
} from '@/services/api/company/update-generate-employ-id';

// CONSTANTS

export enum EUpdateGenerateEmployIdAction {
  UPDATE_GENERATE_EMPLOY_ID = 'UPDATE_GENERATE_EMPLOY_ID',
  UPDATE_GENERATE_EMPLOY_ID_REQUEST = 'UPDATE_GENERATE_EMPLOY_ID_REQUEST',
  UPDATE_GENERATE_EMPLOY_ID_SUCCESS = 'UPDATE_GENERATE_EMPLOY_ID_SUCCESS',
  UPDATE_GENERATE_EMPLOY_ID_FAILED = 'UPDATE_GENERATE_EMPLOY_ID_FAILED',
}

// TYPES

export type TUpdateGenerateEmployIdRequest = {
  type: EUpdateGenerateEmployIdAction.UPDATE_GENERATE_EMPLOY_ID_REQUEST;
  payload: {
    materials: TUpdateGenerateEmployIdMaterials;
    successCallback?: (response: TUpdateGenerateEmployIdResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateGenerateEmployIdSuccess = {
  type: EUpdateGenerateEmployIdAction.UPDATE_GENERATE_EMPLOY_ID_SUCCESS;
  payload: { response: TUpdateGenerateEmployIdResponse };
};

export type TUpdateGenerateEmployIdFailed = { type: EUpdateGenerateEmployIdAction.UPDATE_GENERATE_EMPLOY_ID_FAILED };

// FUNCTION

export const updateGenerateEmployIdAction = {
  request: createActionCreator(
    EUpdateGenerateEmployIdAction.UPDATE_GENERATE_EMPLOY_ID_REQUEST,
    (resolve) =>
      (
        materials: TUpdateGenerateEmployIdMaterials,
        successCallback?: (response: TUpdateGenerateEmployIdResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateGenerateEmployIdRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateGenerateEmployIdAction.UPDATE_GENERATE_EMPLOY_ID_SUCCESS,
    (resolve) =>
      (response: TUpdateGenerateEmployIdResponse): TUpdateGenerateEmployIdSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateGenerateEmployIdAction.UPDATE_GENERATE_EMPLOY_ID_FAILED,
    (resolve) =>
      (error: unknown): TUpdateGenerateEmployIdFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateTypeWorkAction } from '@/redux/actions';
import { updateTypeWork, TUpdateTypeWorkResponse } from '@/services/api';

// FUNCTION

export function* updateTypeWorkSaga(action: ActionType<typeof updateTypeWorkAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateTypeWork, materials);
    const updateTypeWorkResponse: TUpdateTypeWorkResponse = response as TUpdateTypeWorkResponse;
    yield put(updateTypeWorkAction.success(updateTypeWorkResponse));
    successCallback?.(updateTypeWorkResponse);
  } catch (err) {
    yield put(updateTypeWorkAction.failure(err));
    failedCallback?.(err);
  }
}

import ApiService from '@/services/api';

// TYPES

export type TCreateRegulateCategoryParams = unknown;
export type TCreateRegulateCategoryBody = unknown;

export type TCreateRegulateCategoryMaterials = {
  params?: TCreateRegulateCategoryParams;
  body?: TCreateRegulateCategoryBody;
};

export type TCreateRegulateCategoryResponse = unknown;

// FUNCTION

export const createRegulateCategory = async ({
  params,
  body,
}: TCreateRegulateCategoryMaterials): Promise<TCreateRegulateCategoryResponse> => {
  const response = await ApiService.post(`/regulate-category/new`, body, { params });
  return response.data;
};

import { TDepartmentState } from '@/redux/reducers/department';
import { TGetDepartmentsSuccess } from '@/redux/actions/department';

export const getDepartmentsUpdateState = (
  state: TDepartmentState,
  action: TGetDepartmentsSuccess,
): TDepartmentState => ({
  ...state,
  getDepartmentsResponse: action.payload.response,
});

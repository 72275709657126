import { createActionCreator } from 'deox';

import {
  TCopyLastYearHolidaysMaterials,
  TCopyLastYearHolidaysResponse,
} from '@/services/api/holiday/copy-last-year-holidays';

// CONSTANTS

export enum ECopyLastYearHolidaysAction {
  COPY_LAST_YEAR_HOLIDAYS = 'COPY_LAST_YEAR_HOLIDAYS',
  COPY_LAST_YEAR_HOLIDAYS_REQUEST = 'COPY_LAST_YEAR_HOLIDAYS_REQUEST',
  COPY_LAST_YEAR_HOLIDAYS_SUCCESS = 'COPY_LAST_YEAR_HOLIDAYS_SUCCESS',
  COPY_LAST_YEAR_HOLIDAYS_FAILED = 'COPY_LAST_YEAR_HOLIDAYS_FAILED',
}

// TYPES

export type TCopyLastYearHolidaysRequest = {
  type: ECopyLastYearHolidaysAction.COPY_LAST_YEAR_HOLIDAYS_REQUEST;
  payload: {
    materials: TCopyLastYearHolidaysMaterials;
    successCallback?: (response: TCopyLastYearHolidaysResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCopyLastYearHolidaysSuccess = {
  type: ECopyLastYearHolidaysAction.COPY_LAST_YEAR_HOLIDAYS_SUCCESS;
  payload: { response: TCopyLastYearHolidaysResponse };
};

export type TCopyLastYearHolidaysFailed = { type: ECopyLastYearHolidaysAction.COPY_LAST_YEAR_HOLIDAYS_FAILED };

// FUNCTION

export const copyLastYearHolidaysAction = {
  request: createActionCreator(
    ECopyLastYearHolidaysAction.COPY_LAST_YEAR_HOLIDAYS_REQUEST,
    (resolve) =>
      (
        materials: TCopyLastYearHolidaysMaterials,
        successCallback?: (response: TCopyLastYearHolidaysResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCopyLastYearHolidaysRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECopyLastYearHolidaysAction.COPY_LAST_YEAR_HOLIDAYS_SUCCESS,
    (resolve) =>
      (response: TCopyLastYearHolidaysResponse): TCopyLastYearHolidaysSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECopyLastYearHolidaysAction.COPY_LAST_YEAR_HOLIDAYS_FAILED,
    (resolve) =>
      (error: unknown): TCopyLastYearHolidaysFailed =>
        resolve({ error }),
  ),
};

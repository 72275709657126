import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 5.80859L14.8557 11.6643C14.9013 11.7071 14.9376 11.7587 14.9624 11.816C14.9872 11.8733 15 11.9351 15 11.9976C15 12.06 14.9872 12.1218 14.9624 12.1791C14.9376 12.2365 14.9013 12.2881 14.8557 12.3308L9 18.1866"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { TBlanceHistory } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetBalanceHistoriesParams = unknown;

export type TGetBalanceHistoriesPaths = {
  id?: string | number;
};

export type TGetBalanceHistoriesMaterials = {
  params?: TGetBalanceHistoriesParams;
  paths?: TGetBalanceHistoriesPaths;
};

export type TGetBalanceHistoriesResponse = TPaginateResponse & {
  data: TBlanceHistory[];
};

// FUNCTION

export const getBalanceHistories = async ({
  params,
  paths,
}: TGetBalanceHistoriesMaterials): Promise<TGetBalanceHistoriesResponse> => {
  const response = await ApiService.get(`/user/balance-histories/${paths?.id || 'my-info'}`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getIconTimeOffAction } from '@/redux/actions';
import { getIconTimeOff, TGetIconTimeOffResponse } from '@/services/api';

// FUNCTION

export function* getIconTimeOffSaga(action: ActionType<typeof getIconTimeOffAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getIconTimeOff, materials);
    const getIconTimeOffResponse: TGetIconTimeOffResponse = response as TGetIconTimeOffResponse;
    yield put(getIconTimeOffAction.success(getIconTimeOffResponse));
    successCallback?.(getIconTimeOffResponse);
  } catch (err) {
    yield put(getIconTimeOffAction.failure(err));
    failedCallback?.(err);
  }
}

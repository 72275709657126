import { all, takeLatest } from 'redux-saga/effects';

import {
  addRoleMemberAction,
  createRoleAction,
  deleteMemberRoleAction,
  deleteRoleAction,
  getRoleMembersAction,
  getRoleAction,
  getRolesAction,
  updateRolePermissionAction,
  updateRoleAction,
} from '@/redux/actions';

import { addRoleMemberSaga } from './add-role-member';
import { createRoleSaga } from './create-role';
import { deleteMemberRoleSaga } from './delete-member-role';
import { deleteRoleSaga } from './delete-role';
import { getRoleMembersSaga } from './get-role-members';
import { getRoleSaga } from './get-role';
import { getRolesSaga } from './get-roles';
import { updateRolePermissionSaga } from './update-role-permission';
import { updateRoleSaga } from './update-role';

export default function* root(): Generator {
  yield all([
    takeLatest(addRoleMemberAction.request.type, addRoleMemberSaga),
    takeLatest(createRoleAction.request.type, createRoleSaga),
    takeLatest(deleteMemberRoleAction.request.type, deleteMemberRoleSaga),
    takeLatest(deleteRoleAction.request.type, deleteRoleSaga),
    takeLatest(getRoleMembersAction.request.type, getRoleMembersSaga),
    takeLatest(getRoleAction.request.type, getRoleSaga),
    takeLatest(getRolesAction.request.type, getRolesSaga),
    takeLatest(updateRolePermissionAction.request.type, updateRolePermissionSaga),
    takeLatest(updateRoleAction.request.type, updateRoleSaga),
  ]);
}

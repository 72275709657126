import ApiService from '@/services/api';

// TYPES

export type TGetReportMonthlyTimekeepingParams = unknown;

export type TGetReportMonthlyTimekeepingMaterials = {
  params?: TGetReportMonthlyTimekeepingParams;
};

export type TGetReportMonthlyTimekeepingResponse = unknown;

// FUNCTION

export const getReportMonthlyTimekeeping = async ({
  params,
}: TGetReportMonthlyTimekeepingMaterials): Promise<TGetReportMonthlyTimekeepingResponse> => {
  const response = await ApiService.get(`/report/monthly-timekeeping`, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TSignupUserParams = unknown;
export type TSignupUserBody = unknown;

export type TSignupUserMaterials = {
  params?: TSignupUserParams;
  body?: TSignupUserBody;
};

export type TSignupUserResponse = {
  accessToken: string;
};

// FUNCTION

export const signupUser = async ({ params, body }: TSignupUserMaterials): Promise<TSignupUserResponse> => {
  const response = await ApiService.post(`/auth/employee/sign-up`, body, { params });
  return response.data;
};

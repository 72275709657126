import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7548 3.53796L15.1398 8.35296C15.194 8.47517 15.2793 8.58103 15.3872 8.65998C15.4952 8.73894 15.6219 8.78824 15.7548 8.80296L21.0198 9.58296C21.1722 9.60255 21.3159 9.66511 21.4342 9.76334C21.5524 9.86156 21.6402 9.9914 21.6874 10.1377C21.7346 10.284 21.7392 10.4406 21.7006 10.5894C21.6621 10.7382 21.582 10.873 21.4698 10.978L17.6748 14.743C17.5779 14.8334 17.5052 14.9467 17.4633 15.0725C17.4214 15.1982 17.4116 15.3324 17.4348 15.463L18.3498 20.758C18.3763 20.9101 18.3595 21.0666 18.3014 21.2097C18.2434 21.3528 18.1463 21.4768 18.0212 21.5674C17.8962 21.6581 17.7482 21.7118 17.5942 21.7225C17.4401 21.7332 17.2861 21.7004 17.1498 21.628L12.4098 19.123C12.2884 19.0634 12.155 19.0324 12.0198 19.0324C11.8846 19.0324 11.7511 19.0634 11.6298 19.123L6.88976 21.628C6.7534 21.7004 6.59942 21.7332 6.44535 21.7225C6.29129 21.7118 6.14332 21.6581 6.01829 21.5674C5.89325 21.4768 5.79617 21.3528 5.7381 21.2097C5.68002 21.0666 5.66327 20.9101 5.68976 20.758L6.60476 15.403C6.62795 15.2724 6.61814 15.1382 6.57623 15.0125C6.53431 14.8867 6.46162 14.7734 6.36476 14.683L2.52476 10.978C2.41116 10.8701 2.33126 10.7316 2.29476 10.5793C2.25826 10.4269 2.26671 10.2673 2.31909 10.1197C2.37148 9.97202 2.46555 9.84275 2.58991 9.74749C2.71427 9.65223 2.86358 9.59508 3.01976 9.58296L8.28476 8.80296C8.41766 8.78824 8.54438 8.73894 8.65229 8.65998C8.76019 8.58103 8.84552 8.47517 8.89976 8.35296L11.2848 3.53796C11.3497 3.39772 11.4534 3.27899 11.5837 3.19578C11.7139 3.11257 11.8652 3.06836 12.0198 3.06836C12.1743 3.06836 12.3256 3.11257 12.4559 3.19578C12.5861 3.27899 12.6898 3.39772 12.7548 3.53796Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;

import { TDepartment } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetDepartmentsParams = {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
};

export type TGetDepartmentsMaterials = {
  params?: TGetDepartmentsParams;
};

export type TGetDepartmentsResponse = TPaginateResponse & {
  data: TDepartment[];
  total: { [key: string]: number };
};

// FUNCTION

export const getDepartments = async ({ params }: TGetDepartmentsMaterials): Promise<TGetDepartmentsResponse> => {
  const response = await ApiService.get(`/department/list`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TAssignOnboardMaterials, TAssignOnboardResponse } from '@/services/api/checklist/assign-onboard';

// CONSTANTS

export enum EAssignOnboardAction {
  ASSIGN_ONBOARD = 'ASSIGN_ONBOARD',
  ASSIGN_ONBOARD_REQUEST = 'ASSIGN_ONBOARD_REQUEST',
  ASSIGN_ONBOARD_SUCCESS = 'ASSIGN_ONBOARD_SUCCESS',
  ASSIGN_ONBOARD_FAILED = 'ASSIGN_ONBOARD_FAILED',
}

// TYPES

export type TAssignOnboardRequest = {
  type: EAssignOnboardAction.ASSIGN_ONBOARD_REQUEST;
  payload: {
    materials: TAssignOnboardMaterials;
    successCallback?: (response: TAssignOnboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAssignOnboardSuccess = {
  type: EAssignOnboardAction.ASSIGN_ONBOARD_SUCCESS;
  payload: { response: TAssignOnboardResponse };
};

export type TAssignOnboardFailed = { type: EAssignOnboardAction.ASSIGN_ONBOARD_FAILED };

// FUNCTION

export const assignOnboardAction = {
  request: createActionCreator(
    EAssignOnboardAction.ASSIGN_ONBOARD_REQUEST,
    (resolve) =>
      (
        materials: TAssignOnboardMaterials,
        successCallback?: (response: TAssignOnboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAssignOnboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAssignOnboardAction.ASSIGN_ONBOARD_SUCCESS,
    (resolve) =>
      (response: TAssignOnboardResponse): TAssignOnboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAssignOnboardAction.ASSIGN_ONBOARD_FAILED,
    (resolve) =>
      (error: unknown): TAssignOnboardFailed =>
        resolve({ error }),
  ),
};

import { TUser } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetUserPaths = {
  id: string | number;
};
export type TGetUserParams = unknown;

export type TGetUserMaterials = {
  paths?: TGetUserPaths;
  params?: TGetUserParams;
};

export type TGetUserResponse = TUser;

// FUNCTION

export const getUser = async ({ paths, params }: TGetUserMaterials): Promise<TGetUserResponse> => {
  const response = await ApiService.get(`/user/${paths?.id}`, { params });
  return response.data;
};

import { TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetUsersParams = {
  page: number;
  pageSize: number;
  search?: string;
  department?: string;
  location?: string;
  employeeType?: string;
  employeeStatus?: string;
  accountStatus?: string;
  job?: string;
  flag?: string;
  sort?: string;
  role?: string;
};

export type TGetUsersMaterials = {
  params?: TGetUsersParams;
};

export type TGetUsersResponse = TPaginateResponse & {
  data: TUser[];
  statistics: { count: number; id: string; title: string }[];
};

// FUNCTION

export const getUsers = async ({ params }: TGetUsersMaterials): Promise<TGetUsersResponse> => {
  const response = await ApiService.get(`/user/list`, { params });
  return response.data;
};

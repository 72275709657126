import { createActionCreator } from 'deox';

import { TDeleteBenefitMaterials, TDeleteBenefitResponse } from '@/services/api/benefit/delete-benefit';

// CONSTANTS

export enum EDeleteBenefitAction {
  DELETE_BENEFIT = 'DELETE_BENEFIT',
  DELETE_BENEFIT_REQUEST = 'DELETE_BENEFIT_REQUEST',
  DELETE_BENEFIT_SUCCESS = 'DELETE_BENEFIT_SUCCESS',
  DELETE_BENEFIT_FAILED = 'DELETE_BENEFIT_FAILED',
}

// TYPES

export type TDeleteBenefitRequest = {
  type: EDeleteBenefitAction.DELETE_BENEFIT_REQUEST;
  payload: {
    materials: TDeleteBenefitMaterials;
    successCallback?: (response: TDeleteBenefitResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteBenefitSuccess = {
  type: EDeleteBenefitAction.DELETE_BENEFIT_SUCCESS;
  payload: { response: TDeleteBenefitResponse };
};

export type TDeleteBenefitFailed = { type: EDeleteBenefitAction.DELETE_BENEFIT_FAILED };

// FUNCTION

export const deleteBenefitAction = {
  request: createActionCreator(
    EDeleteBenefitAction.DELETE_BENEFIT_REQUEST,
    (resolve) =>
      (
        materials: TDeleteBenefitMaterials,
        successCallback?: (response: TDeleteBenefitResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteBenefitRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteBenefitAction.DELETE_BENEFIT_SUCCESS,
    (resolve) =>
      (response: TDeleteBenefitResponse): TDeleteBenefitSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteBenefitAction.DELETE_BENEFIT_FAILED,
    (resolve) =>
      (error: unknown): TDeleteBenefitFailed =>
        resolve({ error }),
  ),
};

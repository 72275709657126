import { createActionCreator } from 'deox';

import {
  TChangeStatusBenefitMaterials,
  TChangeStatusBenefitResponse,
} from '@/services/api/benefit/change-status-benefit';

// CONSTANTS

export enum EChangeStatusBenefitAction {
  CHANGE_STATUS_BENEFIT = 'CHANGE_STATUS_BENEFIT',
  CHANGE_STATUS_BENEFIT_REQUEST = 'CHANGE_STATUS_BENEFIT_REQUEST',
  CHANGE_STATUS_BENEFIT_SUCCESS = 'CHANGE_STATUS_BENEFIT_SUCCESS',
  CHANGE_STATUS_BENEFIT_FAILED = 'CHANGE_STATUS_BENEFIT_FAILED',
}

// TYPES

export type TChangeStatusBenefitRequest = {
  type: EChangeStatusBenefitAction.CHANGE_STATUS_BENEFIT_REQUEST;
  payload: {
    materials: TChangeStatusBenefitMaterials;
    successCallback?: (response: TChangeStatusBenefitResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TChangeStatusBenefitSuccess = {
  type: EChangeStatusBenefitAction.CHANGE_STATUS_BENEFIT_SUCCESS;
  payload: { response: TChangeStatusBenefitResponse };
};

export type TChangeStatusBenefitFailed = { type: EChangeStatusBenefitAction.CHANGE_STATUS_BENEFIT_FAILED };

// FUNCTION

export const changeStatusBenefitAction = {
  request: createActionCreator(
    EChangeStatusBenefitAction.CHANGE_STATUS_BENEFIT_REQUEST,
    (resolve) =>
      (
        materials: TChangeStatusBenefitMaterials,
        successCallback?: (response: TChangeStatusBenefitResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TChangeStatusBenefitRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EChangeStatusBenefitAction.CHANGE_STATUS_BENEFIT_SUCCESS,
    (resolve) =>
      (response: TChangeStatusBenefitResponse): TChangeStatusBenefitSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EChangeStatusBenefitAction.CHANGE_STATUS_BENEFIT_FAILED,
    (resolve) =>
      (error: unknown): TChangeStatusBenefitFailed =>
        resolve({ error }),
  ),
};

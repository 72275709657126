import { createActionCreator } from 'deox';

import { TReviewOnboardMaterials, TReviewOnboardResponse } from '@/services/api/request/review-onboard';

// CONSTANTS

export enum EReviewOnboardAction {
  REVIEW_ONBOARD = 'REVIEW_ONBOARD',
  REVIEW_ONBOARD_REQUEST = 'REVIEW_ONBOARD_REQUEST',
  REVIEW_ONBOARD_SUCCESS = 'REVIEW_ONBOARD_SUCCESS',
  REVIEW_ONBOARD_FAILED = 'REVIEW_ONBOARD_FAILED',
}

// TYPES

export type TReviewOnboardRequest = {
  type: EReviewOnboardAction.REVIEW_ONBOARD_REQUEST;
  payload: {
    materials: TReviewOnboardMaterials;
    successCallback?: (response: TReviewOnboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TReviewOnboardSuccess = {
  type: EReviewOnboardAction.REVIEW_ONBOARD_SUCCESS;
  payload: { response: TReviewOnboardResponse };
};

export type TReviewOnboardFailed = { type: EReviewOnboardAction.REVIEW_ONBOARD_FAILED };

// FUNCTION

export const reviewOnboardAction = {
  request: createActionCreator(
    EReviewOnboardAction.REVIEW_ONBOARD_REQUEST,
    (resolve) =>
      (
        materials: TReviewOnboardMaterials,
        successCallback?: (response: TReviewOnboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TReviewOnboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EReviewOnboardAction.REVIEW_ONBOARD_SUCCESS,
    (resolve) =>
      (response: TReviewOnboardResponse): TReviewOnboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EReviewOnboardAction.REVIEW_ONBOARD_FAILED,
    (resolve) =>
      (error: unknown): TReviewOnboardFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TUpdateTemplateMaterials, TUpdateTemplateResponse } from '@/services/api/template/update-template';

// CONSTANTS

export enum EUpdateTemplateAction {
  UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
  UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_FAILED = 'UPDATE_TEMPLATE_FAILED',
}

// TYPES

export type TUpdateTemplateRequest = {
  type: EUpdateTemplateAction.UPDATE_TEMPLATE_REQUEST;
  payload: {
    materials: TUpdateTemplateMaterials;
    successCallback?: (response: TUpdateTemplateResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateTemplateSuccess = {
  type: EUpdateTemplateAction.UPDATE_TEMPLATE_SUCCESS;
  payload: { response: TUpdateTemplateResponse };
};

export type TUpdateTemplateFailed = { type: EUpdateTemplateAction.UPDATE_TEMPLATE_FAILED };

// FUNCTION

export const updateTemplateAction = {
  request: createActionCreator(
    EUpdateTemplateAction.UPDATE_TEMPLATE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateTemplateMaterials,
        successCallback?: (response: TUpdateTemplateResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateTemplateRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateTemplateAction.UPDATE_TEMPLATE_SUCCESS,
    (resolve) =>
      (response: TUpdateTemplateResponse): TUpdateTemplateSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateTemplateAction.UPDATE_TEMPLATE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateTemplateFailed =>
        resolve({ error }),
  ),
};

import { TAsset } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetAssetPaths = {
  id: string | number;
};
export type TGetAssetParams = unknown;

export type TGetAssetMaterials = {
  paths?: TGetAssetPaths;
  params?: TGetAssetParams;
};

export type TGetAssetResponse = TAsset;

// FUNCTION

export const getAsset = async ({ paths, params }: TGetAssetMaterials): Promise<TGetAssetResponse> => {
  const response = await ApiService.get(`/asset/${paths?.id}`, { params });
  return response.data;
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateRolePermissionAction } from '@/redux/actions';
import { updateRolePermission, TUpdateRolePermissionResponse } from '@/services/api';

// FUNCTION

export function* updateRolePermissionSaga(action: ActionType<typeof updateRolePermissionAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateRolePermission, materials);
    const updateRolePermissionResponse: TUpdateRolePermissionResponse = response as TUpdateRolePermissionResponse;
    yield put(updateRolePermissionAction.success(updateRolePermissionResponse));
    successCallback?.(updateRolePermissionResponse);
  } catch (err) {
    yield put(updateRolePermissionAction.failure(err));
    failedCallback?.(err);
  }
}

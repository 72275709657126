import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.75 19.0477H11.25" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.5534 8.35108C15.5534 6.87824 14.9683 5.46573 13.9268 4.42426C12.8853 3.38281 11.4728 2.79773 9.99997 2.79773C8.52714 2.79773 7.11463 3.38281 6.07318 4.42426C5.03171 5.46573 4.44663 6.87824 4.44663 8.35108V13.8674C4.44663 14.4197 4.22722 14.9494 3.83667 15.3399C3.44614 15.7306 3.05231 15.9499 2.5 15.9499H17.5C16.9476 15.9499 16.5539 15.7306 16.1633 15.3399C15.7728 14.9494 15.5534 14.4197 15.5534 13.8674V8.35108Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

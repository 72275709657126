import ApiService from '@/services/api';

// TYPES

export type TUpdateAssignOnboardBody = unknown;

export type TUpdateAssignOnboardMaterials = {
  body?: TUpdateAssignOnboardBody;
};

export type TUpdateAssignOnboardResponse = unknown;

// FUNCTION

export const updateAssignOnboard = async ({
  body,
}: TUpdateAssignOnboardMaterials): Promise<TUpdateAssignOnboardResponse> => {
  const response = await ApiService.patch(`/checklist/assign/onboard`, body);
  return response.data;
};

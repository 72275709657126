import { createReducer } from 'deox';

import {
  TDonwloadReportTimeOffScheduleResponse,
  TDownloadEmployeeTurnoverRateResponse,
  TDownloadReportEmployeeDataResponse,
  TDownloadReportHeadcountResponse,
  TDownloadReportOffboardResponse,
  TDownloadReportOnboardResponse,
  TDownloadReportTimeOffBalanceResponse,
  TGetReportEmployeeDataResponse,
  TGetReportEmployeeTurnoverRateResponse,
  TGetReportHeadcountResponse,
  TGetReportMonthlyTimekeepingResponse,
  TGetReportOffboardResponse,
  TGetReportOnboardResponse,
  TGetReportTimeOffBalanceResponse,
  TGetReportTimeOffScheduleResponse,
} from '@/services/api/report';
import {
  donwloadReportTimeOffScheduleAction,
  downloadEmployeeTurnoverRateAction,
  downloadReportEmployeeDataAction,
  downloadReportHeadcountAction,
  downloadReportOffboardAction,
  downloadReportOnboardAction,
  downloadReportTimeOffBalanceAction,
  getReportEmployeeDataAction,
  getReportEmployeeTurnoverRateAction,
  getReportHeadcountAction,
  getReportMonthlyTimekeepingAction,
  getReportOffboardAction,
  getReportOnboardAction,
  getReportTimeOffBalanceAction,
  getReportTimeOffScheduleAction,
} from '@/redux/actions';
import { donwloadReportTimeOffScheduleUpdateState } from './donwload-report-time-off-schedule';
import { downloadEmployeeTurnoverRateUpdateState } from './download-employee-turnover-rate';
import { downloadReportEmployeeDataUpdateState } from './download-report-employee-data';
import { downloadReportHeadcountUpdateState } from './download-report-headcount';
import { downloadReportOffboardUpdateState } from './download-report-offboard';
import { downloadReportOnboardUpdateState } from './download-report-onboard';
import { downloadReportTimeOffBalanceUpdateState } from './download-report-time-off-balance';
import { getReportEmployeeDataUpdateState } from './get-report-employee-data';
import { getReportEmployeeTurnoverRateUpdateState } from './get-report-employee-turnover-rate';
import { getReportHeadcountUpdateState } from './get-report-headcount';
import { getReportMonthlyTimekeepingUpdateState } from './get-report-monthly-timekeeping';
import { getReportOffboardUpdateState } from './get-report-offboard';
import { getReportOnboardUpdateState } from './get-report-onboard';
import { getReportTimeOffBalanceUpdateState } from './get-report-time-off-balance';
import { getReportTimeOffScheduleUpdateState } from './get-report-time-off-schedule';

export type TReportState = {
  donwloadReportTimeOffScheduleResponse?: TDonwloadReportTimeOffScheduleResponse;
  downloadEmployeeTurnoverRateResponse?: TDownloadEmployeeTurnoverRateResponse;
  downloadReportEmployeeDataResponse?: TDownloadReportEmployeeDataResponse;
  downloadReportHeadcountResponse?: TDownloadReportHeadcountResponse;
  downloadReportOffboardResponse?: TDownloadReportOffboardResponse;
  downloadReportOnboardResponse?: TDownloadReportOnboardResponse;
  downloadReportTimeOffBalanceResponse?: TDownloadReportTimeOffBalanceResponse;
  getReportEmployeeDataResponse?: TGetReportEmployeeDataResponse;
  getReportEmployeeTurnoverRateResponse?: TGetReportEmployeeTurnoverRateResponse;
  getReportHeadcountResponse?: TGetReportHeadcountResponse;
  getReportMonthlyTimekeepingResponse?: TGetReportMonthlyTimekeepingResponse;
  getReportOffboardResponse?: TGetReportOffboardResponse;
  getReportOnboardResponse?: TGetReportOnboardResponse;
  getReportTimeOffBalanceResponse?: TGetReportTimeOffBalanceResponse;
  getReportTimeOffScheduleResponse?: TGetReportTimeOffScheduleResponse;
};

const initialState: TReportState = {
  donwloadReportTimeOffScheduleResponse: undefined,
  downloadEmployeeTurnoverRateResponse: undefined,
  downloadReportEmployeeDataResponse: undefined,
  downloadReportHeadcountResponse: undefined,
  downloadReportOffboardResponse: undefined,
  downloadReportOnboardResponse: undefined,
  downloadReportTimeOffBalanceResponse: undefined,
  getReportEmployeeDataResponse: undefined,
  getReportEmployeeTurnoverRateResponse: undefined,
  getReportHeadcountResponse: undefined,
  getReportMonthlyTimekeepingResponse: undefined,
  getReportOffboardResponse: undefined,
  getReportOnboardResponse: undefined,
  getReportTimeOffBalanceResponse: undefined,
  getReportTimeOffScheduleResponse: undefined,
};

const ReportReducer = createReducer(initialState, (handleAction) => [
  handleAction(donwloadReportTimeOffScheduleAction.success, donwloadReportTimeOffScheduleUpdateState),
  handleAction(downloadEmployeeTurnoverRateAction.success, downloadEmployeeTurnoverRateUpdateState),
  handleAction(downloadReportEmployeeDataAction.success, downloadReportEmployeeDataUpdateState),
  handleAction(downloadReportHeadcountAction.success, downloadReportHeadcountUpdateState),
  handleAction(downloadReportOffboardAction.success, downloadReportOffboardUpdateState),
  handleAction(downloadReportOnboardAction.success, downloadReportOnboardUpdateState),
  handleAction(downloadReportTimeOffBalanceAction.success, downloadReportTimeOffBalanceUpdateState),
  handleAction(getReportEmployeeDataAction.success, getReportEmployeeDataUpdateState),
  handleAction(getReportEmployeeTurnoverRateAction.success, getReportEmployeeTurnoverRateUpdateState),
  handleAction(getReportHeadcountAction.success, getReportHeadcountUpdateState),
  handleAction(getReportMonthlyTimekeepingAction.success, getReportMonthlyTimekeepingUpdateState),
  handleAction(getReportOffboardAction.success, getReportOffboardUpdateState),
  handleAction(getReportOnboardAction.success, getReportOnboardUpdateState),
  handleAction(getReportTimeOffBalanceAction.success, getReportTimeOffBalanceUpdateState),
  handleAction(getReportTimeOffScheduleAction.success, getReportTimeOffScheduleUpdateState),
]);

export default ReportReducer;

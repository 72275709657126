import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 4.125C3.75 3.08947 4.58947 2.25 5.625 2.25H18.375C19.4105 2.25 20.25 3.08947 20.25 4.125V19.875C20.25 20.9105 19.4105 21.75 18.375 21.75H5.625C4.58947 21.75 3.75 20.9105 3.75 19.875V4.125Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M8.25 7.52344H15.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8.25 12.0234H15.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8.25 16.5234H15.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default Svg;

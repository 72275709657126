import { TRequestState } from '@/redux/reducers/request';
import { TContractProbationRequestSuccess } from '@/redux/actions/request';

export const contractProbationRequestUpdateState = (
  state: TRequestState,
  action: TContractProbationRequestSuccess,
): TRequestState => ({
  ...state,
  contractProbationRequestResponse: action.payload.response,
});

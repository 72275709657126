import { createReducer } from 'deox';

import {
  TCreateReportViolationResponse,
  TDeleteReportViolationResponse,
  TGetReportViolationResponse,
} from '@/services/api/report-violation';
import { createReportViolationAction, deleteReportViolationAction, getReportViolationAction } from '@/redux/actions';
import { createReportViolationUpdateState } from './create-report-violation';
import { deleteReportViolationUpdateState } from './delete-report-violation';
import { getReportViolationUpdateState } from './get-report-violation';

export type TReportViolationState = {
  createReportViolationResponse?: TCreateReportViolationResponse;
  deleteReportViolationResponse?: TDeleteReportViolationResponse;
  getReportViolationResponse?: TGetReportViolationResponse;
};

const initialState: TReportViolationState = {
  createReportViolationResponse: undefined,
  deleteReportViolationResponse: undefined,
  getReportViolationResponse: undefined,
};

const ReportViolationReducer = createReducer(initialState, (handleAction) => [
  handleAction(createReportViolationAction.success, createReportViolationUpdateState),
  handleAction(deleteReportViolationAction.success, deleteReportViolationUpdateState),
  handleAction(getReportViolationAction.success, getReportViolationUpdateState),
]);

export default ReportViolationReducer;

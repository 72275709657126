import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4297_10014)">
        <path
          d="M14.2858 4H1.71439C1.08321 4 0.571533 4.51167 0.571533 5.14286V10.8571C0.571533 11.4883 1.08321 12 1.71439 12H14.2858C14.917 12 15.4287 11.4883 15.4287 10.8571V5.14286C15.4287 4.51167 14.917 4 14.2858 4Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.00014 8.57254C4.31573 8.57254 4.57157 8.31671 4.57157 8.00112C4.57157 7.68552 4.31573 7.42969 4.00014 7.42969C3.68455 7.42969 3.42871 7.68552 3.42871 8.00112C3.42871 8.31671 3.68455 8.57254 4.00014 8.57254Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.42861 8.57254C7.7442 8.57254 8.00003 8.31671 8.00003 8.00112C8.00003 7.68552 7.7442 7.42969 7.42861 7.42969C7.11301 7.42969 6.85718 7.68552 6.85718 8.00112C6.85718 8.31671 7.11301 8.57254 7.42861 8.57254Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10.8572 9.14453H12.5715" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4297_10014">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg;

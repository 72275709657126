import { all, takeLatest } from 'redux-saga/effects';

import { createReportViolationAction, deleteReportViolationAction, getReportViolationAction } from '@/redux/actions';

import { createReportViolationSaga } from './create-report-violation';
import { deleteReportViolationSaga } from './delete-report-violation';
import { getReportViolationSaga } from './get-report-violation';

export default function* root(): Generator {
  yield all([
    takeLatest(createReportViolationAction.request.type, createReportViolationSaga),
    takeLatest(deleteReportViolationAction.request.type, deleteReportViolationSaga),
    takeLatest(getReportViolationAction.request.type, getReportViolationSaga),
  ]);
}

import ApiService from '@/services/api';

// TYPES

export type TDeleteHolidayPaths = {
  id: string | number;
};
export type TDeleteHolidayParams = unknown;

export type TDeleteHolidayMaterials = {
  paths?: TDeleteHolidayPaths;
  params?: TDeleteHolidayParams;
};

export type TDeleteHolidayResponse = unknown;

// FUNCTION

export const deleteHoliday = async ({ paths, params }: TDeleteHolidayMaterials): Promise<TDeleteHolidayResponse> => {
  const response = await ApiService.delete(`/holiday/${paths?.id}`, { params });
  return response.data;
};

import { TTemplateState } from '@/redux/reducers/template';
import { TDuplicateTemplateSuccess } from '@/redux/actions/template';

export const duplicateTemplateUpdateState = (
  state: TTemplateState,
  action: TDuplicateTemplateSuccess,
): TTemplateState => ({
  ...state,
  duplicateTemplateResponse: action.payload.response,
});

import { all, takeLatest } from 'redux-saga/effects';

import { addApproverAction, getApproversAction, removeApproverAction } from '@/redux/actions';

import { addApproverSaga } from './add-approver';
import { getApproversSaga } from './get-approvers';
import { removeApproverSaga } from './remove-approver';

export default function* root(): Generator {
  yield all([
    takeLatest(addApproverAction.request.type, addApproverSaga),
    takeLatest(getApproversAction.request.type, getApproversSaga),
    takeLatest(removeApproverAction.request.type, removeApproverSaga),
  ]);
}

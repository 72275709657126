import ApiService from '@/services/api';

// TYPES

export type TResignRequestParams = unknown;
export type TResignRequestBody = unknown;

export type TResignRequestMaterials = {
  params?: TResignRequestParams;
  body?: TResignRequestBody;
};

export type TResignRequestResponse = unknown;

// FUNCTION

export const resignRequest = async ({ params, body }: TResignRequestMaterials): Promise<TResignRequestResponse> => {
  const response = await ApiService.post(`/request/resign/new`, body, { params });
  return response.data;
};

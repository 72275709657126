import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getReportOnboardAction } from '@/redux/actions';
import { getReportOnboard, TGetReportOnboardResponse } from '@/services/api';

// FUNCTION

export function* getReportOnboardSaga(action: ActionType<typeof getReportOnboardAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getReportOnboard, materials);
    const getReportOnboardResponse: TGetReportOnboardResponse = response as TGetReportOnboardResponse;
    yield put(getReportOnboardAction.success(getReportOnboardResponse));
    successCallback?.(getReportOnboardResponse);
  } catch (err) {
    yield put(getReportOnboardAction.failure(err));
    failedCallback?.(err);
  }
}

import { TNotification } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetNotificationsParams = {
  page: number;
  pageSize: number;
};

export type TGetNotificationsMaterials = {
  isHRRole?: boolean;
  params?: TGetNotificationsParams;
};

export type TGetNotificationsResponse = TPaginateResponse & {
  data: TNotification[];
};

// FUNCTION

export const getNotifications = async ({
  params,
  isHRRole,
}: TGetNotificationsMaterials): Promise<TGetNotificationsResponse> => {
  const response = await ApiService.get(`/dashboard/notification/${isHRRole ? 'list' : 'my-list'}`, { params });
  return response.data;
};

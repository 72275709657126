import { TMyTimeOffType } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetMyTimeOffTypesParams = unknown;

export type TGetMyTimeOffTypesMaterials = {
  params?: TGetMyTimeOffTypesParams;
};

export type TGetMyTimeOffTypesResponse = TMyTimeOffType[];

// FUNCTION

export const getMyTimeOffTypes = async ({
  params,
}: TGetMyTimeOffTypesMaterials): Promise<TGetMyTimeOffTypesResponse> => {
  const response = await ApiService.get(`/user/time-off-types/my-info`, { params });
  return response.data;
};

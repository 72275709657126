import { createActionCreator } from 'deox';

import {
  TDeleteBackgroundSettingMaterials,
  TDeleteBackgroundSettingResponse,
} from '@/services/api/setting/delete-background-setting';

// CONSTANTS

export enum EDeleteBackgroundSettingAction {
  DELETE_BACKGROUND_SETTING = 'DELETE_BACKGROUND_SETTING',
  DELETE_BACKGROUND_SETTING_REQUEST = 'DELETE_BACKGROUND_SETTING_REQUEST',
  DELETE_BACKGROUND_SETTING_SUCCESS = 'DELETE_BACKGROUND_SETTING_SUCCESS',
  DELETE_BACKGROUND_SETTING_FAILED = 'DELETE_BACKGROUND_SETTING_FAILED',
}

// TYPES

export type TDeleteBackgroundSettingRequest = {
  type: EDeleteBackgroundSettingAction.DELETE_BACKGROUND_SETTING_REQUEST;
  payload: {
    materials: TDeleteBackgroundSettingMaterials;
    successCallback?: (response: TDeleteBackgroundSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteBackgroundSettingSuccess = {
  type: EDeleteBackgroundSettingAction.DELETE_BACKGROUND_SETTING_SUCCESS;
  payload: { response: TDeleteBackgroundSettingResponse };
};

export type TDeleteBackgroundSettingFailed = { type: EDeleteBackgroundSettingAction.DELETE_BACKGROUND_SETTING_FAILED };

// FUNCTION

export const deleteBackgroundSettingAction = {
  request: createActionCreator(
    EDeleteBackgroundSettingAction.DELETE_BACKGROUND_SETTING_REQUEST,
    (resolve) =>
      (
        materials: TDeleteBackgroundSettingMaterials,
        successCallback?: (response: TDeleteBackgroundSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteBackgroundSettingRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteBackgroundSettingAction.DELETE_BACKGROUND_SETTING_SUCCESS,
    (resolve) =>
      (response: TDeleteBackgroundSettingResponse): TDeleteBackgroundSettingSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteBackgroundSettingAction.DELETE_BACKGROUND_SETTING_FAILED,
    (resolve) =>
      (error: unknown): TDeleteBackgroundSettingFailed =>
        resolve({ error }),
  ),
};

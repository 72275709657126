import { createActionCreator } from 'deox';

import { TDeleteDepartmentMaterials, TDeleteDepartmentResponse } from '@/services/api/department/delete-department';

// CONSTANTS

export enum EDeleteDepartmentAction {
  DELETE_DEPARTMENT = 'DELETE_DEPARTMENT',
  DELETE_DEPARTMENT_REQUEST = 'DELETE_DEPARTMENT_REQUEST',
  DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS',
  DELETE_DEPARTMENT_FAILED = 'DELETE_DEPARTMENT_FAILED',
}

// TYPES

export type TDeleteDepartmentRequest = {
  type: EDeleteDepartmentAction.DELETE_DEPARTMENT_REQUEST;
  payload: {
    materials: TDeleteDepartmentMaterials;
    successCallback?: (response: TDeleteDepartmentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteDepartmentSuccess = {
  type: EDeleteDepartmentAction.DELETE_DEPARTMENT_SUCCESS;
  payload: { response: TDeleteDepartmentResponse };
};

export type TDeleteDepartmentFailed = { type: EDeleteDepartmentAction.DELETE_DEPARTMENT_FAILED };

// FUNCTION

export const deleteDepartmentAction = {
  request: createActionCreator(
    EDeleteDepartmentAction.DELETE_DEPARTMENT_REQUEST,
    (resolve) =>
      (
        materials: TDeleteDepartmentMaterials,
        successCallback?: (response: TDeleteDepartmentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteDepartmentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteDepartmentAction.DELETE_DEPARTMENT_SUCCESS,
    (resolve) =>
      (response: TDeleteDepartmentResponse): TDeleteDepartmentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteDepartmentAction.DELETE_DEPARTMENT_FAILED,
    (resolve) =>
      (error: unknown): TDeleteDepartmentFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import { TUpdateRegulationMaterials, TUpdateRegulationResponse } from '@/services/api/regulation/update-regulation';

// CONSTANTS

export enum EUpdateRegulationAction {
  UPDATE_REGULATION = 'UPDATE_REGULATION',
  UPDATE_REGULATION_REQUEST = 'UPDATE_REGULATION_REQUEST',
  UPDATE_REGULATION_SUCCESS = 'UPDATE_REGULATION_SUCCESS',
  UPDATE_REGULATION_FAILED = 'UPDATE_REGULATION_FAILED',
}

// TYPES

export type TUpdateRegulationRequest = {
  type: EUpdateRegulationAction.UPDATE_REGULATION_REQUEST;
  payload: {
    materials: TUpdateRegulationMaterials;
    successCallback?: (response: TUpdateRegulationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateRegulationSuccess = {
  type: EUpdateRegulationAction.UPDATE_REGULATION_SUCCESS;
  payload: { response: TUpdateRegulationResponse };
};

export type TUpdateRegulationFailed = { type: EUpdateRegulationAction.UPDATE_REGULATION_FAILED };

// FUNCTION

export const updateRegulationAction = {
  request: createActionCreator(
    EUpdateRegulationAction.UPDATE_REGULATION_REQUEST,
    (resolve) =>
      (
        materials: TUpdateRegulationMaterials,
        successCallback?: (response: TUpdateRegulationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateRegulationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateRegulationAction.UPDATE_REGULATION_SUCCESS,
    (resolve) =>
      (response: TUpdateRegulationResponse): TUpdateRegulationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateRegulationAction.UPDATE_REGULATION_FAILED,
    (resolve) =>
      (error: unknown): TUpdateRegulationFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TRevokeAccessUserPaths = {
  id: string | number;
};
export type TRevokeAccessUserParams = unknown;
export type TRevokeAccessUserBody = unknown;

export type TRevokeAccessUserMaterials = {
  paths?: TRevokeAccessUserPaths;
  params?: TRevokeAccessUserParams;
  body?: TRevokeAccessUserBody;
};

export type TRevokeAccessUserResponse = unknown;

// FUNCTION

export const revokeAccessUser = async ({
  paths,
  params,
  body,
}: TRevokeAccessUserMaterials): Promise<TRevokeAccessUserResponse> => {
  const response = await ApiService.post(`/user/revoke-access/${paths?.id}`, body, { params });
  return response.data;
};

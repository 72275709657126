import { all, takeLatest } from 'redux-saga/effects';

import {
  addUserRolesAction,
  adjustTimeOffBlanceAction,
  changeUserTypeStatusAction,
  createUserAction,
  deleteUserAction,
  downloadPeopleListAction,
  downloadTemplateUsersAction,
  getBalanceHistoriesAction,
  getMyCareerHistoriesAction,
  getMyInfoAction,
  getMyTimeOffTypesAction,
  getNewMembersAction,
  getTimeOffHistoriesAction,
  getUserCareerHistoriesAction,
  getUserTimeOffTypesAction,
  getUserAction,
  getUsersAction,
  importUsersAction,
  offboardingUserAction,
  onboardingUserFlowAction,
  rehireUserAction,
  reinviteUserAction,
  revokeAccessUserAction,
  updateMyCareerAction,
  updateMyInfoAction,
  updateUserCareerAction,
  updateUserAction,
  welcomeUserAction,
} from '@/redux/actions';

import { addUserRolesSaga } from './add-user-roles';
import { adjustTimeOffBlanceSaga } from './adjust-time-off-blance';
import { changeUserTypeStatusSaga } from './change-user-type-status';
import { createUserSaga } from './create-user';
import { deleteUserSaga } from './delete-user';
import { downloadPeopleListSaga } from './download-people-list';
import { downloadTemplateUsersSaga } from './download-template-users';
import { getBalanceHistoriesSaga } from './get-balance-histories';
import { getMyCareerHistoriesSaga } from './get-my-career-histories';
import { getMyInfoSaga } from './get-my-info';
import { getMyTimeOffTypesSaga } from './get-my-time-off-types';
import { getNewMembersSaga } from './get-new-members';
import { getTimeOffHistoriesSaga } from './get-time-off-histories';
import { getUserCareerHistoriesSaga } from './get-user-career-histories';
import { getUserTimeOffTypesSaga } from './get-user-time-off-types';
import { getUserSaga } from './get-user';
import { getUsersSaga } from './get-users';
import { importUsersSaga } from './import-users';
import { offboardingUserSaga } from './offboarding-user';
import { onboardingUserFlowSaga } from './onboarding-user-flow';
import { rehireUserSaga } from './rehire-user';
import { reinviteUserSaga } from './reinvite-user';
import { revokeAccessUserSaga } from './revoke-access-user';
import { updateMyCareerSaga } from './update-my-career';
import { updateMyInfoSaga } from './update-my-info';
import { updateUserCareerSaga } from './update-user-career';
import { updateUserSaga } from './update-user';
import { welcomeUserSaga } from './welcome-user';

export default function* root(): Generator {
  yield all([
    takeLatest(addUserRolesAction.request.type, addUserRolesSaga),
    takeLatest(adjustTimeOffBlanceAction.request.type, adjustTimeOffBlanceSaga),
    takeLatest(changeUserTypeStatusAction.request.type, changeUserTypeStatusSaga),
    takeLatest(createUserAction.request.type, createUserSaga),
    takeLatest(deleteUserAction.request.type, deleteUserSaga),
    takeLatest(downloadPeopleListAction.request.type, downloadPeopleListSaga),
    takeLatest(downloadTemplateUsersAction.request.type, downloadTemplateUsersSaga),
    takeLatest(getBalanceHistoriesAction.request.type, getBalanceHistoriesSaga),
    takeLatest(getMyCareerHistoriesAction.request.type, getMyCareerHistoriesSaga),
    takeLatest(getMyInfoAction.request.type, getMyInfoSaga),
    takeLatest(getMyTimeOffTypesAction.request.type, getMyTimeOffTypesSaga),
    takeLatest(getNewMembersAction.request.type, getNewMembersSaga),
    takeLatest(getTimeOffHistoriesAction.request.type, getTimeOffHistoriesSaga),
    takeLatest(getUserCareerHistoriesAction.request.type, getUserCareerHistoriesSaga),
    takeLatest(getUserTimeOffTypesAction.request.type, getUserTimeOffTypesSaga),
    takeLatest(getUserAction.request.type, getUserSaga),
    takeLatest(getUsersAction.request.type, getUsersSaga),
    takeLatest(importUsersAction.request.type, importUsersSaga),
    takeLatest(offboardingUserAction.request.type, offboardingUserSaga),
    takeLatest(onboardingUserFlowAction.request.type, onboardingUserFlowSaga),
    takeLatest(rehireUserAction.request.type, rehireUserSaga),
    takeLatest(reinviteUserAction.request.type, reinviteUserSaga),
    takeLatest(revokeAccessUserAction.request.type, revokeAccessUserSaga),
    takeLatest(updateMyCareerAction.request.type, updateMyCareerSaga),
    takeLatest(updateMyInfoAction.request.type, updateMyInfoSaga),
    takeLatest(updateUserCareerAction.request.type, updateUserCareerSaga),
    takeLatest(updateUserAction.request.type, updateUserSaga),
    takeLatest(welcomeUserAction.request.type, welcomeUserSaga),
  ]);
}

import ApiService from '@/services/api';

// TYPES

export type TApproveRequestPaths = {
  id: string | number;
};
export type TApproveRequestBody = unknown;

export type TApproveRequestMaterials = {
  paths?: TApproveRequestPaths;
  body?: TApproveRequestBody;
};

export type TApproveRequestResponse = unknown;

// FUNCTION

export const approveRequest = async ({ paths, body }: TApproveRequestMaterials): Promise<TApproveRequestResponse> => {
  const response = await ApiService.patch(`/request/management/approve-request/${paths?.id}`, body);
  return response.data;
};

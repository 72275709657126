import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteBenefitAction } from '@/redux/actions';
import { deleteBenefit, TDeleteBenefitResponse } from '@/services/api';

// FUNCTION

export function* deleteBenefitSaga(action: ActionType<typeof deleteBenefitAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteBenefit, materials);
    const deleteBenefitResponse: TDeleteBenefitResponse = response as TDeleteBenefitResponse;
    yield put(deleteBenefitAction.success(deleteBenefitResponse));
    successCallback?.(deleteBenefitResponse);
  } catch (err) {
    yield put(deleteBenefitAction.failure(err));
    failedCallback?.(err);
  }
}

import { TDepartment, TTypeWorkTime, TUser } from '@/common/models';
import { TPaginateResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetReportEmployeeDataParams = {
  page: number;
  pageSize: number;
  employType?: string;
  department?: string;
  job?: string;
  status?: string;
  sort?: string;
};

export type TGetReportEmployeeDataMaterials = {
  params?: TGetReportEmployeeDataParams;
};

export type TGetReportEmployeeDataResponse = {
  chart: {
    departments: {
      data: TDepartment[];
      total: { [key: string]: string };
      totalUsers: number;
    };
    employTypes: {
      data: TTypeWorkTime[];
      total: { [key: string]: string };
      totalUsers: number;
    };
  };
  data: TPaginateResponse & {
    data: TUser[];
  };
};

// FUNCTION

export const getReportEmployeeData = async ({
  params,
}: TGetReportEmployeeDataMaterials): Promise<TGetReportEmployeeDataResponse> => {
  const response = await ApiService.get(`/report/employee-data-reports`, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TUpdateDepartmentMaterials, TUpdateDepartmentResponse } from '@/services/api/department/update-department';

// CONSTANTS

export enum EUpdateDepartmentAction {
  UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT',
  UPDATE_DEPARTMENT_REQUEST = 'UPDATE_DEPARTMENT_REQUEST',
  UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS',
  UPDATE_DEPARTMENT_FAILED = 'UPDATE_DEPARTMENT_FAILED',
}

// TYPES

export type TUpdateDepartmentRequest = {
  type: EUpdateDepartmentAction.UPDATE_DEPARTMENT_REQUEST;
  payload: {
    materials: TUpdateDepartmentMaterials;
    successCallback?: (response: TUpdateDepartmentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateDepartmentSuccess = {
  type: EUpdateDepartmentAction.UPDATE_DEPARTMENT_SUCCESS;
  payload: { response: TUpdateDepartmentResponse };
};

export type TUpdateDepartmentFailed = { type: EUpdateDepartmentAction.UPDATE_DEPARTMENT_FAILED };

// FUNCTION

export const updateDepartmentAction = {
  request: createActionCreator(
    EUpdateDepartmentAction.UPDATE_DEPARTMENT_REQUEST,
    (resolve) =>
      (
        materials: TUpdateDepartmentMaterials,
        successCallback?: (response: TUpdateDepartmentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateDepartmentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateDepartmentAction.UPDATE_DEPARTMENT_SUCCESS,
    (resolve) =>
      (response: TUpdateDepartmentResponse): TUpdateDepartmentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateDepartmentAction.UPDATE_DEPARTMENT_FAILED,
    (resolve) =>
      (error: unknown): TUpdateDepartmentFailed =>
        resolve({ error }),
  ),
};

import { createActionCreator } from 'deox';

import {
  TDeleteReportViolationMaterials,
  TDeleteReportViolationResponse,
} from '@/services/api/report-violation/delete-report-violation';

// CONSTANTS

export enum EDeleteReportViolationAction {
  DELETE_REPORT_VIOLATION = 'DELETE_REPORT_VIOLATION',
  DELETE_REPORT_VIOLATION_REQUEST = 'DELETE_REPORT_VIOLATION_REQUEST',
  DELETE_REPORT_VIOLATION_SUCCESS = 'DELETE_REPORT_VIOLATION_SUCCESS',
  DELETE_REPORT_VIOLATION_FAILED = 'DELETE_REPORT_VIOLATION_FAILED',
}

// TYPES

export type TDeleteReportViolationRequest = {
  type: EDeleteReportViolationAction.DELETE_REPORT_VIOLATION_REQUEST;
  payload: {
    materials: TDeleteReportViolationMaterials;
    successCallback?: (response: TDeleteReportViolationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteReportViolationSuccess = {
  type: EDeleteReportViolationAction.DELETE_REPORT_VIOLATION_SUCCESS;
  payload: { response: TDeleteReportViolationResponse };
};

export type TDeleteReportViolationFailed = { type: EDeleteReportViolationAction.DELETE_REPORT_VIOLATION_FAILED };

// FUNCTION

export const deleteReportViolationAction = {
  request: createActionCreator(
    EDeleteReportViolationAction.DELETE_REPORT_VIOLATION_REQUEST,
    (resolve) =>
      (
        materials: TDeleteReportViolationMaterials,
        successCallback?: (response: TDeleteReportViolationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteReportViolationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteReportViolationAction.DELETE_REPORT_VIOLATION_SUCCESS,
    (resolve) =>
      (response: TDeleteReportViolationResponse): TDeleteReportViolationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteReportViolationAction.DELETE_REPORT_VIOLATION_FAILED,
    (resolve) =>
      (error: unknown): TDeleteReportViolationFailed =>
        resolve({ error }),
  ),
};

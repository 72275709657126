import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLUE_300 }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.93159 12.1169C2.78207 12.2032 2.59231 12.1155 2.56897 11.9444C2.34422 10.2975 2.67188 8.61862 3.50624 7.17332C4.3406 5.72802 5.6306 4.60471 7.16919 3.97574C7.32901 3.9104 7.4999 4.03086 7.4999 4.20353V9.11856C7.4999 9.34185 7.38077 9.54818 7.1874 9.65982L2.93159 12.1169Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4866 7.16109C15.8276 6.0243 14.8814 5.08061 13.7429 4.42451C12.7129 3.83094 11.5582 3.49162 10.3744 3.43216C10.1676 3.42177 9.99952 3.59065 9.99952 3.79778V10.9476M16.4866 7.16109C17.1456 8.29788 17.4945 9.58787 17.4981 10.9019C17.5018 12.2159 17.1601 13.5078 16.5074 14.6482C15.8547 15.7887 14.9138 16.7376 13.779 17.4C12.6441 18.0624 11.3552 18.415 10.0412 18.4226C8.7272 18.4301 7.43428 18.0923 6.29192 17.443C5.25872 16.8557 4.38148 16.0322 3.73069 15.0421C3.6167 14.8686 3.67766 14.6377 3.8569 14.5331L9.99952 10.9476M16.4866 7.16109L9.99952 10.9476"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

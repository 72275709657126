import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getChecklistTaskOnboardAction } from '@/redux/actions';
import { getChecklistTaskOnboard, TGetChecklistTaskOnboardResponse } from '@/services/api';

// FUNCTION

export function* getChecklistTaskOnboardSaga(
  action: ActionType<typeof getChecklistTaskOnboardAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getChecklistTaskOnboard, materials);
    const getChecklistTaskOnboardResponse: TGetChecklistTaskOnboardResponse =
      response as TGetChecklistTaskOnboardResponse;
    yield put(getChecklistTaskOnboardAction.success(getChecklistTaskOnboardResponse));
    successCallback?.(getChecklistTaskOnboardResponse);
  } catch (err) {
    yield put(getChecklistTaskOnboardAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TGetTasksCategoryMaterials, TGetTasksCategoryResponse } from '@/services/api/template/get-tasks-category';

// CONSTANTS

export enum EGetTasksCategoryAction {
  GET_TASKS_CATEGORY = 'GET_TASKS_CATEGORY',
  GET_TASKS_CATEGORY_REQUEST = 'GET_TASKS_CATEGORY_REQUEST',
  GET_TASKS_CATEGORY_SUCCESS = 'GET_TASKS_CATEGORY_SUCCESS',
  GET_TASKS_CATEGORY_FAILED = 'GET_TASKS_CATEGORY_FAILED',
}

// TYPES

export type TGetTasksCategoryRequest = {
  type: EGetTasksCategoryAction.GET_TASKS_CATEGORY_REQUEST;
  payload: {
    materials: TGetTasksCategoryMaterials;
    successCallback?: (response: TGetTasksCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTasksCategorySuccess = {
  type: EGetTasksCategoryAction.GET_TASKS_CATEGORY_SUCCESS;
  payload: { response: TGetTasksCategoryResponse };
};

export type TGetTasksCategoryFailed = { type: EGetTasksCategoryAction.GET_TASKS_CATEGORY_FAILED };

// FUNCTION

export const getTasksCategoryAction = {
  request: createActionCreator(
    EGetTasksCategoryAction.GET_TASKS_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TGetTasksCategoryMaterials,
        successCallback?: (response: TGetTasksCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTasksCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTasksCategoryAction.GET_TASKS_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TGetTasksCategoryResponse): TGetTasksCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTasksCategoryAction.GET_TASKS_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TGetTasksCategoryFailed =>
        resolve({ error }),
  ),
};

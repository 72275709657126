import { createActionCreator } from 'deox';

import {
  TCreateRegulateCategoryMaterials,
  TCreateRegulateCategoryResponse,
} from '@/services/api/regulate-category/create-regulate-category';

// CONSTANTS

export enum ECreateRegulateCategoryAction {
  CREATE_REGULATE_CATEGORY = 'CREATE_REGULATE_CATEGORY',
  CREATE_REGULATE_CATEGORY_REQUEST = 'CREATE_REGULATE_CATEGORY_REQUEST',
  CREATE_REGULATE_CATEGORY_SUCCESS = 'CREATE_REGULATE_CATEGORY_SUCCESS',
  CREATE_REGULATE_CATEGORY_FAILED = 'CREATE_REGULATE_CATEGORY_FAILED',
}

// TYPES

export type TCreateRegulateCategoryRequest = {
  type: ECreateRegulateCategoryAction.CREATE_REGULATE_CATEGORY_REQUEST;
  payload: {
    materials: TCreateRegulateCategoryMaterials;
    successCallback?: (response: TCreateRegulateCategoryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateRegulateCategorySuccess = {
  type: ECreateRegulateCategoryAction.CREATE_REGULATE_CATEGORY_SUCCESS;
  payload: { response: TCreateRegulateCategoryResponse };
};

export type TCreateRegulateCategoryFailed = { type: ECreateRegulateCategoryAction.CREATE_REGULATE_CATEGORY_FAILED };

// FUNCTION

export const createRegulateCategoryAction = {
  request: createActionCreator(
    ECreateRegulateCategoryAction.CREATE_REGULATE_CATEGORY_REQUEST,
    (resolve) =>
      (
        materials: TCreateRegulateCategoryMaterials,
        successCallback?: (response: TCreateRegulateCategoryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateRegulateCategoryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateRegulateCategoryAction.CREATE_REGULATE_CATEGORY_SUCCESS,
    (resolve) =>
      (response: TCreateRegulateCategoryResponse): TCreateRegulateCategorySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateRegulateCategoryAction.CREATE_REGULATE_CATEGORY_FAILED,
    (resolve) =>
      (error: unknown): TCreateRegulateCategoryFailed =>
        resolve({ error }),
  ),
};

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRegulateCategoriesAction } from '@/redux/actions';
import { getRegulateCategories, TGetRegulateCategoriesResponse } from '@/services/api';

// FUNCTION

export function* getRegulateCategoriesSaga(action: ActionType<typeof getRegulateCategoriesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRegulateCategories, materials);
    const getRegulateCategoriesResponse: TGetRegulateCategoriesResponse = response as TGetRegulateCategoriesResponse;
    yield put(getRegulateCategoriesAction.success(getRegulateCategoriesResponse));
    successCallback?.(getRegulateCategoriesResponse);
  } catch (err) {
    yield put(getRegulateCategoriesAction.failure(err));
    failedCallback?.(err);
  }
}

import { TState } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetStatesParams = {
  countryCode: string;
};

export type TGetStatesMaterials = {
  params?: TGetStatesParams;
};

export type TGetStatesResponse = TState[];

// FUNCTION

export const getStates = async ({ params }: TGetStatesMaterials): Promise<TGetStatesResponse> => {
  const response = await ApiService.get(`/widen/state`, { params });
  return response.data;
};

import { TAttachment } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetSettingsParams = unknown;

export type TGetSettingsMaterials = {
  params?: TGetSettingsParams;
};

export type TGetSettingsResponse = {
  backgroundImage: string;
  myBackground: TAttachment[];
  createdAt: string;
  dateFormat: string;
  id: string;
  language: string;
  currency: string;
  timeZone: number;
  updatedAt: string;
  user: string;
};

// FUNCTION

export const getSettings = async ({ params }: TGetSettingsMaterials): Promise<TGetSettingsResponse> => {
  const response = await ApiService.get(`/setting`, { params });
  return response.data;
};

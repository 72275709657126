import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { duplicateTemplateAction } from '@/redux/actions';
import { duplicateTemplate, TDuplicateTemplateResponse } from '@/services/api';

// FUNCTION

export function* duplicateTemplateSaga(action: ActionType<typeof duplicateTemplateAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(duplicateTemplate, materials);
    const duplicateTemplateResponse: TDuplicateTemplateResponse = response as TDuplicateTemplateResponse;
    yield put(duplicateTemplateAction.success(duplicateTemplateResponse));
    successCallback?.(duplicateTemplateResponse);
  } catch (err) {
    yield put(duplicateTemplateAction.failure(err));
    failedCallback?.(err);
  }
}

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.251 7.67274H3.75098C3.33676 7.67274 3.00098 8.00852 3.00098 8.42274V20.4227C3.00098 20.837 3.33676 21.1727 3.75098 21.1727H20.251C20.6652 21.1727 21.001 20.837 21.001 20.4227V8.42274C21.001 8.00852 20.6652 7.67274 20.251 7.67274Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 7.67273V6.17273C15.75 5.7749 15.592 5.39337 15.3107 5.11207C15.0294 4.83076 14.6478 4.67273 14.25 4.67273H9.75C9.35218 4.67273 8.97064 4.83076 8.68934 5.11207C8.40804 5.39337 8.25 5.7749 8.25 6.17273V7.67273"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0011 12.7642C18.2658 14.3468 15.1605 15.1777 12.0004 15.1727C8.84075 15.1777 5.736 14.347 3.00098 12.765"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.875 12.1727H13.125" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Svg;

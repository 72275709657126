import { createActionCreator } from 'deox';

import {
  TGetRecentTimeOffRequestsMaterials,
  TGetRecentTimeOffRequestsResponse,
} from '@/services/api/request/get-recent-time-off-requests';

// CONSTANTS

export enum EGetRecentTimeOffRequestsAction {
  GET_RECENT_TIME_OFF_REQUESTS = 'GET_RECENT_TIME_OFF_REQUESTS',
  GET_RECENT_TIME_OFF_REQUESTS_REQUEST = 'GET_RECENT_TIME_OFF_REQUESTS_REQUEST',
  GET_RECENT_TIME_OFF_REQUESTS_SUCCESS = 'GET_RECENT_TIME_OFF_REQUESTS_SUCCESS',
  GET_RECENT_TIME_OFF_REQUESTS_FAILED = 'GET_RECENT_TIME_OFF_REQUESTS_FAILED',
}

// TYPES

export type TGetRecentTimeOffRequestsRequest = {
  type: EGetRecentTimeOffRequestsAction.GET_RECENT_TIME_OFF_REQUESTS_REQUEST;
  payload: {
    materials: TGetRecentTimeOffRequestsMaterials;
    successCallback?: (response: TGetRecentTimeOffRequestsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRecentTimeOffRequestsSuccess = {
  type: EGetRecentTimeOffRequestsAction.GET_RECENT_TIME_OFF_REQUESTS_SUCCESS;
  payload: { response: TGetRecentTimeOffRequestsResponse };
};

export type TGetRecentTimeOffRequestsFailed = {
  type: EGetRecentTimeOffRequestsAction.GET_RECENT_TIME_OFF_REQUESTS_FAILED;
};

// FUNCTION

export const getRecentTimeOffRequestsAction = {
  request: createActionCreator(
    EGetRecentTimeOffRequestsAction.GET_RECENT_TIME_OFF_REQUESTS_REQUEST,
    (resolve) =>
      (
        materials: TGetRecentTimeOffRequestsMaterials,
        successCallback?: (response: TGetRecentTimeOffRequestsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRecentTimeOffRequestsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRecentTimeOffRequestsAction.GET_RECENT_TIME_OFF_REQUESTS_SUCCESS,
    (resolve) =>
      (response: TGetRecentTimeOffRequestsResponse): TGetRecentTimeOffRequestsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRecentTimeOffRequestsAction.GET_RECENT_TIME_OFF_REQUESTS_FAILED,
    (resolve) =>
      (error: unknown): TGetRecentTimeOffRequestsFailed =>
        resolve({ error }),
  ),
};

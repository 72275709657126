import ApiService from '@/services/api';

// TYPES

export type TDownloadTemplateUsersParams = unknown;
export type TDownloadTemplateUsersBody = unknown;

export type TDownloadTemplateUsersMaterials = {
  params?: TDownloadTemplateUsersParams;
  body?: TDownloadTemplateUsersBody;
};

export type TDownloadTemplateUsersResponse = {
  path: string;
};

// FUNCTION

export const downloadTemplateUsers = async ({
  params,
  body,
}: TDownloadTemplateUsersMaterials): Promise<TDownloadTemplateUsersResponse> => {
  const response = await ApiService.post(`/user/download-template`, body, { params });
  return response.data;
};

import { TRegulationState } from '@/redux/reducers/regulation';
import { TDeleteRegulationSuccess } from '@/redux/actions/regulation';

export const deleteRegulationUpdateState = (
  state: TRegulationState,
  action: TDeleteRegulationSuccess,
): TRegulationState => ({
  ...state,
  deleteRegulationResponse: action.payload.response,
});

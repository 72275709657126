import ApiService from '@/services/api';

// TYPES

export type TChangeTaskStatusPaths = {
  id: string | number;
};
export type TChangeTaskStatusBody = unknown;

export type TChangeTaskStatusMaterials = {
  paths?: TChangeTaskStatusPaths;
  body?: TChangeTaskStatusBody;
};

export type TChangeTaskStatusResponse = unknown;

// FUNCTION

export const changeTaskStatus = async ({
  paths,
  body,
}: TChangeTaskStatusMaterials): Promise<TChangeTaskStatusResponse> => {
  const response = await ApiService.patch(`/checklist/task/change-status/${paths?.id}`, body);
  return response.data;
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 9.75H6C5.17157 9.75 4.5 10.4216 4.5 11.25V20.25C4.5 21.0784 5.17157 21.75 6 21.75H18C18.8284 21.75 19.5 21.0784 19.5 20.25V11.25C19.5 10.4216 18.8284 9.75 18 9.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 9.75V7.5C17.25 6.10761 16.6969 4.77226 15.7123 3.78769C14.7277 2.80312 13.3924 2.25 12 2.25C10.6076 2.25 9.27226 2.80312 8.28769 3.78769C7.30312 4.77226 6.75 6.10761 6.75 7.5V9.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.5C12.4142 16.5 12.75 16.1642 12.75 15.75C12.75 15.3358 12.4142 15 12 15C11.5858 15 11.25 15.3358 11.25 15.75C11.25 16.1642 11.5858 16.5 12 16.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRequestsAction } from '@/redux/actions';
import { getRequests, TGetRequestsResponse } from '@/services/api';

// FUNCTION

export function* getRequestsSaga(action: ActionType<typeof getRequestsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRequests, materials);
    const getRequestsResponse: TGetRequestsResponse = response as TGetRequestsResponse;
    yield put(getRequestsAction.success(getRequestsResponse));
    successCallback?.(getRequestsResponse);
  } catch (err) {
    yield put(getRequestsAction.failure(err));
    failedCallback?.(err);
  }
}

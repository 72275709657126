import { ETypeRequestManage } from '@/common/enums';
import { TRequest, TTaskMisison } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetRequestManagementPaths = {
  id: string | number;
};
export type TGetRequestManagementParams = {
  typeManage: ETypeRequestManage;
};

export type TGetRequestManagementMaterials = {
  paths?: TGetRequestManagementPaths;
  params?: TGetRequestManagementParams;
};

export type TGetRequestManagementResponse = {
  data: TRequest;
  position: {
    currentPosition: number;
    nextPosition: string;
    previousRequest: string;
    totalCount: number;
  };
  reTasks: {
    [key: string]: TTaskMisison;
  };
};

// FUNCTION

export const getRequestManagement = async ({
  paths,
  params,
}: TGetRequestManagementMaterials): Promise<TGetRequestManagementResponse> => {
  const response = await ApiService.get(`/request/management/request/${paths?.id}`, { params });
  return response.data;
};

import { TSettingState } from '@/redux/reducers/setting';
import { TCreateNewBackgroundSettingSuccess } from '@/redux/actions/setting';

export const createNewBackgroundSettingUpdateState = (
  state: TSettingState,
  action: TCreateNewBackgroundSettingSuccess,
): TSettingState => ({
  ...state,
  createNewBackgroundSettingResponse: action.payload.response,
});

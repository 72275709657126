import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteTimeOffTypeAction } from '@/redux/actions';
import { deleteTimeOffType, TDeleteTimeOffTypeResponse } from '@/services/api';

// FUNCTION

export function* deleteTimeOffTypeSaga(action: ActionType<typeof deleteTimeOffTypeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteTimeOffType, materials);
    const deleteTimeOffTypeResponse: TDeleteTimeOffTypeResponse = response as TDeleteTimeOffTypeResponse;
    yield put(deleteTimeOffTypeAction.success(deleteTimeOffTypeResponse));
    successCallback?.(deleteTimeOffTypeResponse);
  } catch (err) {
    yield put(deleteTimeOffTypeAction.failure(err));
    failedCallback?.(err);
  }
}

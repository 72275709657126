import { createActionCreator } from 'deox';

import { TDeleteMemberRoleMaterials, TDeleteMemberRoleResponse } from '@/services/api/role/delete-member-role';

// CONSTANTS

export enum EDeleteMemberRoleAction {
  DELETE_MEMBER_ROLE = 'DELETE_MEMBER_ROLE',
  DELETE_MEMBER_ROLE_REQUEST = 'DELETE_MEMBER_ROLE_REQUEST',
  DELETE_MEMBER_ROLE_SUCCESS = 'DELETE_MEMBER_ROLE_SUCCESS',
  DELETE_MEMBER_ROLE_FAILED = 'DELETE_MEMBER_ROLE_FAILED',
}

// TYPES

export type TDeleteMemberRoleRequest = {
  type: EDeleteMemberRoleAction.DELETE_MEMBER_ROLE_REQUEST;
  payload: {
    materials: TDeleteMemberRoleMaterials;
    successCallback?: (response: TDeleteMemberRoleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteMemberRoleSuccess = {
  type: EDeleteMemberRoleAction.DELETE_MEMBER_ROLE_SUCCESS;
  payload: { response: TDeleteMemberRoleResponse };
};

export type TDeleteMemberRoleFailed = { type: EDeleteMemberRoleAction.DELETE_MEMBER_ROLE_FAILED };

// FUNCTION

export const deleteMemberRoleAction = {
  request: createActionCreator(
    EDeleteMemberRoleAction.DELETE_MEMBER_ROLE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteMemberRoleMaterials,
        successCallback?: (response: TDeleteMemberRoleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteMemberRoleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteMemberRoleAction.DELETE_MEMBER_ROLE_SUCCESS,
    (resolve) =>
      (response: TDeleteMemberRoleResponse): TDeleteMemberRoleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteMemberRoleAction.DELETE_MEMBER_ROLE_FAILED,
    (resolve) =>
      (error: unknown): TDeleteMemberRoleFailed =>
        resolve({ error }),
  ),
};

import { TRequestState } from '@/redux/reducers/request';
import { TRequestReturnAssetSuccess } from '@/redux/actions/request';

export const requestReturnAssetUpdateState = (
  state: TRequestState,
  action: TRequestReturnAssetSuccess,
): TRequestState => ({
  ...state,
  requestReturnAssetResponse: action.payload.response,
});

import React from 'react';
import classNames from 'classnames';

import Tooltip from '@/components/Tooltip';
import Icon, { EIconColor, EIconName } from '@/components/Icon';

import { TFormLabelProps } from './FormLabel.types.d';
import './FormLabel.scss';

const FormLabel: React.FC<TFormLabelProps> = ({
  label,
  disabled,
  keepLabelEnable,
  required,
  passed,
  failed,
  tooltip,
}) => {
  return label ? (
    <div className={classNames('FormLabel flex items-center', { disabled: keepLabelEnable ? undefined : disabled })}>
      {label}
      {required && <span>*</span>}
      {typeof passed === 'boolean' && passed && (
        <Icon name={EIconName.CheckCircleOutline} color={EIconColor.LIME_500} />
      )}
      {typeof failed === 'boolean' && !failed && <Icon name={EIconName.StopOutline} color={EIconColor.RED_500} />}
      {tooltip && (
        <Tooltip title={tooltip} placement="right">
          <Icon name={EIconName.QuestionCircle} color={EIconColor.GRAYSCALE_500} />
        </Tooltip>
      )}
    </div>
  ) : (
    <></>
  );
};

export default FormLabel;

import { createActionCreator } from 'deox';

import { TGetRequestsMaterials, TGetRequestsResponse } from '@/services/api/request/get-requests';

// CONSTANTS

export enum EGetRequestsAction {
  GET_REQUESTS = 'GET_REQUESTS',
  GET_REQUESTS_REQUEST = 'GET_REQUESTS_REQUEST',
  GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS',
  GET_REQUESTS_FAILED = 'GET_REQUESTS_FAILED',
}

// TYPES

export type TGetRequestsRequest = {
  type: EGetRequestsAction.GET_REQUESTS_REQUEST;
  payload: {
    materials: TGetRequestsMaterials;
    successCallback?: (response: TGetRequestsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRequestsSuccess = {
  type: EGetRequestsAction.GET_REQUESTS_SUCCESS;
  payload: { response: TGetRequestsResponse };
};

export type TGetRequestsFailed = { type: EGetRequestsAction.GET_REQUESTS_FAILED };

// FUNCTION

export const getRequestsAction = {
  request: createActionCreator(
    EGetRequestsAction.GET_REQUESTS_REQUEST,
    (resolve) =>
      (
        materials: TGetRequestsMaterials,
        successCallback?: (response: TGetRequestsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRequestsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRequestsAction.GET_REQUESTS_SUCCESS,
    (resolve) =>
      (response: TGetRequestsResponse): TGetRequestsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRequestsAction.GET_REQUESTS_FAILED,
    (resolve) =>
      (error: unknown): TGetRequestsFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TRequestReturnAssetParams = unknown;
export type TRequestReturnAssetBody = unknown;

export type TRequestReturnAssetMaterials = {
  params?: TRequestReturnAssetParams;
  body?: TRequestReturnAssetBody;
};

export type TRequestReturnAssetResponse = unknown;

// FUNCTION

export const requestReturnAsset = async ({
  params,
  body,
}: TRequestReturnAssetMaterials): Promise<TRequestReturnAssetResponse> => {
  const response = await ApiService.post(`/request/asset/return`, body, { params });
  return response.data;
};

import { createActionCreator } from 'deox';

import { TCreateRegulationMaterials, TCreateRegulationResponse } from '@/services/api/regulation/create-regulation';

// CONSTANTS

export enum ECreateRegulationAction {
  CREATE_REGULATION = 'CREATE_REGULATION',
  CREATE_REGULATION_REQUEST = 'CREATE_REGULATION_REQUEST',
  CREATE_REGULATION_SUCCESS = 'CREATE_REGULATION_SUCCESS',
  CREATE_REGULATION_FAILED = 'CREATE_REGULATION_FAILED',
}

// TYPES

export type TCreateRegulationRequest = {
  type: ECreateRegulationAction.CREATE_REGULATION_REQUEST;
  payload: {
    materials: TCreateRegulationMaterials;
    successCallback?: (response: TCreateRegulationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateRegulationSuccess = {
  type: ECreateRegulationAction.CREATE_REGULATION_SUCCESS;
  payload: { response: TCreateRegulationResponse };
};

export type TCreateRegulationFailed = { type: ECreateRegulationAction.CREATE_REGULATION_FAILED };

// FUNCTION

export const createRegulationAction = {
  request: createActionCreator(
    ECreateRegulationAction.CREATE_REGULATION_REQUEST,
    (resolve) =>
      (
        materials: TCreateRegulationMaterials,
        successCallback?: (response: TCreateRegulationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateRegulationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateRegulationAction.CREATE_REGULATION_SUCCESS,
    (resolve) =>
      (response: TCreateRegulationResponse): TCreateRegulationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateRegulationAction.CREATE_REGULATION_FAILED,
    (resolve) =>
      (error: unknown): TCreateRegulationFailed =>
        resolve({ error }),
  ),
};

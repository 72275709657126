import env from '@/env';
import AuthorizedInstance from '@/services/authorized-api';

const ApiService = AuthorizedInstance(env.api.baseUrl.service);

export default ApiService;
export * from './auth';
export * from './user';
export * from './type-work';
export * from './department';
export * from './job';
export * from './branch';
export * from './holiday';
export * from './regulation';
export * from './role';
export * from './regulate-category';
export * from './setting';
export * from './benefit';
export * from './company';
export * from './asset';
export * from './request';
export * from './approver';
export * from './global';
export * from './report-violation';
export * from './widen';
export * from './upload';
export * from './template';
export * from './checklist';
export * from './time-off-type';
export * from './mail';
export * from './dashboard';
export * from './report';

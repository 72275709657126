import { createActionCreator } from 'deox';

import {
  TGetUserCareerHistoriesMaterials,
  TGetUserCareerHistoriesResponse,
} from '@/services/api/user/get-user-career-histories';

// CONSTANTS

export enum EGetUserCareerHistoriesAction {
  GET_USER_CAREER_HISTORIES = 'GET_USER_CAREER_HISTORIES',
  GET_USER_CAREER_HISTORIES_REQUEST = 'GET_USER_CAREER_HISTORIES_REQUEST',
  GET_USER_CAREER_HISTORIES_SUCCESS = 'GET_USER_CAREER_HISTORIES_SUCCESS',
  GET_USER_CAREER_HISTORIES_FAILED = 'GET_USER_CAREER_HISTORIES_FAILED',
}

// TYPES

export type TGetUserCareerHistoriesRequest = {
  type: EGetUserCareerHistoriesAction.GET_USER_CAREER_HISTORIES_REQUEST;
  payload: {
    materials: TGetUserCareerHistoriesMaterials;
    successCallback?: (response: TGetUserCareerHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetUserCareerHistoriesSuccess = {
  type: EGetUserCareerHistoriesAction.GET_USER_CAREER_HISTORIES_SUCCESS;
  payload: { response: TGetUserCareerHistoriesResponse };
};

export type TGetUserCareerHistoriesFailed = { type: EGetUserCareerHistoriesAction.GET_USER_CAREER_HISTORIES_FAILED };

// FUNCTION

export const getUserCareerHistoriesAction = {
  request: createActionCreator(
    EGetUserCareerHistoriesAction.GET_USER_CAREER_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetUserCareerHistoriesMaterials,
        successCallback?: (response: TGetUserCareerHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetUserCareerHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetUserCareerHistoriesAction.GET_USER_CAREER_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetUserCareerHistoriesResponse): TGetUserCareerHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetUserCareerHistoriesAction.GET_USER_CAREER_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetUserCareerHistoriesFailed =>
        resolve({ error }),
  ),
};

import { TRequestState } from '@/redux/reducers/request';
import { TGetRequestManagementSuccess } from '@/redux/actions/request';

export const getRequestManagementUpdateState = (
  state: TRequestState,
  action: TGetRequestManagementSuccess,
): TRequestState => ({
  ...state,
  getRequestManagementResponse: action.payload.response,
});

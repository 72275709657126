import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { reinviteUserAction } from '@/redux/actions';
import { reinviteUser, TReinviteUserResponse } from '@/services/api';

// FUNCTION

export function* reinviteUserSaga(action: ActionType<typeof reinviteUserAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(reinviteUser, materials);
    const reinviteUserResponse: TReinviteUserResponse = response as TReinviteUserResponse;
    yield put(reinviteUserAction.success(reinviteUserResponse));
    successCallback?.(reinviteUserResponse);
  } catch (err) {
    yield put(reinviteUserAction.failure(err));
    failedCallback?.(err);
  }
}

import { createActionCreator } from 'deox';

import { TDeleteTypeWorkMaterials, TDeleteTypeWorkResponse } from '@/services/api/type-work/delete-type-work';

// CONSTANTS

export enum EDeleteTypeWorkAction {
  DELETE_TYPE_WORK = 'DELETE_TYPE_WORK',
  DELETE_TYPE_WORK_REQUEST = 'DELETE_TYPE_WORK_REQUEST',
  DELETE_TYPE_WORK_SUCCESS = 'DELETE_TYPE_WORK_SUCCESS',
  DELETE_TYPE_WORK_FAILED = 'DELETE_TYPE_WORK_FAILED',
}

// TYPES

export type TDeleteTypeWorkRequest = {
  type: EDeleteTypeWorkAction.DELETE_TYPE_WORK_REQUEST;
  payload: {
    materials: TDeleteTypeWorkMaterials;
    successCallback?: (response: TDeleteTypeWorkResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteTypeWorkSuccess = {
  type: EDeleteTypeWorkAction.DELETE_TYPE_WORK_SUCCESS;
  payload: { response: TDeleteTypeWorkResponse };
};

export type TDeleteTypeWorkFailed = { type: EDeleteTypeWorkAction.DELETE_TYPE_WORK_FAILED };

// FUNCTION

export const deleteTypeWorkAction = {
  request: createActionCreator(
    EDeleteTypeWorkAction.DELETE_TYPE_WORK_REQUEST,
    (resolve) =>
      (
        materials: TDeleteTypeWorkMaterials,
        successCallback?: (response: TDeleteTypeWorkResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteTypeWorkRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteTypeWorkAction.DELETE_TYPE_WORK_SUCCESS,
    (resolve) =>
      (response: TDeleteTypeWorkResponse): TDeleteTypeWorkSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteTypeWorkAction.DELETE_TYPE_WORK_FAILED,
    (resolve) =>
      (error: unknown): TDeleteTypeWorkFailed =>
        resolve({ error }),
  ),
};

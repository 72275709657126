import { createActionCreator } from 'deox';

import { TDeleteJobMaterials, TDeleteJobResponse } from '@/services/api/job/delete-job';

// CONSTANTS

export enum EDeleteJobAction {
  DELETE_JOB = 'DELETE_JOB',
  DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST',
  DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS',
  DELETE_JOB_FAILED = 'DELETE_JOB_FAILED',
}

// TYPES

export type TDeleteJobRequest = {
  type: EDeleteJobAction.DELETE_JOB_REQUEST;
  payload: {
    materials: TDeleteJobMaterials;
    successCallback?: (response: TDeleteJobResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteJobSuccess = {
  type: EDeleteJobAction.DELETE_JOB_SUCCESS;
  payload: { response: TDeleteJobResponse };
};

export type TDeleteJobFailed = { type: EDeleteJobAction.DELETE_JOB_FAILED };

// FUNCTION

export const deleteJobAction = {
  request: createActionCreator(
    EDeleteJobAction.DELETE_JOB_REQUEST,
    (resolve) =>
      (
        materials: TDeleteJobMaterials,
        successCallback?: (response: TDeleteJobResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteJobRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteJobAction.DELETE_JOB_SUCCESS,
    (resolve) =>
      (response: TDeleteJobResponse): TDeleteJobSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteJobAction.DELETE_JOB_FAILED,
    (resolve) =>
      (error: unknown): TDeleteJobFailed =>
        resolve({ error }),
  ),
};

import ApiService from '@/services/api';

// TYPES

export type TDownloadReportOnboardParams = unknown;
export type TDownloadReportOnboardBody = unknown;

export type TDownloadReportOnboardMaterials = {
  params?: TDownloadReportOnboardParams;
  body?: TDownloadReportOnboardBody;
};

export type TDownloadReportOnboardResponse = string;

// FUNCTION

export const downloadReportOnboard = async ({
  params,
  body,
}: TDownloadReportOnboardMaterials): Promise<TDownloadReportOnboardResponse> => {
  const response = await ApiService.post(`/report/onboard`, body, { params });
  return response.data;
};

import ApiService from '@/services/api';

// TYPES

export type TDeleteBenefitPaths = {
  id: string | number;
};
export type TDeleteBenefitParams = unknown;

export type TDeleteBenefitMaterials = {
  paths?: TDeleteBenefitPaths;
  params?: TDeleteBenefitParams;
};

export type TDeleteBenefitResponse = unknown;

// FUNCTION

export const deleteBenefit = async ({ paths, params }: TDeleteBenefitMaterials): Promise<TDeleteBenefitResponse> => {
  const response = await ApiService.delete(`/benefit/${paths?.id}`, { params });
  return response.data;
};

import { createReducer } from 'deox';

import {
  TCopyLastYearHolidaysResponse,
  TCreateHolidayResponse,
  TDeleteHolidayResponse,
  TGetHolidayResponse,
  TGetHolidaysResponse,
  TUpdateHolidayResponse,
} from '@/services/api/holiday';
import {
  copyLastYearHolidaysAction,
  createHolidayAction,
  deleteHolidayAction,
  getHolidayAction,
  getHolidaysAction,
  updateHolidayAction,
} from '@/redux/actions';
import { copyLastYearHolidaysUpdateState } from './copy-last-year-holidays';
import { createHolidayUpdateState } from './create-holiday';
import { deleteHolidayUpdateState } from './delete-holiday';
import { getHolidayUpdateState } from './get-holiday';
import { getHolidaysUpdateState } from './get-holidays';
import { updateHolidayUpdateState } from './update-holiday';

export type THolidayState = {
  copyLastYearHolidaysResponse?: TCopyLastYearHolidaysResponse;
  createHolidayResponse?: TCreateHolidayResponse;
  deleteHolidayResponse?: TDeleteHolidayResponse;
  getHolidayResponse?: TGetHolidayResponse;
  getHolidaysResponse?: TGetHolidaysResponse;
  updateHolidayResponse?: TUpdateHolidayResponse;
};

const initialState: THolidayState = {
  copyLastYearHolidaysResponse: undefined,
  createHolidayResponse: undefined,
  deleteHolidayResponse: undefined,
  getHolidayResponse: undefined,
  getHolidaysResponse: undefined,
  updateHolidayResponse: undefined,
};

const HolidayReducer = createReducer(initialState, (handleAction) => [
  handleAction(copyLastYearHolidaysAction.success, copyLastYearHolidaysUpdateState),
  handleAction(createHolidayAction.success, createHolidayUpdateState),
  handleAction(deleteHolidayAction.success, deleteHolidayUpdateState),
  handleAction(getHolidayAction.success, getHolidayUpdateState),
  handleAction(getHolidaysAction.success, getHolidaysUpdateState),
  handleAction(updateHolidayAction.success, updateHolidayUpdateState),
]);

export default HolidayReducer;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteRegulateCategoryAction } from '@/redux/actions';
import { deleteRegulateCategory, TDeleteRegulateCategoryResponse } from '@/services/api';

// FUNCTION

export function* deleteRegulateCategorySaga(
  action: ActionType<typeof deleteRegulateCategoryAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteRegulateCategory, materials);
    const deleteRegulateCategoryResponse: TDeleteRegulateCategoryResponse = response as TDeleteRegulateCategoryResponse;
    yield put(deleteRegulateCategoryAction.success(deleteRegulateCategoryResponse));
    successCallback?.(deleteRegulateCategoryResponse);
  } catch (err) {
    yield put(deleteRegulateCategoryAction.failure(err));
    failedCallback?.(err);
  }
}

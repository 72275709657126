import { createActionCreator } from 'deox';

import {
  TUpdateAssignOnboardMaterials,
  TUpdateAssignOnboardResponse,
} from '@/services/api/checklist/update-assign-onboard';

// CONSTANTS

export enum EUpdateAssignOnboardAction {
  UPDATE_ASSIGN_ONBOARD = 'UPDATE_ASSIGN_ONBOARD',
  UPDATE_ASSIGN_ONBOARD_REQUEST = 'UPDATE_ASSIGN_ONBOARD_REQUEST',
  UPDATE_ASSIGN_ONBOARD_SUCCESS = 'UPDATE_ASSIGN_ONBOARD_SUCCESS',
  UPDATE_ASSIGN_ONBOARD_FAILED = 'UPDATE_ASSIGN_ONBOARD_FAILED',
}

// TYPES

export type TUpdateAssignOnboardRequest = {
  type: EUpdateAssignOnboardAction.UPDATE_ASSIGN_ONBOARD_REQUEST;
  payload: {
    materials: TUpdateAssignOnboardMaterials;
    successCallback?: (response: TUpdateAssignOnboardResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateAssignOnboardSuccess = {
  type: EUpdateAssignOnboardAction.UPDATE_ASSIGN_ONBOARD_SUCCESS;
  payload: { response: TUpdateAssignOnboardResponse };
};

export type TUpdateAssignOnboardFailed = { type: EUpdateAssignOnboardAction.UPDATE_ASSIGN_ONBOARD_FAILED };

// FUNCTION

export const updateAssignOnboardAction = {
  request: createActionCreator(
    EUpdateAssignOnboardAction.UPDATE_ASSIGN_ONBOARD_REQUEST,
    (resolve) =>
      (
        materials: TUpdateAssignOnboardMaterials,
        successCallback?: (response: TUpdateAssignOnboardResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateAssignOnboardRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateAssignOnboardAction.UPDATE_ASSIGN_ONBOARD_SUCCESS,
    (resolve) =>
      (response: TUpdateAssignOnboardResponse): TUpdateAssignOnboardSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateAssignOnboardAction.UPDATE_ASSIGN_ONBOARD_FAILED,
    (resolve) =>
      (error: unknown): TUpdateAssignOnboardFailed =>
        resolve({ error }),
  ),
};

import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAYSCALE_600 }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.26897 10.2852L2.25647 14.2602C2.13931 14.3764 2.04631 14.5146 1.98285 14.6669C1.91939 14.8193 1.88672 14.9826 1.88672 15.1477C1.88672 15.3127 1.91939 15.4761 1.98285 15.6284C2.04631 15.7807 2.13931 15.919 2.25647 16.0352L4.90647 18.6852C5.02267 18.8023 5.16092 18.8953 5.31325 18.9588C5.46557 19.0222 5.62895 19.0549 5.79397 19.0549C5.95898 19.0549 6.12236 19.0222 6.27469 18.9588C6.42701 18.8953 6.56526 18.8023 6.68147 18.6852L10.6565 14.6727"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7688 11.5602L17.7438 7.58515C17.861 7.46895 17.954 7.3307 18.0175 7.17837C18.0809 7.02605 18.1136 6.86267 18.1136 6.69765C18.1136 6.53264 18.0809 6.36926 18.0175 6.21693C17.954 6.06461 17.861 5.92636 17.7438 5.81015L15.1313 3.16015C15.0151 3.04299 14.8769 2.95 14.7246 2.88654C14.5722 2.82308 14.4089 2.79041 14.2438 2.79041C14.0788 2.79041 13.9155 2.82308 13.7631 2.88654C13.6108 2.95 13.4726 3.04299 13.3563 3.16015L9.38135 7.17265"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.519 8.42267L7.51904 13.4227"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Svg;

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createTemplateAction } from '@/redux/actions';
import { createTemplate, TCreateTemplateResponse } from '@/services/api';

// FUNCTION

export function* createTemplateSaga(action: ActionType<typeof createTemplateAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createTemplate, materials);
    const createTemplateResponse: TCreateTemplateResponse = response as TCreateTemplateResponse;
    yield put(createTemplateAction.success(createTemplateResponse));
    successCallback?.(createTemplateResponse);
  } catch (err) {
    yield put(createTemplateAction.failure(err));
    failedCallback?.(err);
  }
}

import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { mailFeedbackAction } from '@/redux/actions';
import { mailFeedback, TMailFeedbackResponse } from '@/services/api';

// FUNCTION

export function* mailFeedbackSaga(action: ActionType<typeof mailFeedbackAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(mailFeedback, materials);
    const mailFeedbackResponse: TMailFeedbackResponse = response as TMailFeedbackResponse;
    yield put(mailFeedbackAction.success(mailFeedbackResponse));
    successCallback?.(mailFeedbackResponse);
  } catch (err) {
    yield put(mailFeedbackAction.failure(err));
    failedCallback?.(err);
  }
}
